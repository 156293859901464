import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../../../../actions';
import Button from '../../../../../components/Button';
import TextFormField from '../../../../../components/Form/TextFormField';
import Headline from '../../../../../components/Headline';
import NavigationHeader from '../../../../../components/Navigator/NavigationHeader';
import ResponseDispatcher from '../../../../../components/ResponseDispatcher';
import Section from '../../../../../components/Section/Section';
import View from '../../../../../components/View';
import Color from '../../../../../constants/Color';

class UserMypagePaymentInfoDetail extends React.Component {
  state = {
    activeIndex: 0
  };

  componentDidMount() {
    this.props.getMyselfUser();
  }

  render() {
    const { myself } = this.props.user;

    if (!myself) {
      return null;
    }

    const paymentInfo = myself.paymentInfos.find(
      info => info.id === this.props.match.params.paymentInfoId
    );

    return (
      <View>
        <NavigationHeader
          title="カード情報"
          goBack={this.props.history.goBack}
        />
        <View style={{ paddingTop: 32, marginBottom: 32 }}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '0 16px 0 16px',
              marginBottom: 16
            }}
          >
            <Headline size={3} style={{ fontSize: 15 }}>
              カード情報
            </Headline>
            <Button
              tiny
              color={Color.red}
              onClick={() => {
                const result = window.confirm(
                  'カード情報を削除します。よろしいですか？'
                );

                if (result) {
                  this.props.deletePaymentInfo(this.props.match.params.id);
                }
              }}
            >
              削除する
            </Button>
          </View>
          <Section
            style={{
              padding: 16,
              borderBottom: `1px solid ${Color.lightGrey}`
            }}
          >
            <TextFormField
              staticControl
              labelWidth={120}
              label="ブランド"
              value={paymentInfo.source.brand}
              style={{ marginBottom: 24 }}
            />
            <TextFormField
              staticControl
              labelWidth={120}
              label="カード番号"
              value={`**** ${paymentInfo.source.last4}`}
              style={{ marginBottom: 24 }}
            />
            <TextFormField
              staticControl
              labelWidth={120}
              label="有効期限"
              value={`${paymentInfo.source.expYear}/${paymentInfo.source.expMonth}`}
              style={{ marginBottom: 24 }}
            />
            <TextFormField
              staticControl
              labelWidth={120}
              label="デフォルト利用"
              value={`${
                paymentInfo.id === myself.defaultPaymentInfoId
                  ? 'はい'
                  : 'いいえ'
              }`}
            />
          </Section>
        </View>
        {paymentInfo.id !== myself.defaultPaymentInfoId && (
          <View style={{ padding: '0 16px' }}>
            <Button
              disable={this.props.user.isUpdateLoading}
              onClick={() => {
                this.props.updateMyselfUser({
                  consumerMetadata: {
                    defaultPaymentInfoId: paymentInfo.id
                  }
                });
              }}
            >
              デフォルト利用で利用する
            </Button>
          </View>
        )}
        <ResponseDispatcher
          isLoading={this.props.user.isUpdateLoading}
          isFailed={this.props.user.isUpdateFailed}
          onComplete={() => {
            this.props.getMyselfUser(this.props.auth.uid);
          }}
        />
        <ResponseDispatcher
          isLoading={this.props.paymentInfo.isDeleteLoading}
          isFailed={this.props.paymentInfo.isDeleteFailed}
          onComplete={() => {
            this.props.getMyselfUser(this.props.auth.uid);

            this.props.history.push('/user/mypage/paymentInfo/index');
          }}
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserMypagePaymentInfoDetail);
