import React from 'react';

import TabButton from './TabButton';
import TabNavigator from './TabNavigator';
import View from '../View';

export default function TabContainer(props) {
  const { children, activeIndex, buttons = [], style = {} } = props;

  return (
    <View style={{ height: '100%', ...style }}>
      <View style={{ flex: 1, overflowY: 'scroll' }}>{children}</View>
      <TabNavigator>
        {buttons.map((button, index) => {
          return (
            <TabButton
              key={button.label}
              active={index === activeIndex}
              symbol={{
                label: button.label,
                icon: button.icon
              }}
              onClick={button.onClick}
              style={{ flex: 1 }}
            />
          );
        })}
      </TabNavigator>
    </View>
  );
}
