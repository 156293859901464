import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../../../../../actions';
import ActionSheet from '../../../../../../components/ActionSheet/ActionSheet';
import Button from '../../../../../../components/Button';
import FileInput from '../../../../../../components/Form/FileInput';
import GalleryItem from '../../../../../../components/List/GalleryItem';
import GalleryList from '../../../../../../components/List/GalleryList';
import NavigationHeader from '../../../../../../components/Navigator/NavigationHeader';
import Section from '../../../../../../components/Section/Section';
import View from '../../../../../../components/View';
import Model from '../../../../../../models/Model';

class ModelMypageProfileGalleryEdit extends React.Component {
  constructor(props) {
    super(props);

    const { myself = {} } = this.props.user;

    this.state = {
      params: new Model(myself || {}).object,
      selectedGalleryIndex: null,
      isOpenGalleryOpetationActionSheet: false
    };
  }

  shouldComponentUpdate(nextProps) {
    if (
      this.props.user.isGetMyselfLoading &&
      !nextProps.user.isGetMyselfLoading
    ) {
      const { myself } = nextProps.user;

      this.setState({
        params: new Model(myself).object
      });
    }

    return true;
  }

  render() {
    const { myself } = this.props.user;

    if (!this.props.user.myself) {
      return null;
    }

    return (
      <View>
        <NavigationHeader
          title="ギャラリー"
          goBack={this.props.history.goBack}
        />
        <Section>
          <GalleryList
            data={myself.galleries}
            renderItem={({ item, index }) => {
              return (
                <GalleryItem
                  source={item}
                  onClick={() =>
                    this.setState({
                      isOpenGalleryOpetationActionSheet: true,
                      selectedGalleryIndex: index
                    })
                  }
                  style={{
                    width: this.props.layout.width / 3,
                    height: this.props.layout.width / 3
                  }}
                />
              );
            }}
          />
          <View style={{ padding: 24 }}>
            <FileInput
              onChangeFile={image => {
                this.props.updateMyselfUser({
                  model: {
                    galleries: [...myself.galleries, image]
                  }
                });
              }}
              style={{ marginBottom: 24 }}
            >
              <Button disable={this.props.user.isUpdateLoading}>
                写真を追加する
              </Button>
            </FileInput>
          </View>
        </Section>
        <ActionSheet
          visible={this.state.isOpenGalleryOpetationActionSheet}
          menus={[
            {
              label: '変更する',
              type: 'file',
              onChangeFile: image => {
                this.props.updateMyselfUser({
                  model: {
                    galleries: myself.galleries.map((item, galleryIndex) => {
                      if (galleryIndex === this.state.selectedGalleryIndex) {
                        return image;
                      }

                      return item;
                    })
                  }
                });

                this.setState({
                  isOpenGalleryOpetationActionSheet: false,
                  selectedGalleryIndex: null
                });
              }
            },
            {
              label: '削除する',
              onClick: () => {
                this.props.updateMyselfUser({
                  model: {
                    galleries: myself.galleries.filter((item, galleryIndex) => {
                      return galleryIndex !== this.state.selectedGalleryIndex;
                    })
                  }
                });

                this.setState({
                  isOpenGalleryOpetationActionSheet: false,
                  selectedGalleryIndex: null
                });
              }
            }
          ]}
          onClose={() =>
            this.setState({
              isOpenGalleryOpetationActionSheet: false,
              selectedGalleryIndex: null
            })
          }
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModelMypageProfileGalleryEdit);
