import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../../../actions';
import ChatIndexItem from '../../../../components/Chat/ChatIndexItem';
import ChatIndexList from '../../../../components/Chat/ChatIndexList';
import ChatMessageFooter from '../../../../components/Chat/ChatMessageFooter';
import ChatMessageItem from '../../../../components/Chat/ChatMessageItem';
import ChatMessageList from '../../../../components/Chat/ChatMessageList';
import NavigationHeader from '../../../../components/Navigator/NavigationHeader';
import View from '../../../../components/View';
import Color from '../../../../constants/Color';

class SupporterMessageDetail extends React.Component {
  state = {
    message: '',
    isOpenScheduleModal: false
  };

  deleteBadges(props) {
    props.user.myself.badges
      .filter(badge => badge.consumerId === props.match.params.consumerId)
      .forEach(badge => {
        this.props.deleteBadge('supporter', this.props.auth.uid, badge.id);
      });
  }

  shouldComponentUpdate(nextProps) {
    if (
      nextProps.user.myself !== this.props.user.myself &&
      nextProps.user.myself
    ) {
      this.deleteBadges(nextProps);
    }

    return true;
  }

  componentDidMount() {
    this.props.getChat(this.props.match.params.chatId);

    this.props.subscribeChatHistory(this.props.match.params.chatId);

    if (this.props.user.myself) {
      this.deleteBadges(this.props);
    }

    this.props.subscribeBadge('supporter', this.props.auth.uid);
  }

  componendWillUnmount() {
    this.props.unsubscribeChatHistory(this.props.match.params.chatId);

    this.props.unsubscribeBadge(this.props.auth.uid);
  }

  renderChatList() {
    const result = this.state.searcher.search(this.state.searchText);

    return (
      <ChatIndexList
        data={result}
        renderItem={({ item }) => {
          return <ChatIndexItem {...item} selfType="supporter" />;
        }}
      />
    );
  }

  render() {
    if (!this.props.user.myself) {
      return null;
    }

    const { myself } = this.props.user;

    const chat = this.props.chat.hash[this.props.match.params.chatId];

    if (!chat) {
      return null;
    }

    const otherMembers = chat.members.filter(
      member => member.id !== this.props.auth.uid
    );

    let isGroup = otherMembers.length > 1;

    let opponent = null;

    if (!isGroup) {
      opponent = otherMembers[0];
    }

    return (
      <View
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          height: '100%',
          backgroundColor: Color.white
        }}
      >
        <NavigationHeader
          user={opponent}
          onClickUser={() => {
            this.props.history.push(`/supporter/consumer/${opponent.id}`);
          }}
          goBack={this.props.history.goBack}
        />
        <ChatMessageList
          data={this.props.chatHistory.list.map(
            id => this.props.chatHistory.hash[id]
          )}
          renderItem={({ item }) => {
            const isSelf = item.senderId === this.props.auth.uid;

            const user = myself;

            const opponent = chat.members.find(
              member => member.id === item.senderId
            );

            return (
              <ChatMessageItem
                selfType="supporter"
                key={item.id}
                isSelf={isSelf}
                {...item}
                user={user}
                opponent={opponent}
                style={{ margin: 16 }}
                onClickIcon={() => {
                  this.props.history.push(
                    `/supporter/model/${this.props.auth.uid}`
                  );
                }}
              />
            );
          }}
          isLoading={this.props.chatHistory.isGetLoading}
          onFetchMore={() => {
            if (this.props.chatHistory.list.length < 1) {
              return;
            }

            this.props.getChatHistories(this.props.match.params.chatId);
          }}
          style={{ display: 'block', height: '100%', overflow: 'scroll' }}
        />
        <ChatMessageFooter
          message={this.state.message}
          schedule={false}
          onChangeMessage={text => this.setState({ message: text })}
          onSubmitMessage={() => {
            this.props.createChatHistory(
              'supporter',
              this.props.match.params.chatId,
              {
                type: 'message',
                message: this.state.message
              }
            );

            this.setState({ message: '' });
          }}
          onClickSchedule={() => this.setState({ isOpenScheduleModal: true })}
          onUploadFile={file => {
            this.props.createChatHistory(
              'supporter',
              this.props.match.params.chatId,
              {
                type: 'file',
                file
              }
            );
          }}
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupporterMessageDetail);
