import { getCollection } from '../utils/Firebase';

export default class Chat {
  constructor(state = {}) {
    this.params = {
      id: state.id,
      members: state.members || [],
      createdAt: state.createdAt
    };
  }

  get object() {
    return this.params;
  }

  static async get(id) {
    const doc = await getCollection('chats')
      .doc(id)
      .get();

    const chat = new Chat({
      id: doc.id,
      ...doc.data()
    }).fromFirestore();

    return chat;
  }

  fromFirestore() {
    return {
      ...this.params,
      createdAt: this.params.createdAt && this.params.createdAt.toDate(),
      updatedAt: this.params.updatedAt && this.params.updatedAt.toDate()
    };
  }
}
