import * as types from '../constants/ActionTypes';
import ErrorMessages from '../constants/ErrorMessages';
import ReserveHistory from '../models/ReserveHistory';
import { getCollection, getDb, getTimestamp } from '../utils/Firebase';
import { request } from '../utils/Network';

let modelHistoryListeners = {};

// get like
function fetchGetReserveHistories() {
  return {
    type: types.FETCH_GET_RESERVE_HISTORIES
  };
}

function fetchGetReserveHistoriesSuccess(list) {
  return {
    type: types.FETCH_GET_RESERVE_HISTORIES_SUCCESS,
    state: {
      list
    }
  };
}

function fetchGetReserveHistoriesFailed(error) {
  return {
    type: types.FETCH_GET_RESERVE_HISTORIES_FAILED,
    error
  };
}

export function recoverGetReserveHistories() {
  return {
    type: types.FETCH_GET_RESERVE_HISTORIES_RECOVER
  };
}

export function getReserveHistories(consumerId, modelId) {
  return async dispatch => {
    dispatch(fetchGetReserveHistories());

    try {
      const { docs } = await getCollection('models')
        .doc(modelId)
        .collection('consumers')
        .doc(consumerId)
        .collection('reserveHistories')
        .get();

      const list = docs.map(doc => {
        return new ReserveHistory({
          id: doc.id,
          ...doc.data()
        }).fromFirestore();
      });

      return dispatch(fetchGetReserveHistoriesSuccess(list));
    } catch (error) {
      if (error) {
        return dispatch(
          fetchGetReserveHistoriesFailed({
            code: error.code,
            message: ErrorMessages[error.code]
          })
        );
      }
    }
  };
}

// create reserve
function fetchCreateReserveHistory() {
  return {
    type: types.FETCH_CREATE_RESERVE_HISTORY
  };
}

function fetchCreateReserveHistorySuccess(id, params) {
  return {
    type: types.FETCH_CREATE_RESERVE_HISTORY_SUCCESS,
    state: {
      id,
      params
    }
  };
}

function fetchCreateReserveHistoryFailed(error) {
  return {
    type: types.FETCH_CREATE_RESERVE_HISTORY_FAILED,
    error
  };
}

export function recoverCreateReserveHistory() {
  return {
    type: types.FETCH_CREATE_RESERVE_HISTORY_RECOVER
  };
}

export function createReserveHistory(type, consumerId, modelId, params) {
  return async (dispatch, getState) => {
    dispatch(fetchCreateReserveHistory());

    const timestamp = getTimestamp();

    const data = {
      ...params,
      status: 'pending',
      consumerAgreedAt: null,
      modelAgreedAt: type === 'model' ? timestamp : null,
      createdAt: timestamp
    };

    await getCollection('models')
      .doc(modelId)
      .collection('consumers')
      .doc(consumerId)
      .set({
        updatedAt: getTimestamp()
      });

    return getCollection('models')
      .doc(modelId)
      .collection('consumers')
      .doc(consumerId)
      .collection('reserveHistories')
      .add(data)
      .then(result => {
        return dispatch(fetchCreateReserveHistorySuccess(result.id, data));
      })
      .catch(error => {
        return dispatch(
          fetchCreateReserveHistoryFailed({
            code: error.code,
            message: ErrorMessages[error.code]
          })
        );
      });
  };
}

// update reserve
function fetchUpdateReserveHistory() {
  return {
    type: types.FETCH_UPDATE_RESERVE_HISTORY
  };
}

function fetchUpdateReserveHistorySuccess(id, params) {
  return {
    type: types.FETCH_UPDATE_RESERVE_HISTORY_SUCCESS,
    state: {
      id,
      params
    }
  };
}

function fetchUpdateReserveHistoryFailed(error) {
  return {
    type: types.FETCH_UPDATE_RESERVE_HISTORY_FAILED,
    error
  };
}

export function recoverUpdateReserveHistory() {
  return {
    type: types.FETCH_UPDATE_RESERVE_HISTORY_RECOVER
  };
}

export function updateReserveHistory(consumerId, modelId, reserveId, params) {
  return async (dispatch, getState) => {
    dispatch(fetchUpdateReserveHistory());

    const timestamp = getTimestamp();

    const reserveHistory = getCollection('models')
      .doc(modelId)
      .collection('consumers')
      .doc(consumerId)
      .collection('reserveHistories')
      .doc(reserveId);

    const db = getDb();

    const data = {
      ...params,
      updatedAt: timestamp
    };

    if (params.status === 'confirmed') {
      try {
        const { error } = await request(dispatch, 'POST', '/checkCredit', {
          params: {
            modelId,
            reserveId
          }
        });

        if (error) {
          return dispatch(
            fetchUpdateReserveHistoryFailed({
              code: error.code,
              message: ErrorMessages[error.code]
            })
          );
        }
      } catch (error) {
        return dispatch(
          fetchUpdateReserveHistoryFailed({
            code: error.code,
            message: ErrorMessages[error.code]
          })
        );
      }
    }

    return db
      .runTransaction(transaction => {
        return transaction.get(reserveHistory).then(doc => {
          if (!doc.exists) {
            throw new Error('document is not exist');
          }

          return transaction.update(reserveHistory, data);
        });
      })
      .then(() => {
        return dispatch(fetchUpdateReserveHistorySuccess(reserveId, data));
      })
      .catch(error => {
        return dispatch(
          fetchUpdateReserveHistoryFailed({
            code: error.code,
            message: ErrorMessages[error.code]
          })
        );
      });
  };
}

// subscribe chat history
function fetchSubscribeReserveHistories() {
  return {
    type: types.FETCH_SUBSCRIBE_RESERVE_HISTORY
  };
}

function fetchAddReserveHistorySuccess(id, params) {
  return {
    type: types.FETCH_ADD_RESERVE_HISTORY_SUCCESS,
    state: {
      id,
      params
    }
  };
}

function fetchModifyReserveHistorySuccess(id, params) {
  return {
    type: types.FETCH_MODIFY_RESERVE_HISTORY_SUCCESS,
    state: {
      id,
      params
    }
  };
}

export function subscribeReserveHistory(consumerId, modelId) {
  return (dispatch, getState) => {
    dispatch(fetchSubscribeReserveHistories());

    const listenerId = `${consumerId}/${modelId}`;

    modelHistoryListeners[listenerId] && modelHistoryListeners[listenerId]();

    modelHistoryListeners[listenerId] = getCollection('models')
      .doc(modelId)
      .collection('consumers')
      .doc(consumerId)
      .collection('reserveHistories')
      .onSnapshot(snapshot => {
        snapshot.docChanges().forEach(change => {
          const { doc } = change;

          if (change.type === 'added') {
            const history = new ReserveHistory({
              id: doc.id,
              ...doc.data()
            }).fromFirestore();

            return dispatch(fetchAddReserveHistorySuccess(doc.id, history));
          }

          if (change.type === 'modified') {
            const history = new ReserveHistory({
              id: doc.id,
              ...doc.data()
            }).fromFirestore();

            return dispatch(fetchModifyReserveHistorySuccess(doc.id, history));
          }

          if (change.type === 'removed') {
            console.log('Removed city: ', change.doc.data());
          }
        });
      });

    return null;
  };
}

// unsubscribe chat history
export function unsubscribeReserveHistory(consumerId, modelId) {
  return (dispatch, getState) => {
    const listenerId = `${consumerId}/${modelId}`;

    modelHistoryListeners[listenerId] && modelHistoryListeners[listenerId]();

    return null;
  };
}
