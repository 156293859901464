export default class ReserveHistory {
  constructor(state = {}) {
    this.params = {
      id: state.id,
      startAt: state.startAt,
      price: state.price,
      hours: state.hours,
      status: state.status,
      optionPrice: state.optionPrice,
      optionName: state.optionName,
      createdAt: state.createdAt,
      updatedAt: state.updatedAt,
      consumerAgreedAt: state.consumerAgreedAt,
      modelAgreedAt: state.modelAgreedAt
    };
  }

  get object() {
    return this.params;
  }

  fromFirestore() {
    return {
      ...this.params,
      createdAt: this.params.createdAt && this.params.createdAt.toDate(),
      updatedAt: this.params.updatedAt && this.params.updatedAt.toDate(),
      startAt: this.params.startAt && this.params.startAt.toDate(),
      consumerAgreedAt:
        this.params.consumerAgreedAt && this.params.consumerAgreedAt.toDate(),
      modelAgreedAt:
        this.params.modelAgreedAt && this.params.modelAgreedAt.toDate()
    };
  }
}
