import React from 'react';
import moment from 'moment';

import Color from '../../constants/Color';
import Label from './Label';
import MaterialIcon from '../../utils/MaterialIcon';
import Paragraph from '../Paragraph';
import Text from '../Text';
import View from '../View';

export default class DatetimeFormField extends React.Component {
  render() {
    const {
      label,
      labelWidth = 70,
      name,
      value,
      direction = 'row',
      verification = false,
      errorMessage,
      helpText,
      onChangeValue = () => {},
      items,
      staticControl,
      style = {},
      ...rest
    } = this.props;

    const containerStyle =
      direction === 'row' ? { flexDirection: 'row', alignItems: 'center' } : {};

    const verifyColor = !Boolean(errorMessage) ? Color.green : Color.warmGrey;

    return (
      <View style={{ ...style }}>
        <View style={containerStyle}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: direction === 'row' ? 0 : 8
            }}
          >
            <Label
              style={{
                minWidth: labelWidth
              }}
            >
              {label}
            </Label>
            {!staticControl && verification && (
              <View style={{ marginLeft: 4 }}>
                <MaterialIcon
                  icon="check_circle"
                  size={20}
                  color={verifyColor}
                />
              </View>
            )}
          </View>
          <View style={{ flex: 1 }}>
            {staticControl ? (
              <View>
                <Paragraph style={{ fontWeight: 'bold' }}>
                  {moment(value).format('MM/DD(ddd) HH:mm')}
                </Paragraph>
              </View>
            ) : (
              <View
                style={{
                  borderRadius: 4,
                  border: `solid 1px ${Color.middleGrey}`,
                  padding: 8
                }}
              >
                <input
                  type="datetime-local"
                  defaultValue={value}
                  onChange={({ currentTarget }) => {
                    onChangeValue(currentTarget.value);
                  }}
                  {...rest}
                />
              </View>
            )}
          </View>
        </View>
        {helpText && (
          <Text
            style={{
              marginTop: 4,
              fontSize: 11,
              color: Color.grey,
              paddingLeft: direction === 'row' ? labelWidth : 0
            }}
          >
            {helpText}
          </Text>
        )}
      </View>
    );
  }
}
