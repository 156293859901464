import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../../../../../actions';
import Button from '../../../../../../components/Button';
import SelectFormField from '../../../../../../components/Form/SelectFormField';
import Headline from '../../../../../../components/Headline';
import NavigationHeader from '../../../../../../components/Navigator/NavigationHeader';
import Note from '../../../../../../components/Note/Note';
import Paragraph from '../../../../../../components/Paragraph';
import Section from '../../../../../../components/Section/Section';
import View from '../../../../../../components/View';
import Model from '../../../../../../models/Model';
import Price from '../../../../../../models/model/Price';

class ModelMypageProfilePriceEdit extends React.Component {
  state = {
    ...new Model().object
  };

  constructor(props) {
    super(props);

    const { myself = {} } = this.props.user;

    this.state = {
      ...new Model(myself || {}).object
    };
  }

  shouldComponentUpdate(nextProps) {
    if (
      this.props.user.isGetMyselfLoading &&
      !nextProps.user.isGetMyselfLoading
    ) {
      const { myself } = nextProps.user;

      this.setState({
        ...new Model(myself).object
      });
    }

    return true;
  }

  render() {
    if (!this.props.user.myself) {
      return null;
    }

    return (
      <View>
        <NavigationHeader title="価格" goBack={this.props.history.goBack} />
        <Section
          style={{
            padding: 24
          }}
        >
          <SelectFormField
            label="価格 / 30分"
            direction="column"
            name="price"
            helpText="30分ごとの料金を設定してください。"
            items={Price.getItems()}
            defaultValue={this.state.price}
            onChangeValue={value => this.setState({ price: value })}
            style={{ marginBottom: 16 }}
          />
          <Note style={{ padding: 24, marginBottom: 32 }}>
            <Headline size={3} style={{ marginBottom: 8 }}>
              手取りの概算
            </Headline>
            <Paragraph onerow>
              {Math.floor(this.state.price)}P / 30分 - {this.state.price * 0.2}
              円 (手数料 20%)
            </Paragraph>
            <Paragraph onerow bold>
              = {Math.floor(this.state.price - this.state.price * 0.2)}円 / 30分
            </Paragraph>
          </Note>
          <Button
            disable={this.props.user.isUpdateLoading}
            onClick={() => {
              this.props.updateMyselfUser({
                model: {
                  price: this.state.price
                }
              });
            }}
          >
            保存する
          </Button>
        </Section>
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModelMypageProfilePriceEdit);
