import React from 'react';

import Color from '../../constants/Color';
import Text from '../Text';

export default function Label({ children, style = {} }) {
  return (
    <Text
      bold
      style={{
        color: Color.darkCeladonBlue,
        fontSize: 15,
        ...style
      }}
    >
      {children}
    </Text>
  );
}
