export default class NotificationHistory {
  constructor(state = {}) {
    this.params = {
      id: state.id,
      isRead: state.isRead,
      data: state.data,
      user: state.user,
      type: state.type,
      createdAt: state.createdAt,
      updatedAt: state.updatedAt
    };
  }

  get object() {
    return this.params;
  }

  fromFirestore() {
    return {
      ...this.params,
      createdAt: this.params.createdAt && this.params.createdAt.toDate(),
      updatedAt: this.params.updatedAt && this.params.updatedAt.toDate()
    };
  }
}
