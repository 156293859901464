import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import { ChevronLeftIconSvg } from '../../../../../utils/MaterialIconSvg';
import Model from '../../../../../models/Model';
import Color from '../../../../../constants/Color';
import Header from '../../../../../components/Header';
import ModelPreview from '../../../../../components/Model/ModelPreview';
import Text from '../../../../../components/Text';
import UserIcon from '../../../../../components/Icons/UserIcon';
import View from '../../../../../components/View';
import actions from '../../../../../actions';

class ModelMypageProfilePreview extends React.Component {
  state = {
    ...new Model().object
  };

  constructor(props) {
    super(props);

    const { myself = {} } = this.props.user;

    this.state = {
      ...new Model(myself || {}).object
    };
  }

  shouldComponentUpdate(nextProps) {
    if (
      this.props.user.isGetMyselfLoading &&
      !nextProps.user.isGetMyselfLoading
    ) {
      const { myself } = nextProps.user;

      this.setState({
        ...new Model(myself).object
      });
    }

    return true;
  }

  render() {
    const { myself } = this.props.user;

    if (!myself) {
      return null;
    }

    return (
      <View style={{ backgroundColor: Color.white }}>
        <Header
          title={
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <UserIcon
                source={myself.iconImage}
                size={20}
                style={{ marginRight: 8 }}
              />
              <Text style={{ fontSize: 17 }}>{myself.name}</Text>
            </View>
          }
          left={
            <View
              onClick={() =>
                this.props.history.push('/model/mypage/profile/edit')
              }
              style={{ padding: 4 }}
            >
              <ChevronLeftIconSvg
                fill={Color.black}
                width={24}
                height={24}
                viewBox="0 0 24 24"
                style={{ marginRight: 4 }}
              />
            </View>
          }
        />
        <ModelPreview data={myself} />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModelMypageProfilePreview);
