import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import {
  BellOutlineIconSvg,
  CreditCardOffIconSvg,
  SettingsIconSvg
} from '../../../../utils/MaterialIconSvg';
import Color from '../../../../constants/Color';
import ConsumerTabContainer from '../../../../containers/consumer/ConsumerTabContainer';
import Header from '../../../../components/Header';
import PaymentModalBox from '../../../../containers/payment/PaymentModalBox';
import Section from '../../../../components/Section/Section';
import SectionTransitItem from '../../../../components/Section/SectionTransitItem';
import Text from '../../../../components/Text';
import UserIcon from '../../../../components/Icons/UserIcon';
import View from '../../../../components/View';
import actions from '../../../../actions';

class UserMypageIndex extends React.Component {
  state = {
    isOpenPuchaseModal: false
  };

  render() {
    if (!this.props.user.myself) {
      return null;
    }

    return (
      <ConsumerTabContainer activeIndex={2} history={this.props.history}>
        <Header
          title="マイページ"
          left={
            <View
              style={{ padding: 4 }}
              onClick={() => this.props.history.goBack()}
            >
              <BellOutlineIconSvg
                fill={Color.black}
                width={24}
                height={24}
                viewBox="0 0 24 24"
                style={{ marginRight: 4 }}
              />
            </View>
          }
          right={
            <View
              style={{ padding: 8 }}
              onClick={() => this.props.history.push('/user/mypage/setting')}
            >
              <SettingsIconSvg
                fill={Color.black}
                width={20}
                height={20}
                viewBox="0 0 24 24"
                style={{ marginRight: 4 }}
              />
            </View>
          }
        />
        <Section
          style={{ alignItems: 'center', padding: 32, marginBottom: 16 }}
        >
          <UserIcon
            source={this.props.user.myself.iconImage}
            size={128}
            style={{ marginBottom: 24 }}
          />
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text bold style={{ fontSize: 17 }}>
              {this.props.user.myself.name}
            </Text>
            {!this.props.user.myself.cardRegisterAt && (
              <View onClick={() => this.setState({ isOpenPuchaseModal: true })}>
                <CreditCardOffIconSvg
                  fill={Color.red}
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  style={{ marginLeft: 8 }}
                />
              </View>
            )}
          </View>
        </Section>
        <Section style={{ marginBottom: 16 }}>
          <SectionTransitItem
            title="プロフィール設定"
            onClick={() => this.props.history.push('/user/mypage/profile/edit')}
          />
          <SectionTransitItem
            title="ポイント履歴"
            onClick={() => this.props.history.push('/user/mypage/point')}
          />
          <SectionTransitItem
            title="撮影履歴"
            onClick={() => this.props.history.push('/user/mypage/session')}
          />
          <SectionTransitItem
            title="ポイント購入"
            onClick={() => this.props.history.push('/user/mypage/shop')}
          />
          <SectionTransitItem
            title="カード情報"
            onClick={() =>
              this.props.history.push('/user/mypage/paymentInfo/index')
            }
          />
          <SectionTransitItem
            title="その他"
            onClick={() => this.props.history.push('/user/mypage/other')}
          />
        </Section>
        <Section>
          <SectionTransitItem
            title="ログアウト"
            onClick={() => this.props.signOut()}
          />
        </Section>
        <PaymentModalBox
          visible={this.state.isOpenPuchaseModal}
          onClose={() => {
            this.setState({ isOpenPuchaseModal: false });
          }}
        />
      </ConsumerTabContainer>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserMypageIndex);
