import React from 'react';

import Color from '../../constants/Color';
import View from '../View';

export default function Card({
  gradient = false,
  children,
  header,
  headerColor = Color.red,
  rightHeader = null,
  style = {},
  containerStyle = {}
}) {
  return (
    <View
      className={gradient ? 'gradient-card' : 'none'}
      style={{
        backgroundColor: Color.white,
        borderLeft: `solid ${Color.lightGrey} 1px`,
        borderTop: `solid ${Color.lightGrey} 1px`,
        boxShadow: '0px 2px 16px rgba(60,65,82,0.3)',
        padding: 24,
        ...style
      }}
    >
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        {header ? (
          <View style={{ flexDirection: 'row', margin: '-8px 0 0 8px' }}>
            <View
              style={{
                backgroundColor: headerColor,
                boxShadow: '0px 0px 4px rgba(0,0,0,0.2)',
                padding: 8
              }}
            >
              {header}
            </View>
          </View>
        ) : (
          <View />
        )}
        {rightHeader && (
          <View style={{ margin: '8px 8px 0 0 ' }}>{rightHeader}</View>
        )}
      </View>
      <View style={{ ...containerStyle }}>{children}</View>
    </View>
  );
}
