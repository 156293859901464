import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../../../actions';
import Button from '../../../../components/Button';
import ChatIndexItem from '../../../../components/Chat/ChatIndexItem';
import ChatIndexList from '../../../../components/Chat/ChatIndexList';
import ChatMessageFooter from '../../../../components/Chat/ChatMessageFooter';
import ChatMessageItem from '../../../../components/Chat/ChatMessageItem';
import ChatMessageList from '../../../../components/Chat/ChatMessageList';
import NavigationHeader from '../../../../components/Navigator/NavigationHeader';
import ReserveItem from '../../../../components/Schedule/ReserveItem';
import ScheduleModalBox from '../../../../components/Schedule/ScheduleModalBox';
import View from '../../../../components/View';
import Color from '../../../../constants/Color';
import ReserveHeader from '../../../../containers/reserve/ReserveHeader';
import Geolocation from '../../../../utils/Geolocation';

class ModelMessageDetail extends React.Component {
  state = {
    message: '',
    isOpenScheduleModal: false
  };

  deleteBadges(props) {
    props.user.myself.badges
      .filter(badge => badge.consumerId === props.match.params.consumerId)
      .forEach(badge => {
        this.props.deleteBadge('model', this.props.auth.uid, badge.id);
      });
  }

  shouldComponentUpdate(nextProps) {
    if (
      nextProps.user.myself !== this.props.user.myself &&
      nextProps.user.myself
    ) {
      this.deleteBadges(nextProps);
    }

    return true;
  }

  componentDidMount() {
    this.props.getChat(this.props.match.params.chatId);

    this.props.subscribeChatHistory(this.props.match.params.chatId);

    if (this.props.user.myself) {
      this.deleteBadges(this.props);
    }

    this.props.subscribeBadge('model', this.props.auth.uid);
  }

  componendWillUnmount() {
    this.props.unsubscribeChatHistory(this.props.match.params.chatId);

    this.props.unsubscribeBadge(this.props.auth.uid);
  }

  renderChatList() {
    const result = this.state.searcher.search(this.state.searchText);

    return (
      <ChatIndexList
        data={result}
        renderItem={({ item }) => {
          return <ChatIndexItem {...item} selfType="model" />;
        }}
      />
    );
  }

  render() {
    if (!this.props.user.myself) {
      return null;
    }

    const { myself } = this.props.user;

    const chat = this.props.chat.hash[this.props.match.params.chatId];

    if (!chat) {
      return null;
    }

    const otherMembers = chat.members.filter(
      member => member.id !== this.props.auth.uid
    );

    let isGroup = otherMembers.length > 1;

    let opponent = null;

    if (!isGroup) {
      opponent = otherMembers[0];
    }

    const reserveData = this.props.reserveHistory.list
      .map(id => this.props.reserveHistory.hash[id])
      .filter(
        datum =>
          datum.status !== 'canceled' &&
          datum.status !== 'penaltyCanceled' &&
          datum.status !== 'dismissed'
      );

    return (
      <View
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          height: '100%',
          backgroundColor: Color.white
        }}
      >
        <NavigationHeader
          user={opponent}
          onClickUser={() => {
            if (opponent.roles.includes('consumer')) {
              this.props.history.push(`/model/find/consumer/${opponent.id}`);
            }
          }}
          goBack={this.props.history.goBack}
        />
        {isGroup ? null : (
          <ReserveHeader
            data={reserveData}
            consumerId={opponent.id}
            modelId={this.props.auth.uid}
            renderItem={({ item }) => {
              return (
                <ReserveItem
                  selfType="model"
                  {...item}
                  onCancel={() => {
                    this.props.updateReserveHistory(
                      opponent.id,
                      this.props.auth.uid,
                      item.id,
                      {
                        status: 'canceled',
                        modelAgreedAt: null
                      }
                    );
                  }}
                  onJoin={() => {
                    Geolocation.getPosition()
                      .then(coords => {
                        this.props.updateReserveHistory(
                          opponent.id,
                          this.props.auth.uid,
                          item.id,
                          {
                            status: 'joined',
                            startedAt: moment().toDate(),
                            joinedCoords: {
                              latitude: coords.latitude,
                              longitude: coords.longitude,
                              accuracy: coords.accuracy
                            }
                          }
                        );
                      })
                      .catch(error => {
                        alert(error.message);
                      });
                  }}
                  onDismiss={() => {
                    Geolocation.getPosition()
                      .then(coords => {
                        this.props.updateReserveHistory(
                          opponent.id,
                          this.props.auth.uid,
                          item.id,
                          {
                            status: 'dismissed',
                            dismissedAt: moment().toDate(),
                            dismissedCoords: {
                              latitude: coords.latitude,
                              longitude: coords.longitude,
                              accuracy: coords.accuracy
                            }
                          }
                        );
                      })
                      .catch(error => {
                        alert(error.message);
                      });
                  }}
                  onCancelAndPay={() => {
                    this.props.updateReserveHistory(
                      opponent.id,
                      this.props.auth.uid,
                      item.id,
                      {
                        status: 'penaltyCanceled',
                        modelAgreedAt: null
                      }
                    );
                  }}
                />
              );
            }}
          />
        )}
        <ChatMessageList
          data={this.props.chatHistory.list.map(
            id => this.props.chatHistory.hash[id]
          )}
          renderItem={({ item }) => {
            const isSelf = item.senderId === this.props.auth.uid;

            const user = myself;

            const opponent = chat.members.find(
              member => member.id === item.senderId
            );

            return (
              <ChatMessageItem
                selfType="model"
                key={item.id}
                isSelf={isSelf}
                {...item}
                user={user}
                opponent={opponent}
                style={{ margin: 16 }}
                onClickIcon={() => {
                  this.props.history.push(
                    `/model/model/${this.props.auth.uid}`
                  );
                }}
              />
            );
          }}
          isLoading={this.props.chatHistory.isGetLoading}
          onFetchMore={() => {
            if (this.props.chatHistory.list.length < 1) {
              return;
            }

            this.props.getChatHistories(this.props.match.params.chatId);
          }}
          style={{ display: 'block', height: '100%', overflow: 'scroll' }}
        />
        <ChatMessageFooter
          message={this.state.message}
          onChangeMessage={text => this.setState({ message: text })}
          onSubmitMessage={() => {
            this.props.createChatHistory(
              'model',
              this.props.match.params.chatId,
              {
                type: 'message',
                message: this.state.message
              }
            );

            this.setState({ message: '' });
          }}
          schedule={reserveData.length < 1}
          onClickSchedule={() => this.setState({ isOpenScheduleModal: true })}
          onUploadFile={file => {
            this.props.createChatHistory(
              'model',
              this.props.match.params.chatId,
              {
                type: 'file',
                file
              }
            );
          }}
        />
        <ScheduleModalBox
          visible={this.state.isOpenScheduleModal}
          defaultPrice={myself.price}
          model={myself}
          onClose={() => {
            this.setState({ isOpenScheduleModal: false });
          }}
          isLoading={this.props.reserveHistory.isCreateLoading}
          renderSubmitButton={params => {
            return (
              <Button
                onClick={() => {
                  this.props.createReserveHistory(
                    'model',
                    opponent.id,
                    this.props.auth.uid,
                    {
                      ...params,
                      chatId: this.props.match.params.chatId
                    }
                  );

                  this.setState({ isOpenScheduleModal: false });
                }}
              >
                日程を提案する
              </Button>
            );
          }}
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModelMessageDetail);
