export default class ActionError extends Error {
  constructor({ code, message }) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(message);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ActionError);
    }

    // Custom debugging information
    this.code = code;
  }
}
