import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import ModelTabContainer from '../../../../containers/model/ModelTabContainer';
import ChatIndexItem from '../../../../components/Chat/ChatIndexItem';
import ChatIndexList from '../../../../components/Chat/ChatIndexList';
import FuzzySearch from '../../../../utils/FuzzySearch';
import SearchBox from '../../../../components/Search/SearchBox';
import actions from '../../../../actions';

class ModelMessageIndex extends React.Component {
  state = {
    searchText: '',
    searcher: null
  };

  componentDidMount() {
    // this.props.getChatIndexes('model');
    this.props.subscribeBadge('model', this.props.auth.uid);

    this.props.subscribeChatIndex('model');
  }

  componentWillUnmount() {
    this.props.unsubscribeBadge(this.props.auth.uid);

    this.props.unsubscribeChatIndex('model');
  }

  renderChatList() {
    const { myself } = this.props.user;

    if (!this.props.user.myself) {
      return null;
    }

    const histories = this.props.chatIndex.list.map(
      id => this.props.chatIndex.hash[id]
    );

    const searcher = new FuzzySearch(histories, ['user.name'], {
      caseSensitive: true
    });

    const result = searcher.search(this.state.searchText);

    return (
      <ChatIndexList
        data={result}
        renderItem={({ item }) => {
          return (
            <ChatIndexItem
              key={item.id}
              badgeNum={myself.badges.reduce(
                (acc, badge) =>
                  item.users.map(user => user.id).includes(badge.fromUser.id)
                    ? acc + 1
                    : 0,
                0
              )}
              {...item}
              selfType="model"
              onClick={() => {
                this.props.history.push(`/model/message/${item.chatId}`);
              }}
              onClickIcon={() => {
                if (item.users[0].roles.includes('consumer')) {
                  this.props.history.push(
                    `/model/find/consumer/${item.users[0].id}`
                  );
                }
              }}
            />
          );
        }}
      />
    );
  }

  render() {
    const { myself } = this.props.user;

    if (!myself) {
      return null;
    }

    return (
      <ModelTabContainer activeIndex={1} history={this.props.history}>
        <SearchBox
          onChangeText={text => {
            this.setState({ searchText: text });
          }}
        />
        {this.renderChatList()}
      </ModelTabContainer>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModelMessageIndex);
