import React from 'react';

import { MagnifyIconSvg } from '../../utils/MaterialIconSvg';
import Color from '../../constants/Color';
import Style from '../../constants/Style';
import TextField from '../Form/TextField';
import View from '../View';

export default class SearchBox extends React.Component {
  render() {
    const { onChangeText = () => {} } = this.props;

    return (
      <View
        style={{
          backgroundColor: Color.paleWhite,
          ...Style.shadow.lightTiny,
          padding: 8
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: Color.smokeWhite,
            border: `1px solid ${Color.darkGrey}`,
            borderRadius: 8,
            paddingRight: 4
          }}
        >
          <MagnifyIconSvg
            fill={Color.darkGrey}
            width={24}
            height={24}
            viewBox="0 0 24 24"
            style={{ marginLeft: 8, marginRight: 4 }}
          />
          <TextField
            style={{
              flex: 1,
              backgroundColor: Color.smokeWhite,
              borderColor: Color.transparent
            }}
            inputStyle={{ backgroundColor: Color.smokeWhite }}
            placeholder="ニックネームで検索"
            onChange={onChangeText}
          />
        </View>
      </View>
    );
  }
}
