import { getStorage } from '../utils/Firebase';

export default class ChatMessage {
  constructor(state = {}) {
    this.params = {
      id: state.id,
      data: state.data || {},
      senderId: state.senderId,
      senderType: state.senderType,
      createdAt: state.createdAt,
      updatedAt: state.updatedAt
    };
  }

  get object() {
    return this.params;
  }

  async fromFirestore() {
    let { data } = this.params;

    if (this.params.data.type === 'file') {
      const uri = await getStorage()
        .refFromURL(data.source.uri)
        .getDownloadURL();

      data = {
        ...data,
        source: {
          ...data.source,
          uri
        }
      };
    }

    return {
      ...this.params,
      data,
      createdAt: this.params.createdAt && this.params.createdAt.toDate(),
      updatedAt: this.params.updatedAt && this.params.updatedAt.toDate()
    };
  }
}
