import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../../../../../actions';
import Button from '../../../../../../components/Button';
import TextFormField from '../../../../../../components/Form/TextFormField';
import NavigationHeader from '../../../../../../components/Navigator/NavigationHeader';
import Section from '../../../../../../components/Section/Section';
import View from '../../../../../../components/View';
import User from '../../../../../../models/User';

class UserMypageProfileNameEdit extends React.Component {
  state = {
    ...new User().object
  };

  constructor(props) {
    super(props);

    const { myself = {} } = this.props.user;

    this.state = {
      ...new User(myself || {}).object
    };
  }

  shouldComponentUpdate(nextProps) {
    if (
      this.props.user.isGetMyselfLoading &&
      !nextProps.user.isGetMyselfLoading
    ) {
      const { myself } = nextProps.user;

      this.setState({
        ...new User(myself).object
      });
    }

    return true;
  }

  render() {
    if (!this.props.user.myself) {
      return null;
    }

    return (
      <View>
        <NavigationHeader title="名前" goBack={this.props.history.goBack} />
        <Section
          style={{
            padding: 24
          }}
        >
          <TextFormField
            value={this.state.name}
            name="name"
            direction="column"
            placeholder="名前"
            onChangeText={name => {
              this.setState({
                name
              });
            }}
            label="名前"
            style={{ marginBottom: 16 }}
          />
          <Button
            disable={this.props.user.isUpdateLoading}
            onClick={() => {
              this.props.updateMyselfUser({
                name: this.state.name
              });
            }}
          >
            保存する
          </Button>
        </Section>
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserMypageProfileNameEdit);
