import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../../../../actions';
import NavigationHeader from '../../../../../components/Navigator/NavigationHeader';
import RewardHistoryItem from '../../../../../components/RewardHistory/RewardHistoryItem';
import Section from '../../../../../components/Section/Section';
import Text from '../../../../../components/Text';
import View from '../../../../../components/View';
import Color from '../../../../../constants/Color';
import {
  ChevronLeftIconSvg,
  ChevronRightIconSvg
} from '../../../../../utils/MaterialIconSvg';
import { moment } from '../../../../../utils/Time';

class ModelMypageRewardIndex extends React.Component {
  state = {
    currentDate: moment()
      .startOf('month')
      .toDate()
  };

  componentDidMount() {
    this.props.getRewardHistories();
  }

  render() {
    const { myself } = this.props.user;

    if (!myself) {
      return null;
    }

    const now = moment().toDate();

    const prevMonth = moment(this.state.currentDate)
      .clone()
      .subtract(1, 'month')
      .toDate();

    const nextMonth = moment(this.state.currentDate)
      .clone()
      .add(1, 'month')
      .toDate();

    const isMinMonth =
      moment(now)
        .subtract(6, 'month')
        .toDate() > prevMonth;

    return (
      <View>
        <NavigationHeader
          title="獲得ポイント履歴"
          goBack={this.props.history.goBack}
        />
        <Section
          style={{
            position: 'relative',
            height: 120,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 16
          }}
        >
          <View
            onClick={() => {
              if (isMinMonth) {
                return;
              }

              this.setState(
                {
                  currentDate: prevMonth
                },
                () =>
                  this.props.getRewardHistories({
                    where: ['createdAt', '>=', prevMonth]
                  })
              );
            }}
            style={{ padding: 4, zIndex: 1 }}
          >
            <ChevronLeftIconSvg
              fill={isMinMonth ? Color.lightGrey : Color.black}
              width={24}
              height={24}
              viewBox="0 0 24 24"
              style={{ marginRight: 4 }}
            />
          </View>
          <View
            onClick={() => {
              if (nextMonth > now) {
                return;
              }

              this.setState(
                {
                  currentDate: nextMonth
                },
                () =>
                  this.props.getRewardHistories({
                    where: ['createdAt', '>=', nextMonth]
                  })
              );
            }}
            style={{ padding: 4, zIndex: 1 }}
          >
            <ChevronRightIconSvg
              fill={nextMonth > now ? Color.lightGrey : Color.black}
              width={24}
              height={24}
              viewBox="0 0 24 24"
              style={{ marginRight: 4 }}
            />
          </View>
          <View
            style={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Text bold style={{ color: Color.darkGrey, fontSize: 15 }}>
              {this.state.currentDate.getMonth() + 1}月の獲得ポイント
            </Text>
            <Text bold style={{ fontSize: 17 }}>
              <Text style={{ color: Color.mazenta, fontSize: 32 }}>
                {this.props.rewardHistory.list.reduce((acc, id) => {
                  const history = this.props.rewardHistory.hash[id];

                  return history.value + acc;
                }, 0)}
              </Text>
              P
            </Text>
          </View>
        </Section>
        <Section style={{ marginBottom: 16 }}>
          {this.props.rewardHistory.list
            .map(id => this.props.rewardHistory.hash[id])
            .map(item => {
              return <RewardHistoryItem {...item} />;
            })}
        </Section>
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModelMypageRewardIndex);
