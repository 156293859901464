import React from 'react';
import moment from 'moment';

import Color from '../../constants/Color';
import SectionTransitItem from '../Section/SectionTransitItem';
import Text from '../Text';
import UserIcon from '../Icons/UserIcon';
import View from '../View';

export default function SessionHistoryItem({
  user,
  hours,
  startAt,
  startedAt,
  onClick = () => {},
  style = {}
}) {
  return (
    <SectionTransitItem
      title={
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <UserIcon
            source={user.iconImage}
            size={32}
            style={{ marginRight: 16 }}
          />
          <Text>{user.name}</Text>
        </View>
      }
      content={
        <View style={{ alignItems: 'flex-end' }}>
          <Text style={{ fontSize: 15, color: Color.black }}>
            {moment(startAt).format('MM月DD日(ddd)')}
          </Text>
          <Text style={{ color: Color.darkGrey }}>
            {moment(startAt).format('HH:mm〜')}
          </Text>
        </View>
      }
      style={{ borderBottom: `1px solid ${Color.lightGrey}`, padding: 16 }}
      onClick={onClick}
    />
  );
}
