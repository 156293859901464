import { getCollection, getDb, uploadImages } from '../utils/Firebase';

export default class Consumer {
  constructor(state = {}) {
    this.params = {
      roles: ['consumer'],
      pr: state.pr || '',
      galleries: state.galleries || [],
      address: state.address || 'unselected',
      facebookId: state.facebookId,
      height: state.height || '',
      birthplace: state.birthplace || 'unselected',
      academicBackground: state.academicBackground || 'unselected',
      annualIncome: state.annualIncome || 'unselected',
      brotherAndSister: state.brotherAndSister || 'unselected',
      job: state.job || 'unselected',
      sessionCount: state.sessionCount || 0,
      cardRegisterAt: state.cardRegisterAt || null,
      createdAt: state.createdAt,
      updatedAt: state.updatedAt
    };
  }

  get object() {
    return this.params;
  }

  fromFirestore() {
    return {
      ...this.params,
      cardRegisterAt:
        this.params.cardRegisterAt && this.params.cardRegisterAt.toDate(),
      createdAt: this.params.createdAt && this.params.createdAt.toDate(),
      updatedAt: this.params.updatedAt && this.params.updatedAt.toDate()
    };
  }

  static async get(id) {
    const doc = await getCollection('consumers')
      .doc(id)
      .get();

    const consumer = new Consumer({
      id: doc.id,
      ...doc.data()
    }).fromFirestore();

    return consumer;
  }

  static async update(id, params) {
    const db = getDb();

    const consumers = getCollection('consumers').doc(id);

    const { galleries = null } = params;

    return uploadImages(`/consumers/${id}/galleries`, galleries).then(
      async imagePaths => {
        return db.runTransaction(transaction => {
          return transaction.get(consumers).then(doc => {
            if (!doc.exists) {
              throw new Error('update/not-exist');
            }

            const { createdAt, updatedAt, ...rest } = params;

            const { galleries: docGalleries = [] } = doc.data();

            return transaction.update(consumers, {
              ...rest,
              galleries: imagePaths
                ? imagePaths.map(path => ({ uri: path }))
                : docGalleries
            });
          });
        });
      }
    );
  }
}
