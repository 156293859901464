import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import Footer from '../../../components/FooterDesktop';
import PageHeader from '../../../containers/page/PageDesktopHeader';
import View from '../../../components/View';
import actions from '../../../actions';

class Top extends React.Component {
  state = {
    activeIndex: 0
  };

  render() {
    return (
      <View>
        <PageHeader />
        <Footer />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Top);
