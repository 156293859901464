import * as types from '../constants/ActionTypes';
import ErrorMessages from '../constants/ErrorMessages';
import Chat from '../models/Chat';
import Consumer from '../models/Consumer';
import Model from '../models/Model';
import User from '../models/User';
import { getCollection } from '../utils/Firebase';
import Supporter from '../models/Supporter';

// get chat index
function fetchGetChat() {
  return {
    type: types.FETCH_GET_CHAT
  };
}

function fetchGetChatSuccess(id, params) {
  return {
    type: types.FETCH_GET_CHAT_SUCCESS,
    state: {
      id,
      params
    }
  };
}

function fetchGetChatFailed(error) {
  return {
    type: types.FETCH_GET_CHAT_FAILED,
    error
  };
}

export function recoverGetChat() {
  return {
    type: types.FETCH_GET_CHAT_RECOVER
  };
}

export function getChat(chatId) {
  return (dispatch, getState) => {
    dispatch(fetchGetChat());

    let query = getCollection('chats').doc(chatId);

    return query
      .get()
      .then(async doc => {
        const chat = new Chat({
          id: doc.id,
          ...doc.data()
        }).fromFirestore();

        const members = await Promise.all(
          chat.members.map(async member => {
            const user = await User.get(member.id);

            let roledUser;

            if (member.role === 'model') {
              roledUser = await Model.get(member.id);
            } else if (member.role === 'consumer') {
              roledUser = await Consumer.get(member.id);
            } else if (member.role === 'supporter') {
              roledUser = await Supporter.get(member.id);
            }

            return {
              ...user,
              ...roledUser
            };
          })
        );

        const params = {
          ...chat,
          members
        };

        return dispatch(fetchGetChatSuccess(doc.id, params));
      })
      .catch(error => {
        if (error) {
          return dispatch(
            fetchGetChatFailed({
              code: error.code,
              message: ErrorMessages[error.code]
            })
          );
        }
      });
  };
}
