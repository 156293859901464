import moment from 'moment';

export default class Session {
  static calculateOverMinutes({ startedAt, dismissedAt, hours }) {
    const dismissedDate = moment(dismissedAt).seconds(0);

    const startedDate = moment(startedAt).seconds(0);

    const baseMinutes = hours * 60;

    const allMinutes = dismissedDate.diff(startedDate, 'minutes');

    return Math.max(allMinutes - baseMinutes, 0);
  }

  static calculateOverPrice({ price, startedAt, dismissedAt, hours }) {
    const overMinutes = Session.calculateOverMinutes({
      startedAt,
      dismissedAt,
      hours
    });

    const overMinutesSet = Math.floor(overMinutes / 15);

    const setPrice = (price / 2) * 1.3;

    return overMinutesSet * setPrice;
  }

  static calculateBillingPrice({
    price,
    startedAt,
    dismissedAt,
    hours,
    optionPrice = 0
  }) {
    const dismissedDate = moment(dismissedAt).seconds(0);

    const startedDate = moment(startedAt).seconds(0);

    const baseMinutes = hours * 60;

    const allMinutes = dismissedDate.diff(startedDate, 'minutes');

    if (allMinutes <= baseMinutes) {
      return price * hours * 2 + optionPrice;
    }

    const overMinutes = allMinutes - baseMinutes;

    const overMinutesSet = Math.floor(overMinutes / 15);

    const setPrice = (price / 2) * 1.3;

    return price * hours * 2 + overMinutesSet * setPrice + optionPrice;
  }

  static getIsOverCancelLimit({ startAt, now }) {
    return (
      moment(startAt)
        .subtract(2, 'days')
        .startOf('date')
        .diff(moment(now).startOf('date')) < 0
    );
  }
}
