import { getCollection, getTimestamp } from '../utils/Firebase';
import ErrorMessages from '../constants/ErrorMessages';
import ReviewHistory from '../models/ReviewHistory';
import User from '../models/User';
import * as types from '../constants/ActionTypes';

// get reviewHistories
function fetchGetReviewHistories() {
  return {
    type: types.FETCH_GET_REVIEW_HISTORIES
  };
}

function fetchGetReviewHistoriesSuccess(list) {
  return {
    type: types.FETCH_GET_REVIEW_HISTORIES_SUCCESS,
    state: {
      list
    }
  };
}

function fetchGetReviewHistoriesFailed(error) {
  return {
    type: types.FETCH_GET_REVIEW_HISTORIES_FAILED,
    error
  };
}

export function recoverGetReviewHistories() {
  return {
    type: types.FETCH_GET_REVIEW_HISTORIES_RECOVER
  };
}

export function getReviewHistories(type, uid) {
  return (dispatch, getState) => {
    dispatch(fetchGetReviewHistories());

    let query = getCollection(type === 'consumer' ? 'consumers' : 'models')
      .doc(uid)
      .collection('reviewHistories');

    return query
      .get()
      .then(async ({ docs }) => {
        const list = await Promise.all(
          docs.map(async doc => {
            const params = {
              id: doc.id,
              ...doc.data()
            };

            const reviewer = await User.get(params.reviewerId);

            return new ReviewHistory({
              ...params,
              reviewer
            }).fromFirestore();
          })
        );

        return dispatch(fetchGetReviewHistoriesSuccess(list));
      })
      .catch(error => {
        if (error) {
          return dispatch(
            fetchGetReviewHistoriesFailed({
              code: error.code,
              message: ErrorMessages[error.code]
            })
          );
        }
      });
  };
}

// get review history
function fetchGetReviewHistory() {
  return {
    type: types.FETCH_GET_REVIEW_HISTORY
  };
}

function fetchGetReviewHistorySuccess(id, params) {
  return {
    type: types.FETCH_GET_REVIEW_HISTORY_SUCCESS,
    state: {
      id,
      params
    }
  };
}

function fetchGetReviewHistoryFailed(error) {
  return {
    type: types.FETCH_GET_REVIEW_HISTORY_FAILED,
    error
  };
}

export function recoverGetReviewHistory() {
  return {
    type: types.FETCH_GET_REVIEW_HISTORY_RECOVER
  };
}

export function getReviewHistory(type, uid, reviewHistoryId) {
  return dispatch => {
    dispatch(fetchGetReviewHistory());

    let query = getCollection(type === 'model' ? 'models' : 'consumers')
      .doc(uid)
      .collection('reviewHistories')
      .doc(reviewHistoryId);

    return query
      .get()
      .then(async doc => {
        let params = null;

        if (doc.exists) {
          const reviewer = await User.get(doc.data().reviewerId);

          const reviewHistory = new ReviewHistory({
            id: doc.id,
            ...doc.data(),
            reviewer
          }).fromFirestore();

          params = {
            ...reviewHistory
          };
        }

        return dispatch(fetchGetReviewHistorySuccess(reviewHistoryId, params));
      })
      .catch(error => {
        if (error) {
          return dispatch(
            fetchGetReviewHistoryFailed({
              code: error.code,
              message: ErrorMessages[error.code]
            })
          );
        }
      });
  };
}

// create like
function fetchCreateReviewHistory() {
  return {
    type: types.FETCH_CREATE_REVIEW_HISTORY
  };
}

function fetchCreateReviewHistorySuccess(id, params) {
  return {
    type: types.FETCH_CREATE_REVIEW_HISTORY_SUCCESS,
    state: {
      id,
      params
    }
  };
}

function fetchCreateReviewHistoryFailed(error) {
  return {
    type: types.FETCH_CREATE_REVIEW_HISTORY_FAILED,
    error
  };
}

export function recoverCreateReviewHistory() {
  return {
    type: types.FETCH_CREATE_REVIEW_HISTORY_RECOVER
  };
}

export function upsertReviewHistory(type, uid, historyId, params) {
  return (dispatch, getState) => {
    dispatch(fetchCreateReviewHistory());

    const reviewHistory = getCollection(
      type === 'consumer' ? 'consumers' : 'models'
    )
      .doc(uid)
      .collection('reviewHistories')
      .doc(historyId);

    return reviewHistory
      .set({
        ...params,
        reviewedAt: getTimestamp()
      })
      .then(() => {
        return dispatch(fetchCreateReviewHistorySuccess(historyId, params));
      })
      .catch(error => {
        return dispatch(
          fetchCreateReviewHistoryFailed({
            code: error.code,
            message: ErrorMessages[error.code]
          })
        );
      });
  };
}
