import { persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/es/storage';

import application from './application';
import auth from './auth';
import chat from './chat';
import chatHistory from './chatHistory';
import chatIndex from './chatIndex';
import config from './config';
import consumer from './consumer';
import documentHistory from './documentHistory';
import layout from './layout';
import like from './like';
import model from './model';
import notificationHistory from './notificationHistory';
import paymentInfo from './paymentInfo';
import pointHistory from './pointHistory';
import reserveHistory from './reserveHistory';
import reviewHistory from './reviewHistory';
import rewardHistory from './rewardHistory';
import sessionHistory from './sessionHistory';
import setting from './setting';
import shop from './shop';
import user from './user';

export default persistCombineReducers(
  {
    key: 'root',
    storage,
    whitelist: ['auth']
  },
  {
    application,
    auth,
    model,
    chat,
    chatHistory,
    chatIndex,
    config,
    consumer,
    documentHistory,
    notificationHistory,
    paymentInfo,
    pointHistory,
    reserveHistory,
    reviewHistory,
    rewardHistory,
    sessionHistory,
    setting,
    shop,
    layout,
    like,
    user
  }
);
