import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../../../../actions';
import NavigationHeader from '../../../../../components/Navigator/NavigationHeader';
import PointHistoryItem from '../../../../../components/PointHistory/PointHistoryItem';
import Section from '../../../../../components/Section/Section';
import Text from '../../../../../components/Text';
import View from '../../../../../components/View';
import Color from '../../../../../constants/Color';

class UserMypagePointIndex extends React.Component {
  componentDidMount() {
    this.props.getPointHistories();
  }

  render() {
    const { myself } = this.props.user;

    if (!myself) {
      return null;
    }

    return (
      <View>
        <NavigationHeader
          title="ポイント履歴"
          goBack={this.props.history.goBack}
        />
        <Section
          style={{ alignItems: 'center', padding: 32, marginBottom: 16 }}
        >
          <Text bold style={{ color: Color.darkGrey, fontSize: 15 }}>
            所持ポイント
          </Text>
          <Text bold style={{ fontSize: 17 }}>
            <Text style={{ color: Color.mazenta, fontSize: 32 }}>
              {myself.currentPoint}
            </Text>
            P
          </Text>
        </Section>
        <Section style={{ marginBottom: 16 }}>
          {this.props.pointHistory.list
            .map(id => this.props.pointHistory.hash[id])
            .map(item => {
              return <PointHistoryItem {...item} />;
            })}
        </Section>
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserMypagePointIndex);
