import { getCollection, getDb } from '../utils/Firebase';
import ErrorMessages from '../constants/ErrorMessages';
import NotificationHistory from '../models/NotificationHistory';
import Role from '../utils/Role';
import User from '../models/User';
import * as types from '../constants/ActionTypes';

// get notifications
function fetchGetNotificationHistories() {
  return {
    type: types.FETCH_GET_NOTIFICATION_HISTORIES
  };
}

function fetchGetNotificationHistoriesSuccess(list) {
  return {
    type: types.FETCH_GET_NOTIFICATION_HISTORIES_SUCCESS,
    state: {
      list
    }
  };
}

function fetchGetNotificationHistoriesFailed(error) {
  return {
    type: types.FETCH_GET_NOTIFICATION_HISTORIES_FAILED,
    error
  };
}

export function recoverGetNotificationHistories() {
  return {
    type: types.FETCH_GET_NOTIFICATION_HISTORIES_RECOVER
  };
}

export function getNotificationHistories(role, uid) {
  return (dispatch, getState) => {
    dispatch(fetchGetNotificationHistories());

    let query = getCollection(Role.getCollection(role))
      .doc(uid)
      .collection('notificationHistories');

    return query
      .get()
      .then(async ({ docs }) => {
        const list = await Promise.all(
          docs.map(async doc => {
            const data = doc.data();

            let user;

            if (data.modelId) {
              user = await User.get(data.modelId);
            }

            if (data.consumerId) {
              user = await User.get(data.consumerId);
            }

            return new NotificationHistory({
              id: doc.id,
              user,
              ...doc.data()
            }).fromFirestore();
          })
        );

        return dispatch(fetchGetNotificationHistoriesSuccess(list));
      })
      .catch(error => {
        if (error) {
          return dispatch(
            fetchGetNotificationHistoriesFailed({
              code: error.code,
              message: ErrorMessages[error.code]
            })
          );
        }
      });
  };
}

// update setting
function fetchUpdateNotificationHistory() {
  return {
    type: types.FETCH_UPDATE_NOTIFICATION_HISTORY
  };
}

function fetchUpdateNotificationHistorySuccess(id, params) {
  return {
    type: types.FETCH_UPDATE_NOTIFICATION_HISTORY_SUCCESS,
    state: {
      id,
      params
    }
  };
}

function fetchUpdateNotificationHistoryFailed(error) {
  return {
    type: types.FETCH_UPDATE_NOTIFICATION_HISTORY_FAILED,
    error
  };
}

export function recoverUpdateNotificationHistory() {
  return {
    type: types.FETCH_UPDATE_NOTIFICATION_HISTORY_RECOVER
  };
}

export function updateNotificationHistory(type, uid, params) {
  return (dispatch, getState) => {
    dispatch(fetchUpdateNotificationHistory());

    const ref = getCollection(
      type === 'consumer'
        ? 'consumerNotificationHistorys'
        : 'modelNotificationHistorys'
    ).doc(uid);

    const db = getDb();

    db.runTransaction(transaction => {
      return transaction.get(ref).then(doc => {
        let updateParams = params;

        if (doc.exists) {
          updateParams = {
            ...doc.data(),
            ...params
          };
        }

        return transaction.set(ref, updateParams);
      });
    });

    return ref
      .update(params)
      .then(() => {
        return dispatch(fetchUpdateNotificationHistorySuccess(uid, params));
      })
      .catch(error => {
        return dispatch(
          fetchUpdateNotificationHistoryFailed({
            code: error.code,
            message: ErrorMessages[error.code]
          })
        );
      });
  };
}
