import React from 'react';

import { CreditCardOffIconSvg } from '../../utils/MaterialIconSvg';
import CardPrimitive from '../Card/CardPrimitive';
import Color from '../../constants/Color';
import Image from '../Image';
import Prefecture from '../../models/tag/Prefecture';
import TagItem from '../Tag/TagItem';
import Text from '../Text';
import View from '../View';

export default function CardProfileItem({
  iconImage,
  name,
  sessionCount,
  cardRegisterAt,
  address,
  onClick = () => {},
  style = {}
}) {
  return (
    <View style={{ padding: 16 }} onClick={onClick}>
      <CardPrimitive onClick={onClick} style={{ width: 128, ...style }}>
        <Image source={iconImage} style={{ width: 128 }} />
        <View style={{ padding: 8 }}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: 8
            }}
          >
            <Text bold style={{ fontSize: 13 }}>
              {name}
            </Text>
            {!cardRegisterAt && (
              <CreditCardOffIconSvg
                fill={Color.darkGrey}
                width={20}
                height={20}
                viewBox="0 0 24 24"
                style={{ marginLeft: 8 }}
              />
            )}
          </View>
          <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            <TagItem
              label={`撮影${sessionCount}回`}
              style={{ padding: '2px 4px', marginRight: 4 }}
              textStyle={{ fontSize: 11 }}
            />
            <TagItem
              label={`${Prefecture.getLabel(address)}`}
              style={{ padding: '2px 4px' }}
              textStyle={{ fontSize: 11 }}
            />
          </View>
        </View>
      </CardPrimitive>
    </View>
  );
}
