import React from 'react';
import moment from 'moment';

import {
  ChevronDownIconSvg,
  ChevronUpIconSvg
} from '../../utils/MaterialIconSvg';
import Button from '../Button';
import Color from '../../constants/Color';
import Label from '../Form/Label';
import Note from '../Note/Note';
import Paragraph from '../Paragraph';
import Price from '../../models/model/Price';
import ReserveStatusLabel from '../Reserve/ReserveStatusLabel';
import Session from '../../utils/Session';
import Text from '../Text';
import View from '../View';

export default class ReserveItem extends React.Component {
  state = {
    isOpenDetail: false
  };

  render() {
    const {
      selfType,
      status,
      hours,
      price,
      optionPrice,
      optionName,
      startAt,
      consumerAgreedAt,
      onSubmit = () => {},
      onCancel = () => {},
      onCancelAndPay = () => {},
      onJoin = () => {},
      onDismiss = () => {},
      style = {}
    } = this.props;

    const isOverCancelLimit = Session.getIsOverCancelLimit({
      startAt,
      now: new Date()
    });

    return (
      <View
        style={{
          borderBottom: `1px solid ${Color.warmGrey}`,
          ...style
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            margin: 8,
            padding: '8px 0'
          }}
          onClick={() => {
            this.setState({ isOpenDetail: !this.state.isOpenDetail });
          }}
        >
          <View style={{ flexDirection: 'row', width: 80 }}>
            <ReserveStatusLabel status={status} />
          </View>
          <Text style={{ flex: 1, fontSize: 15 }}>
            {moment(startAt).format('MM/DD(ddd) HH:mm')}〜 ({hours}時間)
          </Text>
          {this.state.isOpenDetail ? (
            <ChevronUpIconSvg
              fill={Color.black}
              width={24}
              height={24}
              viewBox="0 0 24 24"
              style={{ marginRight: 4 }}
            />
          ) : (
            <ChevronDownIconSvg
              fill={Color.black}
              width={24}
              height={24}
              viewBox="0 0 24 24"
              style={{ marginRight: 4 }}
            />
          )}
        </View>
        {this.state.isOpenDetail && (
          <View
            style={{
              padding: 24,
              display: 'block',
              overflow: 'scroll',
              maxHeight: 500
            }}
          >
            <View style={{ flexDirection: 'row', alignItems: 'baseline' }}>
              <Label
                style={{
                  marginBottom: 8
                }}
              >
                撮影料金
              </Label>
            </View>
            <Text style={{ fontSize: 15 }}>
              {Price.getPriceTermsText(price, hours, optionPrice, optionName)}
            </Text>
            <Text bold>
              {Price.getPriceResultText(price, hours, optionPrice)}
            </Text>
            <Paragraph
              onerow
              style={{ fontSize: 11, color: Color.darkGrey }}
              containerStyle={{ marginBottom: 8 }}
            >
              ※ 延長 15分 につき {(this.props.price * 1.3) / 2}P が発生します。
            </Paragraph>
            <Paragraph style={{ color: Color.red, fontSize: 11 }}>
              実際に合流し、撮影が終わるまでポイントは消費されません。
              {isOverCancelLimit &&
                '前日を過ぎているため、キャンセル時には予約分の撮影ポイントが消費されます。'}
            </Paragraph>
            {selfType === 'model' && status === 'confirmed' && (
              <View style={{ marginTop: 16 }}>
                <Note style={{ padding: 16, marginBottom: 16 }}>
                  <Paragraph onerow>
                    撮影者と合流したら、すぐに<Text bold>「開始する」</Text>
                    を押してください。開始後、<Text bold>位置情報の許可</Text>
                    をして、現在地を送信する必要があります。
                  </Paragraph>
                </Note>
                <Button
                  mini
                  onClick={() => {
                    this.setState({ isOpenDetail: false }, () => onJoin());
                  }}
                  style={{ marginBottom: 16 }}
                >
                  開始する
                </Button>
                <Button
                  mini
                  variant="outlined"
                  onClick={() => {
                    this.setState({ isOpenDetail: false }, () => onCancel());
                  }}
                  style={{ marginBottom: 16 }}
                >
                  キャンセルする
                </Button>
                {isOverCancelLimit && (
                  <Button
                    mini
                    variant="outlined"
                    onClick={() => {
                      this.setState({ isOpenDetail: false }, () =>
                        onCancelAndPay()
                      );
                    }}
                  >
                    キャンセルして決済する
                  </Button>
                )}
              </View>
            )}
            {selfType === 'model' &&
              (status === 'joined' || status === 'dismissed') && (
                <View style={{ marginTop: 16 }}>
                  <Note style={{ padding: 16, marginBottom: 16 }}>
                    <Paragraph bold>
                      撮影が終わったら「撮影を終了する」を押してください。
                    </Paragraph>
                  </Note>
                  <Button
                    mini
                    onClick={() => {
                      this.setState({ isOpenDetail: false }, () => onDismiss());
                    }}
                    style={{ marginBottom: 16 }}
                  >
                    撮影を終了する
                  </Button>
                  <Button
                    mini
                    variant="outlined"
                    onClick={() => {
                      this.setState({ isOpenDetail: false }, () => onCancel());
                    }}
                  >
                    キャンセルする
                  </Button>
                </View>
              )}
            {selfType === 'model' && !consumerAgreedAt && (
              <View style={{ marginTop: 16 }}>
                <Note style={{ padding: 16, marginBottom: 16 }}>
                  <Paragraph bold>撮影者の同意を待っています。</Paragraph>
                </Note>
                <Button
                  mini
                  variant="outlined"
                  onClick={() => {
                    this.setState({ isOpenDetail: false }, () => onCancel());
                  }}
                >
                  キャンセルする
                </Button>
              </View>
            )}
            {selfType === 'consumer' &&
              !consumerAgreedAt &&
              status !== 'canceled' &&
              status !== 'penaltyCanceled' && (
                <View style={{ marginTop: 16 }}>
                  <Note style={{ padding: 16, marginBottom: 16 }}>
                    <Paragraph style={{ fontSize: 11, marginBottom: 8 }}>
                      被写体保護宣言にご協力の上、撮影スケジュールを確定してください。
                    </Paragraph>
                    <Paragraph bold style={{ fontSize: 11 }}>
                      {`・プライベートな連絡先交換を迫らない\n・二人きりになる状態で撮影をしない\n・その他、モデルの嫌がる行為をしない`}
                    </Paragraph>
                  </Note>
                  <Button
                    mini
                    onClick={() => {
                      this.setState({ isOpenDetail: false }, () => onSubmit());
                    }}
                  >
                    宣言に同意して確定する
                  </Button>
                </View>
              )}
            {selfType === 'consumer' && status === 'confirmed' && (
              <View style={{ marginTop: 16 }}>
                {isOverCancelLimit ? (
                  <Button
                    mini
                    variant="outlined"
                    onClick={() => {
                      this.setState({ isOpenDetail: false }, () =>
                        onCancelAndPay()
                      );
                    }}
                  >
                    キャンセルして決済する
                  </Button>
                ) : (
                  <Button
                    mini
                    variant="outlined"
                    onClick={() => {
                      this.setState({ isOpenDetail: false }, () => onCancel());
                    }}
                    style={{ marginBottom: 16 }}
                  >
                    キャンセルする
                  </Button>
                )}
              </View>
            )}
          </View>
        )}
      </View>
    );
  }
}
