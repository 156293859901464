const ITEMS = [
  {
    label: '1時間',
    value: 1
  },
  {
    label: '1時間30分',
    value: 1.5
  },
  {
    label: '2時間',
    value: 2
  },
  {
    label: '2時間30分',
    value: 2.5
  },
  {
    label: '3時間',
    value: 3
  },
  {
    label: '4時間',
    value: 4
  },
  {
    label: '5時間',
    value: 5
  },
  {
    label: '6時間',
    value: 6
  }
];

export default class Hours {
  static getItems(withDefault = false) {
    const items = ITEMS;

    if (withDefault) {
      items.unshift({ label: '未選択', value: 'unselected', isDefault: true });
    }

    return items;
  }

  static getLabel(value) {
    return Hours.getItems().find(item => item.value === value).label;
  }
}
