const ITEMS = [
  { key: 'personalUsage', label: '個人利用' },
  { key: 'groupUsage', label: 'グループ鑑賞' },
  { key: 'snsUsage', label: 'SNS掲載利用' },
  { key: 'adUsage', label: '広告利用' },
  { key: 'resaleUsage', label: '再販・商用利用' }
];

export default class PhotoPermission {
  static getItems() {
    return ITEMS;
  }

  static getKeys() {
    return ITEMS.map(item => item.key);
  }

  static getLabel(key) {
    const item = ITEMS.find(item => item.key === key);

    return item.label;
  }
}
