import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../../../../actions';
import View from '../../../../../components/View';
import Section from '../../../../../components/Section/Section';
import SectionTransitItem from '../../../../../components/Section/SectionTransitItem';
import NavigationHeader from '../../../../../components/Navigator/NavigationHeader';

class ModelMypageOtherIndex extends React.Component {
  render() {
    return (
      <View>
        <NavigationHeader title="その他" goBack={this.props.history.goBack} />
        <Section style={{ marginBottom: 16 }}>
          <SectionTransitItem
            title="利用規約"
            onClick={() => this.props.history.push('/model/mypage/other/term')}
          />
          <SectionTransitItem
            title="プライバシーポリシー"
            onClick={() =>
              this.props.history.push('/model/mypage/other/privacy')
            }
          />
          <SectionTransitItem
            title="特定商取引法に関する表示"
            onClick={() => this.props.history.push('/model/mypage/other/scta')}
          />
        </Section>
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModelMypageOtherIndex);
