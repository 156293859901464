import React from 'react';
import moment from 'moment';

import Color from '../../constants/Color';
import DatetimeFormField from '../Form/DatetimeFormField';
import Headline from '../Headline';
import Hours from '../../models/Hours';
import MaterialIcon from '../../utils/MaterialIcon';
import ModalBox from '../Modal/ModalBox';
import ModalBoxHeader from '../Modal/ModalBoxHeader';
import Note from '../Note/Note';
import OptionPrice from '../../models/model/OptionPrice';
import Paragraph from '../Paragraph';
import Permission from '../../models/Permission';
import Price from '../../models/model/Price';
import SelectFormField from '../Form/SelectFormField';
import TextFormField from '../Form/TextFormField';
import View from '../View';

export default class ScheduleModalBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      price: props.defaultPrice,
      hours: 1,
      startAt: moment()
        .add(2, 'hour')
        .startOf('hour')
        .format('YYYY-MM-DDTHH:mm'),
      optionPrice: 0,
      optionName: '',
      personalUsage: props.model.personalUsage,
      snsUsage: props.model.snsUsage,
      adUsage: props.model.adUsage,
      resaleUsage: props.model.resaleUsage
    };
  }

  render() {
    const {
      defaultPrice,
      staticControl,
      renderSubmitButton = () => {}
    } = this.props;

    return (
      <ModalBox
        header={
          <ModalBoxHeader
            title="日程を決める"
            right={
              <View onClick={() => this.props.onClose()}>
                <MaterialIcon icon="close" color={Color.black} size={24} />
              </View>
            }
          />
        }
        visible={this.props.visible}
        onClose={() => this.props.onClose()}
      >
        <View style={{ padding: '0 16px 24px 16px' }}>
          <Headline size={2} style={{ marginBottom: 8 }}>
            料金について
          </Headline>
          <DatetimeFormField
            staticControl={staticControl}
            label="日程"
            name="datetime"
            direction="column"
            value={this.state.startAt}
            onChangeValue={value => {
              this.setState({
                startAt: moment(value).format('YYYY-MM-DDTHH:mm')
              });
            }}
            style={{ marginBottom: 16 }}
          />
          <SelectFormField
            staticControl={staticControl}
            label="撮影料金 (30分)"
            name="price"
            direction="column"
            items={Price.getItems()}
            defaultValue={defaultPrice}
            onChangeValue={value => {
              this.setState({ price: value });
            }}
            style={{ marginBottom: 16 }}
          />
          <SelectFormField
            staticControl={staticControl}
            label="撮影時間"
            name="hours"
            direction="column"
            items={Hours.getItems()}
            defaultValue={this.state.hours}
            onChangeValue={value => this.setState({ hours: value })}
            style={{ marginBottom: 16 }}
          />
          <SelectFormField
            staticControl={staticControl}
            label="オプション料金"
            name="optionPrice"
            direction="column"
            items={OptionPrice.getItems()}
            defaultValue={this.state.optionPrice}
            onChangeValue={value => {
              this.setState({ optionPrice: value });
            }}
            style={{ marginBottom: 16 }}
          />
          {this.state.optionPrice ? (
            <TextFormField
              staticControl={staticControl}
              value={this.state.optionName}
              name="optionName"
              direction="column"
              onChangeText={optionName => {
                this.setState({
                  optionName
                });
              }}
              label="オプション名"
              style={{ marginBottom: 16 }}
            />
          ) : null}
          <Headline size={2} style={{ marginTop: 8, marginBottom: 8 }}>
            写真の取扱について
          </Headline>
          <SelectFormField
            label="個人利用"
            direction="column"
            name="personalUsage"
            items={Permission.getItems()}
            defaultValue={this.state.personalUsage}
            isSearchable
            onChangeValue={value => {
              this.setState({ personalUsage: value });
            }}
            style={{ marginBottom: 16 }}
          />
          <SelectFormField
            label="SNS掲載"
            direction="column"
            name="snsUsage"
            items={Permission.getItems()}
            defaultValue={this.state.snsUsage}
            isSearchable
            onChangeValue={value => {
              this.setState({ snsUsage: value });
            }}
            style={{ marginBottom: 16 }}
          />
          <SelectFormField
            label="広告利用"
            direction="column"
            name="adUsage"
            items={Permission.getItems()}
            defaultValue={this.state.adUsage}
            isSearchable
            onChangeValue={value => {
              this.setState({ adUsage: value });
            }}
            style={{ marginBottom: 16 }}
          />
          <SelectFormField
            label="再販・商用利用"
            direction="column"
            name="resaleUsage"
            items={Permission.getItems()}
            defaultValue={this.state.resaleUsage}
            isSearchable
            onChangeValue={value => {
              this.setState({ resaleUsage: value });
            }}
            style={{ marginBottom: 16 }}
          />
          <Note style={{ padding: 16, marginBottom: 24 }}>
            <Headline size={3} style={{ marginBottom: 8 }}>
              撮影料金
            </Headline>
            <Paragraph onerow>
              {Price.getPriceTermsText(
                this.state.price,
                this.state.hours,
                this.state.optionPrice,
                this.state.optionName
              )}
            </Paragraph>
            <View style={{ flexDirection: 'row', alignItems: 'baseline' }}>
              <Paragraph onerow bold>
                {Price.getPriceResultText(
                  this.state.price,
                  this.state.hours,
                  this.state.optionPrice
                )}
              </Paragraph>
            </View>
            <Paragraph
              onerow
              style={{ fontSize: 11, color: Color.darkGrey }}
              containerStyle={{ marginBottom: 8 }}
            >
              ※ 延長 15分 につき {(this.state.price * 1.3) / 2}P が発生します。
            </Paragraph>
            <Paragraph onerow style={{ color: Color.red }}>
              実際に合流し、撮影が終わるまでポイントは消費されません。
            </Paragraph>
          </Note>
          {renderSubmitButton({
            price: this.state.price,
            hours: this.state.hours,
            startAt: moment(this.state.startAt).toDate(),
            personalUsage: this.state.personalUsage,
            snsUsage: this.state.snsUsage,
            adUsage: this.state.adUsage,
            resaleUsage: this.state.resaleUsage,
            optionPrice: this.state.optionPrice,
            optionName: this.state.optionName
          })}
        </View>
      </ModalBox>
    );
  }
}
