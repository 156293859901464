import { getCollection } from '../utils/Firebase';
import Model from '../models/Model';
import ErrorMessages from '../constants/ErrorMessages';
import User from '../models/User';
import * as types from '../constants/ActionTypes';

// get models
function fetchGetModels() {
  return {
    type: types.FETCH_GET_MODELS
  };
}

function fetchGetModelsSuccess(list) {
  return {
    type: types.FETCH_GET_MODELS_SUCCESS,
    state: {
      list
    }
  };
}

function fetchGetModelsFailed(error) {
  return {
    type: types.FETCH_GET_MODELS_FAILED,
    error
  };
}

export function recoverGetModels() {
  return {
    type: types.FETCH_GET_MODELS_RECOVER
  };
}

export function getModels(
  filter = { where: null, orderBy: ['createdAt', 'desc'] }
) {
  return dispatch => {
    dispatch(fetchGetModels());

    let query = getCollection('models');

    if (filter.where && filter.where[2] !== 'unselected') {
      query = query.where(...filter.where);
    }

    return query
      .orderBy(...filter.orderBy)
      .get()
      .then(async ({ docs }) => {
        const list = await Promise.all(
          docs.map(async doc => {
            const consumer = new Model({
              id: doc.id,
              ...doc.data()
            }).fromFirestore();

            const user = await User.get(doc.id);

            return {
              ...user,
              ...consumer
            };
          })
        );

        return dispatch(fetchGetModelsSuccess(list));
      })
      .catch(error => {
        if (error) {
          return dispatch(
            fetchGetModelsFailed({
              code: error.code,
              message: ErrorMessages[error.code]
            })
          );
        }
      });
  };
}

// get model
function fetchGetModel() {
  return {
    type: types.FETCH_GET_MODEL
  };
}

function fetchGetModelSuccess(id, params) {
  return {
    type: types.FETCH_GET_MODEL_SUCCESS,
    state: {
      id,
      params
    }
  };
}

function fetchGetModelFailed(error) {
  return {
    type: types.FETCH_GET_MODEL_FAILED,
    error
  };
}

export function recoverGetModel() {
  return {
    type: types.FETCH_GET_MODEL_RECOVER
  };
}

export function getModel(id) {
  return dispatch => {
    dispatch(fetchGetModel());

    let query = getCollection('models').doc(id);

    return query
      .get()
      .then(async doc => {
        const model = new Model({
          id: doc.id,
          ...doc.data()
        }).fromFirestore();

        const user = await User.get(doc.id);

        const params = {
          ...user,
          ...model
        };

        return dispatch(fetchGetModelSuccess(id, params));
      })
      .catch(error => {
        if (error) {
          return dispatch(
            fetchGetModelFailed({
              code: error.code,
              message: ErrorMessages[error.code]
            })
          );
        }
      });
  };
}
