const VALUES = [
  0,
  500,
  1000,
  1500,
  2000,
  2500,
  3000,
  5000,
  6000,
  7000,
  8000,
  9000,
  10000,
  15000,
  20000,
  25000,
  30000,
  40000,
  50000,
  60000,
  70000,
  80000,
  90000,
  100000,
  200000,
  300000,
  400000,
  500000,
  1000000
];

export default class OptionPrice {
  static getItems(withDefault = false) {
    const items = VALUES.map(value => ({ label: `+${value}P`, value }));

    if (withDefault) {
      items.unshift({ label: '未選択', value: 'unselected', isDefault: true });
    }

    return items;
  }

  static getLabel(value) {
    return OptionPrice.getItems().find(item => item.value === value).label;
  }
}
