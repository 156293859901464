export default class Geolocation {
  static getPosition() {
    const promise = new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(position => {
        const { coords } = position;

        resolve(coords);
      });

      return setTimeout(() => {
        reject(new Error('位置情報の取得に失敗しました。'));
      }, 30000);
    });

    return promise;
  }
}
