import React from 'react';
import { throttle } from 'lodash';
import View from '../View';

export default class ChatMessageList extends React.Component {
  scrollToBottom() {
    const element = document.getElementById('area');

    if (!element) {
      return;
    }

    const { scrollHeight } = element;

    document.getElementById('area').scrollTop = scrollHeight;
  }

  handleScroll = throttle(event => {
    event.persist();

    if (
      event.currentTarget &&
      event.currentTarget.scrollTop !== undefined &&
      event.currentTarget.scrollTop < 30 &&
      !this.props.isLoading
    ) {
      this.props.onFetchMore();
    }
  }, 10);

  componentDidMount() {
    setTimeout(() => {
      this.scrollToBottom();
    }, 1000);
  }

  shouldComponentUpdate(nextProps) {
    if (
      nextProps.data.length > 0 &&
      this.props.data.length > 0 &&
      nextProps.data[0].id !== this.props.data[0].id
    ) {
      setTimeout(() => {
        this.scrollToBottom();
      }, 1);
    } else if (nextProps.data.length !== this.props.data.length) {
      const scrollHeight = document.getElementById('area').scrollHeight;

      setTimeout(() => {
        const element = document.getElementById('area');

        if (element) {
          const { scrollHeight: scrollHeightAfter } = element;

          document.getElementById('area').scrollTop =
            scrollHeightAfter - scrollHeight;
        }
      }, 1);
    }

    return true;
  }

  render() {
    const { data, renderItem, style = {} } = this.props;

    const invertedData = data.reduce((acc, datum) => {
      return [datum, ...acc];
    }, []);

    return (
      <View
        id="area"
        style={{
          ...style
        }}
        onScroll={this.handleScroll.bind(this)}
      >
        {invertedData.map(datum => {
          return renderItem({ item: datum });
        })}
      </View>
    );
  }
}
