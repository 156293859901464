export default class Role {
  static getOpponentCollection(role) {
    switch (role) {
      case 'model':
        return 'consumers';
      case 'consumer':
        return 'models';
      default:
        return null;
    }
  }

  static getCollection(role) {
    switch (role) {
      case 'model':
        return 'models';
      case 'consumer':
        return 'consumers';
      case 'supporter':
        return 'supporters';
      default:
        return null;
    }
  }

  static getOpponentRole(role) {
    switch (role) {
      case 'model':
        return 'consumer';
      case 'consumer':
        return 'model';
      default:
        return null;
    }
  }
}
