export default class SessionHistory {
  constructor(state = {}) {
    this.params = {
      id: state.id,
      price: state.price,
      hours: state.hours,
      optionPrice: state.optionPrice,
      optionName: state.optionName,
      user: state.user,
      reserveHistoryId: state.reserveHistoryId,
      reserveCreatedAt: state.reserveCreatedAt,
      startAt: state.startAt,
      consumerAgreedAt: state.consumerAgreedAt,
      modelAgreedAt: state.modelAgreedAt,
      startedAt: state.startedAt,
      dismissedAt: state.dismissedAt,
      createdAt: state.createdAt,
      updatedAt: state.updatedAt
    };
  }

  get object() {
    return this.params;
  }

  fromFirestore() {
    return {
      ...this.params,
      reserveCreatedAt:
        this.params.reserveCreatedAt && this.params.reserveCreatedAt.toDate(),
      startAt: this.params.startAt && this.params.startAt.toDate(),
      consumerAgreedAt:
        this.params.consumerAgreedAt && this.params.consumerAgreedAt.toDate(),
      modelAgreedAt:
        this.params.modelAgreedAt && this.params.modelAgreedAt.toDate(),
      startedAt: this.params.startedAt && this.params.startedAt.toDate(),
      dismissedAt: this.params.dismissedAt && this.params.dismissedAt.toDate(),
      createdAt: this.params.createdAt && this.params.createdAt.toDate(),
      updatedAt: this.params.updatedAt && this.params.updatedAt.toDate()
    };
  }

  static getPointHistoryTypeLabel(type) {
    switch (type) {
      case 'buy':
        return 'ご購入';
      case 'session':
        return '撮影';
      default:
        return '';
    }
  }
}
