import { request } from '../utils/Network';
import * as types from '../constants/ActionTypes';

// create payment info
function fetchCreatePaymentInfo() {
  return {
    type: types.FETCH_CREATE_PAYMENT_INFO
  };
}

function fetchCreatePaymentInfoSuccess(contractId, { activityHistories }) {
  return {
    type: types.FETCH_CREATE_PAYMENT_INFO_SUCCESS,
    state: {
      contractId,
      reset: true,
      list: activityHistories
    }
  };
}

function fetchCreatePaymentInfoFailed(error) {
  return {
    type: types.FETCH_CREATE_PAYMENT_INFO_FAILED,
    error
  };
}

export function recoverCreatePaymentInfo() {
  return {
    type: types.FETCH_CREATE_PAYMENT_INFO_RECOVER
  };
}

export function createPaymentInfo(token) {
  return dispatch => {
    dispatch(fetchCreatePaymentInfo());

    return request(dispatch, 'POST', '/createPaymentInfo', {
      params: {
        token
      }
    }).then(({ response, error }) => {
      if (error) {
        return dispatch(fetchCreatePaymentInfoFailed(error));
      }

      return dispatch(fetchCreatePaymentInfoSuccess(response.id, response));
    });
  };
}

// delete payment info
function fetchDeletePaymentInfo() {
  return {
    type: types.FETCH_DELETE_PAYMENT_INFO
  };
}

function fetchDeletePaymentInfoSuccess(contractId, { activityHistories }) {
  return {
    type: types.FETCH_DELETE_PAYMENT_INFO_SUCCESS,
    state: {
      contractId,
      reset: true,
      list: activityHistories
    }
  };
}

function fetchDeletePaymentInfoFailed(error) {
  return {
    type: types.FETCH_DELETE_PAYMENT_INFO_FAILED,
    error
  };
}

export function recoverDeletePaymentInfo() {
  return {
    type: types.FETCH_DELETE_PAYMENT_INFO_RECOVER
  };
}

export function deletePaymentInfo(paymentInfoId) {
  return dispatch => {
    dispatch(fetchDeletePaymentInfo());

    return request(dispatch, 'POST', '/deletePaymentInfo', {
      params: {
        paymentInfoId
      }
    }).then(({ response, error }) => {
      if (error) {
        return dispatch(fetchDeletePaymentInfoFailed(error));
      }

      return dispatch(fetchDeletePaymentInfoSuccess(response.id, response));
    });
  };
}
