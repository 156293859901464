import React from 'react';

import { getTextStyle } from '../utils/TextStyle';
import Color from '../constants/Color';

export default function Headline({
  size = 1,
  mincho = false,
  bold = true,
  demibold = false,
  children,
  style = {}
}) {
  const textStyle = getTextStyle({ mincho, bold, demibold });

  switch (size) {
    case 1:
      return (
        <h1
          style={{ ...textStyle, color: Color.black, fontSize: 20, ...style }}
        >
          {children}
        </h1>
      );
    case 2:
      return (
        <h2
          style={{ ...textStyle, color: Color.black, fontSize: 17, ...style }}
        >
          {children}
        </h2>
      );
    case 3:
      return (
        <h3
          style={{ ...textStyle, color: Color.black, fontSize: 15, ...style }}
        >
          {children}
        </h3>
      );
    case 4:
    default:
      return (
        <h4
          style={{ ...textStyle, color: Color.black, fontSize: 13, ...style }}
        >
          {children}
        </h4>
      );
  }
}
