import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import { ChevronLeftIconSvg } from '../../../../utils/MaterialIconSvg';
import { getTimestamp } from '../../../../utils/Firebase';
import Button from '../../../../components/Button';
import ChatIndexItem from '../../../../components/Chat/ChatIndexItem';
import ChatIndexList from '../../../../components/Chat/ChatIndexList';
import ChatMessageFooter from '../../../../components/Chat/ChatMessageFooter';
import ChatMessageItem from '../../../../components/Chat/ChatMessageItem';
import ChatMessageList from '../../../../components/Chat/ChatMessageList';
import Color from '../../../../constants/Color';
import Header from '../../../../components/Header';
import Note from '../../../../components/Note/Note';
import Paragraph from '../../../../components/Paragraph';
import PaymentModalBox from '../../../../containers/payment/PaymentModalBox';

import ReserveItem from '../../../../components/Schedule/ReserveItem';
import ScheduleModalBox from '../../../../components/Schedule/ScheduleModalBox';
import Text from '../../../../components/Text';
import UserIcon from '../../../../components/Icons/UserIcon';
import View from '../../../../components/View';
import actions from '../../../../actions';
import ReserveHeader from '../../../../containers/reserve/ReserveHeader';
import NavigationHeader from '../../../../components/Navigator/NavigationHeader';

class UserMessageDetail extends React.Component {
  state = {
    message: '',
    isOpenScheduleModal: false,
    isOpenPuchaseModal: false
  };

  deleteBadges(props) {
    props.user.myself.badges
      .filter(badge => badge.modelId === props.match.params.modelId)
      .forEach(badge => {
        this.props.deleteBadge('consumer', this.props.auth.uid, badge.id);
      });
  }

  shouldComponentUpdate(nextProps) {
    if (
      nextProps.user.myself !== this.props.user.myself &&
      nextProps.user.myself
    ) {
      this.deleteBadges(nextProps);
    }

    return true;
  }

  componentDidMount() {
    this.props.getChat(this.props.match.params.chatId);

    this.props.subscribeChatHistory(this.props.match.params.chatId);

    if (this.props.user.myself) {
      this.deleteBadges(this.props);
    }

    this.props.subscribeBadge('consumer', this.props.auth.uid);
  }

  componendWillUnmount() {
    this.props.unsubscribeChatHistory(
      this.props.auth.uid,
      this.props.match.params.modelId
    );

    this.props.unsubscribeBadge(this.props.auth.uid);
  }

  renderChatList() {
    const result = this.state.searcher.search(this.state.searchText);

    return (
      <ChatIndexList
        data={result}
        renderItem={({ item }) => {
          return <ChatIndexItem {...item} selfType="consumer" />;
        }}
      />
    );
  }

  render() {
    if (!this.props.user.myself) {
      return null;
    }

    const { myself } = this.props.user;

    const chat = this.props.chat.hash[this.props.match.params.chatId];

    if (!chat) {
      return null;
    }

    const otherMembers = chat.members.filter(
      member => member.id !== this.props.auth.uid
    );

    let isGroup = otherMembers.length > 1;

    let opponent = null;

    if (!isGroup) {
      opponent = otherMembers[0];
    }

    const reserveHistories = this.props.reserveHistory.list.map(
      id => this.props.reserveHistory.hash[id]
    );

    let reserveHistory = null;

    if (reserveHistories.length > 0) {
      reserveHistory = reserveHistories[0];
    }

    const isActiveSchedule =
      chat &&
      reserveHistory &&
      reserveHistory.status !== 'canceled' &&
      reserveHistory.status !== 'penaltyCanceled';

    const reserveData = this.props.reserveHistory.list
      .map(id => this.props.reserveHistory.hash[id])
      .filter(
        datum =>
          datum.status !== 'canceled' &&
          datum.status !== 'penaltyCanceled' &&
          datum.status !== 'dismissed'
      );

    return (
      <View
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          height: '100%',
          backgroundColor: Color.white
        }}
      >
        <NavigationHeader
          user={opponent}
          onClickUser={() => {
            if (opponent.roles.includes('model')) {
              this.props.history.push(`/user/find/model/${opponent.id}`);
            }
          }}
          goBack={this.props.history.goBack}
        />
        {!myself.cardRegisterAt && (
          <Note
            style={{ padding: '8px 16px' }}
            onClick={() => this.setState({ isOpenPuchaseModal: true })}
          >
            <Paragraph
              onerow
              style={{ color: Color.darkCeladonBlue, fontSize: 13 }}
            >
              クレジットカードが未登録です。
              <Text
                style={{ color: Color.mazenta, textDecoration: 'underline' }}
              >
                こちら
              </Text>
              からクレジットカードの登録をしましょう。
            </Paragraph>
          </Note>
        )}
        {isGroup ? null : (
          <ReserveHeader
            data={reserveData}
            consumerId={this.props.auth.uid}
            modelId={opponent.id}
            renderItem={({ item }) => {
              return (
                <ReserveItem
                  key={item.id}
                  selfType="consumer"
                  {...item}
                  onSubmit={() => {
                    if (!myself.defaultPaymentInfoId) {
                      this.setState({
                        isOpenPuchaseModal: true
                      });
                    } else {
                      this.props.updateReserveHistory(
                        this.props.auth.uid,
                        opponent.id,
                        item.id,
                        {
                          status: 'confirmed',
                          consumerAgreedAt: getTimestamp()
                        }
                      );
                    }
                  }}
                  onCancel={() => {
                    this.props.updateReserveHistory(
                      this.props.auth.uid,
                      opponent.id,
                      reserveHistory.id,
                      {
                        status: 'canceled',
                        consumerAgreedAt: null
                      }
                    );
                  }}
                  onCancelAndPay={() => {
                    this.props.updateReserveHistory(
                      this.props.auth.uid,
                      opponent.id,
                      item.id,
                      {
                        status: 'penaltyCanceled',
                        consumerAgreedAt: null
                      }
                    );
                  }}
                />
              );
            }}
          />
        )}
        <ChatMessageList
          data={this.props.chatHistory.list.map(
            id => this.props.chatHistory.hash[id]
          )}
          renderItem={({ item }) => {
            const isSelf = item.senderId === this.props.auth.uid;

            const user = myself;

            const opponent = chat.members.find(
              member => member.id === item.senderId
            );

            return (
              <ChatMessageItem
                selfType="consumer"
                key={item.id}
                isSelf={isSelf}
                {...item}
                user={user}
                opponent={opponent}
                style={{ margin: 16 }}
                onClickIcon={() => {
                  this.props.history.push(`/user/find/model/${opponent.id}`);
                }}
              />
            );
          }}
          isLoading={this.props.chatHistory.isGetLoading}
          onFetchMore={() => {
            if (this.props.chatHistory.list.length < 1) {
              return;
            }

            this.props.getChatHistories(this.props.match.params.chatId);
          }}
          style={{ display: 'block', height: '100%', overflow: 'scroll' }}
        />
        <ChatMessageFooter
          message={this.state.message}
          schedule={false}
          onChangeMessage={text => this.setState({ message: text })}
          onSubmitMessage={() => {
            this.props.createChatHistory(
              'consumer',
              this.props.match.params.chatId,
              {
                type: 'message',
                message: this.state.message
              }
            );

            this.setState({ message: '' });
          }}
          onClickSchedule={() => this.setState({ isOpenScheduleModal: true })}
          onUploadFile={file => {
            this.props.createChatHistory(
              'consumer',
              this.props.match.params.chatId,
              {
                type: 'file',
                file
              }
            );
          }}
        />
        {isActiveSchedule && (
          <ScheduleModalBox
            staticControl
            visible={this.state.isOpenScheduleModal}
            defaultPrice={opponent.price}
            model={opponent}
            onClose={() => {
              this.setState({ isOpenScheduleModal: false });
            }}
            isLoading={this.props.reserveHistory.isCreateLoading}
            renderSubmitButton={params => {
              if (!reserveHistory) {
              }
              if (reserveHistory.status === 'pending') {
                return (
                  <Button
                    onClick={() => {
                      this.props.updateReserveHistory(
                        this.props.auth.uid,
                        opponent.id,
                        reserveHistory.id,
                        {
                          status: 'confirmed',
                          consumerAgreedAt: getTimestamp()
                        }
                      );

                      this.setState({ isOpenScheduleModal: false });
                    }}
                  >
                    日程を確定する
                  </Button>
                );
              } else if (reserveHistory.status === 'confirmed') {
                return (
                  <Button
                    variant="outlined"
                    onClick={() => {
                      this.props.updateReserveHistory(
                        this.props.auth.uid,
                        opponent.id,
                        reserveHistory.id,
                        {
                          status: 'canceled',
                          consumerAgreedAt: null
                        }
                      );

                      this.setState({ isOpenScheduleModal: false });
                    }}
                  >
                    キャンセルする
                  </Button>
                );
              }
            }}
          />
        )}
        <PaymentModalBox
          visible={this.state.isOpenPuchaseModal}
          onClose={() => {
            this.setState({ isOpenPuchaseModal: false });
          }}
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserMessageDetail);
