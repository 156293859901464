import React from 'react';

import Color from '../../constants/Color';
import Text from '../Text';
import View from '../View';

export default function SectionItem({ title, content, style = {} }) {
  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        ...style
      }}
    >
      <Text style={{ color: Color.black, fontSize: 15, minWidth: 100 }}>
        {title}
      </Text>
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          overflow: 'hidden',
          alignItems: 'center'
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            flex: 1,
            overflow: 'hidden',
            justifyContent: 'flex-end'
          }}
        >
          <Text
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              color: Color.darkGrey,
              fontSize: 13
            }}
          >
            {content}
          </Text>
        </View>
      </View>
    </View>
  );
}
