import React from 'react';

import Color from '../../constants/Color';
import Text from '../Text';
import View from '../View';

export default function StepSection({ symbol, children, style = {} }) {
  let symbolObject = symbol;

  if (typeof symbol === 'string' || typeof symbol === 'number') {
    symbolObject = (
      <Text bold style={{ color: Color.white, fontSize: 24 }}>
        {symbol}
      </Text>
    );
  }

  return (
    <View
      style={{
        flexDirection: 'row',
        ...style
      }}
    >
      <View
        style={{
          backgroundColor: Color.mazenta,
          width: 32,
          height: 32,
          alignItems: 'center',
          justifyContent: 'center',
          marginRight: 16
        }}
      >
        {symbolObject}
      </View>
      <View style={{ flex: 1 }}>{children}</View>
    </View>
  );
}
