import {
  CardCVCElement,
  CardExpiryElement,
  CardNumberElement,
  injectStripe
} from 'react-stripe-elements';
import React from 'react';

import Button from '../../components/Button';
import Color from '../../constants/Color';
import Field from '../../components/Form/Field';
import Label from '../../components/Form/Label';
import Text from '../../components/Text';
import View from '../../components/View';

const createOptions = (fontSize: string, padding: ?string) => {
  return {
    style: {
      base: {
        fontSize,
        color: '#424770',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4'
        },
        border: `1px solid ${Color.lightGrey}`,
        ...(padding ? { padding } : {})
      },
      invalid: {
        color: '#9e2146'
      }
    }
  };
};

class CardForm extends React.Component<InjectedProps & { fontSize: string }> {
  state = {
    disable: false
  };

  handleSubmit = ev => {
    ev.preventDefault();

    this.setState({ disable: true }, () => {
      if (this.props.stripe) {
        this.props.stripe
          .createToken()
          .then(payload => this.props.onCreateToken(payload));
      } else {
        console.log("Stripe.js hasn't loaded yet.");
      }
    });
  };

  render() {
    const { error } = this.props;

    return (
      <form onSubmit={this.handleSubmit}>
        <View style={{ marginBottom: 8 }}>
          <Label>カード番号</Label>
        </View>
        <Field style={{ padding: 8, marginBottom: 16 }}>
          <CardNumberElement {...createOptions(this.props.fontSize)} />
        </Field>
        <View style={{ marginBottom: 8 }}>
          <Label>有効期限</Label>
        </View>
        <Field style={{ padding: 8, marginBottom: 16 }}>
          <CardExpiryElement {...createOptions(this.props.fontSize)} />
        </Field>
        <View style={{ marginBottom: 8 }}>
          <Label>CVC</Label>
        </View>
        <Field style={{ padding: 8, marginBottom: 16 }}>
          <CardCVCElement {...createOptions(this.props.fontSize)} />
        </Field>
        <View>
          <Text
            style={{ color: Color.red, fontWeight: 'bold', marginBottom: 8 }}
          >
            {error && error.message}
          </Text>
          <Button disable={this.state.disable} style={{ marginBottom: 8 }}>
            <Text
              style={{
                color: Color.white,
                fontSize: 15,
                fontWeight: 'bold'
              }}
            >
              登録する
            </Text>
          </Button>
        </View>
      </form>
    );
  }
}

export default injectStripe(CardForm);
