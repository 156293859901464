import React from 'react';

import Color from '../../constants/Color';
import Style from '../../constants/Style';
import View from '../View';

export default function CardPrimitive({ children, style = {} }) {
  return (
    <View
      style={{
        backgroundColor: Color.white,
        zIndex: 1,
        ...Style.shadow.medium,
        ...style
      }}
    >
      {children}
    </View>
  );
}
