import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import {
  BellOutlineIconSvg,
  SettingsIconSvg
} from '../../../../utils/MaterialIconSvg';
import Color from '../../../../constants/Color';
import Header from '../../../../components/Header';
import ModelTabContainer from '../../../../containers/model/ModelTabContainer';
import Section from '../../../../components/Section/Section';
import SectionTransitItem from '../../../../components/Section/SectionTransitItem';
import Text from '../../../../components/Text';
import View from '../../../../components/View';
import actions from '../../../../actions';

class ModelMypageIndex extends React.Component {
  componentDidMount() {
    this.props.getRewardHistories();
  }

  render() {
    if (!this.props.user.myself) {
      return null;
    }

    return (
      <ModelTabContainer activeIndex={2} history={this.props.history}>
        <Header
          title="マイページ"
          left={
            <View
              style={{ padding: 4 }}
              onClick={() => this.props.history.goBack()}
            >
              <BellOutlineIconSvg
                fill={Color.black}
                width={24}
                height={24}
                viewBox="0 0 24 24"
                style={{ marginRight: 4 }}
              />
            </View>
          }
          right={
            <View
              style={{ padding: 4 }}
              onClick={() => this.props.history.push('/model/mypage/setting')}
            >
              <SettingsIconSvg
                fill={Color.black}
                width={20}
                height={20}
                viewBox="0 0 24 24"
                style={{ marginRight: 4 }}
              />
            </View>
          }
        />
        <Section
          style={{ alignItems: 'center', padding: 32, marginBottom: 16 }}
        >
          <Text bold style={{ color: Color.darkGrey, fontSize: 15 }}>
            今月の獲得ポイント
          </Text>
          <Text bold style={{ fontSize: 17 }}>
            <Text style={{ color: Color.mazenta, fontSize: 32 }}>
              {this.props.rewardHistory.list.reduce((acc, id) => {
                const history = this.props.rewardHistory.hash[id];

                return history.value + acc;
              }, 0)}
            </Text>
            P
          </Text>
        </Section>
        <Section style={{ marginBottom: 16 }}>
          <SectionTransitItem
            title="プロフィール設定"
            onClick={() =>
              this.props.history.push('/model/mypage/profile/edit')
            }
          />
          <SectionTransitItem
            title="獲得ポイント履歴"
            onClick={() =>
              this.props.history.push('/model/mypage/reward/index')
            }
          />
          <SectionTransitItem
            title="撮影履歴"
            onClick={() => this.props.history.push('/model/mypage/session')}
          />
          <SectionTransitItem
            title="その他"
            onClick={() => this.props.history.push('/model/mypage/other')}
          />
        </Section>
        <Section>
          <SectionTransitItem
            title="ログアウト"
            onClick={() => this.props.signOut()}
          />
        </Section>
      </ModelTabContainer>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModelMypageIndex);
