import { getCollection, getDb, getTimestamp } from '../utils/Firebase';
import ErrorMessages from '../constants/ErrorMessages';
import Like from '../models/Like';
import * as types from '../constants/ActionTypes';

// get like
function fetchGetLike() {
  return {
    type: types.FETCH_GET_LIKE
  };
}

function fetchGetLikeSuccess(id, params) {
  return {
    type: types.FETCH_GET_LIKE_SUCCESS,
    state: {
      id,
      params
    }
  };
}

function fetchGetLikeFailed(error) {
  return {
    type: types.FETCH_GET_LIKE_FAILED,
    error
  };
}

export function recoverGetLike() {
  return {
    type: types.FETCH_GET_LIKE_RECOVER
  };
}

export function getLike(consumerId, modelId) {
  return async dispatch => {
    dispatch(fetchGetLike());

    const id = `${consumerId}_${modelId}`;

    try {
      const like = await Like.get(consumerId, modelId);

      return dispatch(fetchGetLikeSuccess(id, like));
    } catch (error) {
      if (error) {
        return dispatch(
          fetchGetLikeFailed({
            code: error.code,
            message: ErrorMessages[error.code]
          })
        );
      }
    }
  };
}

// create like
function fetchCreateLike() {
  return {
    type: types.FETCH_CREATE_LIKE
  };
}

function fetchCreateLikeSuccess(id, params) {
  return {
    type: types.FETCH_CREATE_LIKE_SUCCESS,
    state: {
      id,
      params
    }
  };
}

function fetchCreateLikeFailed(error) {
  return {
    type: types.FETCH_CREATE_LIKE_FAILED,
    error
  };
}

export function recoverCreateLike() {
  return {
    type: types.FETCH_CREATE_LIKE_RECOVER
  };
}

export function upsertLike(consumerId, modelId, direction) {
  return (dispatch, getState) => {
    dispatch(fetchCreateLike());

    const db = getDb();

    const likeId = `${consumerId}_${modelId}`;

    const like = getCollection('likeHistories')
      .doc(consumerId)
      .collection('models')
      .doc(modelId);

    let likeHistory = {};

    return db
      .runTransaction(transaction => {
        return transaction.get(like).then(doc => {
          if (doc.exists) {
            likeHistory = {
              ...doc.data(),
              ...direction,
              createdAt: getTimestamp()
            };
          } else {
            likeHistory = {
              ...direction,
              createdAt: getTimestamp()
            };
          }

          return transaction.set(like, likeHistory);
        });
      })
      .then(() => {
        return dispatch(fetchCreateLikeSuccess(likeId, likeHistory));
      })
      .catch(error => {
        return dispatch(
          fetchCreateLikeFailed({
            code: error.code,
            message: ErrorMessages[error.code]
          })
        );
      });
  };
}
