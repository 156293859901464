import React from 'react';

import View from './View';

export default function Plate({ children, style = {}, ...rest }) {
  return (
    <View
      style={{
        zIndex: 1,
        boxShadow: '0px 0px 4px rgba(102,102,102,0.5)',
        ...style
      }}
      {...rest}
    >
      {children}
    </View>
  );
}
