import React from 'react';

import Color from '../../constants/Color';
import StatusLabel from '../StatusLabel';

export default function({ status, style = {} }) {
  return (
    <StatusLabel
      status={status}
      config={{
        pending: {
          color: Color.yellowOrange,
          label: '未確定'
        },
        confirmed: {
          color: Color.green,
          label: '確定済'
        },
        joined: {
          color: Color.celadonBlue,
          label: '撮影中'
        },
        dismissed: {
          color: Color.darkGrey,
          label: '解散済'
        },
        canceled: {
          color: Color.red,
          label: '中止'
        }
      }}
      style={style}
    />
  );
}
