export default class AcademicBackground {
  static getItems(withDefault = true) {
    const items = [
      { value: 'JuniorCollege', label: '短大/専門卒' },
      { value: 'high', label: '高校卒' },
      { value: 'collage', label: '大学卒' },
      { value: 'master', label: '大学院卒' },
      { value: 'other', label: 'その他' }
    ];

    if (withDefault) {
      items.unshift({
        label: '未選択',
        value: 'unselected',
        isDefault: true
      });
    }

    return items.map(item => ({ type: 'impression', ...item }));
  }

  static getLabel(value) {
    return AcademicBackground.getItems().find(item => item.value === value)
      .label;
  }
}
