import { getCollection, getDb, uploadImage } from '../utils/Firebase';
import Consumer from '../models/Consumer';
import ErrorMessages from '../constants/ErrorMessages';
import Model from '../models/Model';
import Supporter from '../models/Supporter';
import PaymentInfo from '../models/PaymentInfo';
import User from '../models/User';
import UserMetadata from '../models/UserMetadata';
import * as types from '../constants/ActionTypes';
import ConsumerMetadata from '../models/ConsumerMetadata';

// get user
function fetchGetMyselfUser() {
  return {
    type: types.FETCH_GET_MYSELF_USER
  };
}

function fetchGetMyselfUserSuccess(id, user) {
  return {
    type: types.FETCH_GET_MYSELF_USER_SUCCESS,
    state: {
      id,
      user
    }
  };
}

function fetchGetMyselfUserFailed(error) {
  return {
    type: types.FETCH_GET_MYSELF_USER_FAILED,
    error
  };
}

export function recoverGetMyselfUser() {
  return {
    type: types.FETCH_GET_MYSELF_USER_RECOVER
  };
}

export function getMyselfUser(uid) {
  return (dispatch, getState) => {
    const { auth } = getState();

    let id = uid;

    if (!uid) {
      id = auth.uid;
    }

    dispatch(fetchGetMyselfUser());

    const users = getCollection('users');

    const query = users.doc(id);

    return query
      .get()
      .then(async doc => {
        const userMetadataDoc = await getCollection('userMetadata')
          .doc(id)
          .get();

        const metadata = userMetadataDoc.data();

        let consumerData = {};

        let consumerMetadataData = {};

        let badges = [];

        if (metadata.roles.includes('consumer')) {
          const consumerDoc = await getCollection('consumers')
            .doc(id)
            .get();

          consumerData = new Consumer(consumerDoc.data()).object;

          const consumerMetadataDoc = await getCollection('consumerMetadata')
            .doc(id)
            .get();

          consumerMetadataData = consumerMetadataDoc.data();

          const { docs: badgeDocs } = await getCollection('consumers')
            .doc(uid)
            .collection('badges')
            .get();

          badges = badgeDocs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
        }

        let modelData = {};

        let modelMetadataData = {};

        if (metadata.roles.includes('model')) {
          modelData = await Model.get(doc.id);

          const modelMetadataDoc = await getCollection('modelMetadata')
            .doc(id)
            .get();

          modelMetadataData = modelMetadataDoc.data();

          const { docs: badgeDocs } = await getCollection('models')
            .doc(uid)
            .collection('badges')
            .get();

          badges = badgeDocs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
        }

        let supporterData = {};

        let supporterMetadataData = {};

        if (metadata.roles.includes('supporter')) {
          supporterData = await Supporter.get(doc.id);

          const supporterMetadataDoc = await getCollection('supporterMetadata')
            .doc(id)
            .get();

          supporterMetadataData = supporterMetadataDoc.data();

          const { docs: badgeDocs } = await getCollection('supporters')
            .doc(uid)
            .collection('badges')
            .get();

          badges = badgeDocs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
        }

        const { docs: paymentInfoDocs } = await getCollection('consumers')
          .doc(id)
          .collection('paymentInfos')
          .get();

        const paymentInfos = paymentInfoDocs.map(doc =>
          new PaymentInfo({ id: doc.id, ...doc.data() }).fromFirestore()
        );

        const user = new User({
          id: doc.id,
          paymentInfos,
          badges,
          ...doc.data(),
          ...userMetadataDoc.data(),
          ...consumerMetadataData,
          ...modelMetadataData,
          ...supporterMetadataData
        }).fromFirestore();

        return dispatch(
          fetchGetMyselfUserSuccess(id, {
            ...user,
            ...modelData,
            ...consumerData,
            ...supporterData
          })
        );
      })
      .catch(error => {
        if (error) {
          return dispatch(fetchGetMyselfUserFailed(error));
        }
      });
  };
}

// get user
function fetchGetUser() {
  return {
    type: types.FETCH_GET_USER
  };
}

function fetchGetUserSuccess(id, user) {
  return {
    type: types.FETCH_GET_USER_SUCCESS,
    state: {
      id,
      user
    }
  };
}

function fetchGetUserFailed(error) {
  return {
    type: types.FETCH_GET_USER_FAILED,
    error
  };
}

export function recoverGetUser() {
  return {
    type: types.FETCH_GET_USER_RECOVER
  };
}

export function getUser(id) {
  return dispatch => {
    dispatch(fetchGetUser());

    let metadata;

    return getCollection('userMetadata')
      .doc(id)
      .get()
      .then(metadataDoc => {
        metadata = metadataDoc.data();
      })
      .then(params => {
        const users = getCollection('users');

        const query = users.doc(id);

        return query.get();
      })
      .then(async doc => {
        const user = {
          id: doc.id,
          ...metadata,
          ...doc.data()
        };

        let model = {};

        if (metadata.roles.includes('model')) {
          model = await Model.get(doc.id);
        }

        return dispatch(fetchGetUserSuccess(id, { ...user, ...model }));
      })
      .catch(error => {
        if (error) {
          return dispatch(fetchGetUserFailed(error));
        }
      });
  };
}

// update an user
function fetchUpdateMyselfUser() {
  return {
    type: types.FETCH_UPDATE_MYSELF_USER
  };
}

function fetchUpdateMyselfUserSuccess(id, user) {
  return {
    type: types.FETCH_UPDATE_MYSELF_USER_SUCCESS,
    state: {
      id,
      user
    }
  };
}

function fetchUpdateMyselfUserFailed(error) {
  return {
    type: types.FETCH_UPDATE_MYSELF_USER_FAILED,
    error
  };
}

export function recoverUpdateMyselfUser() {
  return {
    type: types.FETCH_UPDATE_MYSELF_USER_RECOVER
  };
}

export function updateMyselfUser({ iconImage, ...params }) {
  return (dispatch, getState) => {
    const { auth } = getState();

    const id = auth.uid;

    dispatch(fetchUpdateMyselfUser());

    const users = getCollection('users');

    const user = users.doc(id);

    const {
      consumer = null,
      model = null,
      userMetadata = null,
      consumerMetadata = null,
      ...updateParams
    } = params;

    const db = getDb();

    return uploadImage(`/users/${id}/icons`, iconImage).then(
      async imagePath => {
        if (consumer) {
          await Consumer.update(id, consumer);
        }

        if (model) {
          await Model.update(id, model);
        }

        if (userMetadata) {
          await UserMetadata.update(id, userMetadata);
        }

        if (consumerMetadata) {
          await ConsumerMetadata.update(id, consumerMetadata);
        }

        let iconImageObject = imagePath
          ? { iconImage: { uri: imagePath } }
          : {};

        return db
          .runTransaction(transaction => {
            return transaction.get(user).then(doc => {
              if (!doc.exists) {
                throw new Error('update/not-exist');
              }

              return transaction.update(user, {
                ...iconImageObject,
                ...updateParams
              });
            });
          })
          .then(() => {
            return dispatch(
              fetchUpdateMyselfUserSuccess(id, {
                ...updateParams,
                ...model,
                ...userMetadata,
                ...consumer,
                ...iconImageObject
              })
            );
          })
          .catch(error => {
            return dispatch(
              fetchUpdateMyselfUserFailed({
                code: error.code,
                message: ErrorMessages[error.code]
              })
            );
          });
      }
    );
  };
}
