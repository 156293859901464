import React from 'react';

import Color from '../../constants/Color';
import Label from './Label';
import MaterialIcon from '../../utils/MaterialIcon';
import Paragraph from '../Paragraph';
import Select from '../../utils/Select';
import Text from '../Text';
import View from '../View';

export default class SelectFormField extends React.Component {
  state = {
    isRefreshing: false
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.defaultValue !== nextProps.defaultValue) {
      this.setState({ isRefreshing: true });
    } else if (!this.state.isRefreshing && nextState.isRefreshing) {
      this.setState({ isRefreshing: false });
    }

    return true;
  }

  render() {
    const {
      label,
      labelWidth = 70,
      name,
      value,
      direction = 'row',
      verification = false,
      errorMessage,
      helpText,
      onChangeValue = () => {},
      items,
      staticControl,
      style = {},
      defaultValue,
      ...rest
    } = this.props;

    const item = items.find(item => item.value === defaultValue);

    const containerStyle =
      direction === 'row' ? { flexDirection: 'row', alignItems: 'center' } : {};

    const verifyColor = !Boolean(errorMessage) ? Color.green : Color.warmGrey;

    return (
      <View style={{ ...style }}>
        <View style={containerStyle}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: direction === 'row' ? 0 : 8
            }}
          >
            <Label
              style={{
                minWidth: labelWidth
              }}
            >
              {label}
            </Label>
            {!staticControl && verification && (
              <View style={{ marginLeft: 4 }}>
                <MaterialIcon
                  icon="check_circle"
                  size={20}
                  color={verifyColor}
                />
              </View>
            )}
          </View>
          <View style={{ flex: 1 }}>
            {staticControl ? (
              <View>
                <Paragraph style={{ fontWeight: 'bold' }}>
                  {item.label}
                </Paragraph>
              </View>
            ) : (
              <View>
                {!this.state.isRefreshing && (
                  <Select
                    name={name}
                    onChange={result => {
                      if (rest.isMulti) {
                        return onChangeValue(result.map(item => item.value));
                      }

                      return onChangeValue(result && result.value);
                    }}
                    options={items}
                    defaultValue={
                      Array.isArray(defaultValue)
                        ? defaultValue.map(value =>
                            items.find(item => item.value === value)
                          )
                        : items.find(item => item.value === defaultValue)
                    }
                    {...rest}
                  />
                )}
              </View>
            )}
          </View>
        </View>
        {helpText && (
          <Text
            style={{
              marginTop: 4,
              fontSize: 11,
              color: Color.grey,
              paddingLeft: direction === 'row' ? labelWidth : 0
            }}
          >
            {helpText}
          </Text>
        )}
      </View>
    );
  }
}
