import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../actions';
import TextFormField from '../../components/Form/TextFormField';
import Headline from '../../components/Headline';
import Image from '../../components/Image';
import Plate from '../../components/Plate';
import View from '../../components/View';
import Color from '../../constants/Color';
import {
  MODEL_LOGIN_VALIDATION_LABELS,
  MODEL_LOGIN_VALIDATION_RULES
} from '../../constants/Validations';
import { validate } from '../../utils/Validation';
import Button from '../../components/Button';
import ResponseDispatcher from '../../components/ResponseDispatcher';

class Login extends React.Component {
  state = {
    email: '',
    password: ''
  };

  componentDidMount() {
    this.props.signOut();
  }

  render() {
    return (
      <View style={{ backgroudColor: Color.white }}>
        <View style={{ position: 'relative' }}>
          <Image
            source={require('../../assets/img/model/login/model-login-header.jpg')}
            style={{ height: 538, position: 'absolute' }}
          />
          <View
            style={{
              padding: '154px 16px 16px 16px'
            }}
          >
            <Plate style={{ backgroundColor: Color.white, padding: 16 }}>
              <Headline style={{ textAlign: 'center', marginBottom: 24 }}>
                ログイン
              </Headline>
              <TextFormField
                label="メールアドレス"
                direction="column"
                placeholder="メールアドレスを入力してください。"
                value={this.state.email}
                onChange={email => {
                  this.setState({ email });
                }}
                errorMessage={validate({
                  label: MODEL_LOGIN_VALIDATION_LABELS.email,
                  value: this.state.email,
                  rule: MODEL_LOGIN_VALIDATION_RULES.email
                })}
                style={{ marginBottom: 16 }}
              />
              <TextFormField
                label="パスワード"
                direction="column"
                placeholder="パスワードを入力してください。"
                value={this.state.password}
                type="password"
                onChange={password => {
                  this.setState({ password });
                }}
                errorMessage={validate({
                  label: MODEL_LOGIN_VALIDATION_LABELS.password,
                  value: this.state.password,
                  rule: MODEL_LOGIN_VALIDATION_RULES.password
                })}
                style={{ marginBottom: 24 }}
              />
              <Button
                onClick={() => {
                  this.props.signInWithEmailAndPassword(this.state);
                }}
              >
                ログインする
              </Button>
            </Plate>
          </View>
        </View>
        <ResponseDispatcher
          isLoading={this.props.user.isGetMyselfLoading}
          isFailed={this.props.user.isGetMyselfFailed}
          onComplete={() => {
            if (this.props.auth.roles[0] === 'model') {
              this.props.history.push('/model/find');
            } else if (this.props.auth.roles[0] === 'supporter') {
              this.props.history.push('/supporter/message');
            }
          }}
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
