import { getHash } from '../utils/Reducer';
import * as types from '../constants/ActionTypes';

const initialState = {
  list: [],
  hash: {},
  isGetLoading: false,
  isGetFailed: false,
  getError: null,
  isCreateLoading: false,
  isCreateFailed: false,
  createError: null,
  isUpdateLoading: false,
  isUpdateFailed: false,
  updateError: null
};

export default (state = initialState, action) => {
  let newHash;

  switch (action.type) {
    // get session history
    case types.FETCH_GET_REVIEW_HISTORIES:
      return {
        ...state,
        list: [],
        isGetLoading: true
      };

    case types.FETCH_GET_REVIEW_HISTORIES_SUCCESS:
      newHash = getHash(action.state.list, state.hash, 'id');

      return {
        ...state,
        list: action.state.list.map(item => item.id),
        hash: newHash,
        isGetLoading: false,
        isGetFailed: false
      };

    case types.FETCH_GET_REVIEW_HISTORIES_FAILED:
      return {
        ...state,
        isGetLoading: false,
        isGetFailed: true,
        getError: action.error
      };

    case types.FETCH_GET_REVIEW_HISTORIES_RECOVER:
      return {
        ...state,
        isGetFailed: false
      };

    // get review history
    case types.FETCH_GET_REVIEW_HISTORY:
      return {
        ...state,
        list: [],
        isGetLoading: true
      };

    case types.FETCH_GET_REVIEW_HISTORY_SUCCESS:
      return {
        ...state,
        list: [action.state.id],
        hash: {
          ...state.hash,
          [action.state.id]: action.state.params
        },
        isGetLoading: false,
        isGetFailed: false
      };

    case types.FETCH_GET_REVIEW_HISTORY_FAILED:
      return {
        ...state,
        isGetLoading: false,
        isGetFailed: true,
        getError: action.error
      };

    case types.FETCH_GET_REVIEW_HISTORY_RECOVER:
      return {
        ...state,
        isGetFailed: false
      };

    // create review history
    case types.FETCH_CREATE_REVIEW_HISTORY:
      return {
        ...state,
        isCreateLoading: true
      };

    case types.FETCH_CREATE_REVIEW_HISTORY_SUCCESS:
      return {
        ...state,
        list: [action.state.id, ...state.list],
        hash: {
          ...state.hash,
          [action.state.id]: action.state.params
        },
        isCreateLoading: false,
        isCreateFailed: false
      };

    case types.FETCH_CREATE_REVIEW_HISTORY_FAILED:
      return {
        ...state,
        isCreateLoading: false,
        isCreateFailed: true,
        createError: action.error
      };

    case types.FETCH_CREATE_REVIEW_HISTORY_RECOVER:
      return {
        ...state,
        isCreateFailed: false
      };

    default:
      return state;
  }
};
