import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import actions from '../../actions';
import CardOuterTitle from '../../components/Card/CardOuterTitle';
import ScrollToTop from '../../components/ScrollToTop';
import Text from '../../components/Text';
import View from '../../components/View';
import Color from '../../constants/Color';
import Auth from '../../containers/Auth';
import Application from './application';
import DocumentDetail from './document/detail';
import Login from './login';
import ModelFindConsumerDetail from './model/find/consumer/detail';
import ModelFindIndex from './model/find/index';
import ModelMessageDetail from './model/message/detail';
import ModelMessageIndex from './model/message/index';
import ModelMypageIndex from './model/mypage/index';
import ModelMypageNotificationIndex from './model/mypage/notification/index';
import ModelMypageOtherDetail from './model/mypage/other/detail';
import ModelMypageOtherIndex from './model/mypage/other/index';
import ModelMypageProfileEdit from './model/mypage/profile/edit';
import ModelMypageProfileGalleryEdit from './model/mypage/profile/gallery/edit';
import ModelMypageProfileInfoEdit from './model/mypage/profile/info/edit';
import ModelMypageProfileInstructionEdit from './model/mypage/profile/instruction/edit';
import ModelMypageProfileNameEdit from './model/mypage/profile/name/edit';
import ModelMypageProfilePrEdit from './model/mypage/profile/pr/edit';
import ModelMypageProfilePreview from './model/mypage/profile/preview';
import ModelMypageProfilePriceEdit from './model/mypage/profile/price/edit';
import ModelMypageProfileTagEdit from './model/mypage/profile/tag/edit';
import ModelMypageRewardIndex from './model/mypage/reward/index';
import ModelMypageSessionDetail from './model/mypage/session/detail';
import ModelMypageSessionIndex from './model/mypage/session/index';
import ModelMypageSettingEmailEdit from './model/mypage/setting/email/edit';
import ModelMypageSettingIndex from './model/mypage/setting/index';
import ModelRegister from './model/register';
import SupporterMessageDetail from './supporter/message/detail';
import SupporterMessageIndex from './supporter/message/index';
import Top from './top';
import UserFindIndex from './user/find/index';
import UserFindModelDetail from './user/find/model/detail';
import UserMessageDetail from './user/message/detail';
import UserMessageIndex from './user/message/index';
import UserMypageIndex from './user/mypage/index';
import UserMypageNotificationIndex from './user/mypage/notification/index';
import UserMypageOtherDetail from './user/mypage/other/detail';
import UserMypageOtherIndex from './user/mypage/other/index';
import UserMypagePaymentInfoDetail from './user/mypage/paymentInfo/detail';
import UserMypagePaymentInfoIndex from './user/mypage/paymentInfo/index';
import UserMypagePointIndex from './user/mypage/point/index';
import UserMypageProfileEdit from './user/mypage/profile/edit';
import UserMypageProfileGalleryEdit from './user/mypage/profile/gallery/edit';
import UserMypageProfileInfoEdit from './user/mypage/profile/info/edit';
import UserMypageProfileNameEdit from './user/mypage/profile/name/edit';
import UserMypageProfilePrEdit from './user/mypage/profile/pr/edit';
import UserMypageProfilePreview from './user/mypage/profile/preview';
import UserMypageSessionDetail from './user/mypage/session/detail';
import UserMypageSessionIndex from './user/mypage/session/index';
import UserMypageSettingEmailEdit from './user/mypage/setting/email/edit';
import UserMypageSettingIndex from './user/mypage/setting/index';
import UserMypageShopIndex from './user/mypage/shop/index';

// function WaitingComponent(Component) {
//   return props => (
//     <React.Suspense fallback={<div>Loading...</div>}>
//       <Component {...props} />
//     </React.Suspense>
//   );
// }

class Routes extends React.Component {
  render() {
    if (this.props.config.hash.isMaintenance) {
      return (
        <View style={{ padding: 8 }}>
          <CardOuterTitle>メンテナンス中</CardOuterTitle>
          <Text style={{ color: Color.white }}>
            しばらく経ってからアクセスをお願いします。
          </Text>
        </View>
      );
    }

    return (
      <BrowserRouter>
        <ScrollToTop>
          <div>
            <Switch>
              <Route exact path="/" component={Top} />
              <Route exact path="/application" component={Application} />
              <Route exact path="/model/register" component={ModelRegister} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/document/:type" component={DocumentDetail} />
              <Auth>
                <Switch>
                  <Route
                    exact
                    path="/model/message"
                    component={ModelMessageIndex}
                  />
                  <Route
                    exact
                    path="/model/message/:chatId"
                    component={ModelMessageDetail}
                  />
                  <Route
                    exact
                    path="/model/mypage"
                    component={ModelMypageIndex}
                  />
                  <Route
                    exact
                    path="/model/mypage/notification"
                    component={ModelMypageNotificationIndex}
                  />
                  <Route
                    exact
                    path="/model/mypage/profile/edit"
                    component={ModelMypageProfileEdit}
                  />
                  <Route
                    exact
                    path="/model/mypage/profile/preview"
                    component={ModelMypageProfilePreview}
                  />
                  <Route
                    exact
                    path="/model/mypage/profile/name/edit"
                    component={ModelMypageProfileNameEdit}
                  />
                  <Route
                    exact
                    path="/model/mypage/profile/gallery/edit"
                    component={ModelMypageProfileGalleryEdit}
                  />
                  <Route
                    exact
                    path="/model/mypage/profile/price/edit"
                    component={ModelMypageProfilePriceEdit}
                  />
                  <Route
                    exact
                    path="/model/mypage/profile/tag/edit"
                    component={ModelMypageProfileTagEdit}
                  />
                  <Route
                    exact
                    path="/model/mypage/profile/info/edit"
                    component={ModelMypageProfileInfoEdit}
                  />
                  <Route
                    exact
                    path="/model/mypage/profile/pr/edit"
                    component={ModelMypageProfilePrEdit}
                  />
                  <Route
                    exact
                    path="/model/mypage/profile/instruction/edit"
                    component={ModelMypageProfileInstructionEdit}
                  />
                  <Route
                    exact
                    path="/model/mypage/setting"
                    component={ModelMypageSettingIndex}
                  />
                  <Route
                    exact
                    path="/model/mypage/session/:sessionId"
                    component={ModelMypageSessionDetail}
                  />
                  <Route
                    exact
                    path="/model/mypage/session"
                    component={ModelMypageSessionIndex}
                  />
                  <Route
                    exact
                    path="/model/mypage/reward/index"
                    component={ModelMypageRewardIndex}
                  />
                  <Route exact path="/model/find" component={ModelFindIndex} />
                  <Route
                    exact
                    path="/model/find/consumer/:consumerId"
                    component={ModelFindConsumerDetail}
                  />
                  <Route
                    exact
                    path="/model/mypage/setting/email/edit"
                    component={ModelMypageSettingEmailEdit}
                  />
                  <Route
                    exact
                    path="/model/mypage/other"
                    component={ModelMypageOtherIndex}
                  />
                  <Route
                    exact
                    path="/model/mypage/other/:type"
                    component={ModelMypageOtherDetail}
                  />
                  <Route
                    exact
                    path="/supporter/message"
                    component={SupporterMessageIndex}
                  />
                  <Route
                    exact
                    path="/supporter/message/:chatId"
                    component={SupporterMessageDetail}
                  />
                  <Route exact path="/user/find" component={UserFindIndex} />
                  <Route
                    exact
                    path="/user/find/model/:modelId"
                    component={UserFindModelDetail}
                  />
                  <Route
                    exact
                    path="/user/message"
                    component={UserMessageIndex}
                  />
                  <Route
                    exact
                    path="/user/message/:chatId"
                    component={UserMessageDetail}
                  />
                  <Route
                    exact
                    path="/user/mypage"
                    component={UserMypageIndex}
                  />
                  <Route
                    exact
                    path="/user/mypage/notification"
                    component={UserMypageNotificationIndex}
                  />
                  <Route
                    exact
                    path="/user/mypage/point"
                    component={UserMypagePointIndex}
                  />
                  <Route
                    exact
                    path="/user/mypage/setting/email/edit"
                    component={UserMypageSettingEmailEdit}
                  />
                  <Route
                    exact
                    path="/user/mypage/setting"
                    component={UserMypageSettingIndex}
                  />
                  <Route
                    exact
                    path="/user/mypage/session/:sessionId"
                    component={UserMypageSessionDetail}
                  />
                  <Route
                    exact
                    path="/user/mypage/session"
                    component={UserMypageSessionIndex}
                  />
                  <Route
                    exact
                    path="/user/mypage/shop"
                    component={UserMypageShopIndex}
                  />
                  <Route
                    exact
                    path="/user/mypage/profile/edit"
                    component={UserMypageProfileEdit}
                  />
                  <Route
                    exact
                    path="/user/mypage/profile/preview"
                    component={UserMypageProfilePreview}
                  />
                  <Route
                    exact
                    path="/user/mypage/profile/name/edit"
                    component={UserMypageProfileNameEdit}
                  />
                  <Route
                    exact
                    path="/user/mypage/profile/gallery/edit"
                    component={UserMypageProfileGalleryEdit}
                  />
                  <Route
                    exact
                    path="/user/mypage/profile/info/edit"
                    component={UserMypageProfileInfoEdit}
                  />
                  <Route
                    exact
                    path="/user/mypage/profile/pr/edit"
                    component={UserMypageProfilePrEdit}
                  />
                  <Route
                    exact
                    path="/user/mypage/paymentInfo/index"
                    component={UserMypagePaymentInfoIndex}
                  />
                  <Route
                    exact
                    path="/user/mypage/paymentInfo/:paymentInfoId"
                    component={UserMypagePaymentInfoDetail}
                  />
                  <Route
                    exact
                    path="/user/mypage/other"
                    component={UserMypageOtherIndex}
                  />
                  <Route
                    exact
                    path="/user/mypage/other/:type"
                    component={UserMypageOtherDetail}
                  />
                </Switch>
              </Auth>
            </Switch>
          </div>
        </ScrollToTop>
      </BrowserRouter>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Routes);
