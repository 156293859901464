export default class Permission {
  static getItems(withDefault = false) {
    const items = [{ value: true, label: 'OK' }, { value: false, label: 'NG' }];

    if (withDefault) {
      items.unshift({
        type: 'impression',
        label: '未選択',
        value: 'unselected',
        isDefault: true
      });
    }

    return items.map(item => item);
  }

  static getLabel(value) {
    return Permission.getItems().find(item => item.value === value).label;
  }
}
