import React from 'react';

import Hr from '../Hr';
import Section from '../Section/Section';
import SectionItem from '../Section/SectionItem';
import Session from '../../utils/Session';
import Text from '../Text';
import UserIcon from '../Icons/UserIcon';
import View from '../View';

export default function SessionDetail({ data, style = {} }) {
  return (
    <Section style={{ padding: 16, ...style }}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: 16
        }}
      >
        <UserIcon
          source={data.user.iconImage}
          size={48}
          style={{ marginRight: 16 }}
        />
        <View style={{ flexDirection: 'row', alignItems: 'baseline' }}>
          <Text bold style={{ marginRight: 8 }}>
            {data.user.name}
          </Text>
          <Text>の撮影</Text>
        </View>
      </View>
      <Hr style={{ marginBottom: 16 }} />
      <SectionItem
        title="価格"
        content={`${data.price}P`}
        style={{ marginBottom: 16 }}
      />
      <SectionItem
        title="撮影時間"
        content={`${data.hours}時間`}
        style={{ marginBottom: 16 }}
      />
      <SectionItem
        title={`延長(${Session.calculateOverMinutes(data)}分)`}
        content={`${Session.calculateOverPrice(data)}P`}
        style={{ marginBottom: 16 }}
      />
      <SectionItem
        title={`${data.optionName || 'オプション'}価格`}
        content={`${data.optionPrice}P`}
        style={{ marginBottom: 16 }}
      />
      <Hr style={{ marginBottom: 16 }} />
      <SectionItem
        title="合計"
        content={`${Session.calculateBillingPrice(data)}P`}
      />
    </Section>
  );
}
