const VALUES = [
  3500,
  4000,
  4500,
  5000,
  5500,
  6000,
  6500,
  7000,
  7500,
  8000,
  8500,
  9000,
  9500,
  10000,
  11000,
  12000,
  13000,
  14000,
  15000,
  16000,
  17000,
  18000,
  19000,
  20000,
  25000,
  30000,
  35000,
  40000,
  45000,
  50000,
  60000,
  70000,
  80000,
  90000,
  100000
];

export default class Price {
  static getItems(withDefault = false) {
    const items = VALUES.map(value => ({ label: `${value}P`, value }));

    if (withDefault) {
      items.unshift({ label: '未選択', value: 'unselected', isDefault: true });
    }

    return items;
  }

  static getLabel(value) {
    return Price.getItems().find(item => item.value === value).label;
  }

  static getPriceTermsText(price, hours, optionPrice, optionName) {
    return `${Math.floor(price)}P (30分) × ${hours}時間
              ${
                optionPrice
                  ? ` + ${optionPrice}P (${optionName || 'オプション'}料金)`
                  : ''
              }`;
  }

  static getPriceResultText(price, hours, optionPrice) {
    return `= ${Math.floor(price) * hours * 2 + optionPrice}P`;
  }

  static calculateTotalPrice(price, hours, optionPrice) {
    return Math.round(price * hours * 2 + optionPrice);
  }
}
