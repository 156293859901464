import * as types from '../constants/ActionTypes';

const initialState = {
  isCreateLoading: false,
  isCreateFailed: false,
  createError: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    // create application
    case types.FETCH_CREATE_APPLICATION:
      return {
        ...state,
        isCreateLoading: true
      };

    case types.FETCH_CREATE_APPLICATION_SUCCESS:
      return {
        ...state,
        isCreateLoading: false,
        isCreateFailed: false
      };

    case types.FETCH_CREATE_APPLICATION_FAILED:
      return {
        ...state,
        isCreateLoading: false,
        isCreateFailed: true,
        createError: action.error
      };

    case types.FETCH_CREATE_APPLICATION_RECOVER:
      return {
        ...state,
        isCreateFailed: false
      };

    default:
      return state;
  }
};
