import React from 'react';

import Color from '../../constants/Color';
import MaterialIcon from '../../utils/MaterialIcon';
import Text from '../Text';
import View from '../View';

export default function SectionTransitItem({
  title,
  content,
  style = {},
  onClick = () => {}
}) {
  return (
    <View
      onClick={onClick}
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: Color.white,
        borderBottom: `1px solid ${Color.lightGrey}`,
        padding: '16px',
        ...style
      }}
    >
      <Text style={{ color: Color.black, fontSize: 15, minWidth: 100 }}>
        {title}
      </Text>
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          overflow: 'hidden',
          alignItems: 'center'
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            flex: 1,
            overflow: 'hidden',
            justifyContent: 'flex-end'
          }}
        >
          <Text
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              color: Color.darkGrey,
              fontSize: 13
            }}
          >
            {content}
          </Text>
        </View>
        <View style={{ paddingLeft: 16 }}>
          <MaterialIcon
            icon="arrow_forward_ios"
            color={Color.black}
            size={15}
          />
        </View>
      </View>
    </View>
  );
}
