import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import { SortIconSvg } from '../../../../utils/MaterialIconSvg';
import CardProfileItem from '../../../../components/List/CardProfileItem';
import Color from '../../../../constants/Color';
import Header from '../../../../components/Header';
import ModelTabContainer from '../../../../containers/model/ModelTabContainer';
import PopupSelect from '../../../../components/PopupSelect/PopupSelect';
import Prefecture from '../../../../models/tag/Prefecture';
import ProfileList from '../../../../components/List/ProfileList';
import Select from '../../../../utils/Select';
import View from '../../../../components/View';
import actions from '../../../../actions';

class ModelFindIndex extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenSortPopup: false,
      address: (props.user.myself && props.user.myself.address) || 'unselected',
      orderBy: ['statusChangedAt', 'desc']
    };
  }

  initialize(props, address) {
    props.getConsumers({
      where: ['address', '==', address || 'unselected'],
      orderBy: this.state.orderBy
    });
  }

  componentDidMount() {
    if (this.props.user.myself) {
      this.initialize(this.props, this.props.user.myself.address);
    }
  }

  shouldComponentUpdate(nextProps) {
    if (
      nextProps.user.myself !== this.props.user.myself &&
      nextProps.user.myself
    ) {
      this.setState({ address: nextProps.user.myself.address }, () =>
        this.initialize(nextProps, nextProps.user.myself.address)
      );
    }

    return true;
  }

  render() {
    if (!this.props.user.myself) {
      return null;
    }

    return (
      <ModelTabContainer activeIndex={0} history={this.props.history}>
        <Header
          title={
            <View style={{ width: 120 }}>
              <Select
                name="area"
                onChange={result => {
                  this.setState({ address: result.value }, () =>
                    this.initialize(this.props, result.value)
                  );
                }}
                options={Prefecture.getItems()}
                defaultValue={Prefecture.getItems().find(
                  item => item.value === this.props.user.myself.address
                )}
              />
            </View>
          }
          right={
            <View
              onClick={() => {
                this.setState({ isOpenSortPopup: true });
              }}
            >
              <SortIconSvg
                fill={Color.black}
                width={24}
                height={24}
                viewBox="0 0 24 24"
                style={{ marginRight: 4 }}
              />
            </View>
          }
          style={{
            zIndex: 10,
            padding: 8,
            borderBottom: `1px solid ${Color.warmGrey}`,
            alignItems: 'center',
            position: 'relative'
          }}
        />
        <ProfileList
          data={this.props.consumer.list.map(
            id => this.props.consumer.hash[id]
          )}
          renderItem={({ item }) => (
            <CardProfileItem
              {...item}
              style={{ marginRight: 1 }}
              onClick={() => {
                this.props.history.push(`/model/find/consumer/${item.id}`);
              }}
            />
          )}
        />
        <PopupSelect
          visible={this.state.isOpenSortPopup}
          title="ソート"
          items={[
            { label: 'ログイン順', value: ['statusChangedAt', 'desc'] },
            { label: '登録順', value: ['createdAt', 'desc'] },
            { label: 'カード登録順', value: ['cardRegisterAt', 'desc'] }
          ]}
          value={this.state.orderBy}
          onItemClick={item =>
            this.setState({ orderBy: item.value, isOpenSortPopup: false }, () =>
              this.initialize(this.props, this.state.address)
            )
          }
          onClose={() => this.setState({ isOpenSortPopup: false })}
        />
      </ModelTabContainer>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModelFindIndex);
