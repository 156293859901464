import { getCollection, getDb } from '../utils/Firebase';

export default class ConsumerMetadata {
  constructor(state = {}) {
    this.params = {
      currentPoint: state.currentPoint || 0,
      defaultPaymentInfoId: state.defaultPaymentInfoId || null,
      createdAt: state.createdAt,
      updatedAt: state.updatedAt
    };
  }

  get object() {
    return this.params;
  }

  fromFirestore() {
    return {
      ...this.params,
      createdAt: this.params.createdAt && this.params.createdAt.toDate(),
      updatedAt: this.params.updatedAt && this.params.updatedAt.toDate()
    };
  }

  static async update(id, params) {
    const db = getDb();

    const consumerMetadata = getCollection('consumerMetadata').doc(id);

    return db.runTransaction(transaction => {
      return transaction.get(consumerMetadata).then(doc => {
        if (!doc.exists) {
          throw new Error('update/not-exist');
        }

        const { createdAt, updatedAt, ...rest } = params;

        return transaction.update(consumerMetadata, rest);
      });
    });
  }
}
