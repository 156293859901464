import React from 'react';

import { getTextStyle } from '../utils/TextStyle';
import Color from '../constants/Color';
import View from './View';

export default function Paragraph({
  onerow = false,
  bold = false,
  demibold = false,
  mincho = false,
  children,
  containerStyle = {},
  style = {}
}) {
  const textStyle = getTextStyle({ bold, mincho, demibold });

  let content = children;

  if (Array.isArray(children)) {
    // content = [children.reduce((acc, str) => acc + str, '')];
  } else {
    content = String(children).split('\n');
  }

  return (
    <View style={containerStyle}>
      {onerow ? (
        <p
          style={{
            color: Color.black,
            fontSize: 13,
            lineHeight: 1.8,
            ...textStyle,
            ...style
          }}
        >
          {children}
        </p>
      ) : (
        content.map((line, index) => (
          <p
            key={`paragraph-${index}`}
            style={{
              color: Color.black,
              fontSize: 13,
              lineHeight: 1.8,
              ...textStyle,
              ...style
            }}
          >
            {line || `　`}
          </p>
        ))
      )}
    </View>
  );
}
