import { PersistGate } from 'redux-persist/es/integration/react';
import { Provider } from 'react-redux';
import { StripeProvider } from 'react-stripe-elements';
import React from 'react';
import moment from 'moment';

import Config from './utils/Config';
import DesktopRoutes from './routes/desktop/DesktopRoutes';
import FoundationContainer from './containers/FoundationContainer';
import MobileRoutes from './routes/mobile/MobileRoutes';
import configureStore from './store/configureStore';

const { persistor, store } = configureStore();

moment.locale('ja', {
  relativeTime: {
    future: '%s',
    past: '%s',
    s: '%d数秒',
    m: '1分',
    mm: '%d分',
    h: '1時間',
    hh: '%d時間',
    d: '1日',
    dd: '%d日',
    M: '1ヶ月',
    MM: '%dヶ月',
    y: '1年',
    yy: '%d年'
  },
  calendar: {
    lastDay: '[昨日]',
    sameDay: '[今日]',
    nextDay: '[明日]',
    lastWeek: '[先週] dddd',
    nextWeek: '[来週] dddd',
    sameElse: 'L'
  },
  weekdays: [
    '日曜日',
    '月曜日',
    '火曜日',
    '水曜日',
    '木曜日',
    '金曜日',
    '土曜日'
  ],
  weekdaysShort: ['日', '月', '火', '水', '木', '金', '土']
});

// const IS_OVER_MOBILE = window.innerWidth > 480;

// const IS_MOBILE = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
//   navigator.userAgent
// );
const IS_MOBILE = true;

export default class App extends React.Component {
  /** Gets fired when the route changes.
   *  @param {Object} event   "change" event from [preact-router](http://git.io/preact-router)
   *  @param {string} event.url The newly routed URL
   */

  render() {
    return (
      <div id="app">
        {IS_MOBILE ? (
          <div id="frame">
            <StripeProvider apiKey={Config.STRIPE_API_KEY}>
              <Provider store={store}>
                <PersistGate persistor={persistor}>
                  <FoundationContainer />
                  <MobileRoutes />
                </PersistGate>
              </Provider>
            </StripeProvider>
          </div>
        ) : (
          <StripeProvider apiKey={Config.STRIPE_API_KEY}>
            <Provider store={store}>
              <PersistGate persistor={persistor}>
                <FoundationContainer />
                <DesktopRoutes />
              </PersistGate>
            </Provider>
          </StripeProvider>
        )}
      </div>
    );
  }
}
