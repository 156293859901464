import React from 'react';

import Color from '../../constants/Color';
import MessagePlate from '../Chat/MessagePlate';
import View from '../View';

function renderRightSide({ icon, text, color, style = {} }) {
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'flex-end'
      }}
    >
      <MessagePlate color={Color.white} fontSize={13} style={{ maxWidth: 200 }}>
        {text}
      </MessagePlate>
      <View style={{ marginLeft: 8 }}>{icon}</View>
    </View>
  );
}

function renderLeftSide({ icon, text, style = {} }) {
  return (
    <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
      <View style={{ marginRight: 8 }}>{icon}</View>
      <MessagePlate
        style={{ maxWidth: 200 }}
        color={Color.mazenta}
        invert
        bold
        fontSize={13}
      >
        {text}
      </MessagePlate>
    </View>
  );
}

export default function DemoMessageItem({
  side = 'left',
  style = {},
  ...rest
}) {
  if (side === 'left') {
    return <View style={style}>{renderLeftSide({ ...rest })}</View>;
  }

  return <View style={style}>{renderRightSide({ ...rest })}</View>;
}
