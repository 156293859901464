import numeral from 'numeral';

const VALUES = [
  1000,
  3000,
  5000,
  10000,
  30000,
  50000,
  100000,
  200000,
  300000,
  400000,
  500000,
  1000000,
  2000000,
  3000000,
  4000000,
  5000000
];

export default class PointLineup {
  static getItems(withDefault = false) {
    const items = VALUES.map(value => ({
      label: `${numeral(value).format('0,0')}P`,
      value
    }));

    if (withDefault) {
      items.unshift({ label: '未選択', value: 'unselected', isDefault: true });
    }

    return items;
  }

  static getLabel(value) {
    return PointLineup.getItems().find(item => item.value === value).label;
  }
}
