import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../../../../../actions';
import Button from '../../../../../../components/Button';
import SelectFormField from '../../../../../../components/Form/SelectFormField';
import NavigationHeader from '../../../../../../components/Navigator/NavigationHeader';
import Section from '../../../../../../components/Section/Section';
import View from '../../../../../../components/View';
import Model from '../../../../../../models/Model';
import Costume from '../../../../../../models/tag/Costume';
import Impression from '../../../../../../models/tag/Impression';
import Physique from '../../../../../../models/tag/Physique';
import Prefecture from '../../../../../../models/tag/Prefecture';

class ModelMypageProfileTagEdit extends React.Component {
  state = {
    ...new Model().object
  };

  constructor(props) {
    super(props);

    const { myself = {} } = this.props.user;

    this.state = {
      ...new Model(myself || {}).object
    };
  }

  shouldComponentUpdate(nextProps) {
    if (
      this.props.user.isGetMyselfLoading &&
      !nextProps.user.isGetMyselfLoading
    ) {
      const { myself } = nextProps.user;

      this.setState({
        ...new Model(myself).object
      });
    }

    return true;
  }

  render() {
    if (!this.props.user.myself) {
      return null;
    }

    return (
      <View>
        <NavigationHeader title="タグ" goBack={this.props.history.goBack} />
        <Section
          style={{
            padding: 24
          }}
        >
          <SelectFormField
            isMulti
            label="エリア"
            direction="column"
            name="prefectures"
            helpText="活動エリアを選択してください"
            items={Prefecture.getItems(false)}
            defaultValue={this.state.prefectures}
            isClearable
            isSearchable
            onChangeValue={values => {
              this.setState({ prefectures: values });
            }}
            style={{ marginBottom: 16 }}
          />
          <SelectFormField
            isMulti
            label="顔立ち"
            direction="column"
            name="impression"
            helpText="あなたの顔立ちを選択してください"
            items={Impression.getItems(false)}
            defaultValue={this.state.impressions}
            isClearable
            isSearchable
            onChangeValue={values => {
              this.setState({ impressions: values });
            }}
            style={{ marginBottom: 16 }}
          />
          <SelectFormField
            isMulti
            label="体格"
            direction="column"
            name="physique"
            helpText="あなたの体格を選択してください"
            items={Physique.getItems(false)}
            defaultValue={this.state.physiques}
            isClearable
            isSearchable
            onChangeValue={values => {
              this.setState({ physiques: values });
            }}
            style={{ marginBottom: 16 }}
          />
          <SelectFormField
            isMulti
            label="コスチューム"
            direction="column"
            name="costume"
            helpText="あなたの体格を選択してください"
            items={Costume.getItems(false)}
            defaultValue={this.state.costumes}
            isClearable
            isSearchable
            onChangeValue={values => {
              this.setState({ costumes: values });
            }}
            style={{ marginBottom: 16 }}
          />
          <Button
            disable={this.props.user.isUpdateLoading}
            onClick={() => {
              this.props.updateMyselfUser({
                model: {
                  prefectures: this.state.prefectures,
                  impressions: this.state.impressions,
                  physiques: this.state.physiques,
                  costumes: this.state.costumes
                }
              });
            }}
          >
            保存する
          </Button>
        </Section>
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModelMypageProfileTagEdit);
