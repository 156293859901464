export default class AnnualIncome {
  static getItems(withDefault = true) {
    const items = [
      { label: '200万未満', value: 'less200' },
      { label: '200万〜400万', value: 'over200' },
      { label: '400万〜600万', value: 'over400' },
      { label: '600万〜800万', value: 'over600' },
      { label: '800万〜1000万', value: 'over800' },
      { label: '1000万〜1500万', value: 'over1000' },
      { label: '1500万〜2000万', value: 'over1500' },
      { label: '2000万〜3000万', value: 'over2000' },
      { label: '3000万〜4000万', value: 'over3000' },
      { label: '4000万〜5000万', value: 'over4000' },
      { label: '5000万〜3000万', value: 'over5000' }
    ];

    if (withDefault) {
      items.unshift({ label: '未選択', value: 'unselected', isDefault: true });
    }

    return items;
  }

  static getLabel(value) {
    return AnnualIncome.getItems(true).find(item => item.value === value).label;
  }
}
