/* eslint-disable import/first */

// import AccessPointIcon from 'react-material-icon-svg/dist/AccessPoint';
// export const AccessPointIconSvg = AccessPointIcon;

// import AccessPointNetworkIcon from 'react-material-icon-svg/dist/AccessPointNetwork';
// export const AccessPointNetworkIconSvg = AccessPointNetworkIcon;

// import AccessPointNetworkOffIcon from 'react-material-icon-svg/dist/AccessPointNetworkOff';
// export const AccessPointNetworkOffIconSvg = AccessPointNetworkOffIcon;

import AccountIcon from 'react-material-icon-svg/dist/Account';
export const AccountIconSvg = AccountIcon;

// import AccountAlertIcon from 'react-material-icon-svg/dist/AccountAlert';
// export const AccountAlertIconSvg = AccountAlertIcon;

// import AccountAlertOutlineIcon from 'react-material-icon-svg/dist/AccountAlertOutline';
// export const AccountAlertOutlineIconSvg = AccountAlertOutlineIcon;

// import AccountArrowLeftIcon from 'react-material-icon-svg/dist/AccountArrowLeft';
// export const AccountArrowLeftIconSvg = AccountArrowLeftIcon;

// import AccountArrowLeftOutlineIcon from 'react-material-icon-svg/dist/AccountArrowLeftOutline';
// export const AccountArrowLeftOutlineIconSvg = AccountArrowLeftOutlineIcon;

// import AccountArrowRightIcon from 'react-material-icon-svg/dist/AccountArrowRight';
// export const AccountArrowRightIconSvg = AccountArrowRightIcon;

// import AccountArrowRightOutlineIcon from 'react-material-icon-svg/dist/AccountArrowRightOutline';
// export const AccountArrowRightOutlineIconSvg = AccountArrowRightOutlineIcon;

// import AccountBadgeIcon from 'react-material-icon-svg/dist/AccountBadge';
// export const AccountBadgeIconSvg = AccountBadgeIcon;

// import AccountBadgeAlertIcon from 'react-material-icon-svg/dist/AccountBadgeAlert';
// export const AccountBadgeAlertIconSvg = AccountBadgeAlertIcon;

// import AccountBadgeAlertOutlineIcon from 'react-material-icon-svg/dist/AccountBadgeAlertOutline';
// export const AccountBadgeAlertOutlineIconSvg = AccountBadgeAlertOutlineIcon;

// import AccountBadgeHorizontalIcon from 'react-material-icon-svg/dist/AccountBadgeHorizontal';
// export const AccountBadgeHorizontalIconSvg = AccountBadgeHorizontalIcon;

// import AccountBadgeHorizontalOutlineIcon from 'react-material-icon-svg/dist/AccountBadgeHorizontalOutline';
// export const AccountBadgeHorizontalOutlineIconSvg = AccountBadgeHorizontalOutlineIcon;

// import AccountBadgeOutlineIcon from 'react-material-icon-svg/dist/AccountBadgeOutline';
// export const AccountBadgeOutlineIconSvg = AccountBadgeOutlineIcon;

// import AccountBoxIcon from 'react-material-icon-svg/dist/AccountBox';
// export const AccountBoxIconSvg = AccountBoxIcon;

// import AccountBoxMultipleIcon from 'react-material-icon-svg/dist/AccountBoxMultiple';
// export const AccountBoxMultipleIconSvg = AccountBoxMultipleIcon;

// import AccountBoxOutlineIcon from 'react-material-icon-svg/dist/AccountBoxOutline';
// export const AccountBoxOutlineIconSvg = AccountBoxOutlineIcon;

// import AccountCardDetailsIcon from 'react-material-icon-svg/dist/AccountCardDetails';
// export const AccountCardDetailsIconSvg = AccountCardDetailsIcon;

// import AccountCardDetailsOutlineIcon from 'react-material-icon-svg/dist/AccountCardDetailsOutline';
// export const AccountCardDetailsOutlineIconSvg = AccountCardDetailsOutlineIcon;

// import AccountCheckIcon from 'react-material-icon-svg/dist/AccountCheck';
// export const AccountCheckIconSvg = AccountCheckIcon;

// import AccountCheckOutlineIcon from 'react-material-icon-svg/dist/AccountCheckOutline';
// export const AccountCheckOutlineIconSvg = AccountCheckOutlineIcon;

// import AccountChildIcon from 'react-material-icon-svg/dist/AccountChild';
// export const AccountChildIconSvg = AccountChildIcon;

// import AccountChildCircleIcon from 'react-material-icon-svg/dist/AccountChildCircle';
// export const AccountChildCircleIconSvg = AccountChildCircleIcon;

// import AccountCircleIcon from 'react-material-icon-svg/dist/AccountCircle';
// export const AccountCircleIconSvg = AccountCircleIcon;

// import AccountCircleOutlineIcon from 'react-material-icon-svg/dist/AccountCircleOutline';
// export const AccountCircleOutlineIconSvg = AccountCircleOutlineIcon;

// import AccountClockIcon from 'react-material-icon-svg/dist/AccountClock';
// export const AccountClockIconSvg = AccountClockIcon;

// import AccountClockOutlineIcon from 'react-material-icon-svg/dist/AccountClockOutline';
// export const AccountClockOutlineIconSvg = AccountClockOutlineIcon;

// import AccountConvertIcon from 'react-material-icon-svg/dist/AccountConvert';
// export const AccountConvertIconSvg = AccountConvertIcon;

// import AccountDetailsIcon from 'react-material-icon-svg/dist/AccountDetails';
// export const AccountDetailsIconSvg = AccountDetailsIcon;

// import AccountEditIcon from 'react-material-icon-svg/dist/AccountEdit';
// export const AccountEditIconSvg = AccountEditIcon;

// import AccountGroupIcon from 'react-material-icon-svg/dist/AccountGroup';
// export const AccountGroupIconSvg = AccountGroupIcon;

// import AccountGroupOutlineIcon from 'react-material-icon-svg/dist/AccountGroupOutline';
// export const AccountGroupOutlineIconSvg = AccountGroupOutlineIcon;

// import AccountHeartIcon from 'react-material-icon-svg/dist/AccountHeart';
// export const AccountHeartIconSvg = AccountHeartIcon;

// import AccountHeartOutlineIcon from 'react-material-icon-svg/dist/AccountHeartOutline';
// export const AccountHeartOutlineIconSvg = AccountHeartOutlineIcon;

// import AccountKeyIcon from 'react-material-icon-svg/dist/AccountKey';
// export const AccountKeyIconSvg = AccountKeyIcon;

// import AccountKeyOutlineIcon from 'react-material-icon-svg/dist/AccountKeyOutline';
// export const AccountKeyOutlineIconSvg = AccountKeyOutlineIcon;

// import AccountMinusIcon from 'react-material-icon-svg/dist/AccountMinus';
// export const AccountMinusIconSvg = AccountMinusIcon;

// import AccountMinusOutlineIcon from 'react-material-icon-svg/dist/AccountMinusOutline';
// export const AccountMinusOutlineIconSvg = AccountMinusOutlineIcon;

// import AccountMultipleIcon from 'react-material-icon-svg/dist/AccountMultiple';
// export const AccountMultipleIconSvg = AccountMultipleIcon;

// import AccountMultipleCheckIcon from 'react-material-icon-svg/dist/AccountMultipleCheck';
// export const AccountMultipleCheckIconSvg = AccountMultipleCheckIcon;

// import AccountMultipleMinusIcon from 'react-material-icon-svg/dist/AccountMultipleMinus';
// export const AccountMultipleMinusIconSvg = AccountMultipleMinusIcon;

// import AccountMultipleMinusOutlineIcon from 'react-material-icon-svg/dist/AccountMultipleMinusOutline';
// export const AccountMultipleMinusOutlineIconSvg = AccountMultipleMinusOutlineIcon;

// import AccountMultipleOutlineIcon from 'react-material-icon-svg/dist/AccountMultipleOutline';
// export const AccountMultipleOutlineIconSvg = AccountMultipleOutlineIcon;

// import AccountMultiplePlusIcon from 'react-material-icon-svg/dist/AccountMultiplePlus';
// export const AccountMultiplePlusIconSvg = AccountMultiplePlusIcon;

// import AccountMultiplePlusOutlineIcon from 'react-material-icon-svg/dist/AccountMultiplePlusOutline';
// export const AccountMultiplePlusOutlineIconSvg = AccountMultiplePlusOutlineIcon;

// import AccountNetworkIcon from 'react-material-icon-svg/dist/AccountNetwork';
// export const AccountNetworkIconSvg = AccountNetworkIcon;

// import AccountNetworkOutlineIcon from 'react-material-icon-svg/dist/AccountNetworkOutline';
// export const AccountNetworkOutlineIconSvg = AccountNetworkOutlineIcon;

// import AccountOffIcon from 'react-material-icon-svg/dist/AccountOff';
// export const AccountOffIconSvg = AccountOffIcon;

// import AccountOffOutlineIcon from 'react-material-icon-svg/dist/AccountOffOutline';
// export const AccountOffOutlineIconSvg = AccountOffOutlineIcon;

// import AccountOutlineIcon from 'react-material-icon-svg/dist/AccountOutline';
// export const AccountOutlineIconSvg = AccountOutlineIcon;

// import AccountPlusIcon from 'react-material-icon-svg/dist/AccountPlus';
// export const AccountPlusIconSvg = AccountPlusIcon;

// import AccountPlusOutlineIcon from 'react-material-icon-svg/dist/AccountPlusOutline';
// export const AccountPlusOutlineIconSvg = AccountPlusOutlineIcon;

// import AccountQuestionIcon from 'react-material-icon-svg/dist/AccountQuestion';
// export const AccountQuestionIconSvg = AccountQuestionIcon;

// import AccountQuestionOutlineIcon from 'react-material-icon-svg/dist/AccountQuestionOutline';
// export const AccountQuestionOutlineIconSvg = AccountQuestionOutlineIcon;

// import AccountRemoveIcon from 'react-material-icon-svg/dist/AccountRemove';
// export const AccountRemoveIconSvg = AccountRemoveIcon;

// import AccountRemoveOutlineIcon from 'react-material-icon-svg/dist/AccountRemoveOutline';
// export const AccountRemoveOutlineIconSvg = AccountRemoveOutlineIcon;

// import AccountSearchIcon from 'react-material-icon-svg/dist/AccountSearch';
// export const AccountSearchIconSvg = AccountSearchIcon;

// import AccountSearchOutlineIcon from 'react-material-icon-svg/dist/AccountSearchOutline';
// export const AccountSearchOutlineIconSvg = AccountSearchOutlineIcon;

// import AccountSettingsIcon from 'react-material-icon-svg/dist/AccountSettings';
// export const AccountSettingsIconSvg = AccountSettingsIcon;

// import AccountStarIcon from 'react-material-icon-svg/dist/AccountStar';
// export const AccountStarIconSvg = AccountStarIcon;

// import AccountStarOutlineIcon from 'react-material-icon-svg/dist/AccountStarOutline';
// export const AccountStarOutlineIconSvg = AccountStarOutlineIcon;

// import AccountSupervisorIcon from 'react-material-icon-svg/dist/AccountSupervisor';
// export const AccountSupervisorIconSvg = AccountSupervisorIcon;

// import AccountSupervisorCircleIcon from 'react-material-icon-svg/dist/AccountSupervisorCircle';
// export const AccountSupervisorCircleIconSvg = AccountSupervisorCircleIcon;

// import AccountSwitchIcon from 'react-material-icon-svg/dist/AccountSwitch';
// export const AccountSwitchIconSvg = AccountSwitchIcon;

// import AccountTieIcon from 'react-material-icon-svg/dist/AccountTie';
// export const AccountTieIconSvg = AccountTieIcon;

// import AccusoftIcon from 'react-material-icon-svg/dist/Accusoft';
// export const AccusoftIconSvg = AccusoftIcon;

// import AdchoicesIcon from 'react-material-icon-svg/dist/Adchoices';
// export const AdchoicesIconSvg = AdchoicesIcon;

// import AdjustIcon from 'react-material-icon-svg/dist/Adjust';
// export const AdjustIconSvg = AdjustIcon;

// import AdobeIcon from 'react-material-icon-svg/dist/Adobe';
// export const AdobeIconSvg = AdobeIcon;

// import AirConditionerIcon from 'react-material-icon-svg/dist/AirConditioner';
// export const AirConditionerIconSvg = AirConditionerIcon;

// import AirFilterIcon from 'react-material-icon-svg/dist/AirFilter';
// export const AirFilterIconSvg = AirFilterIcon;

// import AirHornIcon from 'react-material-icon-svg/dist/AirHorn';
// export const AirHornIconSvg = AirHornIcon;

// import AirPurifierIcon from 'react-material-icon-svg/dist/AirPurifier';
// export const AirPurifierIconSvg = AirPurifierIcon;

// import AirbagIcon from 'react-material-icon-svg/dist/Airbag';
// export const AirbagIconSvg = AirbagIcon;

// import AirballoonIcon from 'react-material-icon-svg/dist/Airballoon';
// export const AirballoonIconSvg = AirballoonIcon;

// import AirplaneIcon from 'react-material-icon-svg/dist/Airplane';
// export const AirplaneIconSvg = AirplaneIcon;

// import AirplaneLandingIcon from 'react-material-icon-svg/dist/AirplaneLanding';
// export const AirplaneLandingIconSvg = AirplaneLandingIcon;

// import AirplaneOffIcon from 'react-material-icon-svg/dist/AirplaneOff';
// export const AirplaneOffIconSvg = AirplaneOffIcon;

// import AirplaneTakeoffIcon from 'react-material-icon-svg/dist/AirplaneTakeoff';
// export const AirplaneTakeoffIconSvg = AirplaneTakeoffIcon;

// import AirplayIcon from 'react-material-icon-svg/dist/Airplay';
// export const AirplayIconSvg = AirplayIcon;

// import AirportIcon from 'react-material-icon-svg/dist/Airport';
// export const AirportIconSvg = AirportIcon;

// import AlarmIcon from 'react-material-icon-svg/dist/Alarm';
// export const AlarmIconSvg = AlarmIcon;

// import AlarmBellIcon from 'react-material-icon-svg/dist/AlarmBell';
// export const AlarmBellIconSvg = AlarmBellIcon;

// import AlarmCheckIcon from 'react-material-icon-svg/dist/AlarmCheck';
// export const AlarmCheckIconSvg = AlarmCheckIcon;

// import AlarmLightIcon from 'react-material-icon-svg/dist/AlarmLight';
// export const AlarmLightIconSvg = AlarmLightIcon;

// import AlarmLightOutlineIcon from 'react-material-icon-svg/dist/AlarmLightOutline';
// export const AlarmLightOutlineIconSvg = AlarmLightOutlineIcon;

// import AlarmMultipleIcon from 'react-material-icon-svg/dist/AlarmMultiple';
// export const AlarmMultipleIconSvg = AlarmMultipleIcon;

// import AlarmNoteIcon from 'react-material-icon-svg/dist/AlarmNote';
// export const AlarmNoteIconSvg = AlarmNoteIcon;

// import AlarmNoteOffIcon from 'react-material-icon-svg/dist/AlarmNoteOff';
// export const AlarmNoteOffIconSvg = AlarmNoteOffIcon;

// import AlarmOffIcon from 'react-material-icon-svg/dist/AlarmOff';
// export const AlarmOffIconSvg = AlarmOffIcon;

// import AlarmPlusIcon from 'react-material-icon-svg/dist/AlarmPlus';
// export const AlarmPlusIconSvg = AlarmPlusIcon;

// import AlarmSnoozeIcon from 'react-material-icon-svg/dist/AlarmSnooze';
// export const AlarmSnoozeIconSvg = AlarmSnoozeIcon;

// import AlbumIcon from 'react-material-icon-svg/dist/Album';
// export const AlbumIconSvg = AlbumIcon;

// import AlertIcon from 'react-material-icon-svg/dist/Alert';
// export const AlertIconSvg = AlertIcon;

// import AlertBoxIcon from 'react-material-icon-svg/dist/AlertBox';
// export const AlertBoxIconSvg = AlertBoxIcon;

// import AlertBoxOutlineIcon from 'react-material-icon-svg/dist/AlertBoxOutline';
// export const AlertBoxOutlineIconSvg = AlertBoxOutlineIcon;

// import AlertCircleIcon from 'react-material-icon-svg/dist/AlertCircle';
// export const AlertCircleIconSvg = AlertCircleIcon;

// import AlertCircleOutlineIcon from 'react-material-icon-svg/dist/AlertCircleOutline';
// export const AlertCircleOutlineIconSvg = AlertCircleOutlineIcon;

// import AlertDecagramIcon from 'react-material-icon-svg/dist/AlertDecagram';
// export const AlertDecagramIconSvg = AlertDecagramIcon;

// import AlertDecagramOutlineIcon from 'react-material-icon-svg/dist/AlertDecagramOutline';
// export const AlertDecagramOutlineIconSvg = AlertDecagramOutlineIcon;

// import AlertOctagonIcon from 'react-material-icon-svg/dist/AlertOctagon';
// export const AlertOctagonIconSvg = AlertOctagonIcon;

// import AlertOctagonOutlineIcon from 'react-material-icon-svg/dist/AlertOctagonOutline';
// export const AlertOctagonOutlineIconSvg = AlertOctagonOutlineIcon;

// import AlertOctagramIcon from 'react-material-icon-svg/dist/AlertOctagram';
// export const AlertOctagramIconSvg = AlertOctagramIcon;

// import AlertOctagramOutlineIcon from 'react-material-icon-svg/dist/AlertOctagramOutline';
// export const AlertOctagramOutlineIconSvg = AlertOctagramOutlineIcon;

// import AlertOutlineIcon from 'react-material-icon-svg/dist/AlertOutline';
// export const AlertOutlineIconSvg = AlertOutlineIcon;

// import AlienIcon from 'react-material-icon-svg/dist/Alien';
// export const AlienIconSvg = AlienIcon;

// import AllInclusiveIcon from 'react-material-icon-svg/dist/AllInclusive';
// export const AllInclusiveIconSvg = AllInclusiveIcon;

// import AlphaIcon from 'react-material-icon-svg/dist/Alpha';
// export const AlphaIconSvg = AlphaIcon;

// import AlphaAIcon from 'react-material-icon-svg/dist/AlphaA';
// export const AlphaAIconSvg = AlphaAIcon;

// import AlphaABoxIcon from 'react-material-icon-svg/dist/AlphaABox';
// export const AlphaABoxIconSvg = AlphaABoxIcon;

// import AlphaABoxOutlineIcon from 'react-material-icon-svg/dist/AlphaABoxOutline';
// export const AlphaABoxOutlineIconSvg = AlphaABoxOutlineIcon;

// import AlphaACircleIcon from 'react-material-icon-svg/dist/AlphaACircle';
// export const AlphaACircleIconSvg = AlphaACircleIcon;

// import AlphaACircleOutlineIcon from 'react-material-icon-svg/dist/AlphaACircleOutline';
// export const AlphaACircleOutlineIconSvg = AlphaACircleOutlineIcon;

// import AlphaBIcon from 'react-material-icon-svg/dist/AlphaB';
// export const AlphaBIconSvg = AlphaBIcon;

// import AlphaBBoxIcon from 'react-material-icon-svg/dist/AlphaBBox';
// export const AlphaBBoxIconSvg = AlphaBBoxIcon;

// import AlphaBBoxOutlineIcon from 'react-material-icon-svg/dist/AlphaBBoxOutline';
// export const AlphaBBoxOutlineIconSvg = AlphaBBoxOutlineIcon;

// import AlphaBCircleIcon from 'react-material-icon-svg/dist/AlphaBCircle';
// export const AlphaBCircleIconSvg = AlphaBCircleIcon;

// import AlphaBCircleOutlineIcon from 'react-material-icon-svg/dist/AlphaBCircleOutline';
// export const AlphaBCircleOutlineIconSvg = AlphaBCircleOutlineIcon;

// import AlphaCIcon from 'react-material-icon-svg/dist/AlphaC';
// export const AlphaCIconSvg = AlphaCIcon;

// import AlphaCBoxIcon from 'react-material-icon-svg/dist/AlphaCBox';
// export const AlphaCBoxIconSvg = AlphaCBoxIcon;

// import AlphaCBoxOutlineIcon from 'react-material-icon-svg/dist/AlphaCBoxOutline';
// export const AlphaCBoxOutlineIconSvg = AlphaCBoxOutlineIcon;

// import AlphaCCircleIcon from 'react-material-icon-svg/dist/AlphaCCircle';
// export const AlphaCCircleIconSvg = AlphaCCircleIcon;

// import AlphaCCircleOutlineIcon from 'react-material-icon-svg/dist/AlphaCCircleOutline';
// export const AlphaCCircleOutlineIconSvg = AlphaCCircleOutlineIcon;

// import AlphaDIcon from 'react-material-icon-svg/dist/AlphaD';
// export const AlphaDIconSvg = AlphaDIcon;

// import AlphaDBoxIcon from 'react-material-icon-svg/dist/AlphaDBox';
// export const AlphaDBoxIconSvg = AlphaDBoxIcon;

// import AlphaDBoxOutlineIcon from 'react-material-icon-svg/dist/AlphaDBoxOutline';
// export const AlphaDBoxOutlineIconSvg = AlphaDBoxOutlineIcon;

// import AlphaDCircleIcon from 'react-material-icon-svg/dist/AlphaDCircle';
// export const AlphaDCircleIconSvg = AlphaDCircleIcon;

// import AlphaDCircleOutlineIcon from 'react-material-icon-svg/dist/AlphaDCircleOutline';
// export const AlphaDCircleOutlineIconSvg = AlphaDCircleOutlineIcon;

// import AlphaEIcon from 'react-material-icon-svg/dist/AlphaE';
// export const AlphaEIconSvg = AlphaEIcon;

// import AlphaEBoxIcon from 'react-material-icon-svg/dist/AlphaEBox';
// export const AlphaEBoxIconSvg = AlphaEBoxIcon;

// import AlphaEBoxOutlineIcon from 'react-material-icon-svg/dist/AlphaEBoxOutline';
// export const AlphaEBoxOutlineIconSvg = AlphaEBoxOutlineIcon;

// import AlphaECircleIcon from 'react-material-icon-svg/dist/AlphaECircle';
// export const AlphaECircleIconSvg = AlphaECircleIcon;

// import AlphaECircleOutlineIcon from 'react-material-icon-svg/dist/AlphaECircleOutline';
// export const AlphaECircleOutlineIconSvg = AlphaECircleOutlineIcon;

// import AlphaFIcon from 'react-material-icon-svg/dist/AlphaF';
// export const AlphaFIconSvg = AlphaFIcon;

// import AlphaFBoxIcon from 'react-material-icon-svg/dist/AlphaFBox';
// export const AlphaFBoxIconSvg = AlphaFBoxIcon;

// import AlphaFBoxOutlineIcon from 'react-material-icon-svg/dist/AlphaFBoxOutline';
// export const AlphaFBoxOutlineIconSvg = AlphaFBoxOutlineIcon;

// import AlphaFCircleIcon from 'react-material-icon-svg/dist/AlphaFCircle';
// export const AlphaFCircleIconSvg = AlphaFCircleIcon;

// import AlphaFCircleOutlineIcon from 'react-material-icon-svg/dist/AlphaFCircleOutline';
// export const AlphaFCircleOutlineIconSvg = AlphaFCircleOutlineIcon;

// import AlphaGIcon from 'react-material-icon-svg/dist/AlphaG';
// export const AlphaGIconSvg = AlphaGIcon;

// import AlphaGBoxIcon from 'react-material-icon-svg/dist/AlphaGBox';
// export const AlphaGBoxIconSvg = AlphaGBoxIcon;

// import AlphaGBoxOutlineIcon from 'react-material-icon-svg/dist/AlphaGBoxOutline';
// export const AlphaGBoxOutlineIconSvg = AlphaGBoxOutlineIcon;

// import AlphaGCircleIcon from 'react-material-icon-svg/dist/AlphaGCircle';
// export const AlphaGCircleIconSvg = AlphaGCircleIcon;

// import AlphaGCircleOutlineIcon from 'react-material-icon-svg/dist/AlphaGCircleOutline';
// export const AlphaGCircleOutlineIconSvg = AlphaGCircleOutlineIcon;

// import AlphaHIcon from 'react-material-icon-svg/dist/AlphaH';
// export const AlphaHIconSvg = AlphaHIcon;

// import AlphaHBoxIcon from 'react-material-icon-svg/dist/AlphaHBox';
// export const AlphaHBoxIconSvg = AlphaHBoxIcon;

// import AlphaHBoxOutlineIcon from 'react-material-icon-svg/dist/AlphaHBoxOutline';
// export const AlphaHBoxOutlineIconSvg = AlphaHBoxOutlineIcon;

// import AlphaHCircleIcon from 'react-material-icon-svg/dist/AlphaHCircle';
// export const AlphaHCircleIconSvg = AlphaHCircleIcon;

// import AlphaHCircleOutlineIcon from 'react-material-icon-svg/dist/AlphaHCircleOutline';
// export const AlphaHCircleOutlineIconSvg = AlphaHCircleOutlineIcon;

// import AlphaIIcon from 'react-material-icon-svg/dist/AlphaI';
// export const AlphaIIconSvg = AlphaIIcon;

// import AlphaIBoxIcon from 'react-material-icon-svg/dist/AlphaIBox';
// export const AlphaIBoxIconSvg = AlphaIBoxIcon;

// import AlphaIBoxOutlineIcon from 'react-material-icon-svg/dist/AlphaIBoxOutline';
// export const AlphaIBoxOutlineIconSvg = AlphaIBoxOutlineIcon;

// import AlphaICircleIcon from 'react-material-icon-svg/dist/AlphaICircle';
// export const AlphaICircleIconSvg = AlphaICircleIcon;

// import AlphaICircleOutlineIcon from 'react-material-icon-svg/dist/AlphaICircleOutline';
// export const AlphaICircleOutlineIconSvg = AlphaICircleOutlineIcon;

// import AlphaJIcon from 'react-material-icon-svg/dist/AlphaJ';
// export const AlphaJIconSvg = AlphaJIcon;

// import AlphaJBoxIcon from 'react-material-icon-svg/dist/AlphaJBox';
// export const AlphaJBoxIconSvg = AlphaJBoxIcon;

// import AlphaJBoxOutlineIcon from 'react-material-icon-svg/dist/AlphaJBoxOutline';
// export const AlphaJBoxOutlineIconSvg = AlphaJBoxOutlineIcon;

// import AlphaJCircleIcon from 'react-material-icon-svg/dist/AlphaJCircle';
// export const AlphaJCircleIconSvg = AlphaJCircleIcon;

// import AlphaJCircleOutlineIcon from 'react-material-icon-svg/dist/AlphaJCircleOutline';
// export const AlphaJCircleOutlineIconSvg = AlphaJCircleOutlineIcon;

// import AlphaKIcon from 'react-material-icon-svg/dist/AlphaK';
// export const AlphaKIconSvg = AlphaKIcon;

// import AlphaKBoxIcon from 'react-material-icon-svg/dist/AlphaKBox';
// export const AlphaKBoxIconSvg = AlphaKBoxIcon;

// import AlphaKBoxOutlineIcon from 'react-material-icon-svg/dist/AlphaKBoxOutline';
// export const AlphaKBoxOutlineIconSvg = AlphaKBoxOutlineIcon;

// import AlphaKCircleIcon from 'react-material-icon-svg/dist/AlphaKCircle';
// export const AlphaKCircleIconSvg = AlphaKCircleIcon;

// import AlphaKCircleOutlineIcon from 'react-material-icon-svg/dist/AlphaKCircleOutline';
// export const AlphaKCircleOutlineIconSvg = AlphaKCircleOutlineIcon;

// import AlphaLIcon from 'react-material-icon-svg/dist/AlphaL';
// export const AlphaLIconSvg = AlphaLIcon;

// import AlphaLBoxIcon from 'react-material-icon-svg/dist/AlphaLBox';
// export const AlphaLBoxIconSvg = AlphaLBoxIcon;

// import AlphaLBoxOutlineIcon from 'react-material-icon-svg/dist/AlphaLBoxOutline';
// export const AlphaLBoxOutlineIconSvg = AlphaLBoxOutlineIcon;

// import AlphaLCircleIcon from 'react-material-icon-svg/dist/AlphaLCircle';
// export const AlphaLCircleIconSvg = AlphaLCircleIcon;

// import AlphaLCircleOutlineIcon from 'react-material-icon-svg/dist/AlphaLCircleOutline';
// export const AlphaLCircleOutlineIconSvg = AlphaLCircleOutlineIcon;

// import AlphaMIcon from 'react-material-icon-svg/dist/AlphaM';
// export const AlphaMIconSvg = AlphaMIcon;

// import AlphaMBoxIcon from 'react-material-icon-svg/dist/AlphaMBox';
// export const AlphaMBoxIconSvg = AlphaMBoxIcon;

// import AlphaMBoxOutlineIcon from 'react-material-icon-svg/dist/AlphaMBoxOutline';
// export const AlphaMBoxOutlineIconSvg = AlphaMBoxOutlineIcon;

// import AlphaMCircleIcon from 'react-material-icon-svg/dist/AlphaMCircle';
// export const AlphaMCircleIconSvg = AlphaMCircleIcon;

// import AlphaMCircleOutlineIcon from 'react-material-icon-svg/dist/AlphaMCircleOutline';
// export const AlphaMCircleOutlineIconSvg = AlphaMCircleOutlineIcon;

// import AlphaNIcon from 'react-material-icon-svg/dist/AlphaN';
// export const AlphaNIconSvg = AlphaNIcon;

// import AlphaNBoxIcon from 'react-material-icon-svg/dist/AlphaNBox';
// export const AlphaNBoxIconSvg = AlphaNBoxIcon;

// import AlphaNBoxOutlineIcon from 'react-material-icon-svg/dist/AlphaNBoxOutline';
// export const AlphaNBoxOutlineIconSvg = AlphaNBoxOutlineIcon;

// import AlphaNCircleIcon from 'react-material-icon-svg/dist/AlphaNCircle';
// export const AlphaNCircleIconSvg = AlphaNCircleIcon;

// import AlphaNCircleOutlineIcon from 'react-material-icon-svg/dist/AlphaNCircleOutline';
// export const AlphaNCircleOutlineIconSvg = AlphaNCircleOutlineIcon;

// import AlphaOIcon from 'react-material-icon-svg/dist/AlphaO';
// export const AlphaOIconSvg = AlphaOIcon;

// import AlphaOBoxIcon from 'react-material-icon-svg/dist/AlphaOBox';
// export const AlphaOBoxIconSvg = AlphaOBoxIcon;

// import AlphaOBoxOutlineIcon from 'react-material-icon-svg/dist/AlphaOBoxOutline';
// export const AlphaOBoxOutlineIconSvg = AlphaOBoxOutlineIcon;

// import AlphaOCircleIcon from 'react-material-icon-svg/dist/AlphaOCircle';
// export const AlphaOCircleIconSvg = AlphaOCircleIcon;

// import AlphaOCircleOutlineIcon from 'react-material-icon-svg/dist/AlphaOCircleOutline';
// export const AlphaOCircleOutlineIconSvg = AlphaOCircleOutlineIcon;

// import AlphaPIcon from 'react-material-icon-svg/dist/AlphaP';
// export const AlphaPIconSvg = AlphaPIcon;

// import AlphaPBoxIcon from 'react-material-icon-svg/dist/AlphaPBox';
// export const AlphaPBoxIconSvg = AlphaPBoxIcon;

// import AlphaPBoxOutlineIcon from 'react-material-icon-svg/dist/AlphaPBoxOutline';
// export const AlphaPBoxOutlineIconSvg = AlphaPBoxOutlineIcon;

// import AlphaPCircleIcon from 'react-material-icon-svg/dist/AlphaPCircle';
// export const AlphaPCircleIconSvg = AlphaPCircleIcon;

// import AlphaPCircleOutlineIcon from 'react-material-icon-svg/dist/AlphaPCircleOutline';
// export const AlphaPCircleOutlineIconSvg = AlphaPCircleOutlineIcon;

// import AlphaQIcon from 'react-material-icon-svg/dist/AlphaQ';
// export const AlphaQIconSvg = AlphaQIcon;

// import AlphaQBoxIcon from 'react-material-icon-svg/dist/AlphaQBox';
// export const AlphaQBoxIconSvg = AlphaQBoxIcon;

// import AlphaQBoxOutlineIcon from 'react-material-icon-svg/dist/AlphaQBoxOutline';
// export const AlphaQBoxOutlineIconSvg = AlphaQBoxOutlineIcon;

// import AlphaQCircleIcon from 'react-material-icon-svg/dist/AlphaQCircle';
// export const AlphaQCircleIconSvg = AlphaQCircleIcon;

// import AlphaQCircleOutlineIcon from 'react-material-icon-svg/dist/AlphaQCircleOutline';
// export const AlphaQCircleOutlineIconSvg = AlphaQCircleOutlineIcon;

// import AlphaRIcon from 'react-material-icon-svg/dist/AlphaR';
// export const AlphaRIconSvg = AlphaRIcon;

// import AlphaRBoxIcon from 'react-material-icon-svg/dist/AlphaRBox';
// export const AlphaRBoxIconSvg = AlphaRBoxIcon;

// import AlphaRBoxOutlineIcon from 'react-material-icon-svg/dist/AlphaRBoxOutline';
// export const AlphaRBoxOutlineIconSvg = AlphaRBoxOutlineIcon;

// import AlphaRCircleIcon from 'react-material-icon-svg/dist/AlphaRCircle';
// export const AlphaRCircleIconSvg = AlphaRCircleIcon;

// import AlphaRCircleOutlineIcon from 'react-material-icon-svg/dist/AlphaRCircleOutline';
// export const AlphaRCircleOutlineIconSvg = AlphaRCircleOutlineIcon;

// import AlphaSIcon from 'react-material-icon-svg/dist/AlphaS';
// export const AlphaSIconSvg = AlphaSIcon;

// import AlphaSBoxIcon from 'react-material-icon-svg/dist/AlphaSBox';
// export const AlphaSBoxIconSvg = AlphaSBoxIcon;

// import AlphaSBoxOutlineIcon from 'react-material-icon-svg/dist/AlphaSBoxOutline';
// export const AlphaSBoxOutlineIconSvg = AlphaSBoxOutlineIcon;

// import AlphaSCircleIcon from 'react-material-icon-svg/dist/AlphaSCircle';
// export const AlphaSCircleIconSvg = AlphaSCircleIcon;

// import AlphaSCircleOutlineIcon from 'react-material-icon-svg/dist/AlphaSCircleOutline';
// export const AlphaSCircleOutlineIconSvg = AlphaSCircleOutlineIcon;

// import AlphaTIcon from 'react-material-icon-svg/dist/AlphaT';
// export const AlphaTIconSvg = AlphaTIcon;

// import AlphaTBoxIcon from 'react-material-icon-svg/dist/AlphaTBox';
// export const AlphaTBoxIconSvg = AlphaTBoxIcon;

// import AlphaTBoxOutlineIcon from 'react-material-icon-svg/dist/AlphaTBoxOutline';
// export const AlphaTBoxOutlineIconSvg = AlphaTBoxOutlineIcon;

// import AlphaTCircleIcon from 'react-material-icon-svg/dist/AlphaTCircle';
// export const AlphaTCircleIconSvg = AlphaTCircleIcon;

// import AlphaTCircleOutlineIcon from 'react-material-icon-svg/dist/AlphaTCircleOutline';
// export const AlphaTCircleOutlineIconSvg = AlphaTCircleOutlineIcon;

// import AlphaUIcon from 'react-material-icon-svg/dist/AlphaU';
// export const AlphaUIconSvg = AlphaUIcon;

// import AlphaUBoxIcon from 'react-material-icon-svg/dist/AlphaUBox';
// export const AlphaUBoxIconSvg = AlphaUBoxIcon;

// import AlphaUBoxOutlineIcon from 'react-material-icon-svg/dist/AlphaUBoxOutline';
// export const AlphaUBoxOutlineIconSvg = AlphaUBoxOutlineIcon;

// import AlphaUCircleIcon from 'react-material-icon-svg/dist/AlphaUCircle';
// export const AlphaUCircleIconSvg = AlphaUCircleIcon;

// import AlphaUCircleOutlineIcon from 'react-material-icon-svg/dist/AlphaUCircleOutline';
// export const AlphaUCircleOutlineIconSvg = AlphaUCircleOutlineIcon;

// import AlphaVIcon from 'react-material-icon-svg/dist/AlphaV';
// export const AlphaVIconSvg = AlphaVIcon;

// import AlphaVBoxIcon from 'react-material-icon-svg/dist/AlphaVBox';
// export const AlphaVBoxIconSvg = AlphaVBoxIcon;

// import AlphaVBoxOutlineIcon from 'react-material-icon-svg/dist/AlphaVBoxOutline';
// export const AlphaVBoxOutlineIconSvg = AlphaVBoxOutlineIcon;

// import AlphaVCircleIcon from 'react-material-icon-svg/dist/AlphaVCircle';
// export const AlphaVCircleIconSvg = AlphaVCircleIcon;

// import AlphaVCircleOutlineIcon from 'react-material-icon-svg/dist/AlphaVCircleOutline';
// export const AlphaVCircleOutlineIconSvg = AlphaVCircleOutlineIcon;

// import AlphaWIcon from 'react-material-icon-svg/dist/AlphaW';
// export const AlphaWIconSvg = AlphaWIcon;

// import AlphaWBoxIcon from 'react-material-icon-svg/dist/AlphaWBox';
// export const AlphaWBoxIconSvg = AlphaWBoxIcon;

// import AlphaWBoxOutlineIcon from 'react-material-icon-svg/dist/AlphaWBoxOutline';
// export const AlphaWBoxOutlineIconSvg = AlphaWBoxOutlineIcon;

// import AlphaWCircleIcon from 'react-material-icon-svg/dist/AlphaWCircle';
// export const AlphaWCircleIconSvg = AlphaWCircleIcon;

// import AlphaWCircleOutlineIcon from 'react-material-icon-svg/dist/AlphaWCircleOutline';
// export const AlphaWCircleOutlineIconSvg = AlphaWCircleOutlineIcon;

// import AlphaXIcon from 'react-material-icon-svg/dist/AlphaX';
// export const AlphaXIconSvg = AlphaXIcon;

// import AlphaXBoxIcon from 'react-material-icon-svg/dist/AlphaXBox';
// export const AlphaXBoxIconSvg = AlphaXBoxIcon;

// import AlphaXBoxOutlineIcon from 'react-material-icon-svg/dist/AlphaXBoxOutline';
// export const AlphaXBoxOutlineIconSvg = AlphaXBoxOutlineIcon;

// import AlphaXCircleIcon from 'react-material-icon-svg/dist/AlphaXCircle';
// export const AlphaXCircleIconSvg = AlphaXCircleIcon;

// import AlphaXCircleOutlineIcon from 'react-material-icon-svg/dist/AlphaXCircleOutline';
// export const AlphaXCircleOutlineIconSvg = AlphaXCircleOutlineIcon;

// import AlphaYIcon from 'react-material-icon-svg/dist/AlphaY';
// export const AlphaYIconSvg = AlphaYIcon;

// import AlphaYBoxIcon from 'react-material-icon-svg/dist/AlphaYBox';
// export const AlphaYBoxIconSvg = AlphaYBoxIcon;

// import AlphaYBoxOutlineIcon from 'react-material-icon-svg/dist/AlphaYBoxOutline';
// export const AlphaYBoxOutlineIconSvg = AlphaYBoxOutlineIcon;

// import AlphaYCircleIcon from 'react-material-icon-svg/dist/AlphaYCircle';
// export const AlphaYCircleIconSvg = AlphaYCircleIcon;

// import AlphaYCircleOutlineIcon from 'react-material-icon-svg/dist/AlphaYCircleOutline';
// export const AlphaYCircleOutlineIconSvg = AlphaYCircleOutlineIcon;

// import AlphaZIcon from 'react-material-icon-svg/dist/AlphaZ';
// export const AlphaZIconSvg = AlphaZIcon;

// import AlphaZBoxIcon from 'react-material-icon-svg/dist/AlphaZBox';
// export const AlphaZBoxIconSvg = AlphaZBoxIcon;

// import AlphaZBoxOutlineIcon from 'react-material-icon-svg/dist/AlphaZBoxOutline';
// export const AlphaZBoxOutlineIconSvg = AlphaZBoxOutlineIcon;

// import AlphaZCircleIcon from 'react-material-icon-svg/dist/AlphaZCircle';
// export const AlphaZCircleIconSvg = AlphaZCircleIcon;

// import AlphaZCircleOutlineIcon from 'react-material-icon-svg/dist/AlphaZCircleOutline';
// export const AlphaZCircleOutlineIconSvg = AlphaZCircleOutlineIcon;

// import AlphabeticalIcon from 'react-material-icon-svg/dist/Alphabetical';
// export const AlphabeticalIconSvg = AlphabeticalIcon;

// import AltimeterIcon from 'react-material-icon-svg/dist/Altimeter';
// export const AltimeterIconSvg = AltimeterIcon;

// import AmazonIcon from 'react-material-icon-svg/dist/Amazon';
// export const AmazonIconSvg = AmazonIcon;

// import AmazonAlexaIcon from 'react-material-icon-svg/dist/AmazonAlexa';
// export const AmazonAlexaIconSvg = AmazonAlexaIcon;

// import AmazonDriveIcon from 'react-material-icon-svg/dist/AmazonDrive';
// export const AmazonDriveIconSvg = AmazonDriveIcon;

// import AmbulanceIcon from 'react-material-icon-svg/dist/Ambulance';
// export const AmbulanceIconSvg = AmbulanceIcon;

// import AmmunitionIcon from 'react-material-icon-svg/dist/Ammunition';
// export const AmmunitionIconSvg = AmmunitionIcon;

// import AmpersandIcon from 'react-material-icon-svg/dist/Ampersand';
// export const AmpersandIconSvg = AmpersandIcon;

// import AmplifierIcon from 'react-material-icon-svg/dist/Amplifier';
// export const AmplifierIconSvg = AmplifierIcon;

// import AnchorIcon from 'react-material-icon-svg/dist/Anchor';
// export const AnchorIconSvg = AnchorIcon;

// import AndroidIcon from 'react-material-icon-svg/dist/Android';
// export const AndroidIconSvg = AndroidIcon;

// import AndroidAutoIcon from 'react-material-icon-svg/dist/AndroidAuto';
// export const AndroidAutoIconSvg = AndroidAutoIcon;

// import AndroidDebugBridgeIcon from 'react-material-icon-svg/dist/AndroidDebugBridge';
// export const AndroidDebugBridgeIconSvg = AndroidDebugBridgeIcon;

// import AndroidHeadIcon from 'react-material-icon-svg/dist/AndroidHead';
// export const AndroidHeadIconSvg = AndroidHeadIcon;

// import AndroidMessagesIcon from 'react-material-icon-svg/dist/AndroidMessages';
// export const AndroidMessagesIconSvg = AndroidMessagesIcon;

// import AndroidStudioIcon from 'react-material-icon-svg/dist/AndroidStudio';
// export const AndroidStudioIconSvg = AndroidStudioIcon;

// import AngleAcuteIcon from 'react-material-icon-svg/dist/AngleAcute';
// export const AngleAcuteIconSvg = AngleAcuteIcon;

// import AngleObtuseIcon from 'react-material-icon-svg/dist/AngleObtuse';
// export const AngleObtuseIconSvg = AngleObtuseIcon;

// import AngleRightIcon from 'react-material-icon-svg/dist/AngleRight';
// export const AngleRightIconSvg = AngleRightIcon;

// import AngularIcon from 'react-material-icon-svg/dist/Angular';
// export const AngularIconSvg = AngularIcon;

// import AngularjsIcon from 'react-material-icon-svg/dist/Angularjs';
// export const AngularjsIconSvg = AngularjsIcon;

// import AnimationIcon from 'react-material-icon-svg/dist/Animation';
// export const AnimationIconSvg = AnimationIcon;

// import AnimationOutlineIcon from 'react-material-icon-svg/dist/AnimationOutline';
// export const AnimationOutlineIconSvg = AnimationOutlineIcon;

// import AnimationPlayIcon from 'react-material-icon-svg/dist/AnimationPlay';
// export const AnimationPlayIconSvg = AnimationPlayIcon;

// import AnimationPlayOutlineIcon from 'react-material-icon-svg/dist/AnimationPlayOutline';
// export const AnimationPlayOutlineIconSvg = AnimationPlayOutlineIcon;

// import AnvilIcon from 'react-material-icon-svg/dist/Anvil';
// export const AnvilIconSvg = AnvilIcon;

// import AppleIcon from 'react-material-icon-svg/dist/Apple';
// export const AppleIconSvg = AppleIcon;

// import AppleFinderIcon from 'react-material-icon-svg/dist/AppleFinder';
// export const AppleFinderIconSvg = AppleFinderIcon;

// import AppleIcloudIcon from 'react-material-icon-svg/dist/AppleIcloud';
// export const AppleIcloudIconSvg = AppleIcloudIcon;

// import AppleIosIcon from 'react-material-icon-svg/dist/AppleIos';
// export const AppleIosIconSvg = AppleIosIcon;

// import AppleKeyboardCapsIcon from 'react-material-icon-svg/dist/AppleKeyboardCaps';
// export const AppleKeyboardCapsIconSvg = AppleKeyboardCapsIcon;

// import AppleKeyboardCommandIcon from 'react-material-icon-svg/dist/AppleKeyboardCommand';
// export const AppleKeyboardCommandIconSvg = AppleKeyboardCommandIcon;

// import AppleKeyboardControlIcon from 'react-material-icon-svg/dist/AppleKeyboardControl';
// export const AppleKeyboardControlIconSvg = AppleKeyboardControlIcon;

// import AppleKeyboardOptionIcon from 'react-material-icon-svg/dist/AppleKeyboardOption';
// export const AppleKeyboardOptionIconSvg = AppleKeyboardOptionIcon;

// import AppleKeyboardShiftIcon from 'react-material-icon-svg/dist/AppleKeyboardShift';
// export const AppleKeyboardShiftIconSvg = AppleKeyboardShiftIcon;

// import AppleSafariIcon from 'react-material-icon-svg/dist/AppleSafari';
// export const AppleSafariIconSvg = AppleSafariIcon;

// import ApplicationIcon from 'react-material-icon-svg/dist/Application';
// export const ApplicationIconSvg = ApplicationIcon;

// import ApplicationExportIcon from 'react-material-icon-svg/dist/ApplicationExport';
// export const ApplicationExportIconSvg = ApplicationExportIcon;

// import ApplicationImportIcon from 'react-material-icon-svg/dist/ApplicationImport';
// export const ApplicationImportIconSvg = ApplicationImportIcon;

// import AppsIcon from 'react-material-icon-svg/dist/Apps';
// export const AppsIconSvg = AppsIcon;

// import AppsBoxIcon from 'react-material-icon-svg/dist/AppsBox';
// export const AppsBoxIconSvg = AppsBoxIcon;

// import ArchIcon from 'react-material-icon-svg/dist/Arch';
// export const ArchIconSvg = ArchIcon;

// import ArchiveIcon from 'react-material-icon-svg/dist/Archive';
// export const ArchiveIconSvg = ArchiveIcon;

// import ArrangeBringForwardIcon from 'react-material-icon-svg/dist/ArrangeBringForward';
// export const ArrangeBringForwardIconSvg = ArrangeBringForwardIcon;

// import ArrangeBringToFrontIcon from 'react-material-icon-svg/dist/ArrangeBringToFront';
// export const ArrangeBringToFrontIconSvg = ArrangeBringToFrontIcon;

// import ArrangeSendBackwardIcon from 'react-material-icon-svg/dist/ArrangeSendBackward';
// export const ArrangeSendBackwardIconSvg = ArrangeSendBackwardIcon;

// import ArrangeSendToBackIcon from 'react-material-icon-svg/dist/ArrangeSendToBack';
// export const ArrangeSendToBackIconSvg = ArrangeSendToBackIcon;

// import ArrowAllIcon from 'react-material-icon-svg/dist/ArrowAll';
// export const ArrowAllIconSvg = ArrowAllIcon;

// import ArrowBottomLeftIcon from 'react-material-icon-svg/dist/ArrowBottomLeft';
// export const ArrowBottomLeftIconSvg = ArrowBottomLeftIcon;

// import ArrowBottomLeftBoldOutlineIcon from 'react-material-icon-svg/dist/ArrowBottomLeftBoldOutline';
// export const ArrowBottomLeftBoldOutlineIconSvg = ArrowBottomLeftBoldOutlineIcon;

// import ArrowBottomLeftThickIcon from 'react-material-icon-svg/dist/ArrowBottomLeftThick';
// export const ArrowBottomLeftThickIconSvg = ArrowBottomLeftThickIcon;

// import ArrowBottomRightIcon from 'react-material-icon-svg/dist/ArrowBottomRight';
// export const ArrowBottomRightIconSvg = ArrowBottomRightIcon;

// import ArrowBottomRightBoldOutlineIcon from 'react-material-icon-svg/dist/ArrowBottomRightBoldOutline';
// export const ArrowBottomRightBoldOutlineIconSvg = ArrowBottomRightBoldOutlineIcon;

// import ArrowBottomRightThickIcon from 'react-material-icon-svg/dist/ArrowBottomRightThick';
// export const ArrowBottomRightThickIconSvg = ArrowBottomRightThickIcon;

// import ArrowCollapseIcon from 'react-material-icon-svg/dist/ArrowCollapse';
// export const ArrowCollapseIconSvg = ArrowCollapseIcon;

// import ArrowCollapseAllIcon from 'react-material-icon-svg/dist/ArrowCollapseAll';
// export const ArrowCollapseAllIconSvg = ArrowCollapseAllIcon;

// import ArrowCollapseDownIcon from 'react-material-icon-svg/dist/ArrowCollapseDown';
// export const ArrowCollapseDownIconSvg = ArrowCollapseDownIcon;

// import ArrowCollapseHorizontalIcon from 'react-material-icon-svg/dist/ArrowCollapseHorizontal';
// export const ArrowCollapseHorizontalIconSvg = ArrowCollapseHorizontalIcon;

// import ArrowCollapseLeftIcon from 'react-material-icon-svg/dist/ArrowCollapseLeft';
// export const ArrowCollapseLeftIconSvg = ArrowCollapseLeftIcon;

// import ArrowCollapseRightIcon from 'react-material-icon-svg/dist/ArrowCollapseRight';
// export const ArrowCollapseRightIconSvg = ArrowCollapseRightIcon;

// import ArrowCollapseUpIcon from 'react-material-icon-svg/dist/ArrowCollapseUp';
// export const ArrowCollapseUpIconSvg = ArrowCollapseUpIcon;

// import ArrowCollapseVerticalIcon from 'react-material-icon-svg/dist/ArrowCollapseVertical';
// export const ArrowCollapseVerticalIconSvg = ArrowCollapseVerticalIcon;

// import ArrowDecisionIcon from 'react-material-icon-svg/dist/ArrowDecision';
// export const ArrowDecisionIconSvg = ArrowDecisionIcon;

// import ArrowDecisionAutoIcon from 'react-material-icon-svg/dist/ArrowDecisionAuto';
// export const ArrowDecisionAutoIconSvg = ArrowDecisionAutoIcon;

// import ArrowDecisionAutoOutlineIcon from 'react-material-icon-svg/dist/ArrowDecisionAutoOutline';
// export const ArrowDecisionAutoOutlineIconSvg = ArrowDecisionAutoOutlineIcon;

// import ArrowDecisionOutlineIcon from 'react-material-icon-svg/dist/ArrowDecisionOutline';
// export const ArrowDecisionOutlineIconSvg = ArrowDecisionOutlineIcon;

// import ArrowDownIcon from 'react-material-icon-svg/dist/ArrowDown';
// export const ArrowDownIconSvg = ArrowDownIcon;

// import ArrowDownBoldIcon from 'react-material-icon-svg/dist/ArrowDownBold';
// export const ArrowDownBoldIconSvg = ArrowDownBoldIcon;

// import ArrowDownBoldBoxIcon from 'react-material-icon-svg/dist/ArrowDownBoldBox';
// export const ArrowDownBoldBoxIconSvg = ArrowDownBoldBoxIcon;

// import ArrowDownBoldBoxOutlineIcon from 'react-material-icon-svg/dist/ArrowDownBoldBoxOutline';
// export const ArrowDownBoldBoxOutlineIconSvg = ArrowDownBoldBoxOutlineIcon;

// import ArrowDownBoldCircleIcon from 'react-material-icon-svg/dist/ArrowDownBoldCircle';
// export const ArrowDownBoldCircleIconSvg = ArrowDownBoldCircleIcon;

// import ArrowDownBoldCircleOutlineIcon from 'react-material-icon-svg/dist/ArrowDownBoldCircleOutline';
// export const ArrowDownBoldCircleOutlineIconSvg = ArrowDownBoldCircleOutlineIcon;

// import ArrowDownBoldHexagonOutlineIcon from 'react-material-icon-svg/dist/ArrowDownBoldHexagonOutline';
// export const ArrowDownBoldHexagonOutlineIconSvg = ArrowDownBoldHexagonOutlineIcon;

// import ArrowDownBoldOutlineIcon from 'react-material-icon-svg/dist/ArrowDownBoldOutline';
// export const ArrowDownBoldOutlineIconSvg = ArrowDownBoldOutlineIcon;

// import ArrowDownBoxIcon from 'react-material-icon-svg/dist/ArrowDownBox';
// export const ArrowDownBoxIconSvg = ArrowDownBoxIcon;

// import ArrowDownCircleIcon from 'react-material-icon-svg/dist/ArrowDownCircle';
// export const ArrowDownCircleIconSvg = ArrowDownCircleIcon;

// import ArrowDownCircleOutlineIcon from 'react-material-icon-svg/dist/ArrowDownCircleOutline';
// export const ArrowDownCircleOutlineIconSvg = ArrowDownCircleOutlineIcon;

// import ArrowDownDropCircleIcon from 'react-material-icon-svg/dist/ArrowDownDropCircle';
// export const ArrowDownDropCircleIconSvg = ArrowDownDropCircleIcon;

// import ArrowDownDropCircleOutlineIcon from 'react-material-icon-svg/dist/ArrowDownDropCircleOutline';
// export const ArrowDownDropCircleOutlineIconSvg = ArrowDownDropCircleOutlineIcon;

// import ArrowDownThickIcon from 'react-material-icon-svg/dist/ArrowDownThick';
// export const ArrowDownThickIconSvg = ArrowDownThickIcon;

// import ArrowExpandIcon from 'react-material-icon-svg/dist/ArrowExpand';
// export const ArrowExpandIconSvg = ArrowExpandIcon;

// import ArrowExpandAllIcon from 'react-material-icon-svg/dist/ArrowExpandAll';
// export const ArrowExpandAllIconSvg = ArrowExpandAllIcon;

// import ArrowExpandDownIcon from 'react-material-icon-svg/dist/ArrowExpandDown';
// export const ArrowExpandDownIconSvg = ArrowExpandDownIcon;

// import ArrowExpandHorizontalIcon from 'react-material-icon-svg/dist/ArrowExpandHorizontal';
// export const ArrowExpandHorizontalIconSvg = ArrowExpandHorizontalIcon;

// import ArrowExpandLeftIcon from 'react-material-icon-svg/dist/ArrowExpandLeft';
// export const ArrowExpandLeftIconSvg = ArrowExpandLeftIcon;

// import ArrowExpandRightIcon from 'react-material-icon-svg/dist/ArrowExpandRight';
// export const ArrowExpandRightIconSvg = ArrowExpandRightIcon;

// import ArrowExpandUpIcon from 'react-material-icon-svg/dist/ArrowExpandUp';
// export const ArrowExpandUpIconSvg = ArrowExpandUpIcon;

// import ArrowExpandVerticalIcon from 'react-material-icon-svg/dist/ArrowExpandVertical';
// export const ArrowExpandVerticalIconSvg = ArrowExpandVerticalIcon;

// import ArrowLeftIcon from 'react-material-icon-svg/dist/ArrowLeft';
// export const ArrowLeftIconSvg = ArrowLeftIcon;

// import ArrowLeftBoldIcon from 'react-material-icon-svg/dist/ArrowLeftBold';
// export const ArrowLeftBoldIconSvg = ArrowLeftBoldIcon;

// import ArrowLeftBoldBoxIcon from 'react-material-icon-svg/dist/ArrowLeftBoldBox';
// export const ArrowLeftBoldBoxIconSvg = ArrowLeftBoldBoxIcon;

// import ArrowLeftBoldBoxOutlineIcon from 'react-material-icon-svg/dist/ArrowLeftBoldBoxOutline';
// export const ArrowLeftBoldBoxOutlineIconSvg = ArrowLeftBoldBoxOutlineIcon;

// import ArrowLeftBoldCircleIcon from 'react-material-icon-svg/dist/ArrowLeftBoldCircle';
// export const ArrowLeftBoldCircleIconSvg = ArrowLeftBoldCircleIcon;

// import ArrowLeftBoldCircleOutlineIcon from 'react-material-icon-svg/dist/ArrowLeftBoldCircleOutline';
// export const ArrowLeftBoldCircleOutlineIconSvg = ArrowLeftBoldCircleOutlineIcon;

// import ArrowLeftBoldHexagonOutlineIcon from 'react-material-icon-svg/dist/ArrowLeftBoldHexagonOutline';
// export const ArrowLeftBoldHexagonOutlineIconSvg = ArrowLeftBoldHexagonOutlineIcon;

// import ArrowLeftBoldOutlineIcon from 'react-material-icon-svg/dist/ArrowLeftBoldOutline';
// export const ArrowLeftBoldOutlineIconSvg = ArrowLeftBoldOutlineIcon;

// import ArrowLeftBoxIcon from 'react-material-icon-svg/dist/ArrowLeftBox';
// export const ArrowLeftBoxIconSvg = ArrowLeftBoxIcon;

// import ArrowLeftCircleIcon from 'react-material-icon-svg/dist/ArrowLeftCircle';
// export const ArrowLeftCircleIconSvg = ArrowLeftCircleIcon;

// import ArrowLeftCircleOutlineIcon from 'react-material-icon-svg/dist/ArrowLeftCircleOutline';
// export const ArrowLeftCircleOutlineIconSvg = ArrowLeftCircleOutlineIcon;

// import ArrowLeftDropCircleIcon from 'react-material-icon-svg/dist/ArrowLeftDropCircle';
// export const ArrowLeftDropCircleIconSvg = ArrowLeftDropCircleIcon;

// import ArrowLeftDropCircleOutlineIcon from 'react-material-icon-svg/dist/ArrowLeftDropCircleOutline';
// export const ArrowLeftDropCircleOutlineIconSvg = ArrowLeftDropCircleOutlineIcon;

// import ArrowLeftRightIcon from 'react-material-icon-svg/dist/ArrowLeftRight';
// export const ArrowLeftRightIconSvg = ArrowLeftRightIcon;

// import ArrowLeftRightBoldIcon from 'react-material-icon-svg/dist/ArrowLeftRightBold';
// export const ArrowLeftRightBoldIconSvg = ArrowLeftRightBoldIcon;

// import ArrowLeftRightBoldOutlineIcon from 'react-material-icon-svg/dist/ArrowLeftRightBoldOutline';
// export const ArrowLeftRightBoldOutlineIconSvg = ArrowLeftRightBoldOutlineIcon;

// import ArrowLeftThickIcon from 'react-material-icon-svg/dist/ArrowLeftThick';
// export const ArrowLeftThickIconSvg = ArrowLeftThickIcon;

// import ArrowRightIcon from 'react-material-icon-svg/dist/ArrowRight';
// export const ArrowRightIconSvg = ArrowRightIcon;

// import ArrowRightBoldIcon from 'react-material-icon-svg/dist/ArrowRightBold';
// export const ArrowRightBoldIconSvg = ArrowRightBoldIcon;

// import ArrowRightBoldBoxIcon from 'react-material-icon-svg/dist/ArrowRightBoldBox';
// export const ArrowRightBoldBoxIconSvg = ArrowRightBoldBoxIcon;

// import ArrowRightBoldBoxOutlineIcon from 'react-material-icon-svg/dist/ArrowRightBoldBoxOutline';
// export const ArrowRightBoldBoxOutlineIconSvg = ArrowRightBoldBoxOutlineIcon;

// import ArrowRightBoldCircleIcon from 'react-material-icon-svg/dist/ArrowRightBoldCircle';
// export const ArrowRightBoldCircleIconSvg = ArrowRightBoldCircleIcon;

// import ArrowRightBoldCircleOutlineIcon from 'react-material-icon-svg/dist/ArrowRightBoldCircleOutline';
// export const ArrowRightBoldCircleOutlineIconSvg = ArrowRightBoldCircleOutlineIcon;

// import ArrowRightBoldHexagonOutlineIcon from 'react-material-icon-svg/dist/ArrowRightBoldHexagonOutline';
// export const ArrowRightBoldHexagonOutlineIconSvg = ArrowRightBoldHexagonOutlineIcon;

// import ArrowRightBoldOutlineIcon from 'react-material-icon-svg/dist/ArrowRightBoldOutline';
// export const ArrowRightBoldOutlineIconSvg = ArrowRightBoldOutlineIcon;

// import ArrowRightBoxIcon from 'react-material-icon-svg/dist/ArrowRightBox';
// export const ArrowRightBoxIconSvg = ArrowRightBoxIcon;

// import ArrowRightCircleIcon from 'react-material-icon-svg/dist/ArrowRightCircle';
// export const ArrowRightCircleIconSvg = ArrowRightCircleIcon;

// import ArrowRightCircleOutlineIcon from 'react-material-icon-svg/dist/ArrowRightCircleOutline';
// export const ArrowRightCircleOutlineIconSvg = ArrowRightCircleOutlineIcon;

// import ArrowRightDropCircleIcon from 'react-material-icon-svg/dist/ArrowRightDropCircle';
// export const ArrowRightDropCircleIconSvg = ArrowRightDropCircleIcon;

// import ArrowRightDropCircleOutlineIcon from 'react-material-icon-svg/dist/ArrowRightDropCircleOutline';
// export const ArrowRightDropCircleOutlineIconSvg = ArrowRightDropCircleOutlineIcon;

// import ArrowRightThickIcon from 'react-material-icon-svg/dist/ArrowRightThick';
// export const ArrowRightThickIconSvg = ArrowRightThickIcon;

// import ArrowSplitHorizontalIcon from 'react-material-icon-svg/dist/ArrowSplitHorizontal';
// export const ArrowSplitHorizontalIconSvg = ArrowSplitHorizontalIcon;

// import ArrowSplitVerticalIcon from 'react-material-icon-svg/dist/ArrowSplitVertical';
// export const ArrowSplitVerticalIconSvg = ArrowSplitVerticalIcon;

// import ArrowTopLeftIcon from 'react-material-icon-svg/dist/ArrowTopLeft';
// export const ArrowTopLeftIconSvg = ArrowTopLeftIcon;

// import ArrowTopLeftBoldOutlineIcon from 'react-material-icon-svg/dist/ArrowTopLeftBoldOutline';
// export const ArrowTopLeftBoldOutlineIconSvg = ArrowTopLeftBoldOutlineIcon;

// import ArrowTopLeftBottomRightIcon from 'react-material-icon-svg/dist/ArrowTopLeftBottomRight';
// export const ArrowTopLeftBottomRightIconSvg = ArrowTopLeftBottomRightIcon;

// import ArrowTopLeftBottomRightBoldIcon from 'react-material-icon-svg/dist/ArrowTopLeftBottomRightBold';
// export const ArrowTopLeftBottomRightBoldIconSvg = ArrowTopLeftBottomRightBoldIcon;

// import ArrowTopLeftThickIcon from 'react-material-icon-svg/dist/ArrowTopLeftThick';
// export const ArrowTopLeftThickIconSvg = ArrowTopLeftThickIcon;

// import ArrowTopRightIcon from 'react-material-icon-svg/dist/ArrowTopRight';
// export const ArrowTopRightIconSvg = ArrowTopRightIcon;

// import ArrowTopRightBoldOutlineIcon from 'react-material-icon-svg/dist/ArrowTopRightBoldOutline';
// export const ArrowTopRightBoldOutlineIconSvg = ArrowTopRightBoldOutlineIcon;

// import ArrowTopRightBottomLeftIcon from 'react-material-icon-svg/dist/ArrowTopRightBottomLeft';
// export const ArrowTopRightBottomLeftIconSvg = ArrowTopRightBottomLeftIcon;

// import ArrowTopRightBottomLeftBoldIcon from 'react-material-icon-svg/dist/ArrowTopRightBottomLeftBold';
// export const ArrowTopRightBottomLeftBoldIconSvg = ArrowTopRightBottomLeftBoldIcon;

// import ArrowTopRightThickIcon from 'react-material-icon-svg/dist/ArrowTopRightThick';
// export const ArrowTopRightThickIconSvg = ArrowTopRightThickIcon;

// import ArrowUpIcon from 'react-material-icon-svg/dist/ArrowUp';
// export const ArrowUpIconSvg = ArrowUpIcon;

// import ArrowUpBoldIcon from 'react-material-icon-svg/dist/ArrowUpBold';
// export const ArrowUpBoldIconSvg = ArrowUpBoldIcon;

// import ArrowUpBoldBoxIcon from 'react-material-icon-svg/dist/ArrowUpBoldBox';
// export const ArrowUpBoldBoxIconSvg = ArrowUpBoldBoxIcon;

// import ArrowUpBoldBoxOutlineIcon from 'react-material-icon-svg/dist/ArrowUpBoldBoxOutline';
// export const ArrowUpBoldBoxOutlineIconSvg = ArrowUpBoldBoxOutlineIcon;

// import ArrowUpBoldCircleIcon from 'react-material-icon-svg/dist/ArrowUpBoldCircle';
// export const ArrowUpBoldCircleIconSvg = ArrowUpBoldCircleIcon;

// import ArrowUpBoldCircleOutlineIcon from 'react-material-icon-svg/dist/ArrowUpBoldCircleOutline';
// export const ArrowUpBoldCircleOutlineIconSvg = ArrowUpBoldCircleOutlineIcon;

// import ArrowUpBoldHexagonOutlineIcon from 'react-material-icon-svg/dist/ArrowUpBoldHexagonOutline';
// export const ArrowUpBoldHexagonOutlineIconSvg = ArrowUpBoldHexagonOutlineIcon;

// import ArrowUpBoldOutlineIcon from 'react-material-icon-svg/dist/ArrowUpBoldOutline';
// export const ArrowUpBoldOutlineIconSvg = ArrowUpBoldOutlineIcon;

// import ArrowUpBoxIcon from 'react-material-icon-svg/dist/ArrowUpBox';
// export const ArrowUpBoxIconSvg = ArrowUpBoxIcon;

// import ArrowUpCircleIcon from 'react-material-icon-svg/dist/ArrowUpCircle';
// export const ArrowUpCircleIconSvg = ArrowUpCircleIcon;

// import ArrowUpCircleOutlineIcon from 'react-material-icon-svg/dist/ArrowUpCircleOutline';
// export const ArrowUpCircleOutlineIconSvg = ArrowUpCircleOutlineIcon;

// import ArrowUpDownIcon from 'react-material-icon-svg/dist/ArrowUpDown';
// export const ArrowUpDownIconSvg = ArrowUpDownIcon;

// import ArrowUpDownBoldIcon from 'react-material-icon-svg/dist/ArrowUpDownBold';
// export const ArrowUpDownBoldIconSvg = ArrowUpDownBoldIcon;

// import ArrowUpDownBoldOutlineIcon from 'react-material-icon-svg/dist/ArrowUpDownBoldOutline';
// export const ArrowUpDownBoldOutlineIconSvg = ArrowUpDownBoldOutlineIcon;

// import ArrowUpDropCircleIcon from 'react-material-icon-svg/dist/ArrowUpDropCircle';
// export const ArrowUpDropCircleIconSvg = ArrowUpDropCircleIcon;

// import ArrowUpDropCircleOutlineIcon from 'react-material-icon-svg/dist/ArrowUpDropCircleOutline';
// export const ArrowUpDropCircleOutlineIconSvg = ArrowUpDropCircleOutlineIcon;

// import ArrowUpThickIcon from 'react-material-icon-svg/dist/ArrowUpThick';
// export const ArrowUpThickIconSvg = ArrowUpThickIcon;

// import ArtistIcon from 'react-material-icon-svg/dist/Artist';
// export const ArtistIconSvg = ArtistIcon;

// import ArtistOutlineIcon from 'react-material-icon-svg/dist/ArtistOutline';
// export const ArtistOutlineIconSvg = ArtistOutlineIcon;

// import ArtstationIcon from 'react-material-icon-svg/dist/Artstation';
// export const ArtstationIconSvg = ArtstationIcon;

// import AspectRatioIcon from 'react-material-icon-svg/dist/AspectRatio';
// export const AspectRatioIconSvg = AspectRatioIcon;

// import AssistantIcon from 'react-material-icon-svg/dist/Assistant';
// export const AssistantIconSvg = AssistantIcon;

// import AsteriskIcon from 'react-material-icon-svg/dist/Asterisk';
// export const AsteriskIconSvg = AsteriskIcon;

// import AtIcon from 'react-material-icon-svg/dist/At';
// export const AtIconSvg = AtIcon;

// import AtlassianIcon from 'react-material-icon-svg/dist/Atlassian';
// export const AtlassianIconSvg = AtlassianIcon;

// import AtmIcon from 'react-material-icon-svg/dist/Atm';
// export const AtmIconSvg = AtmIcon;

// import AtomIcon from 'react-material-icon-svg/dist/Atom';
// export const AtomIconSvg = AtomIcon;

// import AtomVariantIcon from 'react-material-icon-svg/dist/AtomVariant';
// export const AtomVariantIconSvg = AtomVariantIcon;

// import AttachmentIcon from 'react-material-icon-svg/dist/Attachment';
// export const AttachmentIconSvg = AttachmentIcon;

// import AudioVideoIcon from 'react-material-icon-svg/dist/AudioVideo';
// export const AudioVideoIconSvg = AudioVideoIcon;

// import AudiobookIcon from 'react-material-icon-svg/dist/Audiobook';
// export const AudiobookIconSvg = AudiobookIcon;

// import AugmentedRealityIcon from 'react-material-icon-svg/dist/AugmentedReality';
// export const AugmentedRealityIconSvg = AugmentedRealityIcon;

// import AutoFixIcon from 'react-material-icon-svg/dist/AutoFix';
// export const AutoFixIconSvg = AutoFixIcon;

// import AutoUploadIcon from 'react-material-icon-svg/dist/AutoUpload';
// export const AutoUploadIconSvg = AutoUploadIcon;

// import AutorenewIcon from 'react-material-icon-svg/dist/Autorenew';
// export const AutorenewIconSvg = AutorenewIcon;

// import AvTimerIcon from 'react-material-icon-svg/dist/AvTimer';
// export const AvTimerIconSvg = AvTimerIcon;

// import AwsIcon from 'react-material-icon-svg/dist/Aws';
// export const AwsIconSvg = AwsIcon;

// import AxeIcon from 'react-material-icon-svg/dist/Axe';
// export const AxeIconSvg = AxeIcon;

// import AxisIcon from 'react-material-icon-svg/dist/Axis';
// export const AxisIconSvg = AxisIcon;

// import AxisArrowIcon from 'react-material-icon-svg/dist/AxisArrow';
// export const AxisArrowIconSvg = AxisArrowIcon;

// import AxisArrowLockIcon from 'react-material-icon-svg/dist/AxisArrowLock';
// export const AxisArrowLockIconSvg = AxisArrowLockIcon;

// import AxisLockIcon from 'react-material-icon-svg/dist/AxisLock';
// export const AxisLockIconSvg = AxisLockIcon;

// import AxisXArrowIcon from 'react-material-icon-svg/dist/AxisXArrow';
// export const AxisXArrowIconSvg = AxisXArrowIcon;

// import AxisXArrowLockIcon from 'react-material-icon-svg/dist/AxisXArrowLock';
// export const AxisXArrowLockIconSvg = AxisXArrowLockIcon;

// import AxisXRotateClockwiseIcon from 'react-material-icon-svg/dist/AxisXRotateClockwise';
// export const AxisXRotateClockwiseIconSvg = AxisXRotateClockwiseIcon;

// import AxisXRotateCounterclockwiseIcon from 'react-material-icon-svg/dist/AxisXRotateCounterclockwise';
// export const AxisXRotateCounterclockwiseIconSvg = AxisXRotateCounterclockwiseIcon;

// import AxisXYArrowLockIcon from 'react-material-icon-svg/dist/AxisXYArrowLock';
// export const AxisXYArrowLockIconSvg = AxisXYArrowLockIcon;

// import AxisYArrowIcon from 'react-material-icon-svg/dist/AxisYArrow';
// export const AxisYArrowIconSvg = AxisYArrowIcon;

// import AxisYArrowLockIcon from 'react-material-icon-svg/dist/AxisYArrowLock';
// export const AxisYArrowLockIconSvg = AxisYArrowLockIcon;

// import AxisYRotateClockwiseIcon from 'react-material-icon-svg/dist/AxisYRotateClockwise';
// export const AxisYRotateClockwiseIconSvg = AxisYRotateClockwiseIcon;

// import AxisYRotateCounterclockwiseIcon from 'react-material-icon-svg/dist/AxisYRotateCounterclockwise';
// export const AxisYRotateCounterclockwiseIconSvg = AxisYRotateCounterclockwiseIcon;

// import AxisZArrowIcon from 'react-material-icon-svg/dist/AxisZArrow';
// export const AxisZArrowIconSvg = AxisZArrowIcon;

// import AxisZArrowLockIcon from 'react-material-icon-svg/dist/AxisZArrowLock';
// export const AxisZArrowLockIconSvg = AxisZArrowLockIcon;

// import AxisZRotateClockwiseIcon from 'react-material-icon-svg/dist/AxisZRotateClockwise';
// export const AxisZRotateClockwiseIconSvg = AxisZRotateClockwiseIcon;

// import AxisZRotateCounterclockwiseIcon from 'react-material-icon-svg/dist/AxisZRotateCounterclockwise';
// export const AxisZRotateCounterclockwiseIconSvg = AxisZRotateCounterclockwiseIcon;

// import AzureIcon from 'react-material-icon-svg/dist/Azure';
// export const AzureIconSvg = AzureIcon;

// import BabelIcon from 'react-material-icon-svg/dist/Babel';
// export const BabelIconSvg = BabelIcon;

// import BabyIcon from 'react-material-icon-svg/dist/Baby';
// export const BabyIconSvg = BabyIcon;

// import BabyBuggyIcon from 'react-material-icon-svg/dist/BabyBuggy';
// export const BabyBuggyIconSvg = BabyBuggyIcon;

// import BabyFaceIcon from 'react-material-icon-svg/dist/BabyFace';
// export const BabyFaceIconSvg = BabyFaceIcon;

// import BabyFaceOutlineIcon from 'react-material-icon-svg/dist/BabyFaceOutline';
// export const BabyFaceOutlineIconSvg = BabyFaceOutlineIcon;

// import BackburgerIcon from 'react-material-icon-svg/dist/Backburger';
// export const BackburgerIconSvg = BackburgerIcon;

// import BackspaceIcon from 'react-material-icon-svg/dist/Backspace';
// export const BackspaceIconSvg = BackspaceIcon;

// import BackspaceOutlineIcon from 'react-material-icon-svg/dist/BackspaceOutline';
// export const BackspaceOutlineIconSvg = BackspaceOutlineIcon;

// import BackspaceReverseIcon from 'react-material-icon-svg/dist/BackspaceReverse';
// export const BackspaceReverseIconSvg = BackspaceReverseIcon;

// import BackspaceReverseOutlineIcon from 'react-material-icon-svg/dist/BackspaceReverseOutline';
// export const BackspaceReverseOutlineIconSvg = BackspaceReverseOutlineIcon;

// import BackupRestoreIcon from 'react-material-icon-svg/dist/BackupRestore';
// export const BackupRestoreIconSvg = BackupRestoreIcon;

// import BadmintonIcon from 'react-material-icon-svg/dist/Badminton';
// export const BadmintonIconSvg = BadmintonIcon;

// import BagPersonalIcon from 'react-material-icon-svg/dist/BagPersonal';
// export const BagPersonalIconSvg = BagPersonalIcon;

// import BagPersonalOffIcon from 'react-material-icon-svg/dist/BagPersonalOff';
// export const BagPersonalOffIconSvg = BagPersonalOffIcon;

// import BagPersonalOffOutlineIcon from 'react-material-icon-svg/dist/BagPersonalOffOutline';
// export const BagPersonalOffOutlineIconSvg = BagPersonalOffOutlineIcon;

// import BagPersonalOutlineIcon from 'react-material-icon-svg/dist/BagPersonalOutline';
// export const BagPersonalOutlineIconSvg = BagPersonalOutlineIcon;

// import BalloonIcon from 'react-material-icon-svg/dist/Balloon';
// export const BalloonIconSvg = BalloonIcon;

// import BallotIcon from 'react-material-icon-svg/dist/Ballot';
// export const BallotIconSvg = BallotIcon;

// import BallotOutlineIcon from 'react-material-icon-svg/dist/BallotOutline';
// export const BallotOutlineIconSvg = BallotOutlineIcon;

// import BallotRecountIcon from 'react-material-icon-svg/dist/BallotRecount';
// export const BallotRecountIconSvg = BallotRecountIcon;

// import BallotRecountOutlineIcon from 'react-material-icon-svg/dist/BallotRecountOutline';
// export const BallotRecountOutlineIconSvg = BallotRecountOutlineIcon;

// import BandageIcon from 'react-material-icon-svg/dist/Bandage';
// export const BandageIconSvg = BandageIcon;

// import BandcampIcon from 'react-material-icon-svg/dist/Bandcamp';
// export const BandcampIconSvg = BandcampIcon;

// import BankIcon from 'react-material-icon-svg/dist/Bank';
// export const BankIconSvg = BankIcon;

// import BankMinusIcon from 'react-material-icon-svg/dist/BankMinus';
// export const BankMinusIconSvg = BankMinusIcon;

// import BankOutlineIcon from 'react-material-icon-svg/dist/BankOutline';
// export const BankOutlineIconSvg = BankOutlineIcon;

// import BankPlusIcon from 'react-material-icon-svg/dist/BankPlus';
// export const BankPlusIconSvg = BankPlusIcon;

// import BankRemoveIcon from 'react-material-icon-svg/dist/BankRemove';
// export const BankRemoveIconSvg = BankRemoveIcon;

// import BankTransferIcon from 'react-material-icon-svg/dist/BankTransfer';
// export const BankTransferIconSvg = BankTransferIcon;

// import BankTransferInIcon from 'react-material-icon-svg/dist/BankTransferIn';
// export const BankTransferInIconSvg = BankTransferInIcon;

// import BankTransferOutIcon from 'react-material-icon-svg/dist/BankTransferOut';
// export const BankTransferOutIconSvg = BankTransferOutIcon;

// import BarcodeIcon from 'react-material-icon-svg/dist/Barcode';
// export const BarcodeIconSvg = BarcodeIcon;

// import BarcodeScanIcon from 'react-material-icon-svg/dist/BarcodeScan';
// export const BarcodeScanIconSvg = BarcodeScanIcon;

// import BarleyIcon from 'react-material-icon-svg/dist/Barley';
// export const BarleyIconSvg = BarleyIcon;

// import BarleyOffIcon from 'react-material-icon-svg/dist/BarleyOff';
// export const BarleyOffIconSvg = BarleyOffIcon;

// import BarnIcon from 'react-material-icon-svg/dist/Barn';
// export const BarnIconSvg = BarnIcon;

// import BarrelIcon from 'react-material-icon-svg/dist/Barrel';
// export const BarrelIconSvg = BarrelIcon;

// import BaseballIcon from 'react-material-icon-svg/dist/Baseball';
// export const BaseballIconSvg = BaseballIcon;

// import BaseballBatIcon from 'react-material-icon-svg/dist/BaseballBat';
// export const BaseballBatIconSvg = BaseballBatIcon;

// import BasecampIcon from 'react-material-icon-svg/dist/Basecamp';
// export const BasecampIconSvg = BasecampIcon;

// import BasketIcon from 'react-material-icon-svg/dist/Basket';
// export const BasketIconSvg = BasketIcon;

// import BasketFillIcon from 'react-material-icon-svg/dist/BasketFill';
// export const BasketFillIconSvg = BasketFillIcon;

// import BasketUnfillIcon from 'react-material-icon-svg/dist/BasketUnfill';
// export const BasketUnfillIconSvg = BasketUnfillIcon;

// import BasketballIcon from 'react-material-icon-svg/dist/Basketball';
// export const BasketballIconSvg = BasketballIcon;

// import BasketballHoopIcon from 'react-material-icon-svg/dist/BasketballHoop';
// export const BasketballHoopIconSvg = BasketballHoopIcon;

// import BasketballHoopOutlineIcon from 'react-material-icon-svg/dist/BasketballHoopOutline';
// export const BasketballHoopOutlineIconSvg = BasketballHoopOutlineIcon;

// import BatIcon from 'react-material-icon-svg/dist/Bat';
// export const BatIconSvg = BatIcon;

// import BatteryIcon from 'react-material-icon-svg/dist/Battery';
// export const BatteryIconSvg = BatteryIcon;

// import BatteryAlertIcon from 'react-material-icon-svg/dist/BatteryAlert';
// export const BatteryAlertIconSvg = BatteryAlertIcon;

// import BatteryAlertBluetoothIcon from 'react-material-icon-svg/dist/BatteryAlertBluetooth';
// export const BatteryAlertBluetoothIconSvg = BatteryAlertBluetoothIcon;

// import BatteryBluetoothIcon from 'react-material-icon-svg/dist/BatteryBluetooth';
// export const BatteryBluetoothIconSvg = BatteryBluetoothIcon;

// import BatteryBluetoothVariantIcon from 'react-material-icon-svg/dist/BatteryBluetoothVariant';
// export const BatteryBluetoothVariantIconSvg = BatteryBluetoothVariantIcon;

// import BatteryChargingIcon from 'react-material-icon-svg/dist/BatteryCharging';
// export const BatteryChargingIconSvg = BatteryChargingIcon;

// import BatteryChargingOutlineIcon from 'react-material-icon-svg/dist/BatteryChargingOutline';
// export const BatteryChargingOutlineIconSvg = BatteryChargingOutlineIcon;

// import BatteryChargingWirelessIcon from 'react-material-icon-svg/dist/BatteryChargingWireless';
// export const BatteryChargingWirelessIconSvg = BatteryChargingWirelessIcon;

// import BatteryChargingWirelessAlertIcon from 'react-material-icon-svg/dist/BatteryChargingWirelessAlert';
// export const BatteryChargingWirelessAlertIconSvg = BatteryChargingWirelessAlertIcon;

// import BatteryChargingWirelessOutlineIcon from 'react-material-icon-svg/dist/BatteryChargingWirelessOutline';
// export const BatteryChargingWirelessOutlineIconSvg = BatteryChargingWirelessOutlineIcon;

// import BatteryChargingWireless_10Icon from 'react-material-icon-svg/dist/BatteryChargingWireless_10';
// export const BatteryChargingWireless_10IconSvg = BatteryChargingWireless_10Icon;

// import BatteryChargingWireless_20Icon from 'react-material-icon-svg/dist/BatteryChargingWireless_20';
// export const BatteryChargingWireless_20IconSvg = BatteryChargingWireless_20Icon;

// import BatteryChargingWireless_30Icon from 'react-material-icon-svg/dist/BatteryChargingWireless_30';
// export const BatteryChargingWireless_30IconSvg = BatteryChargingWireless_30Icon;

// import BatteryChargingWireless_40Icon from 'react-material-icon-svg/dist/BatteryChargingWireless_40';
// export const BatteryChargingWireless_40IconSvg = BatteryChargingWireless_40Icon;

// import BatteryChargingWireless_50Icon from 'react-material-icon-svg/dist/BatteryChargingWireless_50';
// export const BatteryChargingWireless_50IconSvg = BatteryChargingWireless_50Icon;

// import BatteryChargingWireless_60Icon from 'react-material-icon-svg/dist/BatteryChargingWireless_60';
// export const BatteryChargingWireless_60IconSvg = BatteryChargingWireless_60Icon;

// import BatteryChargingWireless_70Icon from 'react-material-icon-svg/dist/BatteryChargingWireless_70';
// export const BatteryChargingWireless_70IconSvg = BatteryChargingWireless_70Icon;

// import BatteryChargingWireless_80Icon from 'react-material-icon-svg/dist/BatteryChargingWireless_80';
// export const BatteryChargingWireless_80IconSvg = BatteryChargingWireless_80Icon;

// import BatteryChargingWireless_90Icon from 'react-material-icon-svg/dist/BatteryChargingWireless_90';
// export const BatteryChargingWireless_90IconSvg = BatteryChargingWireless_90Icon;

// import BatteryCharging_10Icon from 'react-material-icon-svg/dist/BatteryCharging_10';
// export const BatteryCharging_10IconSvg = BatteryCharging_10Icon;

// import BatteryCharging_100Icon from 'react-material-icon-svg/dist/BatteryCharging_100';
// export const BatteryCharging_100IconSvg = BatteryCharging_100Icon;

// import BatteryCharging_20Icon from 'react-material-icon-svg/dist/BatteryCharging_20';
// export const BatteryCharging_20IconSvg = BatteryCharging_20Icon;

// import BatteryCharging_30Icon from 'react-material-icon-svg/dist/BatteryCharging_30';
// export const BatteryCharging_30IconSvg = BatteryCharging_30Icon;

// import BatteryCharging_40Icon from 'react-material-icon-svg/dist/BatteryCharging_40';
// export const BatteryCharging_40IconSvg = BatteryCharging_40Icon;

// import BatteryCharging_50Icon from 'react-material-icon-svg/dist/BatteryCharging_50';
// export const BatteryCharging_50IconSvg = BatteryCharging_50Icon;

// import BatteryCharging_60Icon from 'react-material-icon-svg/dist/BatteryCharging_60';
// export const BatteryCharging_60IconSvg = BatteryCharging_60Icon;

// import BatteryCharging_70Icon from 'react-material-icon-svg/dist/BatteryCharging_70';
// export const BatteryCharging_70IconSvg = BatteryCharging_70Icon;

// import BatteryCharging_80Icon from 'react-material-icon-svg/dist/BatteryCharging_80';
// export const BatteryCharging_80IconSvg = BatteryCharging_80Icon;

// import BatteryCharging_90Icon from 'react-material-icon-svg/dist/BatteryCharging_90';
// export const BatteryCharging_90IconSvg = BatteryCharging_90Icon;

// import BatteryMinusIcon from 'react-material-icon-svg/dist/BatteryMinus';
// export const BatteryMinusIconSvg = BatteryMinusIcon;

// import BatteryNegativeIcon from 'react-material-icon-svg/dist/BatteryNegative';
// export const BatteryNegativeIconSvg = BatteryNegativeIcon;

// import BatteryOutlineIcon from 'react-material-icon-svg/dist/BatteryOutline';
// export const BatteryOutlineIconSvg = BatteryOutlineIcon;

// import BatteryPlusIcon from 'react-material-icon-svg/dist/BatteryPlus';
// export const BatteryPlusIconSvg = BatteryPlusIcon;

// import BatteryPositiveIcon from 'react-material-icon-svg/dist/BatteryPositive';
// export const BatteryPositiveIconSvg = BatteryPositiveIcon;

// import BatteryUnknownIcon from 'react-material-icon-svg/dist/BatteryUnknown';
// export const BatteryUnknownIconSvg = BatteryUnknownIcon;

// import BatteryUnknownBluetoothIcon from 'react-material-icon-svg/dist/BatteryUnknownBluetooth';
// export const BatteryUnknownBluetoothIconSvg = BatteryUnknownBluetoothIcon;

// import Battery_10Icon from 'react-material-icon-svg/dist/Battery_10';
// export const Battery_10IconSvg = Battery_10Icon;

// import Battery_10BluetoothIcon from 'react-material-icon-svg/dist/Battery_10Bluetooth';
// export const Battery_10BluetoothIconSvg = Battery_10BluetoothIcon;

// import Battery_20Icon from 'react-material-icon-svg/dist/Battery_20';
// export const Battery_20IconSvg = Battery_20Icon;

// import Battery_20BluetoothIcon from 'react-material-icon-svg/dist/Battery_20Bluetooth';
// export const Battery_20BluetoothIconSvg = Battery_20BluetoothIcon;

// import Battery_30Icon from 'react-material-icon-svg/dist/Battery_30';
// export const Battery_30IconSvg = Battery_30Icon;

// import Battery_30BluetoothIcon from 'react-material-icon-svg/dist/Battery_30Bluetooth';
// export const Battery_30BluetoothIconSvg = Battery_30BluetoothIcon;

// import Battery_40Icon from 'react-material-icon-svg/dist/Battery_40';
// export const Battery_40IconSvg = Battery_40Icon;

// import Battery_40BluetoothIcon from 'react-material-icon-svg/dist/Battery_40Bluetooth';
// export const Battery_40BluetoothIconSvg = Battery_40BluetoothIcon;

// import Battery_50Icon from 'react-material-icon-svg/dist/Battery_50';
// export const Battery_50IconSvg = Battery_50Icon;

// import Battery_50BluetoothIcon from 'react-material-icon-svg/dist/Battery_50Bluetooth';
// export const Battery_50BluetoothIconSvg = Battery_50BluetoothIcon;

// import Battery_60Icon from 'react-material-icon-svg/dist/Battery_60';
// export const Battery_60IconSvg = Battery_60Icon;

// import Battery_60BluetoothIcon from 'react-material-icon-svg/dist/Battery_60Bluetooth';
// export const Battery_60BluetoothIconSvg = Battery_60BluetoothIcon;

// import Battery_70Icon from 'react-material-icon-svg/dist/Battery_70';
// export const Battery_70IconSvg = Battery_70Icon;

// import Battery_70BluetoothIcon from 'react-material-icon-svg/dist/Battery_70Bluetooth';
// export const Battery_70BluetoothIconSvg = Battery_70BluetoothIcon;

// import Battery_80Icon from 'react-material-icon-svg/dist/Battery_80';
// export const Battery_80IconSvg = Battery_80Icon;

// import Battery_80BluetoothIcon from 'react-material-icon-svg/dist/Battery_80Bluetooth';
// export const Battery_80BluetoothIconSvg = Battery_80BluetoothIcon;

// import Battery_90Icon from 'react-material-icon-svg/dist/Battery_90';
// export const Battery_90IconSvg = Battery_90Icon;

// import Battery_90BluetoothIcon from 'react-material-icon-svg/dist/Battery_90Bluetooth';
// export const Battery_90BluetoothIconSvg = Battery_90BluetoothIcon;

// import BattlenetIcon from 'react-material-icon-svg/dist/Battlenet';
// export const BattlenetIconSvg = BattlenetIcon;

// import BeachIcon from 'react-material-icon-svg/dist/Beach';
// export const BeachIconSvg = BeachIcon;

// import BeakerIcon from 'react-material-icon-svg/dist/Beaker';
// export const BeakerIconSvg = BeakerIcon;

// import BeakerOutlineIcon from 'react-material-icon-svg/dist/BeakerOutline';
// export const BeakerOutlineIconSvg = BeakerOutlineIcon;

// import BeatsIcon from 'react-material-icon-svg/dist/Beats';
// export const BeatsIconSvg = BeatsIcon;

// import BedEmptyIcon from 'react-material-icon-svg/dist/BedEmpty';
// export const BedEmptyIconSvg = BedEmptyIcon;

// import BeerIcon from 'react-material-icon-svg/dist/Beer';
// export const BeerIconSvg = BeerIcon;

// import BehanceIcon from 'react-material-icon-svg/dist/Behance';
// export const BehanceIconSvg = BehanceIcon;

// import BellIcon from 'react-material-icon-svg/dist/Bell';
// export const BellIconSvg = BellIcon;

// import BellAlertIcon from 'react-material-icon-svg/dist/BellAlert';
// export const BellAlertIconSvg = BellAlertIcon;

// import BellAlertOutlineIcon from 'react-material-icon-svg/dist/BellAlertOutline';
// export const BellAlertOutlineIconSvg = BellAlertOutlineIcon;

// import BellCircleIcon from 'react-material-icon-svg/dist/BellCircle';
// export const BellCircleIconSvg = BellCircleIcon;

// import BellCircleOutlineIcon from 'react-material-icon-svg/dist/BellCircleOutline';
// export const BellCircleOutlineIconSvg = BellCircleOutlineIcon;

// import BellOffIcon from 'react-material-icon-svg/dist/BellOff';
// export const BellOffIconSvg = BellOffIcon;

// import BellOffOutlineIcon from 'react-material-icon-svg/dist/BellOffOutline';
// export const BellOffOutlineIconSvg = BellOffOutlineIcon;

import BellOutlineIcon from 'react-material-icon-svg/dist/BellOutline';
export const BellOutlineIconSvg = BellOutlineIcon;

// import BellPlusIcon from 'react-material-icon-svg/dist/BellPlus';
// export const BellPlusIconSvg = BellPlusIcon;

// import BellPlusOutlineIcon from 'react-material-icon-svg/dist/BellPlusOutline';
// export const BellPlusOutlineIconSvg = BellPlusOutlineIcon;

// import BellRingIcon from 'react-material-icon-svg/dist/BellRing';
// export const BellRingIconSvg = BellRingIcon;

// import BellRingOutlineIcon from 'react-material-icon-svg/dist/BellRingOutline';
// export const BellRingOutlineIconSvg = BellRingOutlineIcon;

// import BellSleepIcon from 'react-material-icon-svg/dist/BellSleep';
// export const BellSleepIconSvg = BellSleepIcon;

// import BellSleepOutlineIcon from 'react-material-icon-svg/dist/BellSleepOutline';
// export const BellSleepOutlineIconSvg = BellSleepOutlineIcon;

// import BetaIcon from 'react-material-icon-svg/dist/Beta';
// export const BetaIconSvg = BetaIcon;

// import BetamaxIcon from 'react-material-icon-svg/dist/Betamax';
// export const BetamaxIconSvg = BetamaxIcon;

// import BiathlonIcon from 'react-material-icon-svg/dist/Biathlon';
// export const BiathlonIconSvg = BiathlonIcon;

// import BibleIcon from 'react-material-icon-svg/dist/Bible';
// export const BibleIconSvg = BibleIcon;

// import BikeIcon from 'react-material-icon-svg/dist/Bike';
// export const BikeIconSvg = BikeIcon;

// import BilliardsIcon from 'react-material-icon-svg/dist/Billiards';
// export const BilliardsIconSvg = BilliardsIcon;

// import BilliardsRackIcon from 'react-material-icon-svg/dist/BilliardsRack';
// export const BilliardsRackIconSvg = BilliardsRackIcon;

// import BingIcon from 'react-material-icon-svg/dist/Bing';
// export const BingIconSvg = BingIcon;

// import BinocularsIcon from 'react-material-icon-svg/dist/Binoculars';
// export const BinocularsIconSvg = BinocularsIcon;

// import BioIcon from 'react-material-icon-svg/dist/Bio';
// export const BioIconSvg = BioIcon;

// import BiohazardIcon from 'react-material-icon-svg/dist/Biohazard';
// export const BiohazardIconSvg = BiohazardIcon;

// import BitbucketIcon from 'react-material-icon-svg/dist/Bitbucket';
// export const BitbucketIconSvg = BitbucketIcon;

// import BitcoinIcon from 'react-material-icon-svg/dist/Bitcoin';
// export const BitcoinIconSvg = BitcoinIcon;

// import BlackMesaIcon from 'react-material-icon-svg/dist/BlackMesa';
// export const BlackMesaIconSvg = BlackMesaIcon;

// import BlackberryIcon from 'react-material-icon-svg/dist/Blackberry';
// export const BlackberryIconSvg = BlackberryIcon;

// import BlenderIcon from 'react-material-icon-svg/dist/Blender';
// export const BlenderIconSvg = BlenderIcon;

// import BlenderSoftwareIcon from 'react-material-icon-svg/dist/BlenderSoftware';
// export const BlenderSoftwareIconSvg = BlenderSoftwareIcon;

// import BlindsIcon from 'react-material-icon-svg/dist/Blinds';
// export const BlindsIconSvg = BlindsIcon;

// import BlockHelperIcon from 'react-material-icon-svg/dist/BlockHelper';
// export const BlockHelperIconSvg = BlockHelperIcon;

// import BloggerIcon from 'react-material-icon-svg/dist/Blogger';
// export const BloggerIconSvg = BloggerIcon;

// import BloodBagIcon from 'react-material-icon-svg/dist/BloodBag';
// export const BloodBagIconSvg = BloodBagIcon;

// import BluetoothIcon from 'react-material-icon-svg/dist/Bluetooth';
// export const BluetoothIconSvg = BluetoothIcon;

// import BluetoothAudioIcon from 'react-material-icon-svg/dist/BluetoothAudio';
// export const BluetoothAudioIconSvg = BluetoothAudioIcon;

// import BluetoothConnectIcon from 'react-material-icon-svg/dist/BluetoothConnect';
// export const BluetoothConnectIconSvg = BluetoothConnectIcon;

// import BluetoothOffIcon from 'react-material-icon-svg/dist/BluetoothOff';
// export const BluetoothOffIconSvg = BluetoothOffIcon;

// import BluetoothSettingsIcon from 'react-material-icon-svg/dist/BluetoothSettings';
// export const BluetoothSettingsIconSvg = BluetoothSettingsIcon;

// import BluetoothTransferIcon from 'react-material-icon-svg/dist/BluetoothTransfer';
// export const BluetoothTransferIconSvg = BluetoothTransferIcon;

// import BlurIcon from 'react-material-icon-svg/dist/Blur';
// export const BlurIconSvg = BlurIcon;

// import BlurLinearIcon from 'react-material-icon-svg/dist/BlurLinear';
// export const BlurLinearIconSvg = BlurLinearIcon;

// import BlurOffIcon from 'react-material-icon-svg/dist/BlurOff';
// export const BlurOffIconSvg = BlurOffIcon;

// import BlurRadialIcon from 'react-material-icon-svg/dist/BlurRadial';
// export const BlurRadialIconSvg = BlurRadialIcon;

// import BolnisiCrossIcon from 'react-material-icon-svg/dist/BolnisiCross';
// export const BolnisiCrossIconSvg = BolnisiCrossIcon;

// import BoltIcon from 'react-material-icon-svg/dist/Bolt';
// export const BoltIconSvg = BoltIcon;

// import BombIcon from 'react-material-icon-svg/dist/Bomb';
// export const BombIconSvg = BombIcon;

// import BombOffIcon from 'react-material-icon-svg/dist/BombOff';
// export const BombOffIconSvg = BombOffIcon;

// import BoneIcon from 'react-material-icon-svg/dist/Bone';
// export const BoneIconSvg = BoneIcon;

import BookIcon from 'react-material-icon-svg/dist/Book';
export const BookIconSvg = BookIcon;

// import BookLockIcon from 'react-material-icon-svg/dist/BookLock';
// export const BookLockIconSvg = BookLockIcon;

// import BookLockOpenIcon from 'react-material-icon-svg/dist/BookLockOpen';
// export const BookLockOpenIconSvg = BookLockOpenIcon;

// import BookMinusIcon from 'react-material-icon-svg/dist/BookMinus';
// export const BookMinusIconSvg = BookMinusIcon;

// import BookMultipleIcon from 'react-material-icon-svg/dist/BookMultiple';
// export const BookMultipleIconSvg = BookMultipleIcon;

// import BookMultipleMinusIcon from 'react-material-icon-svg/dist/BookMultipleMinus';
// export const BookMultipleMinusIconSvg = BookMultipleMinusIcon;

// import BookMultiplePlusIcon from 'react-material-icon-svg/dist/BookMultiplePlus';
// export const BookMultiplePlusIconSvg = BookMultiplePlusIcon;

// import BookMultipleRemoveIcon from 'react-material-icon-svg/dist/BookMultipleRemove';
// export const BookMultipleRemoveIconSvg = BookMultipleRemoveIcon;

// import BookMultipleVariantIcon from 'react-material-icon-svg/dist/BookMultipleVariant';
// export const BookMultipleVariantIconSvg = BookMultipleVariantIcon;

// import BookOpenIcon from 'react-material-icon-svg/dist/BookOpen';
// export const BookOpenIconSvg = BookOpenIcon;

// import BookOpenOutlineIcon from 'react-material-icon-svg/dist/BookOpenOutline';
// export const BookOpenOutlineIconSvg = BookOpenOutlineIcon;

// import BookOpenPageVariantIcon from 'react-material-icon-svg/dist/BookOpenPageVariant';
// export const BookOpenPageVariantIconSvg = BookOpenPageVariantIcon;

// import BookOpenVariantIcon from 'react-material-icon-svg/dist/BookOpenVariant';
// export const BookOpenVariantIconSvg = BookOpenVariantIcon;

// import BookOutlineIcon from 'react-material-icon-svg/dist/BookOutline';
// export const BookOutlineIconSvg = BookOutlineIcon;

// import BookPlayIcon from 'react-material-icon-svg/dist/BookPlay';
// export const BookPlayIconSvg = BookPlayIcon;

// import BookPlayOutlineIcon from 'react-material-icon-svg/dist/BookPlayOutline';
// export const BookPlayOutlineIconSvg = BookPlayOutlineIcon;

// import BookPlusIcon from 'react-material-icon-svg/dist/BookPlus';
// export const BookPlusIconSvg = BookPlusIcon;

// import BookRemoveIcon from 'react-material-icon-svg/dist/BookRemove';
// export const BookRemoveIconSvg = BookRemoveIcon;

// import BookSearchIcon from 'react-material-icon-svg/dist/BookSearch';
// export const BookSearchIconSvg = BookSearchIcon;

// import BookSearchOutlineIcon from 'react-material-icon-svg/dist/BookSearchOutline';
// export const BookSearchOutlineIconSvg = BookSearchOutlineIcon;

// import BookVariantIcon from 'react-material-icon-svg/dist/BookVariant';
// export const BookVariantIconSvg = BookVariantIcon;

// import BookmarkIcon from 'react-material-icon-svg/dist/Bookmark';
// export const BookmarkIconSvg = BookmarkIcon;

// import BookmarkCheckIcon from 'react-material-icon-svg/dist/BookmarkCheck';
// export const BookmarkCheckIconSvg = BookmarkCheckIcon;

// import BookmarkMinusIcon from 'react-material-icon-svg/dist/BookmarkMinus';
// export const BookmarkMinusIconSvg = BookmarkMinusIcon;

// import BookmarkMinusOutlineIcon from 'react-material-icon-svg/dist/BookmarkMinusOutline';
// export const BookmarkMinusOutlineIconSvg = BookmarkMinusOutlineIcon;

// import BookmarkMultipleIcon from 'react-material-icon-svg/dist/BookmarkMultiple';
// export const BookmarkMultipleIconSvg = BookmarkMultipleIcon;

// import BookmarkMultipleOutlineIcon from 'react-material-icon-svg/dist/BookmarkMultipleOutline';
// export const BookmarkMultipleOutlineIconSvg = BookmarkMultipleOutlineIcon;

// import BookmarkMusicIcon from 'react-material-icon-svg/dist/BookmarkMusic';
// export const BookmarkMusicIconSvg = BookmarkMusicIcon;

// import BookmarkOffIcon from 'react-material-icon-svg/dist/BookmarkOff';
// export const BookmarkOffIconSvg = BookmarkOffIcon;

// import BookmarkOffOutlineIcon from 'react-material-icon-svg/dist/BookmarkOffOutline';
// export const BookmarkOffOutlineIconSvg = BookmarkOffOutlineIcon;

// import BookmarkOutlineIcon from 'react-material-icon-svg/dist/BookmarkOutline';
// export const BookmarkOutlineIconSvg = BookmarkOutlineIcon;

// import BookmarkPlusIcon from 'react-material-icon-svg/dist/BookmarkPlus';
// export const BookmarkPlusIconSvg = BookmarkPlusIcon;

// import BookmarkPlusOutlineIcon from 'react-material-icon-svg/dist/BookmarkPlusOutline';
// export const BookmarkPlusOutlineIconSvg = BookmarkPlusOutlineIcon;

// import BookmarkRemoveIcon from 'react-material-icon-svg/dist/BookmarkRemove';
// export const BookmarkRemoveIconSvg = BookmarkRemoveIcon;

// import BoomGateIcon from 'react-material-icon-svg/dist/BoomGate';
// export const BoomGateIconSvg = BoomGateIcon;

// import BoomGateAlertIcon from 'react-material-icon-svg/dist/BoomGateAlert';
// export const BoomGateAlertIconSvg = BoomGateAlertIcon;

// import BoomGateAlertOutlineIcon from 'react-material-icon-svg/dist/BoomGateAlertOutline';
// export const BoomGateAlertOutlineIconSvg = BoomGateAlertOutlineIcon;

// import BoomGateDownIcon from 'react-material-icon-svg/dist/BoomGateDown';
// export const BoomGateDownIconSvg = BoomGateDownIcon;

// import BoomGateDownOutlineIcon from 'react-material-icon-svg/dist/BoomGateDownOutline';
// export const BoomGateDownOutlineIconSvg = BoomGateDownOutlineIcon;

// import BoomGateOutlineIcon from 'react-material-icon-svg/dist/BoomGateOutline';
// export const BoomGateOutlineIconSvg = BoomGateOutlineIcon;

// import BoomGateUpIcon from 'react-material-icon-svg/dist/BoomGateUp';
// export const BoomGateUpIconSvg = BoomGateUpIcon;

// import BoomGateUpOutlineIcon from 'react-material-icon-svg/dist/BoomGateUpOutline';
// export const BoomGateUpOutlineIconSvg = BoomGateUpOutlineIcon;

// import BoomboxIcon from 'react-material-icon-svg/dist/Boombox';
// export const BoomboxIconSvg = BoomboxIcon;

// import BootstrapIcon from 'react-material-icon-svg/dist/Bootstrap';
// export const BootstrapIconSvg = BootstrapIcon;

// import BorderAllIcon from 'react-material-icon-svg/dist/BorderAll';
// export const BorderAllIconSvg = BorderAllIcon;

// import BorderAllVariantIcon from 'react-material-icon-svg/dist/BorderAllVariant';
// export const BorderAllVariantIconSvg = BorderAllVariantIcon;

// import BorderBottomIcon from 'react-material-icon-svg/dist/BorderBottom';
// export const BorderBottomIconSvg = BorderBottomIcon;

// import BorderBottomVariantIcon from 'react-material-icon-svg/dist/BorderBottomVariant';
// export const BorderBottomVariantIconSvg = BorderBottomVariantIcon;

// import BorderColorIcon from 'react-material-icon-svg/dist/BorderColor';
// export const BorderColorIconSvg = BorderColorIcon;

// import BorderHorizontalIcon from 'react-material-icon-svg/dist/BorderHorizontal';
// export const BorderHorizontalIconSvg = BorderHorizontalIcon;

// import BorderInsideIcon from 'react-material-icon-svg/dist/BorderInside';
// export const BorderInsideIconSvg = BorderInsideIcon;

// import BorderLeftIcon from 'react-material-icon-svg/dist/BorderLeft';
// export const BorderLeftIconSvg = BorderLeftIcon;

// import BorderLeftVariantIcon from 'react-material-icon-svg/dist/BorderLeftVariant';
// export const BorderLeftVariantIconSvg = BorderLeftVariantIcon;

// import BorderNoneIcon from 'react-material-icon-svg/dist/BorderNone';
// export const BorderNoneIconSvg = BorderNoneIcon;

// import BorderNoneVariantIcon from 'react-material-icon-svg/dist/BorderNoneVariant';
// export const BorderNoneVariantIconSvg = BorderNoneVariantIcon;

// import BorderOutsideIcon from 'react-material-icon-svg/dist/BorderOutside';
// export const BorderOutsideIconSvg = BorderOutsideIcon;

// import BorderRightIcon from 'react-material-icon-svg/dist/BorderRight';
// export const BorderRightIconSvg = BorderRightIcon;

// import BorderRightVariantIcon from 'react-material-icon-svg/dist/BorderRightVariant';
// export const BorderRightVariantIconSvg = BorderRightVariantIcon;

// import BorderStyleIcon from 'react-material-icon-svg/dist/BorderStyle';
// export const BorderStyleIconSvg = BorderStyleIcon;

// import BorderTopIcon from 'react-material-icon-svg/dist/BorderTop';
// export const BorderTopIconSvg = BorderTopIcon;

// import BorderTopVariantIcon from 'react-material-icon-svg/dist/BorderTopVariant';
// export const BorderTopVariantIconSvg = BorderTopVariantIcon;

// import BorderVerticalIcon from 'react-material-icon-svg/dist/BorderVertical';
// export const BorderVerticalIconSvg = BorderVerticalIcon;

// import BottleWineIcon from 'react-material-icon-svg/dist/BottleWine';
// export const BottleWineIconSvg = BottleWineIcon;

// import BowTieIcon from 'react-material-icon-svg/dist/BowTie';
// export const BowTieIconSvg = BowTieIcon;

// import BowlIcon from 'react-material-icon-svg/dist/Bowl';
// export const BowlIconSvg = BowlIcon;

// import BowlingIcon from 'react-material-icon-svg/dist/Bowling';
// export const BowlingIconSvg = BowlingIcon;

// import BoxIcon from 'react-material-icon-svg/dist/Box';
// export const BoxIconSvg = BoxIcon;

// import BoxCutterIcon from 'react-material-icon-svg/dist/BoxCutter';
// export const BoxCutterIconSvg = BoxCutterIcon;

// import BoxShadowIcon from 'react-material-icon-svg/dist/BoxShadow';
// export const BoxShadowIconSvg = BoxShadowIcon;

// import BoxingGloveIcon from 'react-material-icon-svg/dist/BoxingGlove';
// export const BoxingGloveIconSvg = BoxingGloveIcon;

// import BrailleIcon from 'react-material-icon-svg/dist/Braille';
// export const BrailleIconSvg = BrailleIcon;

// import BrainIcon from 'react-material-icon-svg/dist/Brain';
// export const BrainIconSvg = BrainIcon;

// import BreadSliceIcon from 'react-material-icon-svg/dist/BreadSlice';
// export const BreadSliceIconSvg = BreadSliceIcon;

// import BreadSliceOutlineIcon from 'react-material-icon-svg/dist/BreadSliceOutline';
// export const BreadSliceOutlineIconSvg = BreadSliceOutlineIcon;

// import BridgeIcon from 'react-material-icon-svg/dist/Bridge';
// export const BridgeIconSvg = BridgeIcon;

// import BriefcaseIcon from 'react-material-icon-svg/dist/Briefcase';
// export const BriefcaseIconSvg = BriefcaseIcon;

// import BriefcaseAccountIcon from 'react-material-icon-svg/dist/BriefcaseAccount';
// export const BriefcaseAccountIconSvg = BriefcaseAccountIcon;

// import BriefcaseAccountOutlineIcon from 'react-material-icon-svg/dist/BriefcaseAccountOutline';
// export const BriefcaseAccountOutlineIconSvg = BriefcaseAccountOutlineIcon;

// import BriefcaseCheckIcon from 'react-material-icon-svg/dist/BriefcaseCheck';
// export const BriefcaseCheckIconSvg = BriefcaseCheckIcon;

// import BriefcaseDownloadIcon from 'react-material-icon-svg/dist/BriefcaseDownload';
// export const BriefcaseDownloadIconSvg = BriefcaseDownloadIcon;

// import BriefcaseDownloadOutlineIcon from 'react-material-icon-svg/dist/BriefcaseDownloadOutline';
// export const BriefcaseDownloadOutlineIconSvg = BriefcaseDownloadOutlineIcon;

// import BriefcaseEditIcon from 'react-material-icon-svg/dist/BriefcaseEdit';
// export const BriefcaseEditIconSvg = BriefcaseEditIcon;

// import BriefcaseEditOutlineIcon from 'react-material-icon-svg/dist/BriefcaseEditOutline';
// export const BriefcaseEditOutlineIconSvg = BriefcaseEditOutlineIcon;

// import BriefcaseMinusIcon from 'react-material-icon-svg/dist/BriefcaseMinus';
// export const BriefcaseMinusIconSvg = BriefcaseMinusIcon;

// import BriefcaseMinusOutlineIcon from 'react-material-icon-svg/dist/BriefcaseMinusOutline';
// export const BriefcaseMinusOutlineIconSvg = BriefcaseMinusOutlineIcon;

// import BriefcaseOutlineIcon from 'react-material-icon-svg/dist/BriefcaseOutline';
// export const BriefcaseOutlineIconSvg = BriefcaseOutlineIcon;

// import BriefcasePlusIcon from 'react-material-icon-svg/dist/BriefcasePlus';
// export const BriefcasePlusIconSvg = BriefcasePlusIcon;

// import BriefcasePlusOutlineIcon from 'react-material-icon-svg/dist/BriefcasePlusOutline';
// export const BriefcasePlusOutlineIconSvg = BriefcasePlusOutlineIcon;

// import BriefcaseRemoveIcon from 'react-material-icon-svg/dist/BriefcaseRemove';
// export const BriefcaseRemoveIconSvg = BriefcaseRemoveIcon;

// import BriefcaseRemoveOutlineIcon from 'react-material-icon-svg/dist/BriefcaseRemoveOutline';
// export const BriefcaseRemoveOutlineIconSvg = BriefcaseRemoveOutlineIcon;

// import BriefcaseSearchIcon from 'react-material-icon-svg/dist/BriefcaseSearch';
// export const BriefcaseSearchIconSvg = BriefcaseSearchIcon;

// import BriefcaseSearchOutlineIcon from 'react-material-icon-svg/dist/BriefcaseSearchOutline';
// export const BriefcaseSearchOutlineIconSvg = BriefcaseSearchOutlineIcon;

// import BriefcaseUploadIcon from 'react-material-icon-svg/dist/BriefcaseUpload';
// export const BriefcaseUploadIconSvg = BriefcaseUploadIcon;

// import BriefcaseUploadOutlineIcon from 'react-material-icon-svg/dist/BriefcaseUploadOutline';
// export const BriefcaseUploadOutlineIconSvg = BriefcaseUploadOutlineIcon;

// import BrightnessAutoIcon from 'react-material-icon-svg/dist/BrightnessAuto';
// export const BrightnessAutoIconSvg = BrightnessAutoIcon;

// import BrightnessPercentIcon from 'react-material-icon-svg/dist/BrightnessPercent';
// export const BrightnessPercentIconSvg = BrightnessPercentIcon;

// import Brightness_1Icon from 'react-material-icon-svg/dist/Brightness_1';
// export const Brightness_1IconSvg = Brightness_1Icon;

// import Brightness_2Icon from 'react-material-icon-svg/dist/Brightness_2';
// export const Brightness_2IconSvg = Brightness_2Icon;

// import Brightness_3Icon from 'react-material-icon-svg/dist/Brightness_3';
// export const Brightness_3IconSvg = Brightness_3Icon;

// import Brightness_4Icon from 'react-material-icon-svg/dist/Brightness_4';
// export const Brightness_4IconSvg = Brightness_4Icon;

// import Brightness_5Icon from 'react-material-icon-svg/dist/Brightness_5';
// export const Brightness_5IconSvg = Brightness_5Icon;

// import Brightness_6Icon from 'react-material-icon-svg/dist/Brightness_6';
// export const Brightness_6IconSvg = Brightness_6Icon;

// import Brightness_7Icon from 'react-material-icon-svg/dist/Brightness_7';
// export const Brightness_7IconSvg = Brightness_7Icon;

// import BroomIcon from 'react-material-icon-svg/dist/Broom';
// export const BroomIconSvg = BroomIcon;

// import BrushIcon from 'react-material-icon-svg/dist/Brush';
// export const BrushIconSvg = BrushIcon;

// import BuddhismIcon from 'react-material-icon-svg/dist/Buddhism';
// export const BuddhismIconSvg = BuddhismIcon;

// import BufferIcon from 'react-material-icon-svg/dist/Buffer';
// export const BufferIconSvg = BufferIcon;

// import BugIcon from 'react-material-icon-svg/dist/Bug';
// export const BugIconSvg = BugIcon;

// import BugCheckIcon from 'react-material-icon-svg/dist/BugCheck';
// export const BugCheckIconSvg = BugCheckIcon;

// import BugCheckOutlineIcon from 'react-material-icon-svg/dist/BugCheckOutline';
// export const BugCheckOutlineIconSvg = BugCheckOutlineIcon;

// import BugOutlineIcon from 'react-material-icon-svg/dist/BugOutline';
// export const BugOutlineIconSvg = BugOutlineIcon;

// import BugleIcon from 'react-material-icon-svg/dist/Bugle';
// export const BugleIconSvg = BugleIcon;

// import BulldozerIcon from 'react-material-icon-svg/dist/Bulldozer';
// export const BulldozerIconSvg = BulldozerIcon;

// import BulletIcon from 'react-material-icon-svg/dist/Bullet';
// export const BulletIconSvg = BulletIcon;

// import BulletinBoardIcon from 'react-material-icon-svg/dist/BulletinBoard';
// export const BulletinBoardIconSvg = BulletinBoardIcon;

// import BullhornIcon from 'react-material-icon-svg/dist/Bullhorn';
// export const BullhornIconSvg = BullhornIcon;

// import BullhornOutlineIcon from 'react-material-icon-svg/dist/BullhornOutline';
// export const BullhornOutlineIconSvg = BullhornOutlineIcon;

// import BullseyeIcon from 'react-material-icon-svg/dist/Bullseye';
// export const BullseyeIconSvg = BullseyeIcon;

// import BullseyeArrowIcon from 'react-material-icon-svg/dist/BullseyeArrow';
// export const BullseyeArrowIconSvg = BullseyeArrowIcon;

// import BusIcon from 'react-material-icon-svg/dist/Bus';
// export const BusIconSvg = BusIcon;

// import BusAlertIcon from 'react-material-icon-svg/dist/BusAlert';
// export const BusAlertIconSvg = BusAlertIcon;

// import BusArticulatedEndIcon from 'react-material-icon-svg/dist/BusArticulatedEnd';
// export const BusArticulatedEndIconSvg = BusArticulatedEndIcon;

// import BusArticulatedFrontIcon from 'react-material-icon-svg/dist/BusArticulatedFront';
// export const BusArticulatedFrontIconSvg = BusArticulatedFrontIcon;

// import BusClockIcon from 'react-material-icon-svg/dist/BusClock';
// export const BusClockIconSvg = BusClockIcon;

// import BusDoubleDeckerIcon from 'react-material-icon-svg/dist/BusDoubleDecker';
// export const BusDoubleDeckerIconSvg = BusDoubleDeckerIcon;

// import BusSchoolIcon from 'react-material-icon-svg/dist/BusSchool';
// export const BusSchoolIconSvg = BusSchoolIcon;

// import BusSideIcon from 'react-material-icon-svg/dist/BusSide';
// export const BusSideIconSvg = BusSideIcon;

// import CachedIcon from 'react-material-icon-svg/dist/Cached';
// export const CachedIconSvg = CachedIcon;

// import CactusIcon from 'react-material-icon-svg/dist/Cactus';
// export const CactusIconSvg = CactusIcon;

// import CakeIcon from 'react-material-icon-svg/dist/Cake';
// export const CakeIconSvg = CakeIcon;

// import CakeLayeredIcon from 'react-material-icon-svg/dist/CakeLayered';
// export const CakeLayeredIconSvg = CakeLayeredIcon;

// import CakeVariantIcon from 'react-material-icon-svg/dist/CakeVariant';
// export const CakeVariantIconSvg = CakeVariantIcon;

// import CalculatorIcon from 'react-material-icon-svg/dist/Calculator';
// export const CalculatorIconSvg = CalculatorIcon;

// import CalculatorVariantIcon from 'react-material-icon-svg/dist/CalculatorVariant';
// export const CalculatorVariantIconSvg = CalculatorVariantIcon;

// import CalendarIcon from 'react-material-icon-svg/dist/Calendar';
// export const CalendarIconSvg = CalendarIcon;

// import CalendarAlertIcon from 'react-material-icon-svg/dist/CalendarAlert';
// export const CalendarAlertIconSvg = CalendarAlertIcon;

// import CalendarBlankIcon from 'react-material-icon-svg/dist/CalendarBlank';
// export const CalendarBlankIconSvg = CalendarBlankIcon;

// import CalendarBlankOutlineIcon from 'react-material-icon-svg/dist/CalendarBlankOutline';
// export const CalendarBlankOutlineIconSvg = CalendarBlankOutlineIcon;

// import CalendarCheckIcon from 'react-material-icon-svg/dist/CalendarCheck';
// export const CalendarCheckIconSvg = CalendarCheckIcon;

// import CalendarCheckOutlineIcon from 'react-material-icon-svg/dist/CalendarCheckOutline';
// export const CalendarCheckOutlineIconSvg = CalendarCheckOutlineIcon;

// import CalendarClockIcon from 'react-material-icon-svg/dist/CalendarClock';
// export const CalendarClockIconSvg = CalendarClockIcon;

// import CalendarEditIcon from 'react-material-icon-svg/dist/CalendarEdit';
// export const CalendarEditIconSvg = CalendarEditIcon;

// import CalendarExportIcon from 'react-material-icon-svg/dist/CalendarExport';
// export const CalendarExportIconSvg = CalendarExportIcon;

// import CalendarHeartIcon from 'react-material-icon-svg/dist/CalendarHeart';
// export const CalendarHeartIconSvg = CalendarHeartIcon;

// import CalendarImportIcon from 'react-material-icon-svg/dist/CalendarImport';
// export const CalendarImportIconSvg = CalendarImportIcon;

// import CalendarMinusIcon from 'react-material-icon-svg/dist/CalendarMinus';
// export const CalendarMinusIconSvg = CalendarMinusIcon;

// import CalendarMonthIcon from 'react-material-icon-svg/dist/CalendarMonth';
// export const CalendarMonthIconSvg = CalendarMonthIcon;

// import CalendarMonthOutlineIcon from 'react-material-icon-svg/dist/CalendarMonthOutline';
// export const CalendarMonthOutlineIconSvg = CalendarMonthOutlineIcon;

// import CalendarMultipleIcon from 'react-material-icon-svg/dist/CalendarMultiple';
// export const CalendarMultipleIconSvg = CalendarMultipleIcon;

// import CalendarMultipleCheckIcon from 'react-material-icon-svg/dist/CalendarMultipleCheck';
// export const CalendarMultipleCheckIconSvg = CalendarMultipleCheckIcon;

// import CalendarMultiselectIcon from 'react-material-icon-svg/dist/CalendarMultiselect';
// export const CalendarMultiselectIconSvg = CalendarMultiselectIcon;

// import CalendarOutlineIcon from 'react-material-icon-svg/dist/CalendarOutline';
// export const CalendarOutlineIconSvg = CalendarOutlineIcon;

// import CalendarPlusIcon from 'react-material-icon-svg/dist/CalendarPlus';
// export const CalendarPlusIconSvg = CalendarPlusIcon;

// import CalendarQuestionIcon from 'react-material-icon-svg/dist/CalendarQuestion';
// export const CalendarQuestionIconSvg = CalendarQuestionIcon;

// import CalendarRangeIcon from 'react-material-icon-svg/dist/CalendarRange';
// export const CalendarRangeIconSvg = CalendarRangeIcon;

// import CalendarRangeOutlineIcon from 'react-material-icon-svg/dist/CalendarRangeOutline';
// export const CalendarRangeOutlineIconSvg = CalendarRangeOutlineIcon;

// import CalendarRemoveIcon from 'react-material-icon-svg/dist/CalendarRemove';
// export const CalendarRemoveIconSvg = CalendarRemoveIcon;

// import CalendarRemoveOutlineIcon from 'react-material-icon-svg/dist/CalendarRemoveOutline';
// export const CalendarRemoveOutlineIconSvg = CalendarRemoveOutlineIcon;

// import CalendarRepeatIcon from 'react-material-icon-svg/dist/CalendarRepeat';
// export const CalendarRepeatIconSvg = CalendarRepeatIcon;

// import CalendarRepeatOutlineIcon from 'react-material-icon-svg/dist/CalendarRepeatOutline';
// export const CalendarRepeatOutlineIconSvg = CalendarRepeatOutlineIcon;

// import CalendarSearchIcon from 'react-material-icon-svg/dist/CalendarSearch';
// export const CalendarSearchIconSvg = CalendarSearchIcon;

// import CalendarStarIcon from 'react-material-icon-svg/dist/CalendarStar';
// export const CalendarStarIconSvg = CalendarStarIcon;

// import CalendarTextIcon from 'react-material-icon-svg/dist/CalendarText';
// export const CalendarTextIconSvg = CalendarTextIcon;

// import CalendarTextOutlineIcon from 'react-material-icon-svg/dist/CalendarTextOutline';
// export const CalendarTextOutlineIconSvg = CalendarTextOutlineIcon;

import CalendarTodayIcon from 'react-material-icon-svg/dist/CalendarToday';
export const CalendarTodayIconSvg = CalendarTodayIcon;

// import CalendarWeekIcon from 'react-material-icon-svg/dist/CalendarWeek';
// export const CalendarWeekIconSvg = CalendarWeekIcon;

// import CalendarWeekBeginIcon from 'react-material-icon-svg/dist/CalendarWeekBegin';
// export const CalendarWeekBeginIconSvg = CalendarWeekBeginIcon;

// import CallMadeIcon from 'react-material-icon-svg/dist/CallMade';
// export const CallMadeIconSvg = CallMadeIcon;

// import CallMergeIcon from 'react-material-icon-svg/dist/CallMerge';
// export const CallMergeIconSvg = CallMergeIcon;

// import CallMissedIcon from 'react-material-icon-svg/dist/CallMissed';
// export const CallMissedIconSvg = CallMissedIcon;

// import CallReceivedIcon from 'react-material-icon-svg/dist/CallReceived';
// export const CallReceivedIconSvg = CallReceivedIcon;

// import CallSplitIcon from 'react-material-icon-svg/dist/CallSplit';
// export const CallSplitIconSvg = CallSplitIcon;

// import CamcorderIcon from 'react-material-icon-svg/dist/Camcorder';
// export const CamcorderIconSvg = CamcorderIcon;

// import CamcorderBoxIcon from 'react-material-icon-svg/dist/CamcorderBox';
// export const CamcorderBoxIconSvg = CamcorderBoxIcon;

// import CamcorderBoxOffIcon from 'react-material-icon-svg/dist/CamcorderBoxOff';
// export const CamcorderBoxOffIconSvg = CamcorderBoxOffIcon;

// import CamcorderOffIcon from 'react-material-icon-svg/dist/CamcorderOff';
// export const CamcorderOffIconSvg = CamcorderOffIcon;

// import CameraIcon from 'react-material-icon-svg/dist/Camera';
// export const CameraIconSvg = CameraIcon;

// import CameraAccountIcon from 'react-material-icon-svg/dist/CameraAccount';
// export const CameraAccountIconSvg = CameraAccountIcon;

// import CameraBurstIcon from 'react-material-icon-svg/dist/CameraBurst';
// export const CameraBurstIconSvg = CameraBurstIcon;

// import CameraControlIcon from 'react-material-icon-svg/dist/CameraControl';
// export const CameraControlIconSvg = CameraControlIcon;

// import CameraEnhanceIcon from 'react-material-icon-svg/dist/CameraEnhance';
// export const CameraEnhanceIconSvg = CameraEnhanceIcon;

// import CameraEnhanceOutlineIcon from 'react-material-icon-svg/dist/CameraEnhanceOutline';
// export const CameraEnhanceOutlineIconSvg = CameraEnhanceOutlineIcon;

// import CameraFrontIcon from 'react-material-icon-svg/dist/CameraFront';
// export const CameraFrontIconSvg = CameraFrontIcon;

// import CameraFrontVariantIcon from 'react-material-icon-svg/dist/CameraFrontVariant';
// export const CameraFrontVariantIconSvg = CameraFrontVariantIcon;

// import CameraGoproIcon from 'react-material-icon-svg/dist/CameraGopro';
// export const CameraGoproIconSvg = CameraGoproIcon;

// import CameraImageIcon from 'react-material-icon-svg/dist/CameraImage';
// export const CameraImageIconSvg = CameraImageIcon;

// import CameraIrisIcon from 'react-material-icon-svg/dist/CameraIris';
// export const CameraIrisIconSvg = CameraIrisIcon;

// import CameraMeteringCenterIcon from 'react-material-icon-svg/dist/CameraMeteringCenter';
// export const CameraMeteringCenterIconSvg = CameraMeteringCenterIcon;

// import CameraMeteringMatrixIcon from 'react-material-icon-svg/dist/CameraMeteringMatrix';
// export const CameraMeteringMatrixIconSvg = CameraMeteringMatrixIcon;

// import CameraMeteringPartialIcon from 'react-material-icon-svg/dist/CameraMeteringPartial';
// export const CameraMeteringPartialIconSvg = CameraMeteringPartialIcon;

// import CameraMeteringSpotIcon from 'react-material-icon-svg/dist/CameraMeteringSpot';
// export const CameraMeteringSpotIconSvg = CameraMeteringSpotIcon;

// import CameraOffIcon from 'react-material-icon-svg/dist/CameraOff';
// export const CameraOffIconSvg = CameraOffIcon;

// import CameraOutlineIcon from 'react-material-icon-svg/dist/CameraOutline';
// export const CameraOutlineIconSvg = CameraOutlineIcon;

// import CameraPartyModeIcon from 'react-material-icon-svg/dist/CameraPartyMode';
// export const CameraPartyModeIconSvg = CameraPartyModeIcon;

// import CameraRearIcon from 'react-material-icon-svg/dist/CameraRear';
// export const CameraRearIconSvg = CameraRearIcon;

// import CameraRearVariantIcon from 'react-material-icon-svg/dist/CameraRearVariant';
// export const CameraRearVariantIconSvg = CameraRearVariantIcon;

// import CameraRetakeIcon from 'react-material-icon-svg/dist/CameraRetake';
// export const CameraRetakeIconSvg = CameraRetakeIcon;

// import CameraRetakeOutlineIcon from 'react-material-icon-svg/dist/CameraRetakeOutline';
// export const CameraRetakeOutlineIconSvg = CameraRetakeOutlineIcon;

// import CameraSwitchIcon from 'react-material-icon-svg/dist/CameraSwitch';
// export const CameraSwitchIconSvg = CameraSwitchIcon;

// import CameraTimerIcon from 'react-material-icon-svg/dist/CameraTimer';
// export const CameraTimerIconSvg = CameraTimerIcon;

// import CameraWirelessIcon from 'react-material-icon-svg/dist/CameraWireless';
// export const CameraWirelessIconSvg = CameraWirelessIcon;

// import CameraWirelessOutlineIcon from 'react-material-icon-svg/dist/CameraWirelessOutline';
// export const CameraWirelessOutlineIconSvg = CameraWirelessOutlineIcon;

// import CancelIcon from 'react-material-icon-svg/dist/Cancel';
// export const CancelIconSvg = CancelIcon;

// import CandleIcon from 'react-material-icon-svg/dist/Candle';
// export const CandleIconSvg = CandleIcon;

// import CandycaneIcon from 'react-material-icon-svg/dist/Candycane';
// export const CandycaneIconSvg = CandycaneIcon;

// import CannabisIcon from 'react-material-icon-svg/dist/Cannabis';
// export const CannabisIconSvg = CannabisIcon;

// import CapsLockIcon from 'react-material-icon-svg/dist/CapsLock';
// export const CapsLockIconSvg = CapsLockIcon;

// import CarIcon from 'react-material-icon-svg/dist/Car';
// export const CarIconSvg = CarIcon;

// import CarBackIcon from 'react-material-icon-svg/dist/CarBack';
// export const CarBackIconSvg = CarBackIcon;

// import CarBatteryIcon from 'react-material-icon-svg/dist/CarBattery';
// export const CarBatteryIconSvg = CarBatteryIcon;

// import CarBrakeAbsIcon from 'react-material-icon-svg/dist/CarBrakeAbs';
// export const CarBrakeAbsIconSvg = CarBrakeAbsIcon;

// import CarBrakeAlertIcon from 'react-material-icon-svg/dist/CarBrakeAlert';
// export const CarBrakeAlertIconSvg = CarBrakeAlertIcon;

// import CarBrakeHoldIcon from 'react-material-icon-svg/dist/CarBrakeHold';
// export const CarBrakeHoldIconSvg = CarBrakeHoldIcon;

// import CarBrakeParkingIcon from 'react-material-icon-svg/dist/CarBrakeParking';
// export const CarBrakeParkingIconSvg = CarBrakeParkingIcon;

// import CarConnectedIcon from 'react-material-icon-svg/dist/CarConnected';
// export const CarConnectedIconSvg = CarConnectedIcon;

// import CarConvertibleIcon from 'react-material-icon-svg/dist/CarConvertible';
// export const CarConvertibleIconSvg = CarConvertibleIcon;

// import CarCruiseControlIcon from 'react-material-icon-svg/dist/CarCruiseControl';
// export const CarCruiseControlIconSvg = CarCruiseControlIcon;

// import CarDefrostFrontIcon from 'react-material-icon-svg/dist/CarDefrostFront';
// export const CarDefrostFrontIconSvg = CarDefrostFrontIcon;

// import CarDefrostRearIcon from 'react-material-icon-svg/dist/CarDefrostRear';
// export const CarDefrostRearIconSvg = CarDefrostRearIcon;

// import CarDoorIcon from 'react-material-icon-svg/dist/CarDoor';
// export const CarDoorIconSvg = CarDoorIcon;

// import CarElectricIcon from 'react-material-icon-svg/dist/CarElectric';
// export const CarElectricIconSvg = CarElectricIcon;

// import CarEspIcon from 'react-material-icon-svg/dist/CarEsp';
// export const CarEspIconSvg = CarEspIcon;

// import CarEstateIcon from 'react-material-icon-svg/dist/CarEstate';
// export const CarEstateIconSvg = CarEstateIcon;

// import CarHatchbackIcon from 'react-material-icon-svg/dist/CarHatchback';
// export const CarHatchbackIconSvg = CarHatchbackIcon;

// import CarKeyIcon from 'react-material-icon-svg/dist/CarKey';
// export const CarKeyIconSvg = CarKeyIcon;

// import CarLightDimmedIcon from 'react-material-icon-svg/dist/CarLightDimmed';
// export const CarLightDimmedIconSvg = CarLightDimmedIcon;

// import CarLightFogIcon from 'react-material-icon-svg/dist/CarLightFog';
// export const CarLightFogIconSvg = CarLightFogIcon;

// import CarLightHighIcon from 'react-material-icon-svg/dist/CarLightHigh';
// export const CarLightHighIconSvg = CarLightHighIcon;

// import CarLimousineIcon from 'react-material-icon-svg/dist/CarLimousine';
// export const CarLimousineIconSvg = CarLimousineIcon;

// import CarMultipleIcon from 'react-material-icon-svg/dist/CarMultiple';
// export const CarMultipleIconSvg = CarMultipleIcon;

// import CarOffIcon from 'react-material-icon-svg/dist/CarOff';
// export const CarOffIconSvg = CarOffIcon;

// import CarParkingLightsIcon from 'react-material-icon-svg/dist/CarParkingLights';
// export const CarParkingLightsIconSvg = CarParkingLightsIcon;

// import CarPickupIcon from 'react-material-icon-svg/dist/CarPickup';
// export const CarPickupIconSvg = CarPickupIcon;

// import CarSideIcon from 'react-material-icon-svg/dist/CarSide';
// export const CarSideIconSvg = CarSideIcon;

// import CarSportsIcon from 'react-material-icon-svg/dist/CarSports';
// export const CarSportsIconSvg = CarSportsIcon;

// import CarTireAlertIcon from 'react-material-icon-svg/dist/CarTireAlert';
// export const CarTireAlertIconSvg = CarTireAlertIcon;

// import CarTractionControlIcon from 'react-material-icon-svg/dist/CarTractionControl';
// export const CarTractionControlIconSvg = CarTractionControlIcon;

// import CarWashIcon from 'react-material-icon-svg/dist/CarWash';
// export const CarWashIconSvg = CarWashIcon;

// import CaravanIcon from 'react-material-icon-svg/dist/Caravan';
// export const CaravanIconSvg = CaravanIcon;

// import CardIcon from 'react-material-icon-svg/dist/Card';
// export const CardIconSvg = CardIcon;

// import CardBulletedIcon from 'react-material-icon-svg/dist/CardBulleted';
// export const CardBulletedIconSvg = CardBulletedIcon;

// import CardBulletedOffIcon from 'react-material-icon-svg/dist/CardBulletedOff';
// export const CardBulletedOffIconSvg = CardBulletedOffIcon;

// import CardBulletedOffOutlineIcon from 'react-material-icon-svg/dist/CardBulletedOffOutline';
// export const CardBulletedOffOutlineIconSvg = CardBulletedOffOutlineIcon;

// import CardBulletedOutlineIcon from 'react-material-icon-svg/dist/CardBulletedOutline';
// export const CardBulletedOutlineIconSvg = CardBulletedOutlineIcon;

// import CardBulletedSettingsIcon from 'react-material-icon-svg/dist/CardBulletedSettings';
// export const CardBulletedSettingsIconSvg = CardBulletedSettingsIcon;

// import CardBulletedSettingsOutlineIcon from 'react-material-icon-svg/dist/CardBulletedSettingsOutline';
// export const CardBulletedSettingsOutlineIconSvg = CardBulletedSettingsOutlineIcon;

// import CardOutlineIcon from 'react-material-icon-svg/dist/CardOutline';
// export const CardOutlineIconSvg = CardOutlineIcon;

// import CardTextIcon from 'react-material-icon-svg/dist/CardText';
// export const CardTextIconSvg = CardTextIcon;

// import CardTextOutlineIcon from 'react-material-icon-svg/dist/CardTextOutline';
// export const CardTextOutlineIconSvg = CardTextOutlineIcon;

// import CardsIcon from 'react-material-icon-svg/dist/Cards';
// export const CardsIconSvg = CardsIcon;

// import CardsClubIcon from 'react-material-icon-svg/dist/CardsClub';
// export const CardsClubIconSvg = CardsClubIcon;

// import CardsDiamondIcon from 'react-material-icon-svg/dist/CardsDiamond';
// export const CardsDiamondIconSvg = CardsDiamondIcon;

// import CardsHeartIcon from 'react-material-icon-svg/dist/CardsHeart';
// export const CardsHeartIconSvg = CardsHeartIcon;

// import CardsOutlineIcon from 'react-material-icon-svg/dist/CardsOutline';
// export const CardsOutlineIconSvg = CardsOutlineIcon;

// import CardsPlayingOutlineIcon from 'react-material-icon-svg/dist/CardsPlayingOutline';
// export const CardsPlayingOutlineIconSvg = CardsPlayingOutlineIcon;

// import CardsSpadeIcon from 'react-material-icon-svg/dist/CardsSpade';
// export const CardsSpadeIconSvg = CardsSpadeIcon;

// import CardsVariantIcon from 'react-material-icon-svg/dist/CardsVariant';
// export const CardsVariantIconSvg = CardsVariantIcon;

// import CarrotIcon from 'react-material-icon-svg/dist/Carrot';
// export const CarrotIconSvg = CarrotIcon;

// import CarryOnBagCheckIcon from 'react-material-icon-svg/dist/CarryOnBagCheck';
// export const CarryOnBagCheckIconSvg = CarryOnBagCheckIcon;

// import CartIcon from 'react-material-icon-svg/dist/Cart';
// export const CartIconSvg = CartIcon;

// import CartArrowDownIcon from 'react-material-icon-svg/dist/CartArrowDown';
// export const CartArrowDownIconSvg = CartArrowDownIcon;

// import CartArrowRightIcon from 'react-material-icon-svg/dist/CartArrowRight';
// export const CartArrowRightIconSvg = CartArrowRightIcon;

// import CartArrowUpIcon from 'react-material-icon-svg/dist/CartArrowUp';
// export const CartArrowUpIconSvg = CartArrowUpIcon;

// import CartMinusIcon from 'react-material-icon-svg/dist/CartMinus';
// export const CartMinusIconSvg = CartMinusIcon;

// import CartOffIcon from 'react-material-icon-svg/dist/CartOff';
// export const CartOffIconSvg = CartOffIcon;

// import CartOutlineIcon from 'react-material-icon-svg/dist/CartOutline';
// export const CartOutlineIconSvg = CartOutlineIcon;

// import CartPlusIcon from 'react-material-icon-svg/dist/CartPlus';
// export const CartPlusIconSvg = CartPlusIcon;

// import CartRemoveIcon from 'react-material-icon-svg/dist/CartRemove';
// export const CartRemoveIconSvg = CartRemoveIcon;

// import CaseSensitiveAltIcon from 'react-material-icon-svg/dist/CaseSensitiveAlt';
// export const CaseSensitiveAltIconSvg = CaseSensitiveAltIcon;

// import CashIcon from 'react-material-icon-svg/dist/Cash';
// export const CashIconSvg = CashIcon;

// import CashMarkerIcon from 'react-material-icon-svg/dist/CashMarker';
// export const CashMarkerIconSvg = CashMarkerIcon;

import CashMultipleIcon from 'react-material-icon-svg/dist/CashMultiple';
export const CashMultipleIconSvg = CashMultipleIcon;

// import CashRefundIcon from 'react-material-icon-svg/dist/CashRefund';
// export const CashRefundIconSvg = CashRefundIcon;

// import CashRegisterIcon from 'react-material-icon-svg/dist/CashRegister';
// export const CashRegisterIconSvg = CashRegisterIcon;

// import CashUsdIcon from 'react-material-icon-svg/dist/CashUsd';
// export const CashUsdIconSvg = CashUsdIcon;

// import Cash_100Icon from 'react-material-icon-svg/dist/Cash_100';
// export const Cash_100IconSvg = Cash_100Icon;

// import CassetteIcon from 'react-material-icon-svg/dist/Cassette';
// export const CassetteIconSvg = CassetteIcon;

// import CastIcon from 'react-material-icon-svg/dist/Cast';
// export const CastIconSvg = CastIcon;

// import CastConnectedIcon from 'react-material-icon-svg/dist/CastConnected';
// export const CastConnectedIconSvg = CastConnectedIcon;

// import CastEducationIcon from 'react-material-icon-svg/dist/CastEducation';
// export const CastEducationIconSvg = CastEducationIcon;

// import CastOffIcon from 'react-material-icon-svg/dist/CastOff';
// export const CastOffIconSvg = CastOffIcon;

// import CastleIcon from 'react-material-icon-svg/dist/Castle';
// export const CastleIconSvg = CastleIcon;

// import CatIcon from 'react-material-icon-svg/dist/Cat';
// export const CatIconSvg = CatIcon;

// import CctvIcon from 'react-material-icon-svg/dist/Cctv';
// export const CctvIconSvg = CctvIcon;

// import CeilingLightIcon from 'react-material-icon-svg/dist/CeilingLight';
// export const CeilingLightIconSvg = CeilingLightIcon;

// import CellphoneIcon from 'react-material-icon-svg/dist/Cellphone';
// export const CellphoneIconSvg = CellphoneIcon;

// import CellphoneAndroidIcon from 'react-material-icon-svg/dist/CellphoneAndroid';
// export const CellphoneAndroidIconSvg = CellphoneAndroidIcon;

// import CellphoneArrowDownIcon from 'react-material-icon-svg/dist/CellphoneArrowDown';
// export const CellphoneArrowDownIconSvg = CellphoneArrowDownIcon;

// import CellphoneBasicIcon from 'react-material-icon-svg/dist/CellphoneBasic';
// export const CellphoneBasicIconSvg = CellphoneBasicIcon;

// import CellphoneDockIcon from 'react-material-icon-svg/dist/CellphoneDock';
// export const CellphoneDockIconSvg = CellphoneDockIcon;

// import CellphoneEraseIcon from 'react-material-icon-svg/dist/CellphoneErase';
// export const CellphoneEraseIconSvg = CellphoneEraseIcon;

// import CellphoneIphoneIcon from 'react-material-icon-svg/dist/CellphoneIphone';
// export const CellphoneIphoneIconSvg = CellphoneIphoneIcon;

// import CellphoneKeyIcon from 'react-material-icon-svg/dist/CellphoneKey';
// export const CellphoneKeyIconSvg = CellphoneKeyIcon;

// import CellphoneLinkIcon from 'react-material-icon-svg/dist/CellphoneLink';
// export const CellphoneLinkIconSvg = CellphoneLinkIcon;

// import CellphoneLinkOffIcon from 'react-material-icon-svg/dist/CellphoneLinkOff';
// export const CellphoneLinkOffIconSvg = CellphoneLinkOffIcon;

// import CellphoneLockIcon from 'react-material-icon-svg/dist/CellphoneLock';
// export const CellphoneLockIconSvg = CellphoneLockIcon;

// import CellphoneMessageIcon from 'react-material-icon-svg/dist/CellphoneMessage';
// export const CellphoneMessageIconSvg = CellphoneMessageIcon;

// import CellphoneNfcIcon from 'react-material-icon-svg/dist/CellphoneNfc';
// export const CellphoneNfcIconSvg = CellphoneNfcIcon;

// import CellphoneOffIcon from 'react-material-icon-svg/dist/CellphoneOff';
// export const CellphoneOffIconSvg = CellphoneOffIcon;

// import CellphoneScreenshotIcon from 'react-material-icon-svg/dist/CellphoneScreenshot';
// export const CellphoneScreenshotIconSvg = CellphoneScreenshotIcon;

// import CellphoneSettingsIcon from 'react-material-icon-svg/dist/CellphoneSettings';
// export const CellphoneSettingsIconSvg = CellphoneSettingsIcon;

// import CellphoneSettingsVariantIcon from 'react-material-icon-svg/dist/CellphoneSettingsVariant';
// export const CellphoneSettingsVariantIconSvg = CellphoneSettingsVariantIcon;

// import CellphoneSoundIcon from 'react-material-icon-svg/dist/CellphoneSound';
// export const CellphoneSoundIconSvg = CellphoneSoundIcon;

// import CellphoneTextIcon from 'react-material-icon-svg/dist/CellphoneText';
// export const CellphoneTextIconSvg = CellphoneTextIcon;

// import CellphoneWirelessIcon from 'react-material-icon-svg/dist/CellphoneWireless';
// export const CellphoneWirelessIconSvg = CellphoneWirelessIcon;

// import CelticCrossIcon from 'react-material-icon-svg/dist/CelticCross';
// export const CelticCrossIconSvg = CelticCrossIcon;

// import CertificateIcon from 'react-material-icon-svg/dist/Certificate';
// export const CertificateIconSvg = CertificateIcon;

// import ChairSchoolIcon from 'react-material-icon-svg/dist/ChairSchool';
// export const ChairSchoolIconSvg = ChairSchoolIcon;

// import CharityIcon from 'react-material-icon-svg/dist/Charity';
// export const CharityIconSvg = CharityIcon;

// import ChartArcIcon from 'react-material-icon-svg/dist/ChartArc';
// export const ChartArcIconSvg = ChartArcIcon;

// import ChartAreasplineIcon from 'react-material-icon-svg/dist/ChartAreaspline';
// export const ChartAreasplineIconSvg = ChartAreasplineIcon;

// import ChartAreasplineVariantIcon from 'react-material-icon-svg/dist/ChartAreasplineVariant';
// export const ChartAreasplineVariantIconSvg = ChartAreasplineVariantIcon;

// import ChartBarIcon from 'react-material-icon-svg/dist/ChartBar';
// export const ChartBarIconSvg = ChartBarIcon;

// import ChartBarStackedIcon from 'react-material-icon-svg/dist/ChartBarStacked';
// export const ChartBarStackedIconSvg = ChartBarStackedIcon;

// import ChartBellCurveIcon from 'react-material-icon-svg/dist/ChartBellCurve';
// export const ChartBellCurveIconSvg = ChartBellCurveIcon;

// import ChartBubbleIcon from 'react-material-icon-svg/dist/ChartBubble';
// export const ChartBubbleIconSvg = ChartBubbleIcon;

// import ChartDonutIcon from 'react-material-icon-svg/dist/ChartDonut';
// export const ChartDonutIconSvg = ChartDonutIcon;

// import ChartDonutVariantIcon from 'react-material-icon-svg/dist/ChartDonutVariant';
// export const ChartDonutVariantIconSvg = ChartDonutVariantIcon;

// import ChartGanttIcon from 'react-material-icon-svg/dist/ChartGantt';
// export const ChartGanttIconSvg = ChartGanttIcon;

// import ChartHistogramIcon from 'react-material-icon-svg/dist/ChartHistogram';
// export const ChartHistogramIconSvg = ChartHistogramIcon;

// import ChartLineIcon from 'react-material-icon-svg/dist/ChartLine';
// export const ChartLineIconSvg = ChartLineIcon;

// import ChartLineStackedIcon from 'react-material-icon-svg/dist/ChartLineStacked';
// export const ChartLineStackedIconSvg = ChartLineStackedIcon;

// import ChartLineVariantIcon from 'react-material-icon-svg/dist/ChartLineVariant';
// export const ChartLineVariantIconSvg = ChartLineVariantIcon;

// import ChartMultilineIcon from 'react-material-icon-svg/dist/ChartMultiline';
// export const ChartMultilineIconSvg = ChartMultilineIcon;

// import ChartPieIcon from 'react-material-icon-svg/dist/ChartPie';
// export const ChartPieIconSvg = ChartPieIcon;

// import ChartScatterPlotIcon from 'react-material-icon-svg/dist/ChartScatterPlot';
// export const ChartScatterPlotIconSvg = ChartScatterPlotIcon;

// import ChartScatterPlotHexbinIcon from 'react-material-icon-svg/dist/ChartScatterPlotHexbin';
// export const ChartScatterPlotHexbinIconSvg = ChartScatterPlotHexbinIcon;

// import ChartTimelineIcon from 'react-material-icon-svg/dist/ChartTimeline';
// export const ChartTimelineIconSvg = ChartTimelineIcon;

// import ChartTimelineVariantIcon from 'react-material-icon-svg/dist/ChartTimelineVariant';
// export const ChartTimelineVariantIconSvg = ChartTimelineVariantIcon;

// import ChartTreeIcon from 'react-material-icon-svg/dist/ChartTree';
// export const ChartTreeIconSvg = ChartTreeIcon;

// import ChatIcon from 'react-material-icon-svg/dist/Chat';
// export const ChatIconSvg = ChatIcon;

// import ChatAlertIcon from 'react-material-icon-svg/dist/ChatAlert';
// export const ChatAlertIconSvg = ChatAlertIcon;

// import ChatProcessingIcon from 'react-material-icon-svg/dist/ChatProcessing';
// export const ChatProcessingIconSvg = ChatProcessingIcon;

// import CheckIcon from 'react-material-icon-svg/dist/Check';
// export const CheckIconSvg = CheckIcon;

// import CheckAllIcon from 'react-material-icon-svg/dist/CheckAll';
// export const CheckAllIconSvg = CheckAllIcon;

// import CheckBoldIcon from 'react-material-icon-svg/dist/CheckBold';
// export const CheckBoldIconSvg = CheckBoldIcon;

// import CheckBoxMultipleOutlineIcon from 'react-material-icon-svg/dist/CheckBoxMultipleOutline';
// export const CheckBoxMultipleOutlineIconSvg = CheckBoxMultipleOutlineIcon;

// import CheckBoxOutlineIcon from 'react-material-icon-svg/dist/CheckBoxOutline';
// export const CheckBoxOutlineIconSvg = CheckBoxOutlineIcon;

// import CheckCircleIcon from 'react-material-icon-svg/dist/CheckCircle';
// export const CheckCircleIconSvg = CheckCircleIcon;

// import CheckCircleOutlineIcon from 'react-material-icon-svg/dist/CheckCircleOutline';
// export const CheckCircleOutlineIconSvg = CheckCircleOutlineIcon;

import CheckDecagramIcon from 'react-material-icon-svg/dist/CheckDecagram';
export const CheckDecagramIconSvg = CheckDecagramIcon;

// import CheckNetworkIcon from 'react-material-icon-svg/dist/CheckNetwork';
// export const CheckNetworkIconSvg = CheckNetworkIcon;

// import CheckNetworkOutlineIcon from 'react-material-icon-svg/dist/CheckNetworkOutline';
// export const CheckNetworkOutlineIconSvg = CheckNetworkOutlineIcon;

// import CheckOutlineIcon from 'react-material-icon-svg/dist/CheckOutline';
// export const CheckOutlineIconSvg = CheckOutlineIcon;

// import CheckUnderlineIcon from 'react-material-icon-svg/dist/CheckUnderline';
// export const CheckUnderlineIconSvg = CheckUnderlineIcon;

// import CheckUnderlineCircleIcon from 'react-material-icon-svg/dist/CheckUnderlineCircle';
// export const CheckUnderlineCircleIconSvg = CheckUnderlineCircleIcon;

// import CheckUnderlineCircleOutlineIcon from 'react-material-icon-svg/dist/CheckUnderlineCircleOutline';
// export const CheckUnderlineCircleOutlineIconSvg = CheckUnderlineCircleOutlineIcon;

// import CheckbookIcon from 'react-material-icon-svg/dist/Checkbook';
// export const CheckbookIconSvg = CheckbookIcon;

// import CheckboxBlankIcon from 'react-material-icon-svg/dist/CheckboxBlank';
// export const CheckboxBlankIconSvg = CheckboxBlankIcon;

// import CheckboxBlankCircleIcon from 'react-material-icon-svg/dist/CheckboxBlankCircle';
// export const CheckboxBlankCircleIconSvg = CheckboxBlankCircleIcon;

// import CheckboxBlankCircleOutlineIcon from 'react-material-icon-svg/dist/CheckboxBlankCircleOutline';
// export const CheckboxBlankCircleOutlineIconSvg = CheckboxBlankCircleOutlineIcon;

// import CheckboxBlankOutlineIcon from 'react-material-icon-svg/dist/CheckboxBlankOutline';
// export const CheckboxBlankOutlineIconSvg = CheckboxBlankOutlineIcon;

// import CheckboxIntermediateIcon from 'react-material-icon-svg/dist/CheckboxIntermediate';
// export const CheckboxIntermediateIconSvg = CheckboxIntermediateIcon;

// import CheckboxMarkedIcon from 'react-material-icon-svg/dist/CheckboxMarked';
// export const CheckboxMarkedIconSvg = CheckboxMarkedIcon;

// import CheckboxMarkedCircleIcon from 'react-material-icon-svg/dist/CheckboxMarkedCircle';
// export const CheckboxMarkedCircleIconSvg = CheckboxMarkedCircleIcon;

// import CheckboxMarkedCircleOutlineIcon from 'react-material-icon-svg/dist/CheckboxMarkedCircleOutline';
// export const CheckboxMarkedCircleOutlineIconSvg = CheckboxMarkedCircleOutlineIcon;

// import CheckboxMarkedOutlineIcon from 'react-material-icon-svg/dist/CheckboxMarkedOutline';
// export const CheckboxMarkedOutlineIconSvg = CheckboxMarkedOutlineIcon;

// import CheckboxMultipleBlankIcon from 'react-material-icon-svg/dist/CheckboxMultipleBlank';
// export const CheckboxMultipleBlankIconSvg = CheckboxMultipleBlankIcon;

// import CheckboxMultipleBlankCircleIcon from 'react-material-icon-svg/dist/CheckboxMultipleBlankCircle';
// export const CheckboxMultipleBlankCircleIconSvg = CheckboxMultipleBlankCircleIcon;

// import CheckboxMultipleBlankCircleOutlineIcon from 'react-material-icon-svg/dist/CheckboxMultipleBlankCircleOutline';
// export const CheckboxMultipleBlankCircleOutlineIconSvg = CheckboxMultipleBlankCircleOutlineIcon;

// import CheckboxMultipleBlankOutlineIcon from 'react-material-icon-svg/dist/CheckboxMultipleBlankOutline';
// export const CheckboxMultipleBlankOutlineIconSvg = CheckboxMultipleBlankOutlineIcon;

// import CheckboxMultipleMarkedIcon from 'react-material-icon-svg/dist/CheckboxMultipleMarked';
// export const CheckboxMultipleMarkedIconSvg = CheckboxMultipleMarkedIcon;

// import CheckboxMultipleMarkedCircleIcon from 'react-material-icon-svg/dist/CheckboxMultipleMarkedCircle';
// export const CheckboxMultipleMarkedCircleIconSvg = CheckboxMultipleMarkedCircleIcon;

// import CheckboxMultipleMarkedCircleOutlineIcon from 'react-material-icon-svg/dist/CheckboxMultipleMarkedCircleOutline';
// export const CheckboxMultipleMarkedCircleOutlineIconSvg = CheckboxMultipleMarkedCircleOutlineIcon;

// import CheckboxMultipleMarkedOutlineIcon from 'react-material-icon-svg/dist/CheckboxMultipleMarkedOutline';
// export const CheckboxMultipleMarkedOutlineIconSvg = CheckboxMultipleMarkedOutlineIcon;

// import CheckerboardIcon from 'react-material-icon-svg/dist/Checkerboard';
// export const CheckerboardIconSvg = CheckerboardIcon;

// import ChefHatIcon from 'react-material-icon-svg/dist/ChefHat';
// export const ChefHatIconSvg = ChefHatIcon;

// import ChemicalWeaponIcon from 'react-material-icon-svg/dist/ChemicalWeapon';
// export const ChemicalWeaponIconSvg = ChemicalWeaponIcon;

// import ChessBishopIcon from 'react-material-icon-svg/dist/ChessBishop';
// export const ChessBishopIconSvg = ChessBishopIcon;

// import ChessKingIcon from 'react-material-icon-svg/dist/ChessKing';
// export const ChessKingIconSvg = ChessKingIcon;

// import ChessKnightIcon from 'react-material-icon-svg/dist/ChessKnight';
// export const ChessKnightIconSvg = ChessKnightIcon;

// import ChessPawnIcon from 'react-material-icon-svg/dist/ChessPawn';
// export const ChessPawnIconSvg = ChessPawnIcon;

// import ChessQueenIcon from 'react-material-icon-svg/dist/ChessQueen';
// export const ChessQueenIconSvg = ChessQueenIcon;

// import ChessRookIcon from 'react-material-icon-svg/dist/ChessRook';
// export const ChessRookIconSvg = ChessRookIcon;

// import ChevronDoubleDownIcon from 'react-material-icon-svg/dist/ChevronDoubleDown';
// export const ChevronDoubleDownIconSvg = ChevronDoubleDownIcon;

// import ChevronDoubleLeftIcon from 'react-material-icon-svg/dist/ChevronDoubleLeft';
// export const ChevronDoubleLeftIconSvg = ChevronDoubleLeftIcon;

// import ChevronDoubleRightIcon from 'react-material-icon-svg/dist/ChevronDoubleRight';
// export const ChevronDoubleRightIconSvg = ChevronDoubleRightIcon;

// import ChevronDoubleUpIcon from 'react-material-icon-svg/dist/ChevronDoubleUp';
// export const ChevronDoubleUpIconSvg = ChevronDoubleUpIcon;

import ChevronDownIcon from 'react-material-icon-svg/dist/ChevronDown';
export const ChevronDownIconSvg = ChevronDownIcon;

// import ChevronDownBoxIcon from 'react-material-icon-svg/dist/ChevronDownBox';
// export const ChevronDownBoxIconSvg = ChevronDownBoxIcon;

// import ChevronDownBoxOutlineIcon from 'react-material-icon-svg/dist/ChevronDownBoxOutline';
// export const ChevronDownBoxOutlineIconSvg = ChevronDownBoxOutlineIcon;

// import ChevronDownCircleIcon from 'react-material-icon-svg/dist/ChevronDownCircle';
// export const ChevronDownCircleIconSvg = ChevronDownCircleIcon;

// import ChevronDownCircleOutlineIcon from 'react-material-icon-svg/dist/ChevronDownCircleOutline';
// export const ChevronDownCircleOutlineIconSvg = ChevronDownCircleOutlineIcon;

import ChevronLeftIcon from 'react-material-icon-svg/dist/ChevronLeft';
export const ChevronLeftIconSvg = ChevronLeftIcon;

// import ChevronLeftBoxIcon from 'react-material-icon-svg/dist/ChevronLeftBox';
// export const ChevronLeftBoxIconSvg = ChevronLeftBoxIcon;

// import ChevronLeftBoxOutlineIcon from 'react-material-icon-svg/dist/ChevronLeftBoxOutline';
// export const ChevronLeftBoxOutlineIconSvg = ChevronLeftBoxOutlineIcon;

// import ChevronLeftCircleIcon from 'react-material-icon-svg/dist/ChevronLeftCircle';
// export const ChevronLeftCircleIconSvg = ChevronLeftCircleIcon;

// import ChevronLeftCircleOutlineIcon from 'react-material-icon-svg/dist/ChevronLeftCircleOutline';
// export const ChevronLeftCircleOutlineIconSvg = ChevronLeftCircleOutlineIcon;

import ChevronRightIcon from 'react-material-icon-svg/dist/ChevronRight';
export const ChevronRightIconSvg = ChevronRightIcon;

// import ChevronRightBoxIcon from 'react-material-icon-svg/dist/ChevronRightBox';
// export const ChevronRightBoxIconSvg = ChevronRightBoxIcon;

// import ChevronRightBoxOutlineIcon from 'react-material-icon-svg/dist/ChevronRightBoxOutline';
// export const ChevronRightBoxOutlineIconSvg = ChevronRightBoxOutlineIcon;

// import ChevronRightCircleIcon from 'react-material-icon-svg/dist/ChevronRightCircle';
// export const ChevronRightCircleIconSvg = ChevronRightCircleIcon;

// import ChevronRightCircleOutlineIcon from 'react-material-icon-svg/dist/ChevronRightCircleOutline';
// export const ChevronRightCircleOutlineIconSvg = ChevronRightCircleOutlineIcon;

// import ChevronTripleDownIcon from 'react-material-icon-svg/dist/ChevronTripleDown';
// export const ChevronTripleDownIconSvg = ChevronTripleDownIcon;

// import ChevronTripleLeftIcon from 'react-material-icon-svg/dist/ChevronTripleLeft';
// export const ChevronTripleLeftIconSvg = ChevronTripleLeftIcon;

// import ChevronTripleRightIcon from 'react-material-icon-svg/dist/ChevronTripleRight';
// export const ChevronTripleRightIconSvg = ChevronTripleRightIcon;

// import ChevronTripleUpIcon from 'react-material-icon-svg/dist/ChevronTripleUp';
// export const ChevronTripleUpIconSvg = ChevronTripleUpIcon;

import ChevronUpIcon from 'react-material-icon-svg/dist/ChevronUp';
export const ChevronUpIconSvg = ChevronUpIcon;

// import ChevronUpBoxIcon from 'react-material-icon-svg/dist/ChevronUpBox';
// export const ChevronUpBoxIconSvg = ChevronUpBoxIcon;

// import ChevronUpBoxOutlineIcon from 'react-material-icon-svg/dist/ChevronUpBoxOutline';
// export const ChevronUpBoxOutlineIconSvg = ChevronUpBoxOutlineIcon;

// import ChevronUpCircleIcon from 'react-material-icon-svg/dist/ChevronUpCircle';
// export const ChevronUpCircleIconSvg = ChevronUpCircleIcon;

// import ChevronUpCircleOutlineIcon from 'react-material-icon-svg/dist/ChevronUpCircleOutline';
// export const ChevronUpCircleOutlineIconSvg = ChevronUpCircleOutlineIcon;

// import ChiliHotIcon from 'react-material-icon-svg/dist/ChiliHot';
// export const ChiliHotIconSvg = ChiliHotIcon;

// import ChiliMediumIcon from 'react-material-icon-svg/dist/ChiliMedium';
// export const ChiliMediumIconSvg = ChiliMediumIcon;

// import ChiliMildIcon from 'react-material-icon-svg/dist/ChiliMild';
// export const ChiliMildIconSvg = ChiliMildIcon;

// import ChipIcon from 'react-material-icon-svg/dist/Chip';
// export const ChipIconSvg = ChipIcon;

// import ChristianityIcon from 'react-material-icon-svg/dist/Christianity';
// export const ChristianityIconSvg = ChristianityIcon;

// import ChristianityOutlineIcon from 'react-material-icon-svg/dist/ChristianityOutline';
// export const ChristianityOutlineIconSvg = ChristianityOutlineIcon;

// import ChurchIcon from 'react-material-icon-svg/dist/Church';
// export const ChurchIconSvg = ChurchIcon;

// import CircleIcon from 'react-material-icon-svg/dist/Circle';
// export const CircleIconSvg = CircleIcon;

// import CircleDoubleIcon from 'react-material-icon-svg/dist/CircleDouble';
// export const CircleDoubleIconSvg = CircleDoubleIcon;

// import CircleEditOutlineIcon from 'react-material-icon-svg/dist/CircleEditOutline';
// export const CircleEditOutlineIconSvg = CircleEditOutlineIcon;

// import CircleExpandIcon from 'react-material-icon-svg/dist/CircleExpand';
// export const CircleExpandIconSvg = CircleExpandIcon;

// import CircleMediumIcon from 'react-material-icon-svg/dist/CircleMedium';
// export const CircleMediumIconSvg = CircleMediumIcon;

// import CircleOutlineIcon from 'react-material-icon-svg/dist/CircleOutline';
// export const CircleOutlineIconSvg = CircleOutlineIcon;

// import CircleSlice_1Icon from 'react-material-icon-svg/dist/CircleSlice_1';
// export const CircleSlice_1IconSvg = CircleSlice_1Icon;

// import CircleSlice_2Icon from 'react-material-icon-svg/dist/CircleSlice_2';
// export const CircleSlice_2IconSvg = CircleSlice_2Icon;

// import CircleSlice_3Icon from 'react-material-icon-svg/dist/CircleSlice_3';
// export const CircleSlice_3IconSvg = CircleSlice_3Icon;

// import CircleSlice_4Icon from 'react-material-icon-svg/dist/CircleSlice_4';
// export const CircleSlice_4IconSvg = CircleSlice_4Icon;

// import CircleSlice_5Icon from 'react-material-icon-svg/dist/CircleSlice_5';
// export const CircleSlice_5IconSvg = CircleSlice_5Icon;

// import CircleSlice_6Icon from 'react-material-icon-svg/dist/CircleSlice_6';
// export const CircleSlice_6IconSvg = CircleSlice_6Icon;

// import CircleSlice_7Icon from 'react-material-icon-svg/dist/CircleSlice_7';
// export const CircleSlice_7IconSvg = CircleSlice_7Icon;

// import CircleSlice_8Icon from 'react-material-icon-svg/dist/CircleSlice_8';
// export const CircleSlice_8IconSvg = CircleSlice_8Icon;

// import CircleSmallIcon from 'react-material-icon-svg/dist/CircleSmall';
// export const CircleSmallIconSvg = CircleSmallIcon;

// import CircularSawIcon from 'react-material-icon-svg/dist/CircularSaw';
// export const CircularSawIconSvg = CircularSawIcon;

// import CiscoWebexIcon from 'react-material-icon-svg/dist/CiscoWebex';
// export const CiscoWebexIconSvg = CiscoWebexIcon;

// import CityIcon from 'react-material-icon-svg/dist/City';
// export const CityIconSvg = CityIcon;

// import CityVariantIcon from 'react-material-icon-svg/dist/CityVariant';
// export const CityVariantIconSvg = CityVariantIcon;

// import CityVariantOutlineIcon from 'react-material-icon-svg/dist/CityVariantOutline';
// export const CityVariantOutlineIconSvg = CityVariantOutlineIcon;

// import ClipboardIcon from 'react-material-icon-svg/dist/Clipboard';
// export const ClipboardIconSvg = ClipboardIcon;

// import ClipboardAccountIcon from 'react-material-icon-svg/dist/ClipboardAccount';
// export const ClipboardAccountIconSvg = ClipboardAccountIcon;

// import ClipboardAccountOutlineIcon from 'react-material-icon-svg/dist/ClipboardAccountOutline';
// export const ClipboardAccountOutlineIconSvg = ClipboardAccountOutlineIcon;

// import ClipboardAlertIcon from 'react-material-icon-svg/dist/ClipboardAlert';
// export const ClipboardAlertIconSvg = ClipboardAlertIcon;

// import ClipboardAlertOutlineIcon from 'react-material-icon-svg/dist/ClipboardAlertOutline';
// export const ClipboardAlertOutlineIconSvg = ClipboardAlertOutlineIcon;

// import ClipboardArrowDownIcon from 'react-material-icon-svg/dist/ClipboardArrowDown';
// export const ClipboardArrowDownIconSvg = ClipboardArrowDownIcon;

// import ClipboardArrowDownOutlineIcon from 'react-material-icon-svg/dist/ClipboardArrowDownOutline';
// export const ClipboardArrowDownOutlineIconSvg = ClipboardArrowDownOutlineIcon;

// import ClipboardArrowLeftIcon from 'react-material-icon-svg/dist/ClipboardArrowLeft';
// export const ClipboardArrowLeftIconSvg = ClipboardArrowLeftIcon;

// import ClipboardArrowLeftOutlineIcon from 'react-material-icon-svg/dist/ClipboardArrowLeftOutline';
// export const ClipboardArrowLeftOutlineIconSvg = ClipboardArrowLeftOutlineIcon;

// import ClipboardArrowRightIcon from 'react-material-icon-svg/dist/ClipboardArrowRight';
// export const ClipboardArrowRightIconSvg = ClipboardArrowRightIcon;

// import ClipboardArrowRightOutlineIcon from 'react-material-icon-svg/dist/ClipboardArrowRightOutline';
// export const ClipboardArrowRightOutlineIconSvg = ClipboardArrowRightOutlineIcon;

// import ClipboardArrowUpIcon from 'react-material-icon-svg/dist/ClipboardArrowUp';
// export const ClipboardArrowUpIconSvg = ClipboardArrowUpIcon;

// import ClipboardArrowUpOutlineIcon from 'react-material-icon-svg/dist/ClipboardArrowUpOutline';
// export const ClipboardArrowUpOutlineIconSvg = ClipboardArrowUpOutlineIcon;

// import ClipboardCheckIcon from 'react-material-icon-svg/dist/ClipboardCheck';
// export const ClipboardCheckIconSvg = ClipboardCheckIcon;

// import ClipboardCheckOutlineIcon from 'react-material-icon-svg/dist/ClipboardCheckOutline';
// export const ClipboardCheckOutlineIconSvg = ClipboardCheckOutlineIcon;

// import ClipboardFlowIcon from 'react-material-icon-svg/dist/ClipboardFlow';
// export const ClipboardFlowIconSvg = ClipboardFlowIcon;

// import ClipboardOutlineIcon from 'react-material-icon-svg/dist/ClipboardOutline';
// export const ClipboardOutlineIconSvg = ClipboardOutlineIcon;

// import ClipboardPlayIcon from 'react-material-icon-svg/dist/ClipboardPlay';
// export const ClipboardPlayIconSvg = ClipboardPlayIcon;

// import ClipboardPlayOutlineIcon from 'react-material-icon-svg/dist/ClipboardPlayOutline';
// export const ClipboardPlayOutlineIconSvg = ClipboardPlayOutlineIcon;

// import ClipboardPlusIcon from 'react-material-icon-svg/dist/ClipboardPlus';
// export const ClipboardPlusIconSvg = ClipboardPlusIcon;

// import ClipboardPulseIcon from 'react-material-icon-svg/dist/ClipboardPulse';
// export const ClipboardPulseIconSvg = ClipboardPulseIcon;

// import ClipboardPulseOutlineIcon from 'react-material-icon-svg/dist/ClipboardPulseOutline';
// export const ClipboardPulseOutlineIconSvg = ClipboardPulseOutlineIcon;

// import ClipboardTextIcon from 'react-material-icon-svg/dist/ClipboardText';
// export const ClipboardTextIconSvg = ClipboardTextIcon;

// import ClipboardTextOutlineIcon from 'react-material-icon-svg/dist/ClipboardTextOutline';
// export const ClipboardTextOutlineIconSvg = ClipboardTextOutlineIcon;

// import ClipboardTextPlayIcon from 'react-material-icon-svg/dist/ClipboardTextPlay';
// export const ClipboardTextPlayIconSvg = ClipboardTextPlayIcon;

// import ClipboardTextPlayOutlineIcon from 'react-material-icon-svg/dist/ClipboardTextPlayOutline';
// export const ClipboardTextPlayOutlineIconSvg = ClipboardTextPlayOutlineIcon;

// import ClippyIcon from 'react-material-icon-svg/dist/Clippy';
// export const ClippyIconSvg = ClippyIcon;

// import ClockIcon from 'react-material-icon-svg/dist/Clock';
// export const ClockIconSvg = ClockIcon;

// import ClockAlertIcon from 'react-material-icon-svg/dist/ClockAlert';
// export const ClockAlertIconSvg = ClockAlertIcon;

// import ClockAlertOutlineIcon from 'react-material-icon-svg/dist/ClockAlertOutline';
// export const ClockAlertOutlineIconSvg = ClockAlertOutlineIcon;

// import ClockDigitalIcon from 'react-material-icon-svg/dist/ClockDigital';
// export const ClockDigitalIconSvg = ClockDigitalIcon;

// import ClockEndIcon from 'react-material-icon-svg/dist/ClockEnd';
// export const ClockEndIconSvg = ClockEndIcon;

// import ClockFastIcon from 'react-material-icon-svg/dist/ClockFast';
// export const ClockFastIconSvg = ClockFastIcon;

// import ClockInIcon from 'react-material-icon-svg/dist/ClockIn';
// export const ClockInIconSvg = ClockInIcon;

// import ClockOutIcon from 'react-material-icon-svg/dist/ClockOut';
// export const ClockOutIconSvg = ClockOutIcon;

// import ClockOutlineIcon from 'react-material-icon-svg/dist/ClockOutline';
// export const ClockOutlineIconSvg = ClockOutlineIcon;

// import ClockStartIcon from 'react-material-icon-svg/dist/ClockStart';
// export const ClockStartIconSvg = ClockStartIcon;

// import CloseIcon from 'react-material-icon-svg/dist/Close';
// export const CloseIconSvg = CloseIcon;

// import CloseBoxIcon from 'react-material-icon-svg/dist/CloseBox';
// export const CloseBoxIconSvg = CloseBoxIcon;

// import CloseBoxMultipleIcon from 'react-material-icon-svg/dist/CloseBoxMultiple';
// export const CloseBoxMultipleIconSvg = CloseBoxMultipleIcon;

// import CloseBoxMultipleOutlineIcon from 'react-material-icon-svg/dist/CloseBoxMultipleOutline';
// export const CloseBoxMultipleOutlineIconSvg = CloseBoxMultipleOutlineIcon;

// import CloseBoxOutlineIcon from 'react-material-icon-svg/dist/CloseBoxOutline';
// export const CloseBoxOutlineIconSvg = CloseBoxOutlineIcon;

// import CloseCircleIcon from 'react-material-icon-svg/dist/CloseCircle';
// export const CloseCircleIconSvg = CloseCircleIcon;

// import CloseCircleOutlineIcon from 'react-material-icon-svg/dist/CloseCircleOutline';
// export const CloseCircleOutlineIconSvg = CloseCircleOutlineIcon;

// import CloseNetworkIcon from 'react-material-icon-svg/dist/CloseNetwork';
// export const CloseNetworkIconSvg = CloseNetworkIcon;

// import CloseNetworkOutlineIcon from 'react-material-icon-svg/dist/CloseNetworkOutline';
// export const CloseNetworkOutlineIconSvg = CloseNetworkOutlineIcon;

// import CloseOctagonIcon from 'react-material-icon-svg/dist/CloseOctagon';
// export const CloseOctagonIconSvg = CloseOctagonIcon;

// import CloseOctagonOutlineIcon from 'react-material-icon-svg/dist/CloseOctagonOutline';
// export const CloseOctagonOutlineIconSvg = CloseOctagonOutlineIcon;

// import CloseOutlineIcon from 'react-material-icon-svg/dist/CloseOutline';
// export const CloseOutlineIconSvg = CloseOutlineIcon;

// import ClosedCaptionIcon from 'react-material-icon-svg/dist/ClosedCaption';
// export const ClosedCaptionIconSvg = ClosedCaptionIcon;

// import ClosedCaptionOutlineIcon from 'react-material-icon-svg/dist/ClosedCaptionOutline';
// export const ClosedCaptionOutlineIconSvg = ClosedCaptionOutlineIcon;

// import CloudIcon from 'react-material-icon-svg/dist/Cloud';
// export const CloudIconSvg = CloudIcon;

// import CloudAlertIcon from 'react-material-icon-svg/dist/CloudAlert';
// export const CloudAlertIconSvg = CloudAlertIcon;

// import CloudBracesIcon from 'react-material-icon-svg/dist/CloudBraces';
// export const CloudBracesIconSvg = CloudBracesIcon;

// import CloudCheckIcon from 'react-material-icon-svg/dist/CloudCheck';
// export const CloudCheckIconSvg = CloudCheckIcon;

// import CloudCircleIcon from 'react-material-icon-svg/dist/CloudCircle';
// export const CloudCircleIconSvg = CloudCircleIcon;

// import CloudDownloadIcon from 'react-material-icon-svg/dist/CloudDownload';
// export const CloudDownloadIconSvg = CloudDownloadIcon;

// import CloudDownloadOutlineIcon from 'react-material-icon-svg/dist/CloudDownloadOutline';
// export const CloudDownloadOutlineIconSvg = CloudDownloadOutlineIcon;

// import CloudOffOutlineIcon from 'react-material-icon-svg/dist/CloudOffOutline';
// export const CloudOffOutlineIconSvg = CloudOffOutlineIcon;

// import CloudOutlineIcon from 'react-material-icon-svg/dist/CloudOutline';
// export const CloudOutlineIconSvg = CloudOutlineIcon;

// import CloudPrintIcon from 'react-material-icon-svg/dist/CloudPrint';
// export const CloudPrintIconSvg = CloudPrintIcon;

// import CloudPrintOutlineIcon from 'react-material-icon-svg/dist/CloudPrintOutline';
// export const CloudPrintOutlineIconSvg = CloudPrintOutlineIcon;

// import CloudQuestionIcon from 'react-material-icon-svg/dist/CloudQuestion';
// export const CloudQuestionIconSvg = CloudQuestionIcon;

// import CloudSearchIcon from 'react-material-icon-svg/dist/CloudSearch';
// export const CloudSearchIconSvg = CloudSearchIcon;

// import CloudSearchOutlineIcon from 'react-material-icon-svg/dist/CloudSearchOutline';
// export const CloudSearchOutlineIconSvg = CloudSearchOutlineIcon;

// import CloudSyncIcon from 'react-material-icon-svg/dist/CloudSync';
// export const CloudSyncIconSvg = CloudSyncIcon;

// import CloudTagsIcon from 'react-material-icon-svg/dist/CloudTags';
// export const CloudTagsIconSvg = CloudTagsIcon;

// import CloudUploadIcon from 'react-material-icon-svg/dist/CloudUpload';
// export const CloudUploadIconSvg = CloudUploadIcon;

// import CloudUploadOutlineIcon from 'react-material-icon-svg/dist/CloudUploadOutline';
// export const CloudUploadOutlineIconSvg = CloudUploadOutlineIcon;

// import CloverIcon from 'react-material-icon-svg/dist/Clover';
// export const CloverIconSvg = CloverIcon;

// import CodeArrayIcon from 'react-material-icon-svg/dist/CodeArray';
// export const CodeArrayIconSvg = CodeArrayIcon;

// import CodeBracesIcon from 'react-material-icon-svg/dist/CodeBraces';
// export const CodeBracesIconSvg = CodeBracesIcon;

// import CodeBracketsIcon from 'react-material-icon-svg/dist/CodeBrackets';
// export const CodeBracketsIconSvg = CodeBracketsIcon;

// import CodeEqualIcon from 'react-material-icon-svg/dist/CodeEqual';
// export const CodeEqualIconSvg = CodeEqualIcon;

// import CodeGreaterThanIcon from 'react-material-icon-svg/dist/CodeGreaterThan';
// export const CodeGreaterThanIconSvg = CodeGreaterThanIcon;

// import CodeGreaterThanOrEqualIcon from 'react-material-icon-svg/dist/CodeGreaterThanOrEqual';
// export const CodeGreaterThanOrEqualIconSvg = CodeGreaterThanOrEqualIcon;

// import CodeLessThanIcon from 'react-material-icon-svg/dist/CodeLessThan';
// export const CodeLessThanIconSvg = CodeLessThanIcon;

// import CodeLessThanOrEqualIcon from 'react-material-icon-svg/dist/CodeLessThanOrEqual';
// export const CodeLessThanOrEqualIconSvg = CodeLessThanOrEqualIcon;

// import CodeNotEqualIcon from 'react-material-icon-svg/dist/CodeNotEqual';
// export const CodeNotEqualIconSvg = CodeNotEqualIcon;

// import CodeNotEqualVariantIcon from 'react-material-icon-svg/dist/CodeNotEqualVariant';
// export const CodeNotEqualVariantIconSvg = CodeNotEqualVariantIcon;

// import CodeParenthesesIcon from 'react-material-icon-svg/dist/CodeParentheses';
// export const CodeParenthesesIconSvg = CodeParenthesesIcon;

// import CodeStringIcon from 'react-material-icon-svg/dist/CodeString';
// export const CodeStringIconSvg = CodeStringIcon;

// import CodeTagsIcon from 'react-material-icon-svg/dist/CodeTags';
// export const CodeTagsIconSvg = CodeTagsIcon;

// import CodeTagsCheckIcon from 'react-material-icon-svg/dist/CodeTagsCheck';
// export const CodeTagsCheckIconSvg = CodeTagsCheckIcon;

// import CodepenIcon from 'react-material-icon-svg/dist/Codepen';
// export const CodepenIconSvg = CodepenIcon;

// import CoffeeIcon from 'react-material-icon-svg/dist/Coffee';
// export const CoffeeIconSvg = CoffeeIcon;

// import CoffeeOutlineIcon from 'react-material-icon-svg/dist/CoffeeOutline';
// export const CoffeeOutlineIconSvg = CoffeeOutlineIcon;

// import CoffeeToGoIcon from 'react-material-icon-svg/dist/CoffeeToGo';
// export const CoffeeToGoIconSvg = CoffeeToGoIcon;

// import CoffinIcon from 'react-material-icon-svg/dist/Coffin';
// export const CoffinIconSvg = CoffinIcon;

// import CogsIcon from 'react-material-icon-svg/dist/Cogs';
// export const CogsIconSvg = CogsIcon;

// import CoinIcon from 'react-material-icon-svg/dist/Coin';
// export const CoinIconSvg = CoinIcon;

// import CoinsIcon from 'react-material-icon-svg/dist/Coins';
// export const CoinsIconSvg = CoinsIcon;

// import CollageIcon from 'react-material-icon-svg/dist/Collage';
// export const CollageIconSvg = CollageIcon;

// import CollapseAllIcon from 'react-material-icon-svg/dist/CollapseAll';
// export const CollapseAllIconSvg = CollapseAllIcon;

// import CollapseAllOutlineIcon from 'react-material-icon-svg/dist/CollapseAllOutline';
// export const CollapseAllOutlineIconSvg = CollapseAllOutlineIcon;

// import ColorHelperIcon from 'react-material-icon-svg/dist/ColorHelper';
// export const ColorHelperIconSvg = ColorHelperIcon;

// import CommaIcon from 'react-material-icon-svg/dist/Comma';
// export const CommaIconSvg = CommaIcon;

// import CommaBoxIcon from 'react-material-icon-svg/dist/CommaBox';
// export const CommaBoxIconSvg = CommaBoxIcon;

// import CommaBoxOutlineIcon from 'react-material-icon-svg/dist/CommaBoxOutline';
// export const CommaBoxOutlineIconSvg = CommaBoxOutlineIcon;

// import CommaCircleIcon from 'react-material-icon-svg/dist/CommaCircle';
// export const CommaCircleIconSvg = CommaCircleIcon;

// import CommaCircleOutlineIcon from 'react-material-icon-svg/dist/CommaCircleOutline';
// export const CommaCircleOutlineIconSvg = CommaCircleOutlineIcon;

// import CommentIcon from 'react-material-icon-svg/dist/Comment';
// export const CommentIconSvg = CommentIcon;

// import CommentAccountIcon from 'react-material-icon-svg/dist/CommentAccount';
// export const CommentAccountIconSvg = CommentAccountIcon;

// import CommentAccountOutlineIcon from 'react-material-icon-svg/dist/CommentAccountOutline';
// export const CommentAccountOutlineIconSvg = CommentAccountOutlineIcon;

// import CommentAlertIcon from 'react-material-icon-svg/dist/CommentAlert';
// export const CommentAlertIconSvg = CommentAlertIcon;

// import CommentAlertOutlineIcon from 'react-material-icon-svg/dist/CommentAlertOutline';
// export const CommentAlertOutlineIconSvg = CommentAlertOutlineIcon;

// import CommentArrowLeftIcon from 'react-material-icon-svg/dist/CommentArrowLeft';
// export const CommentArrowLeftIconSvg = CommentArrowLeftIcon;

// import CommentArrowLeftOutlineIcon from 'react-material-icon-svg/dist/CommentArrowLeftOutline';
// export const CommentArrowLeftOutlineIconSvg = CommentArrowLeftOutlineIcon;

// import CommentArrowRightIcon from 'react-material-icon-svg/dist/CommentArrowRight';
// export const CommentArrowRightIconSvg = CommentArrowRightIcon;

// import CommentArrowRightOutlineIcon from 'react-material-icon-svg/dist/CommentArrowRightOutline';
// export const CommentArrowRightOutlineIconSvg = CommentArrowRightOutlineIcon;

// import CommentCheckIcon from 'react-material-icon-svg/dist/CommentCheck';
// export const CommentCheckIconSvg = CommentCheckIcon;

// import CommentCheckOutlineIcon from 'react-material-icon-svg/dist/CommentCheckOutline';
// export const CommentCheckOutlineIconSvg = CommentCheckOutlineIcon;

import CommentEyeIcon from 'react-material-icon-svg/dist/CommentEye';
export const CommentEyeIconSvg = CommentEyeIcon;

// import CommentEyeOutlineIcon from 'react-material-icon-svg/dist/CommentEyeOutline';
// export const CommentEyeOutlineIconSvg = CommentEyeOutlineIcon;

// import CommentMultipleIcon from 'react-material-icon-svg/dist/CommentMultiple';
// export const CommentMultipleIconSvg = CommentMultipleIcon;

// import CommentMultipleOutlineIcon from 'react-material-icon-svg/dist/CommentMultipleOutline';
// export const CommentMultipleOutlineIconSvg = CommentMultipleOutlineIcon;

// import CommentOutlineIcon from 'react-material-icon-svg/dist/CommentOutline';
// export const CommentOutlineIconSvg = CommentOutlineIcon;

// import CommentPlusIcon from 'react-material-icon-svg/dist/CommentPlus';
// export const CommentPlusIconSvg = CommentPlusIcon;

// import CommentPlusOutlineIcon from 'react-material-icon-svg/dist/CommentPlusOutline';
// export const CommentPlusOutlineIconSvg = CommentPlusOutlineIcon;

// import CommentProcessingIcon from 'react-material-icon-svg/dist/CommentProcessing';
// export const CommentProcessingIconSvg = CommentProcessingIcon;

// import CommentProcessingOutlineIcon from 'react-material-icon-svg/dist/CommentProcessingOutline';
// export const CommentProcessingOutlineIconSvg = CommentProcessingOutlineIcon;

// import CommentQuestionIcon from 'react-material-icon-svg/dist/CommentQuestion';
// export const CommentQuestionIconSvg = CommentQuestionIcon;

// import CommentQuestionOutlineIcon from 'react-material-icon-svg/dist/CommentQuestionOutline';
// export const CommentQuestionOutlineIconSvg = CommentQuestionOutlineIcon;

// import CommentRemoveIcon from 'react-material-icon-svg/dist/CommentRemove';
// export const CommentRemoveIconSvg = CommentRemoveIcon;

// import CommentRemoveOutlineIcon from 'react-material-icon-svg/dist/CommentRemoveOutline';
// export const CommentRemoveOutlineIconSvg = CommentRemoveOutlineIcon;

// import CommentSearchIcon from 'react-material-icon-svg/dist/CommentSearch';
// export const CommentSearchIconSvg = CommentSearchIcon;

// import CommentSearchOutlineIcon from 'react-material-icon-svg/dist/CommentSearchOutline';
// export const CommentSearchOutlineIconSvg = CommentSearchOutlineIcon;

// import CommentTextIcon from 'react-material-icon-svg/dist/CommentText';
// export const CommentTextIconSvg = CommentTextIcon;

// import CommentTextMultipleIcon from 'react-material-icon-svg/dist/CommentTextMultiple';
// export const CommentTextMultipleIconSvg = CommentTextMultipleIcon;

// import CommentTextMultipleOutlineIcon from 'react-material-icon-svg/dist/CommentTextMultipleOutline';
// export const CommentTextMultipleOutlineIconSvg = CommentTextMultipleOutlineIcon;

// import CommentTextOutlineIcon from 'react-material-icon-svg/dist/CommentTextOutline';
// export const CommentTextOutlineIconSvg = CommentTextOutlineIcon;

// import CompareIcon from 'react-material-icon-svg/dist/Compare';
// export const CompareIconSvg = CompareIcon;

// import CompassIcon from 'react-material-icon-svg/dist/Compass';
// export const CompassIconSvg = CompassIcon;

// import CompassOffIcon from 'react-material-icon-svg/dist/CompassOff';
// export const CompassOffIconSvg = CompassOffIcon;

// import CompassOffOutlineIcon from 'react-material-icon-svg/dist/CompassOffOutline';
// export const CompassOffOutlineIconSvg = CompassOffOutlineIcon;

// import CompassOutlineIcon from 'react-material-icon-svg/dist/CompassOutline';
// export const CompassOutlineIconSvg = CompassOutlineIcon;

// import ConsoleIcon from 'react-material-icon-svg/dist/Console';
// export const ConsoleIconSvg = ConsoleIcon;

// import ConsoleLineIcon from 'react-material-icon-svg/dist/ConsoleLine';
// export const ConsoleLineIconSvg = ConsoleLineIcon;

// import ConsoleNetworkIcon from 'react-material-icon-svg/dist/ConsoleNetwork';
// export const ConsoleNetworkIconSvg = ConsoleNetworkIcon;

// import ConsoleNetworkOutlineIcon from 'react-material-icon-svg/dist/ConsoleNetworkOutline';
// export const ConsoleNetworkOutlineIconSvg = ConsoleNetworkOutlineIcon;

// import ContactMailIcon from 'react-material-icon-svg/dist/ContactMail';
// export const ContactMailIconSvg = ContactMailIcon;

// import ContactMailOutlineIcon from 'react-material-icon-svg/dist/ContactMailOutline';
// export const ContactMailOutlineIconSvg = ContactMailOutlineIcon;

// import ContactPhoneIcon from 'react-material-icon-svg/dist/ContactPhone';
// export const ContactPhoneIconSvg = ContactPhoneIcon;

// import ContactPhoneOutlineIcon from 'react-material-icon-svg/dist/ContactPhoneOutline';
// export const ContactPhoneOutlineIconSvg = ContactPhoneOutlineIcon;

// import ContactlessPaymentIcon from 'react-material-icon-svg/dist/ContactlessPayment';
// export const ContactlessPaymentIconSvg = ContactlessPaymentIcon;

// import ContactsIcon from 'react-material-icon-svg/dist/Contacts';
// export const ContactsIconSvg = ContactsIcon;

// import ContainIcon from 'react-material-icon-svg/dist/Contain';
// export const ContainIconSvg = ContainIcon;

// import ContainEndIcon from 'react-material-icon-svg/dist/ContainEnd';
// export const ContainEndIconSvg = ContainEndIcon;

// import ContainStartIcon from 'react-material-icon-svg/dist/ContainStart';
// export const ContainStartIconSvg = ContainStartIcon;

// import ContentCopyIcon from 'react-material-icon-svg/dist/ContentCopy';
// export const ContentCopyIconSvg = ContentCopyIcon;

// import ContentCutIcon from 'react-material-icon-svg/dist/ContentCut';
// export const ContentCutIconSvg = ContentCutIcon;

// import ContentDuplicateIcon from 'react-material-icon-svg/dist/ContentDuplicate';
// export const ContentDuplicateIconSvg = ContentDuplicateIcon;

// import ContentPasteIcon from 'react-material-icon-svg/dist/ContentPaste';
// export const ContentPasteIconSvg = ContentPasteIcon;

// import ContentSaveIcon from 'react-material-icon-svg/dist/ContentSave';
// export const ContentSaveIconSvg = ContentSaveIcon;

// import ContentSaveAllIcon from 'react-material-icon-svg/dist/ContentSaveAll';
// export const ContentSaveAllIconSvg = ContentSaveAllIcon;

// import ContentSaveEditIcon from 'react-material-icon-svg/dist/ContentSaveEdit';
// export const ContentSaveEditIconSvg = ContentSaveEditIcon;

// import ContentSaveEditOutlineIcon from 'react-material-icon-svg/dist/ContentSaveEditOutline';
// export const ContentSaveEditOutlineIconSvg = ContentSaveEditOutlineIcon;

// import ContentSaveMoveIcon from 'react-material-icon-svg/dist/ContentSaveMove';
// export const ContentSaveMoveIconSvg = ContentSaveMoveIcon;

// import ContentSaveMoveOutlineIcon from 'react-material-icon-svg/dist/ContentSaveMoveOutline';
// export const ContentSaveMoveOutlineIconSvg = ContentSaveMoveOutlineIcon;

// import ContentSaveOutlineIcon from 'react-material-icon-svg/dist/ContentSaveOutline';
// export const ContentSaveOutlineIconSvg = ContentSaveOutlineIcon;

// import ContentSaveSettingsIcon from 'react-material-icon-svg/dist/ContentSaveSettings';
// export const ContentSaveSettingsIconSvg = ContentSaveSettingsIcon;

// import ContentSaveSettingsOutlineIcon from 'react-material-icon-svg/dist/ContentSaveSettingsOutline';
// export const ContentSaveSettingsOutlineIconSvg = ContentSaveSettingsOutlineIcon;

// import ContrastIcon from 'react-material-icon-svg/dist/Contrast';
// export const ContrastIconSvg = ContrastIcon;

// import ContrastBoxIcon from 'react-material-icon-svg/dist/ContrastBox';
// export const ContrastBoxIconSvg = ContrastBoxIcon;

// import ContrastCircleIcon from 'react-material-icon-svg/dist/ContrastCircle';
// export const ContrastCircleIconSvg = ContrastCircleIcon;

// import ControllerClassicIcon from 'react-material-icon-svg/dist/ControllerClassic';
// export const ControllerClassicIconSvg = ControllerClassicIcon;

// import ControllerClassicOutlineIcon from 'react-material-icon-svg/dist/ControllerClassicOutline';
// export const ControllerClassicOutlineIconSvg = ControllerClassicOutlineIcon;

// import CookieIcon from 'react-material-icon-svg/dist/Cookie';
// export const CookieIconSvg = CookieIcon;

// import CopyrightIcon from 'react-material-icon-svg/dist/Copyright';
// export const CopyrightIconSvg = CopyrightIcon;

// import CordovaIcon from 'react-material-icon-svg/dist/Cordova';
// export const CordovaIconSvg = CordovaIcon;

// import CornIcon from 'react-material-icon-svg/dist/Corn';
// export const CornIconSvg = CornIcon;

// import CounterIcon from 'react-material-icon-svg/dist/Counter';
// export const CounterIconSvg = CounterIcon;

// import CowIcon from 'react-material-icon-svg/dist/Cow';
// export const CowIconSvg = CowIcon;

// import CowboyIcon from 'react-material-icon-svg/dist/Cowboy';
// export const CowboyIconSvg = CowboyIcon;

// import CraneIcon from 'react-material-icon-svg/dist/Crane';
// export const CraneIconSvg = CraneIcon;

// import CreationIcon from 'react-material-icon-svg/dist/Creation';
// export const CreationIconSvg = CreationIcon;

// import CreativeCommonsIcon from 'react-material-icon-svg/dist/CreativeCommons';
// export const CreativeCommonsIconSvg = CreativeCommonsIcon;

// import CreditCardIcon from 'react-material-icon-svg/dist/CreditCard';
// export const CreditCardIconSvg = CreditCardIcon;

// import CreditCardMarkerIcon from 'react-material-icon-svg/dist/CreditCardMarker';
// export const CreditCardMarkerIconSvg = CreditCardMarkerIcon;

// import CreditCardMultipleIcon from 'react-material-icon-svg/dist/CreditCardMultiple';
// export const CreditCardMultipleIconSvg = CreditCardMultipleIcon;

import CreditCardOffIcon from 'react-material-icon-svg/dist/CreditCardOff';
export const CreditCardOffIconSvg = CreditCardOffIcon;

// import CreditCardPlusIcon from 'react-material-icon-svg/dist/CreditCardPlus';
// export const CreditCardPlusIconSvg = CreditCardPlusIcon;

// import CreditCardRefundIcon from 'react-material-icon-svg/dist/CreditCardRefund';
// export const CreditCardRefundIconSvg = CreditCardRefundIcon;

// import CreditCardScanIcon from 'react-material-icon-svg/dist/CreditCardScan';
// export const CreditCardScanIconSvg = CreditCardScanIcon;

// import CreditCardSettingsIcon from 'react-material-icon-svg/dist/CreditCardSettings';
// export const CreditCardSettingsIconSvg = CreditCardSettingsIcon;

// import CreditCardWirelessIcon from 'react-material-icon-svg/dist/CreditCardWireless';
// export const CreditCardWirelessIconSvg = CreditCardWirelessIcon;

// import CricketIcon from 'react-material-icon-svg/dist/Cricket';
// export const CricketIconSvg = CricketIcon;

// import CropIcon from 'react-material-icon-svg/dist/Crop';
// export const CropIconSvg = CropIcon;

// import CropFreeIcon from 'react-material-icon-svg/dist/CropFree';
// export const CropFreeIconSvg = CropFreeIcon;

// import CropLandscapeIcon from 'react-material-icon-svg/dist/CropLandscape';
// export const CropLandscapeIconSvg = CropLandscapeIcon;

// import CropPortraitIcon from 'react-material-icon-svg/dist/CropPortrait';
// export const CropPortraitIconSvg = CropPortraitIcon;

// import CropRotateIcon from 'react-material-icon-svg/dist/CropRotate';
// export const CropRotateIconSvg = CropRotateIcon;

// import CropSquareIcon from 'react-material-icon-svg/dist/CropSquare';
// export const CropSquareIconSvg = CropSquareIcon;

// import CrosshairsIcon from 'react-material-icon-svg/dist/Crosshairs';
// export const CrosshairsIconSvg = CrosshairsIcon;

// import CrosshairsGpsIcon from 'react-material-icon-svg/dist/CrosshairsGps';
// export const CrosshairsGpsIconSvg = CrosshairsGpsIcon;

// import CrownIcon from 'react-material-icon-svg/dist/Crown';
// export const CrownIconSvg = CrownIcon;

// import CryengineIcon from 'react-material-icon-svg/dist/Cryengine';
// export const CryengineIconSvg = CryengineIcon;

// import CrystalBallIcon from 'react-material-icon-svg/dist/CrystalBall';
// export const CrystalBallIconSvg = CrystalBallIcon;

// import CubeIcon from 'react-material-icon-svg/dist/Cube';
// export const CubeIconSvg = CubeIcon;

// import CubeOutlineIcon from 'react-material-icon-svg/dist/CubeOutline';
// export const CubeOutlineIconSvg = CubeOutlineIcon;

// import CubeScanIcon from 'react-material-icon-svg/dist/CubeScan';
// export const CubeScanIconSvg = CubeScanIcon;

// import CubeSendIcon from 'react-material-icon-svg/dist/CubeSend';
// export const CubeSendIconSvg = CubeSendIcon;

// import CubeUnfoldedIcon from 'react-material-icon-svg/dist/CubeUnfolded';
// export const CubeUnfoldedIconSvg = CubeUnfoldedIcon;

// import CupIcon from 'react-material-icon-svg/dist/Cup';
// export const CupIconSvg = CupIcon;

// import CupOffIcon from 'react-material-icon-svg/dist/CupOff';
// export const CupOffIconSvg = CupOffIcon;

// import CupWaterIcon from 'react-material-icon-svg/dist/CupWater';
// export const CupWaterIconSvg = CupWaterIcon;

// import CupcakeIcon from 'react-material-icon-svg/dist/Cupcake';
// export const CupcakeIconSvg = CupcakeIcon;

// import CurlingIcon from 'react-material-icon-svg/dist/Curling';
// export const CurlingIconSvg = CurlingIcon;

// import CurrencyBdtIcon from 'react-material-icon-svg/dist/CurrencyBdt';
// export const CurrencyBdtIconSvg = CurrencyBdtIcon;

// import CurrencyBrlIcon from 'react-material-icon-svg/dist/CurrencyBrl';
// export const CurrencyBrlIconSvg = CurrencyBrlIcon;

// import CurrencyBtcIcon from 'react-material-icon-svg/dist/CurrencyBtc';
// export const CurrencyBtcIconSvg = CurrencyBtcIcon;

// import CurrencyChfIcon from 'react-material-icon-svg/dist/CurrencyChf';
// export const CurrencyChfIconSvg = CurrencyChfIcon;

// import CurrencyCnyIcon from 'react-material-icon-svg/dist/CurrencyCny';
// export const CurrencyCnyIconSvg = CurrencyCnyIcon;

// import CurrencyEthIcon from 'react-material-icon-svg/dist/CurrencyEth';
// export const CurrencyEthIconSvg = CurrencyEthIcon;

// import CurrencyEurIcon from 'react-material-icon-svg/dist/CurrencyEur';
// export const CurrencyEurIconSvg = CurrencyEurIcon;

// import CurrencyGbpIcon from 'react-material-icon-svg/dist/CurrencyGbp';
// export const CurrencyGbpIconSvg = CurrencyGbpIcon;

// import CurrencyIlsIcon from 'react-material-icon-svg/dist/CurrencyIls';
// export const CurrencyIlsIconSvg = CurrencyIlsIcon;

// import CurrencyInrIcon from 'react-material-icon-svg/dist/CurrencyInr';
// export const CurrencyInrIconSvg = CurrencyInrIcon;

// import CurrencyJpyIcon from 'react-material-icon-svg/dist/CurrencyJpy';
// export const CurrencyJpyIconSvg = CurrencyJpyIcon;

// import CurrencyKrwIcon from 'react-material-icon-svg/dist/CurrencyKrw';
// export const CurrencyKrwIconSvg = CurrencyKrwIcon;

// import CurrencyKztIcon from 'react-material-icon-svg/dist/CurrencyKzt';
// export const CurrencyKztIconSvg = CurrencyKztIcon;

// import CurrencyNgnIcon from 'react-material-icon-svg/dist/CurrencyNgn';
// export const CurrencyNgnIconSvg = CurrencyNgnIcon;

// import CurrencyPhpIcon from 'react-material-icon-svg/dist/CurrencyPhp';
// export const CurrencyPhpIconSvg = CurrencyPhpIcon;

// import CurrencyRialIcon from 'react-material-icon-svg/dist/CurrencyRial';
// export const CurrencyRialIconSvg = CurrencyRialIcon;

// import CurrencyRubIcon from 'react-material-icon-svg/dist/CurrencyRub';
// export const CurrencyRubIconSvg = CurrencyRubIcon;

// import CurrencySignIcon from 'react-material-icon-svg/dist/CurrencySign';
// export const CurrencySignIconSvg = CurrencySignIcon;

// import CurrencyTryIcon from 'react-material-icon-svg/dist/CurrencyTry';
// export const CurrencyTryIconSvg = CurrencyTryIcon;

// import CurrencyTwdIcon from 'react-material-icon-svg/dist/CurrencyTwd';
// export const CurrencyTwdIconSvg = CurrencyTwdIcon;

// import CurrencyUsdIcon from 'react-material-icon-svg/dist/CurrencyUsd';
// export const CurrencyUsdIconSvg = CurrencyUsdIcon;

// import CurrencyUsdOffIcon from 'react-material-icon-svg/dist/CurrencyUsdOff';
// export const CurrencyUsdOffIconSvg = CurrencyUsdOffIcon;

// import CurrentAcIcon from 'react-material-icon-svg/dist/CurrentAc';
// export const CurrentAcIconSvg = CurrentAcIcon;

// import CurrentDcIcon from 'react-material-icon-svg/dist/CurrentDc';
// export const CurrentDcIconSvg = CurrentDcIcon;

// import CursorDefaultIcon from 'react-material-icon-svg/dist/CursorDefault';
// export const CursorDefaultIconSvg = CursorDefaultIcon;

// import CursorDefaultClickIcon from 'react-material-icon-svg/dist/CursorDefaultClick';
// export const CursorDefaultClickIconSvg = CursorDefaultClickIcon;

// import CursorDefaultClickOutlineIcon from 'react-material-icon-svg/dist/CursorDefaultClickOutline';
// export const CursorDefaultClickOutlineIconSvg = CursorDefaultClickOutlineIcon;

// import CursorDefaultOutlineIcon from 'react-material-icon-svg/dist/CursorDefaultOutline';
// export const CursorDefaultOutlineIconSvg = CursorDefaultOutlineIcon;

// import CursorMoveIcon from 'react-material-icon-svg/dist/CursorMove';
// export const CursorMoveIconSvg = CursorMoveIcon;

// import CursorPointerIcon from 'react-material-icon-svg/dist/CursorPointer';
// export const CursorPointerIconSvg = CursorPointerIcon;

// import CursorTextIcon from 'react-material-icon-svg/dist/CursorText';
// export const CursorTextIconSvg = CursorTextIcon;

// import DatabaseIcon from 'react-material-icon-svg/dist/Database';
// export const DatabaseIconSvg = DatabaseIcon;

// import DatabaseCheckIcon from 'react-material-icon-svg/dist/DatabaseCheck';
// export const DatabaseCheckIconSvg = DatabaseCheckIcon;

// import DatabaseEditIcon from 'react-material-icon-svg/dist/DatabaseEdit';
// export const DatabaseEditIconSvg = DatabaseEditIcon;

// import DatabaseExportIcon from 'react-material-icon-svg/dist/DatabaseExport';
// export const DatabaseExportIconSvg = DatabaseExportIcon;

// import DatabaseImportIcon from 'react-material-icon-svg/dist/DatabaseImport';
// export const DatabaseImportIconSvg = DatabaseImportIcon;

// import DatabaseLockIcon from 'react-material-icon-svg/dist/DatabaseLock';
// export const DatabaseLockIconSvg = DatabaseLockIcon;

// import DatabaseMinusIcon from 'react-material-icon-svg/dist/DatabaseMinus';
// export const DatabaseMinusIconSvg = DatabaseMinusIcon;

// import DatabasePlusIcon from 'react-material-icon-svg/dist/DatabasePlus';
// export const DatabasePlusIconSvg = DatabasePlusIcon;

// import DatabaseRefreshIcon from 'react-material-icon-svg/dist/DatabaseRefresh';
// export const DatabaseRefreshIconSvg = DatabaseRefreshIcon;

// import DatabaseRemoveIcon from 'react-material-icon-svg/dist/DatabaseRemove';
// export const DatabaseRemoveIconSvg = DatabaseRemoveIcon;

// import DatabaseSearchIcon from 'react-material-icon-svg/dist/DatabaseSearch';
// export const DatabaseSearchIconSvg = DatabaseSearchIcon;

// import DatabaseSettingsIcon from 'react-material-icon-svg/dist/DatabaseSettings';
// export const DatabaseSettingsIconSvg = DatabaseSettingsIcon;

// import DeathStarIcon from 'react-material-icon-svg/dist/DeathStar';
// export const DeathStarIconSvg = DeathStarIcon;

// import DeathStarVariantIcon from 'react-material-icon-svg/dist/DeathStarVariant';
// export const DeathStarVariantIconSvg = DeathStarVariantIcon;

// import DeathlyHallowsIcon from 'react-material-icon-svg/dist/DeathlyHallows';
// export const DeathlyHallowsIconSvg = DeathlyHallowsIcon;

// import DebianIcon from 'react-material-icon-svg/dist/Debian';
// export const DebianIconSvg = DebianIcon;

// import DebugStepIntoIcon from 'react-material-icon-svg/dist/DebugStepInto';
// export const DebugStepIntoIconSvg = DebugStepIntoIcon;

// import DebugStepOutIcon from 'react-material-icon-svg/dist/DebugStepOut';
// export const DebugStepOutIconSvg = DebugStepOutIcon;

// import DebugStepOverIcon from 'react-material-icon-svg/dist/DebugStepOver';
// export const DebugStepOverIconSvg = DebugStepOverIcon;

// import DecagramIcon from 'react-material-icon-svg/dist/Decagram';
// export const DecagramIconSvg = DecagramIcon;

// import DecagramOutlineIcon from 'react-material-icon-svg/dist/DecagramOutline';
// export const DecagramOutlineIconSvg = DecagramOutlineIcon;

// import DecimalDecreaseIcon from 'react-material-icon-svg/dist/DecimalDecrease';
// export const DecimalDecreaseIconSvg = DecimalDecreaseIcon;

// import DecimalIncreaseIcon from 'react-material-icon-svg/dist/DecimalIncrease';
// export const DecimalIncreaseIconSvg = DecimalIncreaseIcon;

// import DeleteIcon from 'react-material-icon-svg/dist/Delete';
// export const DeleteIconSvg = DeleteIcon;

// import DeleteCircleIcon from 'react-material-icon-svg/dist/DeleteCircle';
// export const DeleteCircleIconSvg = DeleteCircleIcon;

// import DeleteCircleOutlineIcon from 'react-material-icon-svg/dist/DeleteCircleOutline';
// export const DeleteCircleOutlineIconSvg = DeleteCircleOutlineIcon;

// import DeleteEmptyIcon from 'react-material-icon-svg/dist/DeleteEmpty';
// export const DeleteEmptyIconSvg = DeleteEmptyIcon;

// import DeleteEmptyOutlineIcon from 'react-material-icon-svg/dist/DeleteEmptyOutline';
// export const DeleteEmptyOutlineIconSvg = DeleteEmptyOutlineIcon;

// import DeleteForeverIcon from 'react-material-icon-svg/dist/DeleteForever';
// export const DeleteForeverIconSvg = DeleteForeverIcon;

// import DeleteForeverOutlineIcon from 'react-material-icon-svg/dist/DeleteForeverOutline';
// export const DeleteForeverOutlineIconSvg = DeleteForeverOutlineIcon;

// import DeleteOutlineIcon from 'react-material-icon-svg/dist/DeleteOutline';
// export const DeleteOutlineIconSvg = DeleteOutlineIcon;

// import DeleteRestoreIcon from 'react-material-icon-svg/dist/DeleteRestore';
// export const DeleteRestoreIconSvg = DeleteRestoreIcon;

// import DeleteSweepIcon from 'react-material-icon-svg/dist/DeleteSweep';
// export const DeleteSweepIconSvg = DeleteSweepIcon;

// import DeleteSweepOutlineIcon from 'react-material-icon-svg/dist/DeleteSweepOutline';
// export const DeleteSweepOutlineIconSvg = DeleteSweepOutlineIcon;

// import DeleteVariantIcon from 'react-material-icon-svg/dist/DeleteVariant';
// export const DeleteVariantIconSvg = DeleteVariantIcon;

// import DeltaIcon from 'react-material-icon-svg/dist/Delta';
// export const DeltaIconSvg = DeltaIcon;

// import DeskLampIcon from 'react-material-icon-svg/dist/DeskLamp';
// export const DeskLampIconSvg = DeskLampIcon;

// import DeskphoneIcon from 'react-material-icon-svg/dist/Deskphone';
// export const DeskphoneIconSvg = DeskphoneIcon;

// import DesktopClassicIcon from 'react-material-icon-svg/dist/DesktopClassic';
// export const DesktopClassicIconSvg = DesktopClassicIcon;

// import DesktopMacIcon from 'react-material-icon-svg/dist/DesktopMac';
// export const DesktopMacIconSvg = DesktopMacIcon;

// import DesktopMacDashboardIcon from 'react-material-icon-svg/dist/DesktopMacDashboard';
// export const DesktopMacDashboardIconSvg = DesktopMacDashboardIcon;

// import DesktopTowerIcon from 'react-material-icon-svg/dist/DesktopTower';
// export const DesktopTowerIconSvg = DesktopTowerIcon;

// import DesktopTowerMonitorIcon from 'react-material-icon-svg/dist/DesktopTowerMonitor';
// export const DesktopTowerMonitorIconSvg = DesktopTowerMonitorIcon;

// import DetailsIcon from 'react-material-icon-svg/dist/Details';
// export const DetailsIconSvg = DetailsIcon;

// import DevToIcon from 'react-material-icon-svg/dist/DevTo';
// export const DevToIconSvg = DevToIcon;

// import DeveloperBoardIcon from 'react-material-icon-svg/dist/DeveloperBoard';
// export const DeveloperBoardIconSvg = DeveloperBoardIcon;

// import DeviantartIcon from 'react-material-icon-svg/dist/Deviantart';
// export const DeviantartIconSvg = DeviantartIcon;

// import DialpadIcon from 'react-material-icon-svg/dist/Dialpad';
// export const DialpadIconSvg = DialpadIcon;

// import DiameterIcon from 'react-material-icon-svg/dist/Diameter';
// export const DiameterIconSvg = DiameterIcon;

// import DiameterOutlineIcon from 'react-material-icon-svg/dist/DiameterOutline';
// export const DiameterOutlineIconSvg = DiameterOutlineIcon;

// import DiameterVariantIcon from 'react-material-icon-svg/dist/DiameterVariant';
// export const DiameterVariantIconSvg = DiameterVariantIcon;

// import DiamondIcon from 'react-material-icon-svg/dist/Diamond';
// export const DiamondIconSvg = DiamondIcon;

// import DiamondOutlineIcon from 'react-material-icon-svg/dist/DiamondOutline';
// export const DiamondOutlineIconSvg = DiamondOutlineIcon;

// import DiamondStoneIcon from 'react-material-icon-svg/dist/DiamondStone';
// export const DiamondStoneIconSvg = DiamondStoneIcon;

// import DiceD10Icon from 'react-material-icon-svg/dist/DiceD10';
// export const DiceD10IconSvg = DiceD10Icon;

// import DiceD12Icon from 'react-material-icon-svg/dist/DiceD12';
// export const DiceD12IconSvg = DiceD12Icon;

// import DiceD20Icon from 'react-material-icon-svg/dist/DiceD20';
// export const DiceD20IconSvg = DiceD20Icon;

// import DiceD4Icon from 'react-material-icon-svg/dist/DiceD4';
// export const DiceD4IconSvg = DiceD4Icon;

// import DiceD6Icon from 'react-material-icon-svg/dist/DiceD6';
// export const DiceD6IconSvg = DiceD6Icon;

// import DiceD8Icon from 'react-material-icon-svg/dist/DiceD8';
// export const DiceD8IconSvg = DiceD8Icon;

// import DiceMultipleIcon from 'react-material-icon-svg/dist/DiceMultiple';
// export const DiceMultipleIconSvg = DiceMultipleIcon;

// import Dice_1Icon from 'react-material-icon-svg/dist/Dice_1';
// export const Dice_1IconSvg = Dice_1Icon;

// import Dice_2Icon from 'react-material-icon-svg/dist/Dice_2';
// export const Dice_2IconSvg = Dice_2Icon;

// import Dice_3Icon from 'react-material-icon-svg/dist/Dice_3';
// export const Dice_3IconSvg = Dice_3Icon;

// import Dice_4Icon from 'react-material-icon-svg/dist/Dice_4';
// export const Dice_4IconSvg = Dice_4Icon;

// import Dice_5Icon from 'react-material-icon-svg/dist/Dice_5';
// export const Dice_5IconSvg = Dice_5Icon;

// import Dice_6Icon from 'react-material-icon-svg/dist/Dice_6';
// export const Dice_6IconSvg = Dice_6Icon;

// import DictionaryIcon from 'react-material-icon-svg/dist/Dictionary';
// export const DictionaryIconSvg = DictionaryIcon;

// import DipSwitchIcon from 'react-material-icon-svg/dist/DipSwitch';
// export const DipSwitchIconSvg = DipSwitchIcon;

// import DirectionsIcon from 'react-material-icon-svg/dist/Directions';
// export const DirectionsIconSvg = DirectionsIcon;

// import DirectionsForkIcon from 'react-material-icon-svg/dist/DirectionsFork';
// export const DirectionsForkIconSvg = DirectionsForkIcon;

// import DiscIcon from 'react-material-icon-svg/dist/Disc';
// export const DiscIconSvg = DiscIcon;

// import DiscAlertIcon from 'react-material-icon-svg/dist/DiscAlert';
// export const DiscAlertIconSvg = DiscAlertIcon;

// import DiscPlayerIcon from 'react-material-icon-svg/dist/DiscPlayer';
// export const DiscPlayerIconSvg = DiscPlayerIcon;

// import DiscordIcon from 'react-material-icon-svg/dist/Discord';
// export const DiscordIconSvg = DiscordIcon;

// import DishwasherIcon from 'react-material-icon-svg/dist/Dishwasher';
// export const DishwasherIconSvg = DishwasherIcon;

// import DisqusIcon from 'react-material-icon-svg/dist/Disqus';
// export const DisqusIconSvg = DisqusIcon;

// import DisqusOutlineIcon from 'react-material-icon-svg/dist/DisqusOutline';
// export const DisqusOutlineIconSvg = DisqusOutlineIcon;

// import DivingFlippersIcon from 'react-material-icon-svg/dist/DivingFlippers';
// export const DivingFlippersIconSvg = DivingFlippersIcon;

// import DivingHelmetIcon from 'react-material-icon-svg/dist/DivingHelmet';
// export const DivingHelmetIconSvg = DivingHelmetIcon;

// import DivingScubaIcon from 'react-material-icon-svg/dist/DivingScuba';
// export const DivingScubaIconSvg = DivingScubaIcon;

// import DivingScubaFlagIcon from 'react-material-icon-svg/dist/DivingScubaFlag';
// export const DivingScubaFlagIconSvg = DivingScubaFlagIcon;

// import DivingScubaTankIcon from 'react-material-icon-svg/dist/DivingScubaTank';
// export const DivingScubaTankIconSvg = DivingScubaTankIcon;

// import DivingScubaTankMultipleIcon from 'react-material-icon-svg/dist/DivingScubaTankMultiple';
// export const DivingScubaTankMultipleIconSvg = DivingScubaTankMultipleIcon;

// import DivingSnorkelIcon from 'react-material-icon-svg/dist/DivingSnorkel';
// export const DivingSnorkelIconSvg = DivingSnorkelIcon;

// import DivisionIcon from 'react-material-icon-svg/dist/Division';
// export const DivisionIconSvg = DivisionIcon;

// import DivisionBoxIcon from 'react-material-icon-svg/dist/DivisionBox';
// export const DivisionBoxIconSvg = DivisionBoxIcon;

// import DlnaIcon from 'react-material-icon-svg/dist/Dlna';
// export const DlnaIconSvg = DlnaIcon;

// import DnaIcon from 'react-material-icon-svg/dist/Dna';
// export const DnaIconSvg = DnaIcon;

// import DnsIcon from 'react-material-icon-svg/dist/Dns';
// export const DnsIconSvg = DnsIcon;

// import DnsOutlineIcon from 'react-material-icon-svg/dist/DnsOutline';
// export const DnsOutlineIconSvg = DnsOutlineIcon;

// import DoNotDisturbIcon from 'react-material-icon-svg/dist/DoNotDisturb';
// export const DoNotDisturbIconSvg = DoNotDisturbIcon;

// import DoNotDisturbOffIcon from 'react-material-icon-svg/dist/DoNotDisturbOff';
// export const DoNotDisturbOffIconSvg = DoNotDisturbOffIcon;

// import DockerIcon from 'react-material-icon-svg/dist/Docker';
// export const DockerIconSvg = DockerIcon;

// import DoctorIcon from 'react-material-icon-svg/dist/Doctor';
// export const DoctorIconSvg = DoctorIcon;

// import DogIcon from 'react-material-icon-svg/dist/Dog';
// export const DogIconSvg = DogIcon;

// import DogServiceIcon from 'react-material-icon-svg/dist/DogService';
// export const DogServiceIconSvg = DogServiceIcon;

// import DogSideIcon from 'react-material-icon-svg/dist/DogSide';
// export const DogSideIconSvg = DogSideIcon;

// import DolbyIcon from 'react-material-icon-svg/dist/Dolby';
// export const DolbyIconSvg = DolbyIcon;

// import DollyIcon from 'react-material-icon-svg/dist/Dolly';
// export const DollyIconSvg = DollyIcon;

// import DomainIcon from 'react-material-icon-svg/dist/Domain';
// export const DomainIconSvg = DomainIcon;

// import DomainOffIcon from 'react-material-icon-svg/dist/DomainOff';
// export const DomainOffIconSvg = DomainOffIcon;

// import DonkeyIcon from 'react-material-icon-svg/dist/Donkey';
// export const DonkeyIconSvg = DonkeyIcon;

// import DoorIcon from 'react-material-icon-svg/dist/Door';
// export const DoorIconSvg = DoorIcon;

// import DoorClosedIcon from 'react-material-icon-svg/dist/DoorClosed';
// export const DoorClosedIconSvg = DoorClosedIcon;

// import DoorOpenIcon from 'react-material-icon-svg/dist/DoorOpen';
// export const DoorOpenIconSvg = DoorOpenIcon;

// import DoorbellVideoIcon from 'react-material-icon-svg/dist/DoorbellVideo';
// export const DoorbellVideoIconSvg = DoorbellVideoIcon;

// import DotNetIcon from 'react-material-icon-svg/dist/DotNet';
// export const DotNetIconSvg = DotNetIcon;

// import DotsHorizontalIcon from 'react-material-icon-svg/dist/DotsHorizontal';
// export const DotsHorizontalIconSvg = DotsHorizontalIcon;

// import DotsHorizontalCircleIcon from 'react-material-icon-svg/dist/DotsHorizontalCircle';
// export const DotsHorizontalCircleIconSvg = DotsHorizontalCircleIcon;

// import DotsHorizontalCircleOutlineIcon from 'react-material-icon-svg/dist/DotsHorizontalCircleOutline';
// export const DotsHorizontalCircleOutlineIconSvg = DotsHorizontalCircleOutlineIcon;

// import DotsVerticalIcon from 'react-material-icon-svg/dist/DotsVertical';
// export const DotsVerticalIconSvg = DotsVerticalIcon;

// import DotsVerticalCircleIcon from 'react-material-icon-svg/dist/DotsVerticalCircle';
// export const DotsVerticalCircleIconSvg = DotsVerticalCircleIcon;

// import DotsVerticalCircleOutlineIcon from 'react-material-icon-svg/dist/DotsVerticalCircleOutline';
// export const DotsVerticalCircleOutlineIconSvg = DotsVerticalCircleOutlineIcon;

// import DoubanIcon from 'react-material-icon-svg/dist/Douban';
// export const DoubanIconSvg = DoubanIcon;

// import DownloadIcon from 'react-material-icon-svg/dist/Download';
// export const DownloadIconSvg = DownloadIcon;

// import DownloadMultipleIcon from 'react-material-icon-svg/dist/DownloadMultiple';
// export const DownloadMultipleIconSvg = DownloadMultipleIcon;

// import DownloadNetworkIcon from 'react-material-icon-svg/dist/DownloadNetwork';
// export const DownloadNetworkIconSvg = DownloadNetworkIcon;

// import DownloadNetworkOutlineIcon from 'react-material-icon-svg/dist/DownloadNetworkOutline';
// export const DownloadNetworkOutlineIconSvg = DownloadNetworkOutlineIcon;

// import DownloadOutlineIcon from 'react-material-icon-svg/dist/DownloadOutline';
// export const DownloadOutlineIconSvg = DownloadOutlineIcon;

// import DragIcon from 'react-material-icon-svg/dist/Drag';
// export const DragIconSvg = DragIcon;

// import DragHorizontalIcon from 'react-material-icon-svg/dist/DragHorizontal';
// export const DragHorizontalIconSvg = DragHorizontalIcon;

// import DragVariantIcon from 'react-material-icon-svg/dist/DragVariant';
// export const DragVariantIconSvg = DragVariantIcon;

// import DragVerticalIcon from 'react-material-icon-svg/dist/DragVertical';
// export const DragVerticalIconSvg = DragVerticalIcon;

// import DramaMasksIcon from 'react-material-icon-svg/dist/DramaMasks';
// export const DramaMasksIconSvg = DramaMasksIcon;

// import DrawingIcon from 'react-material-icon-svg/dist/Drawing';
// export const DrawingIconSvg = DrawingIcon;

// import DrawingBoxIcon from 'react-material-icon-svg/dist/DrawingBox';
// export const DrawingBoxIconSvg = DrawingBoxIcon;

// import DribbbleIcon from 'react-material-icon-svg/dist/Dribbble';
// export const DribbbleIconSvg = DribbbleIcon;

// import DribbbleBoxIcon from 'react-material-icon-svg/dist/DribbbleBox';
// export const DribbbleBoxIconSvg = DribbbleBoxIcon;

// import DroneIcon from 'react-material-icon-svg/dist/Drone';
// export const DroneIconSvg = DroneIcon;

// import DropboxIcon from 'react-material-icon-svg/dist/Dropbox';
// export const DropboxIconSvg = DropboxIcon;

// import DrupalIcon from 'react-material-icon-svg/dist/Drupal';
// export const DrupalIconSvg = DrupalIcon;

// import DuckIcon from 'react-material-icon-svg/dist/Duck';
// export const DuckIconSvg = DuckIcon;

// import DumbbellIcon from 'react-material-icon-svg/dist/Dumbbell';
// export const DumbbellIconSvg = DumbbellIcon;

// import DumpTruckIcon from 'react-material-icon-svg/dist/DumpTruck';
// export const DumpTruckIconSvg = DumpTruckIcon;

// import EarHearingIcon from 'react-material-icon-svg/dist/EarHearing';
// export const EarHearingIconSvg = EarHearingIcon;

// import EarHearingOffIcon from 'react-material-icon-svg/dist/EarHearingOff';
// export const EarHearingOffIconSvg = EarHearingOffIcon;

// import EarthIcon from 'react-material-icon-svg/dist/Earth';
// export const EarthIconSvg = EarthIcon;

// import EarthBoxIcon from 'react-material-icon-svg/dist/EarthBox';
// export const EarthBoxIconSvg = EarthBoxIcon;

// import EarthBoxOffIcon from 'react-material-icon-svg/dist/EarthBoxOff';
// export const EarthBoxOffIconSvg = EarthBoxOffIcon;

// import EarthOffIcon from 'react-material-icon-svg/dist/EarthOff';
// export const EarthOffIconSvg = EarthOffIcon;

// import EdgeIcon from 'react-material-icon-svg/dist/Edge';
// export const EdgeIconSvg = EdgeIcon;

// import EggIcon from 'react-material-icon-svg/dist/Egg';
// export const EggIconSvg = EggIcon;

// import EggEasterIcon from 'react-material-icon-svg/dist/EggEaster';
// export const EggEasterIconSvg = EggEasterIcon;

// import EightTrackIcon from 'react-material-icon-svg/dist/EightTrack';
// export const EightTrackIconSvg = EightTrackIcon;

// import EjectIcon from 'react-material-icon-svg/dist/Eject';
// export const EjectIconSvg = EjectIcon;

// import EjectOutlineIcon from 'react-material-icon-svg/dist/EjectOutline';
// export const EjectOutlineIconSvg = EjectOutlineIcon;

// import ElectricSwitchIcon from 'react-material-icon-svg/dist/ElectricSwitch';
// export const ElectricSwitchIconSvg = ElectricSwitchIcon;

// import ElephantIcon from 'react-material-icon-svg/dist/Elephant';
// export const ElephantIconSvg = ElephantIcon;

// import ElevationDeclineIcon from 'react-material-icon-svg/dist/ElevationDecline';
// export const ElevationDeclineIconSvg = ElevationDeclineIcon;

// import ElevationRiseIcon from 'react-material-icon-svg/dist/ElevationRise';
// export const ElevationRiseIconSvg = ElevationRiseIcon;

// import ElevatorIcon from 'react-material-icon-svg/dist/Elevator';
// export const ElevatorIconSvg = ElevatorIcon;

// import EllipseIcon from 'react-material-icon-svg/dist/Ellipse';
// export const EllipseIconSvg = EllipseIcon;

// import EllipseOutlineIcon from 'react-material-icon-svg/dist/EllipseOutline';
// export const EllipseOutlineIconSvg = EllipseOutlineIcon;

import EmailIcon from 'react-material-icon-svg/dist/Email';
export const EmailIconSvg = EmailIcon;

// import EmailAlertIcon from 'react-material-icon-svg/dist/EmailAlert';
// export const EmailAlertIconSvg = EmailAlertIcon;

// import EmailBoxIcon from 'react-material-icon-svg/dist/EmailBox';
// export const EmailBoxIconSvg = EmailBoxIcon;

// import EmailCheckIcon from 'react-material-icon-svg/dist/EmailCheck';
// export const EmailCheckIconSvg = EmailCheckIcon;

// import EmailCheckOutlineIcon from 'react-material-icon-svg/dist/EmailCheckOutline';
// export const EmailCheckOutlineIconSvg = EmailCheckOutlineIcon;

// import EmailLockIcon from 'react-material-icon-svg/dist/EmailLock';
// export const EmailLockIconSvg = EmailLockIcon;

// import EmailMarkAsUnreadIcon from 'react-material-icon-svg/dist/EmailMarkAsUnread';
// export const EmailMarkAsUnreadIconSvg = EmailMarkAsUnreadIcon;

// import EmailOpenIcon from 'react-material-icon-svg/dist/EmailOpen';
// export const EmailOpenIconSvg = EmailOpenIcon;

// import EmailOpenOutlineIcon from 'react-material-icon-svg/dist/EmailOpenOutline';
// export const EmailOpenOutlineIconSvg = EmailOpenOutlineIcon;

// import EmailOutlineIcon from 'react-material-icon-svg/dist/EmailOutline';
// export const EmailOutlineIconSvg = EmailOutlineIcon;

// import EmailPlusIcon from 'react-material-icon-svg/dist/EmailPlus';
// export const EmailPlusIconSvg = EmailPlusIcon;

// import EmailPlusOutlineIcon from 'react-material-icon-svg/dist/EmailPlusOutline';
// export const EmailPlusOutlineIconSvg = EmailPlusOutlineIcon;

// import EmailSearchIcon from 'react-material-icon-svg/dist/EmailSearch';
// export const EmailSearchIconSvg = EmailSearchIcon;

// import EmailSearchOutlineIcon from 'react-material-icon-svg/dist/EmailSearchOutline';
// export const EmailSearchOutlineIconSvg = EmailSearchOutlineIcon;

// import EmailVariantIcon from 'react-material-icon-svg/dist/EmailVariant';
// export const EmailVariantIconSvg = EmailVariantIcon;

// import EmberIcon from 'react-material-icon-svg/dist/Ember';
// export const EmberIconSvg = EmberIcon;

// import EmbyIcon from 'react-material-icon-svg/dist/Emby';
// export const EmbyIconSvg = EmbyIcon;

// import EmoticonIcon from 'react-material-icon-svg/dist/Emoticon';
// export const EmoticonIconSvg = EmoticonIcon;

// import EmoticonAngryIcon from 'react-material-icon-svg/dist/EmoticonAngry';
// export const EmoticonAngryIconSvg = EmoticonAngryIcon;

// import EmoticonAngryOutlineIcon from 'react-material-icon-svg/dist/EmoticonAngryOutline';
// export const EmoticonAngryOutlineIconSvg = EmoticonAngryOutlineIcon;

// import EmoticonCoolIcon from 'react-material-icon-svg/dist/EmoticonCool';
// export const EmoticonCoolIconSvg = EmoticonCoolIcon;

// import EmoticonCoolOutlineIcon from 'react-material-icon-svg/dist/EmoticonCoolOutline';
// export const EmoticonCoolOutlineIconSvg = EmoticonCoolOutlineIcon;

// import EmoticonCryIcon from 'react-material-icon-svg/dist/EmoticonCry';
// export const EmoticonCryIconSvg = EmoticonCryIcon;

// import EmoticonCryOutlineIcon from 'react-material-icon-svg/dist/EmoticonCryOutline';
// export const EmoticonCryOutlineIconSvg = EmoticonCryOutlineIcon;

// import EmoticonDeadIcon from 'react-material-icon-svg/dist/EmoticonDead';
// export const EmoticonDeadIconSvg = EmoticonDeadIcon;

// import EmoticonDeadOutlineIcon from 'react-material-icon-svg/dist/EmoticonDeadOutline';
// export const EmoticonDeadOutlineIconSvg = EmoticonDeadOutlineIcon;

// import EmoticonDevilIcon from 'react-material-icon-svg/dist/EmoticonDevil';
// export const EmoticonDevilIconSvg = EmoticonDevilIcon;

// import EmoticonDevilOutlineIcon from 'react-material-icon-svg/dist/EmoticonDevilOutline';
// export const EmoticonDevilOutlineIconSvg = EmoticonDevilOutlineIcon;

// import EmoticonExcitedIcon from 'react-material-icon-svg/dist/EmoticonExcited';
// export const EmoticonExcitedIconSvg = EmoticonExcitedIcon;

// import EmoticonExcitedOutlineIcon from 'react-material-icon-svg/dist/EmoticonExcitedOutline';
// export const EmoticonExcitedOutlineIconSvg = EmoticonExcitedOutlineIcon;

// import EmoticonHappyIcon from 'react-material-icon-svg/dist/EmoticonHappy';
// export const EmoticonHappyIconSvg = EmoticonHappyIcon;

// import EmoticonHappyOutlineIcon from 'react-material-icon-svg/dist/EmoticonHappyOutline';
// export const EmoticonHappyOutlineIconSvg = EmoticonHappyOutlineIcon;

// import EmoticonKissIcon from 'react-material-icon-svg/dist/EmoticonKiss';
// export const EmoticonKissIconSvg = EmoticonKissIcon;

// import EmoticonKissOutlineIcon from 'react-material-icon-svg/dist/EmoticonKissOutline';
// export const EmoticonKissOutlineIconSvg = EmoticonKissOutlineIcon;

// import EmoticonNeutralIcon from 'react-material-icon-svg/dist/EmoticonNeutral';
// export const EmoticonNeutralIconSvg = EmoticonNeutralIcon;

// import EmoticonNeutralOutlineIcon from 'react-material-icon-svg/dist/EmoticonNeutralOutline';
// export const EmoticonNeutralOutlineIconSvg = EmoticonNeutralOutlineIcon;

// import EmoticonOutlineIcon from 'react-material-icon-svg/dist/EmoticonOutline';
// export const EmoticonOutlineIconSvg = EmoticonOutlineIcon;

// import EmoticonPoopIcon from 'react-material-icon-svg/dist/EmoticonPoop';
// export const EmoticonPoopIconSvg = EmoticonPoopIcon;

// import EmoticonPoopOutlineIcon from 'react-material-icon-svg/dist/EmoticonPoopOutline';
// export const EmoticonPoopOutlineIconSvg = EmoticonPoopOutlineIcon;

// import EmoticonSadIcon from 'react-material-icon-svg/dist/EmoticonSad';
// export const EmoticonSadIconSvg = EmoticonSadIcon;

// import EmoticonSadOutlineIcon from 'react-material-icon-svg/dist/EmoticonSadOutline';
// export const EmoticonSadOutlineIconSvg = EmoticonSadOutlineIcon;

// import EmoticonTongueIcon from 'react-material-icon-svg/dist/EmoticonTongue';
// export const EmoticonTongueIconSvg = EmoticonTongueIcon;

// import EmoticonTongueOutlineIcon from 'react-material-icon-svg/dist/EmoticonTongueOutline';
// export const EmoticonTongueOutlineIconSvg = EmoticonTongueOutlineIcon;

// import EmoticonWinkIcon from 'react-material-icon-svg/dist/EmoticonWink';
// export const EmoticonWinkIconSvg = EmoticonWinkIcon;

// import EmoticonWinkOutlineIcon from 'react-material-icon-svg/dist/EmoticonWinkOutline';
// export const EmoticonWinkOutlineIconSvg = EmoticonWinkOutlineIcon;

// import EngineIcon from 'react-material-icon-svg/dist/Engine';
// export const EngineIconSvg = EngineIcon;

// import EngineOffIcon from 'react-material-icon-svg/dist/EngineOff';
// export const EngineOffIconSvg = EngineOffIcon;

// import EngineOffOutlineIcon from 'react-material-icon-svg/dist/EngineOffOutline';
// export const EngineOffOutlineIconSvg = EngineOffOutlineIcon;

// import EngineOutlineIcon from 'react-material-icon-svg/dist/EngineOutline';
// export const EngineOutlineIconSvg = EngineOutlineIcon;

// import EqualIcon from 'react-material-icon-svg/dist/Equal';
// export const EqualIconSvg = EqualIcon;

// import EqualBoxIcon from 'react-material-icon-svg/dist/EqualBox';
// export const EqualBoxIconSvg = EqualBoxIcon;

// import EqualizerIcon from 'react-material-icon-svg/dist/Equalizer';
// export const EqualizerIconSvg = EqualizerIcon;

// import EqualizerOutlineIcon from 'react-material-icon-svg/dist/EqualizerOutline';
// export const EqualizerOutlineIconSvg = EqualizerOutlineIcon;

// import EraserIcon from 'react-material-icon-svg/dist/Eraser';
// export const EraserIconSvg = EraserIcon;

// import EraserVariantIcon from 'react-material-icon-svg/dist/EraserVariant';
// export const EraserVariantIconSvg = EraserVariantIcon;

// import EscalatorIcon from 'react-material-icon-svg/dist/Escalator';
// export const EscalatorIconSvg = EscalatorIcon;

// import EslintIcon from 'react-material-icon-svg/dist/Eslint';
// export const EslintIconSvg = EslintIcon;

// import EtIcon from 'react-material-icon-svg/dist/Et';
// export const EtIconSvg = EtIcon;

// import EthereumIcon from 'react-material-icon-svg/dist/Ethereum';
// export const EthereumIconSvg = EthereumIcon;

// import EthernetIcon from 'react-material-icon-svg/dist/Ethernet';
// export const EthernetIconSvg = EthernetIcon;

// import EthernetCableIcon from 'react-material-icon-svg/dist/EthernetCable';
// export const EthernetCableIconSvg = EthernetCableIcon;

// import EthernetCableOffIcon from 'react-material-icon-svg/dist/EthernetCableOff';
// export const EthernetCableOffIconSvg = EthernetCableOffIcon;

// import EtsyIcon from 'react-material-icon-svg/dist/Etsy';
// export const EtsyIconSvg = EtsyIcon;

// import EvStationIcon from 'react-material-icon-svg/dist/EvStation';
// export const EvStationIconSvg = EvStationIcon;

// import EventbriteIcon from 'react-material-icon-svg/dist/Eventbrite';
// export const EventbriteIconSvg = EventbriteIcon;

// import EvernoteIcon from 'react-material-icon-svg/dist/Evernote';
// export const EvernoteIconSvg = EvernoteIcon;

// import ExclamationIcon from 'react-material-icon-svg/dist/Exclamation';
// export const ExclamationIconSvg = ExclamationIcon;

// import ExitRunIcon from 'react-material-icon-svg/dist/ExitRun';
// export const ExitRunIconSvg = ExitRunIcon;

// import ExitToAppIcon from 'react-material-icon-svg/dist/ExitToApp';
// export const ExitToAppIconSvg = ExitToAppIcon;

// import ExpandAllIcon from 'react-material-icon-svg/dist/ExpandAll';
// export const ExpandAllIconSvg = ExpandAllIcon;

// import ExpandAllOutlineIcon from 'react-material-icon-svg/dist/ExpandAllOutline';
// export const ExpandAllOutlineIconSvg = ExpandAllOutlineIcon;

// import ExponentIcon from 'react-material-icon-svg/dist/Exponent';
// export const ExponentIconSvg = ExponentIcon;

// import ExponentBoxIcon from 'react-material-icon-svg/dist/ExponentBox';
// export const ExponentBoxIconSvg = ExponentBoxIcon;

// import ExportIcon from 'react-material-icon-svg/dist/Export';
// export const ExportIconSvg = ExportIcon;

// import ExportVariantIcon from 'react-material-icon-svg/dist/ExportVariant';
// export const ExportVariantIconSvg = ExportVariantIcon;

// import EyeIcon from 'react-material-icon-svg/dist/Eye';
// export const EyeIconSvg = EyeIcon;

// import EyeCheckIcon from 'react-material-icon-svg/dist/EyeCheck';
// export const EyeCheckIconSvg = EyeCheckIcon;

// import EyeCheckOutlineIcon from 'react-material-icon-svg/dist/EyeCheckOutline';
// export const EyeCheckOutlineIconSvg = EyeCheckOutlineIcon;

// import EyeCircleIcon from 'react-material-icon-svg/dist/EyeCircle';
// export const EyeCircleIconSvg = EyeCircleIcon;

// import EyeCircleOutlineIcon from 'react-material-icon-svg/dist/EyeCircleOutline';
// export const EyeCircleOutlineIconSvg = EyeCircleOutlineIcon;

// import EyeOffIcon from 'react-material-icon-svg/dist/EyeOff';
// export const EyeOffIconSvg = EyeOffIcon;

// import EyeOffOutlineIcon from 'react-material-icon-svg/dist/EyeOffOutline';
// export const EyeOffOutlineIconSvg = EyeOffOutlineIcon;

// import EyeOutlineIcon from 'react-material-icon-svg/dist/EyeOutline';
// export const EyeOutlineIconSvg = EyeOutlineIcon;

// import EyePlusIcon from 'react-material-icon-svg/dist/EyePlus';
// export const EyePlusIconSvg = EyePlusIcon;

// import EyePlusOutlineIcon from 'react-material-icon-svg/dist/EyePlusOutline';
// export const EyePlusOutlineIconSvg = EyePlusOutlineIcon;

// import EyeSettingsIcon from 'react-material-icon-svg/dist/EyeSettings';
// export const EyeSettingsIconSvg = EyeSettingsIcon;

// import EyeSettingsOutlineIcon from 'react-material-icon-svg/dist/EyeSettingsOutline';
// export const EyeSettingsOutlineIconSvg = EyeSettingsOutlineIcon;

// import EyedropperIcon from 'react-material-icon-svg/dist/Eyedropper';
// export const EyedropperIconSvg = EyedropperIcon;

// import EyedropperVariantIcon from 'react-material-icon-svg/dist/EyedropperVariant';
// export const EyedropperVariantIconSvg = EyedropperVariantIcon;

// import FaceIcon from 'react-material-icon-svg/dist/Face';
// export const FaceIconSvg = FaceIcon;

import FaceAgentIcon from 'react-material-icon-svg/dist/FaceAgent';
export const FaceAgentIconSvg = FaceAgentIcon;

// import FaceOutlineIcon from 'react-material-icon-svg/dist/FaceOutline';
// export const FaceOutlineIconSvg = FaceOutlineIcon;

// import FaceProfileIcon from 'react-material-icon-svg/dist/FaceProfile';
// export const FaceProfileIconSvg = FaceProfileIcon;

// import FaceRecognitionIcon from 'react-material-icon-svg/dist/FaceRecognition';
// export const FaceRecognitionIconSvg = FaceRecognitionIcon;

// import FacebookIcon from 'react-material-icon-svg/dist/Facebook';
// export const FacebookIconSvg = FacebookIcon;

// import FacebookBoxIcon from 'react-material-icon-svg/dist/FacebookBox';
// export const FacebookBoxIconSvg = FacebookBoxIcon;

// import FacebookMessengerIcon from 'react-material-icon-svg/dist/FacebookMessenger';
// export const FacebookMessengerIconSvg = FacebookMessengerIcon;

// import FacebookWorkplaceIcon from 'react-material-icon-svg/dist/FacebookWorkplace';
// export const FacebookWorkplaceIconSvg = FacebookWorkplaceIcon;

// import FactoryIcon from 'react-material-icon-svg/dist/Factory';
// export const FactoryIconSvg = FactoryIcon;

// import FanIcon from 'react-material-icon-svg/dist/Fan';
// export const FanIconSvg = FanIcon;

// import FanOffIcon from 'react-material-icon-svg/dist/FanOff';
// export const FanOffIconSvg = FanOffIcon;

// import FastForwardIcon from 'react-material-icon-svg/dist/FastForward';
// export const FastForwardIconSvg = FastForwardIcon;

// import FastForwardOutlineIcon from 'react-material-icon-svg/dist/FastForwardOutline';
// export const FastForwardOutlineIconSvg = FastForwardOutlineIcon;

// import FastForward_10Icon from 'react-material-icon-svg/dist/FastForward_10';
// export const FastForward_10IconSvg = FastForward_10Icon;

// import FastForward_30Icon from 'react-material-icon-svg/dist/FastForward_30';
// export const FastForward_30IconSvg = FastForward_30Icon;

// import FaxIcon from 'react-material-icon-svg/dist/Fax';
// export const FaxIconSvg = FaxIcon;

// import FeatherIcon from 'react-material-icon-svg/dist/Feather';
// export const FeatherIconSvg = FeatherIcon;

// import FeatureSearchIcon from 'react-material-icon-svg/dist/FeatureSearch';
// export const FeatureSearchIconSvg = FeatureSearchIcon;

// import FeatureSearchOutlineIcon from 'react-material-icon-svg/dist/FeatureSearchOutline';
// export const FeatureSearchOutlineIconSvg = FeatureSearchOutlineIcon;

// import FedoraIcon from 'react-material-icon-svg/dist/Fedora';
// export const FedoraIconSvg = FedoraIcon;

// import FerrisWheelIcon from 'react-material-icon-svg/dist/FerrisWheel';
// export const FerrisWheelIconSvg = FerrisWheelIcon;

// import FerryIcon from 'react-material-icon-svg/dist/Ferry';
// export const FerryIconSvg = FerryIcon;

// import FileIcon from 'react-material-icon-svg/dist/File';
// export const FileIconSvg = FileIcon;

// import FileAccountIcon from 'react-material-icon-svg/dist/FileAccount';
// export const FileAccountIconSvg = FileAccountIcon;

// import FileAlertIcon from 'react-material-icon-svg/dist/FileAlert';
// export const FileAlertIconSvg = FileAlertIcon;

// import FileAlertOutlineIcon from 'react-material-icon-svg/dist/FileAlertOutline';
// export const FileAlertOutlineIconSvg = FileAlertOutlineIcon;

// import FileCabinetIcon from 'react-material-icon-svg/dist/FileCabinet';
// export const FileCabinetIconSvg = FileCabinetIcon;

// import FileCancelIcon from 'react-material-icon-svg/dist/FileCancel';
// export const FileCancelIconSvg = FileCancelIcon;

// import FileCancelOutlineIcon from 'react-material-icon-svg/dist/FileCancelOutline';
// export const FileCancelOutlineIconSvg = FileCancelOutlineIcon;

// import FileChartIcon from 'react-material-icon-svg/dist/FileChart';
// export const FileChartIconSvg = FileChartIcon;

// import FileCheckIcon from 'react-material-icon-svg/dist/FileCheck';
// export const FileCheckIconSvg = FileCheckIcon;

// import FileCheckOutlineIcon from 'react-material-icon-svg/dist/FileCheckOutline';
// export const FileCheckOutlineIconSvg = FileCheckOutlineIcon;

// import FileCloudIcon from 'react-material-icon-svg/dist/FileCloud';
// export const FileCloudIconSvg = FileCloudIcon;

// import FileCompareIcon from 'react-material-icon-svg/dist/FileCompare';
// export const FileCompareIconSvg = FileCompareIcon;

// import FileDelimitedIcon from 'react-material-icon-svg/dist/FileDelimited';
// export const FileDelimitedIconSvg = FileDelimitedIcon;

// import FileDelimitedOutlineIcon from 'react-material-icon-svg/dist/FileDelimitedOutline';
// export const FileDelimitedOutlineIconSvg = FileDelimitedOutlineIcon;

// import FileDocumentIcon from 'react-material-icon-svg/dist/FileDocument';
// export const FileDocumentIconSvg = FileDocumentIcon;

// import FileDocumentBoxIcon from 'react-material-icon-svg/dist/FileDocumentBox';
// export const FileDocumentBoxIconSvg = FileDocumentBoxIcon;

// import FileDocumentBoxCheckIcon from 'react-material-icon-svg/dist/FileDocumentBoxCheck';
// export const FileDocumentBoxCheckIconSvg = FileDocumentBoxCheckIcon;

// import FileDocumentBoxCheckOutlineIcon from 'react-material-icon-svg/dist/FileDocumentBoxCheckOutline';
// export const FileDocumentBoxCheckOutlineIconSvg = FileDocumentBoxCheckOutlineIcon;

// import FileDocumentBoxMinusIcon from 'react-material-icon-svg/dist/FileDocumentBoxMinus';
// export const FileDocumentBoxMinusIconSvg = FileDocumentBoxMinusIcon;

// import FileDocumentBoxMinusOutlineIcon from 'react-material-icon-svg/dist/FileDocumentBoxMinusOutline';
// export const FileDocumentBoxMinusOutlineIconSvg = FileDocumentBoxMinusOutlineIcon;

// import FileDocumentBoxMultipleIcon from 'react-material-icon-svg/dist/FileDocumentBoxMultiple';
// export const FileDocumentBoxMultipleIconSvg = FileDocumentBoxMultipleIcon;

// import FileDocumentBoxMultipleOutlineIcon from 'react-material-icon-svg/dist/FileDocumentBoxMultipleOutline';
// export const FileDocumentBoxMultipleOutlineIconSvg = FileDocumentBoxMultipleOutlineIcon;

// import FileDocumentBoxOutlineIcon from 'react-material-icon-svg/dist/FileDocumentBoxOutline';
// export const FileDocumentBoxOutlineIconSvg = FileDocumentBoxOutlineIcon;

// import FileDocumentBoxPlusIcon from 'react-material-icon-svg/dist/FileDocumentBoxPlus';
// export const FileDocumentBoxPlusIconSvg = FileDocumentBoxPlusIcon;

// import FileDocumentBoxPlusOutlineIcon from 'react-material-icon-svg/dist/FileDocumentBoxPlusOutline';
// export const FileDocumentBoxPlusOutlineIconSvg = FileDocumentBoxPlusOutlineIcon;

// import FileDocumentBoxRemoveIcon from 'react-material-icon-svg/dist/FileDocumentBoxRemove';
// export const FileDocumentBoxRemoveIconSvg = FileDocumentBoxRemoveIcon;

// import FileDocumentBoxRemoveOutlineIcon from 'react-material-icon-svg/dist/FileDocumentBoxRemoveOutline';
// export const FileDocumentBoxRemoveOutlineIconSvg = FileDocumentBoxRemoveOutlineIcon;

// import FileDocumentBoxSearchIcon from 'react-material-icon-svg/dist/FileDocumentBoxSearch';
// export const FileDocumentBoxSearchIconSvg = FileDocumentBoxSearchIcon;

// import FileDocumentBoxSearchOutlineIcon from 'react-material-icon-svg/dist/FileDocumentBoxSearchOutline';
// export const FileDocumentBoxSearchOutlineIconSvg = FileDocumentBoxSearchOutlineIcon;

// import FileDocumentEditIcon from 'react-material-icon-svg/dist/FileDocumentEdit';
// export const FileDocumentEditIconSvg = FileDocumentEditIcon;

// import FileDocumentEditOutlineIcon from 'react-material-icon-svg/dist/FileDocumentEditOutline';
// export const FileDocumentEditOutlineIconSvg = FileDocumentEditOutlineIcon;

// import FileDocumentOutlineIcon from 'react-material-icon-svg/dist/FileDocumentOutline';
// export const FileDocumentOutlineIconSvg = FileDocumentOutlineIcon;

// import FileDownloadIcon from 'react-material-icon-svg/dist/FileDownload';
// export const FileDownloadIconSvg = FileDownloadIcon;

// import FileDownloadOutlineIcon from 'react-material-icon-svg/dist/FileDownloadOutline';
// export const FileDownloadOutlineIconSvg = FileDownloadOutlineIcon;

// import FileExcelIcon from 'react-material-icon-svg/dist/FileExcel';
// export const FileExcelIconSvg = FileExcelIcon;

// import FileExcelBoxIcon from 'react-material-icon-svg/dist/FileExcelBox';
// export const FileExcelBoxIconSvg = FileExcelBoxIcon;

// import FileExportIcon from 'react-material-icon-svg/dist/FileExport';
// export const FileExportIconSvg = FileExportIcon;

// import FileEyeIcon from 'react-material-icon-svg/dist/FileEye';
// export const FileEyeIconSvg = FileEyeIcon;

// import FileEyeOutlineIcon from 'react-material-icon-svg/dist/FileEyeOutline';
// export const FileEyeOutlineIconSvg = FileEyeOutlineIcon;

// import FileFindIcon from 'react-material-icon-svg/dist/FileFind';
// export const FileFindIconSvg = FileFindIcon;

// import FileFindOutlineIcon from 'react-material-icon-svg/dist/FileFindOutline';
// export const FileFindOutlineIconSvg = FileFindOutlineIcon;

// import FileHiddenIcon from 'react-material-icon-svg/dist/FileHidden';
// export const FileHiddenIconSvg = FileHiddenIcon;

// import FileImageIcon from 'react-material-icon-svg/dist/FileImage';
// export const FileImageIconSvg = FileImageIcon;

// import FileImageOutlineIcon from 'react-material-icon-svg/dist/FileImageOutline';
// export const FileImageOutlineIconSvg = FileImageOutlineIcon;

// import FileImportIcon from 'react-material-icon-svg/dist/FileImport';
// export const FileImportIconSvg = FileImportIcon;

// import FileLockIcon from 'react-material-icon-svg/dist/FileLock';
// export const FileLockIconSvg = FileLockIcon;

// import FileMoveIcon from 'react-material-icon-svg/dist/FileMove';
// export const FileMoveIconSvg = FileMoveIcon;

// import FileMultipleIcon from 'react-material-icon-svg/dist/FileMultiple';
// export const FileMultipleIconSvg = FileMultipleIcon;

// import FileMusicIcon from 'react-material-icon-svg/dist/FileMusic';
// export const FileMusicIconSvg = FileMusicIcon;

// import FileMusicOutlineIcon from 'react-material-icon-svg/dist/FileMusicOutline';
// export const FileMusicOutlineIconSvg = FileMusicOutlineIcon;

// import FileOutlineIcon from 'react-material-icon-svg/dist/FileOutline';
// export const FileOutlineIconSvg = FileOutlineIcon;

// import FilePdfIcon from 'react-material-icon-svg/dist/FilePdf';
// export const FilePdfIconSvg = FilePdfIcon;

// import FilePdfBoxIcon from 'react-material-icon-svg/dist/FilePdfBox';
// export const FilePdfBoxIconSvg = FilePdfBoxIcon;

// import FilePdfOutlineIcon from 'react-material-icon-svg/dist/FilePdfOutline';
// export const FilePdfOutlineIconSvg = FilePdfOutlineIcon;

// import FilePercentIcon from 'react-material-icon-svg/dist/FilePercent';
// export const FilePercentIconSvg = FilePercentIcon;

// import FilePlusIcon from 'react-material-icon-svg/dist/FilePlus';
// export const FilePlusIconSvg = FilePlusIcon;

// import FilePowerpointIcon from 'react-material-icon-svg/dist/FilePowerpoint';
// export const FilePowerpointIconSvg = FilePowerpointIcon;

// import FilePowerpointBoxIcon from 'react-material-icon-svg/dist/FilePowerpointBox';
// export const FilePowerpointBoxIconSvg = FilePowerpointBoxIcon;

// import FilePresentationBoxIcon from 'react-material-icon-svg/dist/FilePresentationBox';
// export const FilePresentationBoxIconSvg = FilePresentationBoxIcon;

// import FileQuestionIcon from 'react-material-icon-svg/dist/FileQuestion';
// export const FileQuestionIconSvg = FileQuestionIcon;

// import FileRemoveIcon from 'react-material-icon-svg/dist/FileRemove';
// export const FileRemoveIconSvg = FileRemoveIcon;

// import FileReplaceIcon from 'react-material-icon-svg/dist/FileReplace';
// export const FileReplaceIconSvg = FileReplaceIcon;

// import FileReplaceOutlineIcon from 'react-material-icon-svg/dist/FileReplaceOutline';
// export const FileReplaceOutlineIconSvg = FileReplaceOutlineIcon;

// import FileRestoreIcon from 'react-material-icon-svg/dist/FileRestore';
// export const FileRestoreIconSvg = FileRestoreIcon;

// import FileSearchIcon from 'react-material-icon-svg/dist/FileSearch';
// export const FileSearchIconSvg = FileSearchIcon;

// import FileSearchOutlineIcon from 'react-material-icon-svg/dist/FileSearchOutline';
// export const FileSearchOutlineIconSvg = FileSearchOutlineIcon;

// import FileSendIcon from 'react-material-icon-svg/dist/FileSend';
// export const FileSendIconSvg = FileSendIcon;

// import FileTableIcon from 'react-material-icon-svg/dist/FileTable';
// export const FileTableIconSvg = FileTableIcon;

// import FileTableOutlineIcon from 'react-material-icon-svg/dist/FileTableOutline';
// export const FileTableOutlineIconSvg = FileTableOutlineIcon;

// import FileTreeIcon from 'react-material-icon-svg/dist/FileTree';
// export const FileTreeIconSvg = FileTreeIcon;

// import FileUndoIcon from 'react-material-icon-svg/dist/FileUndo';
// export const FileUndoIconSvg = FileUndoIcon;

// import FileUploadIcon from 'react-material-icon-svg/dist/FileUpload';
// export const FileUploadIconSvg = FileUploadIcon;

// import FileUploadOutlineIcon from 'react-material-icon-svg/dist/FileUploadOutline';
// export const FileUploadOutlineIconSvg = FileUploadOutlineIcon;

// import FileVideoIcon from 'react-material-icon-svg/dist/FileVideo';
// export const FileVideoIconSvg = FileVideoIcon;

// import FileVideoOutlineIcon from 'react-material-icon-svg/dist/FileVideoOutline';
// export const FileVideoOutlineIconSvg = FileVideoOutlineIcon;

// import FileWordIcon from 'react-material-icon-svg/dist/FileWord';
// export const FileWordIconSvg = FileWordIcon;

// import FileWordBoxIcon from 'react-material-icon-svg/dist/FileWordBox';
// export const FileWordBoxIconSvg = FileWordBoxIcon;

// import FileXmlIcon from 'react-material-icon-svg/dist/FileXml';
// export const FileXmlIconSvg = FileXmlIcon;

// import FilmIcon from 'react-material-icon-svg/dist/Film';
// export const FilmIconSvg = FilmIcon;

// import FilmstripIcon from 'react-material-icon-svg/dist/Filmstrip';
// export const FilmstripIconSvg = FilmstripIcon;

// import FilmstripOffIcon from 'react-material-icon-svg/dist/FilmstripOff';
// export const FilmstripOffIconSvg = FilmstripOffIcon;

// import FilterIcon from 'react-material-icon-svg/dist/Filter';
// export const FilterIconSvg = FilterIcon;

// import FilterOutlineIcon from 'react-material-icon-svg/dist/FilterOutline';
// export const FilterOutlineIconSvg = FilterOutlineIcon;

// import FilterRemoveIcon from 'react-material-icon-svg/dist/FilterRemove';
// export const FilterRemoveIconSvg = FilterRemoveIcon;

// import FilterRemoveOutlineIcon from 'react-material-icon-svg/dist/FilterRemoveOutline';
// export const FilterRemoveOutlineIconSvg = FilterRemoveOutlineIcon;

// import FilterVariantIcon from 'react-material-icon-svg/dist/FilterVariant';
// export const FilterVariantIconSvg = FilterVariantIcon;

// import FinanceIcon from 'react-material-icon-svg/dist/Finance';
// export const FinanceIconSvg = FinanceIcon;

// import FindReplaceIcon from 'react-material-icon-svg/dist/FindReplace';
// export const FindReplaceIconSvg = FindReplaceIcon;

// import FingerprintIcon from 'react-material-icon-svg/dist/Fingerprint';
// export const FingerprintIconSvg = FingerprintIcon;

// import FingerprintOffIcon from 'react-material-icon-svg/dist/FingerprintOff';
// export const FingerprintOffIconSvg = FingerprintOffIcon;

// import FireIcon from 'react-material-icon-svg/dist/Fire';
// export const FireIconSvg = FireIcon;

// import FireTruckIcon from 'react-material-icon-svg/dist/FireTruck';
// export const FireTruckIconSvg = FireTruckIcon;

// import FirebaseIcon from 'react-material-icon-svg/dist/Firebase';
// export const FirebaseIconSvg = FirebaseIcon;

// import FirefoxIcon from 'react-material-icon-svg/dist/Firefox';
// export const FirefoxIconSvg = FirefoxIcon;

// import FireplaceIcon from 'react-material-icon-svg/dist/Fireplace';
// export const FireplaceIconSvg = FireplaceIcon;

// import FireplaceOffIcon from 'react-material-icon-svg/dist/FireplaceOff';
// export const FireplaceOffIconSvg = FireplaceOffIcon;

// import FireworkIcon from 'react-material-icon-svg/dist/Firework';
// export const FireworkIconSvg = FireworkIcon;

// import FishIcon from 'react-material-icon-svg/dist/Fish';
// export const FishIconSvg = FishIcon;

// import FlagIcon from 'react-material-icon-svg/dist/Flag';
// export const FlagIconSvg = FlagIcon;

// import FlagCheckeredIcon from 'react-material-icon-svg/dist/FlagCheckered';
// export const FlagCheckeredIconSvg = FlagCheckeredIcon;

// import FlagMinusIcon from 'react-material-icon-svg/dist/FlagMinus';
// export const FlagMinusIconSvg = FlagMinusIcon;

// import FlagOutlineIcon from 'react-material-icon-svg/dist/FlagOutline';
// export const FlagOutlineIconSvg = FlagOutlineIcon;

// import FlagPlusIcon from 'react-material-icon-svg/dist/FlagPlus';
// export const FlagPlusIconSvg = FlagPlusIcon;

// import FlagRemoveIcon from 'react-material-icon-svg/dist/FlagRemove';
// export const FlagRemoveIconSvg = FlagRemoveIcon;

// import FlagTriangleIcon from 'react-material-icon-svg/dist/FlagTriangle';
// export const FlagTriangleIconSvg = FlagTriangleIcon;

// import FlagVariantIcon from 'react-material-icon-svg/dist/FlagVariant';
// export const FlagVariantIconSvg = FlagVariantIcon;

// import FlagVariantOutlineIcon from 'react-material-icon-svg/dist/FlagVariantOutline';
// export const FlagVariantOutlineIconSvg = FlagVariantOutlineIcon;

// import FlareIcon from 'react-material-icon-svg/dist/Flare';
// export const FlareIconSvg = FlareIcon;

// import FlashIcon from 'react-material-icon-svg/dist/Flash';
// export const FlashIconSvg = FlashIcon;

// import FlashAutoIcon from 'react-material-icon-svg/dist/FlashAuto';
// export const FlashAutoIconSvg = FlashAutoIcon;

// import FlashCircleIcon from 'react-material-icon-svg/dist/FlashCircle';
// export const FlashCircleIconSvg = FlashCircleIcon;

// import FlashOffIcon from 'react-material-icon-svg/dist/FlashOff';
// export const FlashOffIconSvg = FlashOffIcon;

// import FlashOutlineIcon from 'react-material-icon-svg/dist/FlashOutline';
// export const FlashOutlineIconSvg = FlashOutlineIcon;

// import FlashRedEyeIcon from 'react-material-icon-svg/dist/FlashRedEye';
// export const FlashRedEyeIconSvg = FlashRedEyeIcon;

// import FlashlightIcon from 'react-material-icon-svg/dist/Flashlight';
// export const FlashlightIconSvg = FlashlightIcon;

// import FlashlightOffIcon from 'react-material-icon-svg/dist/FlashlightOff';
// export const FlashlightOffIconSvg = FlashlightOffIcon;

// import FlaskIcon from 'react-material-icon-svg/dist/Flask';
// export const FlaskIconSvg = FlaskIcon;

// import FlaskEmptyIcon from 'react-material-icon-svg/dist/FlaskEmpty';
// export const FlaskEmptyIconSvg = FlaskEmptyIcon;

// import FlaskEmptyOutlineIcon from 'react-material-icon-svg/dist/FlaskEmptyOutline';
// export const FlaskEmptyOutlineIconSvg = FlaskEmptyOutlineIcon;

// import FlaskOutlineIcon from 'react-material-icon-svg/dist/FlaskOutline';
// export const FlaskOutlineIconSvg = FlaskOutlineIcon;

// import FlattrIcon from 'react-material-icon-svg/dist/Flattr';
// export const FlattrIconSvg = FlattrIcon;

// import FlickrIcon from 'react-material-icon-svg/dist/Flickr';
// export const FlickrIconSvg = FlickrIcon;

// import FlipToBackIcon from 'react-material-icon-svg/dist/FlipToBack';
// export const FlipToBackIconSvg = FlipToBackIcon;

// import FlipToFrontIcon from 'react-material-icon-svg/dist/FlipToFront';
// export const FlipToFrontIconSvg = FlipToFrontIcon;

// import FloorLampIcon from 'react-material-icon-svg/dist/FloorLamp';
// export const FloorLampIconSvg = FloorLampIcon;

// import FloorPlanIcon from 'react-material-icon-svg/dist/FloorPlan';
// export const FloorPlanIconSvg = FloorPlanIcon;

// import FloppyIcon from 'react-material-icon-svg/dist/Floppy';
// export const FloppyIconSvg = FloppyIcon;

// import FloppyVariantIcon from 'react-material-icon-svg/dist/FloppyVariant';
// export const FloppyVariantIconSvg = FloppyVariantIcon;

// import FlowerIcon from 'react-material-icon-svg/dist/Flower';
// export const FlowerIconSvg = FlowerIcon;

// import FlowerOutlineIcon from 'react-material-icon-svg/dist/FlowerOutline';
// export const FlowerOutlineIconSvg = FlowerOutlineIcon;

// import FlowerPoppyIcon from 'react-material-icon-svg/dist/FlowerPoppy';
// export const FlowerPoppyIconSvg = FlowerPoppyIcon;

// import FlowerTulipIcon from 'react-material-icon-svg/dist/FlowerTulip';
// export const FlowerTulipIconSvg = FlowerTulipIcon;

// import FlowerTulipOutlineIcon from 'react-material-icon-svg/dist/FlowerTulipOutline';
// export const FlowerTulipOutlineIconSvg = FlowerTulipOutlineIcon;

// import FolderIcon from 'react-material-icon-svg/dist/Folder';
// export const FolderIconSvg = FolderIcon;

// import FolderAccountIcon from 'react-material-icon-svg/dist/FolderAccount';
// export const FolderAccountIconSvg = FolderAccountIcon;

// import FolderAccountOutlineIcon from 'react-material-icon-svg/dist/FolderAccountOutline';
// export const FolderAccountOutlineIconSvg = FolderAccountOutlineIcon;

// import FolderAlertIcon from 'react-material-icon-svg/dist/FolderAlert';
// export const FolderAlertIconSvg = FolderAlertIcon;

// import FolderAlertOutlineIcon from 'react-material-icon-svg/dist/FolderAlertOutline';
// export const FolderAlertOutlineIconSvg = FolderAlertOutlineIcon;

// import FolderClockIcon from 'react-material-icon-svg/dist/FolderClock';
// export const FolderClockIconSvg = FolderClockIcon;

// import FolderClockOutlineIcon from 'react-material-icon-svg/dist/FolderClockOutline';
// export const FolderClockOutlineIconSvg = FolderClockOutlineIcon;

// import FolderDownloadIcon from 'react-material-icon-svg/dist/FolderDownload';
// export const FolderDownloadIconSvg = FolderDownloadIcon;

// import FolderEditIcon from 'react-material-icon-svg/dist/FolderEdit';
// export const FolderEditIconSvg = FolderEditIcon;

// import FolderEditOutlineIcon from 'react-material-icon-svg/dist/FolderEditOutline';
// export const FolderEditOutlineIconSvg = FolderEditOutlineIcon;

// import FolderGoogleDriveIcon from 'react-material-icon-svg/dist/FolderGoogleDrive';
// export const FolderGoogleDriveIconSvg = FolderGoogleDriveIcon;

// import FolderImageIcon from 'react-material-icon-svg/dist/FolderImage';
// export const FolderImageIconSvg = FolderImageIcon;

// import FolderKeyIcon from 'react-material-icon-svg/dist/FolderKey';
// export const FolderKeyIconSvg = FolderKeyIcon;

// import FolderKeyNetworkIcon from 'react-material-icon-svg/dist/FolderKeyNetwork';
// export const FolderKeyNetworkIconSvg = FolderKeyNetworkIcon;

// import FolderKeyNetworkOutlineIcon from 'react-material-icon-svg/dist/FolderKeyNetworkOutline';
// export const FolderKeyNetworkOutlineIconSvg = FolderKeyNetworkOutlineIcon;

// import FolderLockIcon from 'react-material-icon-svg/dist/FolderLock';
// export const FolderLockIconSvg = FolderLockIcon;

// import FolderLockOpenIcon from 'react-material-icon-svg/dist/FolderLockOpen';
// export const FolderLockOpenIconSvg = FolderLockOpenIcon;

// import FolderMoveIcon from 'react-material-icon-svg/dist/FolderMove';
// export const FolderMoveIconSvg = FolderMoveIcon;

// import FolderMultipleIcon from 'react-material-icon-svg/dist/FolderMultiple';
// export const FolderMultipleIconSvg = FolderMultipleIcon;

// import FolderMultipleImageIcon from 'react-material-icon-svg/dist/FolderMultipleImage';
// export const FolderMultipleImageIconSvg = FolderMultipleImageIcon;

// import FolderMultipleOutlineIcon from 'react-material-icon-svg/dist/FolderMultipleOutline';
// export const FolderMultipleOutlineIconSvg = FolderMultipleOutlineIcon;

// import FolderNetworkIcon from 'react-material-icon-svg/dist/FolderNetwork';
// export const FolderNetworkIconSvg = FolderNetworkIcon;

// import FolderNetworkOutlineIcon from 'react-material-icon-svg/dist/FolderNetworkOutline';
// export const FolderNetworkOutlineIconSvg = FolderNetworkOutlineIcon;

// import FolderOpenIcon from 'react-material-icon-svg/dist/FolderOpen';
// export const FolderOpenIconSvg = FolderOpenIcon;

// import FolderOpenOutlineIcon from 'react-material-icon-svg/dist/FolderOpenOutline';
// export const FolderOpenOutlineIconSvg = FolderOpenOutlineIcon;

// import FolderOutlineIcon from 'react-material-icon-svg/dist/FolderOutline';
// export const FolderOutlineIconSvg = FolderOutlineIcon;

// import FolderPlusIcon from 'react-material-icon-svg/dist/FolderPlus';
// export const FolderPlusIconSvg = FolderPlusIcon;

// import FolderPlusOutlineIcon from 'react-material-icon-svg/dist/FolderPlusOutline';
// export const FolderPlusOutlineIconSvg = FolderPlusOutlineIcon;

// import FolderPoundIcon from 'react-material-icon-svg/dist/FolderPound';
// export const FolderPoundIconSvg = FolderPoundIcon;

// import FolderPoundOutlineIcon from 'react-material-icon-svg/dist/FolderPoundOutline';
// export const FolderPoundOutlineIconSvg = FolderPoundOutlineIcon;

// import FolderRemoveIcon from 'react-material-icon-svg/dist/FolderRemove';
// export const FolderRemoveIconSvg = FolderRemoveIcon;

// import FolderRemoveOutlineIcon from 'react-material-icon-svg/dist/FolderRemoveOutline';
// export const FolderRemoveOutlineIconSvg = FolderRemoveOutlineIcon;

// import FolderSearchIcon from 'react-material-icon-svg/dist/FolderSearch';
// export const FolderSearchIconSvg = FolderSearchIcon;

// import FolderSearchOutlineIcon from 'react-material-icon-svg/dist/FolderSearchOutline';
// export const FolderSearchOutlineIconSvg = FolderSearchOutlineIcon;

// import FolderStarIcon from 'react-material-icon-svg/dist/FolderStar';
// export const FolderStarIconSvg = FolderStarIcon;

// import FolderStarOutlineIcon from 'react-material-icon-svg/dist/FolderStarOutline';
// export const FolderStarOutlineIconSvg = FolderStarOutlineIcon;

// import FolderSyncIcon from 'react-material-icon-svg/dist/FolderSync';
// export const FolderSyncIconSvg = FolderSyncIcon;

// import FolderSyncOutlineIcon from 'react-material-icon-svg/dist/FolderSyncOutline';
// export const FolderSyncOutlineIconSvg = FolderSyncOutlineIcon;

// import FolderTextIcon from 'react-material-icon-svg/dist/FolderText';
// export const FolderTextIconSvg = FolderTextIcon;

// import FolderTextOutlineIcon from 'react-material-icon-svg/dist/FolderTextOutline';
// export const FolderTextOutlineIconSvg = FolderTextOutlineIcon;

// import FolderUploadIcon from 'react-material-icon-svg/dist/FolderUpload';
// export const FolderUploadIconSvg = FolderUploadIcon;

// import FontAwesomeIcon from 'react-material-icon-svg/dist/FontAwesome';
// export const FontAwesomeIconSvg = FontAwesomeIcon;

// import FoodIcon from 'react-material-icon-svg/dist/Food';
// export const FoodIconSvg = FoodIcon;

// import FoodAppleIcon from 'react-material-icon-svg/dist/FoodApple';
// export const FoodAppleIconSvg = FoodAppleIcon;

// import FoodAppleOutlineIcon from 'react-material-icon-svg/dist/FoodAppleOutline';
// export const FoodAppleOutlineIconSvg = FoodAppleOutlineIcon;

// import FoodCroissantIcon from 'react-material-icon-svg/dist/FoodCroissant';
// export const FoodCroissantIconSvg = FoodCroissantIcon;

// import FoodForkDrinkIcon from 'react-material-icon-svg/dist/FoodForkDrink';
// export const FoodForkDrinkIconSvg = FoodForkDrinkIcon;

// import FoodOffIcon from 'react-material-icon-svg/dist/FoodOff';
// export const FoodOffIconSvg = FoodOffIcon;

// import FoodVariantIcon from 'react-material-icon-svg/dist/FoodVariant';
// export const FoodVariantIconSvg = FoodVariantIcon;

// import FootballIcon from 'react-material-icon-svg/dist/Football';
// export const FootballIconSvg = FootballIcon;

// import FootballAustralianIcon from 'react-material-icon-svg/dist/FootballAustralian';
// export const FootballAustralianIconSvg = FootballAustralianIcon;

// import FootballHelmetIcon from 'react-material-icon-svg/dist/FootballHelmet';
// export const FootballHelmetIconSvg = FootballHelmetIcon;

// import ForkliftIcon from 'react-material-icon-svg/dist/Forklift';
// export const ForkliftIconSvg = ForkliftIcon;

// import FormatAlignBottomIcon from 'react-material-icon-svg/dist/FormatAlignBottom';
// export const FormatAlignBottomIconSvg = FormatAlignBottomIcon;

// import FormatAlignCenterIcon from 'react-material-icon-svg/dist/FormatAlignCenter';
// export const FormatAlignCenterIconSvg = FormatAlignCenterIcon;

// import FormatAlignJustifyIcon from 'react-material-icon-svg/dist/FormatAlignJustify';
// export const FormatAlignJustifyIconSvg = FormatAlignJustifyIcon;

// import FormatAlignLeftIcon from 'react-material-icon-svg/dist/FormatAlignLeft';
// export const FormatAlignLeftIconSvg = FormatAlignLeftIcon;

// import FormatAlignMiddleIcon from 'react-material-icon-svg/dist/FormatAlignMiddle';
// export const FormatAlignMiddleIconSvg = FormatAlignMiddleIcon;

// import FormatAlignRightIcon from 'react-material-icon-svg/dist/FormatAlignRight';
// export const FormatAlignRightIconSvg = FormatAlignRightIcon;

// import FormatAlignTopIcon from 'react-material-icon-svg/dist/FormatAlignTop';
// export const FormatAlignTopIconSvg = FormatAlignTopIcon;

// import FormatAnnotationMinusIcon from 'react-material-icon-svg/dist/FormatAnnotationMinus';
// export const FormatAnnotationMinusIconSvg = FormatAnnotationMinusIcon;

// import FormatAnnotationPlusIcon from 'react-material-icon-svg/dist/FormatAnnotationPlus';
// export const FormatAnnotationPlusIconSvg = FormatAnnotationPlusIcon;

// import FormatBoldIcon from 'react-material-icon-svg/dist/FormatBold';
// export const FormatBoldIconSvg = FormatBoldIcon;

// import FormatClearIcon from 'react-material-icon-svg/dist/FormatClear';
// export const FormatClearIconSvg = FormatClearIcon;

// import FormatColorFillIcon from 'react-material-icon-svg/dist/FormatColorFill';
// export const FormatColorFillIconSvg = FormatColorFillIcon;

// import FormatColorHighlightIcon from 'react-material-icon-svg/dist/FormatColorHighlight';
// export const FormatColorHighlightIconSvg = FormatColorHighlightIcon;

// import FormatColorTextIcon from 'react-material-icon-svg/dist/FormatColorText';
// export const FormatColorTextIconSvg = FormatColorTextIcon;

// import FormatColumnsIcon from 'react-material-icon-svg/dist/FormatColumns';
// export const FormatColumnsIconSvg = FormatColumnsIcon;

// import FormatFloatCenterIcon from 'react-material-icon-svg/dist/FormatFloatCenter';
// export const FormatFloatCenterIconSvg = FormatFloatCenterIcon;

// import FormatFloatLeftIcon from 'react-material-icon-svg/dist/FormatFloatLeft';
// export const FormatFloatLeftIconSvg = FormatFloatLeftIcon;

// import FormatFloatNoneIcon from 'react-material-icon-svg/dist/FormatFloatNone';
// export const FormatFloatNoneIconSvg = FormatFloatNoneIcon;

// import FormatFloatRightIcon from 'react-material-icon-svg/dist/FormatFloatRight';
// export const FormatFloatRightIconSvg = FormatFloatRightIcon;

// import FormatFontIcon from 'react-material-icon-svg/dist/FormatFont';
// export const FormatFontIconSvg = FormatFontIcon;

// import FormatFontSizeDecreaseIcon from 'react-material-icon-svg/dist/FormatFontSizeDecrease';
// export const FormatFontSizeDecreaseIconSvg = FormatFontSizeDecreaseIcon;

// import FormatFontSizeIncreaseIcon from 'react-material-icon-svg/dist/FormatFontSizeIncrease';
// export const FormatFontSizeIncreaseIconSvg = FormatFontSizeIncreaseIcon;

// import FormatHeaderDecreaseIcon from 'react-material-icon-svg/dist/FormatHeaderDecrease';
// export const FormatHeaderDecreaseIconSvg = FormatHeaderDecreaseIcon;

// import FormatHeaderEqualIcon from 'react-material-icon-svg/dist/FormatHeaderEqual';
// export const FormatHeaderEqualIconSvg = FormatHeaderEqualIcon;

// import FormatHeaderIncreaseIcon from 'react-material-icon-svg/dist/FormatHeaderIncrease';
// export const FormatHeaderIncreaseIconSvg = FormatHeaderIncreaseIcon;

// import FormatHeaderPoundIcon from 'react-material-icon-svg/dist/FormatHeaderPound';
// export const FormatHeaderPoundIconSvg = FormatHeaderPoundIcon;

// import FormatHeader_1Icon from 'react-material-icon-svg/dist/FormatHeader_1';
// export const FormatHeader_1IconSvg = FormatHeader_1Icon;

// import FormatHeader_2Icon from 'react-material-icon-svg/dist/FormatHeader_2';
// export const FormatHeader_2IconSvg = FormatHeader_2Icon;

// import FormatHeader_3Icon from 'react-material-icon-svg/dist/FormatHeader_3';
// export const FormatHeader_3IconSvg = FormatHeader_3Icon;

// import FormatHeader_4Icon from 'react-material-icon-svg/dist/FormatHeader_4';
// export const FormatHeader_4IconSvg = FormatHeader_4Icon;

// import FormatHeader_5Icon from 'react-material-icon-svg/dist/FormatHeader_5';
// export const FormatHeader_5IconSvg = FormatHeader_5Icon;

// import FormatHeader_6Icon from 'react-material-icon-svg/dist/FormatHeader_6';
// export const FormatHeader_6IconSvg = FormatHeader_6Icon;

// import FormatHorizontalAlignCenterIcon from 'react-material-icon-svg/dist/FormatHorizontalAlignCenter';
// export const FormatHorizontalAlignCenterIconSvg = FormatHorizontalAlignCenterIcon;

// import FormatHorizontalAlignLeftIcon from 'react-material-icon-svg/dist/FormatHorizontalAlignLeft';
// export const FormatHorizontalAlignLeftIconSvg = FormatHorizontalAlignLeftIcon;

// import FormatHorizontalAlignRightIcon from 'react-material-icon-svg/dist/FormatHorizontalAlignRight';
// export const FormatHorizontalAlignRightIconSvg = FormatHorizontalAlignRightIcon;

// import FormatIndentDecreaseIcon from 'react-material-icon-svg/dist/FormatIndentDecrease';
// export const FormatIndentDecreaseIconSvg = FormatIndentDecreaseIcon;

// import FormatIndentIncreaseIcon from 'react-material-icon-svg/dist/FormatIndentIncrease';
// export const FormatIndentIncreaseIconSvg = FormatIndentIncreaseIcon;

// import FormatItalicIcon from 'react-material-icon-svg/dist/FormatItalic';
// export const FormatItalicIconSvg = FormatItalicIcon;

// import FormatLetterCaseIcon from 'react-material-icon-svg/dist/FormatLetterCase';
// export const FormatLetterCaseIconSvg = FormatLetterCaseIcon;

// import FormatLetterCaseLowerIcon from 'react-material-icon-svg/dist/FormatLetterCaseLower';
// export const FormatLetterCaseLowerIconSvg = FormatLetterCaseLowerIcon;

// import FormatLetterCaseUpperIcon from 'react-material-icon-svg/dist/FormatLetterCaseUpper';
// export const FormatLetterCaseUpperIconSvg = FormatLetterCaseUpperIcon;

// import FormatLineSpacingIcon from 'react-material-icon-svg/dist/FormatLineSpacing';
// export const FormatLineSpacingIconSvg = FormatLineSpacingIcon;

// import FormatLineStyleIcon from 'react-material-icon-svg/dist/FormatLineStyle';
// export const FormatLineStyleIconSvg = FormatLineStyleIcon;

// import FormatLineWeightIcon from 'react-material-icon-svg/dist/FormatLineWeight';
// export const FormatLineWeightIconSvg = FormatLineWeightIcon;

// import FormatListBulletedIcon from 'react-material-icon-svg/dist/FormatListBulleted';
// export const FormatListBulletedIconSvg = FormatListBulletedIcon;

// import FormatListBulletedSquareIcon from 'react-material-icon-svg/dist/FormatListBulletedSquare';
// export const FormatListBulletedSquareIconSvg = FormatListBulletedSquareIcon;

// import FormatListBulletedTypeIcon from 'react-material-icon-svg/dist/FormatListBulletedType';
// export const FormatListBulletedTypeIconSvg = FormatListBulletedTypeIcon;

// import FormatListCheckboxIcon from 'react-material-icon-svg/dist/FormatListCheckbox';
// export const FormatListCheckboxIconSvg = FormatListCheckboxIcon;

// import FormatListChecksIcon from 'react-material-icon-svg/dist/FormatListChecks';
// export const FormatListChecksIconSvg = FormatListChecksIcon;

// import FormatListNumberedIcon from 'react-material-icon-svg/dist/FormatListNumbered';
// export const FormatListNumberedIconSvg = FormatListNumberedIcon;

// import FormatListNumberedRtlIcon from 'react-material-icon-svg/dist/FormatListNumberedRtl';
// export const FormatListNumberedRtlIconSvg = FormatListNumberedRtlIcon;

// import FormatListTriangleIcon from 'react-material-icon-svg/dist/FormatListTriangle';
// export const FormatListTriangleIconSvg = FormatListTriangleIcon;

// import FormatOverlineIcon from 'react-material-icon-svg/dist/FormatOverline';
// export const FormatOverlineIconSvg = FormatOverlineIcon;

// import FormatPageBreakIcon from 'react-material-icon-svg/dist/FormatPageBreak';
// export const FormatPageBreakIconSvg = FormatPageBreakIcon;

// import FormatPaintIcon from 'react-material-icon-svg/dist/FormatPaint';
// export const FormatPaintIconSvg = FormatPaintIcon;

// import FormatParagraphIcon from 'react-material-icon-svg/dist/FormatParagraph';
// export const FormatParagraphIconSvg = FormatParagraphIcon;

// import FormatPilcrowIcon from 'react-material-icon-svg/dist/FormatPilcrow';
// export const FormatPilcrowIconSvg = FormatPilcrowIcon;

// import FormatQuoteCloseIcon from 'react-material-icon-svg/dist/FormatQuoteClose';
// export const FormatQuoteCloseIconSvg = FormatQuoteCloseIcon;

// import FormatQuoteOpenIcon from 'react-material-icon-svg/dist/FormatQuoteOpen';
// export const FormatQuoteOpenIconSvg = FormatQuoteOpenIcon;

// import FormatRotate_90Icon from 'react-material-icon-svg/dist/FormatRotate_90';
// export const FormatRotate_90IconSvg = FormatRotate_90Icon;

// import FormatSectionIcon from 'react-material-icon-svg/dist/FormatSection';
// export const FormatSectionIconSvg = FormatSectionIcon;

// import FormatSizeIcon from 'react-material-icon-svg/dist/FormatSize';
// export const FormatSizeIconSvg = FormatSizeIcon;

// import FormatStrikethroughIcon from 'react-material-icon-svg/dist/FormatStrikethrough';
// export const FormatStrikethroughIconSvg = FormatStrikethroughIcon;

// import FormatStrikethroughVariantIcon from 'react-material-icon-svg/dist/FormatStrikethroughVariant';
// export const FormatStrikethroughVariantIconSvg = FormatStrikethroughVariantIcon;

// import FormatSubscriptIcon from 'react-material-icon-svg/dist/FormatSubscript';
// export const FormatSubscriptIconSvg = FormatSubscriptIcon;

// import FormatSuperscriptIcon from 'react-material-icon-svg/dist/FormatSuperscript';
// export const FormatSuperscriptIconSvg = FormatSuperscriptIcon;

// import FormatTextIcon from 'react-material-icon-svg/dist/FormatText';
// export const FormatTextIconSvg = FormatTextIcon;

// import FormatTextRotationDownIcon from 'react-material-icon-svg/dist/FormatTextRotationDown';
// export const FormatTextRotationDownIconSvg = FormatTextRotationDownIcon;

// import FormatTextRotationNoneIcon from 'react-material-icon-svg/dist/FormatTextRotationNone';
// export const FormatTextRotationNoneIconSvg = FormatTextRotationNoneIcon;

// import FormatTextVariantIcon from 'react-material-icon-svg/dist/FormatTextVariant';
// export const FormatTextVariantIconSvg = FormatTextVariantIcon;

// import FormatTextWrappingClipIcon from 'react-material-icon-svg/dist/FormatTextWrappingClip';
// export const FormatTextWrappingClipIconSvg = FormatTextWrappingClipIcon;

// import FormatTextWrappingOverflowIcon from 'react-material-icon-svg/dist/FormatTextWrappingOverflow';
// export const FormatTextWrappingOverflowIconSvg = FormatTextWrappingOverflowIcon;

// import FormatTextWrappingWrapIcon from 'react-material-icon-svg/dist/FormatTextWrappingWrap';
// export const FormatTextWrappingWrapIconSvg = FormatTextWrappingWrapIcon;

// import FormatTextboxIcon from 'react-material-icon-svg/dist/FormatTextbox';
// export const FormatTextboxIconSvg = FormatTextboxIcon;

// import FormatTextdirectionLToRIcon from 'react-material-icon-svg/dist/FormatTextdirectionLToR';
// export const FormatTextdirectionLToRIconSvg = FormatTextdirectionLToRIcon;

// import FormatTextdirectionRToLIcon from 'react-material-icon-svg/dist/FormatTextdirectionRToL';
// export const FormatTextdirectionRToLIconSvg = FormatTextdirectionRToLIcon;

// import FormatTitleIcon from 'react-material-icon-svg/dist/FormatTitle';
// export const FormatTitleIconSvg = FormatTitleIcon;

// import FormatUnderlineIcon from 'react-material-icon-svg/dist/FormatUnderline';
// export const FormatUnderlineIconSvg = FormatUnderlineIcon;

// import FormatVerticalAlignBottomIcon from 'react-material-icon-svg/dist/FormatVerticalAlignBottom';
// export const FormatVerticalAlignBottomIconSvg = FormatVerticalAlignBottomIcon;

// import FormatVerticalAlignCenterIcon from 'react-material-icon-svg/dist/FormatVerticalAlignCenter';
// export const FormatVerticalAlignCenterIconSvg = FormatVerticalAlignCenterIcon;

// import FormatVerticalAlignTopIcon from 'react-material-icon-svg/dist/FormatVerticalAlignTop';
// export const FormatVerticalAlignTopIconSvg = FormatVerticalAlignTopIcon;

// import FormatWrapInlineIcon from 'react-material-icon-svg/dist/FormatWrapInline';
// export const FormatWrapInlineIconSvg = FormatWrapInlineIcon;

// import FormatWrapSquareIcon from 'react-material-icon-svg/dist/FormatWrapSquare';
// export const FormatWrapSquareIconSvg = FormatWrapSquareIcon;

// import FormatWrapTightIcon from 'react-material-icon-svg/dist/FormatWrapTight';
// export const FormatWrapTightIconSvg = FormatWrapTightIcon;

// import FormatWrapTopBottomIcon from 'react-material-icon-svg/dist/FormatWrapTopBottom';
// export const FormatWrapTopBottomIconSvg = FormatWrapTopBottomIcon;

// import ForumIcon from 'react-material-icon-svg/dist/Forum';
// export const ForumIconSvg = ForumIcon;

// import ForumOutlineIcon from 'react-material-icon-svg/dist/ForumOutline';
// export const ForumOutlineIconSvg = ForumOutlineIcon;

// import ForwardIcon from 'react-material-icon-svg/dist/Forward';
// export const ForwardIconSvg = ForwardIcon;

// import ForwardburgerIcon from 'react-material-icon-svg/dist/Forwardburger';
// export const ForwardburgerIconSvg = ForwardburgerIcon;

// import FountainIcon from 'react-material-icon-svg/dist/Fountain';
// export const FountainIconSvg = FountainIcon;

// import FountainPenIcon from 'react-material-icon-svg/dist/FountainPen';
// export const FountainPenIconSvg = FountainPenIcon;

// import FountainPenTipIcon from 'react-material-icon-svg/dist/FountainPenTip';
// export const FountainPenTipIconSvg = FountainPenTipIcon;

// import FoursquareIcon from 'react-material-icon-svg/dist/Foursquare';
// export const FoursquareIconSvg = FoursquareIcon;

// import FreebsdIcon from 'react-material-icon-svg/dist/Freebsd';
// export const FreebsdIconSvg = FreebsdIcon;

// import FrequentlyAskedQuestionsIcon from 'react-material-icon-svg/dist/FrequentlyAskedQuestions';
// export const FrequentlyAskedQuestionsIconSvg = FrequentlyAskedQuestionsIcon;

// import FridgeIcon from 'react-material-icon-svg/dist/Fridge';
// export const FridgeIconSvg = FridgeIcon;

// import FridgeBottomIcon from 'react-material-icon-svg/dist/FridgeBottom';
// export const FridgeBottomIconSvg = FridgeBottomIcon;

// import FridgeOutlineIcon from 'react-material-icon-svg/dist/FridgeOutline';
// export const FridgeOutlineIconSvg = FridgeOutlineIcon;

// import FridgeTopIcon from 'react-material-icon-svg/dist/FridgeTop';
// export const FridgeTopIconSvg = FridgeTopIcon;

// import FuelIcon from 'react-material-icon-svg/dist/Fuel';
// export const FuelIconSvg = FuelIcon;

// import FullscreenIcon from 'react-material-icon-svg/dist/Fullscreen';
// export const FullscreenIconSvg = FullscreenIcon;

// import FullscreenExitIcon from 'react-material-icon-svg/dist/FullscreenExit';
// export const FullscreenExitIconSvg = FullscreenExitIcon;

// import FunctionIcon from 'react-material-icon-svg/dist/Function';
// export const FunctionIconSvg = FunctionIcon;

// import FunctionVariantIcon from 'react-material-icon-svg/dist/FunctionVariant';
// export const FunctionVariantIconSvg = FunctionVariantIcon;

// import FuseIcon from 'react-material-icon-svg/dist/Fuse';
// export const FuseIconSvg = FuseIcon;

// import FuseBladeIcon from 'react-material-icon-svg/dist/FuseBlade';
// export const FuseBladeIconSvg = FuseBladeIcon;

// import GamepadIcon from 'react-material-icon-svg/dist/Gamepad';
// export const GamepadIconSvg = GamepadIcon;

// import GamepadCircleIcon from 'react-material-icon-svg/dist/GamepadCircle';
// export const GamepadCircleIconSvg = GamepadCircleIcon;

// import GamepadCircleDownIcon from 'react-material-icon-svg/dist/GamepadCircleDown';
// export const GamepadCircleDownIconSvg = GamepadCircleDownIcon;

// import GamepadCircleLeftIcon from 'react-material-icon-svg/dist/GamepadCircleLeft';
// export const GamepadCircleLeftIconSvg = GamepadCircleLeftIcon;

// import GamepadCircleOutlineIcon from 'react-material-icon-svg/dist/GamepadCircleOutline';
// export const GamepadCircleOutlineIconSvg = GamepadCircleOutlineIcon;

// import GamepadCircleRightIcon from 'react-material-icon-svg/dist/GamepadCircleRight';
// export const GamepadCircleRightIconSvg = GamepadCircleRightIcon;

// import GamepadCircleUpIcon from 'react-material-icon-svg/dist/GamepadCircleUp';
// export const GamepadCircleUpIconSvg = GamepadCircleUpIcon;

// import GamepadDownIcon from 'react-material-icon-svg/dist/GamepadDown';
// export const GamepadDownIconSvg = GamepadDownIcon;

// import GamepadLeftIcon from 'react-material-icon-svg/dist/GamepadLeft';
// export const GamepadLeftIconSvg = GamepadLeftIcon;

// import GamepadRightIcon from 'react-material-icon-svg/dist/GamepadRight';
// export const GamepadRightIconSvg = GamepadRightIcon;

// import GamepadRoundIcon from 'react-material-icon-svg/dist/GamepadRound';
// export const GamepadRoundIconSvg = GamepadRoundIcon;

// import GamepadRoundDownIcon from 'react-material-icon-svg/dist/GamepadRoundDown';
// export const GamepadRoundDownIconSvg = GamepadRoundDownIcon;

// import GamepadRoundLeftIcon from 'react-material-icon-svg/dist/GamepadRoundLeft';
// export const GamepadRoundLeftIconSvg = GamepadRoundLeftIcon;

// import GamepadRoundOutlineIcon from 'react-material-icon-svg/dist/GamepadRoundOutline';
// export const GamepadRoundOutlineIconSvg = GamepadRoundOutlineIcon;

// import GamepadRoundRightIcon from 'react-material-icon-svg/dist/GamepadRoundRight';
// export const GamepadRoundRightIconSvg = GamepadRoundRightIcon;

// import GamepadRoundUpIcon from 'react-material-icon-svg/dist/GamepadRoundUp';
// export const GamepadRoundUpIconSvg = GamepadRoundUpIcon;

// import GamepadSquareIcon from 'react-material-icon-svg/dist/GamepadSquare';
// export const GamepadSquareIconSvg = GamepadSquareIcon;

// import GamepadSquareOutlineIcon from 'react-material-icon-svg/dist/GamepadSquareOutline';
// export const GamepadSquareOutlineIconSvg = GamepadSquareOutlineIcon;

// import GamepadUpIcon from 'react-material-icon-svg/dist/GamepadUp';
// export const GamepadUpIconSvg = GamepadUpIcon;

// import GamepadVariantIcon from 'react-material-icon-svg/dist/GamepadVariant';
// export const GamepadVariantIconSvg = GamepadVariantIcon;

// import GamepadVariantOutlineIcon from 'react-material-icon-svg/dist/GamepadVariantOutline';
// export const GamepadVariantOutlineIconSvg = GamepadVariantOutlineIcon;

// import GantryCraneIcon from 'react-material-icon-svg/dist/GantryCrane';
// export const GantryCraneIconSvg = GantryCraneIcon;

// import GarageIcon from 'react-material-icon-svg/dist/Garage';
// export const GarageIconSvg = GarageIcon;

// import GarageAlertIcon from 'react-material-icon-svg/dist/GarageAlert';
// export const GarageAlertIconSvg = GarageAlertIcon;

// import GarageOpenIcon from 'react-material-icon-svg/dist/GarageOpen';
// export const GarageOpenIconSvg = GarageOpenIcon;

// import GasCylinderIcon from 'react-material-icon-svg/dist/GasCylinder';
// export const GasCylinderIconSvg = GasCylinderIcon;

// import GasStationIcon from 'react-material-icon-svg/dist/GasStation';
// export const GasStationIconSvg = GasStationIcon;

// import GasStationOutlineIcon from 'react-material-icon-svg/dist/GasStationOutline';
// export const GasStationOutlineIconSvg = GasStationOutlineIcon;

// import GateIcon from 'react-material-icon-svg/dist/Gate';
// export const GateIconSvg = GateIcon;

// import GateAndIcon from 'react-material-icon-svg/dist/GateAnd';
// export const GateAndIconSvg = GateAndIcon;

// import GateNandIcon from 'react-material-icon-svg/dist/GateNand';
// export const GateNandIconSvg = GateNandIcon;

// import GateNorIcon from 'react-material-icon-svg/dist/GateNor';
// export const GateNorIconSvg = GateNorIcon;

// import GateNotIcon from 'react-material-icon-svg/dist/GateNot';
// export const GateNotIconSvg = GateNotIcon;

// import GateOrIcon from 'react-material-icon-svg/dist/GateOr';
// export const GateOrIconSvg = GateOrIcon;

// import GateXnorIcon from 'react-material-icon-svg/dist/GateXnor';
// export const GateXnorIconSvg = GateXnorIcon;

// import GateXorIcon from 'react-material-icon-svg/dist/GateXor';
// export const GateXorIconSvg = GateXorIcon;

// import GatsbyIcon from 'react-material-icon-svg/dist/Gatsby';
// export const GatsbyIconSvg = GatsbyIcon;

// import GaugeIcon from 'react-material-icon-svg/dist/Gauge';
// export const GaugeIconSvg = GaugeIcon;

// import GaugeEmptyIcon from 'react-material-icon-svg/dist/GaugeEmpty';
// export const GaugeEmptyIconSvg = GaugeEmptyIcon;

// import GaugeFullIcon from 'react-material-icon-svg/dist/GaugeFull';
// export const GaugeFullIconSvg = GaugeFullIcon;

// import GaugeLowIcon from 'react-material-icon-svg/dist/GaugeLow';
// export const GaugeLowIconSvg = GaugeLowIcon;

// import GavelIcon from 'react-material-icon-svg/dist/Gavel';
// export const GavelIconSvg = GavelIcon;

// import GenderFemaleIcon from 'react-material-icon-svg/dist/GenderFemale';
// export const GenderFemaleIconSvg = GenderFemaleIcon;

// import GenderMaleIcon from 'react-material-icon-svg/dist/GenderMale';
// export const GenderMaleIconSvg = GenderMaleIcon;

// import GenderMaleFemaleIcon from 'react-material-icon-svg/dist/GenderMaleFemale';
// export const GenderMaleFemaleIconSvg = GenderMaleFemaleIcon;

// import GenderTransgenderIcon from 'react-material-icon-svg/dist/GenderTransgender';
// export const GenderTransgenderIconSvg = GenderTransgenderIcon;

// import GentooIcon from 'react-material-icon-svg/dist/Gentoo';
// export const GentooIconSvg = GentooIcon;

// import GestureIcon from 'react-material-icon-svg/dist/Gesture';
// export const GestureIconSvg = GestureIcon;

// import GestureDoubleTapIcon from 'react-material-icon-svg/dist/GestureDoubleTap';
// export const GestureDoubleTapIconSvg = GestureDoubleTapIcon;

// import GesturePinchIcon from 'react-material-icon-svg/dist/GesturePinch';
// export const GesturePinchIconSvg = GesturePinchIcon;

// import GestureSpreadIcon from 'react-material-icon-svg/dist/GestureSpread';
// export const GestureSpreadIconSvg = GestureSpreadIcon;

// import GestureSwipeIcon from 'react-material-icon-svg/dist/GestureSwipe';
// export const GestureSwipeIconSvg = GestureSwipeIcon;

// import GestureSwipeDownIcon from 'react-material-icon-svg/dist/GestureSwipeDown';
// export const GestureSwipeDownIconSvg = GestureSwipeDownIcon;

// import GestureSwipeHorizontalIcon from 'react-material-icon-svg/dist/GestureSwipeHorizontal';
// export const GestureSwipeHorizontalIconSvg = GestureSwipeHorizontalIcon;

// import GestureSwipeLeftIcon from 'react-material-icon-svg/dist/GestureSwipeLeft';
// export const GestureSwipeLeftIconSvg = GestureSwipeLeftIcon;

// import GestureSwipeRightIcon from 'react-material-icon-svg/dist/GestureSwipeRight';
// export const GestureSwipeRightIconSvg = GestureSwipeRightIcon;

// import GestureSwipeUpIcon from 'react-material-icon-svg/dist/GestureSwipeUp';
// export const GestureSwipeUpIconSvg = GestureSwipeUpIcon;

// import GestureSwipeVerticalIcon from 'react-material-icon-svg/dist/GestureSwipeVertical';
// export const GestureSwipeVerticalIconSvg = GestureSwipeVerticalIcon;

// import GestureTapIcon from 'react-material-icon-svg/dist/GestureTap';
// export const GestureTapIconSvg = GestureTapIcon;

// import GestureTapHoldIcon from 'react-material-icon-svg/dist/GestureTapHold';
// export const GestureTapHoldIconSvg = GestureTapHoldIcon;

// import GestureTwoDoubleTapIcon from 'react-material-icon-svg/dist/GestureTwoDoubleTap';
// export const GestureTwoDoubleTapIconSvg = GestureTwoDoubleTapIcon;

// import GestureTwoTapIcon from 'react-material-icon-svg/dist/GestureTwoTap';
// export const GestureTwoTapIconSvg = GestureTwoTapIcon;

// import GhostIcon from 'react-material-icon-svg/dist/Ghost';
// export const GhostIconSvg = GhostIcon;

// import GhostOffIcon from 'react-material-icon-svg/dist/GhostOff';
// export const GhostOffIconSvg = GhostOffIcon;

// import GifIcon from 'react-material-icon-svg/dist/Gif';
// export const GifIconSvg = GifIcon;

// import GiftIcon from 'react-material-icon-svg/dist/Gift';
// export const GiftIconSvg = GiftIcon;

// import GiftOutlineIcon from 'react-material-icon-svg/dist/GiftOutline';
// export const GiftOutlineIconSvg = GiftOutlineIcon;

// import GitIcon from 'react-material-icon-svg/dist/Git';
// export const GitIconSvg = GitIcon;

// import GithubBoxIcon from 'react-material-icon-svg/dist/GithubBox';
// export const GithubBoxIconSvg = GithubBoxIcon;

// import GithubCircleIcon from 'react-material-icon-svg/dist/GithubCircle';
// export const GithubCircleIconSvg = GithubCircleIcon;

// import GithubFaceIcon from 'react-material-icon-svg/dist/GithubFace';
// export const GithubFaceIconSvg = GithubFaceIcon;

// import GitlabIcon from 'react-material-icon-svg/dist/Gitlab';
// export const GitlabIconSvg = GitlabIcon;

// import GlassCocktailIcon from 'react-material-icon-svg/dist/GlassCocktail';
// export const GlassCocktailIconSvg = GlassCocktailIcon;

// import GlassFluteIcon from 'react-material-icon-svg/dist/GlassFlute';
// export const GlassFluteIconSvg = GlassFluteIcon;

// import GlassMugIcon from 'react-material-icon-svg/dist/GlassMug';
// export const GlassMugIconSvg = GlassMugIcon;

// import GlassStangeIcon from 'react-material-icon-svg/dist/GlassStange';
// export const GlassStangeIconSvg = GlassStangeIcon;

// import GlassTulipIcon from 'react-material-icon-svg/dist/GlassTulip';
// export const GlassTulipIconSvg = GlassTulipIcon;

// import GlassWineIcon from 'react-material-icon-svg/dist/GlassWine';
// export const GlassWineIconSvg = GlassWineIcon;

// import GlassdoorIcon from 'react-material-icon-svg/dist/Glassdoor';
// export const GlassdoorIconSvg = GlassdoorIcon;

// import GlassesIcon from 'react-material-icon-svg/dist/Glasses';
// export const GlassesIconSvg = GlassesIcon;

// import GlobeModelIcon from 'react-material-icon-svg/dist/GlobeModel';
// export const GlobeModelIconSvg = GlobeModelIcon;

// import GmailIcon from 'react-material-icon-svg/dist/Gmail';
// export const GmailIconSvg = GmailIcon;

// import GnomeIcon from 'react-material-icon-svg/dist/Gnome';
// export const GnomeIconSvg = GnomeIcon;

// import GoKartIcon from 'react-material-icon-svg/dist/GoKart';
// export const GoKartIconSvg = GoKartIcon;

// import GoKartTrackIcon from 'react-material-icon-svg/dist/GoKartTrack';
// export const GoKartTrackIconSvg = GoKartTrackIcon;

// import GogIcon from 'react-material-icon-svg/dist/Gog';
// export const GogIconSvg = GogIcon;

// import GolfIcon from 'react-material-icon-svg/dist/Golf';
// export const GolfIconSvg = GolfIcon;

// import GondolaIcon from 'react-material-icon-svg/dist/Gondola';
// export const GondolaIconSvg = GondolaIcon;

// import GoodreadsIcon from 'react-material-icon-svg/dist/Goodreads';
// export const GoodreadsIconSvg = GoodreadsIcon;

// import GoogleIcon from 'react-material-icon-svg/dist/Google';
// export const GoogleIconSvg = GoogleIcon;

// import GoogleAdwordsIcon from 'react-material-icon-svg/dist/GoogleAdwords';
// export const GoogleAdwordsIconSvg = GoogleAdwordsIcon;

// import GoogleAlloIcon from 'react-material-icon-svg/dist/GoogleAllo';
// export const GoogleAlloIconSvg = GoogleAlloIcon;

// import GoogleAnalyticsIcon from 'react-material-icon-svg/dist/GoogleAnalytics';
// export const GoogleAnalyticsIconSvg = GoogleAnalyticsIcon;

// import GoogleAssistantIcon from 'react-material-icon-svg/dist/GoogleAssistant';
// export const GoogleAssistantIconSvg = GoogleAssistantIcon;

// import GoogleCardboardIcon from 'react-material-icon-svg/dist/GoogleCardboard';
// export const GoogleCardboardIconSvg = GoogleCardboardIcon;

// import GoogleChromeIcon from 'react-material-icon-svg/dist/GoogleChrome';
// export const GoogleChromeIconSvg = GoogleChromeIcon;

// import GoogleCirclesIcon from 'react-material-icon-svg/dist/GoogleCircles';
// export const GoogleCirclesIconSvg = GoogleCirclesIcon;

// import GoogleCirclesCommunitiesIcon from 'react-material-icon-svg/dist/GoogleCirclesCommunities';
// export const GoogleCirclesCommunitiesIconSvg = GoogleCirclesCommunitiesIcon;

// import GoogleCirclesExtendedIcon from 'react-material-icon-svg/dist/GoogleCirclesExtended';
// export const GoogleCirclesExtendedIconSvg = GoogleCirclesExtendedIcon;

// import GoogleCirclesGroupIcon from 'react-material-icon-svg/dist/GoogleCirclesGroup';
// export const GoogleCirclesGroupIconSvg = GoogleCirclesGroupIcon;

// import GoogleClassroomIcon from 'react-material-icon-svg/dist/GoogleClassroom';
// export const GoogleClassroomIconSvg = GoogleClassroomIcon;

// import GoogleControllerIcon from 'react-material-icon-svg/dist/GoogleController';
// export const GoogleControllerIconSvg = GoogleControllerIcon;

// import GoogleControllerOffIcon from 'react-material-icon-svg/dist/GoogleControllerOff';
// export const GoogleControllerOffIconSvg = GoogleControllerOffIcon;

// import GoogleDriveIcon from 'react-material-icon-svg/dist/GoogleDrive';
// export const GoogleDriveIconSvg = GoogleDriveIcon;

// import GoogleEarthIcon from 'react-material-icon-svg/dist/GoogleEarth';
// export const GoogleEarthIconSvg = GoogleEarthIcon;

// import GoogleFitIcon from 'react-material-icon-svg/dist/GoogleFit';
// export const GoogleFitIconSvg = GoogleFitIcon;

// import GoogleGlassIcon from 'react-material-icon-svg/dist/GoogleGlass';
// export const GoogleGlassIconSvg = GoogleGlassIcon;

// import GoogleHangoutsIcon from 'react-material-icon-svg/dist/GoogleHangouts';
// export const GoogleHangoutsIconSvg = GoogleHangoutsIcon;

// import GoogleHomeIcon from 'react-material-icon-svg/dist/GoogleHome';
// export const GoogleHomeIconSvg = GoogleHomeIcon;

// import GoogleKeepIcon from 'react-material-icon-svg/dist/GoogleKeep';
// export const GoogleKeepIconSvg = GoogleKeepIcon;

// import GoogleLensIcon from 'react-material-icon-svg/dist/GoogleLens';
// export const GoogleLensIconSvg = GoogleLensIcon;

// import GoogleMapsIcon from 'react-material-icon-svg/dist/GoogleMaps';
// export const GoogleMapsIconSvg = GoogleMapsIcon;

// import GoogleNearbyIcon from 'react-material-icon-svg/dist/GoogleNearby';
// export const GoogleNearbyIconSvg = GoogleNearbyIcon;

// import GooglePagesIcon from 'react-material-icon-svg/dist/GooglePages';
// export const GooglePagesIconSvg = GooglePagesIcon;

// import GooglePhotosIcon from 'react-material-icon-svg/dist/GooglePhotos';
// export const GooglePhotosIconSvg = GooglePhotosIcon;

// import GooglePhysicalWebIcon from 'react-material-icon-svg/dist/GooglePhysicalWeb';
// export const GooglePhysicalWebIconSvg = GooglePhysicalWebIcon;

// import GooglePlayIcon from 'react-material-icon-svg/dist/GooglePlay';
// export const GooglePlayIconSvg = GooglePlayIcon;

// import GooglePlusIcon from 'react-material-icon-svg/dist/GooglePlus';
// export const GooglePlusIconSvg = GooglePlusIcon;

// import GooglePlusBoxIcon from 'react-material-icon-svg/dist/GooglePlusBox';
// export const GooglePlusBoxIconSvg = GooglePlusBoxIcon;

// import GooglePodcastIcon from 'react-material-icon-svg/dist/GooglePodcast';
// export const GooglePodcastIconSvg = GooglePodcastIcon;

// import GoogleSpreadsheetIcon from 'react-material-icon-svg/dist/GoogleSpreadsheet';
// export const GoogleSpreadsheetIconSvg = GoogleSpreadsheetIcon;

// import GoogleStreetViewIcon from 'react-material-icon-svg/dist/GoogleStreetView';
// export const GoogleStreetViewIconSvg = GoogleStreetViewIcon;

// import GoogleTranslateIcon from 'react-material-icon-svg/dist/GoogleTranslate';
// export const GoogleTranslateIconSvg = GoogleTranslateIcon;

// import GpuIcon from 'react-material-icon-svg/dist/Gpu';
// export const GpuIconSvg = GpuIcon;

// import GradientIcon from 'react-material-icon-svg/dist/Gradient';
// export const GradientIconSvg = GradientIcon;

// import GrainIcon from 'react-material-icon-svg/dist/Grain';
// export const GrainIconSvg = GrainIcon;

// import GraphqlIcon from 'react-material-icon-svg/dist/Graphql';
// export const GraphqlIconSvg = GraphqlIcon;

// import GraveStoneIcon from 'react-material-icon-svg/dist/GraveStone';
// export const GraveStoneIconSvg = GraveStoneIcon;

// import GreasePencilIcon from 'react-material-icon-svg/dist/GreasePencil';
// export const GreasePencilIconSvg = GreasePencilIcon;

// import GreaterThanIcon from 'react-material-icon-svg/dist/GreaterThan';
// export const GreaterThanIconSvg = GreaterThanIcon;

// import GreaterThanOrEqualIcon from 'react-material-icon-svg/dist/GreaterThanOrEqual';
// export const GreaterThanOrEqualIconSvg = GreaterThanOrEqualIcon;

// import GridIcon from 'react-material-icon-svg/dist/Grid';
// export const GridIconSvg = GridIcon;

// import GridLargeIcon from 'react-material-icon-svg/dist/GridLarge';
// export const GridLargeIconSvg = GridLargeIcon;

// import GridOffIcon from 'react-material-icon-svg/dist/GridOff';
// export const GridOffIconSvg = GridOffIcon;

// import GrillIcon from 'react-material-icon-svg/dist/Grill';
// export const GrillIconSvg = GrillIcon;

// import GroupIcon from 'react-material-icon-svg/dist/Group';
// export const GroupIconSvg = GroupIcon;

// import GuitarAcousticIcon from 'react-material-icon-svg/dist/GuitarAcoustic';
// export const GuitarAcousticIconSvg = GuitarAcousticIcon;

// import GuitarElectricIcon from 'react-material-icon-svg/dist/GuitarElectric';
// export const GuitarElectricIconSvg = GuitarElectricIcon;

// import GuitarPickIcon from 'react-material-icon-svg/dist/GuitarPick';
// export const GuitarPickIconSvg = GuitarPickIcon;

// import GuitarPickOutlineIcon from 'react-material-icon-svg/dist/GuitarPickOutline';
// export const GuitarPickOutlineIconSvg = GuitarPickOutlineIcon;

// import GuyFawkesMaskIcon from 'react-material-icon-svg/dist/GuyFawkesMask';
// export const GuyFawkesMaskIconSvg = GuyFawkesMaskIcon;

// import HackernewsIcon from 'react-material-icon-svg/dist/Hackernews';
// export const HackernewsIconSvg = HackernewsIcon;

// import HailIcon from 'react-material-icon-svg/dist/Hail';
// export const HailIconSvg = HailIcon;

// import HalloweenIcon from 'react-material-icon-svg/dist/Halloween';
// export const HalloweenIconSvg = HalloweenIcon;

// import HamburgerIcon from 'react-material-icon-svg/dist/Hamburger';
// export const HamburgerIconSvg = HamburgerIcon;

// import HammerIcon from 'react-material-icon-svg/dist/Hammer';
// export const HammerIconSvg = HammerIcon;

// import HandIcon from 'react-material-icon-svg/dist/Hand';
// export const HandIconSvg = HandIcon;

// import HandLeftIcon from 'react-material-icon-svg/dist/HandLeft';
// export const HandLeftIconSvg = HandLeftIcon;

// import HandOkayIcon from 'react-material-icon-svg/dist/HandOkay';
// export const HandOkayIconSvg = HandOkayIcon;

// import HandPeaceIcon from 'react-material-icon-svg/dist/HandPeace';
// export const HandPeaceIconSvg = HandPeaceIcon;

// import HandPeaceVariantIcon from 'react-material-icon-svg/dist/HandPeaceVariant';
// export const HandPeaceVariantIconSvg = HandPeaceVariantIcon;

// import HandPointingDownIcon from 'react-material-icon-svg/dist/HandPointingDown';
// export const HandPointingDownIconSvg = HandPointingDownIcon;

// import HandPointingLeftIcon from 'react-material-icon-svg/dist/HandPointingLeft';
// export const HandPointingLeftIconSvg = HandPointingLeftIcon;

// import HandPointingRightIcon from 'react-material-icon-svg/dist/HandPointingRight';
// export const HandPointingRightIconSvg = HandPointingRightIcon;

// import HandPointingUpIcon from 'react-material-icon-svg/dist/HandPointingUp';
// export const HandPointingUpIconSvg = HandPointingUpIcon;

// import HandRightIcon from 'react-material-icon-svg/dist/HandRight';
// export const HandRightIconSvg = HandRightIcon;

// import HandSawIcon from 'react-material-icon-svg/dist/HandSaw';
// export const HandSawIconSvg = HandSawIcon;

// import HangerIcon from 'react-material-icon-svg/dist/Hanger';
// export const HangerIconSvg = HangerIcon;

// import HardHatIcon from 'react-material-icon-svg/dist/HardHat';
// export const HardHatIconSvg = HardHatIcon;

// import HarddiskIcon from 'react-material-icon-svg/dist/Harddisk';
// export const HarddiskIconSvg = HarddiskIcon;

// import HatFedoraIcon from 'react-material-icon-svg/dist/HatFedora';
// export const HatFedoraIconSvg = HatFedoraIcon;

// import HazardLightsIcon from 'react-material-icon-svg/dist/HazardLights';
// export const HazardLightsIconSvg = HazardLightsIcon;

// import HdrIcon from 'react-material-icon-svg/dist/Hdr';
// export const HdrIconSvg = HdrIcon;

// import HdrOffIcon from 'react-material-icon-svg/dist/HdrOff';
// export const HdrOffIconSvg = HdrOffIcon;

// import HeadphonesIcon from 'react-material-icon-svg/dist/Headphones';
// export const HeadphonesIconSvg = HeadphonesIcon;

// import HeadphonesBluetoothIcon from 'react-material-icon-svg/dist/HeadphonesBluetooth';
// export const HeadphonesBluetoothIconSvg = HeadphonesBluetoothIcon;

// import HeadphonesBoxIcon from 'react-material-icon-svg/dist/HeadphonesBox';
// export const HeadphonesBoxIconSvg = HeadphonesBoxIcon;

// import HeadphonesOffIcon from 'react-material-icon-svg/dist/HeadphonesOff';
// export const HeadphonesOffIconSvg = HeadphonesOffIcon;

// import HeadphonesSettingsIcon from 'react-material-icon-svg/dist/HeadphonesSettings';
// export const HeadphonesSettingsIconSvg = HeadphonesSettingsIcon;

// import HeadsetIcon from 'react-material-icon-svg/dist/Headset';
// export const HeadsetIconSvg = HeadsetIcon;

// import HeadsetDockIcon from 'react-material-icon-svg/dist/HeadsetDock';
// export const HeadsetDockIconSvg = HeadsetDockIcon;

// import HeadsetOffIcon from 'react-material-icon-svg/dist/HeadsetOff';
// export const HeadsetOffIconSvg = HeadsetOffIcon;

// import HeartIcon from 'react-material-icon-svg/dist/Heart';
// export const HeartIconSvg = HeartIcon;

// import HeartBoxIcon from 'react-material-icon-svg/dist/HeartBox';
// export const HeartBoxIconSvg = HeartBoxIcon;

// import HeartBoxOutlineIcon from 'react-material-icon-svg/dist/HeartBoxOutline';
// export const HeartBoxOutlineIconSvg = HeartBoxOutlineIcon;

// import HeartBrokenIcon from 'react-material-icon-svg/dist/HeartBroken';
// export const HeartBrokenIconSvg = HeartBrokenIcon;

// import HeartBrokenOutlineIcon from 'react-material-icon-svg/dist/HeartBrokenOutline';
// export const HeartBrokenOutlineIconSvg = HeartBrokenOutlineIcon;

// import HeartCircleIcon from 'react-material-icon-svg/dist/HeartCircle';
// export const HeartCircleIconSvg = HeartCircleIcon;

// import HeartCircleOutlineIcon from 'react-material-icon-svg/dist/HeartCircleOutline';
// export const HeartCircleOutlineIconSvg = HeartCircleOutlineIcon;

// import HeartHalfIcon from 'react-material-icon-svg/dist/HeartHalf';
// export const HeartHalfIconSvg = HeartHalfIcon;

// import HeartHalfFullIcon from 'react-material-icon-svg/dist/HeartHalfFull';
// export const HeartHalfFullIconSvg = HeartHalfFullIcon;

// import HeartHalfOutlineIcon from 'react-material-icon-svg/dist/HeartHalfOutline';
// export const HeartHalfOutlineIconSvg = HeartHalfOutlineIcon;

// import HeartMultipleIcon from 'react-material-icon-svg/dist/HeartMultiple';
// export const HeartMultipleIconSvg = HeartMultipleIcon;

// import HeartMultipleOutlineIcon from 'react-material-icon-svg/dist/HeartMultipleOutline';
// export const HeartMultipleOutlineIconSvg = HeartMultipleOutlineIcon;

// import HeartOffIcon from 'react-material-icon-svg/dist/HeartOff';
// export const HeartOffIconSvg = HeartOffIcon;

// import HeartOutlineIcon from 'react-material-icon-svg/dist/HeartOutline';
// export const HeartOutlineIconSvg = HeartOutlineIcon;

// import HeartPulseIcon from 'react-material-icon-svg/dist/HeartPulse';
// export const HeartPulseIconSvg = HeartPulseIcon;

// import HelicopterIcon from 'react-material-icon-svg/dist/Helicopter';
// export const HelicopterIconSvg = HelicopterIcon;

// import HelpIcon from 'react-material-icon-svg/dist/Help';
// export const HelpIconSvg = HelpIcon;

// import HelpBoxIcon from 'react-material-icon-svg/dist/HelpBox';
// export const HelpBoxIconSvg = HelpBoxIcon;

// import HelpCircleIcon from 'react-material-icon-svg/dist/HelpCircle';
// export const HelpCircleIconSvg = HelpCircleIcon;

// import HelpCircleOutlineIcon from 'react-material-icon-svg/dist/HelpCircleOutline';
// export const HelpCircleOutlineIconSvg = HelpCircleOutlineIcon;

// import HelpNetworkIcon from 'react-material-icon-svg/dist/HelpNetwork';
// export const HelpNetworkIconSvg = HelpNetworkIcon;

// import HelpNetworkOutlineIcon from 'react-material-icon-svg/dist/HelpNetworkOutline';
// export const HelpNetworkOutlineIconSvg = HelpNetworkOutlineIcon;

// import HelpRhombusIcon from 'react-material-icon-svg/dist/HelpRhombus';
// export const HelpRhombusIconSvg = HelpRhombusIcon;

// import HelpRhombusOutlineIcon from 'react-material-icon-svg/dist/HelpRhombusOutline';
// export const HelpRhombusOutlineIconSvg = HelpRhombusOutlineIcon;

// import HexagonIcon from 'react-material-icon-svg/dist/Hexagon';
// export const HexagonIconSvg = HexagonIcon;

// import HexagonMultipleIcon from 'react-material-icon-svg/dist/HexagonMultiple';
// export const HexagonMultipleIconSvg = HexagonMultipleIcon;

// import HexagonOutlineIcon from 'react-material-icon-svg/dist/HexagonOutline';
// export const HexagonOutlineIconSvg = HexagonOutlineIcon;

// import HexagonSlice_1Icon from 'react-material-icon-svg/dist/HexagonSlice_1';
// export const HexagonSlice_1IconSvg = HexagonSlice_1Icon;

// import HexagonSlice_2Icon from 'react-material-icon-svg/dist/HexagonSlice_2';
// export const HexagonSlice_2IconSvg = HexagonSlice_2Icon;

// import HexagonSlice_3Icon from 'react-material-icon-svg/dist/HexagonSlice_3';
// export const HexagonSlice_3IconSvg = HexagonSlice_3Icon;

// import HexagonSlice_4Icon from 'react-material-icon-svg/dist/HexagonSlice_4';
// export const HexagonSlice_4IconSvg = HexagonSlice_4Icon;

// import HexagonSlice_5Icon from 'react-material-icon-svg/dist/HexagonSlice_5';
// export const HexagonSlice_5IconSvg = HexagonSlice_5Icon;

// import HexagonSlice_6Icon from 'react-material-icon-svg/dist/HexagonSlice_6';
// export const HexagonSlice_6IconSvg = HexagonSlice_6Icon;

// import HexagramIcon from 'react-material-icon-svg/dist/Hexagram';
// export const HexagramIconSvg = HexagramIcon;

// import HexagramOutlineIcon from 'react-material-icon-svg/dist/HexagramOutline';
// export const HexagramOutlineIconSvg = HexagramOutlineIcon;

// import HighDefinitionIcon from 'react-material-icon-svg/dist/HighDefinition';
// export const HighDefinitionIconSvg = HighDefinitionIcon;

// import HighDefinitionBoxIcon from 'react-material-icon-svg/dist/HighDefinitionBox';
// export const HighDefinitionBoxIconSvg = HighDefinitionBoxIcon;

// import HighwayIcon from 'react-material-icon-svg/dist/Highway';
// export const HighwayIconSvg = HighwayIcon;

// import HikingIcon from 'react-material-icon-svg/dist/Hiking';
// export const HikingIconSvg = HikingIcon;

// import HinduismIcon from 'react-material-icon-svg/dist/Hinduism';
// export const HinduismIconSvg = HinduismIcon;

// import HistoryIcon from 'react-material-icon-svg/dist/History';
// export const HistoryIconSvg = HistoryIcon;

// import HockeyPuckIcon from 'react-material-icon-svg/dist/HockeyPuck';
// export const HockeyPuckIconSvg = HockeyPuckIcon;

// import HockeySticksIcon from 'react-material-icon-svg/dist/HockeySticks';
// export const HockeySticksIconSvg = HockeySticksIcon;

// import HololensIcon from 'react-material-icon-svg/dist/Hololens';
// export const HololensIconSvg = HololensIcon;

// import HomeIcon from 'react-material-icon-svg/dist/Home';
// export const HomeIconSvg = HomeIcon;

// import HomeAccountIcon from 'react-material-icon-svg/dist/HomeAccount';
// export const HomeAccountIconSvg = HomeAccountIcon;

// import HomeAlertIcon from 'react-material-icon-svg/dist/HomeAlert';
// export const HomeAlertIconSvg = HomeAlertIcon;

// import HomeAnalyticsIcon from 'react-material-icon-svg/dist/HomeAnalytics';
// export const HomeAnalyticsIconSvg = HomeAnalyticsIcon;

// import HomeAssistantIcon from 'react-material-icon-svg/dist/HomeAssistant';
// export const HomeAssistantIconSvg = HomeAssistantIcon;

// import HomeAutomationIcon from 'react-material-icon-svg/dist/HomeAutomation';
// export const HomeAutomationIconSvg = HomeAutomationIcon;

// import HomeCircleIcon from 'react-material-icon-svg/dist/HomeCircle';
// export const HomeCircleIconSvg = HomeCircleIcon;

// import HomeCityIcon from 'react-material-icon-svg/dist/HomeCity';
// export const HomeCityIconSvg = HomeCityIcon;

// import HomeCityOutlineIcon from 'react-material-icon-svg/dist/HomeCityOutline';
// export const HomeCityOutlineIconSvg = HomeCityOutlineIcon;

// import HomeCurrencyUsdIcon from 'react-material-icon-svg/dist/HomeCurrencyUsd';
// export const HomeCurrencyUsdIconSvg = HomeCurrencyUsdIcon;

// import HomeFloorAIcon from 'react-material-icon-svg/dist/HomeFloorA';
// export const HomeFloorAIconSvg = HomeFloorAIcon;

// import HomeFloorBIcon from 'react-material-icon-svg/dist/HomeFloorB';
// export const HomeFloorBIconSvg = HomeFloorBIcon;

// import HomeFloorGIcon from 'react-material-icon-svg/dist/HomeFloorG';
// export const HomeFloorGIconSvg = HomeFloorGIcon;

// import HomeFloorLIcon from 'react-material-icon-svg/dist/HomeFloorL';
// export const HomeFloorLIconSvg = HomeFloorLIcon;

// import HomeFloorNegative_1Icon from 'react-material-icon-svg/dist/HomeFloorNegative_1';
// export const HomeFloorNegative_1IconSvg = HomeFloorNegative_1Icon;

// import HomeFloor_0Icon from 'react-material-icon-svg/dist/HomeFloor_0';
// export const HomeFloor_0IconSvg = HomeFloor_0Icon;

// import HomeFloor_1Icon from 'react-material-icon-svg/dist/HomeFloor_1';
// export const HomeFloor_1IconSvg = HomeFloor_1Icon;

// import HomeFloor_2Icon from 'react-material-icon-svg/dist/HomeFloor_2';
// export const HomeFloor_2IconSvg = HomeFloor_2Icon;

// import HomeFloor_3Icon from 'react-material-icon-svg/dist/HomeFloor_3';
// export const HomeFloor_3IconSvg = HomeFloor_3Icon;

// import HomeGroupIcon from 'react-material-icon-svg/dist/HomeGroup';
// export const HomeGroupIconSvg = HomeGroupIcon;

// import HomeHeartIcon from 'react-material-icon-svg/dist/HomeHeart';
// export const HomeHeartIconSvg = HomeHeartIcon;

// import HomeLockIcon from 'react-material-icon-svg/dist/HomeLock';
// export const HomeLockIconSvg = HomeLockIcon;

// import HomeLockOpenIcon from 'react-material-icon-svg/dist/HomeLockOpen';
// export const HomeLockOpenIconSvg = HomeLockOpenIcon;

// import HomeMapMarkerIcon from 'react-material-icon-svg/dist/HomeMapMarker';
// export const HomeMapMarkerIconSvg = HomeMapMarkerIcon;

// import HomeMinusIcon from 'react-material-icon-svg/dist/HomeMinus';
// export const HomeMinusIconSvg = HomeMinusIcon;

// import HomeModernIcon from 'react-material-icon-svg/dist/HomeModern';
// export const HomeModernIconSvg = HomeModernIcon;

// import HomeOutlineIcon from 'react-material-icon-svg/dist/HomeOutline';
// export const HomeOutlineIconSvg = HomeOutlineIcon;

// import HomePlusIcon from 'react-material-icon-svg/dist/HomePlus';
// export const HomePlusIconSvg = HomePlusIcon;

// import HomeVariantIcon from 'react-material-icon-svg/dist/HomeVariant';
// export const HomeVariantIconSvg = HomeVariantIcon;

// import HomeVariantOutlineIcon from 'react-material-icon-svg/dist/HomeVariantOutline';
// export const HomeVariantOutlineIconSvg = HomeVariantOutlineIcon;

// import HookIcon from 'react-material-icon-svg/dist/Hook';
// export const HookIconSvg = HookIcon;

// import HookOffIcon from 'react-material-icon-svg/dist/HookOff';
// export const HookOffIconSvg = HookOffIcon;

// import HopsIcon from 'react-material-icon-svg/dist/Hops';
// export const HopsIconSvg = HopsIcon;

// import HorseshoeIcon from 'react-material-icon-svg/dist/Horseshoe';
// export const HorseshoeIconSvg = HorseshoeIcon;

// import HospitalIcon from 'react-material-icon-svg/dist/Hospital';
// export const HospitalIconSvg = HospitalIcon;

// import HospitalBuildingIcon from 'react-material-icon-svg/dist/HospitalBuilding';
// export const HospitalBuildingIconSvg = HospitalBuildingIcon;

// import HospitalMarkerIcon from 'react-material-icon-svg/dist/HospitalMarker';
// export const HospitalMarkerIconSvg = HospitalMarkerIcon;

// import HotTubIcon from 'react-material-icon-svg/dist/HotTub';
// export const HotTubIconSvg = HotTubIcon;

// import HotelIcon from 'react-material-icon-svg/dist/Hotel';
// export const HotelIconSvg = HotelIcon;

// import HouzzIcon from 'react-material-icon-svg/dist/Houzz';
// export const HouzzIconSvg = HouzzIcon;

// import HouzzBoxIcon from 'react-material-icon-svg/dist/HouzzBox';
// export const HouzzBoxIconSvg = HouzzBoxIcon;

// import HubspotIcon from 'react-material-icon-svg/dist/Hubspot';
// export const HubspotIconSvg = HubspotIcon;

// import HuluIcon from 'react-material-icon-svg/dist/Hulu';
// export const HuluIconSvg = HuluIcon;

// import HumanIcon from 'react-material-icon-svg/dist/Human';
// export const HumanIconSvg = HumanIcon;

// import HumanChildIcon from 'react-material-icon-svg/dist/HumanChild';
// export const HumanChildIconSvg = HumanChildIcon;

// import HumanFemaleIcon from 'react-material-icon-svg/dist/HumanFemale';
// export const HumanFemaleIconSvg = HumanFemaleIcon;

// import HumanFemaleBoyIcon from 'react-material-icon-svg/dist/HumanFemaleBoy';
// export const HumanFemaleBoyIconSvg = HumanFemaleBoyIcon;

// import HumanFemaleFemaleIcon from 'react-material-icon-svg/dist/HumanFemaleFemale';
// export const HumanFemaleFemaleIconSvg = HumanFemaleFemaleIcon;

// import HumanFemaleGirlIcon from 'react-material-icon-svg/dist/HumanFemaleGirl';
// export const HumanFemaleGirlIconSvg = HumanFemaleGirlIcon;

// import HumanGreetingIcon from 'react-material-icon-svg/dist/HumanGreeting';
// export const HumanGreetingIconSvg = HumanGreetingIcon;

// import HumanHandsdownIcon from 'react-material-icon-svg/dist/HumanHandsdown';
// export const HumanHandsdownIconSvg = HumanHandsdownIcon;

// import HumanHandsupIcon from 'react-material-icon-svg/dist/HumanHandsup';
// export const HumanHandsupIconSvg = HumanHandsupIcon;

// import HumanMaleIcon from 'react-material-icon-svg/dist/HumanMale';
// export const HumanMaleIconSvg = HumanMaleIcon;

// import HumanMaleBoyIcon from 'react-material-icon-svg/dist/HumanMaleBoy';
// export const HumanMaleBoyIconSvg = HumanMaleBoyIcon;

// import HumanMaleFemaleIcon from 'react-material-icon-svg/dist/HumanMaleFemale';
// export const HumanMaleFemaleIconSvg = HumanMaleFemaleIcon;

// import HumanMaleGirlIcon from 'react-material-icon-svg/dist/HumanMaleGirl';
// export const HumanMaleGirlIconSvg = HumanMaleGirlIcon;

// import HumanMaleMaleIcon from 'react-material-icon-svg/dist/HumanMaleMale';
// export const HumanMaleMaleIconSvg = HumanMaleMaleIcon;

// import HumanPregnantIcon from 'react-material-icon-svg/dist/HumanPregnant';
// export const HumanPregnantIconSvg = HumanPregnantIcon;

// import HumbleBundleIcon from 'react-material-icon-svg/dist/HumbleBundle';
// export const HumbleBundleIconSvg = HumbleBundleIcon;

// import IceCreamIcon from 'react-material-icon-svg/dist/IceCream';
// export const IceCreamIconSvg = IceCreamIcon;

// import IframeIcon from 'react-material-icon-svg/dist/Iframe';
// export const IframeIconSvg = IframeIcon;

// import IframeOutlineIcon from 'react-material-icon-svg/dist/IframeOutline';
// export const IframeOutlineIconSvg = IframeOutlineIcon;

import ImageIcon from 'react-material-icon-svg/dist/Image';
export const ImageIconSvg = ImageIcon;

// import ImageAlbumIcon from 'react-material-icon-svg/dist/ImageAlbum';
// export const ImageAlbumIconSvg = ImageAlbumIcon;

// import ImageAreaIcon from 'react-material-icon-svg/dist/ImageArea';
// export const ImageAreaIconSvg = ImageAreaIcon;

// import ImageAreaCloseIcon from 'react-material-icon-svg/dist/ImageAreaClose';
// export const ImageAreaCloseIconSvg = ImageAreaCloseIcon;

// import ImageBrokenIcon from 'react-material-icon-svg/dist/ImageBroken';
// export const ImageBrokenIconSvg = ImageBrokenIcon;

// import ImageBrokenVariantIcon from 'react-material-icon-svg/dist/ImageBrokenVariant';
// export const ImageBrokenVariantIconSvg = ImageBrokenVariantIcon;

// import ImageFilterIcon from 'react-material-icon-svg/dist/ImageFilter';
// export const ImageFilterIconSvg = ImageFilterIcon;

// import ImageFilterBlackWhiteIcon from 'react-material-icon-svg/dist/ImageFilterBlackWhite';
// export const ImageFilterBlackWhiteIconSvg = ImageFilterBlackWhiteIcon;

// import ImageFilterCenterFocusIcon from 'react-material-icon-svg/dist/ImageFilterCenterFocus';
// export const ImageFilterCenterFocusIconSvg = ImageFilterCenterFocusIcon;

// import ImageFilterCenterFocusWeakIcon from 'react-material-icon-svg/dist/ImageFilterCenterFocusWeak';
// export const ImageFilterCenterFocusWeakIconSvg = ImageFilterCenterFocusWeakIcon;

// import ImageFilterDramaIcon from 'react-material-icon-svg/dist/ImageFilterDrama';
// export const ImageFilterDramaIconSvg = ImageFilterDramaIcon;

// import ImageFilterFramesIcon from 'react-material-icon-svg/dist/ImageFilterFrames';
// export const ImageFilterFramesIconSvg = ImageFilterFramesIcon;

// import ImageFilterHdrIcon from 'react-material-icon-svg/dist/ImageFilterHdr';
// export const ImageFilterHdrIconSvg = ImageFilterHdrIcon;

// import ImageFilterNoneIcon from 'react-material-icon-svg/dist/ImageFilterNone';
// export const ImageFilterNoneIconSvg = ImageFilterNoneIcon;

// import ImageFilterTiltShiftIcon from 'react-material-icon-svg/dist/ImageFilterTiltShift';
// export const ImageFilterTiltShiftIconSvg = ImageFilterTiltShiftIcon;

// import ImageFilterVintageIcon from 'react-material-icon-svg/dist/ImageFilterVintage';
// export const ImageFilterVintageIconSvg = ImageFilterVintageIcon;

// import ImageFrameIcon from 'react-material-icon-svg/dist/ImageFrame';
// export const ImageFrameIconSvg = ImageFrameIcon;

// import ImageMoveIcon from 'react-material-icon-svg/dist/ImageMove';
// export const ImageMoveIconSvg = ImageMoveIcon;

// import ImageMultipleIcon from 'react-material-icon-svg/dist/ImageMultiple';
// export const ImageMultipleIconSvg = ImageMultipleIcon;

import ImageOffIcon from 'react-material-icon-svg/dist/ImageOff';
export const ImageOffIconSvg = ImageOffIcon;

// import ImageOutlineIcon from 'react-material-icon-svg/dist/ImageOutline';
// export const ImageOutlineIconSvg = ImageOutlineIcon;

// import ImagePlusIcon from 'react-material-icon-svg/dist/ImagePlus';
// export const ImagePlusIconSvg = ImagePlusIcon;

// import ImageSearchIcon from 'react-material-icon-svg/dist/ImageSearch';
// export const ImageSearchIconSvg = ImageSearchIcon;

// import ImageSearchOutlineIcon from 'react-material-icon-svg/dist/ImageSearchOutline';
// export const ImageSearchOutlineIconSvg = ImageSearchOutlineIcon;

// import ImageSizeSelectActualIcon from 'react-material-icon-svg/dist/ImageSizeSelectActual';
// export const ImageSizeSelectActualIconSvg = ImageSizeSelectActualIcon;

// import ImageSizeSelectLargeIcon from 'react-material-icon-svg/dist/ImageSizeSelectLarge';
// export const ImageSizeSelectLargeIconSvg = ImageSizeSelectLargeIcon;

// import ImageSizeSelectSmallIcon from 'react-material-icon-svg/dist/ImageSizeSelectSmall';
// export const ImageSizeSelectSmallIconSvg = ImageSizeSelectSmallIcon;

// import ImportIcon from 'react-material-icon-svg/dist/Import';
// export const ImportIconSvg = ImportIcon;

// import InboxIcon from 'react-material-icon-svg/dist/Inbox';
// export const InboxIconSvg = InboxIcon;

// import InboxArrowDownIcon from 'react-material-icon-svg/dist/InboxArrowDown';
// export const InboxArrowDownIconSvg = InboxArrowDownIcon;

// import InboxArrowUpIcon from 'react-material-icon-svg/dist/InboxArrowUp';
// export const InboxArrowUpIconSvg = InboxArrowUpIcon;

// import InboxMultipleIcon from 'react-material-icon-svg/dist/InboxMultiple';
// export const InboxMultipleIconSvg = InboxMultipleIcon;

// import InboxMultipleOutlineIcon from 'react-material-icon-svg/dist/InboxMultipleOutline';
// export const InboxMultipleOutlineIconSvg = InboxMultipleOutlineIcon;

// import IncognitoIcon from 'react-material-icon-svg/dist/Incognito';
// export const IncognitoIconSvg = IncognitoIcon;

// import InfinityIcon from 'react-material-icon-svg/dist/Infinity';
// export const InfinityIconSvg = InfinityIcon;

// import InformationIcon from 'react-material-icon-svg/dist/Information';
// export const InformationIconSvg = InformationIcon;

// import InformationOutlineIcon from 'react-material-icon-svg/dist/InformationOutline';
// export const InformationOutlineIconSvg = InformationOutlineIcon;

// import InformationVariantIcon from 'react-material-icon-svg/dist/InformationVariant';
// export const InformationVariantIconSvg = InformationVariantIcon;

// import InstagramIcon from 'react-material-icon-svg/dist/Instagram';
// export const InstagramIconSvg = InstagramIcon;

// import InstapaperIcon from 'react-material-icon-svg/dist/Instapaper';
// export const InstapaperIconSvg = InstapaperIcon;

// import InternetExplorerIcon from 'react-material-icon-svg/dist/InternetExplorer';
// export const InternetExplorerIconSvg = InternetExplorerIcon;

// import InvertColorsIcon from 'react-material-icon-svg/dist/InvertColors';
// export const InvertColorsIconSvg = InvertColorsIcon;

// import InvertColorsOffIcon from 'react-material-icon-svg/dist/InvertColorsOff';
// export const InvertColorsOffIconSvg = InvertColorsOffIcon;

// import IpIcon from 'react-material-icon-svg/dist/Ip';
// export const IpIconSvg = IpIcon;

// import IpNetworkIcon from 'react-material-icon-svg/dist/IpNetwork';
// export const IpNetworkIconSvg = IpNetworkIcon;

// import IpNetworkOutlineIcon from 'react-material-icon-svg/dist/IpNetworkOutline';
// export const IpNetworkOutlineIconSvg = IpNetworkOutlineIcon;

// import IpodIcon from 'react-material-icon-svg/dist/Ipod';
// export const IpodIconSvg = IpodIcon;

// import IslamIcon from 'react-material-icon-svg/dist/Islam';
// export const IslamIconSvg = IslamIcon;

// import ItunesIcon from 'react-material-icon-svg/dist/Itunes';
// export const ItunesIconSvg = ItunesIcon;

// import JabberIcon from 'react-material-icon-svg/dist/Jabber';
// export const JabberIconSvg = JabberIcon;

// import JeepneyIcon from 'react-material-icon-svg/dist/Jeepney';
// export const JeepneyIconSvg = JeepneyIcon;

// import JiraIcon from 'react-material-icon-svg/dist/Jira';
// export const JiraIconSvg = JiraIcon;

// import JqueryIcon from 'react-material-icon-svg/dist/Jquery';
// export const JqueryIconSvg = JqueryIcon;

// import JsfiddleIcon from 'react-material-icon-svg/dist/Jsfiddle';
// export const JsfiddleIconSvg = JsfiddleIcon;

// import JsonIcon from 'react-material-icon-svg/dist/Json';
// export const JsonIconSvg = JsonIcon;

// import JudaismIcon from 'react-material-icon-svg/dist/Judaism';
// export const JudaismIconSvg = JudaismIcon;

// import KabaddiIcon from 'react-material-icon-svg/dist/Kabaddi';
// export const KabaddiIconSvg = KabaddiIcon;

// import KarateIcon from 'react-material-icon-svg/dist/Karate';
// export const KarateIconSvg = KarateIcon;

// import KegIcon from 'react-material-icon-svg/dist/Keg';
// export const KegIconSvg = KegIcon;

// import KettleIcon from 'react-material-icon-svg/dist/Kettle';
// export const KettleIconSvg = KettleIcon;

// import KeyIcon from 'react-material-icon-svg/dist/Key';
// export const KeyIconSvg = KeyIcon;

// import KeyChangeIcon from 'react-material-icon-svg/dist/KeyChange';
// export const KeyChangeIconSvg = KeyChangeIcon;

// import KeyMinusIcon from 'react-material-icon-svg/dist/KeyMinus';
// export const KeyMinusIconSvg = KeyMinusIcon;

// import KeyOutlineIcon from 'react-material-icon-svg/dist/KeyOutline';
// export const KeyOutlineIconSvg = KeyOutlineIcon;

// import KeyPlusIcon from 'react-material-icon-svg/dist/KeyPlus';
// export const KeyPlusIconSvg = KeyPlusIcon;

// import KeyRemoveIcon from 'react-material-icon-svg/dist/KeyRemove';
// export const KeyRemoveIconSvg = KeyRemoveIcon;

// import KeyVariantIcon from 'react-material-icon-svg/dist/KeyVariant';
// export const KeyVariantIconSvg = KeyVariantIcon;

// import KeyboardIcon from 'react-material-icon-svg/dist/Keyboard';
// export const KeyboardIconSvg = KeyboardIcon;

// import KeyboardBackspaceIcon from 'react-material-icon-svg/dist/KeyboardBackspace';
// export const KeyboardBackspaceIconSvg = KeyboardBackspaceIcon;

// import KeyboardCapsIcon from 'react-material-icon-svg/dist/KeyboardCaps';
// export const KeyboardCapsIconSvg = KeyboardCapsIcon;

// import KeyboardCloseIcon from 'react-material-icon-svg/dist/KeyboardClose';
// export const KeyboardCloseIconSvg = KeyboardCloseIcon;

// import KeyboardOffIcon from 'react-material-icon-svg/dist/KeyboardOff';
// export const KeyboardOffIconSvg = KeyboardOffIcon;

// import KeyboardOffOutlineIcon from 'react-material-icon-svg/dist/KeyboardOffOutline';
// export const KeyboardOffOutlineIconSvg = KeyboardOffOutlineIcon;

// import KeyboardOutlineIcon from 'react-material-icon-svg/dist/KeyboardOutline';
// export const KeyboardOutlineIconSvg = KeyboardOutlineIcon;

// import KeyboardReturnIcon from 'react-material-icon-svg/dist/KeyboardReturn';
// export const KeyboardReturnIconSvg = KeyboardReturnIcon;

// import KeyboardSettingsIcon from 'react-material-icon-svg/dist/KeyboardSettings';
// export const KeyboardSettingsIconSvg = KeyboardSettingsIcon;

// import KeyboardSettingsOutlineIcon from 'react-material-icon-svg/dist/KeyboardSettingsOutline';
// export const KeyboardSettingsOutlineIconSvg = KeyboardSettingsOutlineIcon;

// import KeyboardTabIcon from 'react-material-icon-svg/dist/KeyboardTab';
// export const KeyboardTabIconSvg = KeyboardTabIcon;

// import KeyboardVariantIcon from 'react-material-icon-svg/dist/KeyboardVariant';
// export const KeyboardVariantIconSvg = KeyboardVariantIcon;

// import KickstarterIcon from 'react-material-icon-svg/dist/Kickstarter';
// export const KickstarterIconSvg = KickstarterIcon;

// import KnifeIcon from 'react-material-icon-svg/dist/Knife';
// export const KnifeIconSvg = KnifeIcon;

// import KnifeMilitaryIcon from 'react-material-icon-svg/dist/KnifeMilitary';
// export const KnifeMilitaryIconSvg = KnifeMilitaryIcon;

// import KodiIcon from 'react-material-icon-svg/dist/Kodi';
// export const KodiIconSvg = KodiIcon;

// import LabelIcon from 'react-material-icon-svg/dist/Label';
// export const LabelIconSvg = LabelIcon;

// import LabelOffIcon from 'react-material-icon-svg/dist/LabelOff';
// export const LabelOffIconSvg = LabelOffIcon;

// import LabelOffOutlineIcon from 'react-material-icon-svg/dist/LabelOffOutline';
// export const LabelOffOutlineIconSvg = LabelOffOutlineIcon;

// import LabelOutlineIcon from 'react-material-icon-svg/dist/LabelOutline';
// export const LabelOutlineIconSvg = LabelOutlineIcon;

// import LabelVariantIcon from 'react-material-icon-svg/dist/LabelVariant';
// export const LabelVariantIconSvg = LabelVariantIcon;

// import LabelVariantOutlineIcon from 'react-material-icon-svg/dist/LabelVariantOutline';
// export const LabelVariantOutlineIconSvg = LabelVariantOutlineIcon;

// import LadybugIcon from 'react-material-icon-svg/dist/Ladybug';
// export const LadybugIconSvg = LadybugIcon;

// import LambdaIcon from 'react-material-icon-svg/dist/Lambda';
// export const LambdaIconSvg = LambdaIcon;

// import LampIcon from 'react-material-icon-svg/dist/Lamp';
// export const LampIconSvg = LampIcon;

// import LanIcon from 'react-material-icon-svg/dist/Lan';
// export const LanIconSvg = LanIcon;

// import LanConnectIcon from 'react-material-icon-svg/dist/LanConnect';
// export const LanConnectIconSvg = LanConnectIcon;

// import LanDisconnectIcon from 'react-material-icon-svg/dist/LanDisconnect';
// export const LanDisconnectIconSvg = LanDisconnectIcon;

// import LanPendingIcon from 'react-material-icon-svg/dist/LanPending';
// export const LanPendingIconSvg = LanPendingIcon;

// import LanguageCIcon from 'react-material-icon-svg/dist/LanguageC';
// export const LanguageCIconSvg = LanguageCIcon;

// import LanguageCppIcon from 'react-material-icon-svg/dist/LanguageCpp';
// export const LanguageCppIconSvg = LanguageCppIcon;

// import LanguageCsharpIcon from 'react-material-icon-svg/dist/LanguageCsharp';
// export const LanguageCsharpIconSvg = LanguageCsharpIcon;

// import LanguageCss3Icon from 'react-material-icon-svg/dist/LanguageCss3';
// export const LanguageCss3IconSvg = LanguageCss3Icon;

// import LanguageGoIcon from 'react-material-icon-svg/dist/LanguageGo';
// export const LanguageGoIconSvg = LanguageGoIcon;

// import LanguageHaskellIcon from 'react-material-icon-svg/dist/LanguageHaskell';
// export const LanguageHaskellIconSvg = LanguageHaskellIcon;

// import LanguageHtml5Icon from 'react-material-icon-svg/dist/LanguageHtml5';
// export const LanguageHtml5IconSvg = LanguageHtml5Icon;

// import LanguageJavaIcon from 'react-material-icon-svg/dist/LanguageJava';
// export const LanguageJavaIconSvg = LanguageJavaIcon;

// import LanguageJavascriptIcon from 'react-material-icon-svg/dist/LanguageJavascript';
// export const LanguageJavascriptIconSvg = LanguageJavascriptIcon;

// import LanguageLuaIcon from 'react-material-icon-svg/dist/LanguageLua';
// export const LanguageLuaIconSvg = LanguageLuaIcon;

// import LanguagePhpIcon from 'react-material-icon-svg/dist/LanguagePhp';
// export const LanguagePhpIconSvg = LanguagePhpIcon;

// import LanguagePythonIcon from 'react-material-icon-svg/dist/LanguagePython';
// export const LanguagePythonIconSvg = LanguagePythonIcon;

// import LanguagePythonTextIcon from 'react-material-icon-svg/dist/LanguagePythonText';
// export const LanguagePythonTextIconSvg = LanguagePythonTextIcon;

// import LanguageRIcon from 'react-material-icon-svg/dist/LanguageR';
// export const LanguageRIconSvg = LanguageRIcon;

// import LanguageRubyOnRailsIcon from 'react-material-icon-svg/dist/LanguageRubyOnRails';
// export const LanguageRubyOnRailsIconSvg = LanguageRubyOnRailsIcon;

// import LanguageSwiftIcon from 'react-material-icon-svg/dist/LanguageSwift';
// export const LanguageSwiftIconSvg = LanguageSwiftIcon;

// import LanguageTypescriptIcon from 'react-material-icon-svg/dist/LanguageTypescript';
// export const LanguageTypescriptIconSvg = LanguageTypescriptIcon;

// import LaptopIcon from 'react-material-icon-svg/dist/Laptop';
// export const LaptopIconSvg = LaptopIcon;

// import LaptopChromebookIcon from 'react-material-icon-svg/dist/LaptopChromebook';
// export const LaptopChromebookIconSvg = LaptopChromebookIcon;

// import LaptopMacIcon from 'react-material-icon-svg/dist/LaptopMac';
// export const LaptopMacIconSvg = LaptopMacIcon;

// import LaptopOffIcon from 'react-material-icon-svg/dist/LaptopOff';
// export const LaptopOffIconSvg = LaptopOffIcon;

// import LaptopWindowsIcon from 'react-material-icon-svg/dist/LaptopWindows';
// export const LaptopWindowsIconSvg = LaptopWindowsIcon;

// import LaravelIcon from 'react-material-icon-svg/dist/Laravel';
// export const LaravelIconSvg = LaravelIcon;

// import LastfmIcon from 'react-material-icon-svg/dist/Lastfm';
// export const LastfmIconSvg = LastfmIcon;

// import LastpassIcon from 'react-material-icon-svg/dist/Lastpass';
// export const LastpassIconSvg = LastpassIcon;

// import LaunchIcon from 'react-material-icon-svg/dist/Launch';
// export const LaunchIconSvg = LaunchIcon;

// import LavaLampIcon from 'react-material-icon-svg/dist/LavaLamp';
// export const LavaLampIconSvg = LavaLampIcon;

// import LayersIcon from 'react-material-icon-svg/dist/Layers';
// export const LayersIconSvg = LayersIcon;

// import LayersMinusIcon from 'react-material-icon-svg/dist/LayersMinus';
// export const LayersMinusIconSvg = LayersMinusIcon;

// import LayersOffIcon from 'react-material-icon-svg/dist/LayersOff';
// export const LayersOffIconSvg = LayersOffIcon;

// import LayersOffOutlineIcon from 'react-material-icon-svg/dist/LayersOffOutline';
// export const LayersOffOutlineIconSvg = LayersOffOutlineIcon;

// import LayersOutlineIcon from 'react-material-icon-svg/dist/LayersOutline';
// export const LayersOutlineIconSvg = LayersOutlineIcon;

// import LayersPlusIcon from 'react-material-icon-svg/dist/LayersPlus';
// export const LayersPlusIconSvg = LayersPlusIcon;

// import LayersRemoveIcon from 'react-material-icon-svg/dist/LayersRemove';
// export const LayersRemoveIconSvg = LayersRemoveIcon;

// import LeadPencilIcon from 'react-material-icon-svg/dist/LeadPencil';
// export const LeadPencilIconSvg = LeadPencilIcon;

// import LeafIcon from 'react-material-icon-svg/dist/Leaf';
// export const LeafIconSvg = LeafIcon;

// import LeafMapleIcon from 'react-material-icon-svg/dist/LeafMaple';
// export const LeafMapleIconSvg = LeafMapleIcon;

// import LeakIcon from 'react-material-icon-svg/dist/Leak';
// export const LeakIconSvg = LeakIcon;

// import LeakOffIcon from 'react-material-icon-svg/dist/LeakOff';
// export const LeakOffIconSvg = LeakOffIcon;

// import LedOffIcon from 'react-material-icon-svg/dist/LedOff';
// export const LedOffIconSvg = LedOffIcon;

// import LedOnIcon from 'react-material-icon-svg/dist/LedOn';
// export const LedOnIconSvg = LedOnIcon;

// import LedOutlineIcon from 'react-material-icon-svg/dist/LedOutline';
// export const LedOutlineIconSvg = LedOutlineIcon;

// import LedStripIcon from 'react-material-icon-svg/dist/LedStrip';
// export const LedStripIconSvg = LedStripIcon;

// import LedVariantOffIcon from 'react-material-icon-svg/dist/LedVariantOff';
// export const LedVariantOffIconSvg = LedVariantOffIcon;

// import LedVariantOnIcon from 'react-material-icon-svg/dist/LedVariantOn';
// export const LedVariantOnIconSvg = LedVariantOnIcon;

// import LedVariantOutlineIcon from 'react-material-icon-svg/dist/LedVariantOutline';
// export const LedVariantOutlineIconSvg = LedVariantOutlineIcon;

// import LessThanIcon from 'react-material-icon-svg/dist/LessThan';
// export const LessThanIconSvg = LessThanIcon;

// import LessThanOrEqualIcon from 'react-material-icon-svg/dist/LessThanOrEqual';
// export const LessThanOrEqualIconSvg = LessThanOrEqualIcon;

// import LibraryIcon from 'react-material-icon-svg/dist/Library';
// export const LibraryIconSvg = LibraryIcon;

// import LibraryBooksIcon from 'react-material-icon-svg/dist/LibraryBooks';
// export const LibraryBooksIconSvg = LibraryBooksIcon;

// import LibraryMovieIcon from 'react-material-icon-svg/dist/LibraryMovie';
// export const LibraryMovieIconSvg = LibraryMovieIcon;

// import LibraryMusicIcon from 'react-material-icon-svg/dist/LibraryMusic';
// export const LibraryMusicIconSvg = LibraryMusicIcon;

// import LibraryPlusIcon from 'react-material-icon-svg/dist/LibraryPlus';
// export const LibraryPlusIconSvg = LibraryPlusIcon;

// import LibraryShelvesIcon from 'react-material-icon-svg/dist/LibraryShelves';
// export const LibraryShelvesIconSvg = LibraryShelvesIcon;

// import LibraryVideoIcon from 'react-material-icon-svg/dist/LibraryVideo';
// export const LibraryVideoIconSvg = LibraryVideoIcon;

// import LifebuoyIcon from 'react-material-icon-svg/dist/Lifebuoy';
// export const LifebuoyIconSvg = LifebuoyIcon;

// import LightSwitchIcon from 'react-material-icon-svg/dist/LightSwitch';
// export const LightSwitchIconSvg = LightSwitchIcon;

// import LightbulbIcon from 'react-material-icon-svg/dist/Lightbulb';
// export const LightbulbIconSvg = LightbulbIcon;

// import LightbulbOffIcon from 'react-material-icon-svg/dist/LightbulbOff';
// export const LightbulbOffIconSvg = LightbulbOffIcon;

// import LightbulbOffOutlineIcon from 'react-material-icon-svg/dist/LightbulbOffOutline';
// export const LightbulbOffOutlineIconSvg = LightbulbOffOutlineIcon;

// import LightbulbOnIcon from 'react-material-icon-svg/dist/LightbulbOn';
// export const LightbulbOnIconSvg = LightbulbOnIcon;

// import LightbulbOnOutlineIcon from 'react-material-icon-svg/dist/LightbulbOnOutline';
// export const LightbulbOnOutlineIconSvg = LightbulbOnOutlineIcon;

// import LightbulbOutlineIcon from 'react-material-icon-svg/dist/LightbulbOutline';
// export const LightbulbOutlineIconSvg = LightbulbOutlineIcon;

// import LighthouseIcon from 'react-material-icon-svg/dist/Lighthouse';
// export const LighthouseIconSvg = LighthouseIcon;

// import LighthouseOnIcon from 'react-material-icon-svg/dist/LighthouseOn';
// export const LighthouseOnIconSvg = LighthouseOnIcon;

// import LinkIcon from 'react-material-icon-svg/dist/Link';
// export const LinkIconSvg = LinkIcon;

// import LinkBoxIcon from 'react-material-icon-svg/dist/LinkBox';
// export const LinkBoxIconSvg = LinkBoxIcon;

// import LinkBoxOutlineIcon from 'react-material-icon-svg/dist/LinkBoxOutline';
// export const LinkBoxOutlineIconSvg = LinkBoxOutlineIcon;

// import LinkBoxVariantIcon from 'react-material-icon-svg/dist/LinkBoxVariant';
// export const LinkBoxVariantIconSvg = LinkBoxVariantIcon;

// import LinkBoxVariantOutlineIcon from 'react-material-icon-svg/dist/LinkBoxVariantOutline';
// export const LinkBoxVariantOutlineIconSvg = LinkBoxVariantOutlineIcon;

// import LinkOffIcon from 'react-material-icon-svg/dist/LinkOff';
// export const LinkOffIconSvg = LinkOffIcon;

// import LinkPlusIcon from 'react-material-icon-svg/dist/LinkPlus';
// export const LinkPlusIconSvg = LinkPlusIcon;

// import LinkVariantIcon from 'react-material-icon-svg/dist/LinkVariant';
// export const LinkVariantIconSvg = LinkVariantIcon;

// import LinkVariantOffIcon from 'react-material-icon-svg/dist/LinkVariantOff';
// export const LinkVariantOffIconSvg = LinkVariantOffIcon;

// import LinkedinIcon from 'react-material-icon-svg/dist/Linkedin';
// export const LinkedinIconSvg = LinkedinIcon;

// import LinkedinBoxIcon from 'react-material-icon-svg/dist/LinkedinBox';
// export const LinkedinBoxIconSvg = LinkedinBoxIcon;

// import LinuxIcon from 'react-material-icon-svg/dist/Linux';
// export const LinuxIconSvg = LinuxIcon;

// import LinuxMintIcon from 'react-material-icon-svg/dist/LinuxMint';
// export const LinuxMintIconSvg = LinuxMintIcon;

// import LitecoinIcon from 'react-material-icon-svg/dist/Litecoin';
// export const LitecoinIconSvg = LitecoinIcon;

// import LoadingIcon from 'react-material-icon-svg/dist/Loading';
// export const LoadingIconSvg = LoadingIcon;

// import LockIcon from 'react-material-icon-svg/dist/Lock';
// export const LockIconSvg = LockIcon;

// import LockAlertIcon from 'react-material-icon-svg/dist/LockAlert';
// export const LockAlertIconSvg = LockAlertIcon;

// import LockClockIcon from 'react-material-icon-svg/dist/LockClock';
// export const LockClockIconSvg = LockClockIcon;

// import LockOpenIcon from 'react-material-icon-svg/dist/LockOpen';
// export const LockOpenIconSvg = LockOpenIcon;

// import LockOpenOutlineIcon from 'react-material-icon-svg/dist/LockOpenOutline';
// export const LockOpenOutlineIconSvg = LockOpenOutlineIcon;

// import LockOutlineIcon from 'react-material-icon-svg/dist/LockOutline';
// export const LockOutlineIconSvg = LockOutlineIcon;

// import LockPatternIcon from 'react-material-icon-svg/dist/LockPattern';
// export const LockPatternIconSvg = LockPatternIcon;

// import LockPlusIcon from 'react-material-icon-svg/dist/LockPlus';
// export const LockPlusIconSvg = LockPlusIcon;

// import LockQuestionIcon from 'react-material-icon-svg/dist/LockQuestion';
// export const LockQuestionIconSvg = LockQuestionIcon;

// import LockResetIcon from 'react-material-icon-svg/dist/LockReset';
// export const LockResetIconSvg = LockResetIcon;

// import LockSmartIcon from 'react-material-icon-svg/dist/LockSmart';
// export const LockSmartIconSvg = LockSmartIcon;

// import LockerIcon from 'react-material-icon-svg/dist/Locker';
// export const LockerIconSvg = LockerIcon;

// import LockerMultipleIcon from 'react-material-icon-svg/dist/LockerMultiple';
// export const LockerMultipleIconSvg = LockerMultipleIcon;

// import LoginIcon from 'react-material-icon-svg/dist/Login';
// export const LoginIconSvg = LoginIcon;

// import LoginVariantIcon from 'react-material-icon-svg/dist/LoginVariant';
// export const LoginVariantIconSvg = LoginVariantIcon;

// import LogoutIcon from 'react-material-icon-svg/dist/Logout';
// export const LogoutIconSvg = LogoutIcon;

// import LogoutVariantIcon from 'react-material-icon-svg/dist/LogoutVariant';
// export const LogoutVariantIconSvg = LogoutVariantIcon;

// import LooksIcon from 'react-material-icon-svg/dist/Looks';
// export const LooksIconSvg = LooksIcon;

// import LoopIcon from 'react-material-icon-svg/dist/Loop';
// export const LoopIconSvg = LoopIcon;

// import LoupeIcon from 'react-material-icon-svg/dist/Loupe';
// export const LoupeIconSvg = LoupeIcon;

// import LumxIcon from 'react-material-icon-svg/dist/Lumx';
// export const LumxIconSvg = LumxIcon;

// import LyftIcon from 'react-material-icon-svg/dist/Lyft';
// export const LyftIconSvg = LyftIcon;

// import MagnetIcon from 'react-material-icon-svg/dist/Magnet';
// export const MagnetIconSvg = MagnetIcon;

// import MagnetOnIcon from 'react-material-icon-svg/dist/MagnetOn';
// export const MagnetOnIconSvg = MagnetOnIcon;

import MagnifyIcon from 'react-material-icon-svg/dist/Magnify';
export const MagnifyIconSvg = MagnifyIcon;

// import MagnifyCloseIcon from 'react-material-icon-svg/dist/MagnifyClose';
// export const MagnifyCloseIconSvg = MagnifyCloseIcon;

// import MagnifyMinusIcon from 'react-material-icon-svg/dist/MagnifyMinus';
// export const MagnifyMinusIconSvg = MagnifyMinusIcon;

// import MagnifyMinusCursorIcon from 'react-material-icon-svg/dist/MagnifyMinusCursor';
// export const MagnifyMinusCursorIconSvg = MagnifyMinusCursorIcon;

// import MagnifyMinusOutlineIcon from 'react-material-icon-svg/dist/MagnifyMinusOutline';
// export const MagnifyMinusOutlineIconSvg = MagnifyMinusOutlineIcon;

// import MagnifyPlusIcon from 'react-material-icon-svg/dist/MagnifyPlus';
// export const MagnifyPlusIconSvg = MagnifyPlusIcon;

// import MagnifyPlusCursorIcon from 'react-material-icon-svg/dist/MagnifyPlusCursor';
// export const MagnifyPlusCursorIconSvg = MagnifyPlusCursorIcon;

// import MagnifyPlusOutlineIcon from 'react-material-icon-svg/dist/MagnifyPlusOutline';
// export const MagnifyPlusOutlineIconSvg = MagnifyPlusOutlineIcon;

// import MailIcon from 'react-material-icon-svg/dist/Mail';
// export const MailIconSvg = MailIcon;

// import MailRuIcon from 'react-material-icon-svg/dist/MailRu';
// export const MailRuIconSvg = MailRuIcon;

// import MailboxIcon from 'react-material-icon-svg/dist/Mailbox';
// export const MailboxIconSvg = MailboxIcon;

// import MailboxOpenIcon from 'react-material-icon-svg/dist/MailboxOpen';
// export const MailboxOpenIconSvg = MailboxOpenIcon;

// import MailboxOpenOutlineIcon from 'react-material-icon-svg/dist/MailboxOpenOutline';
// export const MailboxOpenOutlineIconSvg = MailboxOpenOutlineIcon;

// import MailboxOpenUpIcon from 'react-material-icon-svg/dist/MailboxOpenUp';
// export const MailboxOpenUpIconSvg = MailboxOpenUpIcon;

// import MailboxOpenUpOutlineIcon from 'react-material-icon-svg/dist/MailboxOpenUpOutline';
// export const MailboxOpenUpOutlineIconSvg = MailboxOpenUpOutlineIcon;

// import MailboxOutlineIcon from 'react-material-icon-svg/dist/MailboxOutline';
// export const MailboxOutlineIconSvg = MailboxOutlineIcon;

// import MailboxUpIcon from 'react-material-icon-svg/dist/MailboxUp';
// export const MailboxUpIconSvg = MailboxUpIcon;

// import MailboxUpOutlineIcon from 'react-material-icon-svg/dist/MailboxUpOutline';
// export const MailboxUpOutlineIconSvg = MailboxUpOutlineIcon;

// import MapIcon from 'react-material-icon-svg/dist/Map';
// export const MapIconSvg = MapIcon;

// import MapCheckIcon from 'react-material-icon-svg/dist/MapCheck';
// export const MapCheckIconSvg = MapCheckIcon;

// import MapCheckOutlineIcon from 'react-material-icon-svg/dist/MapCheckOutline';
// export const MapCheckOutlineIconSvg = MapCheckOutlineIcon;

// import MapClockIcon from 'react-material-icon-svg/dist/MapClock';
// export const MapClockIconSvg = MapClockIcon;

// import MapClockOutlineIcon from 'react-material-icon-svg/dist/MapClockOutline';
// export const MapClockOutlineIconSvg = MapClockOutlineIcon;

// import MapLegendIcon from 'react-material-icon-svg/dist/MapLegend';
// export const MapLegendIconSvg = MapLegendIcon;

// import MapMarkerIcon from 'react-material-icon-svg/dist/MapMarker';
// export const MapMarkerIconSvg = MapMarkerIcon;

// import MapMarkerCheckIcon from 'react-material-icon-svg/dist/MapMarkerCheck';
// export const MapMarkerCheckIconSvg = MapMarkerCheckIcon;

// import MapMarkerCircleIcon from 'react-material-icon-svg/dist/MapMarkerCircle';
// export const MapMarkerCircleIconSvg = MapMarkerCircleIcon;

// import MapMarkerDistanceIcon from 'react-material-icon-svg/dist/MapMarkerDistance';
// export const MapMarkerDistanceIconSvg = MapMarkerDistanceIcon;

// import MapMarkerMinusIcon from 'react-material-icon-svg/dist/MapMarkerMinus';
// export const MapMarkerMinusIconSvg = MapMarkerMinusIcon;

// import MapMarkerMultipleIcon from 'react-material-icon-svg/dist/MapMarkerMultiple';
// export const MapMarkerMultipleIconSvg = MapMarkerMultipleIcon;

// import MapMarkerOffIcon from 'react-material-icon-svg/dist/MapMarkerOff';
// export const MapMarkerOffIconSvg = MapMarkerOffIcon;

// import MapMarkerOutlineIcon from 'react-material-icon-svg/dist/MapMarkerOutline';
// export const MapMarkerOutlineIconSvg = MapMarkerOutlineIcon;

// import MapMarkerPathIcon from 'react-material-icon-svg/dist/MapMarkerPath';
// export const MapMarkerPathIconSvg = MapMarkerPathIcon;

// import MapMarkerPlusIcon from 'react-material-icon-svg/dist/MapMarkerPlus';
// export const MapMarkerPlusIconSvg = MapMarkerPlusIcon;

// import MapMarkerRadiusIcon from 'react-material-icon-svg/dist/MapMarkerRadius';
// export const MapMarkerRadiusIconSvg = MapMarkerRadiusIcon;

// import MapMinusIcon from 'react-material-icon-svg/dist/MapMinus';
// export const MapMinusIconSvg = MapMinusIcon;

// import MapOutlineIcon from 'react-material-icon-svg/dist/MapOutline';
// export const MapOutlineIconSvg = MapOutlineIcon;

// import MapPlusIcon from 'react-material-icon-svg/dist/MapPlus';
// export const MapPlusIconSvg = MapPlusIcon;

// import MapSearchIcon from 'react-material-icon-svg/dist/MapSearch';
// export const MapSearchIconSvg = MapSearchIcon;

// import MapSearchOutlineIcon from 'react-material-icon-svg/dist/MapSearchOutline';
// export const MapSearchOutlineIconSvg = MapSearchOutlineIcon;

// import MapboxIcon from 'react-material-icon-svg/dist/Mapbox';
// export const MapboxIconSvg = MapboxIcon;

// import MarginIcon from 'react-material-icon-svg/dist/Margin';
// export const MarginIconSvg = MarginIcon;

// import MarkdownIcon from 'react-material-icon-svg/dist/Markdown';
// export const MarkdownIconSvg = MarkdownIcon;

// import MarkerIcon from 'react-material-icon-svg/dist/Marker';
// export const MarkerIconSvg = MarkerIcon;

// import MarkerCancelIcon from 'react-material-icon-svg/dist/MarkerCancel';
// export const MarkerCancelIconSvg = MarkerCancelIcon;

// import MarkerCheckIcon from 'react-material-icon-svg/dist/MarkerCheck';
// export const MarkerCheckIconSvg = MarkerCheckIcon;

// import MastodonIcon from 'react-material-icon-svg/dist/Mastodon';
// export const MastodonIconSvg = MastodonIcon;

// import MastodonVariantIcon from 'react-material-icon-svg/dist/MastodonVariant';
// export const MastodonVariantIconSvg = MastodonVariantIcon;

// import MaterialDesignIcon from 'react-material-icon-svg/dist/MaterialDesign';
// export const MaterialDesignIconSvg = MaterialDesignIcon;

// import MaterialUiIcon from 'react-material-icon-svg/dist/MaterialUi';
// export const MaterialUiIconSvg = MaterialUiIcon;

// import MathCompassIcon from 'react-material-icon-svg/dist/MathCompass';
// export const MathCompassIconSvg = MathCompassIcon;

// import MathCosIcon from 'react-material-icon-svg/dist/MathCos';
// export const MathCosIconSvg = MathCosIcon;

// import MathSinIcon from 'react-material-icon-svg/dist/MathSin';
// export const MathSinIconSvg = MathSinIcon;

// import MathTanIcon from 'react-material-icon-svg/dist/MathTan';
// export const MathTanIconSvg = MathTanIcon;

// import MatrixIcon from 'react-material-icon-svg/dist/Matrix';
// export const MatrixIconSvg = MatrixIcon;

// import MaxcdnIcon from 'react-material-icon-svg/dist/Maxcdn';
// export const MaxcdnIconSvg = MaxcdnIcon;

// import MedalIcon from 'react-material-icon-svg/dist/Medal';
// export const MedalIconSvg = MedalIcon;

// import MedicalBagIcon from 'react-material-icon-svg/dist/MedicalBag';
// export const MedicalBagIconSvg = MedicalBagIcon;

// import MediumIcon from 'react-material-icon-svg/dist/Medium';
// export const MediumIconSvg = MediumIcon;

// import MeetupIcon from 'react-material-icon-svg/dist/Meetup';
// export const MeetupIconSvg = MeetupIcon;

// import MemoryIcon from 'react-material-icon-svg/dist/Memory';
// export const MemoryIconSvg = MemoryIcon;

// import MenuIcon from 'react-material-icon-svg/dist/Menu';
// export const MenuIconSvg = MenuIcon;

// import MenuDownIcon from 'react-material-icon-svg/dist/MenuDown';
// export const MenuDownIconSvg = MenuDownIcon;

// import MenuDownOutlineIcon from 'react-material-icon-svg/dist/MenuDownOutline';
// export const MenuDownOutlineIconSvg = MenuDownOutlineIcon;

// import MenuLeftIcon from 'react-material-icon-svg/dist/MenuLeft';
// export const MenuLeftIconSvg = MenuLeftIcon;

// import MenuLeftOutlineIcon from 'react-material-icon-svg/dist/MenuLeftOutline';
// export const MenuLeftOutlineIconSvg = MenuLeftOutlineIcon;

// import MenuOpenIcon from 'react-material-icon-svg/dist/MenuOpen';
// export const MenuOpenIconSvg = MenuOpenIcon;

// import MenuRightIcon from 'react-material-icon-svg/dist/MenuRight';
// export const MenuRightIconSvg = MenuRightIcon;

// import MenuRightOutlineIcon from 'react-material-icon-svg/dist/MenuRightOutline';
// export const MenuRightOutlineIconSvg = MenuRightOutlineIcon;

// import MenuSwapIcon from 'react-material-icon-svg/dist/MenuSwap';
// export const MenuSwapIconSvg = MenuSwapIcon;

// import MenuSwapOutlineIcon from 'react-material-icon-svg/dist/MenuSwapOutline';
// export const MenuSwapOutlineIconSvg = MenuSwapOutlineIcon;

// import MenuUpIcon from 'react-material-icon-svg/dist/MenuUp';
// export const MenuUpIconSvg = MenuUpIcon;

// import MenuUpOutlineIcon from 'react-material-icon-svg/dist/MenuUpOutline';
// export const MenuUpOutlineIconSvg = MenuUpOutlineIcon;

// import MessageIcon from 'react-material-icon-svg/dist/Message';
// export const MessageIconSvg = MessageIcon;

// import MessageAlertIcon from 'react-material-icon-svg/dist/MessageAlert';
// export const MessageAlertIconSvg = MessageAlertIcon;

// import MessageAlertOutlineIcon from 'react-material-icon-svg/dist/MessageAlertOutline';
// export const MessageAlertOutlineIconSvg = MessageAlertOutlineIcon;

// import MessageBulletedIcon from 'react-material-icon-svg/dist/MessageBulleted';
// export const MessageBulletedIconSvg = MessageBulletedIcon;

// import MessageBulletedOffIcon from 'react-material-icon-svg/dist/MessageBulletedOff';
// export const MessageBulletedOffIconSvg = MessageBulletedOffIcon;

// import MessageDrawIcon from 'react-material-icon-svg/dist/MessageDraw';
// export const MessageDrawIconSvg = MessageDrawIcon;

// import MessageImageIcon from 'react-material-icon-svg/dist/MessageImage';
// export const MessageImageIconSvg = MessageImageIcon;

// import MessageOutlineIcon from 'react-material-icon-svg/dist/MessageOutline';
// export const MessageOutlineIconSvg = MessageOutlineIcon;

// import MessagePlusIcon from 'react-material-icon-svg/dist/MessagePlus';
// export const MessagePlusIconSvg = MessagePlusIcon;

// import MessageProcessingIcon from 'react-material-icon-svg/dist/MessageProcessing';
// export const MessageProcessingIconSvg = MessageProcessingIcon;

// import MessageReplyIcon from 'react-material-icon-svg/dist/MessageReply';
// export const MessageReplyIconSvg = MessageReplyIcon;

// import MessageReplyTextIcon from 'react-material-icon-svg/dist/MessageReplyText';
// export const MessageReplyTextIconSvg = MessageReplyTextIcon;

// import MessageSettingsIcon from 'react-material-icon-svg/dist/MessageSettings';
// export const MessageSettingsIconSvg = MessageSettingsIcon;

// import MessageSettingsVariantIcon from 'react-material-icon-svg/dist/MessageSettingsVariant';
// export const MessageSettingsVariantIconSvg = MessageSettingsVariantIcon;

import MessageTextIcon from 'react-material-icon-svg/dist/MessageText';
export const MessageTextIconSvg = MessageTextIcon;

// import MessageTextOutlineIcon from 'react-material-icon-svg/dist/MessageTextOutline';
// export const MessageTextOutlineIconSvg = MessageTextOutlineIcon;

// import MessageVideoIcon from 'react-material-icon-svg/dist/MessageVideo';
// export const MessageVideoIconSvg = MessageVideoIcon;

// import MeteorIcon from 'react-material-icon-svg/dist/Meteor';
// export const MeteorIconSvg = MeteorIcon;

// import MetronomeIcon from 'react-material-icon-svg/dist/Metronome';
// export const MetronomeIconSvg = MetronomeIcon;

// import MetronomeTickIcon from 'react-material-icon-svg/dist/MetronomeTick';
// export const MetronomeTickIconSvg = MetronomeTickIcon;

// import MicroSdIcon from 'react-material-icon-svg/dist/MicroSd';
// export const MicroSdIconSvg = MicroSdIcon;

// import MicrophoneIcon from 'react-material-icon-svg/dist/Microphone';
// export const MicrophoneIconSvg = MicrophoneIcon;

// import MicrophoneMinusIcon from 'react-material-icon-svg/dist/MicrophoneMinus';
// export const MicrophoneMinusIconSvg = MicrophoneMinusIcon;

// import MicrophoneOffIcon from 'react-material-icon-svg/dist/MicrophoneOff';
// export const MicrophoneOffIconSvg = MicrophoneOffIcon;

// import MicrophoneOutlineIcon from 'react-material-icon-svg/dist/MicrophoneOutline';
// export const MicrophoneOutlineIconSvg = MicrophoneOutlineIcon;

// import MicrophonePlusIcon from 'react-material-icon-svg/dist/MicrophonePlus';
// export const MicrophonePlusIconSvg = MicrophonePlusIcon;

// import MicrophoneSettingsIcon from 'react-material-icon-svg/dist/MicrophoneSettings';
// export const MicrophoneSettingsIconSvg = MicrophoneSettingsIcon;

// import MicrophoneVariantIcon from 'react-material-icon-svg/dist/MicrophoneVariant';
// export const MicrophoneVariantIconSvg = MicrophoneVariantIcon;

// import MicrophoneVariantOffIcon from 'react-material-icon-svg/dist/MicrophoneVariantOff';
// export const MicrophoneVariantOffIconSvg = MicrophoneVariantOffIcon;

// import MicroscopeIcon from 'react-material-icon-svg/dist/Microscope';
// export const MicroscopeIconSvg = MicroscopeIcon;

// import MicrosoftIcon from 'react-material-icon-svg/dist/Microsoft';
// export const MicrosoftIconSvg = MicrosoftIcon;

// import MicrosoftDynamicsIcon from 'react-material-icon-svg/dist/MicrosoftDynamics';
// export const MicrosoftDynamicsIconSvg = MicrosoftDynamicsIcon;

// import MicrowaveIcon from 'react-material-icon-svg/dist/Microwave';
// export const MicrowaveIconSvg = MicrowaveIcon;

// import MidiIcon from 'react-material-icon-svg/dist/Midi';
// export const MidiIconSvg = MidiIcon;

// import MidiPortIcon from 'react-material-icon-svg/dist/MidiPort';
// export const MidiPortIconSvg = MidiPortIcon;

// import MineIcon from 'react-material-icon-svg/dist/Mine';
// export const MineIconSvg = MineIcon;

// import MinecraftIcon from 'react-material-icon-svg/dist/Minecraft';
// export const MinecraftIconSvg = MinecraftIcon;

// import MiniSdIcon from 'react-material-icon-svg/dist/MiniSd';
// export const MiniSdIconSvg = MiniSdIcon;

// import MinidiscIcon from 'react-material-icon-svg/dist/Minidisc';
// export const MinidiscIconSvg = MinidiscIcon;

// import MinusIcon from 'react-material-icon-svg/dist/Minus';
// export const MinusIconSvg = MinusIcon;

// import MinusBoxIcon from 'react-material-icon-svg/dist/MinusBox';
// export const MinusBoxIconSvg = MinusBoxIcon;

// import MinusBoxOutlineIcon from 'react-material-icon-svg/dist/MinusBoxOutline';
// export const MinusBoxOutlineIconSvg = MinusBoxOutlineIcon;

// import MinusCircleIcon from 'react-material-icon-svg/dist/MinusCircle';
// export const MinusCircleIconSvg = MinusCircleIcon;

// import MinusCircleOutlineIcon from 'react-material-icon-svg/dist/MinusCircleOutline';
// export const MinusCircleOutlineIconSvg = MinusCircleOutlineIcon;

// import MinusNetworkIcon from 'react-material-icon-svg/dist/MinusNetwork';
// export const MinusNetworkIconSvg = MinusNetworkIcon;

// import MinusNetworkOutlineIcon from 'react-material-icon-svg/dist/MinusNetworkOutline';
// export const MinusNetworkOutlineIconSvg = MinusNetworkOutlineIcon;

// import MixcloudIcon from 'react-material-icon-svg/dist/Mixcloud';
// export const MixcloudIconSvg = MixcloudIcon;

// import MixedMartialArtsIcon from 'react-material-icon-svg/dist/MixedMartialArts';
// export const MixedMartialArtsIconSvg = MixedMartialArtsIcon;

// import MixedRealityIcon from 'react-material-icon-svg/dist/MixedReality';
// export const MixedRealityIconSvg = MixedRealityIcon;

// import MixerIcon from 'react-material-icon-svg/dist/Mixer';
// export const MixerIconSvg = MixerIcon;

// import MoleculeIcon from 'react-material-icon-svg/dist/Molecule';
// export const MoleculeIconSvg = MoleculeIcon;

// import MonitorIcon from 'react-material-icon-svg/dist/Monitor';
// export const MonitorIconSvg = MonitorIcon;

// import MonitorCellphoneIcon from 'react-material-icon-svg/dist/MonitorCellphone';
// export const MonitorCellphoneIconSvg = MonitorCellphoneIcon;

// import MonitorCellphoneStarIcon from 'react-material-icon-svg/dist/MonitorCellphoneStar';
// export const MonitorCellphoneStarIconSvg = MonitorCellphoneStarIcon;

// import MonitorDashboardIcon from 'react-material-icon-svg/dist/MonitorDashboard';
// export const MonitorDashboardIconSvg = MonitorDashboardIcon;

// import MonitorLockIcon from 'react-material-icon-svg/dist/MonitorLock';
// export const MonitorLockIconSvg = MonitorLockIcon;

// import MonitorMultipleIcon from 'react-material-icon-svg/dist/MonitorMultiple';
// export const MonitorMultipleIconSvg = MonitorMultipleIcon;

// import MonitorOffIcon from 'react-material-icon-svg/dist/MonitorOff';
// export const MonitorOffIconSvg = MonitorOffIcon;

// import MonitorScreenshotIcon from 'react-material-icon-svg/dist/MonitorScreenshot';
// export const MonitorScreenshotIconSvg = MonitorScreenshotIcon;

// import MonitorStarIcon from 'react-material-icon-svg/dist/MonitorStar';
// export const MonitorStarIconSvg = MonitorStarIcon;

// import MoreIcon from 'react-material-icon-svg/dist/More';
// export const MoreIconSvg = MoreIcon;

// import MotherNurseIcon from 'react-material-icon-svg/dist/MotherNurse';
// export const MotherNurseIconSvg = MotherNurseIcon;

// import MotionSensorIcon from 'react-material-icon-svg/dist/MotionSensor';
// export const MotionSensorIconSvg = MotionSensorIcon;

// import MotorbikeIcon from 'react-material-icon-svg/dist/Motorbike';
// export const MotorbikeIconSvg = MotorbikeIcon;

// import MouseIcon from 'react-material-icon-svg/dist/Mouse';
// export const MouseIconSvg = MouseIcon;

// import MouseBluetoothIcon from 'react-material-icon-svg/dist/MouseBluetooth';
// export const MouseBluetoothIconSvg = MouseBluetoothIcon;

// import MouseOffIcon from 'react-material-icon-svg/dist/MouseOff';
// export const MouseOffIconSvg = MouseOffIcon;

// import MouseVariantIcon from 'react-material-icon-svg/dist/MouseVariant';
// export const MouseVariantIconSvg = MouseVariantIcon;

// import MouseVariantOffIcon from 'react-material-icon-svg/dist/MouseVariantOff';
// export const MouseVariantOffIconSvg = MouseVariantOffIcon;

// import MoveResizeIcon from 'react-material-icon-svg/dist/MoveResize';
// export const MoveResizeIconSvg = MoveResizeIcon;

// import MoveResizeVariantIcon from 'react-material-icon-svg/dist/MoveResizeVariant';
// export const MoveResizeVariantIconSvg = MoveResizeVariantIcon;

// import MovieIcon from 'react-material-icon-svg/dist/Movie';
// export const MovieIconSvg = MovieIcon;

// import MovieOutlineIcon from 'react-material-icon-svg/dist/MovieOutline';
// export const MovieOutlineIconSvg = MovieOutlineIcon;

// import MovieRollIcon from 'react-material-icon-svg/dist/MovieRoll';
// export const MovieRollIconSvg = MovieRollIcon;

// import MuffinIcon from 'react-material-icon-svg/dist/Muffin';
// export const MuffinIconSvg = MuffinIcon;

// import MultiplicationIcon from 'react-material-icon-svg/dist/Multiplication';
// export const MultiplicationIconSvg = MultiplicationIcon;

// import MultiplicationBoxIcon from 'react-material-icon-svg/dist/MultiplicationBox';
// export const MultiplicationBoxIconSvg = MultiplicationBoxIcon;

// import MushroomIcon from 'react-material-icon-svg/dist/Mushroom';
// export const MushroomIconSvg = MushroomIcon;

// import MushroomOutlineIcon from 'react-material-icon-svg/dist/MushroomOutline';
// export const MushroomOutlineIconSvg = MushroomOutlineIcon;

// import MusicIcon from 'react-material-icon-svg/dist/Music';
// export const MusicIconSvg = MusicIcon;

// import MusicBoxIcon from 'react-material-icon-svg/dist/MusicBox';
// export const MusicBoxIconSvg = MusicBoxIcon;

// import MusicBoxOutlineIcon from 'react-material-icon-svg/dist/MusicBoxOutline';
// export const MusicBoxOutlineIconSvg = MusicBoxOutlineIcon;

// import MusicCircleIcon from 'react-material-icon-svg/dist/MusicCircle';
// export const MusicCircleIconSvg = MusicCircleIcon;

// import MusicCircleOutlineIcon from 'react-material-icon-svg/dist/MusicCircleOutline';
// export const MusicCircleOutlineIconSvg = MusicCircleOutlineIcon;

// import MusicNoteIcon from 'react-material-icon-svg/dist/MusicNote';
// export const MusicNoteIconSvg = MusicNoteIcon;

// import MusicNoteBluetoothIcon from 'react-material-icon-svg/dist/MusicNoteBluetooth';
// export const MusicNoteBluetoothIconSvg = MusicNoteBluetoothIcon;

// import MusicNoteBluetoothOffIcon from 'react-material-icon-svg/dist/MusicNoteBluetoothOff';
// export const MusicNoteBluetoothOffIconSvg = MusicNoteBluetoothOffIcon;

// import MusicNoteEighthIcon from 'react-material-icon-svg/dist/MusicNoteEighth';
// export const MusicNoteEighthIconSvg = MusicNoteEighthIcon;

// import MusicNoteHalfIcon from 'react-material-icon-svg/dist/MusicNoteHalf';
// export const MusicNoteHalfIconSvg = MusicNoteHalfIcon;

// import MusicNoteOffIcon from 'react-material-icon-svg/dist/MusicNoteOff';
// export const MusicNoteOffIconSvg = MusicNoteOffIcon;

// import MusicNotePlusIcon from 'react-material-icon-svg/dist/MusicNotePlus';
// export const MusicNotePlusIconSvg = MusicNotePlusIcon;

// import MusicNoteQuarterIcon from 'react-material-icon-svg/dist/MusicNoteQuarter';
// export const MusicNoteQuarterIconSvg = MusicNoteQuarterIcon;

// import MusicNoteSixteenthIcon from 'react-material-icon-svg/dist/MusicNoteSixteenth';
// export const MusicNoteSixteenthIconSvg = MusicNoteSixteenthIcon;

// import MusicNoteWholeIcon from 'react-material-icon-svg/dist/MusicNoteWhole';
// export const MusicNoteWholeIconSvg = MusicNoteWholeIcon;

// import MusicOffIcon from 'react-material-icon-svg/dist/MusicOff';
// export const MusicOffIconSvg = MusicOffIcon;

// import NailIcon from 'react-material-icon-svg/dist/Nail';
// export const NailIconSvg = NailIcon;

// import NasIcon from 'react-material-icon-svg/dist/Nas';
// export const NasIconSvg = NasIcon;

// import NativescriptIcon from 'react-material-icon-svg/dist/Nativescript';
// export const NativescriptIconSvg = NativescriptIcon;

// import NatureIcon from 'react-material-icon-svg/dist/Nature';
// export const NatureIconSvg = NatureIcon;

// import NaturePeopleIcon from 'react-material-icon-svg/dist/NaturePeople';
// export const NaturePeopleIconSvg = NaturePeopleIcon;

// import NavigationIcon from 'react-material-icon-svg/dist/Navigation';
// export const NavigationIconSvg = NavigationIcon;

// import NearMeIcon from 'react-material-icon-svg/dist/NearMe';
// export const NearMeIconSvg = NearMeIcon;

// import NeedleIcon from 'react-material-icon-svg/dist/Needle';
// export const NeedleIconSvg = NeedleIcon;

// import NetflixIcon from 'react-material-icon-svg/dist/Netflix';
// export const NetflixIconSvg = NetflixIcon;

// import NetworkIcon from 'react-material-icon-svg/dist/Network';
// export const NetworkIconSvg = NetworkIcon;

// import NetworkOffIcon from 'react-material-icon-svg/dist/NetworkOff';
// export const NetworkOffIconSvg = NetworkOffIcon;

// import NetworkOffOutlineIcon from 'react-material-icon-svg/dist/NetworkOffOutline';
// export const NetworkOffOutlineIconSvg = NetworkOffOutlineIcon;

// import NetworkOutlineIcon from 'react-material-icon-svg/dist/NetworkOutline';
// export const NetworkOutlineIconSvg = NetworkOutlineIcon;

// import NetworkStrengthOffIcon from 'react-material-icon-svg/dist/NetworkStrengthOff';
// export const NetworkStrengthOffIconSvg = NetworkStrengthOffIcon;

// import NetworkStrengthOffOutlineIcon from 'react-material-icon-svg/dist/NetworkStrengthOffOutline';
// export const NetworkStrengthOffOutlineIconSvg = NetworkStrengthOffOutlineIcon;

// import NetworkStrengthOutlineIcon from 'react-material-icon-svg/dist/NetworkStrengthOutline';
// export const NetworkStrengthOutlineIconSvg = NetworkStrengthOutlineIcon;

// import NetworkStrength_1Icon from 'react-material-icon-svg/dist/NetworkStrength_1';
// export const NetworkStrength_1IconSvg = NetworkStrength_1Icon;

// import NetworkStrength_1AlertIcon from 'react-material-icon-svg/dist/NetworkStrength_1Alert';
// export const NetworkStrength_1AlertIconSvg = NetworkStrength_1AlertIcon;

// import NetworkStrength_2Icon from 'react-material-icon-svg/dist/NetworkStrength_2';
// export const NetworkStrength_2IconSvg = NetworkStrength_2Icon;

// import NetworkStrength_2AlertIcon from 'react-material-icon-svg/dist/NetworkStrength_2Alert';
// export const NetworkStrength_2AlertIconSvg = NetworkStrength_2AlertIcon;

// import NetworkStrength_3Icon from 'react-material-icon-svg/dist/NetworkStrength_3';
// export const NetworkStrength_3IconSvg = NetworkStrength_3Icon;

// import NetworkStrength_3AlertIcon from 'react-material-icon-svg/dist/NetworkStrength_3Alert';
// export const NetworkStrength_3AlertIconSvg = NetworkStrength_3AlertIcon;

// import NetworkStrength_4Icon from 'react-material-icon-svg/dist/NetworkStrength_4';
// export const NetworkStrength_4IconSvg = NetworkStrength_4Icon;

// import NetworkStrength_4AlertIcon from 'react-material-icon-svg/dist/NetworkStrength_4Alert';
// export const NetworkStrength_4AlertIconSvg = NetworkStrength_4AlertIcon;

// import NewBoxIcon from 'react-material-icon-svg/dist/NewBox';
// export const NewBoxIconSvg = NewBoxIcon;

// import NewspaperIcon from 'react-material-icon-svg/dist/Newspaper';
// export const NewspaperIconSvg = NewspaperIcon;

// import NfcIcon from 'react-material-icon-svg/dist/Nfc';
// export const NfcIconSvg = NfcIcon;

// import NfcOffIcon from 'react-material-icon-svg/dist/NfcOff';
// export const NfcOffIconSvg = NfcOffIcon;

// import NfcSearchVariantIcon from 'react-material-icon-svg/dist/NfcSearchVariant';
// export const NfcSearchVariantIconSvg = NfcSearchVariantIcon;

// import NfcTapIcon from 'react-material-icon-svg/dist/NfcTap';
// export const NfcTapIconSvg = NfcTapIcon;

// import NfcVariantIcon from 'react-material-icon-svg/dist/NfcVariant';
// export const NfcVariantIconSvg = NfcVariantIcon;

// import NfcVariantOffIcon from 'react-material-icon-svg/dist/NfcVariantOff';
// export const NfcVariantOffIconSvg = NfcVariantOffIcon;

// import NinjaIcon from 'react-material-icon-svg/dist/Ninja';
// export const NinjaIconSvg = NinjaIcon;

// import NintendoSwitchIcon from 'react-material-icon-svg/dist/NintendoSwitch';
// export const NintendoSwitchIconSvg = NintendoSwitchIcon;

// import NodejsIcon from 'react-material-icon-svg/dist/Nodejs';
// export const NodejsIconSvg = NodejsIcon;

// import NotEqualIcon from 'react-material-icon-svg/dist/NotEqual';
// export const NotEqualIconSvg = NotEqualIcon;

// import NotEqualVariantIcon from 'react-material-icon-svg/dist/NotEqualVariant';
// export const NotEqualVariantIconSvg = NotEqualVariantIcon;

// import NoteIcon from 'react-material-icon-svg/dist/Note';
// export const NoteIconSvg = NoteIcon;

// import NoteCircleIcon from 'react-material-icon-svg/dist/NoteCircle';
// export const NoteCircleIconSvg = NoteCircleIcon;

// import NoteMultipleIcon from 'react-material-icon-svg/dist/NoteMultiple';
// export const NoteMultipleIconSvg = NoteMultipleIcon;

// import NoteMultipleOutlineIcon from 'react-material-icon-svg/dist/NoteMultipleOutline';
// export const NoteMultipleOutlineIconSvg = NoteMultipleOutlineIcon;

// import NoteOutlineIcon from 'react-material-icon-svg/dist/NoteOutline';
// export const NoteOutlineIconSvg = NoteOutlineIcon;

// import NotePlusIcon from 'react-material-icon-svg/dist/NotePlus';
// export const NotePlusIconSvg = NotePlusIcon;

// import NotePlusOutlineIcon from 'react-material-icon-svg/dist/NotePlusOutline';
// export const NotePlusOutlineIconSvg = NotePlusOutlineIcon;

// import NoteTextIcon from 'react-material-icon-svg/dist/NoteText';
// export const NoteTextIconSvg = NoteTextIcon;

// import NotebookIcon from 'react-material-icon-svg/dist/Notebook';
// export const NotebookIconSvg = NotebookIcon;

// import NotebookMultipleIcon from 'react-material-icon-svg/dist/NotebookMultiple';
// export const NotebookMultipleIconSvg = NotebookMultipleIcon;

// import NotebookOutlineIcon from 'react-material-icon-svg/dist/NotebookOutline';
// export const NotebookOutlineIconSvg = NotebookOutlineIcon;

// import NotificationClearAllIcon from 'react-material-icon-svg/dist/NotificationClearAll';
// export const NotificationClearAllIconSvg = NotificationClearAllIcon;

// import NpmIcon from 'react-material-icon-svg/dist/Npm';
// export const NpmIconSvg = NpmIcon;

// import NpmVariantIcon from 'react-material-icon-svg/dist/NpmVariant';
// export const NpmVariantIconSvg = NpmVariantIcon;

// import NpmVariantOutlineIcon from 'react-material-icon-svg/dist/NpmVariantOutline';
// export const NpmVariantOutlineIconSvg = NpmVariantOutlineIcon;

// import NukeIcon from 'react-material-icon-svg/dist/Nuke';
// export const NukeIconSvg = NukeIcon;

// import NullIcon from 'react-material-icon-svg/dist/Null';
// export const NullIconSvg = NullIcon;

// import NumericIcon from 'react-material-icon-svg/dist/Numeric';
// export const NumericIconSvg = NumericIcon;

// import Numeric_0Icon from 'react-material-icon-svg/dist/Numeric_0';
// export const Numeric_0IconSvg = Numeric_0Icon;

// import Numeric_0BoxIcon from 'react-material-icon-svg/dist/Numeric_0Box';
// export const Numeric_0BoxIconSvg = Numeric_0BoxIcon;

// import Numeric_0BoxMultipleOutlineIcon from 'react-material-icon-svg/dist/Numeric_0BoxMultipleOutline';
// export const Numeric_0BoxMultipleOutlineIconSvg = Numeric_0BoxMultipleOutlineIcon;

// import Numeric_0BoxOutlineIcon from 'react-material-icon-svg/dist/Numeric_0BoxOutline';
// export const Numeric_0BoxOutlineIconSvg = Numeric_0BoxOutlineIcon;

// import Numeric_0CircleIcon from 'react-material-icon-svg/dist/Numeric_0Circle';
// export const Numeric_0CircleIconSvg = Numeric_0CircleIcon;

// import Numeric_0CircleOutlineIcon from 'react-material-icon-svg/dist/Numeric_0CircleOutline';
// export const Numeric_0CircleOutlineIconSvg = Numeric_0CircleOutlineIcon;

// import Numeric_1Icon from 'react-material-icon-svg/dist/Numeric_1';
// export const Numeric_1IconSvg = Numeric_1Icon;

// import Numeric_1BoxIcon from 'react-material-icon-svg/dist/Numeric_1Box';
// export const Numeric_1BoxIconSvg = Numeric_1BoxIcon;

// import Numeric_1BoxMultipleOutlineIcon from 'react-material-icon-svg/dist/Numeric_1BoxMultipleOutline';
// export const Numeric_1BoxMultipleOutlineIconSvg = Numeric_1BoxMultipleOutlineIcon;

// import Numeric_1BoxOutlineIcon from 'react-material-icon-svg/dist/Numeric_1BoxOutline';
// export const Numeric_1BoxOutlineIconSvg = Numeric_1BoxOutlineIcon;

// import Numeric_1CircleIcon from 'react-material-icon-svg/dist/Numeric_1Circle';
// export const Numeric_1CircleIconSvg = Numeric_1CircleIcon;

// import Numeric_1CircleOutlineIcon from 'react-material-icon-svg/dist/Numeric_1CircleOutline';
// export const Numeric_1CircleOutlineIconSvg = Numeric_1CircleOutlineIcon;

// import Numeric_2Icon from 'react-material-icon-svg/dist/Numeric_2';
// export const Numeric_2IconSvg = Numeric_2Icon;

// import Numeric_2BoxIcon from 'react-material-icon-svg/dist/Numeric_2Box';
// export const Numeric_2BoxIconSvg = Numeric_2BoxIcon;

// import Numeric_2BoxMultipleOutlineIcon from 'react-material-icon-svg/dist/Numeric_2BoxMultipleOutline';
// export const Numeric_2BoxMultipleOutlineIconSvg = Numeric_2BoxMultipleOutlineIcon;

// import Numeric_2BoxOutlineIcon from 'react-material-icon-svg/dist/Numeric_2BoxOutline';
// export const Numeric_2BoxOutlineIconSvg = Numeric_2BoxOutlineIcon;

// import Numeric_2CircleIcon from 'react-material-icon-svg/dist/Numeric_2Circle';
// export const Numeric_2CircleIconSvg = Numeric_2CircleIcon;

// import Numeric_2CircleOutlineIcon from 'react-material-icon-svg/dist/Numeric_2CircleOutline';
// export const Numeric_2CircleOutlineIconSvg = Numeric_2CircleOutlineIcon;

// import Numeric_3Icon from 'react-material-icon-svg/dist/Numeric_3';
// export const Numeric_3IconSvg = Numeric_3Icon;

// import Numeric_3BoxIcon from 'react-material-icon-svg/dist/Numeric_3Box';
// export const Numeric_3BoxIconSvg = Numeric_3BoxIcon;

// import Numeric_3BoxMultipleOutlineIcon from 'react-material-icon-svg/dist/Numeric_3BoxMultipleOutline';
// export const Numeric_3BoxMultipleOutlineIconSvg = Numeric_3BoxMultipleOutlineIcon;

// import Numeric_3BoxOutlineIcon from 'react-material-icon-svg/dist/Numeric_3BoxOutline';
// export const Numeric_3BoxOutlineIconSvg = Numeric_3BoxOutlineIcon;

// import Numeric_3CircleIcon from 'react-material-icon-svg/dist/Numeric_3Circle';
// export const Numeric_3CircleIconSvg = Numeric_3CircleIcon;

// import Numeric_3CircleOutlineIcon from 'react-material-icon-svg/dist/Numeric_3CircleOutline';
// export const Numeric_3CircleOutlineIconSvg = Numeric_3CircleOutlineIcon;

// import Numeric_4Icon from 'react-material-icon-svg/dist/Numeric_4';
// export const Numeric_4IconSvg = Numeric_4Icon;

// import Numeric_4BoxIcon from 'react-material-icon-svg/dist/Numeric_4Box';
// export const Numeric_4BoxIconSvg = Numeric_4BoxIcon;

// import Numeric_4BoxMultipleOutlineIcon from 'react-material-icon-svg/dist/Numeric_4BoxMultipleOutline';
// export const Numeric_4BoxMultipleOutlineIconSvg = Numeric_4BoxMultipleOutlineIcon;

// import Numeric_4BoxOutlineIcon from 'react-material-icon-svg/dist/Numeric_4BoxOutline';
// export const Numeric_4BoxOutlineIconSvg = Numeric_4BoxOutlineIcon;

// import Numeric_4CircleIcon from 'react-material-icon-svg/dist/Numeric_4Circle';
// export const Numeric_4CircleIconSvg = Numeric_4CircleIcon;

// import Numeric_4CircleOutlineIcon from 'react-material-icon-svg/dist/Numeric_4CircleOutline';
// export const Numeric_4CircleOutlineIconSvg = Numeric_4CircleOutlineIcon;

// import Numeric_5Icon from 'react-material-icon-svg/dist/Numeric_5';
// export const Numeric_5IconSvg = Numeric_5Icon;

// import Numeric_5BoxIcon from 'react-material-icon-svg/dist/Numeric_5Box';
// export const Numeric_5BoxIconSvg = Numeric_5BoxIcon;

// import Numeric_5BoxMultipleOutlineIcon from 'react-material-icon-svg/dist/Numeric_5BoxMultipleOutline';
// export const Numeric_5BoxMultipleOutlineIconSvg = Numeric_5BoxMultipleOutlineIcon;

// import Numeric_5BoxOutlineIcon from 'react-material-icon-svg/dist/Numeric_5BoxOutline';
// export const Numeric_5BoxOutlineIconSvg = Numeric_5BoxOutlineIcon;

// import Numeric_5CircleIcon from 'react-material-icon-svg/dist/Numeric_5Circle';
// export const Numeric_5CircleIconSvg = Numeric_5CircleIcon;

// import Numeric_5CircleOutlineIcon from 'react-material-icon-svg/dist/Numeric_5CircleOutline';
// export const Numeric_5CircleOutlineIconSvg = Numeric_5CircleOutlineIcon;

// import Numeric_6Icon from 'react-material-icon-svg/dist/Numeric_6';
// export const Numeric_6IconSvg = Numeric_6Icon;

// import Numeric_6BoxIcon from 'react-material-icon-svg/dist/Numeric_6Box';
// export const Numeric_6BoxIconSvg = Numeric_6BoxIcon;

// import Numeric_6BoxMultipleOutlineIcon from 'react-material-icon-svg/dist/Numeric_6BoxMultipleOutline';
// export const Numeric_6BoxMultipleOutlineIconSvg = Numeric_6BoxMultipleOutlineIcon;

// import Numeric_6BoxOutlineIcon from 'react-material-icon-svg/dist/Numeric_6BoxOutline';
// export const Numeric_6BoxOutlineIconSvg = Numeric_6BoxOutlineIcon;

// import Numeric_6CircleIcon from 'react-material-icon-svg/dist/Numeric_6Circle';
// export const Numeric_6CircleIconSvg = Numeric_6CircleIcon;

// import Numeric_6CircleOutlineIcon from 'react-material-icon-svg/dist/Numeric_6CircleOutline';
// export const Numeric_6CircleOutlineIconSvg = Numeric_6CircleOutlineIcon;

// import Numeric_7Icon from 'react-material-icon-svg/dist/Numeric_7';
// export const Numeric_7IconSvg = Numeric_7Icon;

// import Numeric_7BoxIcon from 'react-material-icon-svg/dist/Numeric_7Box';
// export const Numeric_7BoxIconSvg = Numeric_7BoxIcon;

// import Numeric_7BoxMultipleOutlineIcon from 'react-material-icon-svg/dist/Numeric_7BoxMultipleOutline';
// export const Numeric_7BoxMultipleOutlineIconSvg = Numeric_7BoxMultipleOutlineIcon;

// import Numeric_7BoxOutlineIcon from 'react-material-icon-svg/dist/Numeric_7BoxOutline';
// export const Numeric_7BoxOutlineIconSvg = Numeric_7BoxOutlineIcon;

// import Numeric_7CircleIcon from 'react-material-icon-svg/dist/Numeric_7Circle';
// export const Numeric_7CircleIconSvg = Numeric_7CircleIcon;

// import Numeric_7CircleOutlineIcon from 'react-material-icon-svg/dist/Numeric_7CircleOutline';
// export const Numeric_7CircleOutlineIconSvg = Numeric_7CircleOutlineIcon;

// import Numeric_8Icon from 'react-material-icon-svg/dist/Numeric_8';
// export const Numeric_8IconSvg = Numeric_8Icon;

// import Numeric_8BoxIcon from 'react-material-icon-svg/dist/Numeric_8Box';
// export const Numeric_8BoxIconSvg = Numeric_8BoxIcon;

// import Numeric_8BoxMultipleOutlineIcon from 'react-material-icon-svg/dist/Numeric_8BoxMultipleOutline';
// export const Numeric_8BoxMultipleOutlineIconSvg = Numeric_8BoxMultipleOutlineIcon;

// import Numeric_8BoxOutlineIcon from 'react-material-icon-svg/dist/Numeric_8BoxOutline';
// export const Numeric_8BoxOutlineIconSvg = Numeric_8BoxOutlineIcon;

// import Numeric_8CircleIcon from 'react-material-icon-svg/dist/Numeric_8Circle';
// export const Numeric_8CircleIconSvg = Numeric_8CircleIcon;

// import Numeric_8CircleOutlineIcon from 'react-material-icon-svg/dist/Numeric_8CircleOutline';
// export const Numeric_8CircleOutlineIconSvg = Numeric_8CircleOutlineIcon;

// import Numeric_9Icon from 'react-material-icon-svg/dist/Numeric_9';
// export const Numeric_9IconSvg = Numeric_9Icon;

// import Numeric_9BoxIcon from 'react-material-icon-svg/dist/Numeric_9Box';
// export const Numeric_9BoxIconSvg = Numeric_9BoxIcon;

// import Numeric_9BoxMultipleOutlineIcon from 'react-material-icon-svg/dist/Numeric_9BoxMultipleOutline';
// export const Numeric_9BoxMultipleOutlineIconSvg = Numeric_9BoxMultipleOutlineIcon;

// import Numeric_9BoxOutlineIcon from 'react-material-icon-svg/dist/Numeric_9BoxOutline';
// export const Numeric_9BoxOutlineIconSvg = Numeric_9BoxOutlineIcon;

// import Numeric_9CircleIcon from 'react-material-icon-svg/dist/Numeric_9Circle';
// export const Numeric_9CircleIconSvg = Numeric_9CircleIcon;

// import Numeric_9CircleOutlineIcon from 'react-material-icon-svg/dist/Numeric_9CircleOutline';
// export const Numeric_9CircleOutlineIconSvg = Numeric_9CircleOutlineIcon;

// import Numeric_9PlusBoxIcon from 'react-material-icon-svg/dist/Numeric_9PlusBox';
// export const Numeric_9PlusBoxIconSvg = Numeric_9PlusBoxIcon;

// import Numeric_9PlusBoxMultipleOutlineIcon from 'react-material-icon-svg/dist/Numeric_9PlusBoxMultipleOutline';
// export const Numeric_9PlusBoxMultipleOutlineIconSvg = Numeric_9PlusBoxMultipleOutlineIcon;

// import Numeric_9PlusBoxOutlineIcon from 'react-material-icon-svg/dist/Numeric_9PlusBoxOutline';
// export const Numeric_9PlusBoxOutlineIconSvg = Numeric_9PlusBoxOutlineIcon;

// import Numeric_9PlusCircleIcon from 'react-material-icon-svg/dist/Numeric_9PlusCircle';
// export const Numeric_9PlusCircleIconSvg = Numeric_9PlusCircleIcon;

// import Numeric_9PlusCircleOutlineIcon from 'react-material-icon-svg/dist/Numeric_9PlusCircleOutline';
// export const Numeric_9PlusCircleOutlineIconSvg = Numeric_9PlusCircleOutlineIcon;

// import NutIcon from 'react-material-icon-svg/dist/Nut';
// export const NutIconSvg = NutIcon;

// import NutritionIcon from 'react-material-icon-svg/dist/Nutrition';
// export const NutritionIconSvg = NutritionIcon;

// import OarIcon from 'react-material-icon-svg/dist/Oar';
// export const OarIconSvg = OarIcon;

// import OcarinaIcon from 'react-material-icon-svg/dist/Ocarina';
// export const OcarinaIconSvg = OcarinaIcon;

// import OctagonIcon from 'react-material-icon-svg/dist/Octagon';
// export const OctagonIconSvg = OctagonIcon;

// import OctagonOutlineIcon from 'react-material-icon-svg/dist/OctagonOutline';
// export const OctagonOutlineIconSvg = OctagonOutlineIcon;

// import OctagramIcon from 'react-material-icon-svg/dist/Octagram';
// export const OctagramIconSvg = OctagramIcon;

// import OctagramOutlineIcon from 'react-material-icon-svg/dist/OctagramOutline';
// export const OctagramOutlineIconSvg = OctagramOutlineIcon;

// import OdnoklassnikiIcon from 'react-material-icon-svg/dist/Odnoklassniki';
// export const OdnoklassnikiIconSvg = OdnoklassnikiIcon;

// import OfficeIcon from 'react-material-icon-svg/dist/Office';
// export const OfficeIconSvg = OfficeIcon;

// import OfficeBuildingIcon from 'react-material-icon-svg/dist/OfficeBuilding';
// export const OfficeBuildingIconSvg = OfficeBuildingIcon;

// import OilIcon from 'react-material-icon-svg/dist/Oil';
// export const OilIconSvg = OilIcon;

// import OilTemperatureIcon from 'react-material-icon-svg/dist/OilTemperature';
// export const OilTemperatureIconSvg = OilTemperatureIcon;

// import OmegaIcon from 'react-material-icon-svg/dist/Omega';
// export const OmegaIconSvg = OmegaIcon;

// import OneUpIcon from 'react-material-icon-svg/dist/OneUp';
// export const OneUpIconSvg = OneUpIcon;

// import OnedriveIcon from 'react-material-icon-svg/dist/Onedrive';
// export const OnedriveIconSvg = OnedriveIcon;

// import OnenoteIcon from 'react-material-icon-svg/dist/Onenote';
// export const OnenoteIconSvg = OnenoteIcon;

// import OnepasswordIcon from 'react-material-icon-svg/dist/Onepassword';
// export const OnepasswordIconSvg = OnepasswordIcon;

// import OpacityIcon from 'react-material-icon-svg/dist/Opacity';
// export const OpacityIconSvg = OpacityIcon;

// import OpenInAppIcon from 'react-material-icon-svg/dist/OpenInApp';
// export const OpenInAppIconSvg = OpenInAppIcon;

// import OpenInNewIcon from 'react-material-icon-svg/dist/OpenInNew';
// export const OpenInNewIconSvg = OpenInNewIcon;

// import OpenSourceInitiativeIcon from 'react-material-icon-svg/dist/OpenSourceInitiative';
// export const OpenSourceInitiativeIconSvg = OpenSourceInitiativeIcon;

// import OpenidIcon from 'react-material-icon-svg/dist/Openid';
// export const OpenidIconSvg = OpenidIcon;

// import OperaIcon from 'react-material-icon-svg/dist/Opera';
// export const OperaIconSvg = OperaIcon;

// import OrbitIcon from 'react-material-icon-svg/dist/Orbit';
// export const OrbitIconSvg = OrbitIcon;

// import OriginIcon from 'react-material-icon-svg/dist/Origin';
// export const OriginIconSvg = OriginIcon;

// import OrnamentIcon from 'react-material-icon-svg/dist/Ornament';
// export const OrnamentIconSvg = OrnamentIcon;

// import OrnamentVariantIcon from 'react-material-icon-svg/dist/OrnamentVariant';
// export const OrnamentVariantIconSvg = OrnamentVariantIcon;

// import OutlookIcon from 'react-material-icon-svg/dist/Outlook';
// export const OutlookIconSvg = OutlookIcon;

// import OwlIcon from 'react-material-icon-svg/dist/Owl';
// export const OwlIconSvg = OwlIcon;

// import PacManIcon from 'react-material-icon-svg/dist/PacMan';
// export const PacManIconSvg = PacManIcon;

// import PackageIcon from 'react-material-icon-svg/dist/Package';
// export const PackageIconSvg = PackageIcon;

// import PackageDownIcon from 'react-material-icon-svg/dist/PackageDown';
// export const PackageDownIconSvg = PackageDownIcon;

// import PackageUpIcon from 'react-material-icon-svg/dist/PackageUp';
// export const PackageUpIconSvg = PackageUpIcon;

// import PackageVariantIcon from 'react-material-icon-svg/dist/PackageVariant';
// export const PackageVariantIconSvg = PackageVariantIcon;

// import PackageVariantClosedIcon from 'react-material-icon-svg/dist/PackageVariantClosed';
// export const PackageVariantClosedIconSvg = PackageVariantClosedIcon;

// import PageFirstIcon from 'react-material-icon-svg/dist/PageFirst';
// export const PageFirstIconSvg = PageFirstIcon;

// import PageLastIcon from 'react-material-icon-svg/dist/PageLast';
// export const PageLastIconSvg = PageLastIcon;

// import PageLayoutBodyIcon from 'react-material-icon-svg/dist/PageLayoutBody';
// export const PageLayoutBodyIconSvg = PageLayoutBodyIcon;

// import PageLayoutFooterIcon from 'react-material-icon-svg/dist/PageLayoutFooter';
// export const PageLayoutFooterIconSvg = PageLayoutFooterIcon;

// import PageLayoutHeaderIcon from 'react-material-icon-svg/dist/PageLayoutHeader';
// export const PageLayoutHeaderIconSvg = PageLayoutHeaderIcon;

// import PageLayoutSidebarLeftIcon from 'react-material-icon-svg/dist/PageLayoutSidebarLeft';
// export const PageLayoutSidebarLeftIconSvg = PageLayoutSidebarLeftIcon;

// import PageLayoutSidebarRightIcon from 'react-material-icon-svg/dist/PageLayoutSidebarRight';
// export const PageLayoutSidebarRightIconSvg = PageLayoutSidebarRightIcon;

// import PageNextIcon from 'react-material-icon-svg/dist/PageNext';
// export const PageNextIconSvg = PageNextIcon;

// import PageNextOutlineIcon from 'react-material-icon-svg/dist/PageNextOutline';
// export const PageNextOutlineIconSvg = PageNextOutlineIcon;

// import PagePreviousIcon from 'react-material-icon-svg/dist/PagePrevious';
// export const PagePreviousIconSvg = PagePreviousIcon;

// import PagePreviousOutlineIcon from 'react-material-icon-svg/dist/PagePreviousOutline';
// export const PagePreviousOutlineIconSvg = PagePreviousOutlineIcon;

// import PaletteIcon from 'react-material-icon-svg/dist/Palette';
// export const PaletteIconSvg = PaletteIcon;

// import PaletteAdvancedIcon from 'react-material-icon-svg/dist/PaletteAdvanced';
// export const PaletteAdvancedIconSvg = PaletteAdvancedIcon;

// import PaletteOutlineIcon from 'react-material-icon-svg/dist/PaletteOutline';
// export const PaletteOutlineIconSvg = PaletteOutlineIcon;

// import PaletteSwatchIcon from 'react-material-icon-svg/dist/PaletteSwatch';
// export const PaletteSwatchIconSvg = PaletteSwatchIcon;

// import PanIcon from 'react-material-icon-svg/dist/Pan';
// export const PanIconSvg = PanIcon;

// import PanBottomLeftIcon from 'react-material-icon-svg/dist/PanBottomLeft';
// export const PanBottomLeftIconSvg = PanBottomLeftIcon;

// import PanBottomRightIcon from 'react-material-icon-svg/dist/PanBottomRight';
// export const PanBottomRightIconSvg = PanBottomRightIcon;

// import PanDownIcon from 'react-material-icon-svg/dist/PanDown';
// export const PanDownIconSvg = PanDownIcon;

// import PanHorizontalIcon from 'react-material-icon-svg/dist/PanHorizontal';
// export const PanHorizontalIconSvg = PanHorizontalIcon;

// import PanLeftIcon from 'react-material-icon-svg/dist/PanLeft';
// export const PanLeftIconSvg = PanLeftIcon;

// import PanRightIcon from 'react-material-icon-svg/dist/PanRight';
// export const PanRightIconSvg = PanRightIcon;

// import PanTopLeftIcon from 'react-material-icon-svg/dist/PanTopLeft';
// export const PanTopLeftIconSvg = PanTopLeftIcon;

// import PanTopRightIcon from 'react-material-icon-svg/dist/PanTopRight';
// export const PanTopRightIconSvg = PanTopRightIcon;

// import PanUpIcon from 'react-material-icon-svg/dist/PanUp';
// export const PanUpIconSvg = PanUpIcon;

// import PanVerticalIcon from 'react-material-icon-svg/dist/PanVertical';
// export const PanVerticalIconSvg = PanVerticalIcon;

// import PandaIcon from 'react-material-icon-svg/dist/Panda';
// export const PandaIconSvg = PandaIcon;

// import PandoraIcon from 'react-material-icon-svg/dist/Pandora';
// export const PandoraIconSvg = PandoraIcon;

// import PanoramaIcon from 'react-material-icon-svg/dist/Panorama';
// export const PanoramaIconSvg = PanoramaIcon;

// import PanoramaFisheyeIcon from 'react-material-icon-svg/dist/PanoramaFisheye';
// export const PanoramaFisheyeIconSvg = PanoramaFisheyeIcon;

// import PanoramaHorizontalIcon from 'react-material-icon-svg/dist/PanoramaHorizontal';
// export const PanoramaHorizontalIconSvg = PanoramaHorizontalIcon;

// import PanoramaVerticalIcon from 'react-material-icon-svg/dist/PanoramaVertical';
// export const PanoramaVerticalIconSvg = PanoramaVerticalIcon;

// import PanoramaWideAngleIcon from 'react-material-icon-svg/dist/PanoramaWideAngle';
// export const PanoramaWideAngleIconSvg = PanoramaWideAngleIcon;

// import PaperCutVerticalIcon from 'react-material-icon-svg/dist/PaperCutVertical';
// export const PaperCutVerticalIconSvg = PaperCutVerticalIcon;

// import PaperclipIcon from 'react-material-icon-svg/dist/Paperclip';
// export const PaperclipIconSvg = PaperclipIcon;

// import ParachuteIcon from 'react-material-icon-svg/dist/Parachute';
// export const ParachuteIconSvg = ParachuteIcon;

// import ParachuteOutlineIcon from 'react-material-icon-svg/dist/ParachuteOutline';
// export const ParachuteOutlineIconSvg = ParachuteOutlineIcon;

// import ParkingIcon from 'react-material-icon-svg/dist/Parking';
// export const ParkingIconSvg = ParkingIcon;

// import PassportIcon from 'react-material-icon-svg/dist/Passport';
// export const PassportIconSvg = PassportIcon;

// import PassportBiometricIcon from 'react-material-icon-svg/dist/PassportBiometric';
// export const PassportBiometricIconSvg = PassportBiometricIcon;

// import PatreonIcon from 'react-material-icon-svg/dist/Patreon';
// export const PatreonIconSvg = PatreonIcon;

// import PauseIcon from 'react-material-icon-svg/dist/Pause';
// export const PauseIconSvg = PauseIcon;

// import PauseCircleIcon from 'react-material-icon-svg/dist/PauseCircle';
// export const PauseCircleIconSvg = PauseCircleIcon;

// import PauseCircleOutlineIcon from 'react-material-icon-svg/dist/PauseCircleOutline';
// export const PauseCircleOutlineIconSvg = PauseCircleOutlineIcon;

// import PauseOctagonIcon from 'react-material-icon-svg/dist/PauseOctagon';
// export const PauseOctagonIconSvg = PauseOctagonIcon;

// import PauseOctagonOutlineIcon from 'react-material-icon-svg/dist/PauseOctagonOutline';
// export const PauseOctagonOutlineIconSvg = PauseOctagonOutlineIcon;

// import PawIcon from 'react-material-icon-svg/dist/Paw';
// export const PawIconSvg = PawIcon;

// import PawOffIcon from 'react-material-icon-svg/dist/PawOff';
// export const PawOffIconSvg = PawOffIcon;

// import PaypalIcon from 'react-material-icon-svg/dist/Paypal';
// export const PaypalIconSvg = PaypalIcon;

// import PdfBoxIcon from 'react-material-icon-svg/dist/PdfBox';
// export const PdfBoxIconSvg = PdfBoxIcon;

// import PeaceIcon from 'react-material-icon-svg/dist/Peace';
// export const PeaceIconSvg = PeaceIcon;

// import PenIcon from 'react-material-icon-svg/dist/Pen';
// export const PenIconSvg = PenIcon;

// import PenLockIcon from 'react-material-icon-svg/dist/PenLock';
// export const PenLockIconSvg = PenLockIcon;

// import PenMinusIcon from 'react-material-icon-svg/dist/PenMinus';
// export const PenMinusIconSvg = PenMinusIcon;

// import PenOffIcon from 'react-material-icon-svg/dist/PenOff';
// export const PenOffIconSvg = PenOffIcon;

// import PenPlusIcon from 'react-material-icon-svg/dist/PenPlus';
// export const PenPlusIconSvg = PenPlusIcon;

// import PenRemoveIcon from 'react-material-icon-svg/dist/PenRemove';
// export const PenRemoveIconSvg = PenRemoveIcon;

import PencilIcon from 'react-material-icon-svg/dist/Pencil';
export const PencilIconSvg = PencilIcon;

// import PencilBoxIcon from 'react-material-icon-svg/dist/PencilBox';
// export const PencilBoxIconSvg = PencilBoxIcon;

// import PencilBoxOutlineIcon from 'react-material-icon-svg/dist/PencilBoxOutline';
// export const PencilBoxOutlineIconSvg = PencilBoxOutlineIcon;

// import PencilCircleIcon from 'react-material-icon-svg/dist/PencilCircle';
// export const PencilCircleIconSvg = PencilCircleIcon;

// import PencilCircleOutlineIcon from 'react-material-icon-svg/dist/PencilCircleOutline';
// export const PencilCircleOutlineIconSvg = PencilCircleOutlineIcon;

// import PencilLockIcon from 'react-material-icon-svg/dist/PencilLock';
// export const PencilLockIconSvg = PencilLockIcon;

// import PencilLockOutlineIcon from 'react-material-icon-svg/dist/PencilLockOutline';
// export const PencilLockOutlineIconSvg = PencilLockOutlineIcon;

// import PencilMinusIcon from 'react-material-icon-svg/dist/PencilMinus';
// export const PencilMinusIconSvg = PencilMinusIcon;

// import PencilMinusOutlineIcon from 'react-material-icon-svg/dist/PencilMinusOutline';
// export const PencilMinusOutlineIconSvg = PencilMinusOutlineIcon;

// import PencilOffIcon from 'react-material-icon-svg/dist/PencilOff';
// export const PencilOffIconSvg = PencilOffIcon;

// import PencilOffOutlineIcon from 'react-material-icon-svg/dist/PencilOffOutline';
// export const PencilOffOutlineIconSvg = PencilOffOutlineIcon;

// import PencilOutlineIcon from 'react-material-icon-svg/dist/PencilOutline';
// export const PencilOutlineIconSvg = PencilOutlineIcon;

// import PencilPlusIcon from 'react-material-icon-svg/dist/PencilPlus';
// export const PencilPlusIconSvg = PencilPlusIcon;

// import PencilPlusOutlineIcon from 'react-material-icon-svg/dist/PencilPlusOutline';
// export const PencilPlusOutlineIconSvg = PencilPlusOutlineIcon;

// import PencilRemoveIcon from 'react-material-icon-svg/dist/PencilRemove';
// export const PencilRemoveIconSvg = PencilRemoveIcon;

// import PencilRemoveOutlineIcon from 'react-material-icon-svg/dist/PencilRemoveOutline';
// export const PencilRemoveOutlineIconSvg = PencilRemoveOutlineIcon;

// import PenguinIcon from 'react-material-icon-svg/dist/Penguin';
// export const PenguinIconSvg = PenguinIcon;

// import PentagonIcon from 'react-material-icon-svg/dist/Pentagon';
// export const PentagonIconSvg = PentagonIcon;

// import PentagonOutlineIcon from 'react-material-icon-svg/dist/PentagonOutline';
// export const PentagonOutlineIconSvg = PentagonOutlineIcon;

// import PercentIcon from 'react-material-icon-svg/dist/Percent';
// export const PercentIconSvg = PercentIcon;

// import PeriodicTableIcon from 'react-material-icon-svg/dist/PeriodicTable';
// export const PeriodicTableIconSvg = PeriodicTableIcon;

// import PeriodicTableCo2Icon from 'react-material-icon-svg/dist/PeriodicTableCo2';
// export const PeriodicTableCo2IconSvg = PeriodicTableCo2Icon;

// import PeriscopeIcon from 'react-material-icon-svg/dist/Periscope';
// export const PeriscopeIconSvg = PeriscopeIcon;

// import PerspectiveLessIcon from 'react-material-icon-svg/dist/PerspectiveLess';
// export const PerspectiveLessIconSvg = PerspectiveLessIcon;

// import PerspectiveMoreIcon from 'react-material-icon-svg/dist/PerspectiveMore';
// export const PerspectiveMoreIconSvg = PerspectiveMoreIcon;

// import PharmacyIcon from 'react-material-icon-svg/dist/Pharmacy';
// export const PharmacyIconSvg = PharmacyIcon;

// import PhoneIcon from 'react-material-icon-svg/dist/Phone';
// export const PhoneIconSvg = PhoneIcon;

// import PhoneBluetoothIcon from 'react-material-icon-svg/dist/PhoneBluetooth';
// export const PhoneBluetoothIconSvg = PhoneBluetoothIcon;

// import PhoneClassicIcon from 'react-material-icon-svg/dist/PhoneClassic';
// export const PhoneClassicIconSvg = PhoneClassicIcon;

// import PhoneForwardIcon from 'react-material-icon-svg/dist/PhoneForward';
// export const PhoneForwardIconSvg = PhoneForwardIcon;

// import PhoneHangupIcon from 'react-material-icon-svg/dist/PhoneHangup';
// export const PhoneHangupIconSvg = PhoneHangupIcon;

// import PhoneInTalkIcon from 'react-material-icon-svg/dist/PhoneInTalk';
// export const PhoneInTalkIconSvg = PhoneInTalkIcon;

// import PhoneIncomingIcon from 'react-material-icon-svg/dist/PhoneIncoming';
// export const PhoneIncomingIconSvg = PhoneIncomingIcon;

// import PhoneLockIcon from 'react-material-icon-svg/dist/PhoneLock';
// export const PhoneLockIconSvg = PhoneLockIcon;

// import PhoneLogIcon from 'react-material-icon-svg/dist/PhoneLog';
// export const PhoneLogIconSvg = PhoneLogIcon;

// import PhoneMinusIcon from 'react-material-icon-svg/dist/PhoneMinus';
// export const PhoneMinusIconSvg = PhoneMinusIcon;

// import PhoneMissedIcon from 'react-material-icon-svg/dist/PhoneMissed';
// export const PhoneMissedIconSvg = PhoneMissedIcon;

// import PhoneOffIcon from 'react-material-icon-svg/dist/PhoneOff';
// export const PhoneOffIconSvg = PhoneOffIcon;

// import PhoneOutgoingIcon from 'react-material-icon-svg/dist/PhoneOutgoing';
// export const PhoneOutgoingIconSvg = PhoneOutgoingIcon;

// import PhoneOutlineIcon from 'react-material-icon-svg/dist/PhoneOutline';
// export const PhoneOutlineIconSvg = PhoneOutlineIcon;

// import PhonePausedIcon from 'react-material-icon-svg/dist/PhonePaused';
// export const PhonePausedIconSvg = PhonePausedIcon;

// import PhonePlusIcon from 'react-material-icon-svg/dist/PhonePlus';
// export const PhonePlusIconSvg = PhonePlusIcon;

// import PhoneReturnIcon from 'react-material-icon-svg/dist/PhoneReturn';
// export const PhoneReturnIconSvg = PhoneReturnIcon;

// import PhoneRotateLandscapeIcon from 'react-material-icon-svg/dist/PhoneRotateLandscape';
// export const PhoneRotateLandscapeIconSvg = PhoneRotateLandscapeIcon;

// import PhoneRotatePortraitIcon from 'react-material-icon-svg/dist/PhoneRotatePortrait';
// export const PhoneRotatePortraitIconSvg = PhoneRotatePortraitIcon;

// import PhoneSettingsIcon from 'react-material-icon-svg/dist/PhoneSettings';
// export const PhoneSettingsIconSvg = PhoneSettingsIcon;

// import PhoneVoipIcon from 'react-material-icon-svg/dist/PhoneVoip';
// export const PhoneVoipIconSvg = PhoneVoipIcon;

// import PiIcon from 'react-material-icon-svg/dist/Pi';
// export const PiIconSvg = PiIcon;

// import PiBoxIcon from 'react-material-icon-svg/dist/PiBox';
// export const PiBoxIconSvg = PiBoxIcon;

// import PiHoleIcon from 'react-material-icon-svg/dist/PiHole';
// export const PiHoleIconSvg = PiHoleIcon;

// import PianoIcon from 'react-material-icon-svg/dist/Piano';
// export const PianoIconSvg = PianoIcon;

// import PickaxeIcon from 'react-material-icon-svg/dist/Pickaxe';
// export const PickaxeIconSvg = PickaxeIcon;

// import PictureInPictureBottomRightIcon from 'react-material-icon-svg/dist/PictureInPictureBottomRight';
// export const PictureInPictureBottomRightIconSvg = PictureInPictureBottomRightIcon;

// import PictureInPictureBottomRightOutlineIcon from 'react-material-icon-svg/dist/PictureInPictureBottomRightOutline';
// export const PictureInPictureBottomRightOutlineIconSvg = PictureInPictureBottomRightOutlineIcon;

// import PictureInPictureTopRightIcon from 'react-material-icon-svg/dist/PictureInPictureTopRight';
// export const PictureInPictureTopRightIconSvg = PictureInPictureTopRightIcon;

// import PictureInPictureTopRightOutlineIcon from 'react-material-icon-svg/dist/PictureInPictureTopRightOutline';
// export const PictureInPictureTopRightOutlineIconSvg = PictureInPictureTopRightOutlineIcon;

// import PierIcon from 'react-material-icon-svg/dist/Pier';
// export const PierIconSvg = PierIcon;

// import PierCraneIcon from 'react-material-icon-svg/dist/PierCrane';
// export const PierCraneIconSvg = PierCraneIcon;

// import PigIcon from 'react-material-icon-svg/dist/Pig';
// export const PigIconSvg = PigIcon;

// import PillIcon from 'react-material-icon-svg/dist/Pill';
// export const PillIconSvg = PillIcon;

// import PillarIcon from 'react-material-icon-svg/dist/Pillar';
// export const PillarIconSvg = PillarIcon;

// import PinIcon from 'react-material-icon-svg/dist/Pin';
// export const PinIconSvg = PinIcon;

// import PinOffIcon from 'react-material-icon-svg/dist/PinOff';
// export const PinOffIconSvg = PinOffIcon;

// import PinOffOutlineIcon from 'react-material-icon-svg/dist/PinOffOutline';
// export const PinOffOutlineIconSvg = PinOffOutlineIcon;

// import PinOutlineIcon from 'react-material-icon-svg/dist/PinOutline';
// export const PinOutlineIconSvg = PinOutlineIcon;

// import PineTreeIcon from 'react-material-icon-svg/dist/PineTree';
// export const PineTreeIconSvg = PineTreeIcon;

// import PineTreeBoxIcon from 'react-material-icon-svg/dist/PineTreeBox';
// export const PineTreeBoxIconSvg = PineTreeBoxIcon;

// import PinterestIcon from 'react-material-icon-svg/dist/Pinterest';
// export const PinterestIconSvg = PinterestIcon;

// import PinterestBoxIcon from 'react-material-icon-svg/dist/PinterestBox';
// export const PinterestBoxIconSvg = PinterestBoxIcon;

// import PinwheelIcon from 'react-material-icon-svg/dist/Pinwheel';
// export const PinwheelIconSvg = PinwheelIcon;

// import PinwheelOutlineIcon from 'react-material-icon-svg/dist/PinwheelOutline';
// export const PinwheelOutlineIconSvg = PinwheelOutlineIcon;

// import PipeIcon from 'react-material-icon-svg/dist/Pipe';
// export const PipeIconSvg = PipeIcon;

// import PipeDisconnectedIcon from 'react-material-icon-svg/dist/PipeDisconnected';
// export const PipeDisconnectedIconSvg = PipeDisconnectedIcon;

// import PipeLeakIcon from 'react-material-icon-svg/dist/PipeLeak';
// export const PipeLeakIconSvg = PipeLeakIcon;

// import PirateIcon from 'react-material-icon-svg/dist/Pirate';
// export const PirateIconSvg = PirateIcon;

// import PistolIcon from 'react-material-icon-svg/dist/Pistol';
// export const PistolIconSvg = PistolIcon;

// import PistonIcon from 'react-material-icon-svg/dist/Piston';
// export const PistonIconSvg = PistonIcon;

// import PizzaIcon from 'react-material-icon-svg/dist/Pizza';
// export const PizzaIconSvg = PizzaIcon;

// import PlayIcon from 'react-material-icon-svg/dist/Play';
// export const PlayIconSvg = PlayIcon;

// import PlayBoxOutlineIcon from 'react-material-icon-svg/dist/PlayBoxOutline';
// export const PlayBoxOutlineIconSvg = PlayBoxOutlineIcon;

// import PlayCircleIcon from 'react-material-icon-svg/dist/PlayCircle';
// export const PlayCircleIconSvg = PlayCircleIcon;

// import PlayCircleOutlineIcon from 'react-material-icon-svg/dist/PlayCircleOutline';
// export const PlayCircleOutlineIconSvg = PlayCircleOutlineIcon;

// import PlayNetworkIcon from 'react-material-icon-svg/dist/PlayNetwork';
// export const PlayNetworkIconSvg = PlayNetworkIcon;

// import PlayNetworkOutlineIcon from 'react-material-icon-svg/dist/PlayNetworkOutline';
// export const PlayNetworkOutlineIconSvg = PlayNetworkOutlineIcon;

// import PlayPauseIcon from 'react-material-icon-svg/dist/PlayPause';
// export const PlayPauseIconSvg = PlayPauseIcon;

// import PlayProtectedContentIcon from 'react-material-icon-svg/dist/PlayProtectedContent';
// export const PlayProtectedContentIconSvg = PlayProtectedContentIcon;

// import PlaySpeedIcon from 'react-material-icon-svg/dist/PlaySpeed';
// export const PlaySpeedIconSvg = PlaySpeedIcon;

// import PlaylistCheckIcon from 'react-material-icon-svg/dist/PlaylistCheck';
// export const PlaylistCheckIconSvg = PlaylistCheckIcon;

// import PlaylistEditIcon from 'react-material-icon-svg/dist/PlaylistEdit';
// export const PlaylistEditIconSvg = PlaylistEditIcon;

// import PlaylistMinusIcon from 'react-material-icon-svg/dist/PlaylistMinus';
// export const PlaylistMinusIconSvg = PlaylistMinusIcon;

// import PlaylistMusicIcon from 'react-material-icon-svg/dist/PlaylistMusic';
// export const PlaylistMusicIconSvg = PlaylistMusicIcon;

// import PlaylistMusicOutlineIcon from 'react-material-icon-svg/dist/PlaylistMusicOutline';
// export const PlaylistMusicOutlineIconSvg = PlaylistMusicOutlineIcon;

// import PlaylistPlayIcon from 'react-material-icon-svg/dist/PlaylistPlay';
// export const PlaylistPlayIconSvg = PlaylistPlayIcon;

// import PlaylistPlusIcon from 'react-material-icon-svg/dist/PlaylistPlus';
// export const PlaylistPlusIconSvg = PlaylistPlusIcon;

// import PlaylistRemoveIcon from 'react-material-icon-svg/dist/PlaylistRemove';
// export const PlaylistRemoveIconSvg = PlaylistRemoveIcon;

// import PlaylistStarIcon from 'react-material-icon-svg/dist/PlaylistStar';
// export const PlaylistStarIconSvg = PlaylistStarIcon;

// import PlaystationIcon from 'react-material-icon-svg/dist/Playstation';
// export const PlaystationIconSvg = PlaystationIcon;

// import PlexIcon from 'react-material-icon-svg/dist/Plex';
// export const PlexIconSvg = PlexIcon;

// import PlusIcon from 'react-material-icon-svg/dist/Plus';
// export const PlusIconSvg = PlusIcon;

// import PlusBoxIcon from 'react-material-icon-svg/dist/PlusBox';
// export const PlusBoxIconSvg = PlusBoxIcon;

// import PlusBoxOutlineIcon from 'react-material-icon-svg/dist/PlusBoxOutline';
// export const PlusBoxOutlineIconSvg = PlusBoxOutlineIcon;

// import PlusCircleIcon from 'react-material-icon-svg/dist/PlusCircle';
// export const PlusCircleIconSvg = PlusCircleIcon;

// import PlusCircleMultipleOutlineIcon from 'react-material-icon-svg/dist/PlusCircleMultipleOutline';
// export const PlusCircleMultipleOutlineIconSvg = PlusCircleMultipleOutlineIcon;

// import PlusCircleOutlineIcon from 'react-material-icon-svg/dist/PlusCircleOutline';
// export const PlusCircleOutlineIconSvg = PlusCircleOutlineIcon;

// import PlusMinusIcon from 'react-material-icon-svg/dist/PlusMinus';
// export const PlusMinusIconSvg = PlusMinusIcon;

// import PlusMinusBoxIcon from 'react-material-icon-svg/dist/PlusMinusBox';
// export const PlusMinusBoxIconSvg = PlusMinusBoxIcon;

// import PlusNetworkIcon from 'react-material-icon-svg/dist/PlusNetwork';
// export const PlusNetworkIconSvg = PlusNetworkIcon;

// import PlusNetworkOutlineIcon from 'react-material-icon-svg/dist/PlusNetworkOutline';
// export const PlusNetworkOutlineIconSvg = PlusNetworkOutlineIcon;

// import PlusOneIcon from 'react-material-icon-svg/dist/PlusOne';
// export const PlusOneIconSvg = PlusOneIcon;

// import PlusOutlineIcon from 'react-material-icon-svg/dist/PlusOutline';
// export const PlusOutlineIconSvg = PlusOutlineIcon;

// import PocketIcon from 'react-material-icon-svg/dist/Pocket';
// export const PocketIconSvg = PocketIcon;

// import PodcastIcon from 'react-material-icon-svg/dist/Podcast';
// export const PodcastIconSvg = PodcastIcon;

// import PodiumIcon from 'react-material-icon-svg/dist/Podium';
// export const PodiumIconSvg = PodiumIcon;

// import PodiumBronzeIcon from 'react-material-icon-svg/dist/PodiumBronze';
// export const PodiumBronzeIconSvg = PodiumBronzeIcon;

// import PodiumGoldIcon from 'react-material-icon-svg/dist/PodiumGold';
// export const PodiumGoldIconSvg = PodiumGoldIcon;

// import PodiumSilverIcon from 'react-material-icon-svg/dist/PodiumSilver';
// export const PodiumSilverIconSvg = PodiumSilverIcon;

// import PointOfSaleIcon from 'react-material-icon-svg/dist/PointOfSale';
// export const PointOfSaleIconSvg = PointOfSaleIcon;

// import PokeballIcon from 'react-material-icon-svg/dist/Pokeball';
// export const PokeballIconSvg = PokeballIcon;

// import PokemonGoIcon from 'react-material-icon-svg/dist/PokemonGo';
// export const PokemonGoIconSvg = PokemonGoIcon;

// import PokerChipIcon from 'react-material-icon-svg/dist/PokerChip';
// export const PokerChipIconSvg = PokerChipIcon;

// import PolaroidIcon from 'react-material-icon-svg/dist/Polaroid';
// export const PolaroidIconSvg = PolaroidIcon;

// import PollIcon from 'react-material-icon-svg/dist/Poll';
// export const PollIconSvg = PollIcon;

// import PollBoxIcon from 'react-material-icon-svg/dist/PollBox';
// export const PollBoxIconSvg = PollBoxIcon;

// import PolymerIcon from 'react-material-icon-svg/dist/Polymer';
// export const PolymerIconSvg = PolymerIcon;

// import PoolIcon from 'react-material-icon-svg/dist/Pool';
// export const PoolIconSvg = PoolIcon;

// import PopcornIcon from 'react-material-icon-svg/dist/Popcorn';
// export const PopcornIconSvg = PopcornIcon;

// import PostageStampIcon from 'react-material-icon-svg/dist/PostageStamp';
// export const PostageStampIconSvg = PostageStampIcon;

// import PotIcon from 'react-material-icon-svg/dist/Pot';
// export const PotIconSvg = PotIcon;

// import PotMixIcon from 'react-material-icon-svg/dist/PotMix';
// export const PotMixIconSvg = PotMixIcon;

// import PoundIcon from 'react-material-icon-svg/dist/Pound';
// export const PoundIconSvg = PoundIcon;

// import PoundBoxIcon from 'react-material-icon-svg/dist/PoundBox';
// export const PoundBoxIconSvg = PoundBoxIcon;

// import PowerIcon from 'react-material-icon-svg/dist/Power';
// export const PowerIconSvg = PowerIcon;

// import PowerCycleIcon from 'react-material-icon-svg/dist/PowerCycle';
// export const PowerCycleIconSvg = PowerCycleIcon;

// import PowerOffIcon from 'react-material-icon-svg/dist/PowerOff';
// export const PowerOffIconSvg = PowerOffIcon;

// import PowerOnIcon from 'react-material-icon-svg/dist/PowerOn';
// export const PowerOnIconSvg = PowerOnIcon;

// import PowerPlugIcon from 'react-material-icon-svg/dist/PowerPlug';
// export const PowerPlugIconSvg = PowerPlugIcon;

// import PowerPlugOffIcon from 'react-material-icon-svg/dist/PowerPlugOff';
// export const PowerPlugOffIconSvg = PowerPlugOffIcon;

// import PowerSettingsIcon from 'react-material-icon-svg/dist/PowerSettings';
// export const PowerSettingsIconSvg = PowerSettingsIcon;

// import PowerSleepIcon from 'react-material-icon-svg/dist/PowerSleep';
// export const PowerSleepIconSvg = PowerSleepIcon;

// import PowerSocketIcon from 'react-material-icon-svg/dist/PowerSocket';
// export const PowerSocketIconSvg = PowerSocketIcon;

// import PowerSocketAuIcon from 'react-material-icon-svg/dist/PowerSocketAu';
// export const PowerSocketAuIconSvg = PowerSocketAuIcon;

// import PowerSocketEuIcon from 'react-material-icon-svg/dist/PowerSocketEu';
// export const PowerSocketEuIconSvg = PowerSocketEuIcon;

// import PowerSocketUkIcon from 'react-material-icon-svg/dist/PowerSocketUk';
// export const PowerSocketUkIconSvg = PowerSocketUkIcon;

// import PowerSocketUsIcon from 'react-material-icon-svg/dist/PowerSocketUs';
// export const PowerSocketUsIconSvg = PowerSocketUsIcon;

// import PowerStandbyIcon from 'react-material-icon-svg/dist/PowerStandby';
// export const PowerStandbyIconSvg = PowerStandbyIcon;

// import PowershellIcon from 'react-material-icon-svg/dist/Powershell';
// export const PowershellIconSvg = PowershellIcon;

// import PrescriptionIcon from 'react-material-icon-svg/dist/Prescription';
// export const PrescriptionIconSvg = PrescriptionIcon;

// import PresentationIcon from 'react-material-icon-svg/dist/Presentation';
// export const PresentationIconSvg = PresentationIcon;

// import PresentationPlayIcon from 'react-material-icon-svg/dist/PresentationPlay';
// export const PresentationPlayIconSvg = PresentationPlayIcon;

// import PrinterIcon from 'react-material-icon-svg/dist/Printer';
// export const PrinterIconSvg = PrinterIcon;

// import PrinterAlertIcon from 'react-material-icon-svg/dist/PrinterAlert';
// export const PrinterAlertIconSvg = PrinterAlertIcon;

// import PrinterOffIcon from 'react-material-icon-svg/dist/PrinterOff';
// export const PrinterOffIconSvg = PrinterOffIcon;

// import PrinterSettingsIcon from 'react-material-icon-svg/dist/PrinterSettings';
// export const PrinterSettingsIconSvg = PrinterSettingsIcon;

// import PrinterWirelessIcon from 'react-material-icon-svg/dist/PrinterWireless';
// export const PrinterWirelessIconSvg = PrinterWirelessIcon;

// import Printer_3dIcon from 'react-material-icon-svg/dist/Printer_3d';
// export const Printer_3dIconSvg = Printer_3dIcon;

// import Printer_3dNozzleIcon from 'react-material-icon-svg/dist/Printer_3dNozzle';
// export const Printer_3dNozzleIconSvg = Printer_3dNozzleIcon;

// import Printer_3dNozzleOutlineIcon from 'react-material-icon-svg/dist/Printer_3dNozzleOutline';
// export const Printer_3dNozzleOutlineIconSvg = Printer_3dNozzleOutlineIcon;

// import PriorityHighIcon from 'react-material-icon-svg/dist/PriorityHigh';
// export const PriorityHighIconSvg = PriorityHighIcon;

// import PriorityLowIcon from 'react-material-icon-svg/dist/PriorityLow';
// export const PriorityLowIconSvg = PriorityLowIcon;

// import ProfessionalHexagonIcon from 'react-material-icon-svg/dist/ProfessionalHexagon';
// export const ProfessionalHexagonIconSvg = ProfessionalHexagonIcon;

// import ProgressAlertIcon from 'react-material-icon-svg/dist/ProgressAlert';
// export const ProgressAlertIconSvg = ProgressAlertIcon;

// import ProgressCheckIcon from 'react-material-icon-svg/dist/ProgressCheck';
// export const ProgressCheckIconSvg = ProgressCheckIcon;

// import ProgressClockIcon from 'react-material-icon-svg/dist/ProgressClock';
// export const ProgressClockIconSvg = ProgressClockIcon;

// import ProgressDownloadIcon from 'react-material-icon-svg/dist/ProgressDownload';
// export const ProgressDownloadIconSvg = ProgressDownloadIcon;

// import ProgressUploadIcon from 'react-material-icon-svg/dist/ProgressUpload';
// export const ProgressUploadIconSvg = ProgressUploadIcon;

// import ProgressWrenchIcon from 'react-material-icon-svg/dist/ProgressWrench';
// export const ProgressWrenchIconSvg = ProgressWrenchIcon;

// import ProjectorIcon from 'react-material-icon-svg/dist/Projector';
// export const ProjectorIconSvg = ProjectorIcon;

// import ProjectorScreenIcon from 'react-material-icon-svg/dist/ProjectorScreen';
// export const ProjectorScreenIconSvg = ProjectorScreenIcon;

// import PublishIcon from 'react-material-icon-svg/dist/Publish';
// export const PublishIconSvg = PublishIcon;

// import PulseIcon from 'react-material-icon-svg/dist/Pulse';
// export const PulseIconSvg = PulseIcon;

// import PumpkinIcon from 'react-material-icon-svg/dist/Pumpkin';
// export const PumpkinIconSvg = PumpkinIcon;

// import PuzzleIcon from 'react-material-icon-svg/dist/Puzzle';
// export const PuzzleIconSvg = PuzzleIcon;

// import PuzzleOutlineIcon from 'react-material-icon-svg/dist/PuzzleOutline';
// export const PuzzleOutlineIconSvg = PuzzleOutlineIcon;

// import QiIcon from 'react-material-icon-svg/dist/Qi';
// export const QiIconSvg = QiIcon;

// import QqchatIcon from 'react-material-icon-svg/dist/Qqchat';
// export const QqchatIconSvg = QqchatIcon;

// import QrcodeIcon from 'react-material-icon-svg/dist/Qrcode';
// export const QrcodeIconSvg = QrcodeIcon;

// import QrcodeEditIcon from 'react-material-icon-svg/dist/QrcodeEdit';
// export const QrcodeEditIconSvg = QrcodeEditIcon;

// import QrcodeScanIcon from 'react-material-icon-svg/dist/QrcodeScan';
// export const QrcodeScanIconSvg = QrcodeScanIcon;

// import QuadcopterIcon from 'react-material-icon-svg/dist/Quadcopter';
// export const QuadcopterIconSvg = QuadcopterIcon;

// import QualityHighIcon from 'react-material-icon-svg/dist/QualityHigh';
// export const QualityHighIconSvg = QualityHighIcon;

// import QualityLowIcon from 'react-material-icon-svg/dist/QualityLow';
// export const QualityLowIconSvg = QualityLowIcon;

// import QualityMediumIcon from 'react-material-icon-svg/dist/QualityMedium';
// export const QualityMediumIconSvg = QualityMediumIcon;

// import QuicktimeIcon from 'react-material-icon-svg/dist/Quicktime';
// export const QuicktimeIconSvg = QuicktimeIcon;

// import QuoraIcon from 'react-material-icon-svg/dist/Quora';
// export const QuoraIconSvg = QuoraIcon;

// import RabbitIcon from 'react-material-icon-svg/dist/Rabbit';
// export const RabbitIconSvg = RabbitIcon;

// import RacingHelmetIcon from 'react-material-icon-svg/dist/RacingHelmet';
// export const RacingHelmetIconSvg = RacingHelmetIcon;

// import RacquetballIcon from 'react-material-icon-svg/dist/Racquetball';
// export const RacquetballIconSvg = RacquetballIcon;

// import RadarIcon from 'react-material-icon-svg/dist/Radar';
// export const RadarIconSvg = RadarIcon;

// import RadiatorIcon from 'react-material-icon-svg/dist/Radiator';
// export const RadiatorIconSvg = RadiatorIcon;

// import RadiatorDisabledIcon from 'react-material-icon-svg/dist/RadiatorDisabled';
// export const RadiatorDisabledIconSvg = RadiatorDisabledIcon;

// import RadiatorOffIcon from 'react-material-icon-svg/dist/RadiatorOff';
// export const RadiatorOffIconSvg = RadiatorOffIcon;

// import RadioIcon from 'react-material-icon-svg/dist/Radio';
// export const RadioIconSvg = RadioIcon;

// import RadioAmIcon from 'react-material-icon-svg/dist/RadioAm';
// export const RadioAmIconSvg = RadioAmIcon;

// import RadioFmIcon from 'react-material-icon-svg/dist/RadioFm';
// export const RadioFmIconSvg = RadioFmIcon;

// import RadioHandheldIcon from 'react-material-icon-svg/dist/RadioHandheld';
// export const RadioHandheldIconSvg = RadioHandheldIcon;

// import RadioTowerIcon from 'react-material-icon-svg/dist/RadioTower';
// export const RadioTowerIconSvg = RadioTowerIcon;

// import RadioactiveIcon from 'react-material-icon-svg/dist/Radioactive';
// export const RadioactiveIconSvg = RadioactiveIcon;

// import RadioactiveOffIcon from 'react-material-icon-svg/dist/RadioactiveOff';
// export const RadioactiveOffIconSvg = RadioactiveOffIcon;

// import RadioboxBlankIcon from 'react-material-icon-svg/dist/RadioboxBlank';
// export const RadioboxBlankIconSvg = RadioboxBlankIcon;

// import RadioboxMarkedIcon from 'react-material-icon-svg/dist/RadioboxMarked';
// export const RadioboxMarkedIconSvg = RadioboxMarkedIcon;

// import RadiusIcon from 'react-material-icon-svg/dist/Radius';
// export const RadiusIconSvg = RadiusIcon;

// import RadiusOutlineIcon from 'react-material-icon-svg/dist/RadiusOutline';
// export const RadiusOutlineIconSvg = RadiusOutlineIcon;

// import RaspberryPiIcon from 'react-material-icon-svg/dist/RaspberryPi';
// export const RaspberryPiIconSvg = RaspberryPiIcon;

// import RayEndIcon from 'react-material-icon-svg/dist/RayEnd';
// export const RayEndIconSvg = RayEndIcon;

// import RayEndArrowIcon from 'react-material-icon-svg/dist/RayEndArrow';
// export const RayEndArrowIconSvg = RayEndArrowIcon;

// import RayStartIcon from 'react-material-icon-svg/dist/RayStart';
// export const RayStartIconSvg = RayStartIcon;

// import RayStartArrowIcon from 'react-material-icon-svg/dist/RayStartArrow';
// export const RayStartArrowIconSvg = RayStartArrowIcon;

// import RayStartEndIcon from 'react-material-icon-svg/dist/RayStartEnd';
// export const RayStartEndIconSvg = RayStartEndIcon;

// import RayVertexIcon from 'react-material-icon-svg/dist/RayVertex';
// export const RayVertexIconSvg = RayVertexIcon;

// import ReactIcon from 'react-material-icon-svg/dist/React';
// export const ReactIconSvg = ReactIcon;

// import ReadIcon from 'react-material-icon-svg/dist/Read';
// export const ReadIconSvg = ReadIcon;

// import ReceiptIcon from 'react-material-icon-svg/dist/Receipt';
// export const ReceiptIconSvg = ReceiptIcon;

// import RecordIcon from 'react-material-icon-svg/dist/Record';
// export const RecordIconSvg = RecordIcon;

// import RecordCircleIcon from 'react-material-icon-svg/dist/RecordCircle';
// export const RecordCircleIconSvg = RecordCircleIcon;

// import RecordCircleOutlineIcon from 'react-material-icon-svg/dist/RecordCircleOutline';
// export const RecordCircleOutlineIconSvg = RecordCircleOutlineIcon;

// import RecordPlayerIcon from 'react-material-icon-svg/dist/RecordPlayer';
// export const RecordPlayerIconSvg = RecordPlayerIcon;

// import RecordRecIcon from 'react-material-icon-svg/dist/RecordRec';
// export const RecordRecIconSvg = RecordRecIcon;

// import RectangleIcon from 'react-material-icon-svg/dist/Rectangle';
// export const RectangleIconSvg = RectangleIcon;

// import RectangleOutlineIcon from 'react-material-icon-svg/dist/RectangleOutline';
// export const RectangleOutlineIconSvg = RectangleOutlineIcon;

// import RecycleIcon from 'react-material-icon-svg/dist/Recycle';
// export const RecycleIconSvg = RecycleIcon;

// import RedditIcon from 'react-material-icon-svg/dist/Reddit';
// export const RedditIconSvg = RedditIcon;

// import RedoIcon from 'react-material-icon-svg/dist/Redo';
// export const RedoIconSvg = RedoIcon;

// import RedoVariantIcon from 'react-material-icon-svg/dist/RedoVariant';
// export const RedoVariantIconSvg = RedoVariantIcon;

// import ReflectHorizontalIcon from 'react-material-icon-svg/dist/ReflectHorizontal';
// export const ReflectHorizontalIconSvg = ReflectHorizontalIcon;

// import ReflectVerticalIcon from 'react-material-icon-svg/dist/ReflectVertical';
// export const ReflectVerticalIconSvg = ReflectVerticalIcon;

// import RefreshIcon from 'react-material-icon-svg/dist/Refresh';
// export const RefreshIconSvg = RefreshIcon;

// import RegexIcon from 'react-material-icon-svg/dist/Regex';
// export const RegexIconSvg = RegexIcon;

// import RegisteredTrademarkIcon from 'react-material-icon-svg/dist/RegisteredTrademark';
// export const RegisteredTrademarkIconSvg = RegisteredTrademarkIcon;

// import RelativeScaleIcon from 'react-material-icon-svg/dist/RelativeScale';
// export const RelativeScaleIconSvg = RelativeScaleIcon;

// import ReloadIcon from 'react-material-icon-svg/dist/Reload';
// export const ReloadIconSvg = ReloadIcon;

// import ReminderIcon from 'react-material-icon-svg/dist/Reminder';
// export const ReminderIconSvg = ReminderIcon;

// import RemoteIcon from 'react-material-icon-svg/dist/Remote';
// export const RemoteIconSvg = RemoteIcon;

// import RemoteDesktopIcon from 'react-material-icon-svg/dist/RemoteDesktop';
// export const RemoteDesktopIconSvg = RemoteDesktopIcon;

// import RemoteOffIcon from 'react-material-icon-svg/dist/RemoteOff';
// export const RemoteOffIconSvg = RemoteOffIcon;

// import RemoteTvIcon from 'react-material-icon-svg/dist/RemoteTv';
// export const RemoteTvIconSvg = RemoteTvIcon;

// import RemoteTvOffIcon from 'react-material-icon-svg/dist/RemoteTvOff';
// export const RemoteTvOffIconSvg = RemoteTvOffIcon;

// import RenameBoxIcon from 'react-material-icon-svg/dist/RenameBox';
// export const RenameBoxIconSvg = RenameBoxIcon;

// import ReorderHorizontalIcon from 'react-material-icon-svg/dist/ReorderHorizontal';
// export const ReorderHorizontalIconSvg = ReorderHorizontalIcon;

// import ReorderVerticalIcon from 'react-material-icon-svg/dist/ReorderVertical';
// export const ReorderVerticalIconSvg = ReorderVerticalIcon;

// import RepeatIcon from 'react-material-icon-svg/dist/Repeat';
// export const RepeatIconSvg = RepeatIcon;

// import RepeatOffIcon from 'react-material-icon-svg/dist/RepeatOff';
// export const RepeatOffIconSvg = RepeatOffIcon;

// import RepeatOnceIcon from 'react-material-icon-svg/dist/RepeatOnce';
// export const RepeatOnceIconSvg = RepeatOnceIcon;

// import ReplayIcon from 'react-material-icon-svg/dist/Replay';
// export const ReplayIconSvg = ReplayIcon;

// import ReplyIcon from 'react-material-icon-svg/dist/Reply';
// export const ReplyIconSvg = ReplyIcon;

// import ReplyAllIcon from 'react-material-icon-svg/dist/ReplyAll';
// export const ReplyAllIconSvg = ReplyAllIcon;

// import ReproductionIcon from 'react-material-icon-svg/dist/Reproduction';
// export const ReproductionIconSvg = ReproductionIcon;

// import ResistorIcon from 'react-material-icon-svg/dist/Resistor';
// export const ResistorIconSvg = ResistorIcon;

// import ResistorNodesIcon from 'react-material-icon-svg/dist/ResistorNodes';
// export const ResistorNodesIconSvg = ResistorNodesIcon;

// import ResizeIcon from 'react-material-icon-svg/dist/Resize';
// export const ResizeIconSvg = ResizeIcon;

// import ResizeBottomRightIcon from 'react-material-icon-svg/dist/ResizeBottomRight';
// export const ResizeBottomRightIconSvg = ResizeBottomRightIcon;

// import ResponsiveIcon from 'react-material-icon-svg/dist/Responsive';
// export const ResponsiveIconSvg = ResponsiveIcon;

// import RestartIcon from 'react-material-icon-svg/dist/Restart';
// export const RestartIconSvg = RestartIcon;

// import RestartOffIcon from 'react-material-icon-svg/dist/RestartOff';
// export const RestartOffIconSvg = RestartOffIcon;

// import RestoreIcon from 'react-material-icon-svg/dist/Restore';
// export const RestoreIconSvg = RestoreIcon;

// import RestoreClockIcon from 'react-material-icon-svg/dist/RestoreClock';
// export const RestoreClockIconSvg = RestoreClockIcon;

// import RewindIcon from 'react-material-icon-svg/dist/Rewind';
// export const RewindIconSvg = RewindIcon;

// import RewindOutlineIcon from 'react-material-icon-svg/dist/RewindOutline';
// export const RewindOutlineIconSvg = RewindOutlineIcon;

// import Rewind_10Icon from 'react-material-icon-svg/dist/Rewind_10';
// export const Rewind_10IconSvg = Rewind_10Icon;

// import Rewind_30Icon from 'react-material-icon-svg/dist/Rewind_30';
// export const Rewind_30IconSvg = Rewind_30Icon;

// import RhombusIcon from 'react-material-icon-svg/dist/Rhombus';
// export const RhombusIconSvg = RhombusIcon;

// import RhombusMediumIcon from 'react-material-icon-svg/dist/RhombusMedium';
// export const RhombusMediumIconSvg = RhombusMediumIcon;

// import RhombusOutlineIcon from 'react-material-icon-svg/dist/RhombusOutline';
// export const RhombusOutlineIconSvg = RhombusOutlineIcon;

// import RhombusSplitIcon from 'react-material-icon-svg/dist/RhombusSplit';
// export const RhombusSplitIconSvg = RhombusSplitIcon;

// import RibbonIcon from 'react-material-icon-svg/dist/Ribbon';
// export const RibbonIconSvg = RibbonIcon;

// import RiceIcon from 'react-material-icon-svg/dist/Rice';
// export const RiceIconSvg = RiceIcon;

// import RingIcon from 'react-material-icon-svg/dist/Ring';
// export const RingIconSvg = RingIcon;

// import RivetIcon from 'react-material-icon-svg/dist/Rivet';
// export const RivetIconSvg = RivetIcon;

// import RoadIcon from 'react-material-icon-svg/dist/Road';
// export const RoadIconSvg = RoadIcon;

// import RoadVariantIcon from 'react-material-icon-svg/dist/RoadVariant';
// export const RoadVariantIconSvg = RoadVariantIcon;

// import RobotIcon from 'react-material-icon-svg/dist/Robot';
// export const RobotIconSvg = RobotIcon;

// import RobotIndustrialIcon from 'react-material-icon-svg/dist/RobotIndustrial';
// export const RobotIndustrialIconSvg = RobotIndustrialIcon;

// import RobotVacuumIcon from 'react-material-icon-svg/dist/RobotVacuum';
// export const RobotVacuumIconSvg = RobotVacuumIcon;

// import RobotVacuumVariantIcon from 'react-material-icon-svg/dist/RobotVacuumVariant';
// export const RobotVacuumVariantIconSvg = RobotVacuumVariantIcon;

// import RocketIcon from 'react-material-icon-svg/dist/Rocket';
// export const RocketIconSvg = RocketIcon;

// import RollerSkateIcon from 'react-material-icon-svg/dist/RollerSkate';
// export const RollerSkateIconSvg = RollerSkateIcon;

// import RollerbladeIcon from 'react-material-icon-svg/dist/Rollerblade';
// export const RollerbladeIconSvg = RollerbladeIcon;

// import RollupjsIcon from 'react-material-icon-svg/dist/Rollupjs';
// export const RollupjsIconSvg = RollupjsIcon;

// import RoomServiceIcon from 'react-material-icon-svg/dist/RoomService';
// export const RoomServiceIconSvg = RoomServiceIcon;

// import RoomServiceOutlineIcon from 'react-material-icon-svg/dist/RoomServiceOutline';
// export const RoomServiceOutlineIconSvg = RoomServiceOutlineIcon;

// import RotateLeftIcon from 'react-material-icon-svg/dist/RotateLeft';
// export const RotateLeftIconSvg = RotateLeftIcon;

// import RotateLeftVariantIcon from 'react-material-icon-svg/dist/RotateLeftVariant';
// export const RotateLeftVariantIconSvg = RotateLeftVariantIcon;

// import RotateOrbitIcon from 'react-material-icon-svg/dist/RotateOrbit';
// export const RotateOrbitIconSvg = RotateOrbitIcon;

// import RotateRightIcon from 'react-material-icon-svg/dist/RotateRight';
// export const RotateRightIconSvg = RotateRightIcon;

// import RotateRightVariantIcon from 'react-material-icon-svg/dist/RotateRightVariant';
// export const RotateRightVariantIconSvg = RotateRightVariantIcon;

// import Rotate_3dIcon from 'react-material-icon-svg/dist/Rotate_3d';
// export const Rotate_3dIconSvg = Rotate_3dIcon;

// import Rotate_3dVariantIcon from 'react-material-icon-svg/dist/Rotate_3dVariant';
// export const Rotate_3dVariantIconSvg = Rotate_3dVariantIcon;

// import RoundedCornerIcon from 'react-material-icon-svg/dist/RoundedCorner';
// export const RoundedCornerIconSvg = RoundedCornerIcon;

// import RouterWirelessIcon from 'react-material-icon-svg/dist/RouterWireless';
// export const RouterWirelessIconSvg = RouterWirelessIcon;

// import RouterWirelessSettingsIcon from 'react-material-icon-svg/dist/RouterWirelessSettings';
// export const RouterWirelessSettingsIconSvg = RouterWirelessSettingsIcon;

// import RoutesIcon from 'react-material-icon-svg/dist/Routes';
// export const RoutesIconSvg = RoutesIcon;

// import RowingIcon from 'react-material-icon-svg/dist/Rowing';
// export const RowingIconSvg = RowingIcon;

// import RssIcon from 'react-material-icon-svg/dist/Rss';
// export const RssIconSvg = RssIcon;

// import RssBoxIcon from 'react-material-icon-svg/dist/RssBox';
// export const RssBoxIconSvg = RssBoxIcon;

// import RubyIcon from 'react-material-icon-svg/dist/Ruby';
// export const RubyIconSvg = RubyIcon;

// import RugbyIcon from 'react-material-icon-svg/dist/Rugby';
// export const RugbyIconSvg = RugbyIcon;

// import RulerIcon from 'react-material-icon-svg/dist/Ruler';
// export const RulerIconSvg = RulerIcon;

// import RulerSquareIcon from 'react-material-icon-svg/dist/RulerSquare';
// export const RulerSquareIconSvg = RulerSquareIcon;

// import RunIcon from 'react-material-icon-svg/dist/Run';
// export const RunIconSvg = RunIcon;

// import RunFastIcon from 'react-material-icon-svg/dist/RunFast';
// export const RunFastIconSvg = RunFastIcon;

// import SackIcon from 'react-material-icon-svg/dist/Sack';
// export const SackIconSvg = SackIcon;

import SackPercentIcon from 'react-material-icon-svg/dist/SackPercent';
export const SackPercentIconSvg = SackPercentIcon;

// import SafeIcon from 'react-material-icon-svg/dist/Safe';
// export const SafeIconSvg = SafeIcon;

// import SafetyGogglesIcon from 'react-material-icon-svg/dist/SafetyGoggles';
// export const SafetyGogglesIconSvg = SafetyGogglesIcon;

// import SailingIcon from 'react-material-icon-svg/dist/Sailing';
// export const SailingIconSvg = SailingIcon;

// import SaleIcon from 'react-material-icon-svg/dist/Sale';
// export const SaleIconSvg = SaleIcon;

// import SalesforceIcon from 'react-material-icon-svg/dist/Salesforce';
// export const SalesforceIconSvg = SalesforceIcon;

// import SassIcon from 'react-material-icon-svg/dist/Sass';
// export const SassIconSvg = SassIcon;

// import SatelliteIcon from 'react-material-icon-svg/dist/Satellite';
// export const SatelliteIconSvg = SatelliteIcon;

// import SatelliteUplinkIcon from 'react-material-icon-svg/dist/SatelliteUplink';
// export const SatelliteUplinkIconSvg = SatelliteUplinkIcon;

// import SatelliteVariantIcon from 'react-material-icon-svg/dist/SatelliteVariant';
// export const SatelliteVariantIconSvg = SatelliteVariantIcon;

// import SausageIcon from 'react-material-icon-svg/dist/Sausage';
// export const SausageIconSvg = SausageIcon;

// import SawBladeIcon from 'react-material-icon-svg/dist/SawBlade';
// export const SawBladeIconSvg = SawBladeIcon;

// import SaxophoneIcon from 'react-material-icon-svg/dist/Saxophone';
// export const SaxophoneIconSvg = SaxophoneIcon;

// import ScaleIcon from 'react-material-icon-svg/dist/Scale';
// export const ScaleIconSvg = ScaleIcon;

// import ScaleBalanceIcon from 'react-material-icon-svg/dist/ScaleBalance';
// export const ScaleBalanceIconSvg = ScaleBalanceIcon;

// import ScaleBathroomIcon from 'react-material-icon-svg/dist/ScaleBathroom';
// export const ScaleBathroomIconSvg = ScaleBathroomIcon;

// import ScannerIcon from 'react-material-icon-svg/dist/Scanner';
// export const ScannerIconSvg = ScannerIcon;

// import ScannerOffIcon from 'react-material-icon-svg/dist/ScannerOff';
// export const ScannerOffIconSvg = ScannerOffIcon;

// import ScatterPlotIcon from 'react-material-icon-svg/dist/ScatterPlot';
// export const ScatterPlotIconSvg = ScatterPlotIcon;

// import ScatterPlotOutlineIcon from 'react-material-icon-svg/dist/ScatterPlotOutline';
// export const ScatterPlotOutlineIconSvg = ScatterPlotOutlineIcon;

// import SchoolIcon from 'react-material-icon-svg/dist/School';
// export const SchoolIconSvg = SchoolIcon;

// import ScissorsCuttingIcon from 'react-material-icon-svg/dist/ScissorsCutting';
// export const ScissorsCuttingIconSvg = ScissorsCuttingIcon;

// import ScreenRotationIcon from 'react-material-icon-svg/dist/ScreenRotation';
// export const ScreenRotationIconSvg = ScreenRotationIcon;

// import ScreenRotationLockIcon from 'react-material-icon-svg/dist/ScreenRotationLock';
// export const ScreenRotationLockIconSvg = ScreenRotationLockIcon;

// import ScrewFlatTopIcon from 'react-material-icon-svg/dist/ScrewFlatTop';
// export const ScrewFlatTopIconSvg = ScrewFlatTopIcon;

// import ScrewLagIcon from 'react-material-icon-svg/dist/ScrewLag';
// export const ScrewLagIconSvg = ScrewLagIcon;

// import ScrewMachineFlatTopIcon from 'react-material-icon-svg/dist/ScrewMachineFlatTop';
// export const ScrewMachineFlatTopIconSvg = ScrewMachineFlatTopIcon;

// import ScrewMachineRoundTopIcon from 'react-material-icon-svg/dist/ScrewMachineRoundTop';
// export const ScrewMachineRoundTopIconSvg = ScrewMachineRoundTopIcon;

// import ScrewRoundTopIcon from 'react-material-icon-svg/dist/ScrewRoundTop';
// export const ScrewRoundTopIconSvg = ScrewRoundTopIcon;

// import ScrewdriverIcon from 'react-material-icon-svg/dist/Screwdriver';
// export const ScrewdriverIconSvg = ScrewdriverIcon;

// import ScriptIcon from 'react-material-icon-svg/dist/Script';
// export const ScriptIconSvg = ScriptIcon;

// import ScriptOutlineIcon from 'react-material-icon-svg/dist/ScriptOutline';
// export const ScriptOutlineIconSvg = ScriptOutlineIcon;

// import ScriptTextIcon from 'react-material-icon-svg/dist/ScriptText';
// export const ScriptTextIconSvg = ScriptTextIcon;

// import ScriptTextOutlineIcon from 'react-material-icon-svg/dist/ScriptTextOutline';
// export const ScriptTextOutlineIconSvg = ScriptTextOutlineIcon;

// import SdIcon from 'react-material-icon-svg/dist/Sd';
// export const SdIconSvg = SdIcon;

// import SealIcon from 'react-material-icon-svg/dist/Seal';
// export const SealIconSvg = SealIcon;

// import SearchWebIcon from 'react-material-icon-svg/dist/SearchWeb';
// export const SearchWebIconSvg = SearchWebIcon;

// import SeatIcon from 'react-material-icon-svg/dist/Seat';
// export const SeatIconSvg = SeatIcon;

// import SeatFlatIcon from 'react-material-icon-svg/dist/SeatFlat';
// export const SeatFlatIconSvg = SeatFlatIcon;

// import SeatFlatAngledIcon from 'react-material-icon-svg/dist/SeatFlatAngled';
// export const SeatFlatAngledIconSvg = SeatFlatAngledIcon;

// import SeatIndividualSuiteIcon from 'react-material-icon-svg/dist/SeatIndividualSuite';
// export const SeatIndividualSuiteIconSvg = SeatIndividualSuiteIcon;

// import SeatLegroomExtraIcon from 'react-material-icon-svg/dist/SeatLegroomExtra';
// export const SeatLegroomExtraIconSvg = SeatLegroomExtraIcon;

// import SeatLegroomNormalIcon from 'react-material-icon-svg/dist/SeatLegroomNormal';
// export const SeatLegroomNormalIconSvg = SeatLegroomNormalIcon;

// import SeatLegroomReducedIcon from 'react-material-icon-svg/dist/SeatLegroomReduced';
// export const SeatLegroomReducedIconSvg = SeatLegroomReducedIcon;

// import SeatOutlineIcon from 'react-material-icon-svg/dist/SeatOutline';
// export const SeatOutlineIconSvg = SeatOutlineIcon;

// import SeatReclineExtraIcon from 'react-material-icon-svg/dist/SeatReclineExtra';
// export const SeatReclineExtraIconSvg = SeatReclineExtraIcon;

// import SeatReclineNormalIcon from 'react-material-icon-svg/dist/SeatReclineNormal';
// export const SeatReclineNormalIconSvg = SeatReclineNormalIcon;

// import SeatbeltIcon from 'react-material-icon-svg/dist/Seatbelt';
// export const SeatbeltIconSvg = SeatbeltIcon;

// import SecurityIcon from 'react-material-icon-svg/dist/Security';
// export const SecurityIconSvg = SecurityIcon;

// import SecurityNetworkIcon from 'react-material-icon-svg/dist/SecurityNetwork';
// export const SecurityNetworkIconSvg = SecurityNetworkIcon;

// import SeedIcon from 'react-material-icon-svg/dist/Seed';
// export const SeedIconSvg = SeedIcon;

// import SeedOutlineIcon from 'react-material-icon-svg/dist/SeedOutline';
// export const SeedOutlineIconSvg = SeedOutlineIcon;

// import SegmentIcon from 'react-material-icon-svg/dist/Segment';
// export const SegmentIconSvg = SegmentIcon;

// import SelectIcon from 'react-material-icon-svg/dist/Select';
// export const SelectIconSvg = SelectIcon;

// import SelectAllIcon from 'react-material-icon-svg/dist/SelectAll';
// export const SelectAllIconSvg = SelectAllIcon;

// import SelectColorIcon from 'react-material-icon-svg/dist/SelectColor';
// export const SelectColorIconSvg = SelectColorIcon;

// import SelectCompareIcon from 'react-material-icon-svg/dist/SelectCompare';
// export const SelectCompareIconSvg = SelectCompareIcon;

// import SelectDragIcon from 'react-material-icon-svg/dist/SelectDrag';
// export const SelectDragIconSvg = SelectDragIcon;

// import SelectInverseIcon from 'react-material-icon-svg/dist/SelectInverse';
// export const SelectInverseIconSvg = SelectInverseIcon;

// import SelectOffIcon from 'react-material-icon-svg/dist/SelectOff';
// export const SelectOffIconSvg = SelectOffIcon;

// import SelectionIcon from 'react-material-icon-svg/dist/Selection';
// export const SelectionIconSvg = SelectionIcon;

// import SelectionDragIcon from 'react-material-icon-svg/dist/SelectionDrag';
// export const SelectionDragIconSvg = SelectionDragIcon;

// import SelectionEllipseIcon from 'react-material-icon-svg/dist/SelectionEllipse';
// export const SelectionEllipseIconSvg = SelectionEllipseIcon;

// import SelectionOffIcon from 'react-material-icon-svg/dist/SelectionOff';
// export const SelectionOffIconSvg = SelectionOffIcon;

// import SendIcon from 'react-material-icon-svg/dist/Send';
// export const SendIconSvg = SendIcon;

// import SendCircleIcon from 'react-material-icon-svg/dist/SendCircle';
// export const SendCircleIconSvg = SendCircleIcon;

// import SendCircleOutlineIcon from 'react-material-icon-svg/dist/SendCircleOutline';
// export const SendCircleOutlineIconSvg = SendCircleOutlineIcon;

// import SendLockIcon from 'react-material-icon-svg/dist/SendLock';
// export const SendLockIconSvg = SendLockIcon;

// import SerialPortIcon from 'react-material-icon-svg/dist/SerialPort';
// export const SerialPortIconSvg = SerialPortIcon;

// import ServerIcon from 'react-material-icon-svg/dist/Server';
// export const ServerIconSvg = ServerIcon;

// import ServerMinusIcon from 'react-material-icon-svg/dist/ServerMinus';
// export const ServerMinusIconSvg = ServerMinusIcon;

// import ServerNetworkIcon from 'react-material-icon-svg/dist/ServerNetwork';
// export const ServerNetworkIconSvg = ServerNetworkIcon;

// import ServerNetworkOffIcon from 'react-material-icon-svg/dist/ServerNetworkOff';
// export const ServerNetworkOffIconSvg = ServerNetworkOffIcon;

// import ServerOffIcon from 'react-material-icon-svg/dist/ServerOff';
// export const ServerOffIconSvg = ServerOffIcon;

// import ServerPlusIcon from 'react-material-icon-svg/dist/ServerPlus';
// export const ServerPlusIconSvg = ServerPlusIcon;

// import ServerRemoveIcon from 'react-material-icon-svg/dist/ServerRemove';
// export const ServerRemoveIconSvg = ServerRemoveIcon;

// import ServerSecurityIcon from 'react-material-icon-svg/dist/ServerSecurity';
// export const ServerSecurityIconSvg = ServerSecurityIcon;

// import SetAllIcon from 'react-material-icon-svg/dist/SetAll';
// export const SetAllIconSvg = SetAllIcon;

// import SetCenterIcon from 'react-material-icon-svg/dist/SetCenter';
// export const SetCenterIconSvg = SetCenterIcon;

// import SetCenterRightIcon from 'react-material-icon-svg/dist/SetCenterRight';
// export const SetCenterRightIconSvg = SetCenterRightIcon;

// import SetLeftIcon from 'react-material-icon-svg/dist/SetLeft';
// export const SetLeftIconSvg = SetLeftIcon;

// import SetLeftCenterIcon from 'react-material-icon-svg/dist/SetLeftCenter';
// export const SetLeftCenterIconSvg = SetLeftCenterIcon;

// import SetLeftRightIcon from 'react-material-icon-svg/dist/SetLeftRight';
// export const SetLeftRightIconSvg = SetLeftRightIcon;

// import SetNoneIcon from 'react-material-icon-svg/dist/SetNone';
// export const SetNoneIconSvg = SetNoneIcon;

// import SetRightIcon from 'react-material-icon-svg/dist/SetRight';
// export const SetRightIconSvg = SetRightIcon;

// import SetTopBoxIcon from 'react-material-icon-svg/dist/SetTopBox';
// export const SetTopBoxIconSvg = SetTopBoxIcon;

import SettingsIcon from 'react-material-icon-svg/dist/Settings';
export const SettingsIconSvg = SettingsIcon;

// import SettingsBoxIcon from 'react-material-icon-svg/dist/SettingsBox';
// export const SettingsBoxIconSvg = SettingsBoxIcon;

// import SettingsHelperIcon from 'react-material-icon-svg/dist/SettingsHelper';
// export const SettingsHelperIconSvg = SettingsHelperIcon;

// import SettingsOutlineIcon from 'react-material-icon-svg/dist/SettingsOutline';
// export const SettingsOutlineIconSvg = SettingsOutlineIcon;

// import ShapeIcon from 'react-material-icon-svg/dist/Shape';
// export const ShapeIconSvg = ShapeIcon;

// import ShapeCirclePlusIcon from 'react-material-icon-svg/dist/ShapeCirclePlus';
// export const ShapeCirclePlusIconSvg = ShapeCirclePlusIcon;

// import ShapeOutlineIcon from 'react-material-icon-svg/dist/ShapeOutline';
// export const ShapeOutlineIconSvg = ShapeOutlineIcon;

// import ShapePlusIcon from 'react-material-icon-svg/dist/ShapePlus';
// export const ShapePlusIconSvg = ShapePlusIcon;

// import ShapePolygonPlusIcon from 'react-material-icon-svg/dist/ShapePolygonPlus';
// export const ShapePolygonPlusIconSvg = ShapePolygonPlusIcon;

// import ShapeRectanglePlusIcon from 'react-material-icon-svg/dist/ShapeRectanglePlus';
// export const ShapeRectanglePlusIconSvg = ShapeRectanglePlusIcon;

// import ShapeSquarePlusIcon from 'react-material-icon-svg/dist/ShapeSquarePlus';
// export const ShapeSquarePlusIconSvg = ShapeSquarePlusIcon;

// import ShareIcon from 'react-material-icon-svg/dist/Share';
// export const ShareIconSvg = ShareIcon;

// import ShareOutlineIcon from 'react-material-icon-svg/dist/ShareOutline';
// export const ShareOutlineIconSvg = ShareOutlineIcon;

// import ShareVariantIcon from 'react-material-icon-svg/dist/ShareVariant';
// export const ShareVariantIconSvg = ShareVariantIcon;

// import SheepIcon from 'react-material-icon-svg/dist/Sheep';
// export const SheepIconSvg = SheepIcon;

// import ShieldIcon from 'react-material-icon-svg/dist/Shield';
// export const ShieldIconSvg = ShieldIcon;

import ShieldAccountIcon from 'react-material-icon-svg/dist/ShieldAccount';
export const ShieldAccountIconSvg = ShieldAccountIcon;

// import ShieldAccountOutlineIcon from 'react-material-icon-svg/dist/ShieldAccountOutline';
// export const ShieldAccountOutlineIconSvg = ShieldAccountOutlineIcon;

// import ShieldAirplaneIcon from 'react-material-icon-svg/dist/ShieldAirplane';
// export const ShieldAirplaneIconSvg = ShieldAirplaneIcon;

// import ShieldAirplaneOutlineIcon from 'react-material-icon-svg/dist/ShieldAirplaneOutline';
// export const ShieldAirplaneOutlineIconSvg = ShieldAirplaneOutlineIcon;

// import ShieldAlertIcon from 'react-material-icon-svg/dist/ShieldAlert';
// export const ShieldAlertIconSvg = ShieldAlertIcon;

// import ShieldAlertOutlineIcon from 'react-material-icon-svg/dist/ShieldAlertOutline';
// export const ShieldAlertOutlineIconSvg = ShieldAlertOutlineIcon;

import ShieldCheckIcon from 'react-material-icon-svg/dist/ShieldCheck';
export const ShieldCheckIconSvg = ShieldCheckIcon;

// import ShieldCheckOutlineIcon from 'react-material-icon-svg/dist/ShieldCheckOutline';
// export const ShieldCheckOutlineIconSvg = ShieldCheckOutlineIcon;

// import ShieldCrossIcon from 'react-material-icon-svg/dist/ShieldCross';
// export const ShieldCrossIconSvg = ShieldCrossIcon;

// import ShieldCrossOutlineIcon from 'react-material-icon-svg/dist/ShieldCrossOutline';
// export const ShieldCrossOutlineIconSvg = ShieldCrossOutlineIcon;

// import ShieldHalfFullIcon from 'react-material-icon-svg/dist/ShieldHalfFull';
// export const ShieldHalfFullIconSvg = ShieldHalfFullIcon;

// import ShieldHomeIcon from 'react-material-icon-svg/dist/ShieldHome';
// export const ShieldHomeIconSvg = ShieldHomeIcon;

// import ShieldHomeOutlineIcon from 'react-material-icon-svg/dist/ShieldHomeOutline';
// export const ShieldHomeOutlineIconSvg = ShieldHomeOutlineIcon;

// import ShieldKeyIcon from 'react-material-icon-svg/dist/ShieldKey';
// export const ShieldKeyIconSvg = ShieldKeyIcon;

// import ShieldKeyOutlineIcon from 'react-material-icon-svg/dist/ShieldKeyOutline';
// export const ShieldKeyOutlineIconSvg = ShieldKeyOutlineIcon;

// import ShieldLinkVariantIcon from 'react-material-icon-svg/dist/ShieldLinkVariant';
// export const ShieldLinkVariantIconSvg = ShieldLinkVariantIcon;

// import ShieldLinkVariantOutlineIcon from 'react-material-icon-svg/dist/ShieldLinkVariantOutline';
// export const ShieldLinkVariantOutlineIconSvg = ShieldLinkVariantOutlineIcon;

// import ShieldLockIcon from 'react-material-icon-svg/dist/ShieldLock';
// export const ShieldLockIconSvg = ShieldLockIcon;

// import ShieldLockOutlineIcon from 'react-material-icon-svg/dist/ShieldLockOutline';
// export const ShieldLockOutlineIconSvg = ShieldLockOutlineIcon;

// import ShieldOffIcon from 'react-material-icon-svg/dist/ShieldOff';
// export const ShieldOffIconSvg = ShieldOffIcon;

// import ShieldOffOutlineIcon from 'react-material-icon-svg/dist/ShieldOffOutline';
// export const ShieldOffOutlineIconSvg = ShieldOffOutlineIcon;

// import ShieldOutlineIcon from 'react-material-icon-svg/dist/ShieldOutline';
// export const ShieldOutlineIconSvg = ShieldOutlineIcon;

// import ShieldPlusIcon from 'react-material-icon-svg/dist/ShieldPlus';
// export const ShieldPlusIconSvg = ShieldPlusIcon;

// import ShieldPlusOutlineIcon from 'react-material-icon-svg/dist/ShieldPlusOutline';
// export const ShieldPlusOutlineIconSvg = ShieldPlusOutlineIcon;

// import ShieldRemoveIcon from 'react-material-icon-svg/dist/ShieldRemove';
// export const ShieldRemoveIconSvg = ShieldRemoveIcon;

// import ShieldRemoveOutlineIcon from 'react-material-icon-svg/dist/ShieldRemoveOutline';
// export const ShieldRemoveOutlineIconSvg = ShieldRemoveOutlineIcon;

// import ShieldSearchIcon from 'react-material-icon-svg/dist/ShieldSearch';
// export const ShieldSearchIconSvg = ShieldSearchIcon;

// import ShipWheelIcon from 'react-material-icon-svg/dist/ShipWheel';
// export const ShipWheelIconSvg = ShipWheelIcon;

// import ShoeFormalIcon from 'react-material-icon-svg/dist/ShoeFormal';
// export const ShoeFormalIconSvg = ShoeFormalIcon;

// import ShoeHeelIcon from 'react-material-icon-svg/dist/ShoeHeel';
// export const ShoeHeelIconSvg = ShoeHeelIcon;

// import ShoePrintIcon from 'react-material-icon-svg/dist/ShoePrint';
// export const ShoePrintIconSvg = ShoePrintIcon;

// import ShopifyIcon from 'react-material-icon-svg/dist/Shopify';
// export const ShopifyIconSvg = ShopifyIcon;

// import ShoppingIcon from 'react-material-icon-svg/dist/Shopping';
// export const ShoppingIconSvg = ShoppingIcon;

// import ShoppingMusicIcon from 'react-material-icon-svg/dist/ShoppingMusic';
// export const ShoppingMusicIconSvg = ShoppingMusicIcon;

// import ShovelIcon from 'react-material-icon-svg/dist/Shovel';
// export const ShovelIconSvg = ShovelIcon;

// import ShovelOffIcon from 'react-material-icon-svg/dist/ShovelOff';
// export const ShovelOffIconSvg = ShovelOffIcon;

// import ShowerIcon from 'react-material-icon-svg/dist/Shower';
// export const ShowerIconSvg = ShowerIcon;

// import ShowerHeadIcon from 'react-material-icon-svg/dist/ShowerHead';
// export const ShowerHeadIconSvg = ShowerHeadIcon;

// import ShredderIcon from 'react-material-icon-svg/dist/Shredder';
// export const ShredderIconSvg = ShredderIcon;

// import ShuffleIcon from 'react-material-icon-svg/dist/Shuffle';
// export const ShuffleIconSvg = ShuffleIcon;

// import ShuffleDisabledIcon from 'react-material-icon-svg/dist/ShuffleDisabled';
// export const ShuffleDisabledIconSvg = ShuffleDisabledIcon;

// import ShuffleVariantIcon from 'react-material-icon-svg/dist/ShuffleVariant';
// export const ShuffleVariantIconSvg = ShuffleVariantIcon;

// import SigmaIcon from 'react-material-icon-svg/dist/Sigma';
// export const SigmaIconSvg = SigmaIcon;

// import SigmaLowerIcon from 'react-material-icon-svg/dist/SigmaLower';
// export const SigmaLowerIconSvg = SigmaLowerIcon;

// import SignCautionIcon from 'react-material-icon-svg/dist/SignCaution';
// export const SignCautionIconSvg = SignCautionIcon;

// import SignDirectionIcon from 'react-material-icon-svg/dist/SignDirection';
// export const SignDirectionIconSvg = SignDirectionIcon;

// import SignTextIcon from 'react-material-icon-svg/dist/SignText';
// export const SignTextIconSvg = SignTextIcon;

// import SignalIcon from 'react-material-icon-svg/dist/Signal';
// export const SignalIconSvg = SignalIcon;

// import SignalCellularOutlineIcon from 'react-material-icon-svg/dist/SignalCellularOutline';
// export const SignalCellularOutlineIconSvg = SignalCellularOutlineIcon;

// import SignalCellular_1Icon from 'react-material-icon-svg/dist/SignalCellular_1';
// export const SignalCellular_1IconSvg = SignalCellular_1Icon;

// import SignalCellular_2Icon from 'react-material-icon-svg/dist/SignalCellular_2';
// export const SignalCellular_2IconSvg = SignalCellular_2Icon;

// import SignalCellular_3Icon from 'react-material-icon-svg/dist/SignalCellular_3';
// export const SignalCellular_3IconSvg = SignalCellular_3Icon;

// import SignalDistanceVariantIcon from 'react-material-icon-svg/dist/SignalDistanceVariant';
// export const SignalDistanceVariantIconSvg = SignalDistanceVariantIcon;

// import SignalHspaIcon from 'react-material-icon-svg/dist/SignalHspa';
// export const SignalHspaIconSvg = SignalHspaIcon;

// import SignalHspaPlusIcon from 'react-material-icon-svg/dist/SignalHspaPlus';
// export const SignalHspaPlusIconSvg = SignalHspaPlusIcon;

// import SignalOffIcon from 'react-material-icon-svg/dist/SignalOff';
// export const SignalOffIconSvg = SignalOffIcon;

// import SignalVariantIcon from 'react-material-icon-svg/dist/SignalVariant';
// export const SignalVariantIconSvg = SignalVariantIcon;

// import Signal_2gIcon from 'react-material-icon-svg/dist/Signal_2g';
// export const Signal_2gIconSvg = Signal_2gIcon;

// import Signal_3gIcon from 'react-material-icon-svg/dist/Signal_3g';
// export const Signal_3gIconSvg = Signal_3gIcon;

// import Signal_4gIcon from 'react-material-icon-svg/dist/Signal_4g';
// export const Signal_4gIconSvg = Signal_4gIcon;

// import Signal_5gIcon from 'react-material-icon-svg/dist/Signal_5g';
// export const Signal_5gIconSvg = Signal_5gIcon;

// import SignatureIcon from 'react-material-icon-svg/dist/Signature';
// export const SignatureIconSvg = SignatureIcon;

// import SignatureFreehandIcon from 'react-material-icon-svg/dist/SignatureFreehand';
// export const SignatureFreehandIconSvg = SignatureFreehandIcon;

// import SignatureImageIcon from 'react-material-icon-svg/dist/SignatureImage';
// export const SignatureImageIconSvg = SignatureImageIcon;

// import SignatureTextIcon from 'react-material-icon-svg/dist/SignatureText';
// export const SignatureTextIconSvg = SignatureTextIcon;

// import SiloIcon from 'react-material-icon-svg/dist/Silo';
// export const SiloIconSvg = SiloIcon;

// import SilverwareIcon from 'react-material-icon-svg/dist/Silverware';
// export const SilverwareIconSvg = SilverwareIcon;

// import SilverwareForkIcon from 'react-material-icon-svg/dist/SilverwareFork';
// export const SilverwareForkIconSvg = SilverwareForkIcon;

// import SilverwareForkKnifeIcon from 'react-material-icon-svg/dist/SilverwareForkKnife';
// export const SilverwareForkKnifeIconSvg = SilverwareForkKnifeIcon;

// import SilverwareSpoonIcon from 'react-material-icon-svg/dist/SilverwareSpoon';
// export const SilverwareSpoonIconSvg = SilverwareSpoonIcon;

// import SilverwareVariantIcon from 'react-material-icon-svg/dist/SilverwareVariant';
// export const SilverwareVariantIconSvg = SilverwareVariantIcon;

// import SimIcon from 'react-material-icon-svg/dist/Sim';
// export const SimIconSvg = SimIcon;

// import SimAlertIcon from 'react-material-icon-svg/dist/SimAlert';
// export const SimAlertIconSvg = SimAlertIcon;

// import SimOffIcon from 'react-material-icon-svg/dist/SimOff';
// export const SimOffIconSvg = SimOffIcon;

// import SinaWeiboIcon from 'react-material-icon-svg/dist/SinaWeibo';
// export const SinaWeiboIconSvg = SinaWeiboIcon;

// import SitemapIcon from 'react-material-icon-svg/dist/Sitemap';
// export const SitemapIconSvg = SitemapIcon;

// import SkateIcon from 'react-material-icon-svg/dist/Skate';
// export const SkateIconSvg = SkateIcon;

// import SkewLessIcon from 'react-material-icon-svg/dist/SkewLess';
// export const SkewLessIconSvg = SkewLessIcon;

// import SkewMoreIcon from 'react-material-icon-svg/dist/SkewMore';
// export const SkewMoreIconSvg = SkewMoreIcon;

// import SkipBackwardIcon from 'react-material-icon-svg/dist/SkipBackward';
// export const SkipBackwardIconSvg = SkipBackwardIcon;

// import SkipForwardIcon from 'react-material-icon-svg/dist/SkipForward';
// export const SkipForwardIconSvg = SkipForwardIcon;

// import SkipNextIcon from 'react-material-icon-svg/dist/SkipNext';
// export const SkipNextIconSvg = SkipNextIcon;

// import SkipNextCircleIcon from 'react-material-icon-svg/dist/SkipNextCircle';
// export const SkipNextCircleIconSvg = SkipNextCircleIcon;

// import SkipNextCircleOutlineIcon from 'react-material-icon-svg/dist/SkipNextCircleOutline';
// export const SkipNextCircleOutlineIconSvg = SkipNextCircleOutlineIcon;

// import SkipPreviousIcon from 'react-material-icon-svg/dist/SkipPrevious';
// export const SkipPreviousIconSvg = SkipPreviousIcon;

// import SkipPreviousCircleIcon from 'react-material-icon-svg/dist/SkipPreviousCircle';
// export const SkipPreviousCircleIconSvg = SkipPreviousCircleIcon;

// import SkipPreviousCircleOutlineIcon from 'react-material-icon-svg/dist/SkipPreviousCircleOutline';
// export const SkipPreviousCircleOutlineIconSvg = SkipPreviousCircleOutlineIcon;

// import SkullIcon from 'react-material-icon-svg/dist/Skull';
// export const SkullIconSvg = SkullIcon;

// import SkullCrossbonesIcon from 'react-material-icon-svg/dist/SkullCrossbones';
// export const SkullCrossbonesIconSvg = SkullCrossbonesIcon;

// import SkullCrossbonesOutlineIcon from 'react-material-icon-svg/dist/SkullCrossbonesOutline';
// export const SkullCrossbonesOutlineIconSvg = SkullCrossbonesOutlineIcon;

// import SkullOutlineIcon from 'react-material-icon-svg/dist/SkullOutline';
// export const SkullOutlineIconSvg = SkullOutlineIcon;

// import SkypeIcon from 'react-material-icon-svg/dist/Skype';
// export const SkypeIconSvg = SkypeIcon;

// import SkypeBusinessIcon from 'react-material-icon-svg/dist/SkypeBusiness';
// export const SkypeBusinessIconSvg = SkypeBusinessIcon;

// import SlackIcon from 'react-material-icon-svg/dist/Slack';
// export const SlackIconSvg = SlackIcon;

// import SlackwareIcon from 'react-material-icon-svg/dist/Slackware';
// export const SlackwareIconSvg = SlackwareIcon;

// import SleepIcon from 'react-material-icon-svg/dist/Sleep';
// export const SleepIconSvg = SleepIcon;

// import SleepOffIcon from 'react-material-icon-svg/dist/SleepOff';
// export const SleepOffIconSvg = SleepOffIcon;

// import SlopeDownhillIcon from 'react-material-icon-svg/dist/SlopeDownhill';
// export const SlopeDownhillIconSvg = SlopeDownhillIcon;

// import SlopeUphillIcon from 'react-material-icon-svg/dist/SlopeUphill';
// export const SlopeUphillIconSvg = SlopeUphillIcon;

// import SmogIcon from 'react-material-icon-svg/dist/Smog';
// export const SmogIconSvg = SmogIcon;

// import SmokeDetectorIcon from 'react-material-icon-svg/dist/SmokeDetector';
// export const SmokeDetectorIconSvg = SmokeDetectorIcon;

// import SmokingIcon from 'react-material-icon-svg/dist/Smoking';
// export const SmokingIconSvg = SmokingIcon;

// import SmokingOffIcon from 'react-material-icon-svg/dist/SmokingOff';
// export const SmokingOffIconSvg = SmokingOffIcon;

// import SnapchatIcon from 'react-material-icon-svg/dist/Snapchat';
// export const SnapchatIconSvg = SnapchatIcon;

// import SnowflakeIcon from 'react-material-icon-svg/dist/Snowflake';
// export const SnowflakeIconSvg = SnowflakeIcon;

// import SnowmanIcon from 'react-material-icon-svg/dist/Snowman';
// export const SnowmanIconSvg = SnowmanIcon;

// import SoccerIcon from 'react-material-icon-svg/dist/Soccer';
// export const SoccerIconSvg = SoccerIcon;

// import SoccerFieldIcon from 'react-material-icon-svg/dist/SoccerField';
// export const SoccerFieldIconSvg = SoccerFieldIcon;

// import SofaIcon from 'react-material-icon-svg/dist/Sofa';
// export const SofaIconSvg = SofaIcon;

// import SolarPanelIcon from 'react-material-icon-svg/dist/SolarPanel';
// export const SolarPanelIconSvg = SolarPanelIcon;

// import SolarPanelLargeIcon from 'react-material-icon-svg/dist/SolarPanelLarge';
// export const SolarPanelLargeIconSvg = SolarPanelLargeIcon;

// import SolarPowerIcon from 'react-material-icon-svg/dist/SolarPower';
// export const SolarPowerIconSvg = SolarPowerIcon;

// import SolidIcon from 'react-material-icon-svg/dist/Solid';
// export const SolidIconSvg = SolidIcon;

import SortIcon from 'react-material-icon-svg/dist/Sort';
export const SortIconSvg = SortIcon;

// import SortAlphabeticalIcon from 'react-material-icon-svg/dist/SortAlphabetical';
// export const SortAlphabeticalIconSvg = SortAlphabeticalIcon;

// import SortAscendingIcon from 'react-material-icon-svg/dist/SortAscending';
// export const SortAscendingIconSvg = SortAscendingIcon;

// import SortDescendingIcon from 'react-material-icon-svg/dist/SortDescending';
// export const SortDescendingIconSvg = SortDescendingIcon;

// import SortNumericIcon from 'react-material-icon-svg/dist/SortNumeric';
// export const SortNumericIconSvg = SortNumericIcon;

// import SortVariantIcon from 'react-material-icon-svg/dist/SortVariant';
// export const SortVariantIconSvg = SortVariantIcon;

// import SortVariantLockIcon from 'react-material-icon-svg/dist/SortVariantLock';
// export const SortVariantLockIconSvg = SortVariantLockIcon;

// import SortVariantLockOpenIcon from 'react-material-icon-svg/dist/SortVariantLockOpen';
// export const SortVariantLockOpenIconSvg = SortVariantLockOpenIcon;

// import SoundcloudIcon from 'react-material-icon-svg/dist/Soundcloud';
// export const SoundcloudIconSvg = SoundcloudIcon;

// import SourceBranchIcon from 'react-material-icon-svg/dist/SourceBranch';
// export const SourceBranchIconSvg = SourceBranchIcon;

// import SourceCommitIcon from 'react-material-icon-svg/dist/SourceCommit';
// export const SourceCommitIconSvg = SourceCommitIcon;

// import SourceCommitEndIcon from 'react-material-icon-svg/dist/SourceCommitEnd';
// export const SourceCommitEndIconSvg = SourceCommitEndIcon;

// import SourceCommitEndLocalIcon from 'react-material-icon-svg/dist/SourceCommitEndLocal';
// export const SourceCommitEndLocalIconSvg = SourceCommitEndLocalIcon;

// import SourceCommitLocalIcon from 'react-material-icon-svg/dist/SourceCommitLocal';
// export const SourceCommitLocalIconSvg = SourceCommitLocalIcon;

// import SourceCommitNextLocalIcon from 'react-material-icon-svg/dist/SourceCommitNextLocal';
// export const SourceCommitNextLocalIconSvg = SourceCommitNextLocalIcon;

// import SourceCommitStartIcon from 'react-material-icon-svg/dist/SourceCommitStart';
// export const SourceCommitStartIconSvg = SourceCommitStartIcon;

// import SourceCommitStartNextLocalIcon from 'react-material-icon-svg/dist/SourceCommitStartNextLocal';
// export const SourceCommitStartNextLocalIconSvg = SourceCommitStartNextLocalIcon;

// import SourceForkIcon from 'react-material-icon-svg/dist/SourceFork';
// export const SourceForkIconSvg = SourceForkIcon;

// import SourceMergeIcon from 'react-material-icon-svg/dist/SourceMerge';
// export const SourceMergeIconSvg = SourceMergeIcon;

// import SourcePullIcon from 'react-material-icon-svg/dist/SourcePull';
// export const SourcePullIconSvg = SourcePullIcon;

// import SourceRepositoryIcon from 'react-material-icon-svg/dist/SourceRepository';
// export const SourceRepositoryIconSvg = SourceRepositoryIcon;

// import SourceRepositoryMultipleIcon from 'react-material-icon-svg/dist/SourceRepositoryMultiple';
// export const SourceRepositoryMultipleIconSvg = SourceRepositoryMultipleIcon;

// import SoySauceIcon from 'react-material-icon-svg/dist/SoySauce';
// export const SoySauceIconSvg = SoySauceIcon;

// import SpaIcon from 'react-material-icon-svg/dist/Spa';
// export const SpaIconSvg = SpaIcon;

// import SpaOutlineIcon from 'react-material-icon-svg/dist/SpaOutline';
// export const SpaOutlineIconSvg = SpaOutlineIcon;

// import SpaceInvadersIcon from 'react-material-icon-svg/dist/SpaceInvaders';
// export const SpaceInvadersIconSvg = SpaceInvadersIcon;

// import SpadeIcon from 'react-material-icon-svg/dist/Spade';
// export const SpadeIconSvg = SpadeIcon;

// import SpeakerIcon from 'react-material-icon-svg/dist/Speaker';
// export const SpeakerIconSvg = SpeakerIcon;

// import SpeakerBluetoothIcon from 'react-material-icon-svg/dist/SpeakerBluetooth';
// export const SpeakerBluetoothIconSvg = SpeakerBluetoothIcon;

// import SpeakerMultipleIcon from 'react-material-icon-svg/dist/SpeakerMultiple';
// export const SpeakerMultipleIconSvg = SpeakerMultipleIcon;

// import SpeakerOffIcon from 'react-material-icon-svg/dist/SpeakerOff';
// export const SpeakerOffIconSvg = SpeakerOffIcon;

// import SpeakerWirelessIcon from 'react-material-icon-svg/dist/SpeakerWireless';
// export const SpeakerWirelessIconSvg = SpeakerWirelessIcon;

// import SpeedometerIcon from 'react-material-icon-svg/dist/Speedometer';
// export const SpeedometerIconSvg = SpeedometerIcon;

// import SpellcheckIcon from 'react-material-icon-svg/dist/Spellcheck';
// export const SpellcheckIconSvg = SpellcheckIcon;

// import SpiderWebIcon from 'react-material-icon-svg/dist/SpiderWeb';
// export const SpiderWebIconSvg = SpiderWebIcon;

// import SpotifyIcon from 'react-material-icon-svg/dist/Spotify';
// export const SpotifyIconSvg = SpotifyIcon;

// import SpotlightIcon from 'react-material-icon-svg/dist/Spotlight';
// export const SpotlightIconSvg = SpotlightIcon;

// import SpotlightBeamIcon from 'react-material-icon-svg/dist/SpotlightBeam';
// export const SpotlightBeamIconSvg = SpotlightBeamIcon;

// import SprayIcon from 'react-material-icon-svg/dist/Spray';
// export const SprayIconSvg = SprayIcon;

// import SprayBottleIcon from 'react-material-icon-svg/dist/SprayBottle';
// export const SprayBottleIconSvg = SprayBottleIcon;

// import SproutIcon from 'react-material-icon-svg/dist/Sprout';
// export const SproutIconSvg = SproutIcon;

// import SproutOutlineIcon from 'react-material-icon-svg/dist/SproutOutline';
// export const SproutOutlineIconSvg = SproutOutlineIcon;

// import SquareIcon from 'react-material-icon-svg/dist/Square';
// export const SquareIconSvg = SquareIcon;

// import SquareEditOutlineIcon from 'react-material-icon-svg/dist/SquareEditOutline';
// export const SquareEditOutlineIconSvg = SquareEditOutlineIcon;

// import SquareIncIcon from 'react-material-icon-svg/dist/SquareInc';
// export const SquareIncIconSvg = SquareIncIcon;

// import SquareIncCashIcon from 'react-material-icon-svg/dist/SquareIncCash';
// export const SquareIncCashIconSvg = SquareIncCashIcon;

// import SquareMediumIcon from 'react-material-icon-svg/dist/SquareMedium';
// export const SquareMediumIconSvg = SquareMediumIcon;

// import SquareMediumOutlineIcon from 'react-material-icon-svg/dist/SquareMediumOutline';
// export const SquareMediumOutlineIconSvg = SquareMediumOutlineIcon;

// import SquareOutlineIcon from 'react-material-icon-svg/dist/SquareOutline';
// export const SquareOutlineIconSvg = SquareOutlineIcon;

// import SquareRootIcon from 'react-material-icon-svg/dist/SquareRoot';
// export const SquareRootIconSvg = SquareRootIcon;

// import SquareRootBoxIcon from 'react-material-icon-svg/dist/SquareRootBox';
// export const SquareRootBoxIconSvg = SquareRootBoxIcon;

// import SquareSmallIcon from 'react-material-icon-svg/dist/SquareSmall';
// export const SquareSmallIconSvg = SquareSmallIcon;

// import SqueegeeIcon from 'react-material-icon-svg/dist/Squeegee';
// export const SqueegeeIconSvg = SqueegeeIcon;

// import SshIcon from 'react-material-icon-svg/dist/Ssh';
// export const SshIconSvg = SshIcon;

// import StackExchangeIcon from 'react-material-icon-svg/dist/StackExchange';
// export const StackExchangeIconSvg = StackExchangeIcon;

// import StackOverflowIcon from 'react-material-icon-svg/dist/StackOverflow';
// export const StackOverflowIconSvg = StackOverflowIcon;

// import StadiumIcon from 'react-material-icon-svg/dist/Stadium';
// export const StadiumIconSvg = StadiumIcon;

// import StairsIcon from 'react-material-icon-svg/dist/Stairs';
// export const StairsIconSvg = StairsIcon;

// import StamperIcon from 'react-material-icon-svg/dist/Stamper';
// export const StamperIconSvg = StamperIcon;

// import StandardDefinitionIcon from 'react-material-icon-svg/dist/StandardDefinition';
// export const StandardDefinitionIconSvg = StandardDefinitionIcon;

// import StarIcon from 'react-material-icon-svg/dist/Star';
// export const StarIconSvg = StarIcon;

// import StarBoxIcon from 'react-material-icon-svg/dist/StarBox';
// export const StarBoxIconSvg = StarBoxIcon;

// import StarBoxOutlineIcon from 'react-material-icon-svg/dist/StarBoxOutline';
// export const StarBoxOutlineIconSvg = StarBoxOutlineIcon;

// import StarCircleIcon from 'react-material-icon-svg/dist/StarCircle';
// export const StarCircleIconSvg = StarCircleIcon;

// import StarCircleOutlineIcon from 'react-material-icon-svg/dist/StarCircleOutline';
// export const StarCircleOutlineIconSvg = StarCircleOutlineIcon;

// import StarFaceIcon from 'react-material-icon-svg/dist/StarFace';
// export const StarFaceIconSvg = StarFaceIcon;

// import StarFourPointsIcon from 'react-material-icon-svg/dist/StarFourPoints';
// export const StarFourPointsIconSvg = StarFourPointsIcon;

// import StarFourPointsOutlineIcon from 'react-material-icon-svg/dist/StarFourPointsOutline';
// export const StarFourPointsOutlineIconSvg = StarFourPointsOutlineIcon;

// import StarHalfIcon from 'react-material-icon-svg/dist/StarHalf';
// export const StarHalfIconSvg = StarHalfIcon;

// import StarOffIcon from 'react-material-icon-svg/dist/StarOff';
// export const StarOffIconSvg = StarOffIcon;

// import StarOutlineIcon from 'react-material-icon-svg/dist/StarOutline';
// export const StarOutlineIconSvg = StarOutlineIcon;

// import StarThreePointsIcon from 'react-material-icon-svg/dist/StarThreePoints';
// export const StarThreePointsIconSvg = StarThreePointsIcon;

// import StarThreePointsOutlineIcon from 'react-material-icon-svg/dist/StarThreePointsOutline';
// export const StarThreePointsOutlineIconSvg = StarThreePointsOutlineIcon;

// import SteamIcon from 'react-material-icon-svg/dist/Steam';
// export const SteamIconSvg = SteamIcon;

// import SteamBoxIcon from 'react-material-icon-svg/dist/SteamBox';
// export const SteamBoxIconSvg = SteamBoxIcon;

// import SteeringIcon from 'react-material-icon-svg/dist/Steering';
// export const SteeringIconSvg = SteeringIcon;

// import SteeringOffIcon from 'react-material-icon-svg/dist/SteeringOff';
// export const SteeringOffIconSvg = SteeringOffIcon;

// import StepBackwardIcon from 'react-material-icon-svg/dist/StepBackward';
// export const StepBackwardIconSvg = StepBackwardIcon;

// import StepBackward_2Icon from 'react-material-icon-svg/dist/StepBackward_2';
// export const StepBackward_2IconSvg = StepBackward_2Icon;

// import StepForwardIcon from 'react-material-icon-svg/dist/StepForward';
// export const StepForwardIconSvg = StepForwardIcon;

// import StepForward_2Icon from 'react-material-icon-svg/dist/StepForward_2';
// export const StepForward_2IconSvg = StepForward_2Icon;

// import StethoscopeIcon from 'react-material-icon-svg/dist/Stethoscope';
// export const StethoscopeIconSvg = StethoscopeIcon;

// import StickerIcon from 'react-material-icon-svg/dist/Sticker';
// export const StickerIconSvg = StickerIcon;

// import StickerEmojiIcon from 'react-material-icon-svg/dist/StickerEmoji';
// export const StickerEmojiIconSvg = StickerEmojiIcon;

// import StockingIcon from 'react-material-icon-svg/dist/Stocking';
// export const StockingIconSvg = StockingIcon;

// import StopIcon from 'react-material-icon-svg/dist/Stop';
// export const StopIconSvg = StopIcon;

// import StopCircleIcon from 'react-material-icon-svg/dist/StopCircle';
// export const StopCircleIconSvg = StopCircleIcon;

// import StopCircleOutlineIcon from 'react-material-icon-svg/dist/StopCircleOutline';
// export const StopCircleOutlineIconSvg = StopCircleOutlineIcon;

// import StoreIcon from 'react-material-icon-svg/dist/Store';
// export const StoreIconSvg = StoreIcon;

// import Store_24HourIcon from 'react-material-icon-svg/dist/Store_24Hour';
// export const Store_24HourIconSvg = Store_24HourIcon;

// import StoveIcon from 'react-material-icon-svg/dist/Stove';
// export const StoveIconSvg = StoveIcon;

// import StravaIcon from 'react-material-icon-svg/dist/Strava';
// export const StravaIconSvg = StravaIcon;

// import SubdirectoryArrowLeftIcon from 'react-material-icon-svg/dist/SubdirectoryArrowLeft';
// export const SubdirectoryArrowLeftIconSvg = SubdirectoryArrowLeftIcon;

// import SubdirectoryArrowRightIcon from 'react-material-icon-svg/dist/SubdirectoryArrowRight';
// export const SubdirectoryArrowRightIconSvg = SubdirectoryArrowRightIcon;

// import SubtitlesIcon from 'react-material-icon-svg/dist/Subtitles';
// export const SubtitlesIconSvg = SubtitlesIcon;

// import SubtitlesOutlineIcon from 'react-material-icon-svg/dist/SubtitlesOutline';
// export const SubtitlesOutlineIconSvg = SubtitlesOutlineIcon;

// import SubwayIcon from 'react-material-icon-svg/dist/Subway';
// export const SubwayIconSvg = SubwayIcon;

// import SubwayAlertVariantIcon from 'react-material-icon-svg/dist/SubwayAlertVariant';
// export const SubwayAlertVariantIconSvg = SubwayAlertVariantIcon;

// import SubwayVariantIcon from 'react-material-icon-svg/dist/SubwayVariant';
// export const SubwayVariantIconSvg = SubwayVariantIcon;

// import SummitIcon from 'react-material-icon-svg/dist/Summit';
// export const SummitIconSvg = SummitIcon;

// import SunglassesIcon from 'react-material-icon-svg/dist/Sunglasses';
// export const SunglassesIconSvg = SunglassesIcon;

// import SurroundSoundIcon from 'react-material-icon-svg/dist/SurroundSound';
// export const SurroundSoundIconSvg = SurroundSoundIcon;

// import SurroundSound_2_0Icon from 'react-material-icon-svg/dist/SurroundSound_2_0';
// export const SurroundSound_2_0IconSvg = SurroundSound_2_0Icon;

// import SurroundSound_3_1Icon from 'react-material-icon-svg/dist/SurroundSound_3_1';
// export const SurroundSound_3_1IconSvg = SurroundSound_3_1Icon;

// import SurroundSound_5_1Icon from 'react-material-icon-svg/dist/SurroundSound_5_1';
// export const SurroundSound_5_1IconSvg = SurroundSound_5_1Icon;

// import SurroundSound_7_1Icon from 'react-material-icon-svg/dist/SurroundSound_7_1';
// export const SurroundSound_7_1IconSvg = SurroundSound_7_1Icon;

// import SvgIcon from 'react-material-icon-svg/dist/Svg';
// export const SvgIconSvg = SvgIcon;

// import SwapHorizontalIcon from 'react-material-icon-svg/dist/SwapHorizontal';
// export const SwapHorizontalIconSvg = SwapHorizontalIcon;

// import SwapHorizontalBoldIcon from 'react-material-icon-svg/dist/SwapHorizontalBold';
// export const SwapHorizontalBoldIconSvg = SwapHorizontalBoldIcon;

// import SwapHorizontalVariantIcon from 'react-material-icon-svg/dist/SwapHorizontalVariant';
// export const SwapHorizontalVariantIconSvg = SwapHorizontalVariantIcon;

// import SwapVerticalIcon from 'react-material-icon-svg/dist/SwapVertical';
// export const SwapVerticalIconSvg = SwapVerticalIcon;

// import SwapVerticalBoldIcon from 'react-material-icon-svg/dist/SwapVerticalBold';
// export const SwapVerticalBoldIconSvg = SwapVerticalBoldIcon;

// import SwapVerticalVariantIcon from 'react-material-icon-svg/dist/SwapVerticalVariant';
// export const SwapVerticalVariantIconSvg = SwapVerticalVariantIcon;

// import SwimIcon from 'react-material-icon-svg/dist/Swim';
// export const SwimIconSvg = SwimIcon;

// import SwitchIcon from 'react-material-icon-svg/dist/Switch';
// export const SwitchIconSvg = SwitchIcon;

// import SwordIcon from 'react-material-icon-svg/dist/Sword';
// export const SwordIconSvg = SwordIcon;

// import SwordCrossIcon from 'react-material-icon-svg/dist/SwordCross';
// export const SwordCrossIconSvg = SwordCrossIcon;

// import SymfonyIcon from 'react-material-icon-svg/dist/Symfony';
// export const SymfonyIconSvg = SymfonyIcon;

// import SyncIcon from 'react-material-icon-svg/dist/Sync';
// export const SyncIconSvg = SyncIcon;

// import SyncAlertIcon from 'react-material-icon-svg/dist/SyncAlert';
// export const SyncAlertIconSvg = SyncAlertIcon;

// import SyncOffIcon from 'react-material-icon-svg/dist/SyncOff';
// export const SyncOffIconSvg = SyncOffIcon;

// import TabIcon from 'react-material-icon-svg/dist/Tab';
// export const TabIconSvg = TabIcon;

// import TabMinusIcon from 'react-material-icon-svg/dist/TabMinus';
// export const TabMinusIconSvg = TabMinusIcon;

// import TabPlusIcon from 'react-material-icon-svg/dist/TabPlus';
// export const TabPlusIconSvg = TabPlusIcon;

// import TabRemoveIcon from 'react-material-icon-svg/dist/TabRemove';
// export const TabRemoveIconSvg = TabRemoveIcon;

// import TabUnselectedIcon from 'react-material-icon-svg/dist/TabUnselected';
// export const TabUnselectedIconSvg = TabUnselectedIcon;

// import TableIcon from 'react-material-icon-svg/dist/Table';
// export const TableIconSvg = TableIcon;

// import TableBorderIcon from 'react-material-icon-svg/dist/TableBorder';
// export const TableBorderIconSvg = TableBorderIcon;

// import TableColumnIcon from 'react-material-icon-svg/dist/TableColumn';
// export const TableColumnIconSvg = TableColumnIcon;

// import TableColumnPlusAfterIcon from 'react-material-icon-svg/dist/TableColumnPlusAfter';
// export const TableColumnPlusAfterIconSvg = TableColumnPlusAfterIcon;

// import TableColumnPlusBeforeIcon from 'react-material-icon-svg/dist/TableColumnPlusBefore';
// export const TableColumnPlusBeforeIconSvg = TableColumnPlusBeforeIcon;

// import TableColumnRemoveIcon from 'react-material-icon-svg/dist/TableColumnRemove';
// export const TableColumnRemoveIconSvg = TableColumnRemoveIcon;

// import TableColumnWidthIcon from 'react-material-icon-svg/dist/TableColumnWidth';
// export const TableColumnWidthIconSvg = TableColumnWidthIcon;

// import TableEditIcon from 'react-material-icon-svg/dist/TableEdit';
// export const TableEditIconSvg = TableEditIcon;

// import TableLargeIcon from 'react-material-icon-svg/dist/TableLarge';
// export const TableLargeIconSvg = TableLargeIcon;

// import TableMergeCellsIcon from 'react-material-icon-svg/dist/TableMergeCells';
// export const TableMergeCellsIconSvg = TableMergeCellsIcon;

// import TableOfContentsIcon from 'react-material-icon-svg/dist/TableOfContents';
// export const TableOfContentsIconSvg = TableOfContentsIcon;

// import TablePlusIcon from 'react-material-icon-svg/dist/TablePlus';
// export const TablePlusIconSvg = TablePlusIcon;

// import TableRemoveIcon from 'react-material-icon-svg/dist/TableRemove';
// export const TableRemoveIconSvg = TableRemoveIcon;

// import TableRowIcon from 'react-material-icon-svg/dist/TableRow';
// export const TableRowIconSvg = TableRowIcon;

// import TableRowHeightIcon from 'react-material-icon-svg/dist/TableRowHeight';
// export const TableRowHeightIconSvg = TableRowHeightIcon;

// import TableRowPlusAfterIcon from 'react-material-icon-svg/dist/TableRowPlusAfter';
// export const TableRowPlusAfterIconSvg = TableRowPlusAfterIcon;

// import TableRowPlusBeforeIcon from 'react-material-icon-svg/dist/TableRowPlusBefore';
// export const TableRowPlusBeforeIconSvg = TableRowPlusBeforeIcon;

// import TableRowRemoveIcon from 'react-material-icon-svg/dist/TableRowRemove';
// export const TableRowRemoveIconSvg = TableRowRemoveIcon;

// import TableSearchIcon from 'react-material-icon-svg/dist/TableSearch';
// export const TableSearchIconSvg = TableSearchIcon;

// import TableSettingsIcon from 'react-material-icon-svg/dist/TableSettings';
// export const TableSettingsIconSvg = TableSettingsIcon;

// import TableTennisIcon from 'react-material-icon-svg/dist/TableTennis';
// export const TableTennisIconSvg = TableTennisIcon;

// import TabletIcon from 'react-material-icon-svg/dist/Tablet';
// export const TabletIconSvg = TabletIcon;

// import TabletAndroidIcon from 'react-material-icon-svg/dist/TabletAndroid';
// export const TabletAndroidIconSvg = TabletAndroidIcon;

// import TabletCellphoneIcon from 'react-material-icon-svg/dist/TabletCellphone';
// export const TabletCellphoneIconSvg = TabletCellphoneIcon;

// import TabletDashboardIcon from 'react-material-icon-svg/dist/TabletDashboard';
// export const TabletDashboardIconSvg = TabletDashboardIcon;

// import TabletIpadIcon from 'react-material-icon-svg/dist/TabletIpad';
// export const TabletIpadIconSvg = TabletIpadIcon;

// import TacoIcon from 'react-material-icon-svg/dist/Taco';
// export const TacoIconSvg = TacoIcon;

// import TagIcon from 'react-material-icon-svg/dist/Tag';
// export const TagIconSvg = TagIcon;

// import TagFacesIcon from 'react-material-icon-svg/dist/TagFaces';
// export const TagFacesIconSvg = TagFacesIcon;

// import TagHeartIcon from 'react-material-icon-svg/dist/TagHeart';
// export const TagHeartIconSvg = TagHeartIcon;

// import TagHeartOutlineIcon from 'react-material-icon-svg/dist/TagHeartOutline';
// export const TagHeartOutlineIconSvg = TagHeartOutlineIcon;

// import TagMinusIcon from 'react-material-icon-svg/dist/TagMinus';
// export const TagMinusIconSvg = TagMinusIcon;

// import TagMultipleIcon from 'react-material-icon-svg/dist/TagMultiple';
// export const TagMultipleIconSvg = TagMultipleIcon;

// import TagOutlineIcon from 'react-material-icon-svg/dist/TagOutline';
// export const TagOutlineIconSvg = TagOutlineIcon;

// import TagPlusIcon from 'react-material-icon-svg/dist/TagPlus';
// export const TagPlusIconSvg = TagPlusIcon;

// import TagRemoveIcon from 'react-material-icon-svg/dist/TagRemove';
// export const TagRemoveIconSvg = TagRemoveIcon;

// import TagTextOutlineIcon from 'react-material-icon-svg/dist/TagTextOutline';
// export const TagTextOutlineIconSvg = TagTextOutlineIcon;

// import TankIcon from 'react-material-icon-svg/dist/Tank';
// export const TankIconSvg = TankIcon;

// import TapeMeasureIcon from 'react-material-icon-svg/dist/TapeMeasure';
// export const TapeMeasureIconSvg = TapeMeasureIcon;

// import TargetIcon from 'react-material-icon-svg/dist/Target';
// export const TargetIconSvg = TargetIcon;

// import TargetAccountIcon from 'react-material-icon-svg/dist/TargetAccount';
// export const TargetAccountIconSvg = TargetAccountIcon;

// import TargetVariantIcon from 'react-material-icon-svg/dist/TargetVariant';
// export const TargetVariantIconSvg = TargetVariantIcon;

// import TaxiIcon from 'react-material-icon-svg/dist/Taxi';
// export const TaxiIconSvg = TaxiIcon;

// import TeaIcon from 'react-material-icon-svg/dist/Tea';
// export const TeaIconSvg = TeaIcon;

// import TeaOutlineIcon from 'react-material-icon-svg/dist/TeaOutline';
// export const TeaOutlineIconSvg = TeaOutlineIcon;

// import TeachIcon from 'react-material-icon-svg/dist/Teach';
// export const TeachIconSvg = TeachIcon;

// import TeamviewerIcon from 'react-material-icon-svg/dist/Teamviewer';
// export const TeamviewerIconSvg = TeamviewerIcon;

// import TelegramIcon from 'react-material-icon-svg/dist/Telegram';
// export const TelegramIconSvg = TelegramIcon;

// import TelescopeIcon from 'react-material-icon-svg/dist/Telescope';
// export const TelescopeIconSvg = TelescopeIcon;

// import TelevisionIcon from 'react-material-icon-svg/dist/Television';
// export const TelevisionIconSvg = TelevisionIcon;

// import TelevisionBoxIcon from 'react-material-icon-svg/dist/TelevisionBox';
// export const TelevisionBoxIconSvg = TelevisionBoxIcon;

// import TelevisionClassicIcon from 'react-material-icon-svg/dist/TelevisionClassic';
// export const TelevisionClassicIconSvg = TelevisionClassicIcon;

// import TelevisionClassicOffIcon from 'react-material-icon-svg/dist/TelevisionClassicOff';
// export const TelevisionClassicOffIconSvg = TelevisionClassicOffIcon;

// import TelevisionGuideIcon from 'react-material-icon-svg/dist/TelevisionGuide';
// export const TelevisionGuideIconSvg = TelevisionGuideIcon;

// import TelevisionOffIcon from 'react-material-icon-svg/dist/TelevisionOff';
// export const TelevisionOffIconSvg = TelevisionOffIcon;

// import TelevisionPlayIcon from 'react-material-icon-svg/dist/TelevisionPlay';
// export const TelevisionPlayIconSvg = TelevisionPlayIcon;

// import TemperatureCelsiusIcon from 'react-material-icon-svg/dist/TemperatureCelsius';
// export const TemperatureCelsiusIconSvg = TemperatureCelsiusIcon;

// import TemperatureFahrenheitIcon from 'react-material-icon-svg/dist/TemperatureFahrenheit';
// export const TemperatureFahrenheitIconSvg = TemperatureFahrenheitIcon;

// import TemperatureKelvinIcon from 'react-material-icon-svg/dist/TemperatureKelvin';
// export const TemperatureKelvinIconSvg = TemperatureKelvinIcon;

// import TennisIcon from 'react-material-icon-svg/dist/Tennis';
// export const TennisIconSvg = TennisIcon;

// import TennisBallIcon from 'react-material-icon-svg/dist/TennisBall';
// export const TennisBallIconSvg = TennisBallIcon;

// import TentIcon from 'react-material-icon-svg/dist/Tent';
// export const TentIconSvg = TentIcon;

// import TerrainIcon from 'react-material-icon-svg/dist/Terrain';
// export const TerrainIconSvg = TerrainIcon;

// import TestTubeIcon from 'react-material-icon-svg/dist/TestTube';
// export const TestTubeIconSvg = TestTubeIcon;

// import TestTubeEmptyIcon from 'react-material-icon-svg/dist/TestTubeEmpty';
// export const TestTubeEmptyIconSvg = TestTubeEmptyIcon;

// import TestTubeOffIcon from 'react-material-icon-svg/dist/TestTubeOff';
// export const TestTubeOffIconSvg = TestTubeOffIcon;

// import TextIcon from 'react-material-icon-svg/dist/Text';
// export const TextIconSvg = TextIcon;

// import TextShadowIcon from 'react-material-icon-svg/dist/TextShadow';
// export const TextShadowIconSvg = TextShadowIcon;

// import TextShortIcon from 'react-material-icon-svg/dist/TextShort';
// export const TextShortIconSvg = TextShortIcon;

// import TextSubjectIcon from 'react-material-icon-svg/dist/TextSubject';
// export const TextSubjectIconSvg = TextSubjectIcon;

// import TextToSpeechIcon from 'react-material-icon-svg/dist/TextToSpeech';
// export const TextToSpeechIconSvg = TextToSpeechIcon;

// import TextToSpeechOffIcon from 'react-material-icon-svg/dist/TextToSpeechOff';
// export const TextToSpeechOffIconSvg = TextToSpeechOffIcon;

// import TextboxIcon from 'react-material-icon-svg/dist/Textbox';
// export const TextboxIconSvg = TextboxIcon;

// import TextboxPasswordIcon from 'react-material-icon-svg/dist/TextboxPassword';
// export const TextboxPasswordIconSvg = TextboxPasswordIcon;

// import TextureIcon from 'react-material-icon-svg/dist/Texture';
// export const TextureIconSvg = TextureIcon;

// import TheaterIcon from 'react-material-icon-svg/dist/Theater';
// export const TheaterIconSvg = TheaterIcon;

// import ThemeLightDarkIcon from 'react-material-icon-svg/dist/ThemeLightDark';
// export const ThemeLightDarkIconSvg = ThemeLightDarkIcon;

// import ThermometerIcon from 'react-material-icon-svg/dist/Thermometer';
// export const ThermometerIconSvg = ThermometerIcon;

// import ThermometerAlertIcon from 'react-material-icon-svg/dist/ThermometerAlert';
// export const ThermometerAlertIconSvg = ThermometerAlertIcon;

// import ThermometerChevronDownIcon from 'react-material-icon-svg/dist/ThermometerChevronDown';
// export const ThermometerChevronDownIconSvg = ThermometerChevronDownIcon;

// import ThermometerChevronUpIcon from 'react-material-icon-svg/dist/ThermometerChevronUp';
// export const ThermometerChevronUpIconSvg = ThermometerChevronUpIcon;

// import ThermometerLinesIcon from 'react-material-icon-svg/dist/ThermometerLines';
// export const ThermometerLinesIconSvg = ThermometerLinesIcon;

// import ThermometerMinusIcon from 'react-material-icon-svg/dist/ThermometerMinus';
// export const ThermometerMinusIconSvg = ThermometerMinusIcon;

// import ThermometerPlusIcon from 'react-material-icon-svg/dist/ThermometerPlus';
// export const ThermometerPlusIconSvg = ThermometerPlusIcon;

// import ThermostatIcon from 'react-material-icon-svg/dist/Thermostat';
// export const ThermostatIconSvg = ThermostatIcon;

// import ThermostatBoxIcon from 'react-material-icon-svg/dist/ThermostatBox';
// export const ThermostatBoxIconSvg = ThermostatBoxIcon;

// import ThoughtBubbleIcon from 'react-material-icon-svg/dist/ThoughtBubble';
// export const ThoughtBubbleIconSvg = ThoughtBubbleIcon;

// import ThoughtBubbleOutlineIcon from 'react-material-icon-svg/dist/ThoughtBubbleOutline';
// export const ThoughtBubbleOutlineIconSvg = ThoughtBubbleOutlineIcon;

// import ThumbDownIcon from 'react-material-icon-svg/dist/ThumbDown';
// export const ThumbDownIconSvg = ThumbDownIcon;

// import ThumbDownOutlineIcon from 'react-material-icon-svg/dist/ThumbDownOutline';
// export const ThumbDownOutlineIconSvg = ThumbDownOutlineIcon;

// import ThumbUpIcon from 'react-material-icon-svg/dist/ThumbUp';
// export const ThumbUpIconSvg = ThumbUpIcon;

// import ThumbUpOutlineIcon from 'react-material-icon-svg/dist/ThumbUpOutline';
// export const ThumbUpOutlineIconSvg = ThumbUpOutlineIcon;

// import ThumbsUpDownIcon from 'react-material-icon-svg/dist/ThumbsUpDown';
// export const ThumbsUpDownIconSvg = ThumbsUpDownIcon;

// import TicketIcon from 'react-material-icon-svg/dist/Ticket';
// export const TicketIconSvg = TicketIcon;

// import TicketAccountIcon from 'react-material-icon-svg/dist/TicketAccount';
// export const TicketAccountIconSvg = TicketAccountIcon;

// import TicketConfirmationIcon from 'react-material-icon-svg/dist/TicketConfirmation';
// export const TicketConfirmationIconSvg = TicketConfirmationIcon;

// import TicketOutlineIcon from 'react-material-icon-svg/dist/TicketOutline';
// export const TicketOutlineIconSvg = TicketOutlineIcon;

// import TicketPercentIcon from 'react-material-icon-svg/dist/TicketPercent';
// export const TicketPercentIconSvg = TicketPercentIcon;

// import TieIcon from 'react-material-icon-svg/dist/Tie';
// export const TieIconSvg = TieIcon;

// import TildeIcon from 'react-material-icon-svg/dist/Tilde';
// export const TildeIconSvg = TildeIcon;

// import TimelapseIcon from 'react-material-icon-svg/dist/Timelapse';
// export const TimelapseIconSvg = TimelapseIcon;

// import TimelineIcon from 'react-material-icon-svg/dist/Timeline';
// export const TimelineIconSvg = TimelineIcon;

// import TimelineOutlineIcon from 'react-material-icon-svg/dist/TimelineOutline';
// export const TimelineOutlineIconSvg = TimelineOutlineIcon;

// import TimelineTextIcon from 'react-material-icon-svg/dist/TimelineText';
// export const TimelineTextIconSvg = TimelineTextIcon;

// import TimelineTextOutlineIcon from 'react-material-icon-svg/dist/TimelineTextOutline';
// export const TimelineTextOutlineIconSvg = TimelineTextOutlineIcon;

// import TimerIcon from 'react-material-icon-svg/dist/Timer';
// export const TimerIconSvg = TimerIcon;

// import TimerOffIcon from 'react-material-icon-svg/dist/TimerOff';
// export const TimerOffIconSvg = TimerOffIcon;

// import TimerSandIcon from 'react-material-icon-svg/dist/TimerSand';
// export const TimerSandIconSvg = TimerSandIcon;

// import TimerSandEmptyIcon from 'react-material-icon-svg/dist/TimerSandEmpty';
// export const TimerSandEmptyIconSvg = TimerSandEmptyIcon;

// import TimerSandFullIcon from 'react-material-icon-svg/dist/TimerSandFull';
// export const TimerSandFullIconSvg = TimerSandFullIcon;

// import Timer_10Icon from 'react-material-icon-svg/dist/Timer_10';
// export const Timer_10IconSvg = Timer_10Icon;

// import Timer_3Icon from 'react-material-icon-svg/dist/Timer_3';
// export const Timer_3IconSvg = Timer_3Icon;

// import TimetableIcon from 'react-material-icon-svg/dist/Timetable';
// export const TimetableIconSvg = TimetableIcon;

// import ToasterOvenIcon from 'react-material-icon-svg/dist/ToasterOven';
// export const ToasterOvenIconSvg = ToasterOvenIcon;

// import ToggleSwitchIcon from 'react-material-icon-svg/dist/ToggleSwitch';
// export const ToggleSwitchIconSvg = ToggleSwitchIcon;

// import ToggleSwitchOffIcon from 'react-material-icon-svg/dist/ToggleSwitchOff';
// export const ToggleSwitchOffIconSvg = ToggleSwitchOffIcon;

// import ToggleSwitchOffOutlineIcon from 'react-material-icon-svg/dist/ToggleSwitchOffOutline';
// export const ToggleSwitchOffOutlineIconSvg = ToggleSwitchOffOutlineIcon;

// import ToggleSwitchOutlineIcon from 'react-material-icon-svg/dist/ToggleSwitchOutline';
// export const ToggleSwitchOutlineIconSvg = ToggleSwitchOutlineIcon;

// import ToiletIcon from 'react-material-icon-svg/dist/Toilet';
// export const ToiletIconSvg = ToiletIcon;

// import ToolboxIcon from 'react-material-icon-svg/dist/Toolbox';
// export const ToolboxIconSvg = ToolboxIcon;

// import ToolboxOutlineIcon from 'react-material-icon-svg/dist/ToolboxOutline';
// export const ToolboxOutlineIconSvg = ToolboxOutlineIcon;

// import TooltipIcon from 'react-material-icon-svg/dist/Tooltip';
// export const TooltipIconSvg = TooltipIcon;

// import TooltipAccountIcon from 'react-material-icon-svg/dist/TooltipAccount';
// export const TooltipAccountIconSvg = TooltipAccountIcon;

// import TooltipEditIcon from 'react-material-icon-svg/dist/TooltipEdit';
// export const TooltipEditIconSvg = TooltipEditIcon;

// import TooltipImageIcon from 'react-material-icon-svg/dist/TooltipImage';
// export const TooltipImageIconSvg = TooltipImageIcon;

// import TooltipImageOutlineIcon from 'react-material-icon-svg/dist/TooltipImageOutline';
// export const TooltipImageOutlineIconSvg = TooltipImageOutlineIcon;

// import TooltipOutlineIcon from 'react-material-icon-svg/dist/TooltipOutline';
// export const TooltipOutlineIconSvg = TooltipOutlineIcon;

// import TooltipPlusIcon from 'react-material-icon-svg/dist/TooltipPlus';
// export const TooltipPlusIconSvg = TooltipPlusIcon;

// import TooltipPlusOutlineIcon from 'react-material-icon-svg/dist/TooltipPlusOutline';
// export const TooltipPlusOutlineIconSvg = TooltipPlusOutlineIcon;

// import TooltipTextIcon from 'react-material-icon-svg/dist/TooltipText';
// export const TooltipTextIconSvg = TooltipTextIcon;

// import TooltipTextOutlineIcon from 'react-material-icon-svg/dist/TooltipTextOutline';
// export const TooltipTextOutlineIconSvg = TooltipTextOutlineIcon;

// import ToothIcon from 'react-material-icon-svg/dist/Tooth';
// export const ToothIconSvg = ToothIcon;

// import ToothOutlineIcon from 'react-material-icon-svg/dist/ToothOutline';
// export const ToothOutlineIconSvg = ToothOutlineIcon;

// import TorIcon from 'react-material-icon-svg/dist/Tor';
// export const TorIconSvg = TorIcon;

// import TortoiseIcon from 'react-material-icon-svg/dist/Tortoise';
// export const TortoiseIconSvg = TortoiseIcon;

// import TournamentIcon from 'react-material-icon-svg/dist/Tournament';
// export const TournamentIconSvg = TournamentIcon;

// import TowerBeachIcon from 'react-material-icon-svg/dist/TowerBeach';
// export const TowerBeachIconSvg = TowerBeachIcon;

// import TowerFireIcon from 'react-material-icon-svg/dist/TowerFire';
// export const TowerFireIconSvg = TowerFireIcon;

// import TowingIcon from 'react-material-icon-svg/dist/Towing';
// export const TowingIconSvg = TowingIcon;

// import TrackLightIcon from 'react-material-icon-svg/dist/TrackLight';
// export const TrackLightIconSvg = TrackLightIcon;

// import TrackpadIcon from 'react-material-icon-svg/dist/Trackpad';
// export const TrackpadIconSvg = TrackpadIcon;

// import TrackpadLockIcon from 'react-material-icon-svg/dist/TrackpadLock';
// export const TrackpadLockIconSvg = TrackpadLockIcon;

// import TractorIcon from 'react-material-icon-svg/dist/Tractor';
// export const TractorIconSvg = TractorIcon;

// import TrademarkIcon from 'react-material-icon-svg/dist/Trademark';
// export const TrademarkIconSvg = TrademarkIcon;

// import TrafficLightIcon from 'react-material-icon-svg/dist/TrafficLight';
// export const TrafficLightIconSvg = TrafficLightIcon;

// import TrainIcon from 'react-material-icon-svg/dist/Train';
// export const TrainIconSvg = TrainIcon;

// import TrainCarIcon from 'react-material-icon-svg/dist/TrainCar';
// export const TrainCarIconSvg = TrainCarIcon;

// import TrainVariantIcon from 'react-material-icon-svg/dist/TrainVariant';
// export const TrainVariantIconSvg = TrainVariantIcon;

// import TramIcon from 'react-material-icon-svg/dist/Tram';
// export const TramIconSvg = TramIcon;

// import TranscribeIcon from 'react-material-icon-svg/dist/Transcribe';
// export const TranscribeIconSvg = TranscribeIcon;

// import TranscribeCloseIcon from 'react-material-icon-svg/dist/TranscribeClose';
// export const TranscribeCloseIconSvg = TranscribeCloseIcon;

// import TransferDownIcon from 'react-material-icon-svg/dist/TransferDown';
// export const TransferDownIconSvg = TransferDownIcon;

// import TransferLeftIcon from 'react-material-icon-svg/dist/TransferLeft';
// export const TransferLeftIconSvg = TransferLeftIcon;

// import TransferRightIcon from 'react-material-icon-svg/dist/TransferRight';
// export const TransferRightIconSvg = TransferRightIcon;

// import TransferUpIcon from 'react-material-icon-svg/dist/TransferUp';
// export const TransferUpIconSvg = TransferUpIcon;

// import TransitConnectionIcon from 'react-material-icon-svg/dist/TransitConnection';
// export const TransitConnectionIconSvg = TransitConnectionIcon;

// import TransitConnectionVariantIcon from 'react-material-icon-svg/dist/TransitConnectionVariant';
// export const TransitConnectionVariantIconSvg = TransitConnectionVariantIcon;

// import TransitTransferIcon from 'react-material-icon-svg/dist/TransitTransfer';
// export const TransitTransferIconSvg = TransitTransferIcon;

// import TransitionIcon from 'react-material-icon-svg/dist/Transition';
// export const TransitionIconSvg = TransitionIcon;

// import TransitionMaskedIcon from 'react-material-icon-svg/dist/TransitionMasked';
// export const TransitionMaskedIconSvg = TransitionMaskedIcon;

// import TranslateIcon from 'react-material-icon-svg/dist/Translate';
// export const TranslateIconSvg = TranslateIcon;

// import TranslateOffIcon from 'react-material-icon-svg/dist/TranslateOff';
// export const TranslateOffIconSvg = TranslateOffIcon;

// import TransmissionTowerIcon from 'react-material-icon-svg/dist/TransmissionTower';
// export const TransmissionTowerIconSvg = TransmissionTowerIcon;

// import TrashCanIcon from 'react-material-icon-svg/dist/TrashCan';
// export const TrashCanIconSvg = TrashCanIcon;

// import TrashCanOutlineIcon from 'react-material-icon-svg/dist/TrashCanOutline';
// export const TrashCanOutlineIconSvg = TrashCanOutlineIcon;

// import TreasureChestIcon from 'react-material-icon-svg/dist/TreasureChest';
// export const TreasureChestIconSvg = TreasureChestIcon;

// import TreeIcon from 'react-material-icon-svg/dist/Tree';
// export const TreeIconSvg = TreeIcon;

// import TreeOutlineIcon from 'react-material-icon-svg/dist/TreeOutline';
// export const TreeOutlineIconSvg = TreeOutlineIcon;

// import TrelloIcon from 'react-material-icon-svg/dist/Trello';
// export const TrelloIconSvg = TrelloIcon;

// import TrendingDownIcon from 'react-material-icon-svg/dist/TrendingDown';
// export const TrendingDownIconSvg = TrendingDownIcon;

// import TrendingNeutralIcon from 'react-material-icon-svg/dist/TrendingNeutral';
// export const TrendingNeutralIconSvg = TrendingNeutralIcon;

// import TrendingUpIcon from 'react-material-icon-svg/dist/TrendingUp';
// export const TrendingUpIconSvg = TrendingUpIcon;

// import TriangleIcon from 'react-material-icon-svg/dist/Triangle';
// export const TriangleIconSvg = TriangleIcon;

// import TriangleOutlineIcon from 'react-material-icon-svg/dist/TriangleOutline';
// export const TriangleOutlineIconSvg = TriangleOutlineIcon;

// import TriforceIcon from 'react-material-icon-svg/dist/Triforce';
// export const TriforceIconSvg = TriforceIcon;

// import TrophyIcon from 'react-material-icon-svg/dist/Trophy';
// export const TrophyIconSvg = TrophyIcon;

// import TrophyAwardIcon from 'react-material-icon-svg/dist/TrophyAward';
// export const TrophyAwardIconSvg = TrophyAwardIcon;

// import TrophyBrokenIcon from 'react-material-icon-svg/dist/TrophyBroken';
// export const TrophyBrokenIconSvg = TrophyBrokenIcon;

// import TrophyOutlineIcon from 'react-material-icon-svg/dist/TrophyOutline';
// export const TrophyOutlineIconSvg = TrophyOutlineIcon;

// import TrophyVariantIcon from 'react-material-icon-svg/dist/TrophyVariant';
// export const TrophyVariantIconSvg = TrophyVariantIcon;

// import TrophyVariantOutlineIcon from 'react-material-icon-svg/dist/TrophyVariantOutline';
// export const TrophyVariantOutlineIconSvg = TrophyVariantOutlineIcon;

// import TruckIcon from 'react-material-icon-svg/dist/Truck';
// export const TruckIconSvg = TruckIcon;

// import TruckCheckIcon from 'react-material-icon-svg/dist/TruckCheck';
// export const TruckCheckIconSvg = TruckCheckIcon;

// import TruckDeliveryIcon from 'react-material-icon-svg/dist/TruckDelivery';
// export const TruckDeliveryIconSvg = TruckDeliveryIcon;

// import TruckFastIcon from 'react-material-icon-svg/dist/TruckFast';
// export const TruckFastIconSvg = TruckFastIcon;

// import TruckTrailerIcon from 'react-material-icon-svg/dist/TruckTrailer';
// export const TruckTrailerIconSvg = TruckTrailerIcon;

// import TshirtCrewIcon from 'react-material-icon-svg/dist/TshirtCrew';
// export const TshirtCrewIconSvg = TshirtCrewIcon;

// import TshirtCrewOutlineIcon from 'react-material-icon-svg/dist/TshirtCrewOutline';
// export const TshirtCrewOutlineIconSvg = TshirtCrewOutlineIcon;

// import TshirtVIcon from 'react-material-icon-svg/dist/TshirtV';
// export const TshirtVIconSvg = TshirtVIcon;

// import TshirtVOutlineIcon from 'react-material-icon-svg/dist/TshirtVOutline';
// export const TshirtVOutlineIconSvg = TshirtVOutlineIcon;

// import TumbleDryerIcon from 'react-material-icon-svg/dist/TumbleDryer';
// export const TumbleDryerIconSvg = TumbleDryerIcon;

// import TumblrIcon from 'react-material-icon-svg/dist/Tumblr';
// export const TumblrIconSvg = TumblrIcon;

// import TumblrBoxIcon from 'react-material-icon-svg/dist/TumblrBox';
// export const TumblrBoxIconSvg = TumblrBoxIcon;

// import TumblrReblogIcon from 'react-material-icon-svg/dist/TumblrReblog';
// export const TumblrReblogIconSvg = TumblrReblogIcon;

// import TuneIcon from 'react-material-icon-svg/dist/Tune';
// export const TuneIconSvg = TuneIcon;

// import TuneVerticalIcon from 'react-material-icon-svg/dist/TuneVertical';
// export const TuneVerticalIconSvg = TuneVerticalIcon;

// import TurnstileIcon from 'react-material-icon-svg/dist/Turnstile';
// export const TurnstileIconSvg = TurnstileIcon;

// import TurnstileOutlineIcon from 'react-material-icon-svg/dist/TurnstileOutline';
// export const TurnstileOutlineIconSvg = TurnstileOutlineIcon;

// import TurtleIcon from 'react-material-icon-svg/dist/Turtle';
// export const TurtleIconSvg = TurtleIcon;

// import TwitchIcon from 'react-material-icon-svg/dist/Twitch';
// export const TwitchIconSvg = TwitchIcon;

// import TwitterIcon from 'react-material-icon-svg/dist/Twitter';
// export const TwitterIconSvg = TwitterIcon;

// import TwitterBoxIcon from 'react-material-icon-svg/dist/TwitterBox';
// export const TwitterBoxIconSvg = TwitterBoxIcon;

// import TwitterCircleIcon from 'react-material-icon-svg/dist/TwitterCircle';
// export const TwitterCircleIconSvg = TwitterCircleIcon;

// import TwitterRetweetIcon from 'react-material-icon-svg/dist/TwitterRetweet';
// export const TwitterRetweetIconSvg = TwitterRetweetIcon;

// import TwoFactorAuthenticationIcon from 'react-material-icon-svg/dist/TwoFactorAuthentication';
// export const TwoFactorAuthenticationIconSvg = TwoFactorAuthenticationIcon;

// import UberIcon from 'react-material-icon-svg/dist/Uber';
// export const UberIconSvg = UberIcon;

// import UbisoftIcon from 'react-material-icon-svg/dist/Ubisoft';
// export const UbisoftIconSvg = UbisoftIcon;

// import UbuntuIcon from 'react-material-icon-svg/dist/Ubuntu';
// export const UbuntuIconSvg = UbuntuIcon;

// import UltraHighDefinitionIcon from 'react-material-icon-svg/dist/UltraHighDefinition';
// export const UltraHighDefinitionIconSvg = UltraHighDefinitionIcon;

// import UmbracoIcon from 'react-material-icon-svg/dist/Umbraco';
// export const UmbracoIconSvg = UmbracoIcon;

// import UmbrellaIcon from 'react-material-icon-svg/dist/Umbrella';
// export const UmbrellaIconSvg = UmbrellaIcon;

// import UmbrellaClosedIcon from 'react-material-icon-svg/dist/UmbrellaClosed';
// export const UmbrellaClosedIconSvg = UmbrellaClosedIcon;

// import UmbrellaOutlineIcon from 'react-material-icon-svg/dist/UmbrellaOutline';
// export const UmbrellaOutlineIconSvg = UmbrellaOutlineIcon;

// import UndoIcon from 'react-material-icon-svg/dist/Undo';
// export const UndoIconSvg = UndoIcon;

// import UndoVariantIcon from 'react-material-icon-svg/dist/UndoVariant';
// export const UndoVariantIconSvg = UndoVariantIcon;

// import UnfoldLessHorizontalIcon from 'react-material-icon-svg/dist/UnfoldLessHorizontal';
// export const UnfoldLessHorizontalIconSvg = UnfoldLessHorizontalIcon;

// import UnfoldLessVerticalIcon from 'react-material-icon-svg/dist/UnfoldLessVertical';
// export const UnfoldLessVerticalIconSvg = UnfoldLessVerticalIcon;

// import UnfoldMoreHorizontalIcon from 'react-material-icon-svg/dist/UnfoldMoreHorizontal';
// export const UnfoldMoreHorizontalIconSvg = UnfoldMoreHorizontalIcon;

// import UnfoldMoreVerticalIcon from 'react-material-icon-svg/dist/UnfoldMoreVertical';
// export const UnfoldMoreVerticalIconSvg = UnfoldMoreVerticalIcon;

// import UngroupIcon from 'react-material-icon-svg/dist/Ungroup';
// export const UngroupIconSvg = UngroupIcon;

// import UnicodeIcon from 'react-material-icon-svg/dist/Unicode';
// export const UnicodeIconSvg = UnicodeIcon;

// import UnityIcon from 'react-material-icon-svg/dist/Unity';
// export const UnityIconSvg = UnityIcon;

// import UnrealIcon from 'react-material-icon-svg/dist/Unreal';
// export const UnrealIconSvg = UnrealIcon;

// import UntappdIcon from 'react-material-icon-svg/dist/Untappd';
// export const UntappdIconSvg = UntappdIcon;

// import UpdateIcon from 'react-material-icon-svg/dist/Update';
// export const UpdateIconSvg = UpdateIcon;

// import UploadIcon from 'react-material-icon-svg/dist/Upload';
// export const UploadIconSvg = UploadIcon;

// import UploadMultipleIcon from 'react-material-icon-svg/dist/UploadMultiple';
// export const UploadMultipleIconSvg = UploadMultipleIcon;

// import UploadNetworkIcon from 'react-material-icon-svg/dist/UploadNetwork';
// export const UploadNetworkIconSvg = UploadNetworkIcon;

// import UploadNetworkOutlineIcon from 'react-material-icon-svg/dist/UploadNetworkOutline';
// export const UploadNetworkOutlineIconSvg = UploadNetworkOutlineIcon;

// import UploadOutlineIcon from 'react-material-icon-svg/dist/UploadOutline';
// export const UploadOutlineIconSvg = UploadOutlineIcon;

// import UsbIcon from 'react-material-icon-svg/dist/Usb';
// export const UsbIconSvg = UsbIcon;

// import VanPassengerIcon from 'react-material-icon-svg/dist/VanPassenger';
// export const VanPassengerIconSvg = VanPassengerIcon;

// import VanUtilityIcon from 'react-material-icon-svg/dist/VanUtility';
// export const VanUtilityIconSvg = VanUtilityIcon;

// import VanishIcon from 'react-material-icon-svg/dist/Vanish';
// export const VanishIconSvg = VanishIcon;

// import VariableIcon from 'react-material-icon-svg/dist/Variable';
// export const VariableIconSvg = VariableIcon;

// import VectorArrangeAboveIcon from 'react-material-icon-svg/dist/VectorArrangeAbove';
// export const VectorArrangeAboveIconSvg = VectorArrangeAboveIcon;

// import VectorArrangeBelowIcon from 'react-material-icon-svg/dist/VectorArrangeBelow';
// export const VectorArrangeBelowIconSvg = VectorArrangeBelowIcon;

// import VectorBezierIcon from 'react-material-icon-svg/dist/VectorBezier';
// export const VectorBezierIconSvg = VectorBezierIcon;

// import VectorCircleIcon from 'react-material-icon-svg/dist/VectorCircle';
// export const VectorCircleIconSvg = VectorCircleIcon;

// import VectorCircleVariantIcon from 'react-material-icon-svg/dist/VectorCircleVariant';
// export const VectorCircleVariantIconSvg = VectorCircleVariantIcon;

// import VectorCombineIcon from 'react-material-icon-svg/dist/VectorCombine';
// export const VectorCombineIconSvg = VectorCombineIcon;

// import VectorCurveIcon from 'react-material-icon-svg/dist/VectorCurve';
// export const VectorCurveIconSvg = VectorCurveIcon;

// import VectorDifferenceIcon from 'react-material-icon-svg/dist/VectorDifference';
// export const VectorDifferenceIconSvg = VectorDifferenceIcon;

// import VectorDifferenceAbIcon from 'react-material-icon-svg/dist/VectorDifferenceAb';
// export const VectorDifferenceAbIconSvg = VectorDifferenceAbIcon;

// import VectorDifferenceBaIcon from 'react-material-icon-svg/dist/VectorDifferenceBa';
// export const VectorDifferenceBaIconSvg = VectorDifferenceBaIcon;

// import VectorEllipseIcon from 'react-material-icon-svg/dist/VectorEllipse';
// export const VectorEllipseIconSvg = VectorEllipseIcon;

// import VectorIntersectionIcon from 'react-material-icon-svg/dist/VectorIntersection';
// export const VectorIntersectionIconSvg = VectorIntersectionIcon;

// import VectorLineIcon from 'react-material-icon-svg/dist/VectorLine';
// export const VectorLineIconSvg = VectorLineIcon;

// import VectorPointIcon from 'react-material-icon-svg/dist/VectorPoint';
// export const VectorPointIconSvg = VectorPointIcon;

// import VectorPolygonIcon from 'react-material-icon-svg/dist/VectorPolygon';
// export const VectorPolygonIconSvg = VectorPolygonIcon;

// import VectorPolylineIcon from 'react-material-icon-svg/dist/VectorPolyline';
// export const VectorPolylineIconSvg = VectorPolylineIcon;

// import VectorRadiusIcon from 'react-material-icon-svg/dist/VectorRadius';
// export const VectorRadiusIconSvg = VectorRadiusIcon;

// import VectorRectangleIcon from 'react-material-icon-svg/dist/VectorRectangle';
// export const VectorRectangleIconSvg = VectorRectangleIcon;

// import VectorSelectionIcon from 'react-material-icon-svg/dist/VectorSelection';
// export const VectorSelectionIconSvg = VectorSelectionIcon;

// import VectorSquareIcon from 'react-material-icon-svg/dist/VectorSquare';
// export const VectorSquareIconSvg = VectorSquareIcon;

// import VectorTriangleIcon from 'react-material-icon-svg/dist/VectorTriangle';
// export const VectorTriangleIconSvg = VectorTriangleIcon;

// import VectorUnionIcon from 'react-material-icon-svg/dist/VectorUnion';
// export const VectorUnionIconSvg = VectorUnionIcon;

// import VenmoIcon from 'react-material-icon-svg/dist/Venmo';
// export const VenmoIconSvg = VenmoIcon;

// import VhsIcon from 'react-material-icon-svg/dist/Vhs';
// export const VhsIconSvg = VhsIcon;

// import VibrateIcon from 'react-material-icon-svg/dist/Vibrate';
// export const VibrateIconSvg = VibrateIcon;

// import VibrateOffIcon from 'react-material-icon-svg/dist/VibrateOff';
// export const VibrateOffIconSvg = VibrateOffIcon;

// import VideoIcon from 'react-material-icon-svg/dist/Video';
// export const VideoIconSvg = VideoIcon;

// import VideoAccountIcon from 'react-material-icon-svg/dist/VideoAccount';
// export const VideoAccountIconSvg = VideoAccountIcon;

// import VideoImageIcon from 'react-material-icon-svg/dist/VideoImage';
// export const VideoImageIconSvg = VideoImageIcon;

// import VideoInputAntennaIcon from 'react-material-icon-svg/dist/VideoInputAntenna';
// export const VideoInputAntennaIconSvg = VideoInputAntennaIcon;

// import VideoInputComponentIcon from 'react-material-icon-svg/dist/VideoInputComponent';
// export const VideoInputComponentIconSvg = VideoInputComponentIcon;

// import VideoInputHdmiIcon from 'react-material-icon-svg/dist/VideoInputHdmi';
// export const VideoInputHdmiIconSvg = VideoInputHdmiIcon;

// import VideoInputSvideoIcon from 'react-material-icon-svg/dist/VideoInputSvideo';
// export const VideoInputSvideoIconSvg = VideoInputSvideoIcon;

// import VideoMinusIcon from 'react-material-icon-svg/dist/VideoMinus';
// export const VideoMinusIconSvg = VideoMinusIcon;

// import VideoOffIcon from 'react-material-icon-svg/dist/VideoOff';
// export const VideoOffIconSvg = VideoOffIcon;

// import VideoOffOutlineIcon from 'react-material-icon-svg/dist/VideoOffOutline';
// export const VideoOffOutlineIconSvg = VideoOffOutlineIcon;

// import VideoOutlineIcon from 'react-material-icon-svg/dist/VideoOutline';
// export const VideoOutlineIconSvg = VideoOutlineIcon;

// import VideoPlusIcon from 'react-material-icon-svg/dist/VideoPlus';
// export const VideoPlusIconSvg = VideoPlusIcon;

// import VideoStabilizationIcon from 'react-material-icon-svg/dist/VideoStabilization';
// export const VideoStabilizationIconSvg = VideoStabilizationIcon;

// import VideoSwitchIcon from 'react-material-icon-svg/dist/VideoSwitch';
// export const VideoSwitchIconSvg = VideoSwitchIcon;

// import VideoVintageIcon from 'react-material-icon-svg/dist/VideoVintage';
// export const VideoVintageIconSvg = VideoVintageIcon;

// import VideoWirelessIcon from 'react-material-icon-svg/dist/VideoWireless';
// export const VideoWirelessIconSvg = VideoWirelessIcon;

// import VideoWirelessOutlineIcon from 'react-material-icon-svg/dist/VideoWirelessOutline';
// export const VideoWirelessOutlineIconSvg = VideoWirelessOutlineIcon;

// import Video_3dIcon from 'react-material-icon-svg/dist/Video_3d';
// export const Video_3dIconSvg = Video_3dIcon;

// import Video_3dVariantIcon from 'react-material-icon-svg/dist/Video_3dVariant';
// export const Video_3dVariantIconSvg = Video_3dVariantIcon;

// import Video_4kBoxIcon from 'react-material-icon-svg/dist/Video_4kBox';
// export const Video_4kBoxIconSvg = Video_4kBoxIcon;

// import ViewAgendaIcon from 'react-material-icon-svg/dist/ViewAgenda';
// export const ViewAgendaIconSvg = ViewAgendaIcon;

// import ViewArrayIcon from 'react-material-icon-svg/dist/ViewArray';
// export const ViewArrayIconSvg = ViewArrayIcon;

// import ViewCarouselIcon from 'react-material-icon-svg/dist/ViewCarousel';
// export const ViewCarouselIconSvg = ViewCarouselIcon;

// import ViewColumnIcon from 'react-material-icon-svg/dist/ViewColumn';
// export const ViewColumnIconSvg = ViewColumnIcon;

// import ViewComfyIcon from 'react-material-icon-svg/dist/ViewComfy';
// export const ViewComfyIconSvg = ViewComfyIcon;

// import ViewCompactIcon from 'react-material-icon-svg/dist/ViewCompact';
// export const ViewCompactIconSvg = ViewCompactIcon;

// import ViewCompactOutlineIcon from 'react-material-icon-svg/dist/ViewCompactOutline';
// export const ViewCompactOutlineIconSvg = ViewCompactOutlineIcon;

// import ViewDashboardIcon from 'react-material-icon-svg/dist/ViewDashboard';
// export const ViewDashboardIconSvg = ViewDashboardIcon;

// import ViewDashboardOutlineIcon from 'react-material-icon-svg/dist/ViewDashboardOutline';
// export const ViewDashboardOutlineIconSvg = ViewDashboardOutlineIcon;

// import ViewDashboardVariantIcon from 'react-material-icon-svg/dist/ViewDashboardVariant';
// export const ViewDashboardVariantIconSvg = ViewDashboardVariantIcon;

// import ViewDayIcon from 'react-material-icon-svg/dist/ViewDay';
// export const ViewDayIconSvg = ViewDayIcon;

// import ViewGridIcon from 'react-material-icon-svg/dist/ViewGrid';
// export const ViewGridIconSvg = ViewGridIcon;

// import ViewHeadlineIcon from 'react-material-icon-svg/dist/ViewHeadline';
// export const ViewHeadlineIconSvg = ViewHeadlineIcon;

// import ViewListIcon from 'react-material-icon-svg/dist/ViewList';
// export const ViewListIconSvg = ViewListIcon;

// import ViewModuleIcon from 'react-material-icon-svg/dist/ViewModule';
// export const ViewModuleIconSvg = ViewModuleIcon;

// import ViewParallelIcon from 'react-material-icon-svg/dist/ViewParallel';
// export const ViewParallelIconSvg = ViewParallelIcon;

// import ViewQuiltIcon from 'react-material-icon-svg/dist/ViewQuilt';
// export const ViewQuiltIconSvg = ViewQuiltIcon;

// import ViewSequentialIcon from 'react-material-icon-svg/dist/ViewSequential';
// export const ViewSequentialIconSvg = ViewSequentialIcon;

// import ViewSplitHorizontalIcon from 'react-material-icon-svg/dist/ViewSplitHorizontal';
// export const ViewSplitHorizontalIconSvg = ViewSplitHorizontalIcon;

// import ViewSplitVerticalIcon from 'react-material-icon-svg/dist/ViewSplitVertical';
// export const ViewSplitVerticalIconSvg = ViewSplitVerticalIcon;

// import ViewStreamIcon from 'react-material-icon-svg/dist/ViewStream';
// export const ViewStreamIconSvg = ViewStreamIcon;

// import ViewWeekIcon from 'react-material-icon-svg/dist/ViewWeek';
// export const ViewWeekIconSvg = ViewWeekIcon;

// import VimeoIcon from 'react-material-icon-svg/dist/Vimeo';
// export const VimeoIconSvg = VimeoIcon;

// import ViolinIcon from 'react-material-icon-svg/dist/Violin';
// export const ViolinIconSvg = ViolinIcon;

// import VirtualRealityIcon from 'react-material-icon-svg/dist/VirtualReality';
// export const VirtualRealityIconSvg = VirtualRealityIcon;

// import VisualStudioIcon from 'react-material-icon-svg/dist/VisualStudio';
// export const VisualStudioIconSvg = VisualStudioIcon;

// import VisualStudioCodeIcon from 'react-material-icon-svg/dist/VisualStudioCode';
// export const VisualStudioCodeIconSvg = VisualStudioCodeIcon;

// import VkIcon from 'react-material-icon-svg/dist/Vk';
// export const VkIconSvg = VkIcon;

// import VkBoxIcon from 'react-material-icon-svg/dist/VkBox';
// export const VkBoxIconSvg = VkBoxIcon;

// import VkCircleIcon from 'react-material-icon-svg/dist/VkCircle';
// export const VkCircleIconSvg = VkCircleIcon;

// import VlcIcon from 'react-material-icon-svg/dist/Vlc';
// export const VlcIconSvg = VlcIcon;

// import VoiceIcon from 'react-material-icon-svg/dist/Voice';
// export const VoiceIconSvg = VoiceIcon;

// import VoiceOffIcon from 'react-material-icon-svg/dist/VoiceOff';
// export const VoiceOffIconSvg = VoiceOffIcon;

// import VoicemailIcon from 'react-material-icon-svg/dist/Voicemail';
// export const VoicemailIconSvg = VoicemailIcon;

// import VolleyballIcon from 'react-material-icon-svg/dist/Volleyball';
// export const VolleyballIconSvg = VolleyballIcon;

// import VolumeHighIcon from 'react-material-icon-svg/dist/VolumeHigh';
// export const VolumeHighIconSvg = VolumeHighIcon;

// import VolumeLowIcon from 'react-material-icon-svg/dist/VolumeLow';
// export const VolumeLowIconSvg = VolumeLowIcon;

// import VolumeMediumIcon from 'react-material-icon-svg/dist/VolumeMedium';
// export const VolumeMediumIconSvg = VolumeMediumIcon;

// import VolumeMinusIcon from 'react-material-icon-svg/dist/VolumeMinus';
// export const VolumeMinusIconSvg = VolumeMinusIcon;

// import VolumeMuteIcon from 'react-material-icon-svg/dist/VolumeMute';
// export const VolumeMuteIconSvg = VolumeMuteIcon;

// import VolumeOffIcon from 'react-material-icon-svg/dist/VolumeOff';
// export const VolumeOffIconSvg = VolumeOffIcon;

// import VolumePlusIcon from 'react-material-icon-svg/dist/VolumePlus';
// export const VolumePlusIconSvg = VolumePlusIcon;

// import VolumeVariantOffIcon from 'react-material-icon-svg/dist/VolumeVariantOff';
// export const VolumeVariantOffIconSvg = VolumeVariantOffIcon;

// import VoteIcon from 'react-material-icon-svg/dist/Vote';
// export const VoteIconSvg = VoteIcon;

// import VoteOutlineIcon from 'react-material-icon-svg/dist/VoteOutline';
// export const VoteOutlineIconSvg = VoteOutlineIcon;

// import VpnIcon from 'react-material-icon-svg/dist/Vpn';
// export const VpnIconSvg = VpnIcon;

// import VuejsIcon from 'react-material-icon-svg/dist/Vuejs';
// export const VuejsIconSvg = VuejsIcon;

// import VuetifyIcon from 'react-material-icon-svg/dist/Vuetify';
// export const VuetifyIconSvg = VuetifyIcon;

// import WalkIcon from 'react-material-icon-svg/dist/Walk';
// export const WalkIconSvg = WalkIcon;

// import WallIcon from 'react-material-icon-svg/dist/Wall';
// export const WallIconSvg = WallIcon;

// import WallSconceIcon from 'react-material-icon-svg/dist/WallSconce';
// export const WallSconceIconSvg = WallSconceIcon;

// import WallSconceFlatIcon from 'react-material-icon-svg/dist/WallSconceFlat';
// export const WallSconceFlatIconSvg = WallSconceFlatIcon;

// import WallSconceVariantIcon from 'react-material-icon-svg/dist/WallSconceVariant';
// export const WallSconceVariantIconSvg = WallSconceVariantIcon;

// import WalletIcon from 'react-material-icon-svg/dist/Wallet';
// export const WalletIconSvg = WalletIcon;

import WalletGiftcardIcon from 'react-material-icon-svg/dist/WalletGiftcard';
export const WalletGiftcardIconSvg = WalletGiftcardIcon;

// import WalletMembershipIcon from 'react-material-icon-svg/dist/WalletMembership';
// export const WalletMembershipIconSvg = WalletMembershipIcon;

// import WalletOutlineIcon from 'react-material-icon-svg/dist/WalletOutline';
// export const WalletOutlineIconSvg = WalletOutlineIcon;

// import WalletTravelIcon from 'react-material-icon-svg/dist/WalletTravel';
// export const WalletTravelIconSvg = WalletTravelIcon;

// import WallpaperIcon from 'react-material-icon-svg/dist/Wallpaper';
// export const WallpaperIconSvg = WallpaperIcon;

// import WanIcon from 'react-material-icon-svg/dist/Wan';
// export const WanIconSvg = WanIcon;

// import WashingMachineIcon from 'react-material-icon-svg/dist/WashingMachine';
// export const WashingMachineIconSvg = WashingMachineIcon;

// import WatchIcon from 'react-material-icon-svg/dist/Watch';
// export const WatchIconSvg = WatchIcon;

// import WatchExportIcon from 'react-material-icon-svg/dist/WatchExport';
// export const WatchExportIconSvg = WatchExportIcon;

// import WatchExportVariantIcon from 'react-material-icon-svg/dist/WatchExportVariant';
// export const WatchExportVariantIconSvg = WatchExportVariantIcon;

// import WatchImportIcon from 'react-material-icon-svg/dist/WatchImport';
// export const WatchImportIconSvg = WatchImportIcon;

// import WatchImportVariantIcon from 'react-material-icon-svg/dist/WatchImportVariant';
// export const WatchImportVariantIconSvg = WatchImportVariantIcon;

// import WatchVariantIcon from 'react-material-icon-svg/dist/WatchVariant';
// export const WatchVariantIconSvg = WatchVariantIcon;

// import WatchVibrateIcon from 'react-material-icon-svg/dist/WatchVibrate';
// export const WatchVibrateIconSvg = WatchVibrateIcon;

// import WatchVibrateOffIcon from 'react-material-icon-svg/dist/WatchVibrateOff';
// export const WatchVibrateOffIconSvg = WatchVibrateOffIcon;

// import WaterIcon from 'react-material-icon-svg/dist/Water';
// export const WaterIconSvg = WaterIcon;

// import WaterOffIcon from 'react-material-icon-svg/dist/WaterOff';
// export const WaterOffIconSvg = WaterOffIcon;

// import WaterOutlineIcon from 'react-material-icon-svg/dist/WaterOutline';
// export const WaterOutlineIconSvg = WaterOutlineIcon;

// import WaterPercentIcon from 'react-material-icon-svg/dist/WaterPercent';
// export const WaterPercentIconSvg = WaterPercentIcon;

// import WaterPumpIcon from 'react-material-icon-svg/dist/WaterPump';
// export const WaterPumpIconSvg = WaterPumpIcon;

// import WatermarkIcon from 'react-material-icon-svg/dist/Watermark';
// export const WatermarkIconSvg = WatermarkIcon;

// import WavesIcon from 'react-material-icon-svg/dist/Waves';
// export const WavesIconSvg = WavesIcon;

// import WazeIcon from 'react-material-icon-svg/dist/Waze';
// export const WazeIconSvg = WazeIcon;

// import WeatherCloudyIcon from 'react-material-icon-svg/dist/WeatherCloudy';
// export const WeatherCloudyIconSvg = WeatherCloudyIcon;

// import WeatherCloudyArrowRightIcon from 'react-material-icon-svg/dist/WeatherCloudyArrowRight';
// export const WeatherCloudyArrowRightIconSvg = WeatherCloudyArrowRightIcon;

// import WeatherFogIcon from 'react-material-icon-svg/dist/WeatherFog';
// export const WeatherFogIconSvg = WeatherFogIcon;

// import WeatherHailIcon from 'react-material-icon-svg/dist/WeatherHail';
// export const WeatherHailIconSvg = WeatherHailIcon;

// import WeatherHurricaneIcon from 'react-material-icon-svg/dist/WeatherHurricane';
// export const WeatherHurricaneIconSvg = WeatherHurricaneIcon;

// import WeatherLightningIcon from 'react-material-icon-svg/dist/WeatherLightning';
// export const WeatherLightningIconSvg = WeatherLightningIcon;

// import WeatherLightningRainyIcon from 'react-material-icon-svg/dist/WeatherLightningRainy';
// export const WeatherLightningRainyIconSvg = WeatherLightningRainyIcon;

// import WeatherNightIcon from 'react-material-icon-svg/dist/WeatherNight';
// export const WeatherNightIconSvg = WeatherNightIcon;

// import WeatherPartlycloudyIcon from 'react-material-icon-svg/dist/WeatherPartlycloudy';
// export const WeatherPartlycloudyIconSvg = WeatherPartlycloudyIcon;

// import WeatherPouringIcon from 'react-material-icon-svg/dist/WeatherPouring';
// export const WeatherPouringIconSvg = WeatherPouringIcon;

// import WeatherRainyIcon from 'react-material-icon-svg/dist/WeatherRainy';
// export const WeatherRainyIconSvg = WeatherRainyIcon;

// import WeatherSnowyIcon from 'react-material-icon-svg/dist/WeatherSnowy';
// export const WeatherSnowyIconSvg = WeatherSnowyIcon;

// import WeatherSnowyRainyIcon from 'react-material-icon-svg/dist/WeatherSnowyRainy';
// export const WeatherSnowyRainyIconSvg = WeatherSnowyRainyIcon;

// import WeatherSunnyIcon from 'react-material-icon-svg/dist/WeatherSunny';
// export const WeatherSunnyIconSvg = WeatherSunnyIcon;

// import WeatherSunsetIcon from 'react-material-icon-svg/dist/WeatherSunset';
// export const WeatherSunsetIconSvg = WeatherSunsetIcon;

// import WeatherSunsetDownIcon from 'react-material-icon-svg/dist/WeatherSunsetDown';
// export const WeatherSunsetDownIconSvg = WeatherSunsetDownIcon;

// import WeatherSunsetUpIcon from 'react-material-icon-svg/dist/WeatherSunsetUp';
// export const WeatherSunsetUpIconSvg = WeatherSunsetUpIcon;

// import WeatherWindyIcon from 'react-material-icon-svg/dist/WeatherWindy';
// export const WeatherWindyIconSvg = WeatherWindyIcon;

// import WeatherWindyVariantIcon from 'react-material-icon-svg/dist/WeatherWindyVariant';
// export const WeatherWindyVariantIconSvg = WeatherWindyVariantIcon;

// import WebIcon from 'react-material-icon-svg/dist/Web';
// export const WebIconSvg = WebIcon;

// import WebcamIcon from 'react-material-icon-svg/dist/Webcam';
// export const WebcamIconSvg = WebcamIcon;

// import WebhookIcon from 'react-material-icon-svg/dist/Webhook';
// export const WebhookIconSvg = WebhookIcon;

// import WebpackIcon from 'react-material-icon-svg/dist/Webpack';
// export const WebpackIconSvg = WebpackIcon;

// import WechatIcon from 'react-material-icon-svg/dist/Wechat';
// export const WechatIconSvg = WechatIcon;

// import WeightIcon from 'react-material-icon-svg/dist/Weight';
// export const WeightIconSvg = WeightIcon;

// import WeightGramIcon from 'react-material-icon-svg/dist/WeightGram';
// export const WeightGramIconSvg = WeightGramIcon;

// import WeightKilogramIcon from 'react-material-icon-svg/dist/WeightKilogram';
// export const WeightKilogramIconSvg = WeightKilogramIcon;

// import WeightPoundIcon from 'react-material-icon-svg/dist/WeightPound';
// export const WeightPoundIconSvg = WeightPoundIcon;

// import WhatsappIcon from 'react-material-icon-svg/dist/Whatsapp';
// export const WhatsappIconSvg = WhatsappIcon;

// import WheelchairAccessibilityIcon from 'react-material-icon-svg/dist/WheelchairAccessibility';
// export const WheelchairAccessibilityIconSvg = WheelchairAccessibilityIcon;

// import WhistleIcon from 'react-material-icon-svg/dist/Whistle';
// export const WhistleIconSvg = WhistleIcon;

// import WhiteBalanceAutoIcon from 'react-material-icon-svg/dist/WhiteBalanceAuto';
// export const WhiteBalanceAutoIconSvg = WhiteBalanceAutoIcon;

// import WhiteBalanceIncandescentIcon from 'react-material-icon-svg/dist/WhiteBalanceIncandescent';
// export const WhiteBalanceIncandescentIconSvg = WhiteBalanceIncandescentIcon;

// import WhiteBalanceIridescentIcon from 'react-material-icon-svg/dist/WhiteBalanceIridescent';
// export const WhiteBalanceIridescentIconSvg = WhiteBalanceIridescentIcon;

// import WhiteBalanceSunnyIcon from 'react-material-icon-svg/dist/WhiteBalanceSunny';
// export const WhiteBalanceSunnyIconSvg = WhiteBalanceSunnyIcon;

// import WidgetsIcon from 'react-material-icon-svg/dist/Widgets';
// export const WidgetsIconSvg = WidgetsIcon;

// import WifiIcon from 'react-material-icon-svg/dist/Wifi';
// export const WifiIconSvg = WifiIcon;

// import WifiOffIcon from 'react-material-icon-svg/dist/WifiOff';
// export const WifiOffIconSvg = WifiOffIcon;

// import WifiStarIcon from 'react-material-icon-svg/dist/WifiStar';
// export const WifiStarIconSvg = WifiStarIcon;

// import WifiStrengthAlertOutlineIcon from 'react-material-icon-svg/dist/WifiStrengthAlertOutline';
// export const WifiStrengthAlertOutlineIconSvg = WifiStrengthAlertOutlineIcon;

// import WifiStrengthLockOutlineIcon from 'react-material-icon-svg/dist/WifiStrengthLockOutline';
// export const WifiStrengthLockOutlineIconSvg = WifiStrengthLockOutlineIcon;

// import WifiStrengthOffIcon from 'react-material-icon-svg/dist/WifiStrengthOff';
// export const WifiStrengthOffIconSvg = WifiStrengthOffIcon;

// import WifiStrengthOffOutlineIcon from 'react-material-icon-svg/dist/WifiStrengthOffOutline';
// export const WifiStrengthOffOutlineIconSvg = WifiStrengthOffOutlineIcon;

// import WifiStrengthOutlineIcon from 'react-material-icon-svg/dist/WifiStrengthOutline';
// export const WifiStrengthOutlineIconSvg = WifiStrengthOutlineIcon;

// import WifiStrength_1Icon from 'react-material-icon-svg/dist/WifiStrength_1';
// export const WifiStrength_1IconSvg = WifiStrength_1Icon;

// import WifiStrength_1AlertIcon from 'react-material-icon-svg/dist/WifiStrength_1Alert';
// export const WifiStrength_1AlertIconSvg = WifiStrength_1AlertIcon;

// import WifiStrength_1LockIcon from 'react-material-icon-svg/dist/WifiStrength_1Lock';
// export const WifiStrength_1LockIconSvg = WifiStrength_1LockIcon;

// import WifiStrength_2Icon from 'react-material-icon-svg/dist/WifiStrength_2';
// export const WifiStrength_2IconSvg = WifiStrength_2Icon;

// import WifiStrength_2AlertIcon from 'react-material-icon-svg/dist/WifiStrength_2Alert';
// export const WifiStrength_2AlertIconSvg = WifiStrength_2AlertIcon;

// import WifiStrength_2LockIcon from 'react-material-icon-svg/dist/WifiStrength_2Lock';
// export const WifiStrength_2LockIconSvg = WifiStrength_2LockIcon;

// import WifiStrength_3Icon from 'react-material-icon-svg/dist/WifiStrength_3';
// export const WifiStrength_3IconSvg = WifiStrength_3Icon;

// import WifiStrength_3AlertIcon from 'react-material-icon-svg/dist/WifiStrength_3Alert';
// export const WifiStrength_3AlertIconSvg = WifiStrength_3AlertIcon;

// import WifiStrength_3LockIcon from 'react-material-icon-svg/dist/WifiStrength_3Lock';
// export const WifiStrength_3LockIconSvg = WifiStrength_3LockIcon;

// import WifiStrength_4Icon from 'react-material-icon-svg/dist/WifiStrength_4';
// export const WifiStrength_4IconSvg = WifiStrength_4Icon;

// import WifiStrength_4AlertIcon from 'react-material-icon-svg/dist/WifiStrength_4Alert';
// export const WifiStrength_4AlertIconSvg = WifiStrength_4AlertIcon;

// import WifiStrength_4LockIcon from 'react-material-icon-svg/dist/WifiStrength_4Lock';
// export const WifiStrength_4LockIconSvg = WifiStrength_4LockIcon;

// import WiiIcon from 'react-material-icon-svg/dist/Wii';
// export const WiiIconSvg = WiiIcon;

// import WiiuIcon from 'react-material-icon-svg/dist/Wiiu';
// export const WiiuIconSvg = WiiuIcon;

// import WikipediaIcon from 'react-material-icon-svg/dist/Wikipedia';
// export const WikipediaIconSvg = WikipediaIcon;

// import WindTurbineIcon from 'react-material-icon-svg/dist/WindTurbine';
// export const WindTurbineIconSvg = WindTurbineIcon;

// import WindowCloseIcon from 'react-material-icon-svg/dist/WindowClose';
// export const WindowCloseIconSvg = WindowCloseIcon;

// import WindowClosedIcon from 'react-material-icon-svg/dist/WindowClosed';
// export const WindowClosedIconSvg = WindowClosedIcon;

// import WindowMaximizeIcon from 'react-material-icon-svg/dist/WindowMaximize';
// export const WindowMaximizeIconSvg = WindowMaximizeIcon;

// import WindowMinimizeIcon from 'react-material-icon-svg/dist/WindowMinimize';
// export const WindowMinimizeIconSvg = WindowMinimizeIcon;

// import WindowOpenIcon from 'react-material-icon-svg/dist/WindowOpen';
// export const WindowOpenIconSvg = WindowOpenIcon;

// import WindowRestoreIcon from 'react-material-icon-svg/dist/WindowRestore';
// export const WindowRestoreIconSvg = WindowRestoreIcon;

// import WindowsIcon from 'react-material-icon-svg/dist/Windows';
// export const WindowsIconSvg = WindowsIcon;

// import WindowsClassicIcon from 'react-material-icon-svg/dist/WindowsClassic';
// export const WindowsClassicIconSvg = WindowsClassicIcon;

// import WiperIcon from 'react-material-icon-svg/dist/Wiper';
// export const WiperIconSvg = WiperIcon;

// import WiperWashIcon from 'react-material-icon-svg/dist/WiperWash';
// export const WiperWashIconSvg = WiperWashIcon;

// import WordpressIcon from 'react-material-icon-svg/dist/Wordpress';
// export const WordpressIconSvg = WordpressIcon;

// import WorkerIcon from 'react-material-icon-svg/dist/Worker';
// export const WorkerIconSvg = WorkerIcon;

// import WrapIcon from 'react-material-icon-svg/dist/Wrap';
// export const WrapIconSvg = WrapIcon;

// import WrapDisabledIcon from 'react-material-icon-svg/dist/WrapDisabled';
// export const WrapDisabledIconSvg = WrapDisabledIcon;

// import WrenchIcon from 'react-material-icon-svg/dist/Wrench';
// export const WrenchIconSvg = WrenchIcon;

// import WrenchOutlineIcon from 'react-material-icon-svg/dist/WrenchOutline';
// export const WrenchOutlineIconSvg = WrenchOutlineIcon;

// import WunderlistIcon from 'react-material-icon-svg/dist/Wunderlist';
// export const WunderlistIconSvg = WunderlistIcon;

// import XamarinIcon from 'react-material-icon-svg/dist/Xamarin';
// export const XamarinIconSvg = XamarinIcon;

// import XamarinOutlineIcon from 'react-material-icon-svg/dist/XamarinOutline';
// export const XamarinOutlineIconSvg = XamarinOutlineIcon;

// import XamlIcon from 'react-material-icon-svg/dist/Xaml';
// export const XamlIconSvg = XamlIcon;

// import XboxIcon from 'react-material-icon-svg/dist/Xbox';
// export const XboxIconSvg = XboxIcon;

// import XboxControllerIcon from 'react-material-icon-svg/dist/XboxController';
// export const XboxControllerIconSvg = XboxControllerIcon;

// import XboxControllerBatteryAlertIcon from 'react-material-icon-svg/dist/XboxControllerBatteryAlert';
// export const XboxControllerBatteryAlertIconSvg = XboxControllerBatteryAlertIcon;

// import XboxControllerBatteryChargingIcon from 'react-material-icon-svg/dist/XboxControllerBatteryCharging';
// export const XboxControllerBatteryChargingIconSvg = XboxControllerBatteryChargingIcon;

// import XboxControllerBatteryEmptyIcon from 'react-material-icon-svg/dist/XboxControllerBatteryEmpty';
// export const XboxControllerBatteryEmptyIconSvg = XboxControllerBatteryEmptyIcon;

// import XboxControllerBatteryFullIcon from 'react-material-icon-svg/dist/XboxControllerBatteryFull';
// export const XboxControllerBatteryFullIconSvg = XboxControllerBatteryFullIcon;

// import XboxControllerBatteryLowIcon from 'react-material-icon-svg/dist/XboxControllerBatteryLow';
// export const XboxControllerBatteryLowIconSvg = XboxControllerBatteryLowIcon;

// import XboxControllerBatteryMediumIcon from 'react-material-icon-svg/dist/XboxControllerBatteryMedium';
// export const XboxControllerBatteryMediumIconSvg = XboxControllerBatteryMediumIcon;

// import XboxControllerBatteryUnknownIcon from 'react-material-icon-svg/dist/XboxControllerBatteryUnknown';
// export const XboxControllerBatteryUnknownIconSvg = XboxControllerBatteryUnknownIcon;

// import XboxControllerMenuIcon from 'react-material-icon-svg/dist/XboxControllerMenu';
// export const XboxControllerMenuIconSvg = XboxControllerMenuIcon;

// import XboxControllerOffIcon from 'react-material-icon-svg/dist/XboxControllerOff';
// export const XboxControllerOffIconSvg = XboxControllerOffIcon;

// import XboxControllerViewIcon from 'react-material-icon-svg/dist/XboxControllerView';
// export const XboxControllerViewIconSvg = XboxControllerViewIcon;

// import XdaIcon from 'react-material-icon-svg/dist/Xda';
// export const XdaIconSvg = XdaIcon;

// import XingIcon from 'react-material-icon-svg/dist/Xing';
// export const XingIconSvg = XingIcon;

// import XingBoxIcon from 'react-material-icon-svg/dist/XingBox';
// export const XingBoxIconSvg = XingBoxIcon;

// import XingCircleIcon from 'react-material-icon-svg/dist/XingCircle';
// export const XingCircleIconSvg = XingCircleIcon;

// import XmlIcon from 'react-material-icon-svg/dist/Xml';
// export const XmlIconSvg = XmlIcon;

// import XmppIcon from 'react-material-icon-svg/dist/Xmpp';
// export const XmppIconSvg = XmppIcon;

// import YahooIcon from 'react-material-icon-svg/dist/Yahoo';
// export const YahooIconSvg = YahooIcon;

// import YammerIcon from 'react-material-icon-svg/dist/Yammer';
// export const YammerIconSvg = YammerIcon;

// import YeastIcon from 'react-material-icon-svg/dist/Yeast';
// export const YeastIconSvg = YeastIcon;

// import YelpIcon from 'react-material-icon-svg/dist/Yelp';
// export const YelpIconSvg = YelpIcon;

// import YinYangIcon from 'react-material-icon-svg/dist/YinYang';
// export const YinYangIconSvg = YinYangIcon;

// import YoutubeIcon from 'react-material-icon-svg/dist/Youtube';
// export const YoutubeIconSvg = YoutubeIcon;

// import YoutubeCreatorStudioIcon from 'react-material-icon-svg/dist/YoutubeCreatorStudio';
// export const YoutubeCreatorStudioIconSvg = YoutubeCreatorStudioIcon;

// import YoutubeGamingIcon from 'react-material-icon-svg/dist/YoutubeGaming';
// export const YoutubeGamingIconSvg = YoutubeGamingIcon;

// import YoutubeSubscriptionIcon from 'react-material-icon-svg/dist/YoutubeSubscription';
// export const YoutubeSubscriptionIconSvg = YoutubeSubscriptionIcon;

// import YoutubeTvIcon from 'react-material-icon-svg/dist/YoutubeTv';
// export const YoutubeTvIconSvg = YoutubeTvIcon;

// import ZWaveIcon from 'react-material-icon-svg/dist/ZWave';
// export const ZWaveIconSvg = ZWaveIcon;

// import ZendIcon from 'react-material-icon-svg/dist/Zend';
// export const ZendIconSvg = ZendIcon;

// import ZigbeeIcon from 'react-material-icon-svg/dist/Zigbee';
// export const ZigbeeIconSvg = ZigbeeIcon;

// import ZipBoxIcon from 'react-material-icon-svg/dist/ZipBox';
// export const ZipBoxIconSvg = ZipBoxIcon;

// import ZipDiskIcon from 'react-material-icon-svg/dist/ZipDisk';
// export const ZipDiskIconSvg = ZipDiskIcon;

// import ZodiacAquariusIcon from 'react-material-icon-svg/dist/ZodiacAquarius';
// export const ZodiacAquariusIconSvg = ZodiacAquariusIcon;

// import ZodiacAriesIcon from 'react-material-icon-svg/dist/ZodiacAries';
// export const ZodiacAriesIconSvg = ZodiacAriesIcon;

// import ZodiacCancerIcon from 'react-material-icon-svg/dist/ZodiacCancer';
// export const ZodiacCancerIconSvg = ZodiacCancerIcon;

// import ZodiacCapricornIcon from 'react-material-icon-svg/dist/ZodiacCapricorn';
// export const ZodiacCapricornIconSvg = ZodiacCapricornIcon;

// import ZodiacGeminiIcon from 'react-material-icon-svg/dist/ZodiacGemini';
// export const ZodiacGeminiIconSvg = ZodiacGeminiIcon;

// import ZodiacLeoIcon from 'react-material-icon-svg/dist/ZodiacLeo';
// export const ZodiacLeoIconSvg = ZodiacLeoIcon;

// import ZodiacLibraIcon from 'react-material-icon-svg/dist/ZodiacLibra';
// export const ZodiacLibraIconSvg = ZodiacLibraIcon;

// import ZodiacPiscesIcon from 'react-material-icon-svg/dist/ZodiacPisces';
// export const ZodiacPiscesIconSvg = ZodiacPiscesIcon;

// import ZodiacSagittariusIcon from 'react-material-icon-svg/dist/ZodiacSagittarius';
// export const ZodiacSagittariusIconSvg = ZodiacSagittariusIcon;

// import ZodiacScorpioIcon from 'react-material-icon-svg/dist/ZodiacScorpio';
// export const ZodiacScorpioIconSvg = ZodiacScorpioIcon;

// import ZodiacTaurusIcon from 'react-material-icon-svg/dist/ZodiacTaurus';
// export const ZodiacTaurusIconSvg = ZodiacTaurusIcon;

// import ZodiacVirgoIcon from 'react-material-icon-svg/dist/ZodiacVirgo';
// export const ZodiacVirgoIconSvg = ZodiacVirgoIcon;

// import filenames.txtIcon from 'react-material-icon-svg/dist/filenames.txt';
// export const filenames.txtIconSvg = filenames.txtIcon;

/* eslint-enable import/first */
