import React from 'react';

import Color from '../constants/Color';
import Headline from './Headline';
import View from './View';

export default function Header({
  title,
  right,
  left,
  color = Color.slate,
  style = {}
}) {
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'relative',
        minHeight: 48,
        padding: 8,
        borderBottom: `1px solid ${Color.warmGrey}`,
        backgroundColor: Color.white,
        ...style
      }}
    >
      <View
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Headline size={2} style={{ color }}>
          {title}
        </Headline>
      </View>
      <View style={{ zIndex: 1 }}>{left}</View>
      <View style={{ zIndex: 1 }}>{right}</View>
    </View>
  );
}
