import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../../actions';
import Button from '../../../components/Button';
import Footer from '../../../components/Footer';
import Headline from '../../../components/Headline';
import Image from '../../../components/Image';
import HorizontalList from '../../../components/List/HorizontalList';
import Paragraph from '../../../components/Paragraph';
import ResponseDialog from '../../../components/ResponseDialog';
import StepSection from '../../../components/Section/StepSection';
import Text from '../../../components/Text';
import View from '../../../components/View';
import Color from '../../../constants/Color';
import { getErrorMessage } from '../../../utils/ErrorMessage';

class Top extends React.Component {
  renderRegisterButtons() {
    return (
      <View>
        <Button
          disable={this.props.auth.isAuthLoading}
          onClick={() => {
            this.props.authWithFacebook();
          }}
          color={Color.facebookBlue}
          style={{ marginBottom: 8 }}
        >
          <View>
            <Text bold style={{ color: Color.white, fontSize: 20 }}>
              Facebookではじめる
            </Text>
          </View>
        </Button>
        <Paragraph
          style={{
            color: Color.black,
            fontSize: 10
          }}
          containerStyle={{ marginBottom: 16 }}
        >
          ※利用開始で、利用規約とプライバシーポリシーに同意したとみなします。
        </Paragraph>
        <Paragraph style={{ fontSize: 15, textAlign: 'center' }}>
          登録後、最短30分で撮影を開始！
        </Paragraph>
      </View>
    );
  }

  render() {
    return (
      <View>
        <View style={{ position: 'relative', height: 400 }}>
          <Image
            source={require('../../../assets/img/top/header.jpg')}
            style={{ height: 400, position: 'absolute' }}
          />
          <View
            style={{
              flex: 1,
              justifyContent: 'space-between',
              zIndex: 1,
              padding: 16
            }}
          >
            <Headline size={1} style={{ color: Color.white }}>
              potore
            </Headline>
            <Text
              mincho
              bold
              size={2}
              style={{
                fontSize: 20,
                color: Color.white,
                textAlign: 'right',
                padding: 8
              }}
            >
              「撮りたい」を、すぐに
            </Text>
          </View>
        </View>
        <View style={{ backgroundColor: Color.mazenta, padding: 32 }}>
          <Paragraph bold style={{ color: Color.white, textAlign: 'center' }}>
            {`potore（ポトレ）は
            あなたの「撮りたい」を叶える
            ポートレート撮影専用の
            マッチングサービスです。
            あなたはもう、撮影会まで
            待つ必要がありません。`}
          </Paragraph>
        </View>
        <View style={{ padding: '64px 16px 64px 16px' }}>
          <Headline
            size={1}
            mincho
            demibold
            style={{ marginBottom: 24, textAlign: 'center' }}
          >
            まずは、無料で登録
          </Headline>
          {this.renderRegisterButtons()}
        </View>
        <View style={{ marginBottom: 64 }}>
          <Headline
            size={1}
            mincho
            demibold
            style={{ marginBottom: 24, textAlign: 'center' }}
          >
            いつでも、どこでも
          </Headline>
          <HorizontalList
            data={[null, null, null]}
            renderItem={item => (
              <Image
                style={{ minWidth: 160, minHeight: 160, marginRight: 16 }}
              />
            )}
            style={{ marginBottom: 32 }}
          />
          <View style={{ padding: '0 16px' }}>
            <Paragraph style={{ fontSize: 15 }}>
              条件を伝えて、すぐに撮影！
            </Paragraph>
            <Paragraph style={{ color: Color.darkGrey }}>
              ※スタジオ代等は、撮影する方の負担となります。
            </Paragraph>
          </View>
        </View>
        <View style={{ marginBottom: 64 }}>
          <Headline
            size={1}
            mincho
            demibold
            style={{ marginBottom: 24, textAlign: 'center' }}
          >
            審査率10%の美しさ
          </Headline>
          <HorizontalList
            data={[null, null, null]}
            renderItem={item => (
              <View
                style={{
                  padding: '0 8px',
                  alignItems: 'center',
                  marginRight: 16,
                  marginBottom: 8
                }}
              >
                <Image
                  style={{ minWidth: 120, minHeight: 120, marginBottom: 16 }}
                />
                <Text bold>RYOKO</Text>
              </View>
            )}
            style={{ marginBottom: 32 }}
          />
          <View style={{ padding: '0 16px' }}>
            <Paragraph style={{ fontSize: 15 }} onerow>
              {`ポートレートモデルやコスプレイヤーなど、通過率`}
              <Text bold>10%</Text>
              {`の厳格な審査で、被写体の品質を維持しています。`}
            </Paragraph>
          </View>
        </View>
        <View style={{ marginBottom: 64 }}>
          <Headline
            size={1}
            mincho
            demibold
            style={{ marginBottom: 24, textAlign: 'center' }}
          >
            撮影までの流れ
          </Headline>
          <View style={{ padding: '0 16px' }}>
            <StepSection symbol={1} style={{ marginBottom: 16 }}>
              <Paragraph onerow style={{ fontSize: 15 }}>
                気になるモデルに「いいね」を送ると相手に
                <Text style={{ color: Color.mazenta }}>メッセージが届く</Text>！
              </Paragraph>
            </StepSection>
            <StepSection symbol={2} style={{ marginBottom: 16 }}>
              <Paragraph onerow style={{ fontSize: 15 }}>
                撮影の内容を提案して、
                <Text style={{ color: Color.mazenta }}>スケジュールを確定</Text>
                ！
              </Paragraph>
            </StepSection>
            <StepSection symbol={3} style={{ marginBottom: 16 }}>
              <Paragraph onerow style={{ fontSize: 15 }}>
                実際に撮影の現場に行き、
                <Text style={{ color: Color.mazenta }}>撮影スタート</Text>！
              </Paragraph>
            </StepSection>
          </View>
        </View>
        <View style={{ marginBottom: 64 }}>
          <Headline
            size={1}
            mincho
            demibold
            style={{ marginBottom: 24, textAlign: 'center' }}
          >
            被写体/モデル
          </Headline>
          <View style={{ flexDirection: 'row' }}>
            <Image style={{ flex: 1, height: 106 }} />
            <Image style={{ flex: 1, height: 106 }} />
            <Image style={{ flex: 1, height: 106 }} />
          </View>
          <View style={{ flexDirection: 'row', marginBottom: 32 }}>
            <Image style={{ flex: 1, height: 106 }} />
            <Image style={{ flex: 1, height: 106 }} />
            <Image style={{ flex: 1, height: 106 }} />
          </View>
          <View style={{ padding: '0 16px' }}>
            <Paragraph onerow style={{ fontSize: 15, marginBottom: 32 }}>
              たくさんのモデルたちが、あなたの撮影を待っています。
              <Text style={{ color: Color.mazenta }}>登録は無料</Text>
              です！今すぐ登録して、撮影を楽しみましょう！
            </Paragraph>
            {this.renderRegisterButtons()}
          </View>
        </View>
        <Footer />
        <ResponseDialog
          isLoading={this.props.auth.isAuthLoading}
          isFailed={this.props.auth.isAuthFailed}
          onComplete={() => {
            this.props.getMyselfUser(this.props.auth.uid);

            this.props.authSignInWithCustomToken();

            this.props.history.push('/user/find');
          }}
          error={this.props.auth.authError}
          onError={() => {
            const error = this.props.auth.authError;

            if (
              error.code === 'auth/invalid-email' ||
              error.code === 'auth/email-already-in-use'
            ) {
              this.setState({ emailErrorMessage: getErrorMessage(error) });
            }

            if (error.code === 'auth/weak-password') {
              this.setState({ passwordErrorMessage: getErrorMessage(error) });
            }

            this.props.recoverAuthWithProvider();
          }}
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Top);
