import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../../../../../actions';
import Button from '../../../../../../components/Button';
import TextFormField from '../../../../../../components/Form/TextFormField';
import NavigationHeader from '../../../../../../components/Navigator/NavigationHeader';
import Section from '../../../../../../components/Section/Section';
import View from '../../../../../../components/View';
import Consumer from '../../../../../../models/Consumer';

class UserMypageProfilePrEdit extends React.Component {
  state = {
    ...new Consumer().object
  };

  constructor(props) {
    super(props);

    const { myself = {} } = this.props.user;

    this.state = {
      ...new Consumer(myself || {}).object
    };
  }

  shouldComponentUpdate(nextProps) {
    if (
      this.props.user.isGetMyselfLoading &&
      !nextProps.user.isGetMyselfLoading
    ) {
      const { myself } = nextProps.user;

      this.setState({
        ...new Consumer(myself).object
      });
    }

    return true;
  }

  render() {
    if (!this.props.user.myself) {
      return null;
    }

    return (
      <View>
        <NavigationHeader title="自己紹介" goBack={this.props.history.goBack} />
        <Section
          style={{
            padding: 24
          }}
        >
          <TextFormField
            multiline
            value={this.state.pr}
            name="pr"
            direction="column"
            placeholder="自己紹介"
            onChangeText={pr => {
              this.setState({
                pr
              });
            }}
            label="自己紹介"
            style={{ marginBottom: 16 }}
          />
          <Button
            disable={this.props.user.isUpdateLoading}
            onClick={() => {
              this.props.updateMyselfUser({
                consumer: {
                  pr: this.state.pr
                }
              });
            }}
          >
            保存する
          </Button>
        </Section>
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserMypageProfilePrEdit);
