import { ScaleLoader } from 'react-spinners';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import {
  REVIEW_VALIDATION_LABELS,
  REVIEW_VALIDATION_RULES
} from '../../constants/Validations';
import { validate, validateAll } from '../../utils/Validation';
import Button from '../../components/Button';
import Color from '../../constants/Color';
import Headline from '../../components/Headline';
import Paragraph from '../../components/Paragraph';
import ReviewStar from '../../models/ReviewStar';
import Section from '../../components/Section/Section';
import SelectFormField from '../../components/Form/SelectFormField';
import Text from '../../components/Text';
import TextFormField from '../../components/Form/TextFormField';
import View from '../../components/View';
import actions from '../../actions';

class ReviewSection extends React.Component {
  state = {
    starCount: 'unselected',
    reviewText: ''
  };

  componentDidMount() {
    this.props.getReviewHistory(
      this.props.type,
      this.props.uid,
      this.props.reviewHistoryId
    );
  }

  renderReviewContent() {
    const { onSubmit = () => {} } = this.props;

    if (this.props.reviewHistory.isGetLoading) {
      return (
        <View style={{ alignItems: 'center' }}>
          <ScaleLoader color={Color.black} />
        </View>
      );
    }

    if (this.props.reviewHistory.hash[this.props.reviewHistoryId]) {
      return (
        <View>
          <Paragraph onerow>このレビューは送信済みです。</Paragraph>
        </View>
      );
    }

    return (
      <View>
        <Paragraph onerow containerStyle={{ marginBottom: 16 }}>
          撮影に対するレビューをしましょう。このレビューは、
          <Text bold>本人には表示されません。</Text>
          他のモデルは閲覧することができます。
        </Paragraph>
        <SelectFormField
          label="評価(5段階)"
          name="starCount"
          direction="column"
          items={ReviewStar.getItems(true)}
          defaultValue={this.state.starCount}
          onChangeValue={value => this.setState({ starCount: value })}
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          label="本文"
          direction="column"
          multiline
          rows={5}
          value={this.state.reviewText}
          onChange={reviewText => {
            this.setState({ reviewText });
          }}
          errorMessage={validate({
            label: REVIEW_VALIDATION_LABELS.reviewText,
            value: this.state.reviewText,
            rule: REVIEW_VALIDATION_RULES.reviewText
          })}
          style={{ marginBottom: 16 }}
        />
        <Button
          onClick={() => {
            const errorMessages = validateAll({
              labels: REVIEW_VALIDATION_LABELS,
              params: this.state,
              rules: REVIEW_VALIDATION_RULES
            });

            if (errorMessages) {
              return alert(errorMessages);
            }

            onSubmit({
              starCount: this.state.starCount,
              reviewText: this.state.reviewText
            });
          }}
        >
          送信する
        </Button>
      </View>
    );
  }

  render() {
    const { style = {} } = this.props;

    return (
      <View style={style}>
        <Headline size={3} style={{ padding: 16 }}>
          レビュー
        </Headline>
        <Section style={{ padding: 16 }}>{this.renderReviewContent()}</Section>
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReviewSection);
