import { Elements } from 'react-stripe-elements';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import CardForm from './CardForm';
import Color from '../../constants/Color';
import MaterialIcon from '../../utils/MaterialIcon';
import ModalBox from '../../components/Modal/ModalBox';
import ModalBoxHeader from '../../components/Modal/ModalBoxHeader';
import View from '../../components/View';
import actions from '../../actions';

class PaymentModalBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isClicked: false,
      cardFormError: null
    };
  }

  render() {
    return (
      <ModalBox
        header={
          <ModalBoxHeader
            title="カード登録"
            right={
              <View onClick={() => this.props.onClose()}>
                <MaterialIcon icon="close" color={Color.black} size={24} />
              </View>
            }
          />
        }
        visible={this.props.visible}
        onClose={() => this.props.onClose()}
      >
        <View style={{ padding: '0 16px 24px 16px' }}>
          <Elements>
            <CardForm
              fontSize={17}
              disable={this.state.isClicked}
              error={this.state.cardFormError}
              onClick={() => this.setState({ isClicked: true })}
              onCreateToken={payload => {
                if (payload.error) {
                  return this.setState({
                    cardFormError: payload.error,
                    isClicked: false
                  });
                }

                this.props.createPaymentInfo(payload.token.id);

                this.props.onClose();
              }}
            />
          </Elements>
        </View>
      </ModalBox>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentModalBox);
