import React from 'react';

import HorizontalList from './HorizontalList';
import Image from '../Image';
import View from '../View';

export default class GalleryList extends React.Component {
  state = {
    activeIndex: 0
  };

  render() {
    const { data = [], style = {} } = this.props;

    const image = data.length > 0 ? data[this.state.activeIndex] : null;

    return (
      <View style={style}>
        <Image
          resizeMode="cover"
          source={image}
          style={{ width: 320, height: 320, marginBottom: 4 }}
        />
        <HorizontalList
          data={data}
          renderItem={({ item, index }) => (
            <View
              onClick={() => {
                this.setState({ activeIndex: index });
              }}
            >
              <Image
                source={item}
                style={{ width: 80, height: 80, marginRight: 4 }}
                resizeMode="cover"
              />
            </View>
          )}
        />
      </View>
    );
  }
}
