import { getCollection, getTimestamp, uploadImage } from '../utils/Firebase';
import ChatMessage from '../models/ChatMessage';
import ErrorMessages from '../constants/ErrorMessages';
import * as types from '../constants/ActionTypes';
import Role from '../utils/Role';

const chatHistoryListeners = {};

// get chat histories
function fetchGetChatHistories() {
  return {
    type: types.FETCH_GET_CHAT_HISTORIES
  };
}

function fetchGetChatHistoriesSuccess(list) {
  return {
    type: types.FETCH_GET_CHAT_HISTORIES_SUCCESS,
    state: {
      list
    }
  };
}

function fetchGetChatHistoriesFailed(error) {
  return {
    type: types.FETCH_GET_CHAT_HISTORIES_FAILED,
    error
  };
}

export function recoverGetChatHistories() {
  return {
    type: types.FETCH_GET_CHAT_HISTORIES_RECOVER
  };
}

export function getChatHistories(
  chatId,
  filter = { startAfterName: 'createdAt', orderByItemName: 'createdAt' }
) {
  return (dispatch, getState) => {
    dispatch(fetchGetChatHistories());

    const { chatHistory } = getState();

    const lastIndex = chatHistory.list.length - 1;

    const id = chatHistory.list[lastIndex];

    const lastChatHistory = chatHistory.hash[id];

    let query = getCollection('chats')
      .doc(chatId)
      .collection('chatHistories')
      .orderBy(filter.orderByItemName, 'desc')
      .startAfter(lastChatHistory.createdAt)
      .limit(10);

    return query
      .get()
      .then(async ({ docs }) => {
        const list = await Promise.all(
          docs.map(async doc => {
            return await new ChatMessage({
              id: doc.id,
              ...doc.data()
            }).fromFirestore();
          })
        );

        return dispatch(fetchGetChatHistoriesSuccess(list));
      })
      .catch(error => {
        if (error) {
          return dispatch(
            fetchGetChatHistoriesFailed({
              code: error.code,
              message: ErrorMessages[error.code]
            })
          );
        }
      });
  };
}

// create chat history
function fetchCreateChatHistory() {
  return {
    type: types.FETCH_CREATE_CHAT_HISTORY
  };
}

function fetchCreateChatHistorySuccess(id, params) {
  return {
    type: types.FETCH_CREATE_CHAT_HISTORY_SUCCESS,
    state: {
      id,
      params
    }
  };
}

function fetchCreateChatHistoryFailed(error) {
  return {
    type: types.FETCH_CREATE_CHAT_HISTORY_FAILED,
    error
  };
}

export function recoverCreateChatHistory() {
  return {
    type: types.FETCH_CREATE_CHAT_HISTORY_RECOVER
  };
}

function createChatMessage(chatId, params) {
  return getCollection('chats')
    .doc(chatId)
    .collection('chatHistories')
    .add(params);
}

export function createChatHistory(role, chatId, data) {
  return (dispatch, getState) => {
    dispatch(fetchCreateChatHistory());

    const {
      auth: { uid }
    } = getState();

    let query;

    let params;

    if (data.type === 'message') {
      params = {
        senderId: uid,
        senderType: role,
        data,
        createdAt: getTimestamp()
      };

      query = createChatMessage(chatId, params);
    } else if (data.type === 'file') {
      query = uploadImage(
        `/chats/${chatId}/${Role.getCollection(role)}/${uid}/images`,
        data.file
      ).then(async imagePath => {
        params = {
          senderId: uid,
          senderType: role,
          data: {
            type: 'file',
            source: { uri: imagePath }
          },
          createdAt: getTimestamp()
        };

        return createChatMessage(chatId, params);
      });
    }

    return query
      .then(result => {
        return dispatch(fetchCreateChatHistorySuccess(result.id, params));
      })
      .catch(error => {
        return dispatch(
          fetchCreateChatHistoryFailed({
            code: error.code,
            message: ErrorMessages[error.code]
          })
        );
      });
  };
}

// subscribe chat history
function fetchAddChatHistories() {
  return {
    type: types.FETCH_ADD_CHAT_HISTORY
  };
}

function fetchAddChatHistorySuccess(id, params) {
  return {
    type: types.FETCH_ADD_CHAT_HISTORY_SUCCESS,
    state: {
      id,
      params
    }
  };
}

export function subscribeChatHistory(chatId) {
  return (dispatch, getState) => {
    dispatch(fetchAddChatHistories());

    const listenerId = chatId;

    chatHistoryListeners[listenerId] && chatHistoryListeners[listenerId]();

    chatHistoryListeners[listenerId] = getCollection('chats')
      .doc(chatId)
      .collection('chatHistories')
      .orderBy('createdAt', 'desc')
      .limit(10)
      .onSnapshot(snapshot => {
        snapshot.docChanges().forEach(async change => {
          const { doc } = change;

          if (change.type === 'added') {
            const chatMessage = await new ChatMessage({
              id: doc.id,
              ...doc.data()
            }).fromFirestore();

            return dispatch(fetchAddChatHistorySuccess(doc.id, chatMessage));
          }

          if (change.type === 'modified') {
            console.log('Modified city: ', change.doc.data());
          }

          if (change.type === 'removed') {
            console.log('Removed city: ', change.doc.data());
          }
        });
      });

    return null;
  };
}

// unsubscribe chat history
export function unsubscribeChatHistory(chatId) {
  return (dispatch, getState) => {
    const listenerId = `${chatId}`;

    chatHistoryListeners[listenerId] && chatHistoryListeners[listenerId]();

    return null;
  };
}
