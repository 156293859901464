import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import { getErrorMessage } from '../../../utils/ErrorMessage';
import Button from '../../../components/Button';
import Color from '../../../constants/Color';
import Headline from '../../../components/Headline';
import ResponseDispatcher from '../../../components/ResponseDispatcher';
import TextFormField from '../../../components/Form/TextFormField';
import View from '../../../components/View';
import actions from '../../../actions';

class ModelRegister extends React.Component {
  state = {
    code: ''
  };

  componentDidMount() {
    this.props.signOut();
  }

  render() {
    return (
      <View>
        <View style={{ padding: '64px 16px 64px 16px' }}>
          <Headline
            size={1}
            mincho
            demibold
            style={{ marginBottom: 24, textAlign: 'center' }}
          >
            モデル登録
          </Headline>
          <TextFormField
            label="コード"
            value={this.state.code}
            style={{ marginBottom: 16 }}
            onChange={code => {
              this.setState({ code });
            }}
          />
          <Button
            disable={this.props.auth.isAuthLoading}
            onClick={() => {
              this.props.authWithTwitter({ code: this.state.code });
            }}
            color={Color.twitterBlue}
            style={{ marginBottom: 16 }}
          >
            Twitterでサインイン
          </Button>
        </View>
        <ResponseDispatcher
          isLoading={this.props.auth.isAuthLoading}
          isFailed={this.props.auth.isAuthFailed}
          onComplete={() => this.props.history.push('/model/find')}
          onError={() => {
            const error = this.props.auth.authError;

            if (
              error.code === 'auth/invalid-email' ||
              error.code === 'auth/email-already-in-use'
            ) {
              this.setState({ emailErrorMessage: getErrorMessage(error) });
            }

            if (error.code === 'auth/weak-password') {
              this.setState({ passwordErrorMessage: getErrorMessage(error) });
            }

            this.props.recoverAuthWithProvider();
          }}
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModelRegister);
