export default class ReviewHistory {
  constructor(state = {}) {
    this.params = {
      id: state.id,
      reviewer: state.reviewer,
      reviewText: state.reviewText,
      starCount: state.starCount,
      createdAt: state.createdAt,
      updatedAt: state.updatedAt
    };
  }

  get object() {
    return this.params;
  }

  fromFirestore() {
    return {
      ...this.params,
      createdAt: this.params.createdAt && this.params.createdAt.toDate(),
      updatedAt: this.params.updatedAt && this.params.updatedAt.toDate()
    };
  }
}
