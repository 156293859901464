export default {
  shadow: {
    tiny: {
      boxShadow: '0px 0px 2px rgba(28,43,47,0.5)'
    },
    lightTiny: {
      boxShadow: '0px 0px 2px rgba(28,43,47,0.1)'
    },
    small: {
      boxShadow: '0px 1px 4px rgba(28,43,47,0.5)'
    },
    lightSmall: {
      boxShadow: '0px 1px 4px rgba(28,43,47,0.1)'
    },
    medium: {
      boxShadow: '0px 1px 8px rgba(28,43,47,0.5)'
    },
    lightMedium: {
      boxShadow: '0px 1px 8px rgba(28,43,47,0.1)'
    },
    large: {
      boxShadow: '0px 2px 16px rgba(28,43,47,0.5)'
    },
    xLarge: {
      boxShadow: '0px 4px 32px 8px rgba(28,43,47,0.5)'
    }
  }
};
