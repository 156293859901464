import * as types from '../constants/ActionTypes';
import {
  getCollection,
  getTimestamp,
  signInWithCustomToken,
  signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword,
  signInWithFacebook,
  signOut as firebaseSignOut
} from '../utils/Firebase';
import ActionError from '../utils/ActionError';

// facebook login
function authWithFacebookEntry() {
  return {
    type: types.AUTH_WITH_FACEBOOK_ENTRY
  };
}

function authWithFacebookSuccess({ user, credential }) {
  return {
    type: types.AUTH_WITH_FACEBOOK_SUCCESS,
    state: {
      uid: user.uid,
      info: {
        accessToken: credential.accessToken,
        secret: credential.secret
      }
    }
  };
}

function authWithFacebookFailed(error) {
  return {
    type: types.AUTH_WITH_FACEBOOK_FAILED,
    error
  };
}

export function recoverAuthWithProvider() {
  return {
    type: types.AUTH_WITH_PROVIDER_RECOVER
  };
}

export function authWithFacebook() {
  return dispatch => {
    dispatch(authWithFacebookEntry());

    let authResult;

    let facebookId;

    let uid;

    return signInWithFacebook()
      .then(result => {
        authResult = result;

        return result;
      })
      .then(async result => {
        const {
          additionalUserInfo: {
            profile: { id, email }
          }
        } = result;

        facebookId = id;

        uid = result.user.uid;

        const users = getCollection('users');

        const userRef = users.doc(result.user.uid);

        const doc = await userRef.get();

        if (!email) {
          throw new ActionError({
            code: 'auth/invalid-email',
            message: 'facebook認証からメールアドレスが読み込めませんでした。'
          });
        }

        if (doc.exists) {
          return new Promise(resolve => resolve());
        } else {
          await userRef.set({
            name: result.user.displayName,
            iconImage: {
              uri: `https://graph.facebook.com/${id}/picture?type=large`
            },
            createdAt: getTimestamp()
          });

          return getCollection('userMetadata')
            .doc(uid)
            .set({
              roles: ['consumer'],
              email
            });
        }
      })
      .then(async () => {
        const consumerRef = getCollection('consumers').doc(uid);

        const doc = await consumerRef.get();

        if (doc.exists) {
          return new Promise(resolve => resolve());
        } else {
          return consumerRef.set({
            facebookId: facebookId,
            createdAt: getTimestamp(),
            statusChangedAt: getTimestamp()
          });
        }
      })
      .then(() => {
        return dispatch(authWithFacebookSuccess(authResult));
      })
      .catch(error => {
        if (error) {
          return dispatch(authWithFacebookFailed(error));
        }
      });
  };
}

// sign in with email and password
function signInWithEmailAndPasswordEntry() {
  return {
    type: types.SIGN_IN_WITH_EMAIL_AND_PASSWORD_ENTRY
  };
}

function signInWithEmailAndPasswordSuccess(uid) {
  return {
    type: types.SIGN_IN_WITH_EMAIL_AND_PASSWORD_SUCCESS,
    state: {
      uid
    }
  };
}

function signInWithEmailAndPasswordFailed(error) {
  return {
    type: types.SIGN_IN_WITH_EMAIL_AND_PASSWORD_FAILED,
    error
  };
}

export function recoverSignInWithEmailAndPassword() {
  return {
    type: types.SIGN_IN_WITH_EMAIL_AND_PASSWORD_RECOVER
  };
}

export function signInWithEmailAndPassword({ email, password }) {
  return dispatch => {
    dispatch(signInWithEmailAndPasswordEntry());

    return firebaseSignInWithEmailAndPassword(email, password)
      .then(result => {
        const { user } = result;

        return dispatch(signInWithEmailAndPasswordSuccess(user.uid));
      })
      .catch(error => {
        if (error) {
          return dispatch(signInWithEmailAndPasswordFailed(error));
        }
      });
  };
}

// sign out
function authSignOutEntry() {
  return {
    type: types.AUTH_SIGN_OUT_ENTRY
  };
}

function authSignOutSuccess() {
  return {
    type: types.AUTH_SIGN_OUT_SUCCESS
  };
}

function authSignOutFailed(error) {
  return {
    type: types.AUTH_SIGN_OUT_FAILED,
    error
  };
}

export function recoverAuthSignOut() {
  return {
    type: types.AUTH_SIGN_OUT_RECOVER
  };
}

export function signOut() {
  return dispatch => {
    dispatch(authSignOutEntry());

    return firebaseSignOut()
      .then(() => {
        return dispatch(authSignOutSuccess());
      })
      .catch(error => {
        if (error) {
          return dispatch(authSignOutFailed(error));
        }
      });
  };
}

// sign in with custom token
function authSignInWithCustomTokenEntry() {
  return {
    type: types.AUTH_SIGN_IN_WITH_CUSTOM_TOKEN_ENTRY
  };
}

function authSignInWithCustomTokenSuccess() {
  return {
    type: types.AUTH_SIGN_IN_WITH_CUSTOM_TOKEN_SUCCESS
  };
}

function authSignInWithCustomTokenFailed(error) {
  return {
    type: types.AUTH_SIGN_IN_WITH_CUSTOM_TOKEN_FAILED,
    error
  };
}

export function recoverAuthSignInWithCustomToken() {
  return {
    type: types.AUTH_SIGN_IN_WITH_CUSTOM_TOKEN_RECOVER
  };
}

export function authSignInWithCustomToken() {
  return dispatch => {
    dispatch(authSignInWithCustomTokenEntry());

    return signInWithCustomToken(dispatch)
      .then(() => {
        return dispatch(authSignInWithCustomTokenSuccess());
      })
      .catch(error => {
        if (error) {
          return dispatch(authSignInWithCustomTokenFailed(error));
        }
      });
  };
}
