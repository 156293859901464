import { Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import actions from '../actions';

const Auth = props => {
  if (!props.auth.uid || props.auth.roles.length < 1) {
    return <Redirect to={'/'} />;
  }

  const isConsumerPage = props.location.pathname.match(/^\/user/);

  const isModelPage = props.location.pathname.match(/^\/model/);

  const isSupporterPage = props.location.pathname.match(/^\/supporter/);

  if (isModelPage && props.auth.roles.includes('model')) {
    return props.children;
  } else if (isSupporterPage && props.auth.roles.includes('supporter')) {
    return props.children;
  } else if (isConsumerPage && props.auth.roles.includes('consumer')) {
    return props.children;
  }

  return <Redirect to={'/'} />;
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Auth);
