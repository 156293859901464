import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import Color from '../../../../constants/Color';
import ConsumerTabContainer from '../../../../containers/consumer/ConsumerTabContainer';
import Header from '../../../../components/Header';
import Prefecture from '../../../../models/tag/Prefecture';
import ProfileItem from '../../../../components/List/ProfileItem';
import ProfileList from '../../../../components/List/ProfileList';
import Select from '../../../../utils/Select';
import View from '../../../../components/View';
import actions from '../../../../actions';

class UserFindIndex extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      address: (props.user.myself && props.user.myself.address) || 'unselected'
    };
  }

  initialize(props, address) {
    const query = address
      ? { where: ['prefectures', 'array-contains', address] }
      : {};

    props.getModels({
      ...query,
      orderBy: ['statusChangedAt', 'desc']
    });
  }

  componentDidMount() {
    if (this.props.user.myself) {
      this.initialize(this.props, this.props.user.myself.address);
    }
  }

  shouldComponentUpdate(nextProps) {
    if (
      nextProps.user.myself !== this.props.user.myself &&
      nextProps.user.myself
    ) {
      this.setState({ address: nextProps.user.myself.address }, () =>
        this.initialize(nextProps, nextProps.user.myself.address)
      );
    }

    return true;
  }

  render() {
    const { myself } = this.props.user;

    if (!myself) {
      return null;
    }

    return (
      <ConsumerTabContainer activeIndex={0} history={this.props.history}>
        <Header
          title={
            <View style={{ width: 120, zIndex: 1 }}>
              <Select
                name="area"
                onChange={result => {
                  this.setState({ address: result.value }, () =>
                    this.initialize(this.props, result.value)
                  );
                }}
                options={Prefecture.getItems()}
                defaultValue={Prefecture.getItems().find(
                  item => item.value === this.props.user.myself.address
                )}
              />
            </View>
          }
          style={{
            zIndex: 10,
            padding: 8,
            borderBottom: `1px solid ${Color.warmGrey}`,
            alignItems: 'center',
            position: 'relative'
          }}
        />
        <ProfileList
          data={this.props.model.list.map(id => this.props.model.hash[id])}
          renderItem={({ item, index }) => (
            <ProfileItem
              iconImage={item.iconImage}
              style={{ marginRight: index % 3 === 2 ? 0 : 1 }}
              onClick={() => {
                this.props.history.push(`/user/find/model/${item.id}`);
              }}
            />
          )}
          style={{ flex: 1 }}
        />
      </ConsumerTabContainer>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserFindIndex);
