import * as types from '../constants/ActionTypes';

const initialState = {
  list: [],
  hash: {},
  isGetLoading: false,
  isGetFailed: false,
  getError: null,
  isCreateLoading: false,
  isCreateFailed: false,
  createError: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    // get like
    case types.FETCH_GET_LIKE:
      return {
        ...state,
        list: [],
        isGetLoading: true
      };

    case types.FETCH_GET_LIKE_SUCCESS:
      return {
        ...state,
        list: [action.state.id],
        hash: {
          ...state.hash,
          [action.state.id]: action.state.params
        },
        isGetLoading: false,
        isGetFailed: false
      };

    case types.FETCH_GET_LIKE_FAILED:
      return {
        ...state,
        isGetLoading: false,
        isGetFailed: true,
        getError: action.error
      };

    case types.FETCH_GET_LIKE_RECOVER:
      return {
        ...state,
        isGetFailed: false
      };

    // create like
    case types.FETCH_CREATE_LIKE:
      return {
        ...state,
        isCreateLoading: true
      };

    case types.FETCH_CREATE_LIKE_SUCCESS:
      return {
        ...state,
        list: [action.state.id, ...state.list],
        hash: {
          ...state.hash,
          [action.state.id]: action.state.params
        },
        isCreateLoading: false,
        isCreateFailed: false
      };

    case types.FETCH_CREATE_LIKE_FAILED:
      return {
        ...state,
        isCreateLoading: false,
        isCreateFailed: true,
        createError: action.error
      };

    case types.FETCH_CREATE_LIKE_RECOVER:
      return {
        ...state,
        isCreateFailed: false
      };

    default:
      return state;
  }
};
