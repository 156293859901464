export default class Costume {
  static getItems(withDefault = true) {
    const items = [
      { value: 'private', label: '私服OK' },
      { value: 'cosplay', label: 'コスプレOK' },
      { value: 'swimwear', label: '水着OK' },
      { value: 'studio', label: 'スタジオOK' },
      { value: 'room', label: '個室OK' }
    ];

    if (withDefault) {
      items.unshift({
        label: '未選択',
        value: 'unselected',
        isDefault: true
      });
    }

    return items;
  }

  static getLabel(value) {
    return Costume.getItems().find(item => item.value === value).label;
  }
}
