import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import Color from '../../constants/Color';
import ConsumerPreview from '../../components/Consumer/ConsumerPreview';
import Label from '../../components/Form/Label';
import ReviewHistoryItem from '../../components/ReviewHistory/ReviewHistoryItem';
import ReviewHistoryList from '../../components/ReviewHistory/ReviewHistoryList';
import View from '../../components/View';
import actions from '../../actions';

class ConsumerDetail extends React.Component {
  componentDidMount() {
    this.props.getReviewHistories('consumer', this.props.data.id);
  }

  render() {
    const data = this.props.reviewHistory.list.map(
      id => this.props.reviewHistory.hash[id]
    );

    return (
      <View style={{ backgroundColor: Color.white }}>
        <ConsumerPreview data={this.props.data} />
        <View
          style={{
            padding: '0 16px 64px 16px'
          }}
        >
          <Label style={{ marginBottom: 24, marginTop: 32 }}>レビュー</Label>
          <ReviewHistoryList
            data={data}
            renderItem={({ item }) => {
              return (
                <ReviewHistoryItem {...item} style={{ marginBottom: 16 }} />
              );
            }}
          />
        </View>
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsumerDetail);
