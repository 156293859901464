import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import CardOuterTitle from '../../components/Card/CardOuterTitle';
import Color from '../../constants/Color';
import ScrollToTop from '../../components/ScrollToTop';
import Text from '../../components/Text';
import Top from './top';
import View from '../../components/View';
import actions from '../../actions';

// function WaitingComponent(Component) {
//   return props => (
//     <React.Suspense fallback={<div>Loading...</div>}>
//       <Component {...props} />
//     </React.Suspense>
//   );
// }

class Routes extends React.Component {
  render() {
    if (this.props.config.hash.isMaintenance) {
      return (
        <View style={{ padding: 8 }}>
          <CardOuterTitle>メンテナンス中</CardOuterTitle>
          <Text style={{ color: Color.white }}>
            しばらく経ってからアクセスをお願いします。
          </Text>
        </View>
      );
    }

    return (
      <BrowserRouter>
        <ScrollToTop>
          <div>
            <Switch>
              <Route exact path="/" component={Top} />
            </Switch>
          </div>
        </ScrollToTop>
      </BrowserRouter>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Routes);
