import { getHash } from '../utils/Reducer';
import * as types from '../constants/ActionTypes';

const initialState = {
  list: [],
  hash: {},
  isGetLoading: false,
  isGetFailed: false,
  getError: null,
  isAddLoading: false,
  isAddFailed: false,
  addError: null
};

export default (state = initialState, action) => {
  let newHash;

  let list;

  switch (action.type) {
    // get chat histories
    case types.FETCH_GET_CHAT_HISTORIES:
      return {
        ...state,
        isGetLoading: true
      };

    case types.FETCH_GET_CHAT_HISTORIES_SUCCESS:
      newHash = getHash(action.state.list, state.hash, 'id');

      list = [...state.list, ...action.state.list.map(item => item.id)].sort(
        (a, b) => {
          if (newHash[a].createdAt > newHash[b].createdAt) {
            return -1;
          } else {
            return 1;
          }
        }
      );

      return {
        ...state,
        list,
        hash: newHash,
        isGetLoading: false,
        isGetFailed: false
      };

    case types.FETCH_GET_CHAT_HISTORIES_FAILED:
      return {
        ...state,
        isGetLoading: false,
        isGetFailed: true,
        getError: action.error
      };

    case types.FETCH_GET_CHAT_HISTORIES_RECOVER:
      return {
        ...state,
        isGetFailed: false
      };

    case types.FETCH_ADD_CHAT_HISTORY:
      return {
        ...state,
        list: [],
        hash: {},
        isAddLoading: true
      };

    case types.FETCH_ADD_CHAT_HISTORY_SUCCESS:
      newHash = {
        ...state.hash,
        [action.state.id]: action.state.params
      };

      list = [...state.list, action.state.id].sort((a, b) => {
        if (newHash[a].createdAt > newHash[b].createdAt) {
          return -1;
        } else {
          return 1;
        }
      });

      return {
        ...state,
        list,
        hash: newHash,
        isAddLoading: true
      };

    default:
      return state;
  }
};
