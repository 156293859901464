import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import Button from '../../components/Button';
import Color from '../../constants/Color';
import Container from '../../components/Container';
import Paragraph from '../../components/Paragraph';
import RegisterButton from '../auth/RegisterButton';
import Style from '../../constants/Style';
import Text from '../../components/Text';
import View from '../../components/View';
import actions from '../../actions';

class PageDesktopHeader extends React.Component {
  render() {
    const { style = {} } = this.props;

    return (
      <View
        style={{
          backgroundColor: Color.white,
          ...Style.shadow.medium,
          zIndex: 10
        }}
      >
        <Container>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '16px',
              ...style
            }}
          >
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Link to="/">
                <Text
                  style={{
                    fontSize: 17,
                    fontWeight: 'bold',
                    color: Color.slate,
                    letterSpacing: 4,
                    marginRight: 40
                  }}
                >
                  VISIONS
                </Text>
              </Link>
              <Paragraph>
                クラウドファンディング「VISIONS」は、夢を叶える人々の活動を支援します
              </Paragraph>
            </View>
            {this.props.auth.uid ? (
              <Link to="/mypage">
                <Button
                  variant="outlined"
                  color={Color.pink}
                  tiny
                  shadow={false}
                  onClick={() => this.setState({ isOpenRegisterModal: true })}
                >
                  <Text
                    style={{
                      fontSize: 13,
                      fontWeight: 'bold',
                      color: Color.pink
                    }}
                  >
                    マイページ
                  </Text>
                </Button>
              </Link>
            ) : (
              <RegisterButton />
            )}
          </View>
        </Container>
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PageDesktopHeader);
