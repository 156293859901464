import { getCollection } from '../utils/Firebase';

export default class Like {
  constructor(state = {}) {
    this.params = {
      fromUser: state.fromUser || null,
      toUser: state.toUser || null
    };
  }

  get object() {
    return this.params;
  }

  fromFirestore() {
    return {
      ...this.params,
      createdAt: this.params.createdAt && this.params.createdAt.toDate(),
      updatedAt: this.params.updatedAt && this.params.updatedAt.toDate()
    };
  }

  static async get(consumerId, modelId) {
    const doc = await getCollection('likeHistories')
      .doc(consumerId)
      .collection('models')
      .doc(modelId)
      .get();

    if (!doc.exists) {
      return null;
    }

    const like = new Like({
      id: doc.id,
      ...doc.data()
    }).fromFirestore();

    return like;
  }
}
