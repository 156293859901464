import React from 'react';

import View from '../View';

export default class FileInput extends React.Component {
  state = {
    imageId: 0
  };

  render() {
    const { onChangeFile, children, onClick = () => {} } = this.props;

    return (
      <View>
        <View
          onClick={() => {
            onClick();

            this.refs.fileInput.click();
          }}
        >
          {children}
        </View>
        <input
          ref="fileInput"
          key={`file-upload-${this.state.imageId}`}
          onChange={e => {
            const reader = new FileReader();

            const file = e.target.files[0];

            const image = new Image();

            reader.onload = fileEvent => {
              image.src = reader.result;

              const data = fileEvent.target.result;

              image.src = reader.result;

              image.onload = () => {
                const info = {
                  width: image.naturalWidth,
                  height: image.naturalHeight
                };

                onChangeFile({ file, data, info });
              };
            };

            reader.readAsDataURL(file);
          }}
          style={{ display: 'none' }}
          type="file"
        />
      </View>
    );
  }
}
