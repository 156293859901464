import React from 'react';

import Color from '../../constants/Color';
import Text from '../Text';
import View from '../View';

export default class TagItem extends React.Component {
  render() {
    const {
      label,
      onClick = () => {},
      style = {},
      textStyle = {}
    } = this.props;

    return (
      <View
        style={{
          border: `1px solid ${Color.mazenta}`,
          padding: '4px 8px',
          ...style
        }}
        onClick={onClick}
      >
        <Text style={{ fontSize: 13, color: Color.mazenta, ...textStyle }}>
          {label}
        </Text>
      </View>
    );
  }
}
