import React from 'react';

import TagItem from './TagItem';
import View from '../View';

export default function TagList({ data, renderItem, style = {} }) {
  return (
    <View
      style={{
        flexDirection: 'row',
        flexWrap: 'wrap',
        ...style
      }}
    >
      {data.map((datum, index) => (
        <TagItem
          label={datum.label}
          onClick={datum.onClick}
          style={{ marginRight: 8, marginBottom: 4 }}
        />
      ))}
    </View>
  );
}
