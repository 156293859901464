import { getHash } from '../utils/Reducer';
import * as types from '../constants/ActionTypes';

const initialState = {
  list: [],
  hash: {},
  isGetLoading: false,
  isGetFailed: false,
  getError: null,
  isCreateLoading: false,
  isCreateFailed: false,
  createError: null
};

export default (state = initialState, action) => {
  let newHash;

  switch (action.type) {
    // get ateliers
    case types.FETCH_GET_CONSUMERS:
      return {
        ...state,
        list: [],
        isGetLoading: true
      };

    case types.FETCH_GET_CONSUMERS_SUCCESS:
      newHash = getHash(action.state.list, state.hash, 'id');

      return {
        ...state,
        list: action.state.list.map(item => item.id),
        hash: newHash,
        isGetLoading: false,
        isGetFailed: false
      };

    case types.FETCH_GET_CONSUMERS_FAILED:
      return {
        ...state,
        isGetLoading: false,
        isGetFailed: true,
        getError: action.error
      };

    case types.FETCH_GET_CONSUMERS_RECOVER:
      return {
        ...state,
        isGetFailed: false
      };

    // get consumer
    case types.FETCH_GET_CONSUMER:
      return {
        ...state,
        list: [],
        isGetLoading: true
      };

    case types.FETCH_GET_CONSUMER_SUCCESS:
      return {
        ...state,
        list: [action.state.id],
        hash: {
          ...state.hash,
          [action.state.id]: action.state.params
        },
        isGetLoading: false,
        isGetFailed: false
      };

    case types.FETCH_GET_CONSUMER_FAILED:
      return {
        ...state,
        isGetLoading: false,
        isGetFailed: true,
        getError: action.error
      };

    case types.FETCH_GET_CONSUMER_RECOVER:
      return {
        ...state,
        isGetFailed: false
      };

    default:
      return state;
  }
};
