import { getCollection, getDb } from '../utils/Firebase';
import ConsumerSetting from '../models/ConsumerSetting';
import ErrorMessages from '../constants/ErrorMessages';
import ModelSetting from '../models/ModelSetting';
import * as types from '../constants/ActionTypes';

// get session history
function fetchGetSetting() {
  return {
    type: types.FETCH_GET_SETTING
  };
}

function fetchGetSettingSuccess(id, params) {
  return {
    type: types.FETCH_GET_SETTING_SUCCESS,
    state: {
      id,
      params
    }
  };
}

function fetchGetSettingFailed(error) {
  return {
    type: types.FETCH_GET_SETTING_FAILED,
    error
  };
}

export function recoverGetSetting() {
  return {
    type: types.FETCH_GET_SETTING_RECOVER
  };
}

export function getSetting(type, uid) {
  return dispatch => {
    dispatch(fetchGetSetting());

    const ref = getCollection(
      type === 'consumer' ? 'consumerSettings' : 'modelSettings'
    ).doc(uid);

    return ref
      .get()
      .then(doc => {
        let params = {};

        if (type === 'consumer') {
          params = new ConsumerSetting({
            id: doc.id,
            ...doc.data()
          }).fromFirestore();
        }

        if (type === 'model') {
          params = new ModelSetting({
            id: doc.id,
            ...doc.data()
          }).fromFirestore();
        }

        return dispatch(fetchGetSettingSuccess(params.id, params));
      })
      .catch(error => {
        if (error) {
          return dispatch(
            fetchGetSettingFailed({
              code: error.code,
              message: ErrorMessages[error.code]
            })
          );
        }
      });
  };
}

// update setting
function fetchUpdateSetting() {
  return {
    type: types.FETCH_UPDATE_SETTING
  };
}

function fetchUpdateSettingSuccess(id, params) {
  return {
    type: types.FETCH_UPDATE_SETTING_SUCCESS,
    state: {
      id,
      params
    }
  };
}

function fetchUpdateSettingFailed(error) {
  return {
    type: types.FETCH_UPDATE_SETTING_FAILED,
    error
  };
}

export function recoverUpdateSetting() {
  return {
    type: types.FETCH_UPDATE_SETTING_RECOVER
  };
}

export function updateSetting(type, uid, params) {
  return (dispatch, getState) => {
    dispatch(fetchUpdateSetting());

    const ref = getCollection(
      type === 'consumer' ? 'consumerSettings' : 'modelSettings'
    ).doc(uid);

    const db = getDb();

    db.runTransaction(transaction => {
      return transaction.get(ref).then(doc => {
        let updateParams = params;

        if (doc.exists) {
          updateParams = {
            ...doc.data(),
            ...params
          };
        }

        return transaction.set(ref, updateParams);
      });
    });

    return ref
      .update(params)
      .then(() => {
        return dispatch(fetchUpdateSettingSuccess(uid, params));
      })
      .catch(error => {
        return dispatch(
          fetchUpdateSettingFailed({
            code: error.code,
            message: ErrorMessages[error.code]
          })
        );
      });
  };
}
