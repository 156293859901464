import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import Color from '../constants/Color';
import Text from './Text';
import View from '../components/View';
import actions from '../actions';

class Footer extends React.Component {
  render() {
    return (
      <View
        style={{
          backgroundColor: Color.greenBlack,
          padding: '64px 16px 40px 16px'
        }}
      >
        <View
          style={{ flexDirection: 'row', flexWrap: 'wrap', marginBottom: 32 }}
        >
          <Link
            to="/document/privacy"
            style={{ marginRight: 8, marginBottom: 8 }}
          >
            <Text
              style={{ color: Color.white, fontSize: 11, fontWeight: 'bold' }}
            >
              プライバシーポリシー
            </Text>
          </Link>
          <Link to="/document/term" style={{ marginRight: 8, marginBottom: 8 }}>
            <Text
              style={{ color: Color.white, fontSize: 11, fontWeight: 'bold' }}
            >
              利用規約
            </Text>
          </Link>
          <Link to="/document/scta" style={{ marginBottom: 8 }}>
            <Text
              style={{ color: Color.white, fontSize: 11, fontWeight: 'bold' }}
            >
              特定商取引法に基づく表記
            </Text>
          </Link>
        </View>
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Footer);
