import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../../../../actions';
import NavigationHeader from '../../../../../components/Navigator/NavigationHeader';
import View from '../../../../../components/View';
import Markdown from '../../../../../components/Markdown';
import Color from '../../../../../constants/Color';

class UserMypageOtherDetail extends React.Component {
  componentDidMount() {
    this.props.getDocumentHistories({ type: this.props.match.params.type });
  }

  render() {
    const [id] = this.props.documentHistory.list;

    const document = this.props.documentHistory.hash[id];

    if (!document) {
      return null;
    }
    console.log(document);
    return (
      <View>
        <NavigationHeader
          title={document.label}
          goBack={this.props.history.goBack}
        />
        <View
          style={{ backgroundColor: Color.white, padding: '0 24px 64px 24px' }}
        >
          <Markdown>{document.text}</Markdown>
        </View>
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserMypageOtherDetail);
