import React from 'react';

import Paragraph from '../Paragraph';
import View from '../View';

export default function NotificationHistoryList({
  data,
  renderItem,
  style = {}
}) {
  if (data.length < 1) {
    return (
      <View
        style={{
          padding: 16,
          ...style
        }}
      >
        <Paragraph>お知らせはありません。</Paragraph>
      </View>
    );
  }

  return (
    <View
      style={{
        ...style
      }}
    >
      {data.map((datum, index) => renderItem({ item: datum, index }))}
    </View>
  );
}
