import { getCollection } from '../utils/Firebase';
import ErrorMessages from '../constants/ErrorMessages';
import PointHistory from '../models/PointHistory';
import * as types from '../constants/ActionTypes';

// get pointHistories
function fetchGetPointHistories() {
  return {
    type: types.FETCH_GET_POINT_HISTORIES
  };
}

function fetchGetPointHistoriesSuccess(list) {
  return {
    type: types.FETCH_GET_POINT_HISTORIES_SUCCESS,
    state: {
      list
    }
  };
}

function fetchGetPointHistoriesFailed(error) {
  return {
    type: types.FETCH_GET_POINT_HISTORIES_FAILED,
    error
  };
}

export function recoverGetPointHistories() {
  return {
    type: types.FETCH_GET_POINT_HISTORIES_RECOVER
  };
}

export function getPointHistories() {
  return (dispatch, getState) => {
    const { auth } = getState();

    dispatch(fetchGetPointHistories());

    let query = getCollection('consumers')
      .doc(auth.uid)
      .collection('pointHistories')
      .orderBy('createdAt', 'desc')
      .limit(10);

    return query
      .get()
      .then(({ docs }) => {
        const list = docs.map(doc =>
          new PointHistory({
            id: doc.id,
            ...doc.data()
          }).fromFirestore()
        );

        return dispatch(fetchGetPointHistoriesSuccess(list));
      })
      .catch(error => {
        if (error) {
          return dispatch(
            fetchGetPointHistoriesFailed({
              code: error.code,
              message: ErrorMessages[error.code]
            })
          );
        }
      });
  };
}
