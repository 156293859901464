export const MODEL_LOGIN_VALIDATION_LABELS = {
  email: 'メールアドレス',
  password: 'パスワード'
};

export const MODEL_LOGIN_VALIDATION_RULES = {
  email: {
    matches: [
      {
        reg:
          "^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$",
        message: 'メールアドレスが正しくありません。'
      }
    ]
  },
  password: {
    minLength: 1,
    maxLength: 64
  }
};

export const APPLICATION_VALIDATION_LABELS = {
  name: 'お名前',
  email: '連絡用メールアドレス',
  twitterId: 'Twitter ID',
  instagramId: 'Instagram ID',
  url: 'ホームページURL',
  address: '居住地',
  pr: '自己PR'
};

export const APPLICATION_VALIDATION_RULES = {
  name: {
    minLength: 1,
    maxLength: 64
  },
  address: {
    validators: [
      value => {
        if (value !== 'unselected') {
          return '';
        }

        return '居住地を選択してください。';
      }
    ]
  },
  email: {
    matches: [
      {
        reg:
          "^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$",
        message: 'メールアドレスが正しくありません。'
      }
    ]
  },
  twitterId: {
    minLength: 1,
    maxLength: 64
  },
  instagramId: {
    minLength: 1,
    maxLength: 64
  },
  url: {
    minLength: 0,
    maxLength: 300
  },
  pr: {
    minLength: 0,
    maxLength: 500
  }
};

export const REVIEW_VALIDATION_LABELS = {
  starCount: '評価(5段階)',
  reviewText: '本文'
};

export const REVIEW_VALIDATION_RULES = {
  starCount: {
    validators: [
      value => {
        if (value !== 'unselected') {
          return '';
        }

        return '評価(5段階)を選択してください。';
      }
    ]
  },
  reviewText: {
    minLength: 1,
    maxLength: 200
  }
};
