export default class BrotherAndSister {
  static getItems(withDefault = true) {
    const items = [
      { value: 'first', label: '長男/長女' },
      { value: 'second', label: '次男/次女' },
      { value: 'third', label: '三男/三女' },
      { value: 'other', label: 'その他' }
    ];

    if (withDefault) {
      items.unshift({
        label: '未選択',
        value: 'unselected',
        isDefault: true
      });
    }

    return items.map(item => ({ type: 'impression', ...item }));
  }

  static getLabel(value) {
    return BrotherAndSister.getItems().find(item => item.value === value).label;
  }
}
