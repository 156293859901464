import { getCollection } from '../utils/Firebase';
import ChatIndex from '../models/ChatIndex';
import ErrorMessages from '../constants/ErrorMessages';
import User from '../models/User';
import * as types from '../constants/ActionTypes';
import Consumer from '../models/Consumer';
import Role from '../utils/Role';
import Chat from '../models/Chat';
import Model from '../models/Model';
import Supporter from '../models/Supporter';

let chatIndexListener = null;

// get chat indexes
function fetchGetChatIndexes() {
  return {
    type: types.FETCH_GET_CHAT_INDEXES
  };
}

function fetchGetChatIndexesSuccess(list) {
  return {
    type: types.FETCH_GET_CHAT_INDEXES_SUCCESS,
    state: {
      list
    }
  };
}

function fetchGetChatIndexesFailed(error) {
  return {
    type: types.FETCH_GET_CHAT_INDEXES_FAILED,
    error
  };
}

export function recoverGetChatIndexes() {
  return {
    type: types.FETCH_GET_CHAT_INDEXES_RECOVER
  };
}

export function getChatIndexes(
  type,
  filter = { orderByItemName: 'createdAt' }
) {
  return (dispatch, getState) => {
    dispatch(fetchGetChatIndexes());

    const {
      auth: { uid }
    } = getState();

    let selfCollection = type === 'consumer' ? 'consumers' : 'models';

    let query = getCollection(selfCollection)
      .doc(uid)
      .collection('chatIndexes')
      .orderBy(filter.orderByItemName, 'desc')
      .limit(10);

    return query
      .get()
      .then(async ({ docs }) => {
        const list = await Promise.all(
          docs.map(async doc => {
            const chatIndex = new ChatIndex({
              id: doc.id,
              ...doc.data()
            }).fromFirestore();

            const user = await User.get(doc.id);

            let consumer = {};

            if (type === 'model') {
              consumer = await Consumer.get(doc.id);
            }

            return {
              ...chatIndex,
              user: {
                ...user,
                ...consumer
              }
            };
          })
        );

        return dispatch(fetchGetChatIndexesSuccess(list));
      })
      .catch(error => {
        if (error) {
          return dispatch(
            fetchGetChatIndexesFailed({
              code: error.code,
              message: ErrorMessages[error.code]
            })
          );
        }
      });
  };
}

// get chat index
function fetchGetChatIndex() {
  return {
    type: types.FETCH_GET_CHAT_INDEX
  };
}

function fetchGetChatIndexSuccess(id, params) {
  return {
    type: types.FETCH_GET_CHAT_INDEX_SUCCESS,
    state: {
      id,
      params
    }
  };
}

function fetchGetChatIndexFailed(error) {
  return {
    type: types.FETCH_GET_CHAT_INDEX_FAILED,
    error
  };
}

export function recoverGetChatIndex() {
  return {
    type: types.FETCH_GET_CHAT_INDEX_RECOVER
  };
}

export function getChatIndex(type, opponentId) {
  return (dispatch, getState) => {
    dispatch(fetchGetChatIndex());

    const {
      auth: { uid }
    } = getState();

    let selfCollection = type === 'consumer' ? 'consumers' : 'models';

    let query = getCollection(selfCollection)
      .doc(uid)
      .collection('chatIndexes')
      .doc(opponentId);

    return query
      .get()
      .then(async doc => {
        const chatIndex = new ChatIndex({
          id: doc.id,
          ...doc.data()
        }).fromFirestore();

        const user = await User.get(doc.id);

        let consumer = {};

        if (type === 'model') {
          consumer = await Consumer.get(doc.id);
        }

        const params = {
          ...chatIndex,
          user: {
            ...user,
            ...consumer
          }
        };

        return dispatch(fetchGetChatIndexSuccess(doc.id, params));
      })
      .catch(error => {
        if (error) {
          return dispatch(
            fetchGetChatIndexFailed({
              code: error.code,
              message: ErrorMessages[error.code]
            })
          );
        }
      });
  };
}

// subscribe chat history
function fetchSubscribeChatIndexes() {
  return {
    type: types.FETCH_SUBSCRIBE_CHAT_INDEX
  };
}

function fetchAddChatIndexSuccess(id, params) {
  return {
    type: types.FETCH_ADD_CHAT_INDEX_SUCCESS,
    state: {
      id,
      params
    }
  };
}

function fetchModifyChatIndexSuccess(id, params) {
  return {
    type: types.FETCH_MODIFY_CHAT_INDEX_SUCCESS,
    state: {
      id,
      params
    }
  };
}

export function subscribeChatIndex(type) {
  return (dispatch, getState) => {
    dispatch(fetchSubscribeChatIndexes());

    const { auth } = getState();

    chatIndexListener && chatIndexListener();

    const collection = Role.getCollection(type);

    chatIndexListener = getCollection(collection)
      .doc(auth.uid)
      .collection('chatIndexes')
      .orderBy('createdAt', 'desc')
      .limit(10)
      .onSnapshot(snapshot => {
        snapshot.docChanges().forEach(async change => {
          const { doc } = change;

          const chatIndex = new ChatIndex({
            id: doc.id,
            ...doc.data()
          }).fromFirestore();

          const chat = await Chat.get(doc.id);

          const others = chat.members.filter(member => member.id !== auth.uid);

          const users = await Promise.all(
            others.map(async other => {
              const user = await User.get(other.id);

              let roledUser = {};

              if (other.role === 'model') {
                roledUser = await Model.get(other.id);
              } else if (other.role === 'consumer') {
                roledUser = await Consumer.get(other.id);
              } else if (other.role === 'supporter') {
                roledUser = await Supporter.get(other.id);
              }

              return {
                ...user,
                ...roledUser
              };
            })
          );

          const params = {
            ...chatIndex,
            users
          };

          if (change.type === 'added') {
            return dispatch(fetchAddChatIndexSuccess(doc.id, params));
          }

          if (change.type === 'modified') {
            return dispatch(fetchModifyChatIndexSuccess(doc.id, params));
          }

          if (change.type === 'removed') {
            console.log('Removed index: ', change.doc.data());
          }
        });
      });

    return null;
  };
}

// unsubscribe chat history
export function unsubscribeChatIndex(consumerId, modelId) {
  return (dispatch, getState) => {
    chatIndexListener && chatIndexListener();

    return null;
  };
}
