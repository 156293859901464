import { getCollection } from '../utils/Firebase';

export default class Supporter {
  constructor(state = {}) {
    this.params = {
      roles: ['supporter'],
      createdAt: state.createdAt,
      updatedAt: state.updatedAt
    };
  }

  get object() {
    return this.params;
  }

  fromFirestore() {
    return {
      ...this.params,
      createdAt: this.params.createdAt && this.params.createdAt.toDate(),
      updatedAt: this.params.updatedAt && this.params.updatedAt.toDate()
    };
  }

  static async get(id) {
    const doc = await getCollection('supporters')
      .doc(id)
      .get();

    const supporter = new Supporter({
      id: doc.id,
      ...doc.data()
    }).fromFirestore();

    return supporter;
  }
}
