import React from 'react';

import Color from '../../constants/Color';
import Text from '../Text';
import Toggle from '../../utils/Toggle';
import View from '../View';

export default function SectionToggleItem({
  title,
  defaultValue,
  onChange = () => {},
  style = {}
}) {
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 16,
        borderBottom: `1px solid ${Color.middleGrey}`,
        ...style
      }}
    >
      <Text style={{ color: Color.black, fontSize: 15, minWidth: 100 }}>
        {title}
      </Text>
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          overflow: 'hidden',
          alignItems: 'center'
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            flex: 1,
            overflow: 'hidden',
            justifyContent: 'flex-end'
          }}
        >
          <Toggle
            defaultChecked={defaultValue}
            icons={false}
            onChange={event => {
              onChange(event.target.checked);
            }}
          />
        </View>
      </View>
    </View>
  );
}
