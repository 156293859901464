import React from 'react';

import Image from '../Image';
import View from '../View';

export default function ProfileItem({
  iconImage,
  onClick = () => {},
  style = {}
}) {
  return (
    <View onClick={onClick} style={{ width: 106, height: 106, ...style }}>
      <Image
        resizeMode="cover"
        source={iconImage}
        style={{ width: '100%', height: '100%' }}
      />
    </View>
  );
}
