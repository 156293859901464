import { ScaleLoader } from 'react-spinners';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React, { Component } from 'react';

import Color from '../constants/Color';
import Firebase, {
  getRealtimeDatabase,
  getRealtimeDatabaseTimestamp
} from '../utils/Firebase';
import ResponseDialog from '../components/ResponseDialog';
import View from '../components/View';
import actions from '../actions';

class FoundationContainer extends Component {
  onlineListener = null;

  updateWindowDimensions() {
    this.props.updateWindowSize({
      width: window.innerWidth,
      height: window.innerHeight
    });
  }

  shouldComponentUpdate(nextProps) {
    if (
      nextProps.user.myself !== this.props.user.myself &&
      nextProps.user.myself &&
      this.onlineListener == null
    ) {
      const userRef = getRealtimeDatabase().ref(
        `connections/${nextProps.auth.uid}`
      );

      this.onlineListener = getRealtimeDatabase()
        .ref(`.info/connected`)
        .on('value', snapshot => {
          userRef
            .onDisconnect()
            .set({
              status: 'offline',
              roles: nextProps.auth.roles,
              changedAt: getRealtimeDatabaseTimestamp()
            })
            .then(() => {
              userRef.set({
                status: 'online',
                roles: nextProps.auth.roles,
                changedAt: getRealtimeDatabaseTimestamp()
              });
            });
        });
    }

    return true;
  }

  componentDidMount() {
    this.updateWindowDimensions();

    window.addEventListener('resize', this.updateWindowDimensions.bind(this));

    Firebase.auth().onAuthStateChanged(user => {
      if (user) {
        if (!this.props.auth.uid) {
          return;
        }
        // User is signed in.
        this.props.getMyselfUser(user.uid);

        this.props.authSignInWithCustomToken();
        // this.props.login();
      } else {
        // No user is signed in.
        this.props.signOut();
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener(
      'resize',
      this.updateWindowDimensions.bind(this)
    );
  }

  getNotification(condition, index) {
    const key = index;

    return <ResponseDialog key={key} {...condition} style={{ zIndex: 200 }} />;
  }

  renderNotifications() {
    const conditions = [
      {
        isLoading: this.props.paymentInfo.isCreateLoading,
        isFailed: this.props.paymentInfo.isCreateFailed,
        error: this.props.paymentInfo.createError,
        completeMessage: 'カード登録が完了しました。',
        onComplete: () => {
          this.props.getMyselfUser(this.props.auth.uid);
        },
        onError: () => {
          this.props.recoverCreatePaymentInfo();
        }
      },
      {
        isLoading: this.props.shop.isPurchaseLoading,
        isFailed: this.props.shop.isPurchaseFailed,
        error: this.props.shop.purchaseError,
        completeMessage: 'ポイントを購入しました。',
        onComplete: () => {
          this.props.getMyselfUser(this.props.auth.uid);
        },
        onError: () => {
          this.props.recoverPurchaseShop();
        }
      },
      {
        isLoading: this.props.paymentInfo.isDeleteLoading,
        isFailed: this.props.paymentInfo.isDeleteFailed,
        error: this.props.paymentInfo.deleteError,
        completeMessage: 'カード情報を削除しました。',
        onComplete: () => {
          this.props.getMyselfUser(this.props.auth.uid);
        },
        onError: () => {
          this.props.recoverDeletePaymentInfo();
        }
      }
    ];

    return conditions.map((condition, index) =>
      this.getNotification(condition, index)
    );
  }

  renderLoadingOverlay() {
    const { paymentInfo, shop, auth, user } = this.props;

    if (
      !paymentInfo.isCreateLoading &&
      !paymentInfo.isDeleteLoading &&
      !shop.isPurchaseLoading &&
      !auth.isSignUpLoading &&
      !auth.isSignInLoading &&
      !user.isGetMyselfLoading
    ) {
      return null;
    }

    return (
      <View
        style={{
          display: 'flex',
          top: 0,
          left: 0,
          position: 'fixed',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          backgroundColor: Color.printBlack,
          zIndex: 100
        }}
      >
        <ScaleLoader color={Color.peach} />
      </View>
    );
  }

  render() {
    return (
      <View style={{ backgroundColor: Color.red }}>
        <View
          style={{
            pointerEvents: 'none',
            position: 'fixed',
            width: '100%',
            height: '100%',
            zIndex: 200
          }}
        >
          {this.renderNotifications()}
        </View>
        {this.renderLoadingOverlay()}
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FoundationContainer);
