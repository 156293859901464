import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../../../../actions';
import Button from '../../../../../components/Button';
import ModelPreview from '../../../../../components/Model/ModelPreview';
import NavigationHeader from '../../../../../components/Navigator/NavigationHeader';
import View from '../../../../../components/View';
import Color from '../../../../../constants/Color';

class UserFindModelDetail extends React.Component {
  componentDidMount() {
    this.props.getModel(this.props.match.params.modelId);

    this.props.getLike(this.props.auth.uid, this.props.match.params.modelId);
  }

  renderLikeButton() {
    if (this.props.like.isGetLoading) {
      return null;
    }

    const like = this.props.like.hash[
      `${this.props.auth.uid}_${this.props.match.params.modelId}`
    ];

    if (like) {
      return (
        <View
          style={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: Color.white
          }}
        >
          <Button color={Color.celadonBlue} onClick={() => {}}>
            メッセージを送る
          </Button>
        </View>
      );
    }

    return (
      <View
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: Color.white
        }}
      >
        <Button
          disable={this.props.like.isCreateLoading}
          onClick={() => {
            this.props.upsertLike(
              this.props.auth.uid,
              this.props.match.params.modelId,
              {
                fromUser: {
                  id: this.props.auth.uid,
                  role: 'consumer'
                },
                toUser: {
                  id: this.props.match.params.modelId,
                  role: 'model'
                }
              }
            );
          }}
        >
          いいねを送る
        </Button>
      </View>
    );
  }

  render() {
    const model = this.props.model.hash[this.props.match.params.modelId];

    if (!model) {
      return null;
    }

    return (
      <View style={{ backgroundColor: Color.white }}>
        <NavigationHeader user={model} goBack={this.props.history.goBack} />
        <ModelPreview
          data={model}
          style={{
            paddingBottom: 64
          }}
        />
        {this.renderLikeButton()}
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserFindModelDetail);
