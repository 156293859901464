import React from 'react';

import Color from '../../constants/Color';
import Paragraph from '../Paragraph';
import View from '../View';

export default function MessagePlate({
  children,
  bold = false,
  fontSize = 11,
  color = Color.smokeWhite,
  invert = false,
  style = {}
}) {
  const textColor = invert ? Color.white : Color.black;

  const content =
    typeof children === 'string' ? (
      <Paragraph
        bold={bold}
        style={{ color: textColor, fontSize, wordBreak: 'break-all' }}
      >
        {children}
      </Paragraph>
    ) : (
      children
    );

  return (
    <View
      style={{
        padding: '8px 16px',
        borderRadius: 8,
        backgroundColor: color,
        ...style
      }}
    >
      {content}
    </View>
  );
}
