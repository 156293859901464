import React from 'react';

import Text from './Text';
import View from './View';

export default function StatusLabel({
  status,
  config = {},
  style = {},
  textStyle = {}
}) {
  return (
    <View
      style={{
        borderRadius: 16,
        border: `1px solid ${config[status].color}`,
        alignItems: 'center',
        padding: '2px 16px',
        ...style
      }}
    >
      <Text
        bold
        style={{
          color: config[status].color,
          fontSize: 13,
          ...textStyle
        }}
      >
        {config[status].label}
      </Text>
    </View>
  );
}
