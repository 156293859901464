export default class DocumentHistory {
  constructor(state = {}) {
    this.params = {
      id: state.id,
      label: state.label || '',
      type: state.type || 'term',
      no: state.no || '',
      text: state.text || ''
    };
  }

  get object() {
    return this.params;
  }

  fromFirestore() {
    return {
      ...this.params,
      createdAt: this.params.createdAt && this.params.createdAt.toDate(),
      updatedAt: this.params.updatedAt && this.params.updatedAt.toDate()
    };
  }
}
