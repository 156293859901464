import React from 'react';
import moment from 'moment';

import { getChatMessage } from '../../utils/ChatMessage';
import Color from '../../constants/Color';
import Headline from '../Headline';
import Image from '../Image';
import MessagePlate from './MessagePlate';
import Paragraph from '../Paragraph';
import PhotoPermission from '../../models/PhotoPermission';
import Text from '../Text';
import UserIcon from '../Icons/UserIcon';
import View from '../View';

function renderContent({ color, invert, opponent, data, selfType }) {
  if (data.type === 'file') {
    return (
      <Image source={data.source} style={{ maxWidth: 200, height: 'auto' }} />
    );
  }

  return (
    <MessagePlate color={color} invert={invert} style={{ maxWidth: 170 }}>
      {getChatMessage(opponent, data, selfType)}
    </MessagePlate>
  );
}

function renderRightSide({
  isSelf,
  selfType,
  data,
  senderId,
  senderType,
  createdAt,
  style = {},
  user,
  opponent
}) {
  const invert = isSelf || data.type === 'like';

  let color = Color.smokeWhite;

  if (data.type === 'like') {
    color = Color.mazenta;
  } else if (isSelf) {
    color = Color.darkCeladonBlue;
  }

  return (
    <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
      <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
        <Text style={{ color: Color.darkGrey, fontSize: 11, marginRight: 8 }}>
          {moment(createdAt).format('HH:mm')}
        </Text>
        {renderContent({ color, invert, opponent, data, selfType })}
      </View>
    </View>
  );
}

function renderLeftSide({
  isSelf,
  selfType,
  data,
  senderId,
  senderType,
  createdAt,
  onClickIcon = () => {},
  style = {},
  user,
  opponent
}) {
  const invert = isSelf || data.type === 'like';

  let color = Color.smokeWhite;

  if (data.type === 'like') {
    color = Color.mazenta;
  } else if (isSelf) {
    color = Color.darkCeladonBlue;
  }

  return (
    <View style={{ flexDirection: 'row' }}>
      <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
        <UserIcon
          source={opponent.iconImage}
          size={32}
          style={{ marginRight: 8 }}
          onClick={onClickIcon}
        />
        {renderContent({ color, invert, opponent, data, selfType })}
        <Text style={{ color: Color.darkGrey, fontSize: 11, marginLeft: 8 }}>
          {moment(createdAt).format('HH:mm')}
        </Text>
      </View>
    </View>
  );
}

export default function ChatMessageItem({ isSelf, style = {}, ...rest }) {
  const { data, senderId, createdAt } = rest;

  if (senderId === 'system') {
    return (
      <View style={{ alignItems: 'center', ...style }}>
        <MessagePlate
          color={Color.smokeWhite}
          style={{
            alignItems: 'center',
            maxWidth: 200,
            marginRight: 8
          }}
        >
          <Text
            style={{
              color: Color.darkGrey,
              fontSize: 11,
              textAlign: 'center',
              marginBottom: 4
            }}
          >
            {data.message}
          </Text>
        </MessagePlate>
      </View>
    );
  } else if (data.type === 'reserve') {
    return (
      <View style={{ alignItems: 'center', ...style }}>
        <MessagePlate
          color={Color.smokeWhite}
          style={{
            alignItems: 'center',
            maxWidth: 200,
            marginRight: 8
          }}
        >
          <Text
            style={{
              color: Color.darkGrey,
              fontSize: 11,
              textAlign: 'center',
              marginBottom: 4
            }}
          >
            {moment(createdAt).format('HH:mm')}
          </Text>
          <Headline
            size={3}
            style={{
              textAlign: 'center',
              color: Color.mazenta,
              marginBottom: 8
            }}
          >
            撮影のご提案
          </Headline>
          <View>
            <Paragraph containerStyle={{ marginBottom: 4 }}>
              {`日時：${moment(data.reserve.startAt.toDate()).format(
                'MM月DD日 HH:mm〜'
              )}
            時間：${data.reserve.hours}時間
            消費：${data.reserve.price * data.reserve.hours * 2 +
              data.reserve.optionPrice}P
              ${
                data.reserve.optionPrice > 0
                  ? `${data.reserve.optionName}オプション(${data.reserve.optionPrice}P)`
                  : ''
              }
            (延長：${(data.reserve.price / 2) * 1.3}P/15分)`}
            </Paragraph>
            <Paragraph style={{ color: Color.celadonBlue }}>
              {PhotoPermission.getKeys()
                .filter(key => !data.reserve[key])
                .map(key => `${PhotoPermission.getLabel(key)}NG`)
                .join(', ')}
            </Paragraph>
          </View>
        </MessagePlate>
      </View>
    );
  } else if (data.type === 'reserveConfirmed') {
    return (
      <View style={{ alignItems: 'center', ...style }}>
        <MessagePlate
          color={Color.smokeWhite}
          style={{
            textAlign: 'center',
            alignItems: 'center',
            maxWidth: 170,
            marginRight: 8
          }}
        >
          <Text style={{ color: Color.darkGrey, fontSize: 11 }}>
            {moment(createdAt).format('HH:mm')}
          </Text>
          <Text style={{ fontSize: 11 }}>日程が確定しました。</Text>
        </MessagePlate>
      </View>
    );
  } else if (data.type === 'reserveJoined') {
    return (
      <View style={{ alignItems: 'center', ...style }}>
        <MessagePlate
          color={Color.smokeWhite}
          style={{
            textAlign: 'center',
            alignItems: 'center',
            maxWidth: 170,
            marginRight: 8
          }}
        >
          <Text style={{ color: Color.darkGrey, fontSize: 11 }}>
            {moment(createdAt).format('HH:mm')}
          </Text>
          <Text style={{ fontSize: 11 }}>撮影を開始しました。</Text>
        </MessagePlate>
      </View>
    );
  } else if (data.type === 'reserveDismissed') {
    return (
      <View style={{ alignItems: 'center', ...style }}>
        <MessagePlate
          color={Color.smokeWhite}
          style={{
            textAlign: 'center',
            alignItems: 'center',
            maxWidth: 170,
            marginRight: 8
          }}
        >
          <Text style={{ color: Color.darkGrey, fontSize: 11 }}>
            {moment(createdAt).format('HH:mm')}
          </Text>
          <Text style={{ fontSize: 11 }}>撮影が完了しました。</Text>
        </MessagePlate>
      </View>
    );
  } else if (data.type === 'reserveCanceled') {
    return (
      <View style={{ alignItems: 'center', ...style }}>
        <MessagePlate
          color={Color.smokeWhite}
          style={{
            textAlign: 'center',
            alignItems: 'center',
            maxWidth: 170,
            marginRight: 8
          }}
        >
          <Text style={{ color: Color.darkGrey, fontSize: 11 }}>
            {moment(createdAt).format('HH:mm')}
          </Text>
          <Text style={{ fontSize: 11 }}>撮影がキャンセルされました。</Text>
        </MessagePlate>
      </View>
    );
  } else if (data.type === 'reservePenaltyCanceled') {
    return (
      <View style={{ alignItems: 'center', ...style }}>
        <MessagePlate
          color={Color.smokeWhite}
          style={{
            textAlign: 'center',
            alignItems: 'center',
            maxWidth: 170,
            marginRight: 8
          }}
        >
          <Text style={{ color: Color.darkGrey, fontSize: 11 }}>
            {moment(createdAt).format('HH:mm')}
          </Text>
          <Text style={{ fontSize: 11 }}>
            撮影がキャンセルされました。前日キャンセルによりポイントが決済されました。
          </Text>
        </MessagePlate>
      </View>
    );
  }

  if (isSelf) {
    return <View style={style}>{renderRightSide({ isSelf, ...rest })}</View>;
  }

  return <View style={style}>{renderLeftSide({ isSelf, ...rest })}</View>;
}
