import React from 'react';

import Color from '../../constants/Color';
import Image from '../Image';
import MaterialIcon from '../../utils/MaterialIcon';
import View from '../View';

export default function UserIcon({ source, size = 24, style = {}, ...rest }) {
  if (!source) {
    return (
      <View
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: size / 2,
          width: size,
          height: size,
          backgroundColor: Color.lightGrey,
          ...style
        }}
        {...rest}
      >
        <MaterialIcon icon="person" size={size * 0.8} />
      </View>
    );
  }

  return (
    <View
      style={{ borderRadius: size / 2, width: size, height: size, ...style }}
      {...rest}
    >
      <Image
        source={source}
        resizeMode="cover"
        style={{ borderRadius: size / 2, width: size, height: size }}
        {...rest}
      />
    </View>
  );
}
