export default class ReviewStar {
  static getItems(withDefault = false) {
    const items = [
      { label: '5(とても良い)', value: 5 },
      { label: '4(良い)', value: 4 },
      { label: '3(ふつう)', value: 3 },
      { label: '2(悪い)', value: 2 },
      { label: '1(とても悪い)', value: 1 }
    ];

    if (withDefault) {
      items.unshift({ label: '未選択', value: 'unselected', isDefault: true });
    }

    return items;
  }

  static getLabel(value) {
    return ReviewStar.getItems().find(item => item.value === value).label;
  }
}
