import React from 'react';

import Color from '../../constants/Color';
import Style from '../../constants/Style';
import View from '../View';

export default function TabNavigator(props) {
  const { children, style = {} } = props;

  return (
    <View
      style={{
        borderTop: `1px solid ${Color.warmGrey}`,
        backgroundColor: Color.paleWhite,
        flexDirection: 'row',
        ...Style.shadow.small,
        ...style
      }}
    >
      {children}
    </View>
  );
}
