import React from 'react';

import { getInvertColor } from '../utils/ColorJs';
import ActivityIndicator from '../utils/ActivityIndicator';
import Color from '../constants/Color';
import Style from '../constants/Style';
import Text from './Text';

function getBackgroundColor({ variant, disable, color = Color.mazenta }) {
  if (disable) {
    return Color.grey;
  }

  switch (variant) {
    case 'contained':
      return color;
    case 'flat':
    case 'outlined':
      return Color.white;
    case 'text':
    default:
      return 'transparent';
  }
}

function renderContent(
  loading,
  variant,
  color = Color.mazenta,
  textStyle,
  children
) {
  if (loading) {
    return <ActivityIndicator color={Color.white} size={10} />;
  }

  const textColor =
    variant === 'flat' || variant === 'text' || variant === 'outlined'
      ? Color.mazenta
      : getInvertColor(color || Color.mazenta, true);

  return typeof children === 'string' ? (
    <Text
      style={{
        color: textColor,
        fontSize: 18,
        fontWeight: 'bold',
        ...textStyle
      }}
    >
      {children}
    </Text>
  ) : (
    children
  );
}

export default function Button(props) {
  const {
    children,
    variant = 'contained',
    disable = false,
    loading = false,
    color = null,
    mini = false,
    tiny = false,
    shadow = false,
    textStyle = {},
    style = {},
    onClick = () => {}
  } = props;

  let padding = '16px 32px 16px 32px';

  let smallTextStyle = {};

  if (mini) {
    padding = '12px 24px 12px 24px';

    smallTextStyle = { fontSize: 15 };
  }

  if (tiny) {
    padding = '4px 16px 4px 16px';

    smallTextStyle = { fontSize: 15 };
  }

  const content = renderContent(
    loading,
    variant,
    color,
    {
      ...textStyle,
      ...smallTextStyle
    },
    children
  );

  const backgroundColor = getBackgroundColor({ variant, disable, color });

  let buttonStyle = style;

  if (variant === 'contained') {
    buttonStyle = {
      ...style
    };
  }

  let shadowStyle = shadow && variant !== 'text' ? Style.shadow.medium : {};

  return (
    <button
      className={disable || color ? '' : variant}
      style={{
        backgroundColor,
        borderWidth: variant === 'outlined' ? 1 : 0,
        borderColor: color || Color.mazenta,
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        padding,
        ...shadowStyle,
        ...buttonStyle
      }}
      onClick={event => {
        if (disable) {
          return;
        }

        onClick(event);
      }}
    >
      {content}
    </button>
  );
}
