import React from 'react';

import {
  CalendarTodayIconSvg,
  ImageIconSvg,
  WalletGiftcardIconSvg
} from '../../utils/MaterialIconSvg';
import Button from '../Button';
import Color from '../../constants/Color';
import FileInput from '../Form/FileInput';
import Style from '../../constants/Style';
import TextArea from '../Form/TextArea';
import View from '../View';

export default class ChatMessageFooter extends React.Component {
  render() {
    const { schedule = true } = this.props;

    return (
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          ...Style.shadow.small
        }}
      >
        {schedule && (
          <View
            onClick={() => {
              this.props.onClickSchedule();
            }}
            style={{ padding: 16 }}
          >
            <CalendarTodayIconSvg
              fill={Color.darkGrey}
              width={24}
              height={24}
              viewBox="0 0 24 24"
            />
          </View>
        )}
        <View style={{ flex: 1, padding: '8px 8px' }}>
          <TextArea
            value={this.props.message}
            rows={Math.min(5, this.props.message.split('\n').length)}
            onChange={text => this.props.onChangeMessage(text)}
            placeholder="メッセージを入力"
            style={{ flex: 1 }}
          />
        </View>
        <View style={{ width: 80 }}>
          {this.props.message ? (
            <Button
              variant="text"
              tiny
              onClick={() => {
                this.props.onSubmitMessage();
              }}
            >
              送信
            </Button>
          ) : (
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <FileInput
                onChangeFile={file => {
                  this.props.onUploadFile(file);
                }}
                ref="fileInput"
              >
                <View style={{ padding: '16px 8px' }}>
                  <ImageIconSvg
                    fill={Color.darkGrey}
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                  />
                </View>
              </FileInput>
              <View style={{ padding: '16px 8px' }}>
                <WalletGiftcardIconSvg
                  fill={Color.darkGrey}
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                />
              </View>
            </View>
          )}
        </View>
      </View>
    );
  }
}
