import React from 'react';
import View from '../View';

export default class GalleryItem extends React.Component {
  render() {
    const { source, onClick = () => {}, style = {} } = this.props;

    return (
      <View
        style={{
          backgroundImage: `url(${source.data || source.uri})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          ...style
        }}
        onClick={onClick}
      />
    );
  }
}
