import { getCollection } from '../utils/Firebase';
import Consumer from '../models/Consumer';
import ErrorMessages from '../constants/ErrorMessages';
import User from '../models/User';
import * as types from '../constants/ActionTypes';

// get documentHistories
function fetchGetConsumers() {
  return {
    type: types.FETCH_GET_CONSUMERS
  };
}

function fetchGetConsumersSuccess(list) {
  return {
    type: types.FETCH_GET_CONSUMERS_SUCCESS,
    state: {
      list
    }
  };
}

function fetchGetConsumersFailed(error) {
  return {
    type: types.FETCH_GET_CONSUMERS_FAILED,
    error
  };
}

export function recoverGetConsumers() {
  return {
    type: types.FETCH_GET_CONSUMERS_RECOVER
  };
}

export function getConsumers(
  filter = { where: null, orderBy: ['createdAt', 'desc'] }
) {
  return dispatch => {
    dispatch(fetchGetConsumers());

    let query = getCollection('consumers');

    if (filter.where && filter.where[2] !== 'unselected') {
      query = query.where(...filter.where);
    }

    return query
      .orderBy(...filter.orderBy)
      .limit(10)
      .get()
      .then(async ({ docs }) => {
        const list = await Promise.all(
          docs.map(async doc => {
            const consumer = new Consumer({
              id: doc.id,
              ...doc.data()
            }).fromFirestore();

            const user = await User.get(doc.id);

            return {
              ...user,
              ...consumer
            };
          })
        );

        return dispatch(fetchGetConsumersSuccess(list));
      })
      .catch(error => {
        if (error) {
          return dispatch(
            fetchGetConsumersFailed({
              code: error.code,
              message: ErrorMessages[error.code]
            })
          );
        }
      });
  };
}

// get consumer
function fetchGetConsumer() {
  return {
    type: types.FETCH_GET_CONSUMER
  };
}

function fetchGetConsumerSuccess(id, params) {
  return {
    type: types.FETCH_GET_CONSUMER_SUCCESS,
    state: {
      id,
      params
    }
  };
}

function fetchGetConsumerFailed(error) {
  return {
    type: types.FETCH_GET_CONSUMER_FAILED,
    error
  };
}

export function recoverGetConsumer() {
  return {
    type: types.FETCH_GET_CONSUMER_RECOVER
  };
}

export function getConsumer(id) {
  return dispatch => {
    dispatch(fetchGetConsumer());

    let query = getCollection('consumers').doc(id);

    return query
      .get()
      .then(async doc => {
        const consumer = new Consumer({
          id: doc.id,
          ...doc.data()
        }).fromFirestore();

        const user = await User.get(doc.id);

        const params = {
          ...user,
          ...consumer
        };

        return dispatch(fetchGetConsumerSuccess(id, params));
      })
      .catch(error => {
        if (error) {
          return dispatch(
            fetchGetConsumerFailed({
              code: error.code,
              message: ErrorMessages[error.code]
            })
          );
        }
      });
  };
}
