import React from 'react';
import moment from 'moment';

import Color from '../../constants/Color';
import MessagePlate from '../Chat/MessagePlate';
import Text from '../Text';
import UserIcon from '../Icons/UserIcon';
import View from '../View';

export default function ReviewHistoryItem({
  reviewer,
  reviewText,
  reviewedAt,
  style = {}
}) {
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'flex-end',
        ...style
      }}
    >
      <UserIcon
        source={reviewer.iconImage}
        size={32}
        style={{ marginRight: 8 }}
      />
      <MessagePlate
        color={Color.mazenta}
        invert
        style={{ maxWidth: 170, marginRight: 8 }}
      >
        {reviewText}
      </MessagePlate>
      <Text style={{ color: Color.darkGrey, fontSize: 11 }}>
        {moment(reviewedAt).format('MM月DD日')}
      </Text>
    </View>
  );
}
