import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../../../../actions';
import NavigationHeader from '../../../../../components/Navigator/NavigationHeader';
import NotificationHistoryItem from '../../../../../components/NotificationHistory/NotificationHistoryItem';
import NotificationHistoryList from '../../../../../components/NotificationHistory/NotificationHistoryList';
import View from '../../../../../components/View';

class UserMypageNotificationIndex extends React.Component {
  componentDidMount() {
    this.props.getNotificationHistories('consumer', this.props.auth.uid);
  }

  updateNotification(params) {
    this.props.updateNotification('consumer', this.props.auth.uid, params);
  }

  render() {
    const notificationHistories = this.props.notificationHistory.list.map(
      id => this.props.notificationHistory.hash[id]
    );

    if (!notificationHistories) {
      return null;
    }

    return (
      <View>
        <NavigationHeader title="お知らせ" goBack={this.props.history.goBack} />
        <NotificationHistoryList
          data={notificationHistories}
          renderItem={({ item }) => {
            return (
              <NotificationHistoryItem
                {...item}
                selfId={this.props.auth.uid}
                onTransit={path => this.props.history.push(`${path}`)}
              />
            );
          }}
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserMypageNotificationIndex);
