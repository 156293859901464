import { getCollection } from '../utils/Firebase';
import ErrorMessages from '../constants/ErrorMessages';
import * as types from '../constants/ActionTypes';

// create application
function fetchCreateApplication() {
  return {
    type: types.FETCH_CREATE_APPLICATION
  };
}

function fetchCreateApplicationSuccess() {
  return {
    type: types.FETCH_CREATE_APPLICATION_SUCCESS
  };
}

function fetchCreateApplicationFailed(error) {
  return {
    type: types.FETCH_CREATE_APPLICATION_FAILED,
    error
  };
}

export function recoverCreateApplication() {
  return {
    type: types.FETCH_CREATE_APPLICATION_RECOVER
  };
}

export function createApplication(params) {
  return (dispatch, getState) => {
    dispatch(fetchCreateApplication());

    const applications = getCollection('applications');

    return applications
      .add(params)
      .then(() => {
        return dispatch(fetchCreateApplicationSuccess());
      })
      .catch(error => {
        return dispatch(
          fetchCreateApplicationFailed({
            code: error.code,
            message: ErrorMessages[error.code]
          })
        );
      });
  };
}
