export default class ConsumerSetting {
  constructor(state = {}) {
    this.params = {
      id: state.id,
      isEnableLikeEmailNotification:
        state.isEnableLikeEmailNotification === undefined
          ? true
          : state.isEnableLikeEmailNotification,
      isEnableMessageEmailNotification:
        state.isEnableMessageEmailNotification === undefined
          ? true
          : state.isEnableMessageEmailNotification,
      isEnableScheduleEmailNotification:
        state.isEnableScheduleEmailNotification === undefined
          ? true
          : state.isEnableScheduleEmailNotification,
      isEnableSessionEmailNotification:
        state.isEnableSessionEmailNotification === undefined
          ? true
          : state.isEnableSessionEmailNotification,
      isEnableNewsEmailNotification:
        state.isEnableNewsEmailNotification === undefined
          ? true
          : state.isEnableNewsEmailNotification,
      createdAt: state.createdAt,
      updatedAt: state.updatedAt
    };
  }

  get object() {
    return this.params;
  }

  fromFirestore() {
    return {
      ...this.params,
      createdAt: this.params.createdAt && this.params.createdAt.toDate(),
      updatedAt: this.params.updatedAt && this.params.updatedAt.toDate()
    };
  }
}
