// activity history
export const FETCH_GET_ACTIVITY_HISTORIES = 'FETCH_GET_ACTIVITY_HISTORIES';
export const FETCH_GET_ACTIVITY_HISTORIES_SUCCESS =
  'FETCH_GET_ACTIVITY_HISTORIES_SUCCESS';
export const FETCH_GET_ACTIVITY_HISTORIES_FAILED =
  'FETCH_GET_ACTIVITY_HISTORIES_FAILED';
export const FETCH_GET_ACTIVITY_HISTORIES_RECOVER =
  'FETCH_GET_ACTIVITY_HISTORIES_RECOVER';

// auth
export const SIGN_UP_WITH_EMAIL_AND_PASSWORD_ENTRY =
  'SIGN_UP_WITH_EMAIL_AND_PASSWORD_ENTRY';
export const SIGN_UP_WITH_EMAIL_AND_PASSWORD_SUCCESS =
  'SIGN_UP_WITH_EMAIL_AND_PASSWORD_SUCCESS';
export const SIGN_UP_WITH_EMAIL_AND_PASSWORD_FAILED =
  'SIGN_UP_WITH_EMAIL_AND_PASSWORD_FAILED';
export const SIGN_UP_WITH_EMAIL_AND_PASSWORD_RECOVER =
  'SIGN_UP_WITH_EMAIL_AND_PASSWORD_RECOVER';
export const SIGN_IN_WITH_EMAIL_AND_PASSWORD_ENTRY =
  'SIGN_IN_WITH_EMAIL_AND_PASSWORD_ENTRY';
export const SIGN_IN_WITH_EMAIL_AND_PASSWORD_SUCCESS =
  'SIGN_IN_WITH_EMAIL_AND_PASSWORD_SUCCESS';
export const SIGN_IN_WITH_EMAIL_AND_PASSWORD_FAILED =
  'SIGN_IN_WITH_EMAIL_AND_PASSWORD_FAILED';
export const SIGN_IN_WITH_EMAIL_AND_PASSWORD_RECOVER =
  'SIGN_IN_WITH_EMAIL_AND_PASSWORD_RECOVER';

export const AUTH_WITH_PHONE_NUMBER_ENTRY = 'AUTH_WITH_PHONE_NUMBER_ENTRY';
export const AUTH_WITH_PHONE_NUMBER_SUCCESS = 'AUTH_WITH_PHONE_NUMBER_SUCCESS';
export const AUTH_WITH_PHONE_NUMBER_FAILED = 'AUTH_WITH_PHONE_NUMBER_FAILED';
export const AUTH_WITH_PHONE_NUMBER_RECOVER = 'AUTH_WITH_PHONE_NUMBER_RECOVER';

export const AUTH_WITH_TWITTER_ENTRY = 'AUTH_WITH_TWITTER_ENTRY';
export const AUTH_WITH_TWITTER_SUCCESS = 'AUTH_WITH_TWITTER_SUCCESS';
export const AUTH_WITH_TWITTER_FAILED = 'AUTH_WITH_TWITTER_FAILED';

export const AUTH_WITH_FACEBOOK_ENTRY = 'AUTH_WITH_FACEBOOK_ENTRY';
export const AUTH_WITH_FACEBOOK_SUCCESS = 'AUTH_WITH_FACEBOOK_SUCCESS';
export const AUTH_WITH_FACEBOOK_FAILED = 'AUTH_WITH_FACEBOOK_FAILED';

export const AUTH_WITH_PROVIDER_RECOVER = 'AUTH_WITH_PROVIDER_RECOVER';

export const AUTH_SIGN_OUT_ENTRY = 'AUTH_SIGN_OUT_ENTRY';
export const AUTH_SIGN_OUT_SUCCESS = 'AUTH_SIGN_OUT_SUCCESS';
export const AUTH_SIGN_OUT_FAILED = 'AUTH_SIGN_OUT_FAILED';
export const AUTH_SIGN_OUT_RECOVER = 'AUTH_SIGN_OUT_RECOVER';
export const AUTH_SIGN_IN_WITH_CUSTOM_TOKEN_ENTRY =
  'AUTH_SIGN_IN_WITH_CUSTOM_TOKEN_ENTRY';
export const AUTH_SIGN_IN_WITH_CUSTOM_TOKEN_SUCCESS =
  'AUTH_SIGN_IN_WITH_CUSTOM_TOKEN_SUCCESS';
export const AUTH_SIGN_IN_WITH_CUSTOM_TOKEN_FAILED =
  'AUTH_SIGN_IN_WITH_CUSTOM_TOKEN_FAILED';
export const AUTH_SIGN_IN_WITH_CUSTOM_TOKEN_RECOVER =
  'AUTH_SIGN_IN_WITH_CUSTOM_TOKEN_RECOVER';

// application
export const FETCH_CREATE_APPLICATION = 'FETCH_CREATE_APPLICATION';
export const FETCH_CREATE_APPLICATION_SUCCESS =
  'FETCH_CREATE_APPLICATION_SUCCESS';
export const FETCH_CREATE_APPLICATION_FAILED =
  'FETCH_CREATE_APPLICATION_FAILED';
export const FETCH_CREATE_APPLICATION_RECOVER =
  'FETCH_CREATE_APPLICATION_RECOVER';

// atelier board
export const FETCH_GET_ATELIER_BOARDS = 'FETCH_GET_ATELIER_BOARDS';
export const FETCH_GET_ATELIER_BOARDS_SUCCESS =
  'FETCH_GET_ATELIER_BOARDS_SUCCESS';
export const FETCH_GET_ATELIER_BOARDS_FAILED =
  'FETCH_GET_ATELIER_BOARDS_FAILED';
export const FETCH_GET_ATELIER_BOARDS_RECOVER =
  'FETCH_GET_ATELIER_BOARDS_RECOVER';
export const FETCH_CREATE_ATELIER_BOARD = 'FETCH_CREATE_ATELIER_BOARD';
export const FETCH_CREATE_ATELIER_BOARD_SUCCESS =
  'FETCH_CREATE_ATELIER_BOARD_SUCCESS';
export const FETCH_CREATE_ATELIER_BOARD_FAILED =
  'FETCH_CREATE_ATELIER_BOARD_FAILED';
export const FETCH_CREATE_ATELIER_BOARD_RECOVER =
  'FETCH_CREATE_ATELIER_BOARD_RECOVER';
export const FETCH_UPDATE_ATELIER_BOARD = 'FETCH_UPDATE_ATELIER_BOARD';
export const FETCH_UPDATE_ATELIER_BOARD_SUCCESS =
  'FETCH_UPDATE_ATELIER_BOARD_SUCCESS';
export const FETCH_UPDATE_ATELIER_BOARD_FAILED =
  'FETCH_UPDATE_ATELIER_BOARD_FAILED';
export const FETCH_UPDATE_ATELIER_BOARD_RECOVER =
  'FETCH_UPDATE_ATELIER_BOARD_RECOVER';
export const FETCH_DELETE_ATELIER_BOARD = 'FETCH_DELETE_ATELIER_BOARD';
export const FETCH_DELETE_ATELIER_BOARD_SUCCESS =
  'FETCH_DELETE_ATELIER_BOARD_SUCCESS';
export const FETCH_DELETE_ATELIER_BOARD_FAILED =
  'FETCH_DELETE_ATELIER_BOARD_FAILED';
export const FETCH_DELETE_ATELIER_BOARD_RECOVER =
  'FETCH_DELETE_ATELIER_BOARD_RECOVER';

// badge
export const FETCH_GET_BADGES = 'FETCH_GET_BADGES';
export const FETCH_GET_BADGES_SUCCESS = 'FETCH_GET_BADGES_SUCCESS';
export const FETCH_GET_BADGES_FAILED = 'FETCH_GET_BADGES_FAILED';
export const FETCH_GET_BADGES_RECOVER = 'FETCH_GET_BADGES_RECOVER';
export const FETCH_SUBSCRIBE_BADGE = 'FETCH_SUBSCRIBE_BADGE';
export const FETCH_ADD_BADGE_SUCCESS = 'FETCH_ADD_BADGE_SUCCESS';
export const FETCH_REMOVE_BADGE_SUCCESS = 'FETCH_REMOVE_BADGE_SUCCESS';
export const FETCH_DELETE_BADGE = 'FETCH_DELETE_BADGE';
export const FETCH_DELETE_BADGE_SUCCESS = 'FETCH_DELETE_BADGE_SUCCESS';
export const FETCH_DELETE_BADGE_FAILED = 'FETCH_DELETE_BADGE_FAILED';
export const FETCH_DELETE_BADGE_RECOVER = 'FETCH_DELETE_BADGE_RECOVER';

// model
export const FETCH_GET_MODELS = 'FETCH_GET_MODELS';
export const FETCH_GET_MODELS_SUCCESS = 'FETCH_GET_MODELS_SUCCESS';
export const FETCH_GET_MODELS_FAILED = 'FETCH_GET_MODELS_FAILED';
export const FETCH_GET_MODELS_RECOVER = 'FETCH_GET_MODELS_RECOVER';
export const FETCH_GET_MODEL = 'FETCH_GET_MODEL';
export const FETCH_GET_MODEL_SUCCESS = 'FETCH_GET_MODEL_SUCCESS';
export const FETCH_GET_MODEL_FAILED = 'FETCH_GET_MODEL_FAILED';
export const FETCH_GET_MODEL_RECOVER = 'FETCH_GET_MODEL_RECOVER';

// chat
export const FETCH_GET_CHAT = 'FETCH_GET_CHAT';
export const FETCH_GET_CHAT_SUCCESS = 'FETCH_GET_CHAT_SUCCESS';
export const FETCH_GET_CHAT_FAILED = 'FETCH_GET_CHAT_FAILED';
export const FETCH_GET_CHAT_RECOVER = 'FETCH_GET_CHAT_RECOVER';

// chat index
export const FETCH_GET_CHAT_INDEXES = 'FETCH_GET_CHAT_INDEXES';
export const FETCH_GET_CHAT_INDEXES_SUCCESS = 'FETCH_GET_CHAT_INDEXES_SUCCESS';
export const FETCH_GET_CHAT_INDEXES_FAILED = 'FETCH_GET_CHAT_INDEXES_FAILED';
export const FETCH_GET_CHAT_INDEXES_RECOVER = 'FETCH_GET_CHAT_INDEXES_RECOVER';
export const FETCH_GET_CHAT_INDEX = 'FETCH_GET_CHAT_INDEX';
export const FETCH_GET_CHAT_INDEX_SUCCESS = 'FETCH_GET_CHAT_INDEX_SUCCESS';
export const FETCH_GET_CHAT_INDEX_FAILED = 'FETCH_GET_CHAT_INDEX_FAILED';
export const FETCH_GET_CHAT_INDEX_RECOVER = 'FETCH_GET_CHAT_INDEX_RECOVER';
export const FETCH_SUBSCRIBE_CHAT_INDEX = 'FETCH_SUBSCRIBE_CHAT_INDEX';
export const FETCH_ADD_CHAT_INDEX_SUCCESS = 'FETCH_ADD_CHAT_INDEX_SUCCESS';
export const FETCH_MODIFY_CHAT_INDEX_SUCCESS =
  'FETCH_MODIFY_CHAT_INDEX_SUCCESS';

export const FETCH_GET_CHAT_HISTORIES = 'FETCH_GET_CHAT_HISTORIES';
export const FETCH_GET_CHAT_HISTORIES_SUCCESS =
  'FETCH_GET_CHAT_HISTORIES_SUCCESS';
export const FETCH_GET_CHAT_HISTORIES_FAILED =
  'FETCH_GET_CHAT_HISTORIES_FAILED';
export const FETCH_GET_CHAT_HISTORIES_RECOVER =
  'FETCH_GET_CHAT_HISTORIES_RECOVER';
export const FETCH_CREATE_CHAT_HISTORY = 'FETCH_CREATE_CHAT_HISTORY';
export const FETCH_CREATE_CHAT_HISTORY_SUCCESS =
  'FETCH_CREATE_CHAT_HISTORY_SUCCESS';
export const FETCH_CREATE_CHAT_HISTORY_FAILED =
  'FETCH_CREATE_CHAT_HISTORY_FAILED';
export const FETCH_CREATE_CHAT_HISTORY_RECOVER =
  'FETCH_CREATE_CHAT_HISTORY_RECOVER';
export const FETCH_ADD_CHAT_HISTORY = 'FETCH_ADD_CHAT_HISTORY';
export const FETCH_ADD_CHAT_HISTORY_SUCCESS = 'FETCH_ADD_CHAT_HISTORY_SUCCESS';
export const FETCH_ADD_CHAT_HISTORY_FAILED = 'FETCH_ADD_CHAT_HISTORY_FAILED';
export const FETCH_ADD_CHAT_HISTORY_RECOVER = 'FETCH_ADD_CHAT_HISTORY_RECOVER';

// config
export const FETCH_GET_CONFIGS = 'FETCH_GET_CONFIGS';
export const FETCH_GET_CONFIGS_SUCCESS = 'FETCH_GET_CONFIGS_SUCCESS';
export const FETCH_GET_CONFIGS_FAILED = 'FETCH_GET_CONFIGS_FAILED';
export const FETCH_GET_CONFIGS_RECOVER = 'FETCH_GET_CONFIGS_RECOVER';

// consumer
export const FETCH_GET_CONSUMERS = 'FETCH_GET_CONSUMERS';
export const FETCH_GET_CONSUMERS_SUCCESS = 'FETCH_GET_CONSUMERS_SUCCESS';
export const FETCH_GET_CONSUMERS_FAILED = 'FETCH_GET_CONSUMERS_FAILED';
export const FETCH_GET_CONSUMERS_RECOVER = 'FETCH_GET_CONSUMERS_RECOVER';
export const FETCH_GET_CONSUMER = 'FETCH_GET_CONSUMER';
export const FETCH_GET_CONSUMER_SUCCESS = 'FETCH_GET_CONSUMER_SUCCESS';
export const FETCH_GET_CONSUMER_FAILED = 'FETCH_GET_CONSUMER_FAILED';
export const FETCH_GET_CONSUMER_RECOVER = 'FETCH_GET_CONSUMER_RECOVER';

// document
export const FETCH_GET_DOCUMENT_HISTORIES = 'FETCH_GET_DOCUMENT_HISTORIES';
export const FETCH_GET_DOCUMENT_HISTORIES_SUCCESS =
  'FETCH_GET_DOCUMENT_HISTORIES_SUCCESS';
export const FETCH_GET_DOCUMENT_HISTORIES_FAILED =
  'FETCH_GET_DOCUMENT_HISTORIES_FAILED';
export const FETCH_GET_DOCUMENT_HISTORIES_RECOVER =
  'FETCH_GET_DOCUMENT_HISTORIES_RECOVER';

// layout
export const UPDATE_WINDOW_SIZE = 'UPDATE_WINDOW_SIZE';

// like
export const FETCH_GET_LIKE = 'FETCH_GET_LIKE';
export const FETCH_GET_LIKE_SUCCESS = 'FETCH_GET_LIKE_SUCCESS';
export const FETCH_GET_LIKE_FAILED = 'FETCH_GET_LIKE_FAILED';
export const FETCH_GET_LIKE_RECOVER = 'FETCH_GET_LIKE_RECOVER';
export const FETCH_CREATE_LIKE = 'FETCH_CREATE_LIKE';
export const FETCH_CREATE_LIKE_SUCCESS = 'FETCH_CREATE_LIKE_SUCCESS';
export const FETCH_CREATE_LIKE_FAILED = 'FETCH_CREATE_LIKE_FAILED';
export const FETCH_CREATE_LIKE_RECOVER = 'FETCH_CREATE_LIKE_RECOVER';

// notification
export const FETCH_GET_NOTIFICATION_HISTORIES =
  'FETCH_GET_NOTIFICATION_HISTORIES';
export const FETCH_GET_NOTIFICATION_HISTORIES_SUCCESS =
  'FETCH_GET_NOTIFICATION_HISTORIES_SUCCESS';
export const FETCH_GET_NOTIFICATION_HISTORIES_FAILED =
  'FETCH_GET_NOTIFICATION_HISTORIES_FAILED';
export const FETCH_GET_NOTIFICATION_HISTORIES_RECOVER =
  'FETCH_GET_NOTIFICATION_HISTORIES_RECOVER';
export const FETCH_UPDATE_NOTIFICATION_HISTORY =
  'FETCH_UPDATE_NOTIFICATION_HISTORY';
export const FETCH_UPDATE_NOTIFICATION_HISTORY_SUCCESS =
  'FETCH_UPDATE_NOTIFICATION_HISTORY_SUCCESS';
export const FETCH_UPDATE_NOTIFICATION_HISTORY_FAILED =
  'FETCH_UPDATE_NOTIFICATION_HISTORY_FAILED';
export const FETCH_UPDATE_NOTIFICATION_HISTORY_RECOVER =
  'FETCH_UPDATE_NOTIFICATION_HISTORY_RECOVER';

// payment info
export const FETCH_CREATE_PAYMENT_INFO = 'FETCH_CREATE_PAYMENT_INFO';
export const FETCH_CREATE_PAYMENT_INFO_SUCCESS =
  'FETCH_CREATE_PAYMENT_INFO_SUCCESS';
export const FETCH_CREATE_PAYMENT_INFO_FAILED =
  'FETCH_CREATE_PAYMENT_INFO_FAILED';
export const FETCH_CREATE_PAYMENT_INFO_RECOVER =
  'FETCH_CREATE_PAYMENT_INFO_RECOVER';
export const FETCH_DELETE_PAYMENT_INFO = 'FETCH_DELETE_PAYMENT_INFO';
export const FETCH_DELETE_PAYMENT_INFO_SUCCESS =
  'FETCH_DELETE_PAYMENT_INFO_SUCCESS';
export const FETCH_DELETE_PAYMENT_INFO_FAILED =
  'FETCH_DELETE_PAYMENT_INFO_FAILED';
export const FETCH_DELETE_PAYMENT_INFO_RECOVER =
  'FETCH_DELETE_PAYMENT_INFO_RECOVER';

// point history
export const FETCH_GET_POINT_HISTORIES = 'FETCH_GET_POINT_HISTORIES';
export const FETCH_GET_POINT_HISTORIES_SUCCESS =
  'FETCH_GET_POINT_HISTORIES_SUCCESS';
export const FETCH_GET_POINT_HISTORIES_FAILED =
  'FETCH_GET_POINT_HISTORIES_FAILED';
export const FETCH_GET_POINT_HISTORIES_RECOVER =
  'FETCH_GET_POINT_HISTORIES_RECOVER';

// reserve history
export const FETCH_GET_RESERVE_HISTORIES = 'FETCH_GET_RESERVE_HISTORIES';
export const FETCH_GET_RESERVE_HISTORIES_SUCCESS =
  'FETCH_GET_RESERVE_HISTORIES_SUCCESS';
export const FETCH_GET_RESERVE_HISTORIES_FAILED =
  'FETCH_GET_RESERVE_HISTORIES_FAILED';
export const FETCH_GET_RESERVE_HISTORIES_RECOVER =
  'FETCH_GET_RESERVE_HISTORIES_RECOVER';
export const FETCH_CREATE_RESERVE_HISTORY = 'FETCH_CREATE_RESERVE_HISTORY';
export const FETCH_CREATE_RESERVE_HISTORY_SUCCESS =
  'FETCH_CREATE_RESERVE_HISTORY_SUCCESS';
export const FETCH_CREATE_RESERVE_HISTORY_FAILED =
  'FETCH_CREATE_RESERVE_HISTORY_FAILED';
export const FETCH_CREATE_RESERVE_HISTORY_RECOVER =
  'FETCH_CREATE_RESERVE_HISTORY_RECOVER';
export const FETCH_UPDATE_RESERVE_HISTORY = 'FETCH_UPDATE_RESERVE_HISTORY';
export const FETCH_UPDATE_RESERVE_HISTORY_SUCCESS =
  'FETCH_UPDATE_RESERVE_HISTORY_SUCCESS';
export const FETCH_UPDATE_RESERVE_HISTORY_FAILED =
  'FETCH_UPDATE_RESERVE_HISTORY_FAILED';
export const FETCH_UPDATE_RESERVE_HISTORY_RECOVER =
  'FETCH_UPDATE_RESERVE_HISTORY_RECOVER';

export const FETCH_SUBSCRIBE_RESERVE_HISTORY =
  'FETCH_SUBSCRIBE_RESERVE_HISTORY';
export const FETCH_ADD_RESERVE_HISTORY_SUCCESS =
  'FETCH_ADD_RESERVE_HISTORY_SUCCESS';
export const FETCH_MODIFY_RESERVE_HISTORY_SUCCESS =
  'FETCH_MODIFY_RESERVE_HISTORY_SUCCESS';

// review history
export const FETCH_GET_REVIEW_HISTORIES = 'FETCH_GET_REVIEW_HISTORIES';
export const FETCH_GET_REVIEW_HISTORIES_SUCCESS =
  'FETCH_GET_REVIEW_HISTORIES_SUCCESS';
export const FETCH_GET_REVIEW_HISTORIES_FAILED =
  'FETCH_GET_REVIEW_HISTORIES_FAILED';
export const FETCH_GET_REVIEW_HISTORIES_RECOVER =
  'FETCH_GET_REVIEW_HISTORIES_RECOVER';
export const FETCH_GET_REVIEW_HISTORY = 'FETCH_GET_REVIEW_HISTORY';
export const FETCH_GET_REVIEW_HISTORY_SUCCESS =
  'FETCH_GET_REVIEW_HISTORY_SUCCESS';
export const FETCH_GET_REVIEW_HISTORY_FAILED =
  'FETCH_GET_REVIEW_HISTORY_FAILED';
export const FETCH_GET_REVIEW_HISTORY_RECOVER =
  'FETCH_GET_REVIEW_HISTORY_RECOVER';
export const FETCH_CREATE_REVIEW_HISTORY = 'FETCH_CREATE_REVIEW_HISTORY';
export const FETCH_CREATE_REVIEW_HISTORY_SUCCESS =
  'FETCH_CREATE_REVIEW_HISTORY_SUCCESS';
export const FETCH_CREATE_REVIEW_HISTORY_FAILED =
  'FETCH_CREATE_REVIEW_HISTORY_FAILED';
export const FETCH_CREATE_REVIEW_HISTORY_RECOVER =
  'FETCH_CREATE_REVIEW_HISTORY_RECOVER';

// reward history
export const FETCH_GET_REWARD_HISTORIES = 'FETCH_GET_REWARD_HISTORIES';
export const FETCH_GET_REWARD_HISTORIES_SUCCESS =
  'FETCH_GET_REWARD_HISTORIES_SUCCESS';
export const FETCH_GET_REWARD_HISTORIES_FAILED =
  'FETCH_GET_REWARD_HISTORIES_FAILED';
export const FETCH_GET_REWARD_HISTORIES_RECOVER =
  'FETCH_GET_REWARD_HISTORIES_RECOVER';

// session history
export const FETCH_GET_SESSION_HISTORIES = 'FETCH_GET_SESSION_HISTORIES';
export const FETCH_GET_SESSION_HISTORIES_SUCCESS =
  'FETCH_GET_SESSION_HISTORIES_SUCCESS';
export const FETCH_GET_SESSION_HISTORIES_FAILED =
  'FETCH_GET_SESSION_HISTORIES_FAILED';
export const FETCH_GET_SESSION_HISTORIES_RECOVER =
  'FETCH_GET_SESSION_HISTORIES_RECOVER';
export const FETCH_GET_SESSION_HISTORY = 'FETCH_GET_SESSION_HISTORY';
export const FETCH_GET_SESSION_HISTORY_SUCCESS =
  'FETCH_GET_SESSION_HISTORY_SUCCESS';
export const FETCH_GET_SESSION_HISTORY_FAILED =
  'FETCH_GET_SESSION_HISTORY_FAILED';
export const FETCH_GET_SESSION_HISTORY_RECOVER =
  'FETCH_GET_SESSION_HISTORY_RECOVER';

// setting
export const FETCH_GET_SETTING = 'FETCH_GET_SETTING';
export const FETCH_GET_SETTING_SUCCESS = 'FETCH_GET_SETTING_SUCCESS';
export const FETCH_GET_SETTING_FAILED = 'FETCH_GET_SETTING_FAILED';
export const FETCH_GET_SETTING_RECOVER = 'FETCH_GET_SETTING_RECOVER';
export const FETCH_UPDATE_SETTING = 'FETCH_UPDATE_SETTING';
export const FETCH_UPDATE_SETTING_SUCCESS = 'FETCH_UPDATE_SETTING_SUCCESS';
export const FETCH_UPDATE_SETTING_FAILED = 'FETCH_UPDATE_SETTING_FAILED';
export const FETCH_UPDATE_SETTING_RECOVER = 'FETCH_UPDATE_SETTING_RECOVER';

// shop
export const FETCH_PURCHASE_SHOP = 'FETCH_PURCHASE_SHOP';
export const FETCH_PURCHASE_SHOP_SUCCESS = 'FETCH_PURCHASE_SHOP_SUCCESS';
export const FETCH_PURCHASE_SHOP_FAILED = 'FETCH_PURCHASE_SHOP_FAILED';
export const FETCH_PURCHASE_SHOP_RECOVER = 'FETCH_PURCHASE_SHOP_RECOVER';

// user
export const FETCH_GET_MYSELF_USER = 'FETCH_GET_MYSELF_USER';
export const FETCH_GET_MYSELF_USER_SUCCESS = 'FETCH_GET_MYSELF_USER_SUCCESS';
export const FETCH_GET_MYSELF_USER_FAILED = 'FETCH_GET_MYSELF_USER_FAILED';
export const FETCH_GET_MYSELF_USER_RECOVER = 'FETCH_GET_MYSELF_USER_RECOVER';

export const FETCH_GET_USER = 'FETCH_GET_USER';
export const FETCH_GET_USER_SUCCESS = 'FETCH_GET_USER_SUCCESS';
export const FETCH_GET_USER_FAILED = 'FETCH_GET_USER_FAILED';
export const FETCH_GET_USER_RECOVER = 'FETCH_GET_USER_RECOVER';

export const FETCH_UPDATE_MYSELF_USER = 'FETCH_UPDATE_MYSELF_USER';
export const FETCH_UPDATE_MYSELF_USER_SUCCESS =
  'FETCH_UPDATE_MYSELF_USER_SUCCESS';
export const FETCH_UPDATE_MYSELF_USER_FAILED =
  'FETCH_UPDATE_MYSELF_USER_FAILED';
export const FETCH_UPDATE_MYSELF_USER_RECOVER =
  'FETCH_UPDATE_MYSELF_USER_RECOVER';

export const FETCH_GET_USERS = 'FETCH_GET_USERS';
export const FETCH_GET_USERS_SUCCESS = 'FETCH_GET_USERS_SUCCESS';
export const FETCH_GET_USERS_FAILED = 'FETCH_GET_USERS_FAILED';
export const FETCH_GET_USERS_RECOVER = 'FETCH_GET_USERS_RECOVER';

// network
export const IN_MAINTENANCE = 'IN_MAINTENANCE';
export const NOT_AUTHENTICATED_OR_INVALIDATED =
  'NOT_AUTHENTICATED_OR_INVALIDATED';
