import React from 'react';
import moment from 'moment';

import { CreditCardOffIconSvg } from '../../utils/MaterialIconSvg';
import { getChatMessage } from '../../utils/ChatMessage';
import Color from '../../constants/Color';
import Headline from '../Headline';
import Paragraph from '../Paragraph';
import ReserveStatusLabel from '../Reserve/ReserveStatusLabel';
import Text from '../Text';
import UserIcon from '../Icons/UserIcon';
import View from '../View';

function renderReserveInfo({ reserve = null }) {
  if (!reserve) {
    return null;
  }

  const startAt = reserve.startAt.toDate();

  const hours = reserve.hours;

  const date = moment(startAt).add(hours, 'hours');

  const now = moment();

  if (
    date.diff(now) < 0 ||
    reserve.status === 'canceled' ||
    reserve.status === 'penaltyCanceled' ||
    reserve.status === 'dismissed'
  ) {
    return null;
  }

  return (
    <View
      style={{ flexDirection: 'row', marginBottom: 8, alignItems: 'center' }}
    >
      <ReserveStatusLabel status={reserve.status} style={{ marginRight: 8 }} />
      <Text bold style={{ fontSize: 13, color: Color.mazenta }}>
        {moment(startAt).format('M月D日 HH:mm')}〜
      </Text>
    </View>
  );
}

export default function ChatIndexItem({
  users,
  data,
  selfType,
  createdAt,
  onClick = () => {},
  onClickIcon = () => {},
  badgeNum = 0,
  style = {}
}) {
  // TODO multi user showing
  const user = users[0];

  return (
    <View style={{ padding: 8, borderBottom: `1px solid ${Color.lightGrey}` }}>
      <View style={{ flexDirection: 'row' }}>
        <UserIcon
          source={user.iconImage}
          size={56}
          style={{ marginRight: 16 }}
          onClick={onClickIcon}
        />
        <View style={{ flexDirection: 'row', flex: 1 }} onClick={onClick}>
          <View style={{ flex: 1, justifyContent: 'center' }}>
            {renderReserveInfo(data)}
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: 8
              }}
            >
              <Headline size={3}>{user.name}</Headline>
              {user.roles.includes('consumer') && !user.cardRegisterAt && (
                <CreditCardOffIconSvg
                  fill={Color.darkGrey}
                  width={20}
                  height={20}
                  viewBox="0 0 24 24"
                  style={{ marginLeft: 8 }}
                />
              )}
            </View>
            <Paragraph
              style={{
                fontSize: 13,
                textOverflow: 'ellipsis',
                color: Color.darkGrey,
                wordBreak: 'break-all'
              }}
              containerStyle={{ overflow: 'hidden', maxHeight: 24 }}
            >
              {getChatMessage(user, data, selfType)}
            </Paragraph>
          </View>
          <View>
            <Text style={{ fontSize: 11, color: Color.darkGrey }}>
              {moment(createdAt).fromNow()}前
            </Text>
            {badgeNum > 0 && (
              <View
                style={{
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'flex-end'
                }}
              >
                <View
                  style={{
                    alignItems: 'center',
                    borderRadius: 32,
                    padding: '4px 16px',
                    backgroundColor: Color.mazenta
                  }}
                >
                  <Text bold style={{ fontSize: 13, color: Color.white }}>
                    {badgeNum}
                  </Text>
                </View>
              </View>
            )}
          </View>
        </View>
      </View>
    </View>
  );
}
