import { getHash } from '../utils/Reducer';
import * as types from '../constants/ActionTypes';

const initialState = {
  list: [],
  hash: {},
  isGetLoading: false,
  isGetFailed: false,
  getError: null
};

export default (state = initialState, action) => {
  let newHash;

  let list;

  switch (action.type) {
    // get chat indexes
    case types.FETCH_GET_CHAT_INDEXES:
      return {
        ...state,
        list: [],
        isGetLoading: true
      };

    case types.FETCH_GET_CHAT_INDEXES_SUCCESS:
      newHash = getHash(action.state.list, state.hash, 'id');

      return {
        ...state,
        list: action.state.list.map(item => item.id),
        hash: newHash,
        isGetLoading: false,
        isGetFailed: false
      };

    case types.FETCH_GET_CHAT_INDEXES_FAILED:
      return {
        ...state,
        isGetLoading: false,
        isGetFailed: true,
        getError: action.error
      };

    case types.FETCH_GET_CHAT_INDEXES_RECOVER:
      return {
        ...state,
        isGetFailed: false
      };

    // get chat index
    case types.FETCH_GET_CHAT_INDEX:
      return {
        ...state,
        list: [],
        isGetLoading: true
      };

    case types.FETCH_GET_CHAT_INDEX_SUCCESS:
      return {
        ...state,
        list: [action.state.id],
        hash: {
          ...state.hash,
          [action.state.id]: action.state.params
        },
        isGetLoading: false,
        isGetFailed: false
      };

    case types.FETCH_GET_CHAT_INDEX_FAILED:
      return {
        ...state,
        isGetLoading: false,
        isGetFailed: true,
        getError: action.error
      };

    case types.FETCH_GET_CHAT_INDEX_RECOVER:
      return {
        ...state,
        isGetFailed: false
      };

    case types.FETCH_SUBSCRIBE_CHAT_INDEX:
      return {
        ...state,
        list: [],
        hash: {}
      };

    case types.FETCH_ADD_CHAT_INDEX_SUCCESS:
      newHash = {
        ...state.hash,
        [action.state.id]: action.state.params
      };

      list = [...state.list, action.state.id].sort((a, b) => {
        if (newHash[a].createdAt > newHash[b].createdAt) {
          return -1;
        } else {
          return 1;
        }
      });

      return {
        ...state,
        list,
        hash: newHash
      };

    case types.FETCH_MODIFY_CHAT_INDEX_SUCCESS:
      newHash = {
        ...state.hash,
        [action.state.id]: action.state.params
      };

      return {
        ...state,
        hash: newHash
      };

    default:
      return state;
  }
};
