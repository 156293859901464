import React from 'react';

import View from '../View';

export default function ProfileList({ data, renderItem, style = {} }) {
  return (
    <View
      style={{
        flexDirection: 'row',
        flexWrap: 'wrap',
        ...style
      }}
    >
      {data.map((datum, index) => renderItem({ item: datum, index }))}
    </View>
  );
}
