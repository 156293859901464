import React from 'react';

import Color from '../../constants/Color';
import View from '../View';

export default function Note({
  children,
  style = {},
  containerStyle = {},
  ...rest
}) {
  return (
    <View
      style={{
        backgroundColor: Color.lightGrayishCyan,
        ...style
      }}
      {...rest}
    >
      {children}
    </View>
  );
}
