export default class ChatIndex {
  constructor(state = {}) {
    this.params = {
      id: state.id,
      chatId: state.chatId,
      data: state.data || null,
      createdAt: state.createdAt
    };
  }

  get object() {
    return this.params;
  }

  fromFirestore() {
    return {
      ...this.params,
      createdAt: this.params.createdAt && this.params.createdAt.toDate(),
      updatedAt: this.params.updatedAt && this.params.updatedAt.toDate()
    };
  }
}
