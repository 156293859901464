import React from 'react';
import moment from 'moment';
import numeral from 'numeral';

import Color from '../../constants/Color';
import PointHistory from '../../models/PointHistory';
import SectionItem from '../Section/SectionItem';
import Text from '../Text';
import View from '../View';

export default function PointHistoryItem({
  type,
  value,
  createdAt,
  style = {}
}) {
  return (
    <SectionItem
      title={PointHistory.getPointHistoryTypeLabel(type)}
      content={
        <View style={{ alignItems: 'flex-end' }}>
          <Text
            style={{
              fontSize: 17,
              color: value > 0 ? Color.black : Color.red,
              marginBottom: 4
            }}
          >
            {numeral(value).format('0,0')}P
          </Text>
          <Text style={{ color: Color.darkGrey }}>
            {moment(createdAt).format('YYYY/MM/DD HH:mm')}
          </Text>
        </View>
      }
      style={{ borderBottom: `1px solid ${Color.lightGrey}`, padding: 16 }}
    />
  );
}
