import React from 'react';

import {
  AccountIconSvg,
  MagnifyIconSvg,
  MessageTextIconSvg
} from '../../utils/MaterialIconSvg';
import TabContainer from '../../components/Tab/TabContainer';
import View from '../../components/View';

export default function ModelTabContainer(props) {
  const { children, activeIndex, history, style = {} } = props;

  return (
    <TabContainer
      activeIndex={activeIndex}
      buttons={[
        {
          label: 'みつける',
          icon: MagnifyIconSvg,
          onClick: () => history.push(`/model/find`)
        },
        {
          label: 'メッセージ',
          icon: MessageTextIconSvg,
          onClick: () => history.push(`/model/message`)
        },
        {
          label: 'マイページ',
          icon: AccountIconSvg,
          onClick: () => history.push(`/model/mypage`)
        }
      ]}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: '100%',
        ...style
      }}
    >
      <View style={{ display: 'block', overflow: 'scroll', height: '100%' }}>
        {children}
      </View>
    </TabContainer>
  );
}
