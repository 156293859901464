import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import {
  APPLICATION_VALIDATION_LABELS,
  APPLICATION_VALIDATION_RULES
} from '../../constants/Validations';
import {
  CashMultipleIconSvg,
  CheckDecagramIconSvg,
  CommentEyeIconSvg,
  EmailIconSvg,
  FaceAgentIconSvg,
  ImageOffIconSvg,
  SackPercentIconSvg,
  ShieldAccountIconSvg,
  ShieldCheckIconSvg
} from '../../utils/MaterialIconSvg';
import { validate, validateAll } from '../../utils/Validation';
import BeginnerIcon from '../../components/Icons/BeginnerIcon';
import Button from '../../components/Button';
import Color from '../../constants/Color';
import DemoMessageItem from '../../components/Demo/DemoMessageItem';
import Headline from '../../components/Headline';
import Hr from '../../components/Hr';
import Image from '../../components/Image';
import Paragraph from '../../components/Paragraph';
import Plate from '../../components/Plate';
import Prefecture from '../../models/tag/Prefecture';
import ResponseDialog from '../../components/ResponseDialog';
import SelectFormField from '../../components/Form/SelectFormField';
import StepSection from '../../components/Section/StepSection';
import Text from '../../components/Text';
import TextFormField from '../../components/Form/TextFormField';
import UserIcon from '../../components/Icons/UserIcon';
import View from '../../components/View';
import actions from '../../actions';

class Application extends React.Component {
  state = {
    name: '',
    address: Prefecture.getItems(true)[0].value,
    email: '',
    twitterId: '',
    instagramId: '',
    url: '',
    pr: ''
  };

  render() {
    return (
      <View>
        <View style={{ position: 'relative', height: 211 }}>
          <Image
            source={require('../../assets/img/model/top/header.jpg')}
            style={{ height: 211, position: 'absolute' }}
          />
          <View
            style={{
              flex: 1,
              justifyContent: 'space-between',
              zIndex: 1,
              padding: 16
            }}
          >
            <View
              style={{
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Image
                source={require('../../assets/img/model/top/logo.png')}
                width="109px"
                style={{ paddingTop: 32 }}
              />
            </View>
            <Text
              mincho
              demibold
              size={2}
              style={{
                fontSize: 17,
                color: Color.white,
                textAlign: 'center',
                padding: 8
              }}
            >
              「高単価で撮影モデル」
            </Text>
          </View>
        </View>
        <View style={{ backgroundColor: Color.mazenta, padding: 32 }}>
          <Paragraph bold style={{ color: Color.white, textAlign: 'center' }}>
            {`potore（ポトレ）は
            あなたの空き時間で
            気軽に撮影モデルのお仕事ができる
            個撮のマッチングサービスです。`}
          </Paragraph>
        </View>
        <View
          style={{
            backgroundColor: Color.white,
            padding: '64px 16px 64px 16px'
          }}
        >
          <Headline size={1} mincho demibold style={{ marginBottom: 32 }}>
            potoreで、撮影モデルになろう
          </Headline>
          <Plate
            style={{
              backgroundColor: Color.white,
              flexDirection: 'row',
              marginBottom: 8
            }}
          >
            <View
              style={{
                width: 90,
                height: 90,
                backgroundColor: Color.celadonBlue,
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <BeginnerIcon
                fill={Color.white}
                size={48}
                // style={{ marginBottom: 4 }}
              />
            </View>
            <View style={{ flex: 1, padding: 16 }}>
              <Headline
                size={3}
                style={{ color: Color.mazenta, marginBottom: 2 }}
              >
                <Text style={{ fontSize: 11, color: Color.black }}>
                  撮影モデル
                </Text>
                未経験OK
              </Headline>
              <Paragraph style={{ fontSize: 11, color: Color.darkGrey }}>
                経験がなくても、撮影モデルのお仕事ができます。
              </Paragraph>
            </View>
          </Plate>
          <Plate
            style={{
              backgroundColor: Color.white,
              flexDirection: 'row',
              marginBottom: 8
            }}
          >
            <View
              style={{
                width: 90,
                height: 90,
                backgroundColor: Color.celadonBlue,
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <CheckDecagramIconSvg
                fill={Color.white}
                width={48}
                height={48}
                viewBox="0 0 24 24"
                style={{ marginBottom: 4 }}
              />
            </View>
            <View style={{ flex: 1, padding: 16 }}>
              <Headline
                size={3}
                style={{ color: Color.mazenta, marginBottom: 2 }}
              >
                <Text style={{ fontSize: 11, color: Color.black }}>
                  前日キャンセルを
                </Text>
                全額保証
              </Headline>
              <Paragraph style={{ fontSize: 11, color: Color.darkGrey }}>
                急なキャンセルでも報酬が保証されます。
              </Paragraph>
            </View>
          </Plate>
          <Plate
            style={{
              backgroundColor: Color.white,
              flexDirection: 'row',
              marginBottom: 8
            }}
          >
            <View
              style={{
                width: 90,
                height: 90,
                backgroundColor: Color.celadonBlue,
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <SackPercentIconSvg
                fill={Color.white}
                width={48}
                height={48}
                viewBox="0 0 24 24"
                style={{ marginBottom: 4 }}
              />
            </View>
            <View style={{ flex: 1, padding: 16 }}>
              <Headline
                size={3}
                style={{ color: Color.mazenta, marginBottom: 2 }}
              >
                <Text style={{ fontSize: 11, color: Color.black }}>
                  報酬バック率
                </Text>
                80%
              </Headline>
              <Paragraph style={{ fontSize: 11, color: Color.darkGrey }}>
                設定した料金の80%が報酬として還元されます。
              </Paragraph>
            </View>
          </Plate>
        </View>

        <View>
          <View>
            <Plate
              style={{
                backgroundColor: Color.white,
                margin: '32px 16px 32px 16px'
              }}
            >
              <Image
                source={require('../../assets/img/model/top/headline1.jpg')}
                width="100%"
                height="auto"
              />
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: 24
                }}
              >
                <View
                  style={{
                    borderRadius: 56,
                    width: 56,
                    height: 56,
                    backgroundColor: Color.mazenta,
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 8,
                    marginRight: 16
                  }}
                >
                  <CashMultipleIconSvg
                    fill={Color.white}
                    width={32}
                    height={32}
                    viewBox="0 0 24 24"
                    style={{ marginBottom: 4 }}
                  />
                </View>
                <View>
                  <Text style={{ fontSize: 15 }}>街撮り・カフェ撮りで</Text>
                  <View
                    style={{ flexDirection: 'row', alignItems: 'baseline' }}
                  >
                    <Text bold style={{ fontSize: 24, color: Color.mazenta }}>
                      報酬
                    </Text>
                    <Text style={{ fontSize: 15, color: Color.mazenta }}>
                      をGET！
                    </Text>
                  </View>
                </View>
              </View>
            </Plate>
            <View style={{ marginBottom: 64 }}>
              <Headline
                size={1}
                mincho
                demibold
                style={{ marginBottom: 24, textAlign: 'center' }}
              >
                撮影までの流れ
              </Headline>
              <View style={{ padding: '0 16px' }}>
                <StepSection symbol={1} style={{ marginBottom: 16 }}>
                  <Paragraph onerow style={{ fontSize: 15 }}>
                    <Text style={{ color: Color.mazenta }}>
                      希望する撮影料金
                    </Text>
                    を設定して
                    <br />
                    募集を開始！
                  </Paragraph>
                </StepSection>
                <StepSection symbol={2} style={{ marginBottom: 16 }}>
                  <Paragraph onerow style={{ fontSize: 15 }}>
                    <Text style={{ color: Color.mazenta }}>
                      撮影依頼メッセージ
                    </Text>
                    が届いたら
                    <br />
                    撮影日を設定！
                  </Paragraph>
                </StepSection>
                <StepSection symbol={3} style={{ marginBottom: 16 }}>
                  <Paragraph onerow style={{ fontSize: 15 }}>
                    撮影が終わり、報酬が確定したら
                    <br />
                    <Text style={{ color: Color.mazenta }}>
                      報酬は運営からお支払い
                    </Text>
                    ！
                  </Paragraph>
                </StepSection>
              </View>
            </View>
          </View>
        </View>

        <View style={{ backgroundColor: Color.white }}>
          <View>
            <Plate
              style={{
                backgroundColor: Color.white,
                margin: '32px 16px 32px 16px'
              }}
            >
              <Image
                source={require('../../assets/img/model/top/headline2.jpg')}
                width="100%"
                height="auto"
              />
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: 24
                }}
              >
                <View
                  style={{
                    borderRadius: 56,
                    width: 56,
                    height: 56,
                    backgroundColor: Color.mazenta,
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 8,
                    marginRight: 16
                  }}
                >
                  <ShieldCheckIconSvg
                    fill={Color.white}
                    width={32}
                    height={32}
                    viewBox="0 0 24 24"
                    style={{ marginBottom: 4 }}
                  />
                </View>
                <View>
                  <Text style={{ fontSize: 15 }}>高単価の審査済み顧客で</Text>
                  <View
                    style={{ flexDirection: 'row', alignItems: 'baseline' }}
                  >
                    <Text bold style={{ fontSize: 24, color: Color.mazenta }}>
                      安心・安全
                    </Text>
                    <Text style={{ fontSize: 15, color: Color.mazenta }}>
                      ！
                    </Text>
                  </View>
                </View>
              </View>
            </Plate>
            <View style={{ marginBottom: 64 }}>
              <Headline
                size={1}
                mincho
                demibold
                style={{ marginBottom: 24, textAlign: 'center' }}
              >
                安心・安全への取り組み
              </Headline>
              <View style={{ padding: '0 16px' }}>
                <Plate
                  style={{
                    backgroundColor: Color.white,
                    flexDirection: 'row',
                    marginBottom: 8
                  }}
                >
                  <View
                    style={{
                      width: 90,
                      height: 90,
                      backgroundColor: Color.emerald,
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <ShieldAccountIconSvg
                      fill={Color.white}
                      width={48}
                      height={48}
                      viewBox="0 0 24 24"
                      style={{ marginBottom: 4 }}
                    />
                  </View>
                  <View style={{ flex: 1, padding: 16 }}>
                    <Headline
                      size={3}
                      style={{ color: Color.mazenta, marginBottom: 2 }}
                    >
                      <Text style={{ fontSize: 11, color: Color.black }}>
                        カード審査で
                      </Text>
                      本人確認済み
                    </Headline>
                    <Paragraph style={{ fontSize: 11, color: Color.darkGrey }}>
                      VISAカード、Masterカードユーザーがサービスを利用可能
                    </Paragraph>
                  </View>
                </Plate>
                <Plate
                  style={{
                    backgroundColor: Color.white,
                    flexDirection: 'row',
                    marginBottom: 8
                  }}
                >
                  <View
                    style={{
                      width: 90,
                      height: 90,
                      backgroundColor: Color.emerald,
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <ImageOffIconSvg
                      fill={Color.white}
                      width={48}
                      height={48}
                      viewBox="0 0 24 24"
                      style={{ marginBottom: 4 }}
                    />
                  </View>
                  <View style={{ flex: 1, padding: 16 }}>
                    <Headline
                      size={3}
                      style={{ color: Color.mazenta, marginBottom: 2 }}
                    >
                      <Text style={{ fontSize: 11, color: Color.black }}>
                        条件提示で
                      </Text>
                      写真利用を制限
                    </Headline>
                    <Paragraph style={{ fontSize: 11, color: Color.darkGrey }}>
                      室内NGや個室NGなど、条件を予め提示
                    </Paragraph>
                  </View>
                </Plate>
                <Plate
                  style={{
                    backgroundColor: Color.white,
                    flexDirection: 'row'
                  }}
                >
                  <View
                    style={{
                      width: 90,
                      height: 90,
                      backgroundColor: Color.emerald,
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <CommentEyeIconSvg
                      fill={Color.white}
                      width={48}
                      height={48}
                      viewBox="0 0 24 24"
                      style={{ marginBottom: 4 }}
                    />
                  </View>
                  <View style={{ flex: 1, padding: 16 }}>
                    <Headline
                      size={3}
                      style={{ color: Color.mazenta, marginBottom: 2 }}
                    >
                      <Text style={{ fontSize: 11, color: Color.black }}>
                        撮影者の
                      </Text>
                      評判を閲覧可能
                    </Headline>
                    <Paragraph style={{ fontSize: 11, color: Color.darkGrey }}>
                      ユーザーの評判を公開し、モデルの安全性を向上
                    </Paragraph>
                  </View>
                </Plate>
              </View>
            </View>
          </View>
        </View>

        <View>
          <View>
            <Plate
              style={{
                backgroundColor: Color.white,
                margin: '32px 16px 32px 16px'
              }}
            >
              <Image
                source={require('../../assets/img/model/top/headline3.jpg')}
                width="100%"
                height="auto"
              />
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: 24
                }}
              >
                <View
                  style={{
                    borderRadius: 56,
                    width: 56,
                    height: 56,
                    backgroundColor: Color.mazenta,
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 8,
                    marginRight: 16
                  }}
                >
                  <EmailIconSvg
                    fill={Color.white}
                    width={32}
                    height={32}
                    viewBox="0 0 24 24"
                    style={{ marginBottom: 4 }}
                  />
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={{ fontSize: 15 }}>個撮でよくある</Text>
                  <View
                    style={{ flexDirection: 'row', alignItems: 'baseline' }}
                  >
                    <Text style={{ fontSize: 15, color: Color.mazenta }}>
                      <Text bold style={{ fontSize: 24, color: Color.mazenta }}>
                        お悩みを解消
                      </Text>
                      ！
                    </Text>
                  </View>
                </View>
              </View>
            </Plate>
            <Headline
              size={1}
              mincho
              demibold
              style={{ marginBottom: 24, textAlign: 'center' }}
            >
              モデル活動を徹底サポート
            </Headline>
            <View style={{ padding: '0 16px' }}>
              <DemoMessageItem
                icon={
                  <UserIcon
                    source={require('../../assets/img/demo/user-icon.png')}
                    size={40}
                  />
                }
                text="お金のやり取りがめんどくさい…"
                side="left"
                style={{ marginBottom: 24 }}
              />
              <DemoMessageItem
                icon={
                  <View
                    style={{
                      width: 40,
                      height: 40,
                      position: 'relative',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <Image
                      source={require('../../assets/img/demo/support-icon-background.png')}
                      style={{ width: 40, height: 40, position: 'absolute' }}
                    />
                    <FaceAgentIconSvg
                      fill={Color.white}
                      width={28}
                      height={28}
                      viewBox="0 0 24 24"
                      style={{ zIndex: 1 }}
                    />
                  </View>
                }
                text={
                  <Paragraph style={{ fontSize: 13 }} onerow>
                    撮影開始後、料金を<Text bold>自動で決済</Text>
                    します。<Text bold>延長料金も自動で追加</Text>
                    されるので、お金のやり取りで嫌な思いをする必要がありません。
                  </Paragraph>
                }
                side="right"
                style={{ marginBottom: 24 }}
              />
              <Hr
                style={{
                  backgroundColor: Color.warmGrey,
                  height: 2,
                  marginBottom: 24
                }}
              />
              <DemoMessageItem
                icon={
                  <UserIcon
                    source={require('../../assets/img/demo/user-icon.png')}
                    size={40}
                  />
                }
                text="厄介な撮影者が怖い…"
                side="left"
                style={{ marginBottom: 24 }}
              />
              <DemoMessageItem
                icon={
                  <View
                    style={{
                      width: 40,
                      height: 40,
                      position: 'relative',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <Image
                      source={require('../../assets/img/demo/support-icon-background.png')}
                      style={{ width: 40, height: 40, position: 'absolute' }}
                    />
                    <FaceAgentIconSvg
                      fill={Color.white}
                      width={28}
                      height={28}
                      viewBox="0 0 24 24"
                      style={{ zIndex: 1 }}
                    />
                  </View>
                }
                text={
                  <Paragraph style={{ fontSize: 13 }} onerow>
                    高単価な条件で、質の低い撮影者を排除します。さらに、
                    <Text bold>
                      撮影者レビューで厄介客の情報が事前にチェック
                    </Text>
                    できます。
                  </Paragraph>
                }
                side="right"
                style={{ marginBottom: 24 }}
              />
              <Hr
                style={{
                  backgroundColor: Color.warmGrey,
                  height: 2,
                  marginBottom: 24
                }}
              />
              <DemoMessageItem
                icon={
                  <UserIcon
                    source={require('../../assets/img/demo/user-icon.png')}
                    size={40}
                  />
                }
                text="直接やり取りしたほうが高収入"
                side="left"
                style={{ marginBottom: 24 }}
              />
              <DemoMessageItem
                icon={
                  <View
                    style={{
                      width: 40,
                      height: 40,
                      position: 'relative',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <Image
                      source={require('../../assets/img/demo/support-icon-background.png')}
                      style={{ width: 40, height: 40, position: 'absolute' }}
                    />
                    <FaceAgentIconSvg
                      fill={Color.white}
                      width={28}
                      height={28}
                      viewBox="0 0 24 24"
                      style={{ zIndex: 1 }}
                    />
                  </View>
                }
                text={
                  <Paragraph style={{ fontSize: 13 }} onerow>
                    希望の金額に手数料を乗せて募集しましょう。また、通常の撮影では
                    <Text bold>延長料金が取りづらい</Text>
                    ことがありますが、potoreなら
                    <Text bold>自然に延長</Text>
                    をしてもらうことができます。
                  </Paragraph>
                }
                side="right"
                style={{ marginBottom: 24 }}
              />
            </View>
          </View>
        </View>
        <View style={{ backgroundColor: Color.mazenta, padding: 32 }}>
          <Paragraph
            bold
            style={{ color: Color.white, textAlign: 'center', lineHeight: 2 }}
            onerow
          >
            potore（ポトレ）は
            <br />
            <Text style={{ fontSize: 20, color: Color.white }}>高単価</Text>
            ユーザー向けの
            <br />
            個撮のマッチングサービスです。
            <br />
            <Text style={{ fontSize: 20, color: Color.white }}>30分</Text>
            あたりの撮影料金は、
            <br />
            <Text style={{ fontSize: 20, color: Color.white }}>
              5,000円〜20,000円
            </Text>
            が目安です。
          </Paragraph>
        </View>
        <View style={{ position: 'relative', height: 158 }}>
          <Image
            source={require('../../assets/img/model/top/stimulus.jpg')}
            style={{ height: 158, position: 'absolute' }}
          />
          <View style={{ zIndex: 1, flex: 1, justifyContent: 'center' }}>
            <Paragraph
              mincho
              demibold
              size={2}
              style={{
                fontSize: 17,
                color: Color.white,
                textAlign: 'center'
              }}
            >
              さぁ、potoreで{'\n'}撮影モデルをはじめましょう
            </Paragraph>
          </View>
        </View>
        <View style={{ padding: '64px 0 0 0', backgroundColor: Color.white }}>
          <Headline
            size={1}
            mincho
            demibold
            style={{ marginBottom: 24, textAlign: 'center' }}
          >
            ご応募
          </Headline>
          <View style={{ padding: '0 16px' }}>
            <Paragraph containerStyle={{ marginBottom: 24 }}>
              potoreの撮影モデルは完全審査制です。
              下記のフォームからのご応募をお待ちしております。
            </Paragraph>
            <Plate style={{ marginBottom: 32, paddingTop: 24 }}>
              <Headline
                size={2}
                style={{ color: Color.darkCeladonBlue, textAlign: 'center' }}
              >
                応募フォーム
              </Headline>
              <View style={{ padding: '24px 24px 0 24px' }}>
                <TextFormField
                  value={this.state.name}
                  name="name"
                  direction="column"
                  onChangeText={name => {
                    this.setState({
                      name
                    });
                  }}
                  label="お名前"
                  errorMessage={validate({
                    label: APPLICATION_VALIDATION_LABELS.name,
                    value: this.state.name,
                    rule: APPLICATION_VALIDATION_RULES.name
                  })}
                  style={{ marginBottom: 24 }}
                />
                <SelectFormField
                  direction="column"
                  label="居住地(都道府県のみ)"
                  name="address"
                  items={Prefecture.getItems()}
                  defaultValue={this.state.address}
                  onChangeValue={value => this.setState({ address: value })}
                  style={{ marginBottom: 24 }}
                />
                <TextFormField
                  value={this.state.email}
                  name="email"
                  direction="column"
                  onChangeText={email => {
                    this.setState({
                      email
                    });
                  }}
                  label="連絡用メールアドレス"
                  errorMessage={validate({
                    label: APPLICATION_VALIDATION_LABELS.email,
                    value: this.state.email,
                    rule: APPLICATION_VALIDATION_RULES.email
                  })}
                  style={{ marginBottom: 24 }}
                />
                <TextFormField
                  value={this.state.twitterId}
                  name="twitterId"
                  direction="column"
                  onChangeText={twitterId => {
                    this.setState({
                      twitterId
                    });
                  }}
                  label="Twitter ID"
                  errorMessage={validate({
                    label: APPLICATION_VALIDATION_LABELS.twitterId,
                    value: this.state.twitterId,
                    rule: APPLICATION_VALIDATION_RULES.twitterId
                  })}
                  style={{ marginBottom: 24 }}
                />
                <TextFormField
                  value={this.state.instagramId}
                  name="instagramId"
                  direction="column"
                  onChangeText={instagramId => {
                    this.setState({
                      instagramId
                    });
                  }}
                  label="Instagram ID"
                  errorMessage={validate({
                    label: APPLICATION_VALIDATION_LABELS.instagramId,
                    value: this.state.instagramId,
                    rule: APPLICATION_VALIDATION_RULES.instagramId
                  })}
                  style={{ marginBottom: 24 }}
                />
                <TextFormField
                  value={this.state.url}
                  name="url"
                  direction="column"
                  onChangeText={url => {
                    this.setState({
                      url
                    });
                  }}
                  label="ホームページ (任意)"
                  errorMessage={validate({
                    label: APPLICATION_VALIDATION_LABELS.url,
                    value: this.state.url,
                    rule: APPLICATION_VALIDATION_RULES.url
                  })}
                  style={{ marginBottom: 24 }}
                />
                <TextFormField
                  multiline
                  value={this.state.pr}
                  rows={5}
                  name="pr"
                  direction="column"
                  onChangeText={pr => {
                    this.setState({
                      pr
                    });
                  }}
                  label="自己PR (任意)"
                  errorMessage={validate({
                    label: APPLICATION_VALIDATION_LABELS.pr,
                    value: this.state.pr,
                    rule: APPLICATION_VALIDATION_RULES.pr
                  })}
                  style={{ marginBottom: 24 }}
                />
                <Paragraph style={{ marginBottom: 16 }}>
                  ご応募の合格結果は、返信をもって通達とさせていただきます。
                </Paragraph>
              </View>
              <Button
                disable={this.props.application.isCreateLoading}
                onClick={() => {
                  const errorMessages = validateAll({
                    labels: APPLICATION_VALIDATION_LABELS,
                    params: this.state,
                    rules: APPLICATION_VALIDATION_RULES
                  });

                  if (errorMessages) {
                    return alert(errorMessages);
                  }

                  this.props.createApplication({
                    ...this.state
                  });
                }}
              >
                応募する
              </Button>
            </Plate>
          </View>
        </View>
        <ResponseDialog
          isLoading={this.props.application.isCreateLoading}
          isFailed={this.props.application.isCreateFailed}
          completeMessage="応募が完了しました。メールにて当選通知を送らせていただきます。２〜７営業日ほどお待ち下さい。"
          onComplete={() => {
            this.setState({
              name: '',
              email: '',
              twitterId: '',
              instagramId: '',
              url: '',
              pr: ''
            });
          }}
          onError={() => {
            this.props.recoverCreateApplication();
          }}
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Application);
