import * as types from '../constants/ActionTypes';
import ErrorMessages from '../constants/ErrorMessages';
import RewardHistory from '../models/RewardHistory';
import { getCollection } from '../utils/Firebase';
import { moment } from '../utils/Time';

// get rewardHistories
function fetchGetRewardHistories() {
  return {
    type: types.FETCH_GET_REWARD_HISTORIES
  };
}

function fetchGetRewardHistoriesSuccess(list) {
  return {
    type: types.FETCH_GET_REWARD_HISTORIES_SUCCESS,
    state: {
      list
    }
  };
}

function fetchGetRewardHistoriesFailed(error) {
  return {
    type: types.FETCH_GET_REWARD_HISTORIES_FAILED,
    error
  };
}

export function recoverGetRewardHistories() {
  return {
    type: types.FETCH_GET_REWARD_HISTORIES_RECOVER
  };
}

export function getRewardHistories(
  filter = {
    where: [
      'createdAt',
      '>=',
      moment()
        .startOf('month')
        .toDate()
    ]
  }
) {
  return (dispatch, getState) => {
    const { auth } = getState();

    dispatch(fetchGetRewardHistories());

    let query = getCollection('models')
      .doc(auth.uid)
      .collection('rewardHistories')
      .where(...filter.where)
      .orderBy('createdAt', 'desc');

    const nextMonthDate = moment(filter.where[2])
      .clone()
      .add(1, 'month')
      .toDate();

    return query
      .get()
      .then(({ docs }) => {
        const list = docs.reduce((acc, doc) => {
          const data = new RewardHistory({
            id: doc.id,
            ...doc.data()
          }).fromFirestore();

          if (nextMonthDate < data.createdAt) {
            return acc;
          }

          return [...acc, data];
        }, []);

        return dispatch(fetchGetRewardHistoriesSuccess(list));
      })
      .catch(error => {
        if (error) {
          return dispatch(
            fetchGetRewardHistoriesFailed({
              code: error.code,
              message: ErrorMessages[error.code]
            })
          );
        }
      });
  };
}
