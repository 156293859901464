import React from 'react';
import numeral from 'numeral';

import Color from '../../constants/Color';
import Costume from '../../models/tag/Costume';
import GalleryPreview from '../List/GalleryPreview';
import Impression from '../../models/tag/Impression';
import Label from '../Form/Label';
import Paragraph from '../Paragraph';
import Permission from '../../models/Permission';
import Physique from '../../models/tag/Physique';
import Prefecture from '../../models/tag/Prefecture';
import SectionItem from '../Section/SectionItem';
import TagList from '../Tag/TagList';
import Text from '../Text';
import View from '../View';

export default function ModelPreview({
  data,
  style = {},
  containerStyle = {}
}) {
  return (
    <View
      style={{
        backgroundColor: Color.white,
        ...style
      }}
    >
      <GalleryPreview data={data.galleries} />
      <View
        style={{
          flexDirection: 'row',
          padding: '16px 24px',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Text bold style={{ fontSize: 13 }}>
          撮影30分あたり
        </Text>
        <Text bold style={{ fontSize: 13 }}>
          <Text style={{ color: Color.mazenta, fontSize: 20 }}>
            {numeral(data.price).format('0,0')}
          </Text>
          P
        </Text>
      </View>
      <TagList
        data={[
          ...data.prefectures.map(prefecture => ({
            label: Prefecture.getLabel(prefecture)
          })),
          ...data.impressions.map(impression => ({
            label: Impression.getLabel(impression)
          })),
          ...data.physiques.map(physique => ({
            label: Physique.getLabel(physique)
          })),
          ...data.costumes.map(costume => ({
            label: Costume.getLabel(costume)
          }))
        ]}
        style={{ padding: '0 16px', marginBottom: 32 }}
      />
      <View style={{ padding: '0 16px' }}>
        <Label style={{ marginBottom: 24 }}>基本情報</Label>
        <SectionItem
          title="身長"
          content={`${data.height}cm`}
          style={{ marginBottom: 24 }}
        />
        <SectionItem
          title="居住地"
          content={`${Prefecture.getLabel(data.birthplace)}`}
          style={{ marginBottom: 24 }}
        />
        <SectionItem
          title="出身地"
          content={`${Prefecture.getLabel(data.address)}`}
          style={{ marginBottom: 64 }}
        />
        <Label style={{ marginBottom: 24 }}>自己紹介</Label>
        <Paragraph
          style={{ color: Color.darkGrey, fontSize: 13 }}
          containerStyle={{ marginBottom: 64 }}
        >
          {data.pr}
        </Paragraph>
        <Label style={{ marginBottom: 24 }}>写真の取扱について</Label>
        <SectionItem
          title="個人利用"
          content={`${Permission.getLabel(data.personalUsage)}`}
          style={{ marginBottom: 24 }}
        />
        <SectionItem
          title="グループ鑑賞"
          content={`${Permission.getLabel(data.groupUsage)}`}
          style={{ marginBottom: 24 }}
        />
        <SectionItem
          title="SNS掲載"
          content={`${Permission.getLabel(data.snsUsage)}`}
          style={{ marginBottom: 24 }}
        />
        <SectionItem
          title="広告利用"
          content={`${Permission.getLabel(data.adUsage)}`}
          style={{ marginBottom: 24 }}
        />
        <SectionItem
          title="再販・商用利用"
          content={`${Permission.getLabel(data.resaleUsage)}`}
          style={{ marginBottom: 64 }}
        />
      </View>
    </View>
  );
}
