import { request } from '../utils/Network';
import * as types from '../constants/ActionTypes';

// get chat histories
function fetchPurchaseShop() {
  return {
    type: types.FETCH_PURCHASE_SHOP
  };
}

function fetchPurchaseShopSuccess(list) {
  return {
    type: types.FETCH_PURCHASE_SHOP_SUCCESS,
    state: {
      list
    }
  };
}

function fetchPurchaseShopFailed(error) {
  return {
    type: types.FETCH_PURCHASE_SHOP_FAILED,
    error
  };
}

export function recoverPurchaseShop() {
  return {
    type: types.FETCH_PURCHASE_SHOP_RECOVER
  };
}

export function purchaseShop(value) {
  return (dispatch, getState) => {
    dispatch(fetchPurchaseShop());

    return request(dispatch, 'POST', '/purchaseShop', {
      params: {
        value
      }
    }).then(({ response, error }) => {
      if (error) {
        return dispatch(fetchPurchaseShopFailed(error));
      }

      return dispatch(fetchPurchaseShopSuccess(response.id, response));
    });
  };
}
