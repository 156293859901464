import React from 'react';

import View from '../../components/View';
import Color from '../../constants/Color';
import { bindActionCreators } from 'redux';
import actions from '../../actions';
import { connect } from 'react-redux';

class ReserveHeader extends React.Component {
  componentDidMount() {
    this.props.subscribeReserveHistory(
      this.props.consumerId,
      this.props.modelId
    );
  }

  componentWillUnmount() {
    this.props.unsubscribeReserveHistory(
      this.props.consumerId,
      this.props.modelId
    );
  }

  render() {
    const { data = [], renderItem = () => {}, style = {} } = this.props;

    return (
      <View
        style={{
          backgroundColor: Color.white,
          ...style
        }}
      >
        {data.map(datum => {
          return renderItem({ item: datum });
        })}
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReserveHeader);
