import { getHash } from '../utils/Reducer';
import * as types from '../constants/ActionTypes';

export const GIVED_LOGIN_BONUS = 'GIVED_LOGIN_BONUS';

const initialState = {
  myself: null,
  isGetMyselfLoading: false,
  isGetMyselfFailed: false,
  getMyselfError: null,
  list: [],
  hash: {},
  isGetLoading: false,
  isGetFailed: false,
  getError: null,
  isUpdateLoading: false,
  isUpdateFailed: false,
  updateError: null
};

export default (state = initialState, action) => {
  let newHash;

  let newList;

  switch (action.type) {
    // get myself
    case types.FETCH_GET_MYSELF_USER:
      return {
        ...state,
        isGetMyselfLoading: true
      };

    case types.FETCH_GET_MYSELF_USER_SUCCESS:
      return {
        ...state,
        myself: action.state.user,
        isGetMyselfLoading: false,
        isGetMyselfFailed: false
      };

    case types.FETCH_GET_MYSELF_USER_FAILED:
      return {
        ...state,
        isGetMyselfLoading: false,
        isGetMyselfFailed: true,
        getMyselfError: action.error
      };

    case types.FETCH_GET_MYSELF_USER_RECOVER:
      return {
        ...state,
        isGetMyselfFailed: false
      };

    // get users
    case types.FETCH_GET_USERS:
      return {
        ...state,
        list: action.state.reset ? [] : state.list,
        isGetLoading: true
      };

    case types.FETCH_GET_USERS_SUCCESS:
      newHash = getHash(action.state.list, state.hash, 'id');

      newList = action.state.reset
        ? action.state.list.map(item => item.id)
        : [...state.list, ...action.state.list.map(item => item.id)];

      return {
        ...state,
        list: newList,
        hash: newHash,
        isGetLoading: false,
        isGetFailed: false
      };

    case types.FETCH_GET_USERS_FAILED:
      return {
        ...state,
        isGetLoading: false,
        isGetFailed: true,
        getError: action.error
      };

    case types.FETCH_GET_USERS_RECOVER:
      return {
        ...state,
        isGetFailed: false
      };

    // update user
    case types.FETCH_UPDATE_MYSELF_USER:
      return {
        ...state,
        isUpdateLoading: true
      };

    case types.FETCH_UPDATE_MYSELF_USER_SUCCESS:
      return {
        ...state,
        myself: {
          ...state.myself,
          ...action.state.user
        },
        isUpdateLoading: false,
        isUpdateFailed: false
      };

    case types.FETCH_UPDATE_MYSELF_USER_FAILED:
      return {
        ...state,
        isUpdateLoading: false,
        isUpdateFailed: true,
        updateError: action.error
      };

    case types.FETCH_UPDATE_MYSELF_USER_RECOVER:
      return {
        ...state,
        isUpdateFailed: false
      };

    // add badge
    case types.FETCH_ADD_BADGE_SUCCESS:
      return {
        ...state,
        myself: {
          ...state.myself,
          badges: [...((state.myself && state.myself.badges) || []), action.state.params]
        }
      };

    // delete badge
    case types.FETCH_REMOVE_BADGE_SUCCESS:
      return {
        ...state,
        myself: {
          ...state.myself,
          badges: state.myself.badges.filter(
            badge => badge.id !== action.state.id
          )
        }
      };

    // sign out
    case types.AUTH_SIGN_OUT_SUCCESS:
      return {
        ...state,
        myself: null
      };

    default:
      return state;
  }
};
