import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../../../../actions';
import NavigationHeader from '../../../../../components/Navigator/NavigationHeader';
import SessionDetail from '../../../../../components/Session/SessionDetail';
import View from '../../../../../components/View';
import ReviewSection from '../../../../../containers/review/ReviewSection';

class MypageSessionDetail extends React.Component {
  componentDidMount() {
    this.props.getSessionHistory(
      'model',
      this.props.auth.uid,
      this.props.match.params.sessionId
    );
  }

  render() {
    const sessionHistory = this.props.sessionHistory.hash[
      this.props.match.params.sessionId
    ];

    return (
      <View>
        <NavigationHeader title="撮影履歴" goBack={this.props.history.goBack} />
        {sessionHistory && (
          <View>
            <SessionDetail data={sessionHistory} style={{ marginBottom: 16 }} />
            <ReviewSection
              type="consumer"
              uid={sessionHistory.user.id}
              reviewHistoryId={sessionHistory.id}
              onSubmit={params => {
                this.props.upsertReviewHistory(
                  'consumer',
                  sessionHistory.user.id,
                  sessionHistory.id,
                  {
                    ...params,
                    reviewerId: this.props.auth.uid
                  }
                );
              }}
            />
          </View>
        )}
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MypageSessionDetail);
