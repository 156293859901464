import { getCollection } from '../utils/Firebase';
import Badge from '../models/Badge';
import ErrorMessages from '../constants/ErrorMessages';
import Role from '../utils/Role';
import * as types from '../constants/ActionTypes';

let badgeListeners = {};

// get notifications
function fetchGetBadges() {
  return {
    type: types.FETCH_GET_BADGES
  };
}

function fetchGetBadgesSuccess(list) {
  return {
    type: types.FETCH_GET_BADGES_SUCCESS,
    state: {
      list
    }
  };
}

function fetchGetBadgesFailed(error) {
  return {
    type: types.FETCH_GET_BADGES_FAILED,
    error
  };
}

export function recoverGetBadges() {
  return {
    type: types.FETCH_GET_BADGES_RECOVER
  };
}

export function getBadges(role, uid) {
  return (dispatch, getState) => {
    dispatch(fetchGetBadges());

    let query = getCollection(Role.getCollection(role))
      .doc(uid)
      .collection('badges');

    return query
      .get()
      .then(async ({ docs }) => {
        const list = await Promise.all(
          docs.map(async doc => {
            return new Badge({
              id: doc.id,
              ...doc.data()
            }).fromFirestore();
          })
        );

        return dispatch(fetchGetBadgesSuccess(list));
      })
      .catch(error => {
        if (error) {
          return dispatch(
            fetchGetBadgesFailed({
              code: error.code,
              message: ErrorMessages[error.code]
            })
          );
        }
      });
  };
}

// clear badge
function fetchDeleteBadges() {
  return {
    type: types.FETCH_DELETE_BADGE
  };
}

function fetchDeleteBadgesSuccess(id) {
  return {
    type: types.FETCH_DELETE_BADGE_SUCCESS,
    state: {
      id
    }
  };
}

function fetchDeleteBadgesFailed(error) {
  return {
    type: types.FETCH_DELETE_BADGE_FAILED,
    error
  };
}

export function recoverDeleteBadges() {
  return {
    type: types.FETCH_DELETE_BADGE_RECOVER
  };
}

export function deleteBadge(role, uid, id) {
  return (dispatch, getState) => {
    dispatch(fetchDeleteBadges());

    let query = getCollection(Role.getCollection(role))
      .doc(uid)
      .collection('badges')
      .doc(id);

    return query
      .delete()
      .then(() => {
        return dispatch(fetchDeleteBadgesSuccess(id));
      })
      .catch(error => {
        if (error) {
          return dispatch(
            fetchDeleteBadgesFailed({
              code: error.code,
              message: ErrorMessages[error.code]
            })
          );
        }
      });
  };
}

// subscribe badge
function fetchAddBadge() {
  return {
    type: types.FETCH_SUBSCRIBE_BADGE
  };
}

function fetchAddBadgeSuccess(id, params) {
  return {
    type: types.FETCH_ADD_BADGE_SUCCESS,
    state: {
      id,
      params
    }
  };
}

function fetchDeleteBadgeSuccess(id) {
  return {
    type: types.FETCH_REMOVE_BADGE_SUCCESS,
    state: {
      id
    }
  };
}

export function subscribeBadge(role, uid) {
  return (dispatch, getState) => {
    dispatch(fetchAddBadge());

    const listenerId = uid;

    badgeListeners[listenerId] && badgeListeners[listenerId]();

    badgeListeners[listenerId] = getCollection(Role.getCollection(role))
      .doc(uid)
      .collection('badges')
      .onSnapshot(snapshot => {
        snapshot.docChanges().forEach(async change => {
          const { doc } = change;

          if (change.type === 'added') {
            const badge = {
              id: doc.id,
              ...doc.data()
            };

            return dispatch(fetchAddBadgeSuccess(doc.id, badge));
          }

          if (change.type === 'modified') {
            console.log('Modified badge: ', change.doc.data());
          }

          if (change.type === 'removed') {
            return dispatch(fetchDeleteBadgeSuccess(doc.id));
          }
        });
      });

    return null;
  };
}

// unsubscribe chat history
export function unsubscribeBadge(uid) {
  return (dispatch, getState) => {
    const listenerId = uid;

    badgeListeners[listenerId] && badgeListeners[listenerId]();

    return null;
  };
}
