import { getHash } from '../utils/Reducer';
import * as types from '../constants/ActionTypes';

const initialState = {
  list: [],
  hash: {},
  isGetLoading: false,
  isGetFailed: false,
  getError: null,
  isCreateLoading: false,
  isCreateFailed: false,
  createError: null,
  isUpdateLoading: false,
  isUpdateFailed: false,
  updateError: null
};

export default (state = initialState, action) => {
  let newHash;

  switch (action.type) {
    // get point histories
    case types.FETCH_GET_POINT_HISTORIES:
      return {
        ...state,
        list: [],
        isGetLoading: true
      };

    case types.FETCH_GET_POINT_HISTORIES_SUCCESS:
      newHash = getHash(action.state.list, state.hash, 'id');

      return {
        ...state,
        list: action.state.list.map(item => item.id),
        hash: newHash,
        isGetLoading: false,
        isGetFailed: false
      };

    case types.FETCH_GET_POINT_HISTORIES_FAILED:
      return {
        ...state,
        isGetLoading: false,
        isGetFailed: true,
        getError: action.error
      };

    case types.FETCH_GET_POINT_HISTORIES_RECOVER:
      return {
        ...state,
        isGetFailed: false
      };

    default:
      return state;
  }
};
