export default class Physique {
  static getItems(withDefault = true) {
    const items = [
      { value: 'slender', label: 'スレンダー' },
      { value: 'glamor', label: 'グラマー' },
      { value: 'petite', label: '小柄' }
    ];

    if (withDefault) {
      items.unshift({
        label: '未選択',
        value: 'unselected',
        isDefault: true
      });
    }

    return items.map(item => ({ type: 'impression', ...item }));
  }

  static getLabel(value) {
    return Physique.getItems().find(item => item.value === value).label;
  }
}
