import React from 'react';
import Color from '../../constants/Color';
import FileInput from '../Form/FileInput';
import Modal from '../Modal/Modal';
import Text from '../Text';
import View from '../View';

export default class ActionSheet extends React.Component {
  render() {
    const { visible, menus = [], onClose = () => {} } = this.props;

    return (
      <Modal visible={visible} onClose={onClose}>
        <View
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: Color.transparent,
            padding: 16,
            justifyContent: 'flex-end'
          }}
        >
          <View style={{ flex: 1 }} onClick={onClose} />
          <View>
            {menus.map((menu, index) => {
              const isFirst = index === 0;

              const isLast = menus.length - 1 === index;

              const borderRadius = `${isFirst ? '8px 8px' : '0 0'} ${
                isLast ? '8px 8px' : '0 0'
              }`;

              return (
                <View
                  style={{
                    borderRadius,
                    backgroundColor: Color.white,
                    textAlign: 'center',
                    padding: 12,
                    marginBottom: !isLast ? 2 : 0
                  }}
                >
                  {menu.type === 'file' ? (
                    <FileInput
                      onChangeFile={image => menu.onChangeFile(image)}
                      style={{ marginBottom: 24 }}
                    >
                      <Text style={{ color: Color.celadonBlue }}>
                        {menu.label}
                      </Text>
                    </FileInput>
                  ) : (
                    <View onClick={() => menu.onClick()}>
                      <Text style={{ color: menu.color || Color.celadonBlue }}>
                        {menu.label}
                      </Text>
                    </View>
                  )}
                </View>
              );
            })}
            <View
              style={{
                marginTop: 16,
                borderRadius: 8,
                backgroundColor: Color.white,
                padding: 12,
                textAlign: 'center'
              }}
              onClick={() => {
                this.props.onClose();
              }}
            >
              <Text style={{ color: Color.celadonBlue }}>キャンセルする</Text>
            </View>
          </View>
        </View>
      </Modal>
    );
  }
}
