import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../../../../actions';
import Button from '../../../../../components/Button';
import NavigationHeader from '../../../../../components/Navigator/NavigationHeader';
import Section from '../../../../../components/Section/Section';
import Text from '../../../../../components/Text';
import View from '../../../../../components/View';
import Color from '../../../../../constants/Color';
import PointLineup from '../../../../../models/model/PointLineup';

class UserMypageShopIndex extends React.Component {
  componentDidMount() {
    this.props.getPointHistories();
  }

  render() {
    const { myself } = this.props.user;

    if (!myself) {
      return null;
    }

    return (
      <View>
        <NavigationHeader
          title="ポイント購入"
          goBack={this.props.history.goBack}
        />
        <Section
          style={{ alignItems: 'center', padding: 32, marginBottom: 16 }}
        >
          <Text bold style={{ color: Color.darkGrey, fontSize: 15 }}>
            所持ポイント
          </Text>
          <Text bold style={{ fontSize: 17 }}>
            <Text style={{ color: Color.mazenta, fontSize: 32 }}>
              {myself.currentPoint}
            </Text>
            P
          </Text>
        </Section>
        <Section style={{ marginBottom: 16 }}>
          {PointLineup.getItems().map(lineup => {
            const price = numeral(Math.round(lineup.value * 1.1)).format('0,0');

            return (
              <View
                key={`price-${lineup.value}`}
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: 16,
                  borderBottom: `1px solid ${Color.lightGrey}`
                }}
              >
                <Text>{lineup.label}</Text>
                <Button
                  tiny
                  style={{ width: 120 }}
                  onClick={() => {
                    if (
                      window.confirm(
                        `${price}円で${lineup.label}を購入します。よろしいですか？`
                      )
                    ) {
                      this.props.purchaseShop(lineup.value);
                    }
                  }}
                >
                  <Text style={{ color: Color.white }}>¥{price}</Text>
                </Button>
              </View>
            );
          })}
        </Section>
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserMypageShopIndex);
