import React from 'react';
import moment from 'moment';

import Color from '../../constants/Color';
import MaterialIcon from '../../utils/MaterialIcon';
import Paragraph from '../Paragraph';
import UserIcon from '../Icons/UserIcon';
import View from '../View';

export default function NotificationHistoryItem({
  data,
  type,
  user,
  selfId,
  createdAt,
  onTransit = () => {},
  style = {}
}) {
  switch (type) {
    case 'like':
      return (
        <View
          style={{
            flexDirection: 'row',
            padding: 16,
            backgroundColor: Color.white,
            borderBottom: `1px solid ${Color.middleGrey}`
          }}
          onClick={() => {
            if (data.modelToConsumer) {
              onTransit(`/message/${user.id}/models/${selfId}`);
            } else {
              onTransit(`/message/${selfId}/models/${user.id}`);
            }
          }}
        >
          <UserIcon
            source={user.iconImage}
            size={32}
            style={{ marginRight: 16 }}
          />
          <View style={{ flex: 1 }}>
            <Paragraph style={{ flex: 1, fontSize: 13 }} onerow>
              {user.name}さんから「いいね」が届きました！
            </Paragraph>
            <Paragraph
              style={{
                color: Color.darkGrey,
                fontSize: 11,
                textAlign: 'right'
              }}
            >
              {moment(createdAt).format('MM月DD日(ddd) HH:mm')}
            </Paragraph>
          </View>
          <View style={{ paddingLeft: 16, justifyContent: 'center' }}>
            <MaterialIcon
              icon="arrow_forward_ios"
              color={Color.black}
              size={15}
            />
          </View>
        </View>
      );
    default:
      return null;
  }
}
