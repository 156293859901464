import { getCollection } from '../utils/Firebase';
import ErrorMessages from '../constants/ErrorMessages';
import SessionHistory from '../models/SessionHistory';
import User from '../models/User';
import * as types from '../constants/ActionTypes';

// get sessionHistories
function fetchGetSessionHistories() {
  return {
    type: types.FETCH_GET_SESSION_HISTORIES
  };
}

function fetchGetSessionHistoriesSuccess(list) {
  return {
    type: types.FETCH_GET_SESSION_HISTORIES_SUCCESS,
    state: {
      list
    }
  };
}

function fetchGetSessionHistoriesFailed(error) {
  return {
    type: types.FETCH_GET_SESSION_HISTORIES_FAILED,
    error
  };
}

export function recoverGetSessionHistories() {
  return {
    type: types.FETCH_GET_SESSION_HISTORIES_RECOVER
  };
}

export function getSessionHistories(type) {
  return (dispatch, getState) => {
    const { auth } = getState();

    dispatch(fetchGetSessionHistories());

    let query = getCollection(type === 'consumer' ? 'consumers' : 'models')
      .doc(auth.uid)
      .collection('sessionHistories')
      .orderBy('createdAt', 'desc')
      .limit(10);

    return query
      .get()
      .then(async ({ docs }) => {
        const list = await Promise.all(
          docs.map(async doc => {
            const params = {
              id: doc.id,
              ...doc.data()
            };

            let user;

            if (params.modelId) {
              user = await User.get(params.modelId);
            }

            if (params.consumerId) {
              user = await User.get(params.consumerId);
            }

            return new SessionHistory({
              ...params,
              user
            }).fromFirestore();
          })
        );

        return dispatch(fetchGetSessionHistoriesSuccess(list));
      })
      .catch(error => {
        if (error) {
          return dispatch(
            fetchGetSessionHistoriesFailed({
              code: error.code,
              message: ErrorMessages[error.code]
            })
          );
        }
      });
  };
}

// get session history
function fetchGetSessionHistory() {
  return {
    type: types.FETCH_GET_SESSION_HISTORY
  };
}

function fetchGetSessionHistorySuccess(id, params) {
  return {
    type: types.FETCH_GET_SESSION_HISTORY_SUCCESS,
    state: {
      id,
      params
    }
  };
}

function fetchGetSessionHistoryFailed(error) {
  return {
    type: types.FETCH_GET_SESSION_HISTORY_FAILED,
    error
  };
}

export function recoverGetSessionHistory() {
  return {
    type: types.FETCH_GET_SESSION_HISTORY_RECOVER
  };
}

export function getSessionHistory(type, uid, sessionHistoryId) {
  return dispatch => {
    dispatch(fetchGetSessionHistory());

    let query = getCollection(type === 'model' ? 'models' : 'consumers')
      .doc(uid)
      .collection('sessionHistories')
      .doc(sessionHistoryId);

    return query
      .get()
      .then(async doc => {
        let user = {};

        if (type === 'model') {
          user = await User.get(doc.data().consumerId);
        }

        if (type === 'consumer') {
          user = await User.get(doc.data().modelId);
        }

        const sessionHistory = new SessionHistory({
          id: doc.id,
          user,
          ...doc.data()
        }).fromFirestore();

        const params = {
          ...sessionHistory
        };

        return dispatch(
          fetchGetSessionHistorySuccess(sessionHistoryId, params)
        );
      })
      .catch(error => {
        if (error) {
          return dispatch(
            fetchGetSessionHistoryFailed({
              code: error.code,
              message: ErrorMessages[error.code]
            })
          );
        }
      });
  };
}
