import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../../../../../actions';
import Button from '../../../../../../components/Button';
import SelectFormField from '../../../../../../components/Form/SelectFormField';
import Section from '../../../../../../components/Section/Section';
import View from '../../../../../../components/View';
import Model from '../../../../../../models/Model';
import Permission from '../../../../../../models/Permission';
import NavigationHeader from '../../../../../../components/Navigator/NavigationHeader';

class ModelMypageProfileInstructionEdit extends React.Component {
  state = {
    ...new Model().object
  };

  constructor(props) {
    super(props);

    const { myself = {} } = this.props.user;

    this.state = {
      ...new Model(myself || {}).object
    };
  }

  shouldComponentUpdate(nextProps) {
    if (
      this.props.user.isGetMyselfLoading &&
      !nextProps.user.isGetMyselfLoading
    ) {
      const { myself } = nextProps.user;

      this.setState({
        ...new Model(myself).object
      });
    }

    return true;
  }

  render() {
    if (!this.props.user.myself) {
      return null;
    }

    return (
      <View>
        <NavigationHeader
          title="写真の取扱について"
          goBack={this.props.history.goBack}
        />
        <Section
          style={{
            padding: 24
          }}
        >
          <SelectFormField
            label="個人利用"
            direction="column"
            name="personalUsage"
            helpText="個人利用を許可する場合はOKを選択してください。"
            items={Permission.getItems()}
            defaultValue={this.state.personalUsage}
            isSearchable
            onChangeValue={value => {
              this.setState({ personalUsage: value });
            }}
            style={{ marginBottom: 16 }}
          />
          <SelectFormField
            label="グループ鑑賞"
            direction="column"
            name="groupUsage"
            helpText="グループ鑑賞を許可する場合はOKを選択してください。"
            items={Permission.getItems()}
            defaultValue={this.state.groupUsage}
            isSearchable
            onChangeValue={value => {
              this.setState({ groupUsage: value });
            }}
            style={{ marginBottom: 16 }}
          />
          <SelectFormField
            label="SNS掲載"
            direction="column"
            name="snsUsage"
            helpText="SNS掲載を許可する場合はOKを選択してください。"
            items={Permission.getItems()}
            defaultValue={this.state.snsUsage}
            isSearchable
            onChangeValue={value => {
              this.setState({ snsUsage: value });
            }}
            style={{ marginBottom: 16 }}
          />
          <SelectFormField
            label="広告利用"
            direction="column"
            name="adUsage"
            helpText="広告利用を許可する場合はOKを選択してください。"
            items={Permission.getItems()}
            defaultValue={this.state.adUsage}
            isSearchable
            onChangeValue={value => {
              this.setState({ adUsage: value });
            }}
            style={{ marginBottom: 16 }}
          />
          <SelectFormField
            label="再販・商用利用"
            direction="column"
            name="resaleUsage"
            helpText="再販・商用利用を許可する場合はOKを選択してください。"
            items={Permission.getItems()}
            defaultValue={this.state.resaleUsage}
            isSearchable
            onChangeValue={value => {
              this.setState({ resaleUsage: value });
            }}
            style={{ marginBottom: 16 }}
          />
          <Button
            disable={this.props.user.isUpdateLoading}
            onClick={() => {
              this.props.updateMyselfUser({
                model: {
                  personalUsage: this.state.personalUsage,
                  snsUsage: this.state.snsUsage,
                  adUsage: this.state.adUsage,
                  resaleUsage: this.state.resaleUsage
                }
              });
            }}
          >
            保存する
          </Button>
        </Section>
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModelMypageProfileInstructionEdit);
