import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../../../../actions';
import NavigationHeader from '../../../../../components/Navigator/NavigationHeader';
import Section from '../../../../../components/Section/Section';
import SessionHistoryItem from '../../../../../components/SessionHistory/SessionHistoryItem';
import View from '../../../../../components/View';

class MypageSessionIndex extends React.Component {
  componentDidMount() {
    this.props.getSessionHistories('model');
  }

  render() {
    const { myself } = this.props.user;

    if (!myself) {
      return null;
    }

    return (
      <View>
        <NavigationHeader title="撮影履歴" goBack={this.props.history.goBack} />
        <Section style={{ marginBottom: 16 }}>
          {this.props.sessionHistory.list
            .map(id => this.props.sessionHistory.hash[id])
            .map(item => {
              return (
                <SessionHistoryItem
                  {...item}
                  onClick={() => {
                    this.props.history.push(`/model/mypage/session/${item.id}`);
                  }}
                />
              );
            })}
        </Section>
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MypageSessionIndex);
