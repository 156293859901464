import React from 'react';
import numeral from 'numeral';

import AcademicBackground from '../../models/AcademicBackground';
import AnnualIncome from '../../models/model/AnnualIncome';
import BrotherAndSister from '../../models/BrotherAndSister';
import Color from '../../constants/Color';
import GalleryPreview from '../List/GalleryPreview';
import Job from '../../models/Job';
import Label from '../Form/Label';
import Paragraph from '../Paragraph';
import Prefecture from '../../models/tag/Prefecture';
import SectionItem from '../Section/SectionItem';
import Text from '../Text';
import View from '../View';

export default function ConsumerPreview({
  data,
  style = {},
  containerStyle = {}
}) {
  return (
    <View
      style={{
        backgroundColor: Color.white,
        ...style
      }}
    >
      <GalleryPreview data={data.galleries} />
      <View
        style={{
          flexDirection: 'row',
          padding: '16px 24px',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Text bold style={{ fontSize: 13 }}>
          撮影回数
        </Text>
        <Text bold style={{ fontSize: 13 }}>
          <Text style={{ color: Color.mazenta, fontSize: 20 }}>
            {numeral(data.sessionCount).format('0,0')}
          </Text>
          回
        </Text>
      </View>
      <View style={{ padding: '0 16px' }}>
        <Label style={{ marginBottom: 24, marginTop: 32 }}>基本情報</Label>
        <SectionItem
          title="身長"
          content={`${data.height}cm`}
          style={{ marginBottom: 24 }}
        />
        <SectionItem
          title="居住地"
          content={`${Prefecture.getLabel(data.birthplace)}`}
          style={{ marginBottom: 24 }}
        />
        <SectionItem
          title="出身地"
          content={`${Prefecture.getLabel(data.address)}`}
          style={{ marginBottom: 24 }}
        />
        <SectionItem
          title="学歴"
          content={`${AcademicBackground.getLabel(data.academicBackground)}`}
          style={{ marginBottom: 24 }}
        />
        <SectionItem
          title="年収"
          content={`${AnnualIncome.getLabel(data.annualIncome)}`}
          style={{ marginBottom: 24 }}
        />
        <SectionItem
          title="職業"
          content={`${Job.getLabel(data.job)}`}
          style={{ marginBottom: 24 }}
        />
        <SectionItem
          title="兄弟/姉妹"
          content={`${BrotherAndSister.getLabel(data.brotherAndSister)}`}
          style={{ marginBottom: 24 }}
        />
        <Label style={{ marginBottom: 24 }}>自己紹介</Label>
        <Paragraph style={{ color: Color.darkGrey, fontSize: 13 }}>
          {data.pr}
        </Paragraph>
      </View>
    </View>
  );
}
