import Font from '../constants/Font';

export function getTextStyle({
  bold = false,
  demibold = false,
  mincho = false
}) {
  let fontFamily = {};

  if (mincho) {
    fontFamily = Font.style.mincho;
  }

  let fontWeight = {};

  if (bold) {
    fontWeight = {
      fontWeight: 'bold'
    };
  }

  if (demibold) {
    fontWeight = {
      fontWeight: 600
    };
  }

  return {
    ...fontFamily,
    ...fontWeight
  };
}
