import React from 'react';

import Color from '../../constants/Color';
import Text from '../Text';
import View from '../View';

export default function TabButton(props) {
  const { symbol, active, onClick, style = {} } = props;

  return (
    <View
      style={{ alignItems: 'center', padding: '8px 0', ...style }}
      onClick={onClick}
    >
      <symbol.icon
        fill={active ? Color.celadonBlue : Color.darkGrey}
        width={32}
        height={32}
        viewBox="0 0 24 24"
        style={{ ...style }}
      />
      <Text
        style={{
          fontSize: 13,
          color: active ? Color.celadonBlue : Color.darkGrey
        }}
      >
        {symbol.label}
      </Text>
    </View>
  );
}
