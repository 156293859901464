import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import {
  ChevronLeftIconSvg,
  PencilIconSvg
} from '../../../../../utils/MaterialIconSvg';
import Color from '../../../../../constants/Color';
import FileInput from '../../../../../components/Form/FileInput';
import Header from '../../../../../components/Header';
import Section from '../../../../../components/Section/Section';
import SectionTransitItem from '../../../../../components/Section/SectionTransitItem';
import Text from '../../../../../components/Text';
import UserIcon from '../../../../../components/Icons/UserIcon';
import View from '../../../../../components/View';
import actions from '../../../../../actions';

class UserMypageProfileEdit extends React.Component {
  render() {
    if (!this.props.user.myself) {
      return null;
    }

    return (
      <View>
        <Header
          title="プロフィール"
          right={
            <View
              onClick={() => this.props.history.goBack()}
              style={{ padding: 4 }}
            >
              <Text
                bold
                style={{
                  fontSize: 13
                }}
              >
                プレビュー
              </Text>
            </View>
          }
          left={
            <View
              onClick={() => this.props.history.push('/user/mypage')}
              style={{ padding: 4 }}
            >
              <ChevronLeftIconSvg
                fill={Color.black}
                width={24}
                height={24}
                viewBox="0 0 24 24"
                style={{ marginRight: 4 }}
              />
            </View>
          }
        />
        <Section
          style={{ alignItems: 'center', padding: 32, marginBottom: 16 }}
        >
          <FileInput
            onChangeFile={iconImage => {
              this.props.updateMyselfUser({ iconImage });
            }}
            ref="fileInput"
            style={{ marginBottom: 24 }}
          >
            <UserIcon
              source={this.props.user.myself.iconImage}
              size={88}
              style={{ marginBottom: 16 }}
            />
          </FileInput>
          <View
            style={{ flexDirection: 'row', alignItems: 'center' }}
            onClick={() => {
              this.props.history.push('/user/mypage/profile/name/edit');
            }}
          >
            <Text bold style={{ fontSize: 17, marginRight: 4 }}>
              {this.props.user.myself.name}
            </Text>
            <PencilIconSvg
              fill={Color.black}
              width={20}
              height={20}
              viewBox="0 0 24 24"
              style={{ marginRight: 4 }}
            />
          </View>
        </Section>
        <Section>
          <SectionTransitItem
            title="ギャラリー"
            onClick={() =>
              this.props.history.push('/user/mypage/profile/gallery/edit')
            }
          />
          <SectionTransitItem
            title="基本情報"
            onClick={() =>
              this.props.history.push('/user/mypage/profile/info/edit')
            }
          />
          <SectionTransitItem
            title="自己紹介"
            onClick={() =>
              this.props.history.push('/user/mypage/profile/pr/edit')
            }
          />
        </Section>
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserMypageProfileEdit);
