export default class Impression {
  static getItems(withDefault = true) {
    const items = [
      { value: 'cute', label: 'かわいい系' },
      { value: 'beauty', label: 'キレイ系' }
    ];

    if (withDefault) {
      items.unshift({
        type: 'impression',
        label: '未選択',
        value: 'unselected',
        isDefault: true
      });
    }

    return items.map(item => ({ type: 'impression', ...item }));
  }

  static getLabel(value) {
    return Impression.getItems().find(item => item.value === value).label;
  }
}
