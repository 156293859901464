import * as application from './application';
import * as auth from './auth';
import * as badge from './badge';
import * as chat from './chat';
import * as chatHistory from './chatHistory';
import * as chatIndex from './chatIndex';
import * as config from './config';
import * as consumer from './consumer';
import * as documentHistory from './documentHistory';
import * as layout from './layout';
import * as like from './like';
import * as model from './model';
import * as notificationHistory from './notificationHistory';
import * as paymentInfo from './paymentInfo';
import * as pointHistory from './pointHistory';
import * as reserveHistory from './reserveHistory';
import * as reviewHistory from './reviewHistory';
import * as rewardHistory from './rewardHistory';
import * as sessionHistory from './sessionHistory';
import * as setting from './setting';
import * as shop from './shop';
import * as user from './user';

export default {
  ...application,
  ...auth,
  ...badge,
  ...model,
  ...chat,
  ...chatIndex,
  ...chatHistory,
  ...config,
  ...consumer,
  ...documentHistory,
  ...layout,
  ...like,
  ...notificationHistory,
  ...paymentInfo,
  ...pointHistory,
  ...reserveHistory,
  ...reviewHistory,
  ...rewardHistory,
  ...sessionHistory,
  ...setting,
  ...shop,
  ...user
};
