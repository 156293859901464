export default class Job {
  static getItems(withDefault = true) {
    const items = [
      { label: 'カメラマン', value: 'photographer' },
      { label: 'デザイナー', value: 'designer' },
      { label: '経営者・役員', value: 'executiveManager' },
      { label: '医者', value: 'doctor' },
      { label: '弁護士', value: 'lawyer' },
      { label: '公認会計士', value: 'cpa' },
      { label: '公務員', value: 'governmentEmployee' },
      { label: '事務員', value: 'officeWorker' },
      { label: '商社', value: 'trading' },
      { label: '金融', value: 'finance' },
      { label: 'クリエイター', value: 'creator' },
      { label: 'IT系', value: 'it' },
      { label: '電子通信', value: 'engineer' },
      { label: '芸能・モデル', value: 'talent' },
      { label: '自由業', value: 'freelance' },
      { label: '学生', value: 'student' },
      { label: 'コンサル', value: 'consultant' },
      { label: '服飾関係', value: 'fashion' },
      { label: '調理師・栄養士', value: 'cook' },
      { label: '教育関連', value: 'education' },
      { label: '食品関連', value: 'food' },
      { label: '製薬', value: 'pharmaceutical' },
      { label: '保険', value: 'insurance' },
      { label: '不動産', value: 'realEstate' },
      { label: '建築関連', value: 'building' },
      { label: '運送・流通', value: 'transportation' },
      { label: '接客業', value: 'serviceTrader' },
      { label: 'エンタメ', value: 'entertainment' },
      { label: 'ブライダル関係', value: 'prydal' },
      { label: '福祉・介護', value: 'care' },
      { label: '広告業', value: 'ad' },
      { label: '報道', value: 'media' },
      { label: 'その他', value: 'other' }
    ];

    if (withDefault) {
      items.unshift({ label: '未選択', value: 'unselected', isDefault: true });
    }

    return items;
  }

  static getLabel(value) {
    return Job.getItems().find(item => item.value === value).label;
  }
}
