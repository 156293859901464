export default class PointHistory {
  constructor(state = {}) {
    this.params = {
      id: state.id,
      value: state.value,
      type: state.type,
      createdAt: state.createdAt,
      updatedAt: state.updatedAt,
      expiredAt: state.expiredAt
    };
  }

  get object() {
    return this.params;
  }

  fromFirestore() {
    return {
      ...this.params,
      createdAt: this.params.createdAt && this.params.createdAt.toDate(),
      updatedAt: this.params.updatedAt && this.params.updatedAt.toDate(),
      expiredAt: this.params.expiredAt && this.params.expiredAt.toDate()
    };
  }

  static getPointHistoryTypeLabel(type) {
    switch (type) {
      case 'buy':
        return 'オートチャージ';
      case 'shop':
        return 'コイン購入';
      case 'session':
        return '撮影';
      default:
        return '';
    }
  }
}
