import React from 'react';

import { getTextStyle } from '../utils/TextStyle';
import Color from '../constants/Color';

export default function Text({
  children,
  mincho = false,
  bold = false,
  demibold = false,
  style = {}
}) {
  const textStyle = getTextStyle({ mincho, bold, demibold });

  return (
    <span
      style={{
        color: Color.black,
        lineHeight: 1.5,
        ...textStyle,
        ...style
      }}
    >
      {children}
    </span>
  );
}
