import React from 'react';
import Color from '../../constants/Color';
import Section from '../Section/Section';

export default function ChatIndexList({ data, renderItem, style = {} }) {
  return (
    <Section
      style={{
        backgroundColor: Color.white,
        ...style
      }}
    >
      {data.map(datum => {
        return renderItem({ item: datum });
      })}
    </Section>
  );
}
