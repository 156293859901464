import React from 'react';

import { MessageTextIconSvg } from '../../utils/MaterialIconSvg';
import TabContainer from '../../components/Tab/TabContainer';

export default function SupporterTabContainer(props) {
  const { children, activeIndex, history, style = {} } = props;

  return (
    <TabContainer
      activeIndex={activeIndex}
      buttons={[
        {
          label: 'メッセージ',
          icon: MessageTextIconSvg,
          onClick: () => history.push(`/supporter/message`)
        }
      ]}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: '100%',
        ...style
      }}
    >
      {children}
    </TabContainer>
  );
}
