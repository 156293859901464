export function getChatMessage(user, data, selfType) {
  if (data.type === 'like' && data.fromUser.role === selfType) {
    return `${user.name}さんに いいね を送りました！`;
  } else if (data.type === 'like' && data.fromUser.role !== selfType) {
    return `${user.name}さんから いいね が届きました！`;
  }

  if (data.type === 'message') {
    return data.message;
  }

  switch (data.type) {
    case 'file':
      return '写真が送信されました。';
    case 'reserve':
      return '撮影日程の提案がされました。';
    case 'reserveConfirmed':
      return '撮影の日程が確定しました。';
    case 'reserveJoined':
      return '撮影が開始しました。';
    case 'reserveDismissed':
      return '撮影が完了しました。';
    case 'reserveCanceled':
      return '撮影がキャンセルされました。';
    case 'reservePenaltyCanceled':
      return '撮影がキャンセルされました。';
    default:
      return '';
  }
}
