import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../../../../actions';
import Label from '../../../../../components/Form/Label';
import NavigationHeader from '../../../../../components/Navigator/NavigationHeader';
import Section from '../../../../../components/Section/Section';
import SectionToggleItem from '../../../../../components/Section/SectionToggleItem';
import SectionTransitItem from '../../../../../components/Section/SectionTransitItem';
import View from '../../../../../components/View';
import Color from '../../../../../constants/Color';

class UserMypageSettingIndex extends React.Component {
  componentDidMount() {
    this.props.getSetting('consumer', this.props.auth.uid);
  }

  updateSetting(params) {
    this.props.updateSetting('consumer', this.props.auth.uid, params);
  }

  render() {
    const setting = this.props.setting.hash[this.props.auth.uid];

    if (!setting) {
      return null;
    }

    const { myself } = this.props.user;

    if (!myself) {
      return null;
    }

    return (
      <View>
        <NavigationHeader title="設定" goBack={this.props.history.goBack} />
        <Label style={{ color: Color.darkGrey, padding: 16 }}>
          通知用メール設定
        </Label>
        <Section style={{ marginBottom: 16 }}>
          <SectionTransitItem
            title="メール"
            content={myself.email}
            onClick={() =>
              this.props.history.push(`/user/mypage/setting/email/edit`)
            }
          />
        </Section>
        <Label style={{ color: Color.darkGrey, padding: 16 }}>
          メール通知設定
        </Label>
        <Section style={{ marginBottom: 16 }}>
          <SectionToggleItem
            title="いいね"
            defaultValue={setting.isEnableLikeEmailNotification}
            onChange={value =>
              this.updateSetting({ isEnableLikeEmailNotification: value })
            }
          />
          <SectionToggleItem
            title="メッセージ"
            defaultValue={setting.isEnableMessageEmailNotification}
            onChange={value =>
              this.updateSetting({ isEnableMessageEmailNotification: value })
            }
          />
          <SectionToggleItem
            title="合流・解散"
            defaultValue={setting.isEnableSessionEmailNotification}
            onChange={value =>
              this.updateSetting({ isEnableSessionEmailNotification: value })
            }
          />
          <SectionToggleItem
            title="運営からのお知らせ"
            defaultValue={setting.isEnableNewsEmailNotification}
            onChange={value =>
              this.updateSetting({ isEnableNewsEmailNotification: value })
            }
          />
        </Section>
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserMypageSettingIndex);
