import React from 'react';

import Color from '../../constants/Color';
import Headline from '../Headline';
import Modal from '../Modal/Modal';
import Text from '../Text';
import View from '../View';

export default class PopupSelect extends React.Component {
  render() {
    const {
      title,
      visible,
      value,
      items = [],
      onItemClick = () => {},
      onClose = () => {}
    } = this.props;

    return (
      <Modal visible={visible} onClose={onClose}>
        <View
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: Color.transparent,
            padding: 48,
            justifyContent: 'center'
          }}
          onClick={onClose}
        >
          <View style={{ backgroundColor: Color.white, maxHeight: 320 }}>
            <View
              style={{
                alignItems: 'center',
                padding: 16,
                borderBottom: `1px solid ${Color.lightGrey}`
              }}
            >
              <Headline size={3}>{title}</Headline>
            </View>
            {items.map(item => {
              return (
                <View
                  style={{
                    flexDirection: 'row',
                    padding: 16,
                    borderBottom: `1px solid ${Color.lightGrey}`,
                    alignItems: 'center'
                  }}
                  onClick={() => onItemClick(item)}
                >
                  <Text bold style={{ flex: 1, color: Color.celadonBlue }}>
                    {item.label}
                  </Text>
                  <View
                    style={{
                      borderRadius: 12,
                      backgroundColor: Color.white,
                      width: 24,
                      height: 24,
                      border: `2px solid ${Color.celadonBlue}`,
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    {JSON.stringify(item.value) === JSON.stringify(value) && (
                      <View
                        style={{
                          borderRadius: 8,
                          backgroundColor: Color.celadonBlue,
                          width: 12,
                          height: 12
                        }}
                      />
                    )}
                  </View>
                </View>
              );
            })}
          </View>
        </View>
      </Modal>
    );
  }
}
