import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import actions from '../../../../../actions';
import Button from '../../../../../components/Button';
import EmptyText from '../../../../../components/EmptyText';
import NavigationHeader from '../../../../../components/Navigator/NavigationHeader';
import Section from '../../../../../components/Section/Section';
import SectionTransitItem from '../../../../../components/Section/SectionTransitItem';
import View from '../../../../../components/View';
import PaymentModalBox from '../../../../../containers/payment/PaymentModalBox';

class UserMypagePaymentInfoIndex extends React.Component {
  state = {
    isOpenCardRegisterModal: false
  };

  componentDidMount() {
    this.props.getMyselfUser();
  }

  render() {
    const { myself } = this.props.user;

    if (!myself) {
      return null;
    }

    return (
      <View>
        <NavigationHeader
          title="カード情報"
          goBack={this.props.history.goBack}
        />
        <View style={{ paddingTop: 32 }}>
          <View style={{ marginBottom: 32 }}>
            {myself.paymentInfos.length < 1 ? (
              <EmptyText style={{ paddingLeft: 16 }}>
                カード情報がありません。
              </EmptyText>
            ) : (
              <Section>
                {myself.paymentInfos.map(info => {
                  const isDefault = info.id === myself.defaultPaymentInfoId;

                  return (
                    <Link to={`/user/mypage/paymentInfo/${info.id}`}>
                      <SectionTransitItem
                        title={`${info.source.brand}${
                          isDefault ? ' [デフォルト]' : ''
                        }`}
                        content={`${info.source.expYear}/${info.source.expMonth} | **** ${info.source.last4}`}
                      />
                    </Link>
                  );
                })}
              </Section>
            )}
          </View>
        </View>
        <View style={{ padding: '0 16px' }}>
          <Button
            onClick={() => this.setState({ isOpenCardRegisterModal: true })}
          >
            カードを追加する
          </Button>
        </View>
        <PaymentModalBox
          visible={this.state.isOpenCardRegisterModal}
          onClose={() => this.setState({ isOpenCardRegisterModal: false })}
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserMypagePaymentInfoIndex);
