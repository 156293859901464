import React from 'react';
import Color from '../../constants/Color';
import Header from '../Header';
import View from '../View';
import UserIcon from '../Icons/UserIcon';
import Text from '../Text';
import {
  ChevronLeftIconSvg,
  CreditCardOffIconSvg
} from '../../utils/MaterialIconSvg';

export default function NavigationHeader({
  title,
  user = null,
  goBack = () => {},
  onClickUser = () => {},
  right,
  left,
  color = Color.slate,
  style = {}
}) {
  return (
    <Header
      title={
        user ? (
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <View
              style={{ flexDirection: 'row', alignItems: 'center' }}
              onClick={() => onClickUser(user)}
            >
              <UserIcon
                source={user.iconImage}
                size={20}
                style={{ marginRight: 8 }}
              />
              <Text style={{ fontSize: 17 }}>{user.name}</Text>
            </View>
            {user.roles[0] === 'consumer' && !user.cardRegisterAt && (
              <CreditCardOffIconSvg
                fill={Color.darkGrey}
                width={20}
                height={20}
                viewBox="0 0 24 24"
                style={{ marginLeft: 8 }}
              />
            )}
          </View>
        ) : (
          title
        )
      }
      left={
        <View onClick={() => goBack()} style={{ padding: 4 }}>
          <ChevronLeftIconSvg
            fill={Color.black}
            width={24}
            height={24}
            viewBox="0 0 24 24"
            style={{ marginRight: 4 }}
          />
        </View>
      }
    />
  );
}
