import {
  getCollection,
  getDb,
  getTimestamp,
  uploadImages
} from '../utils/Firebase';

export default class Model {
  constructor(state = {}) {
    this.params = {
      roles: ['model'],
      price: state.price || 5000,
      prefectures: state.prefectures || [],
      impressions: state.impressions || [],
      physiques: state.physiques || [],
      costumes: state.costumes || [],
      height: state.height || '',
      birthplace: state.birthplace || 'unselected',
      address: state.address || 'unselected',
      pr: state.pr || '',
      personalUsage: state.personalUsage || false,
      groupUsage: state.groupUsage || false,
      snsUsage: state.snsUsage || false,
      adUsage: state.adUsage || false,
      resaleUsage: state.resaleUsage || false,
      galleries: state.galleries || [],
      createdAt: state.createdAt,
      updatedAt: state.updatedAt
    };
  }

  get object() {
    return this.params;
  }

  fromFirestore() {
    return {
      ...this.params,
      createdAt: this.params.createdAt && this.params.createdAt.toDate(),
      updatedAt: this.params.updatedAt && this.params.updatedAt.toDate()
    };
  }

  static async get(id) {
    const doc = await getCollection('models')
      .doc(id)
      .get();

    const model = new Model({
      id: doc.id,
      ...doc.data()
    }).fromFirestore();

    return model;
  }

  static async update(id, params) {
    const db = getDb();

    const model = getCollection('models').doc(id);

    const { galleries = null } = params;

    return uploadImages(`/models/${id}/galleries`, galleries).then(
      async imagePaths => {
        return db.runTransaction(transaction => {
          return transaction.get(model).then(doc => {
            if (!doc.exists) {
              throw new Error('update/not-exist');
            }

            const { createdAt, updatedAt, ...rest } = params;

            const { galleries: docGalleries = [] } = doc.data();

            return transaction.update(model, {
              ...rest,
              galleries: imagePaths
                ? imagePaths.map(path => ({ uri: path }))
                : docGalleries,
              updatedAt: getTimestamp()
            });
          });
        });
      }
    );
  }
}
