import * as types from '../constants/ActionTypes';

const initialState = {
  list: [],
  hash: {},
  isPurchaseLoading: false,
  isPurchaseFailed: false,
  purchaseError: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    // create payment info
    case types.FETCH_PURCHASE_SHOP:
      return {
        ...state,
        isPurchaseLoading: true
      };

    case types.FETCH_PURCHASE_SHOP_SUCCESS:
      return {
        ...state,
        hash: {
          ...state.hash,
          [action.state.id]: action.state.params
        },
        list: [...state.list, action.state.id],
        isPurchaseLoading: false,
        isPurchaseFailed: false
      };

    case types.FETCH_PURCHASE_SHOP_FAILED:
      return {
        ...state,
        isPurchaseLoading: false,
        isPurchaseFailed: true,
        purchaseError: action.error
      };

    case types.FETCH_PURCHASE_SHOP_RECOVER:
      return {
        ...state,
        isPurchaseFailed: false
      };

    default:
      return state;
  }
};
